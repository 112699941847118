import { Alert, Snackbar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography, IconButton, DialogActions } from "@mui/material"
import { Fragment, useState } from "react"
import Breadcrumb from "../../registri/shared/breadcrumb"
import ElencoCorsiFormatore from "../elencoCorsiFormatore"
import { useNavigate } from "react-router-dom";
import { convertDataDiNascita, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import { TirociniAPI } from "../../../api/tirociniApi";
import { ArrowBack, EditCalendar } from "@mui/icons-material";
import RangeDate from "../../shared/rangeDate";
import { convertISODate } from "../../shared/metodiDateUtility";
import { Help, Close } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { DateTime } from "luxon";
import TableValidazionePresenze from "./tableValidazionePresenze";
import DettaglioAlunno from "../../dettaglio-utente/dettaglioAlunno";
import DettaglioGiornataValidazione from "./dettaglio-giornaliero-validazione/dettaglioGiornataValidazione";
import LegendaValidazionePresenzeTirocinio from "./legendaValidazionePresenze";
import { isAlunnoIscrittoInRange } from "../../shared/utilityMethods";

export default function SintesiValidazionePresenze() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const [dialogSelezionaCorsoOpen, setDialogSelezionaCorsoOpen] = useState(true)
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1)
    const [nomeCorso, setNomeCorso] = useState("")
    const [sedeUtente, setSedeUtente] = useState("");

    const [dataDiInteresseInizio, setDataDiInteresseInizio] = useState(null);
    const [dataDiInteresseFine, setDataDiInteresseFine] = useState(null);
    const [formattedDataDiInteresseInizio, setFormattedDataDiInteresseInizio] = useState("");
    const [formattedDataDiInteresseFine, setFormattedDataDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false)

    const [listAlunni, setListAlunni] = useState([]);
    const [listDays, setListDays] = useState([]);

    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [isDialogDettaglioOpen, setIsDialogDettaglioOpen] = useState(false);
    const [dataDettaglio, setDataDettaglio] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [isDettaglioAlunnoOpen, setIsDettaglioAlunnoOpen] = useState(false);
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [indexTabDettaglioAlunno, setIndexTabDettaglioAlunno] = useState(0);

    function goToHome() {
        navigate("/")
    }

    function openChangeCorso() {
        setDialogSelezionaCorsoOpen(true);
        setHasLoaded(false);
    }

    function changeIdCorsoSelezionata(idCorso, nomeCorso, codiceCorso, codiceSimon, nomeSede, dataDiInteresse) {

        setDialogSelezionaCorsoOpen(false);

        setIdCorsoSelezionato(idCorso);
        setNomeCorso(codiceCorso + " - " + nomeCorso + " - " + nomeSede);

        setSedeUtente(nomeSede)

        let dataDiInteresseInizioConverted = convertDataGetProssimeLezione(dataDiInteresse, true);
        setDataDiInteresseInizio(dataDiInteresseInizioConverted);
        let dataDiInteresseInizioFormatted = convertDataDiNascita(dataDiInteresse);
        setFormattedDataDiInteresseInizio(dataDiInteresseInizioFormatted);

        let dataDiInteresseFineTmp = dataDiInteresse.endOf('week');
        let dataDiInteresseFineConverted = convertDataGetProssimeLezione(dataDiInteresseFineTmp, true);
        setDataDiInteresseFine(dataDiInteresseFineConverted);
        let dataDiInteresseFineFormatted = convertDataDiNascita(dataDiInteresseFineTmp);
        setFormattedDataDiInteresseFine(dataDiInteresseFineFormatted);

        getRegistroSintesi(idCorso, dataDiInteresseInizioConverted, dataDiInteresseFineConverted);
    }

    function validazioniSalvate(){
        setHasLoaded(false);
        getRegistroSintesi(idCorsoSelezionato, dataDiInteresseInizio, dataDiInteresseFine);
        setIsDialogDettaglioOpen(false);
        openAlertMsg("Modifiche salvate correttamente", "success")
    }



    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }

    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let today = DateTime.local();
        let dataInizioTmp = dataInizio ?? today.toISO();
        let dataFineTmp = dataFine ?? today.toISO();

        setDataDiInteresseInizio(dataInizioTmp);
        setDataDiInteresseFine(dataFineTmp);

        setFormattedDataDiInteresseInizio(convertISODate(dataInizio));
        setFormattedDataDiInteresseFine(convertISODate(dataFine));

        setChangePeriodoInteresseOpen(false)

        getRegistroSintesi(idCorsoSelezionato, dataInizio, dataFine);
    }

    function openDialogDettaglio(dataDettaglio) {
        setDataDettaglio(dataDettaglio);
        setIsDialogDettaglioOpen(true);
    }

    function closeDialogDettaglio() {
        setHasLoaded(false);
        getRegistroSintesi(idCorsoSelezionato, dataDiInteresseInizio, dataDiInteresseFine);
        setIsDialogDettaglioOpen(false);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }
    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }


    function openDettaglioAlunno(alunno) {
        let alunnoTmp = null;
        if (alunno !== undefined) {
            alunnoTmp = {
                id: alunno?.id,
                nome: alunno?.nome,
                cognome: alunno?.cognome
            }
        }
        setIndexTabDettaglioAlunno(0);
        setAlunnoSelezionato(alunnoTmp);
        setIsDettaglioAlunnoOpen(prev => !prev);
    }

    async function getRegistroSintesi(idCorso, dataInizio, dataFine) {
        let dataObj = {
            idCorso: idCorso,
            dataDa: dataInizio,
            dataA: dataFine
        }
        TirociniAPI.sintesiValidazionePresenze(dataObj)
            .then((result) => {
                setHasLoaded(true);
                if (isError) setIsError(false);

                setListDays([...result?.giorniLezioneTirocinio])

               let listAlunniTmp = result?.alunni ?? [];
                for (let i = 0; i < listAlunniTmp.length; i++) {
                    listAlunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataInizio, dataFine, listAlunniTmp[i]);
                }

                setListAlunni([...listAlunniTmp])

            

            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    setIsError(true);
                }
            })
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Breadcrumb sections={[ "Validazione presenze tirocinio", nomeCorso]} isTirocinio={true} />
                    <Box sx={{ px: 5, minHeight: '80vh' }}>
                        {listDays.length > 0 ? (
                            <Fragment>
                                <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: 'start', md: 'space-between' }}>
                                    <Box display={"flex"} flexDirection={{ xs: 'column', lg: 'row' }} width={{ xs: '100%', lg: 'unset' }}>
                                        <Button onClick={openChangeCorso} sx={{ width: { xs: '100%', lg: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                            Torna alla selezione del corso
                                        </Button>
                                        <Button sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                            Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                        </Button>
                                        <Button startIcon={<Help />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, mb: { xs: 1, md: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                            Mostra legenda
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{ border: '1px solid #eaeaea', borderRadius: '15px', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center', overflow: 'scroll' }}>
                                    <TableValidazionePresenze nomeCorso={nomeCorso} listAlunni={listAlunni} listDays={listDays} openDettaglioAlunno={openDettaglioAlunno} openDialogDettaglio={openDialogDettaglio} />
                                </Box>
                            </Fragment>
                        ) : (
                            <Box height={"80vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                <Alert severity="info">Nessuna giornata di tirocinio nel periodo selezionato ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})</Alert>
                                <Button startIcon={<ArrowBack />} onClick={openChangeCorso} variant="contained" sx={{ mt: 2 }}>  Torna alla selezione del corso</Button>
                                <Button sx={{ mt: 1, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                    Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Fragment >
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                </Box>
            )
            }

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaCorsoOpen} sx={{ mt: 8 }}>
                <DialogTitle variant="h6"><b>Seleziona corso</b></DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoCorsiFormatore showDateRange={true}
                        sedeUtente={sedeUtente} goToHome={goToHome} changeCorsoSelezionato={changeIdCorsoSelezionata} />
                </DialogContent>
            </Dialog>
            <Dialog open={changePeriodoDiInteresseOpen} >
                <DialogTitle variant="h6">
                    Seleziona il periodo di interesse
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RangeDate idClasse={idCorsoSelezionato} dataDiInteresseInizio={dataDiInteresseInizio} dataDiInteresseFine={dataDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>


            <Dialog sx={{ marginTop: '7vh' }} fullScreen open={isDialogDettaglioOpen}>
                <DialogContent>
                    <DettaglioGiornataValidazione validazioniSalvate={validazioniSalvate} idCorso={idCorsoSelezionato} dataDettaglio={dataDettaglio} nomeCorso={nomeCorso} closeDialogDettaglio={closeDialogDettaglio} openDialogLegenda={openDialogLegenda} /> 
                </DialogContent>
            </Dialog>



            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="md" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaValidazionePresenzeTirocinio/>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioAlunnoOpen} onClose={openDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome})</Typography>
                    <IconButton onClick={openDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno tabIndex={indexTabDettaglioAlunno} id={alunnoSelezionato?.id} nomeAlunno={alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome} idCorso={idCorsoSelezionato} />
                </DialogContent>
            </Dialog>


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Validazione presenze tirocinio</title>
            </Helmet>
        </Fragment >
    )
}