import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Divider, Skeleton, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button } from '@mui/material';
import { Close, Edit } from "@mui/icons-material";
import PresenzaTirocini from "../presenzaTirocini";
import { convertOra, convertToItalianTime } from "../../shared/metodiDateUtility";
import DettaglioVisita from "./dettaglioVisita";
import DettaglioAlunno from "../../dettaglio-utente/dettaglioAlunno";

export default function TablePresenzeTutorTirocinio(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [listVisite, setListVisite] = useState([]);

    const [isDettaglioVisitaOpen, setIsDettaglioVisitaOpen] = useState(false);
    const [idVisitaSelezionata, setIdVisitaSelezionata] = useState(-1);

    const [isDettaglioAlunnoOpen, setIsDettaglioAlunnoOpen] = useState(false);
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [canEditFirme, setCanEditFirme] = useState(false);

    useEffect(() => {
        if (props.canEditFirme) setCanEditFirme(props.canEditFirme);
    }, [props.canEditFirme])

    useEffect(() => {
        if (props.corso) setIdCorsoSelezionato(props.idCorso);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idCorso])

    useEffect(() => {

        if (props.listVisite) {
            setListVisite([...props.listVisite]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100)
        }
    }, [props.listVisite])

    function viewDettaglioVisita(presenza) {
        if(presenza !== undefined && presenza !== null){
        setIsDettaglioVisitaOpen(true);
            setIdVisitaSelezionata(presenza.idPresenza);
        }
    }

    function openDettaglioAlunno(alunno) {
        let alunnoTmp = null;
        if (alunno !== undefined) {
            alunnoTmp = {
                id: alunno?.id,
                nome: alunno?.nome,
                cognome: alunno?.cognome
            }
        }
        setAlunnoSelezionato(alunnoTmp);
        setIsDettaglioAlunnoOpen(prev => !prev);
    }

    function openDialogFirmaOraVisita(visita) {
        if (props.openDialogFirmaOraVisita) props.openDialogFirmaOraVisita(visita)
    }

    return (
        <Fragment>
            <TableContainer sx={{ width: '100%', maxHeight: { xs: 'unset', md: '40vh', lg: '60vh' } }} component={Paper}>
                <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            {canEditFirme && (<TableCell align="center"
                                className="row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Modifica orario
                                        {/* Data visite */}
                                    </Typography>
                                </Box>
                            </TableCell>)}
                            <TableCell align="center"
                                className="row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Data
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Ora inizio
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Ora fine
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Alunno
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Stato presenza alunno
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Validata
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Annullata
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {listVisite.map((visita, index) => (
                            <TableRow style={{ height: '40px' }} key={visita?.idVisita + '_visita'}>
                                {canEditFirme && (<TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {(!visita?.validata && !visita?.annullata) && (
                                        <Box display={'flex'} justifyContent={'center'}>
                                            <IconButton color={'primary'} sx={{ border: '1px solid ' }} onClick={() => openDialogFirmaOraVisita(visita)}><Edit sx={{ fontSize: '1rem' }} /></IconButton>

                                            {/* {studente?.annotazioneUtente === null ? (
                                                <IconButton color={'primary'} sx={{ border: '1px solid ' }} onClick={() => openDialogAnnotazioni({}, studente, true, false)}><Edit sx={{ fontSize: '1rem' }} /></IconButton>
                                            ) : (
                                                < Box width={"35px"} height={"35px"} backgroundColor={'#1976d3'}
                                                    onClick={() => openDialogAnnotazioni(studente?.annotazioneUtente, studente, true, false)}

                                                    display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                    sx={{ borderRadius: '100%', color: 'white', cursor: 'pointer' }}>
                                                    <Edit sx={{ fontSize: '1rem', color: 'white' }} />
                                                </Box>
                                            )} */}
                                        </Box>
                                    )}
                                </TableCell>)}
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{  backgroundColor: 'white', px: 2, py: 2 }}>
                                            <Typography variant="body2" textAlign={'center'}>  {convertToItalianTime(visita?.data)}</Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{  backgroundColor: 'white', px: 2, py: 2 }}>
                                            <Typography variant="body2" textAlign={'center'}>  {convertOra(visita?.orarioInizio)}</Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{  backgroundColor: 'white', px: 2, py: 2 }}>
                                            <Typography variant="body2" textAlign={'center'}>  {convertOra(visita?.orarioFine)}</Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        visita?.alunno !== undefined ? (
                                        <Typography onClick={() => openDettaglioAlunno(visita?.alunno, false)} variant={mobile ? 'caption' : 'body2'} textAlign={'center'} sx={{ cursor: 'pointer', px: 2, mr: 2, fontWeight: visita?.alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                            {(visita?.alunno?.cognome).toUpperCase() + " " + (visita?.alunno?.nome).toUpperCase()}
                                        </Typography>
                                        ): (
                                                <Typography variant={mobile ? 'caption' : 'body2'} textAlign={'center'}>-</Typography>
                                        )
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{backgroundColor: 'white', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <Box sx={{ cursor: visita.presenzaAlunno != null ? 'pointer' : 'unset' }} onClick={() => viewDettaglioVisita(visita.presenzaAlunno)}>
                                                <PresenzaTirocini isSmallSize={true} presenza={visita?.presenzaAlunno} />
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box display="flex" justifyContent={"center"}>
                                            <Skeleton variant="circular" width={40} height={40} sx={{ mx: 2 }} />
                                        </Box>
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{  backgroundColor: 'white', px: 2, py: 2 }}>
                                            <Typography variant="body2" textAlign={'center'} color={visita?.validata ? 'primary' : 'unset'}>  {visita?.validata ? 'SI' : 'NO'}</Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{  backgroundColor: 'white', px: 2, py: 2 }}>
                                            <Typography variant="body2" textAlign={'center'} color={visita?.annullata ? 'primary' : 'unset'}>  {visita?.annullata ? 'SI' : 'NO'}</Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioVisitaOpen} onClose={() => setIsDettaglioVisitaOpen(false)}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio visita</Typography>
                    <IconButton onClick={() => setIsDettaglioVisitaOpen(false)} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioVisita idPresenza={idVisitaSelezionata} />
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button variant="contained" fullWidth color="primary" onClick={() => setIsDettaglioVisitaOpen(false)}>Chiudi dettaglio</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioAlunnoOpen} onClose={openDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome})</Typography>
                    <IconButton onClick={openDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno tabIndex={0} id={alunnoSelezionato?.id} nomeAlunno={alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome} idCorso={idCorsoSelezionato} />
                </DialogContent>
            </Dialog>

        </Fragment>
    )
}

