import React from "react";
import { useState, Fragment, useRef } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, useTheme, useMediaQuery, Checkbox, IconButton, Dialog, DialogContent, DialogTitle, Button, Breadcrumbs, Link, CircularProgress, Alert, Snackbar, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, Divider, DialogContentText, DialogActions, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ArrowBack, Search, Edit, VisibilityOff, Visibility, Download, HowToReg, Close, ErrorOutlineOutlined, } from "@mui/icons-material";
import SelezioneClassiTutor from "../../riepilogo-attivita-formativa/selezioneClassiTutor";
import { convertDataDiNascita, convertISODate } from "../../shared/metodiDateUtility";
import SchedaAlunno from "./schedaAlunno";
import { ScrutiniAPI } from "../../../api/scrutiniApi";
import ModificaVotiCondottaTutor from "./modificaVotiCondottaTutor";
import InserimentoVotiPrescrutinio from "./inserimentoVotiPrescrutinio";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ElencoDocumentiScrutinio from "./elencoDocumentiScrutinio";
import { regexInserimentoVoto } from "../../shared/utilityMethods";
import Breadcrumb from "../../registri/shared/breadcrumb";
import ElencoCollegioFormatori from "./elencoCollegioFormatori";

export default function ElencoAlunniRiepilogoDidattico() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();

  const [indexAlunnoSelezionato, setIndexAlunnoSelezionato] = useState(0);
  const [listAlunni, setListAlunni] = useState([]);
  const [listAlunniSupporto, setListAlunniSupporto] = useState([]);
  const [materie, setMaterie] = useState([]);

  const [dettaglioAlunnoOpen, setDettaglioOpen] = useState(false);
  const [selezionaClasseDialogOpen, setSelezionaClasseDialogOpen] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [nomeClasseSelezionata, setNomeClasseSelezionata] = useState("");
  const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
  const [trimestreSelezionato, setTrimestreSelezionato] = useState(null);
  const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
  const [nomeSede, setNomeSede] = useState("");

  const [showInserisciVotiCondotta, setShowInserisciVotiCondotta] = useState(true); //Nasconde o visualizza il pulsante "inserisci voti condotta" per il tutor
  const [showIniziaScrutinio, setShowIniziaScrutinio] = useState(true); //Nasconde o visualizza il tasto "inizia scrutinio"
  const [showIniziaScrutinioAlunno, setShowIniziaScrutinioAlunno] = useState(false);
  const [showPreparaScrutinio, setShowPreparaScrutinio] = useState(true); //Nasconde o visualizza il tasto "prepara scrutinio"

  // (solo se data e ruolo corrispondono e se NON c'è già uno scrutinio per quella classe/corso)
  const [showPropostaVotoCondottaDocente, setShowPropostaVotoCondottaDocente] = useState(true); //Nasconde o visualizza i voti della condotta dei docenti
  const [showVotiScrutinio, setShowVotiScrutinio] = useState(true); //Nasconde o visualizza la sezione scrutinio (viola)
  const [riaperturaScrutinio, setRiaperturaScrutinio] = useState(true); //Nasconde o visualizza il pulsante (riapertura straordinaria)
  const [nomeStato, setNomeStato] = useState("-"); //Il nome dello stato (da visualizzare a fianco del nome periodo)
  const [idScrutinio, setIdScrutinio] = useState(null); //id dello scrutinio se già ne esiste uno aperto

  const [showConcludi, setShowConcludi] = useState(false);

  const [scrutinioConcluso, setScrutinioConcluso] = useState(false);
  const [sezioneScrutinioAttiva, setSezioneScrutinioAttiva] = useState(false);

  const [modificaVotiOpen, setModificaVotiOpen] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMsg, setAlertMsg] = useState(false);

  const [hasError, setHasError] = useState(false);

  const [riaperturaScrutinioConfirmDisabled, setRiaperturaScrutinioConfirmDisabled] = useState(false);
  const [motivazione, setMotivazione] = useState("");
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [isPinError, setIsPinError] = useState(false);
  const [isMotivazioneError, setIsMotivazioneError] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [riaperturaScrutinioDialogOpen, setRiaperturaScrutinioDialogOpen] = useState(false);
  const [confirmDialogRiaperturaOpen, setConfirmDialogRiaperturaOpen] = useState(false);
  const [openDialogModifica, setOpenDialogModifica] = useState(false);

  const [elencoDocumentiScrutiniOpen, setElencoDocumentiScrutiniOpen] = useState(false);
  const [documentiScrutinio, setDocumentiScrutinio] = useState([]);

  const [showAccettaPrescrutinio, setShowAccettaPrescrutinio] = useState(false);
  const [alunnoAccettaPrescrutinio, setAlunnoAccettaPrescrutinio] = useState({});

  const [isLoadingConclusioneScrutinio, setIsLoadingConclusioneScrutinio] = useState(false)
  const [isUltimoPeriodo, setIsUltimoPeriodo] = useState(false);
  const [isDialogCollegioFormatoriOpen, setIsDialogCollegioFormatoriOpen] = useState(false);
  const [periodoSelezionato, setPeriodoSelezionato] = useState(3);
  const [numericoCorso, setNumericoCorso] = useState(1);

  const inputMotivazioneRef = useRef(null);
  const refPin = useRef(null);

  const condotta = [
    "Motivazione ed interesse",
    "Interazione con il contesto",
    "Relazione con gli altri",
    "Media",
  ];

  function openCambiaClasse() {
    setSelezionaClasseDialogOpen(true);
    setHasLoaded(false);
  }

  function openDialogDettaglio(index) {
    setIndexAlunnoSelezionato(index);
    setAlunnoSelezionato(listAlunni[index]);
    setDettaglioOpen(true);
    setHasLoaded(false);
  }

  function closeSchedaAlunno() {
    //setHasLoaded(true);
    setDettaglioOpen(false);
    getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
    getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
  }

  function closeSchedaAlunnoSavedScrutinio(nome, cognome) {
    setDettaglioOpen(false);
    setAlertMsg(
      "Scrutinio dell'alunno " +
      nome +
      " " +
      cognome +
      "  salvato correttamente"
    );
    setAlertSeverity("success");
    setAlertOpen(true);
    getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
    getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
  }

  function changeIdClasseSelezionata(
    idClasseSelezionata,
    dataInizio,
    dataFine,
    idMateriaSelezionata,
    nomeClasseSelezionata,
    trimestri,
    trimestreSelezionato,
    nomeMateriaSelezionata,
    sedeUtente,
    nonSo,
    codiceClasseSelezionata,
    codiceSimonClasseSelezionata,
    trimestreNome,
    sedeNome
  ) {
    setIdCorsoSelezionato(idClasseSelezionata);
    let objTrimestre = {
      id: trimestreSelezionato,
      nome: trimestreNome,
      dataInizio: dataInizio,
      dataFine: dataFine,
      formattedInizio: convertISODate(dataInizio),
      formattedFine: convertISODate(dataFine),
    };
    setPeriodoSelezionato(objTrimestre?.id ?? 3)
    setTrimestreSelezionato(objTrimestre);
    setNomeClasseSelezionata(codiceClasseSelezionata + " - " + nomeClasseSelezionata + " (" + codiceSimonClasseSelezionata + ")");
    setSelezionaClasseDialogOpen(false);
    setNomeSede(sedeNome);
    //getRiepilogoClasse(idClasseSelezionata, dataInizio, dataFine, 53);
    getElencoAlunni(idClasseSelezionata, trimestreSelezionato);
    getPermessiStato(trimestreSelezionato, idClasseSelezionata);
  }

  function changeDialogInserisciVotiOpen() {
    let isOpening = !modificaVotiOpen;
    setModificaVotiOpen(isOpening);
    if (isOpening === false) {
      setAlertMsg("Voti di prescrutinio salvati correttamente");
      setAlertSeverity("success");
      setAlertOpen(true);
      getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
      getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
    }
    // setHasLoaded(prev => !prev);
  }

  function closeDialogInserisciVotiOpen() {
    setModificaVotiOpen(false)
  }

  function calcolaColorePercentuale(percentualeString) {
    if (percentualeString !== undefined) {
      let nuovaString = percentualeString.split("%", 2);
      let percentualeIntera = parseInt(nuovaString);
      if (percentualeIntera < 25) return "green";
      else return "red";
    } else return "black";
  }

  function isVotoMateriaNull(studente, materiaTmp) {

    const studenteMaterieLookup = studente?.materie.reduce(
      (lookup, materia) => {
        lookup[materia.id] = true;
        return lookup;
      },
      {}
    );

    return (
      !studenteMaterieLookup[materiaTmp?.idMateria] && (
        <Typography
          variant="caption"
          sx={{ fontWeight: "bold" }}
        >
          -
        </Typography>
      )
    );

  }

  async function getElencoAlunni(idCorso, idPeriodo) {
    let dataObj = {
      idCorso: idCorso,
      idPeriodo: idPeriodo,
    };

    ScrutiniAPI.getElencoAlunniRiepilogoDidattico(dataObj)
      .then((result) => {
        setListAlunni([...result?.alunni]);
        setListAlunniSupporto([...result?.alunni]);
        setMaterie([...result?.materie]);
        setScrutinioConcluso(checkScrutinio(result?.alunni));
        setHasLoaded(true);
        setHasError(false);
        setNumericoCorso(result?.numericoCorso ?? 1);
      })
      .catch(function (error) {
        setHasError(true);
      });
  }
  function checkScrutinio(array) {
    let isAllNull = array.some((element) => element.concluso == null);
    if (isAllNull === false) {
      //Se non sono null
      let check = array.some(
        (element) => element.concluso?.isConcluso === false
      );
      return check;
    } else {
      //Altrimenti se sono tutti null vuol dire che non l'ho iniziato per niente lo scrutinio
      return true;
    }
  }

  function changeOpenDialogModifica() {
    setOpenDialogModifica((prev) => !prev);
    setHasLoaded((prev) => !prev);
  }

  function changeConfermaModifica(voti) {
    editVotoCondotta(voti);
  }

  function changeTestoMotivazione(e) {
    inputMotivazioneRef.current.value = e.target.value;
    // setRiaperturaScrutinioConfirmDisabled(checkIfButtonRiaperturaIsDisabled());
  }
  function changeTestoMotivazioneBlur(e) {
    setMotivazione(e.target.value);
    if (e.target.value !== "")
      setIsMotivazioneError(false);
    // setRiaperturaScrutinioConfirmDisabled(checkIfButtonRiaperturaIsDisabled());
  }

  function changeOpenDialogRiaperturaScrutinio() {
    setRiaperturaScrutinioDialogOpen((prev) => !prev);
  }

  function confermaRiaperturaScrutinio() {
    riapriScrutinio();
  }

  function closeDialogRiaperturaScrutinio() {
    setRiaperturaScrutinioDialogOpen(false);
    setPinError('')
    setIsPinError(false)
    getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
    getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
  }

  function openConfirmDialogRiapertura() {
    if (checkIfButtonRiaperturaIsDisabled() == false) {
      setIsPinError(false)
      setIsMotivazioneError(false);
      setConfirmDialogRiaperturaOpen((prev) => !prev);
    }
    else {
      if (refPin.current.value == "")
        setIsPinError(true)
      if (inputMotivazioneRef.current.value == "")
        setIsMotivazioneError(true);
      setAlertMsg("Per proseguire è necessario aver inserito una motivazione ed il pin.");
      setAlertSeverity("error");
      setAlertOpen(true);

    }
  }

  function changePin(e) {
    refPin.current.value = e.target.value;
    //setRiaperturaScrutinioConfirmDisabled(checkIfButtonRiaperturaIsDisabled());
  }

  function changePinBlur(e) {
    setPin(e.target.value);
    if (e.target.value !== "")
      setIsPinError(false);
  }

  function showPasswordInput() {
    setShowPin((prev) => !prev);
  }

  function checkIfButtonRiaperturaIsDisabled() {
    if (
      refPin?.current?.value === "" ||
      inputMotivazioneRef?.current?.value === ""
    )
      return true;
    else return false;
  }

  function accettaPrescrutinio(studente) {
    setAlunnoAccettaPrescrutinio(studente);
    setShowAccettaPrescrutinio((prev) => !prev);
  }

  function chiudiAccettaPrescrutinio() {
    setShowAccettaPrescrutinio(false);
  }

  function confermaAccettaPrescrutinio() {
    setShowAccettaPrescrutinio(false);
  }

  function openDialogCollegioFormatori() {
    setIsDialogCollegioFormatoriOpen(prev => !prev);
  }

  function saveAndCloseCollegioFormatori(alertMsg) {
    setIsDialogCollegioFormatoriOpen(false);
    setAlertMsg(alertMsg);
    setAlertSeverity("success");
    setAlertOpen(true);
  }

  function openElencoScrutiniDocumentazione() {
    let isOpen = !elencoDocumentiScrutiniOpen;
    setElencoDocumentiScrutiniOpen(isOpen);
    if (isOpen) getElencoDocumenti();
  }

  async function editVotoCondotta(arrayVoti) {
    let dataObj = {
      idCorso: idCorsoSelezionato,
      // idMateria: 0,
      idPeriodo: trimestreSelezionato.id,
      condotta: arrayVoti,
    };

    ScrutiniAPI.editCondottaTutor(dataObj)
      .then((result) => {
        setOpenDialogModifica(false);
        setHasLoaded(true);

        let listAlunniTmp = listAlunniSupporto;

        for (let a of listAlunniTmp) {
          let votiTmp = result.find((oggetto) => oggetto.idAlunno === a.id);

          a.condottaTutor.motivazione = votiTmp.motivazione;
          a.condottaTutor.interazione = votiTmp.interazione;
          a.condottaTutor.relazione = votiTmp.relazione;
          a.condottaTutor.mediaCondotta = votiTmp.mediaCondotta;
        }

        setAlertMsg("Proposta voti condotta modificati correttamente");
        setAlertSeverity("success");
        setAlertOpen(true);

        //setListAlunniSupporto([...listAlunniTmp])
        //setListAlunni([...listAlunniTmp])

        getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
        getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
      })
      .catch(function (error) {
        setAlertMsg("Errore durante la modifica dei voti di condotta proposti");
        setAlertSeverity("error");
        setAlertOpen(true);
      });
  }
  async function avviaScrutinio() {
    let dataObj = {
      idCorso: idCorsoSelezionato,
      idPeriodo: trimestreSelezionato.id,
    };

    ScrutiniAPI.avviaScrutinio(dataObj)
      .then((result) => {
        setShowIniziaScrutinioAlunno(true);
        getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
        if (result.idScrutinio !== undefined)
          setIdScrutinio(result.idScrutinio);
        setAlertMsg("Scrutinio avviato");
        setAlertSeverity("info");
        setAlertOpen(true);
      })
      .catch(function (error) {
        setAlertMsg(
          error?.response?.data?.data[0]?.msg ??
          "Errore durante l'apertura dello scrutinio. Provare più tardi."
        );
        setAlertSeverity("error");
        setAlertOpen(true);
      });
  }
  async function concludiScrutinio() {
    let dataObj = {
      idScrutinio: idScrutinio,
    };
    setIsLoadingConclusioneScrutinio(true)
    ScrutiniAPI.concludiScrutinio(dataObj)
      .then((result) => {

        setIsLoadingConclusioneScrutinio(false)
        setShowIniziaScrutinioAlunno(false)
        getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
        getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);
        setAlertMsg("Scrutinio concluso");
        setAlertSeverity("success");
        setAlertOpen(true);
      })
      .catch(function (error) {


        setIsLoadingConclusioneScrutinio(false)
        if (error.response.status === 409) {
        setAlertMsg(error?.response?.data?.data[0]?.msg);
        } else {
        setAlertMsg("Errore durante la conclusione dello scrutinio");
        }
        setAlertSeverity("error");
        setAlertOpen(true);
      });
  }
  async function riapriScrutinio() {
    let dataObj = {
      idCorso: idCorsoSelezionato,
      idPeriodo: trimestreSelezionato.id,
      motivazione: motivazione,
      pin: pin,
    };

    ScrutiniAPI.riaperturaScrutinio(dataObj)
      .then((result) => {
        setConfirmDialogRiaperturaOpen(false);
        setRiaperturaScrutinioDialogOpen(false);
        setShowIniziaScrutinioAlunno(true);

        setPinError('')
        setIsPinError(false)

        let newIdScrutinio = result.idScrutinio;
        setIdScrutinio(newIdScrutinio)

        getElencoAlunni(idCorsoSelezionato, trimestreSelezionato.id);
        getPermessiStato(trimestreSelezionato.id, idCorsoSelezionato);

        setAlertMsg("Riapertura straordinaria dell scrutinio");
        setAlertSeverity("info");
        setAlertOpen(true);

      })
      .catch(function (error) {
        setConfirmDialogRiaperturaOpen(false);
        if (typeof (error?.response?.data) != "string") { //Se nella response non c'è il messaggio di errore
          setAlertMsg(error?.response?.data?.data[0]?.msg);
        } else setAlertMsg("Errore durante la riapertura dello scrutinio");
        setAlertSeverity("error");
        setAlertOpen(true);
      });
  }
  async function getElencoDocumenti() {
    let dataObj = {
      idCorso: idCorsoSelezionato,
    };

    ScrutiniAPI.getDocumentiScrutini(dataObj)
      .then((result) => {
        let documentiTmp = result?.scrutini ?? [];
        documentiTmp.sort((a, b) => {
          return new Date(b.dataScrutinio) - new Date(a.dataScrutinio);
        });
        setDocumentiScrutinio([...documentiTmp]);
      })
      .catch(function (error) {
        setAlertMsg(
          error?.response?.data?.data[0]?.msg ??
          "Al momento non è possibile recuperare la documentazione associata al corso selezionato."
        );
        setAlertSeverity("error");
        setAlertOpen(true);
      });
  }
  async function getPermessiStato(idPeriodo, idCorso) {
    let dataObj = {
      idCorso: idCorso,
      idPeriodo: idPeriodo,
    };

    ScrutiniAPI.getPermessiStatoElencoAlunni(dataObj)
      .then((result) => {
        if (result?.nomeStato !== undefined) setNomeStato(result?.nomeStato);
        else setNomeStato("-");
        if (result?.showIniziaScrutinio !== undefined) {
          setShowIniziaScrutinioAlunno(result?.showIniziaScrutinio)
          setShowIniziaScrutinio(result?.showIniziaScrutinio);
        }
        else {
          setShowIniziaScrutinio(false);
        }
        // setShowIniziaScrutinio(true)

        if (result?.showInserisciVotiCondotta !== undefined)
          setShowInserisciVotiCondotta(result?.showInserisciVotiCondotta);
        else setShowInserisciVotiCondotta(false);

        if (result?.showPreparaScrutinio !== undefined)
          setShowPreparaScrutinio(result?.showPreparaScrutinio);
        else setShowPreparaScrutinio(false);

        if (result?.showPropostaVotoCondottaDocente !== undefined)
          setShowPropostaVotoCondottaDocente(
            result?.showPropostaVotoCondottaDocente
          );
        else setShowPropostaVotoCondottaDocente(false);

        if (result?.showVotiScrutinio !== undefined)
          setShowVotiScrutinio(result?.showVotiScrutinio);
        else setShowVotiScrutinio(false);

        if (result?.riaperturaScrutinio !== undefined)
          setRiaperturaScrutinio(result?.riaperturaScrutinio);
        else setRiaperturaScrutinio(false);

        if (result?.showConcludi !== undefined)
          setShowConcludi(result?.showConcludi);
        else setShowConcludi(false);

        if (result?.idScrutinio !== undefined) {
          setIdScrutinio(result?.idScrutinio);
          if (result?.idScrutinio > 0) {
            setSezioneScrutinioAttiva(true);
          } else setSezioneScrutinioAttiva(false);
        } else setIdScrutinio(null);

        if (result?.isUltimoPeriodo !== undefined)
          setIsUltimoPeriodo(result?.isUltimoPeriodo ?? false);

        setHasError(false);
      })
      .catch(function (error) {
        setHasError(true);
      });
  }


  function returnVotoConverted(voto) {
    //{alunno?.condotta?.motivazione != 0 ? (alunno?.condotta?.motivazione ?? '-') : 'N.V.'}
    if (voto != null && voto !== undefined) {
      if (voto > 0) {
        return voto;
      } else if (voto === 0) {
        return "N.V."
      } else return "/"
    } else return "-"
  }
  function changeColorVoto(voto) {
    if (voto != null && voto !== undefined) {
      if (voto == -1) return "black"
      else if (voto >= 18) {
        return "green"
      } else return "red";
    }
  }

  return (
    <Fragment>
      {hasLoaded ? (
        !hasError ? (
          <Fragment>
            <Breadcrumb sections={["Elenco alunni con riepilogo didattico", nomeClasseSelezionata]} />
            <Grid container sx={{ px: 4 }} spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      ml: 0,
                      mt: { xs: 1, md: 0 },
                      width: { xs: "100%", md: "unset" },
                    }}
                    disabled={isLoadingConclusioneScrutinio}
                    onClick={openCambiaClasse}
                    startIcon={<ArrowBack />}
                  >
                    Torna alla selezione del corso
                  </Button>
                  {showPreparaScrutinio && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }}
                      onClick={changeDialogInserisciVotiOpen}
                      startIcon={<Edit />}
                    >
                      Preparazione scrutinio
                    </Button>
                  )}

                  {idScrutinio === 0 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }}
                      onClick={changeDialogInserisciVotiOpen}
                      startIcon={<Edit />}
                    >
                      {" "}
                      Modifica Preparazione scrutinio
                    </Button>
                  )}
                  {isUltimoPeriodo && (
                    <Button
                      disabled={isLoadingConclusioneScrutinio}
                      variant="outlined"
                      color="success"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }}
                      onClick={openDialogCollegioFormatori}
                      startIcon={<HowToReg />}
                    >
                      Collegio dei formatori
                    </Button>
                  )}
                  {showIniziaScrutinio && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }}
                      onClick={avviaScrutinio}
                    >
                      Inizia scrutinio
                    </Button>
                  )}
                  {(idScrutinio > 0 && riaperturaScrutinio === false && showConcludi === true) && (
                    <Button
                      disabled={scrutinioConcluso || isLoadingConclusioneScrutinio}
                      variant="contained"
                      color="success"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }}
                      onClick={concludiScrutinio}
                    >
                      Concludi scrutinio
                    </Button>
                  )}
                  {riaperturaScrutinio && (
                    <Button
                      startIcon={<Download />}
                      variant="contained"
                      color="success"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }}
                      onClick={openElencoScrutiniDocumentazione}
                    >
                      Scarica documentazione
                    </Button>
                  )}

                  {riaperturaScrutinio && (
                    <Button variant="contained" color="warning"
                      sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: "100%", md: "unset" }, }} onClick={changeOpenDialogRiaperturaScrutinio} >
                      Riapertura straordinaria
                    </Button>
                  )}
                  {showInserisciVotiCondotta && (
                    <Button variant="contained" color="success"
                      sx={{
                        ml: { xs: 0, md: 2 },
                        mt: { xs: 1, md: 0 },
                        width: { xs: "100%", md: "unset" },
                      }} onClick={changeOpenDialogModifica} >
                      Modifica voti condotta proposti
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 3 }}>
                    <Typography variant={mobile ? "caption" : "body2"} >Sede:</Typography>
                    <Typography
                      variant={mobile ? "caption" : "body2"}
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      <b>{nomeSede}</b>
                    </Typography>
                  </Box>

                  <Box sx={{ mr: 3 }}>
                    <Typography variant={mobile ? "caption" : "body2"}>Corso:</Typography>
                    <Typography
                      color={"primary"}
                      variant={mobile ? "caption" : "body2"}
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      {(nomeClasseSelezionata ?? "-").toUpperCase()}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: 3 }}>
                    <Typography variant={mobile ? "caption" : "body2"}>Periodo: </Typography>
                    <Typography
                      variant={mobile ? "caption" : "body2"}
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      <b>
                        {trimestreSelezionato?.nome} (
                        {trimestreSelezionato?.formattedInizio +
                          " - " +
                          trimestreSelezionato?.formattedFine}
                        )
                      </b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={mobile ? "caption" : "body2"}>Stato:</Typography>
                    <Typography
                      color="secondary"
                      variant={mobile ? "caption" : "body2"}
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      {nomeStato}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    mt: 3,
                    mb: 3,
                    width: "100%",
                    maxHeight: { xs: "unset", md: "65vh" },
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            rowSpan={2}
                            className="colonna-nomi row-head"
                            sx={{
                              p: 0,
                              borderTop: "1px solid white",
                              borderRight: "3px solid black",
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              zIndex: 7,
                            }}
                          >
                            <Box
                              sx={{
                                borderTop: "1px solid white",
                                backgroundColor: "white",
                                height: "120px",
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h6">Alunno</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center" rowSpan={2} className="row-head"
                            sx={{ minWidth: "100px", p: 0, borderRight: "3px solid black", borderBottom: "1px solid rgba(224, 224, 224, 1)", zIndex: 6, }}
                          >
                            <Box sx={{ backgroundColor: "white", height: "120px", px: 2, display: "flex", flexDirection: "column", justifyContent: "center", }} >
                              <Typography variant="caption"><b>Ore periodo</b></Typography>
                              <Typography variant="caption">
                                assenze/svolte
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center" rowSpan={2} className="row-head"
                            sx={{ minWidth: "100px", p: 0, borderRight: "3px solid black", borderBottom: "1px solid rgba(224, 224, 224, 1)", zIndex: 6, }}
                          >
                            <Box sx={{ backgroundColor: "white", height: "120px", px: 2, display: "flex", flexDirection: "column", justifyContent: "center", }} >
                              <Typography variant="caption"><b>Ore totali</b></Typography>
                              <Typography variant="caption">
                                assenze/svolte
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            colSpan={
                              !showPropostaVotoCondottaDocente
                                ? materie.length + condotta.length
                                : materie.length + condotta.length * 2
                            }
                            className=" row-head"
                            sx={{ p: 0, borderRight: "3px solid black" }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "#1976d3",
                                height: "50px",
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                color: "white",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                VOTI PROPOSTI
                              </Typography>
                            </Box>
                          </TableCell>
                          {showVotiScrutinio && (
                            <TableCell
                              colSpan={materie.length + condotta.length}
                              className=" row-head"
                              sx={{ p: 0, borderRight: "3px solid black" }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#7300a5",
                                  height: "50px",
                                  px: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  color: "white",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  VOTI SCRUTINIO
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {/* TODO: pulsante accetta Prescrutinio da riattivare */}
                          {/* {showVotiScrutinio && (
                          <TableCell
                            align="center"
                            rowSpan={2}
                            className=" row-head"
                            sx={{
                              p: 0,
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                              width: "120px",
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "rgba(245, 245, 245, 1)",
                                height: "120px",
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            ></Box>
                          </TableCell>
                        )} */}
                          {(showVotiScrutinio && periodoSelezionato === 3 && numericoCorso <= 2) && (
                            <Fragment>
                              <TableCell
                                align="center"
                                rowSpan={2}
                                className=" row-head"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                  width: "120px",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "rgba(245, 245, 245, 1)",
                                    height: "120px",
                                    px: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                  >
                                    PROVA DI PASSAGGIO
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                rowSpan={2}
                                className=" row-head"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                  width: "120px",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "rgba(245, 245, 245, 1)",
                                    height: "120px",
                                    px: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                  >
                                    ESITO ALUNNO
                                  </Typography>
                                </Box>
                              </TableCell>
                            </Fragment>
                          )}
                          {showVotiScrutinio && (
                            <TableCell
                              align="center"
                              rowSpan={2}
                              className=" row-head"
                              sx={{
                                p: 0,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                width: "120px",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "rgba(245, 245, 245, 1)",
                                  height: "120px",
                                  px: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                >
                                  INIZIATO
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                          {showVotiScrutinio && (
                            <TableCell
                              align="center"
                              rowSpan={2}
                              className=" row-head"
                              sx={{
                                p: 0,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                width: "120px",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "rgba(245, 245, 245, 1)",
                                  height: "120px",
                                  px: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                >
                                  CONCLUSO
                                </Typography>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          {materie.length > 0 &&
                            materie.map((materia, index) => (
                              <TableCell
                                key={materia?.idMateria + "materia"}
                                align="center"
                                className=" secondRow-head"
                                sx={{
                                  width: "120px",
                                  p: 0,
                                  borderRight:
                                    index === materie.length - 1
                                      ? "3px solid black"
                                      : "1px solid rgba(224, 224, 224, 1)",
                                  zIndex: 6,
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "white",
                                    height: "70px",
                                    width:
                                      materia?.nomeMateria.length > 40
                                        ? "200px"
                                        : null,
                                    px: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {materia?.nomeMateria}
                                  </Typography>
                                </Box>
                              </TableCell>
                            ))}
                          {condotta.map((testo, index) => (
                            <Fragment key={index + "condotta"}>
                              {showPropostaVotoCondottaDocente && (
                                <TableCell
                                  align="center"
                                  className=" secondRow-head"
                                  sx={{
                                    width: "120px",
                                    p: 0,
                                    borderRight:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    zIndex: 5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "white",
                                      height: "70px",
                                      width: testo.length > 40 ? "150px" : null,
                                      px: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      {testo}
                                    </Typography>
                                    <Typography variant={"caption"}>
                                      (docenti)
                                    </Typography>
                                  </Box>
                                </TableCell>
                              )}

                              <TableCell
                                align="center"
                                className=" secondRow-head"
                                sx={{
                                  width: "120px",
                                  p: 0,
                                  borderRight:
                                    index === condotta.length - 1
                                      ? "3px solid black"
                                      : "1px solid rgba(224, 224, 224, 1)",
                                  zIndex: 5,
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "#dae9f9",
                                    height: "70px",
                                    width: testo.length > 40 ? "150px" : null,
                                    px: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {testo}
                                  </Typography>
                                  {showPropostaVotoCondottaDocente && (
                                    <Typography variant={"caption"}>
                                      {"(" + "proposto" + ")"}
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                            </Fragment>
                          ))}
                          {materie.length > 0 &&
                            showVotiScrutinio &&
                            materie.map((materia, index) => (
                              <TableCell
                                key={materia?.idMateria + "materia2"}
                                align="center"
                                className=" secondRow-head"
                                sx={{
                                  width: "120px",
                                  p: 0,
                                  borderRight:
                                    index === materie.length - 1
                                      ? "3px solid black"
                                      : "1px solid rgba(224, 224, 224, 1)",
                                  zIndex: 6,
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "white"
                                      : "rgba(245, 245, 245, 1)",
                                    height: "70px",
                                    width:
                                      materia?.nomeMateria.length > 40
                                        ? "200px"
                                        : null,
                                    px: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {materia?.nomeMateria}
                                  </Typography>
                                </Box>
                              </TableCell>
                            ))}
                          {condotta.map((testo, index) => (
                            <Fragment key={index + "condotta2"}>
                              {showVotiScrutinio && (
                                <TableCell
                                  align="center"
                                  className=" secondRow-head"
                                  sx={{
                                    width: "120px",
                                    p: 0,
                                    borderRight:
                                      index === condotta.length - 1 &&
                                        !showPropostaVotoCondottaDocente
                                        ? "3px solid black"
                                        : "1px solid rgba(224, 224, 224, 1)",
                                    zIndex: 5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: sezioneScrutinioAttiva
                                        ? "#dae9f9"
                                        : "rgba(245, 245, 245, 1)", // '#dae9f9'
                                      height: "70px",
                                      width: testo.length > 40 ? "150px" : null,
                                      px: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      {testo}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              )}
                            </Fragment>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listAlunniSupporto.map((studente, index) => (
                          <TableRow key={studente?.id + "studente"}>
                            <TableCell
                              className="colonna-nomi"
                              sx={{
                                minWidth: mobile ? "300px" : "400px",
                                p: 0,
                                borderRight: "3px solid black",
                                zIndex: 6,
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "white",
                                  height:
                                    studente?.cognome.length +
                                      studente?.nome.length >
                                      40
                                      ? "100px"
                                      : "80px",
                                  px: 2,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  width={"100%"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Box>
                                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"start"} textAlign={"start"} >
                                      <Typography variant="caption" mr={2} >
                                        {index + 1 + ") "}
                                      </Typography>
                                      <Typography variant="caption" sx={{ mr: 2 }} >
                                        {(studente?.cognome).toUpperCase() + " " + (studente?.nome).toUpperCase()}
                                      </Typography>
                                    </Box>
                                    {studente?.isRitirato !== undefined && studente?.isRitirato === 1 && (
                                      <Box >
                                        <Typography color="primary" variant="subtitle2">Ritirato il {convertDataDiNascita(studente?.iscrittoAl)}</Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  <IconButton
                                    onClick={() => openDialogDettaglio(index)}
                                    sx={{ mr: 2 }}
                                  >
                                    <Search color="primary" />
                                  </IconButton>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              key={studente?.id + "studenteAssenza"}
                              align="center"
                              sx={{ p: 0, borderRight: "3px solid black" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                >
                                  {" "}
                                  {studente?.orePeriodo?.oreAssenza ?? "-"} /{" "}
                                  {studente?.orePeriodo?.oreSvolte ?? "-"}
                                </Typography>
                                <Typography
                                  variant="caption"

                                  fontWeight={
                                    studente?.orePeriodo?.percentuale > 25
                                      ? "bold"
                                      : "normal"
                                  }
                                  color={calcolaColorePercentuale(
                                    studente?.orePeriodo?.percentuale
                                  )}
                                >
                                  {studente?.orePeriodo?.percentuale ?? "-"}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell
                              key={studente?.id + "oreTotali"}
                              align="center"
                              sx={{ p: 0, borderRight: "3px solid black" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"

                                >
                                  {" "}
                                  {studente?.oreTotali?.oreAssenza ?? "-"} /{" "}
                                  {studente?.oreTotali?.oreSvolte ?? "-"}
                                </Typography>
                                <Typography
                                  variant="caption"

                                  fontWeight={
                                    studente?.oreTotali?.percentuale > 25
                                      ? "bold"
                                      : "normal"
                                  }
                                  color={calcolaColorePercentuale(
                                    studente?.oreTotali?.percentuale
                                  )}
                                >
                                  {studente?.oreTotali?.percentuale ?? "-"}
                                </Typography>
                              </Box>
                            </TableCell>

                            {materie.length > 0 &&
                              materie.map((materia, index) => (
                                <TableCell
                                  key={studente?.id + materia?.idMateria}
                                  align="center"
                                  className=" secondRow-head"
                                  sx={{
                                    width: "120px",
                                    p: 0,
                                    borderRight:
                                      index === materie.length - 1
                                        ? "3px solid black"
                                        : "1px solid rgba(224, 224, 224, 1)",
                                    zIndex: 5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "white",
                                      height: "50px",
                                      width:
                                        materia?.nomeMateria.length > 40
                                          ? "200px"
                                          : null,
                                      px: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {studente?.materie.length > 0 &&
                                      studente?.materie.map(
                                        (materiaStudente) =>
                                          materia?.idMateria ===
                                          materiaStudente?.id && (
                                            <Typography
                                              key={
                                                materiaStudente?.id +
                                                materia?.idMateria +
                                                "proposto"
                                              }
                                              variant={
                                                "caption"
                                              }
                                              color={changeColorVoto(materiaStudente?.votoProposto)}

                                              sx={{ fontWeight: "bold" }}
                                            >
                                              {returnVotoConverted(
                                                materiaStudente?.votoProposto
                                              )}
                                            </Typography>
                                          )
                                      )}
                                    {isVotoMateriaNull(studente, materia)}
                                  </Box>
                                </TableCell>
                              ))}

                            {showPropostaVotoCondottaDocente && (
                              <TableCell
                                key={studente?.id + "condottaDocent"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "white",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaDocenti?.motivazione),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaDocenti?.motivazione
                                    )}
                                  </Typography>
                                </Box>
                              </TableCell>
                            )}
                            <TableCell
                              key={studente?.id + "condottaTutorMot"}
                              align="center"
                              sx={{
                                p: 0,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#DAE9F9",
                                  display: "flex",
                                  flexDirection: "column",
                                  px: 2,
                                  height:
                                    studente?.cognome.length +
                                      studente?.nome.length >
                                      40
                                      ? "100px"
                                      : "80px",
                                  color: changeColorVoto(studente?.condottaTutor?.motivazione),
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption">
                                  {returnVotoConverted(
                                    studente?.condottaTutor?.motivazione
                                  )}
                                </Typography>
                              </Box>
                            </TableCell>
                            {showPropostaVotoCondottaDocente && (
                              <TableCell
                                key={studente?.id + "condottaDocentInter"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "white",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaDocenti?.interazione),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaDocenti?.interazione
                                    )}
                                  </Typography>
                                </Box>
                              </TableCell>
                            )}
                            <TableCell
                              key={studente?.id + "condottaDocentInt2"}
                              align="center"
                              sx={{
                                p: 0,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#DAE9F9",
                                  display: "flex",
                                  flexDirection: "column",
                                  px: 2,
                                  height:
                                    studente?.cognome.length +
                                      studente?.nome.length >
                                      40
                                      ? "100px"
                                      : "80px",
                                  color: changeColorVoto(studente?.condottaTutor?.interazione),
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption">
                                  {returnVotoConverted(
                                    studente?.condottaTutor?.interazione
                                  )}
                                </Typography>
                              </Box>
                            </TableCell>
                            {showPropostaVotoCondottaDocente && (
                              <TableCell
                                key={studente?.id + "condottaDocentRel"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "white",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaDocenti?.relazione),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaDocenti?.relazione
                                    )}
                                  </Typography>
                                </Box>
                              </TableCell>
                            )}
                            <TableCell
                              key={studente?.id + "condottaDocentRel2"}
                              align="center"
                              sx={{
                                p: 0,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#DAE9F9",
                                  display: "flex",
                                  flexDirection: "column",
                                  px: 2,
                                  height:
                                    studente?.cognome.length +
                                      studente?.nome.length >
                                      40
                                      ? "100px"
                                      : "80px",
                                  color: changeColorVoto(studente?.condottaTutor?.relazione),
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption">
                                  {returnVotoConverted(
                                    studente?.condottaTutor?.relazione
                                  )}
                                </Typography>
                              </Box>
                            </TableCell>
                            {showPropostaVotoCondottaDocente && (
                              <TableCell
                                key={studente?.id + "condottaDocentMedia"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "white",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaDocenti?.mediaCondotta),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaDocenti?.mediaCondotta
                                    )}
                                  </Typography>
                                </Box>
                              </TableCell>
                            )}
                            <TableCell
                              key={studente?.id + "condottaDocent2"}
                              align="center"
                              sx={{ p: 0, borderRight: "3px solid black" }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#DAE9F9",
                                  display: "flex",
                                  flexDirection: "column",
                                  px: 2,
                                  height:
                                    studente?.cognome.length +
                                      studente?.nome.length >
                                      40
                                      ? "100px"
                                      : "80px",
                                  color: changeColorVoto(studente?.condottaTutor?.mediaCondotta),
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption">

                                  {returnVotoConverted(
                                    studente?.condottaTutor?.mediaCondotta
                                  )}
                                </Typography>
                              </Box>
                            </TableCell>

                            {materie.length > 0 &&
                              showVotiScrutinio &&
                              materie.map((materia, index) => (
                                <TableCell
                                  key={materia?.idMateria + "mat"}
                                  align="center"
                                  className=" secondRow-head"
                                  sx={{
                                    // width: "120px",
                                    p: 0,
                                    borderRight:
                                      index === materie.length - 1
                                        ? "3px solid black"
                                        : "1px solid rgba(224, 224, 224, 1)",
                                    zIndex: 5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: sezioneScrutinioAttiva
                                        ? "white"
                                        : "rgba(245, 245, 245, 1)",
                                      height: "80px",
                                      width:
                                        materia?.nomeMateria.length > 40
                                          ? "200px"
                                          : null,
                                      px: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {studente?.materie.length > 0 &&
                                      studente?.materie.map(
                                        (materiaStudente) =>
                                          materia?.idMateria ===
                                          materiaStudente?.id && (
                                            <Fragment>
                                              <Typography
                                                key={
                                                  materiaStudente?.id +
                                                  materia?.idMateria +
                                                  "3"
                                                }
                                                variant={
                                                  "caption"
                                                }
                                                color={changeColorVoto(materiaStudente?.votoScrutinio)}
                                                sx={{ fontWeight: "bold" }}
                                              >
                                                {returnVotoConverted(
                                                  materiaStudente?.votoScrutinio
                                                )}
                                              </Typography>
                                              {materiaStudente?.is_prescrutinio &&
                                                materiaStudente?.votoScrutinio !=
                                                null && (
                                                  <Typography variant="caption">
                                                    (Prescrutinio)
                                                  </Typography>
                                                )}
                                            </Fragment>
                                          )
                                      )}
                                    {isVotoMateriaNull(studente, materia)}

                                    {/* {!studente?.materie.some(m => m.id === materia?.idMateria) && (
                                                                    <Typography variant={mobile ? 'caption' : 'body1'} sx={{ fontWeight: 'bold' }}>
                                                                        -
                                                                    </Typography>)} */}
                                  </Box>
                                </TableCell>
                              ))}
                            {showVotiScrutinio && (
                              <TableCell
                                key={studente?.id + "condottaScrMot"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box

                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "#dae9f9"
                                      : "rgba(245, 245, 245, 1)",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",

                                    color: changeColorVoto(studente?.condottaScrutinio?.motivazione),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaScrutinio?.motivazione
                                    )}
                                  </Typography>
                                  {studente.condottaScrutinio.is_prescrutinio &&
                                    studente?.condottaScrutinio?.motivazione !=
                                    null && (
                                      <Typography variant="caption">
                                        (Prescrutinio)
                                      </Typography>
                                    )}
                                </Box>
                              </TableCell>
                            )}
                            {showVotiScrutinio && (
                              <TableCell
                                key={studente?.id + "condottaScrInt"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "#dae9f9"
                                      : "rgba(245, 245, 245, 1)",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaScrutinio?.interazione),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaScrutinio?.interazione
                                    )}
                                  </Typography>
                                  {studente.condottaScrutinio.is_prescrutinio &&
                                    studente?.condottaScrutinio?.interazione !=
                                    null && (
                                      <Typography variant="caption">
                                        (Prescrutinio)
                                      </Typography>
                                    )}
                                </Box>
                              </TableCell>
                            )}
                            {showVotiScrutinio && (
                              <TableCell
                                key={studente?.id + "condottaScrRel"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "#dae9f9"
                                      : "rgba(245, 245, 245, 1)",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaScrutinio?.relazione),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaScrutinio?.relazione
                                    )}
                                  </Typography>
                                  {studente.condottaScrutinio.is_prescrutinio &&
                                    studente?.condottaScrutinio?.relazione !=
                                    null && (
                                      <Typography variant="caption">
                                        (Prescrutinio)
                                      </Typography>
                                    )}
                                </Box>
                              </TableCell>
                            )}
                            {showVotiScrutinio && (
                              <TableCell
                                key={studente?.id + "condottaScrMedia"}
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: !showPropostaVotoCondottaDocente
                                    ? "3px solid black"
                                    : "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "#dae9f9"
                                      : "rgba(245, 245, 245, 1)",
                                    display: "flex",
                                    flexDirection: "column",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                    color: changeColorVoto(studente?.condottaScrutinio?.mediaCondotta),
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {returnVotoConverted(
                                      studente?.condottaScrutinio?.mediaCondotta
                                    )}
                                  </Typography>
                                  {studente.condottaScrutinio.is_prescrutinio &&
                                    studente?.condottaScrutinio?.mediaCondotta !=
                                    null && (
                                      <Typography variant="caption">
                                        (Prescrutinio)
                                      </Typography>
                                    )}
                                </Box>
                              </TableCell>
                            )}
                            {/* TODO: pulsante accetta Prescrutinio da riattivare */}
                            {/* {showVotiScrutinio && (
                            <TableCell
                              align="center"
                              sx={{
                                p: 0,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                            
                               <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                sx={{
                                  backgroundColor: sezioneScrutinioAttiva
                                    ? "white"
                                    : "rgba(245, 245, 245, 1)",
                                  px: 2,
                                  height:
                                    studente?.cognome.length +
                                      studente?.nome.length >
                                    40
                                      ? "100px"
                                      : "80px",
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  {!studente?.concluso?.isConcluso && (
                                    <Button
                                      variant="outlined"
                                      sx={{ fontSize: "0.7em" }}
                                      onClick={() =>
                                        accettaPrescrutinio(studente)
                                      }
                                      disabled={studente?.iniziato?.isIniziato}
                                    >
                                      Accetta prescrutinio
                                    </Button>
                                  )}
                                </Box>
                              </Box> 
                            </TableCell>
                          )} */}
                            {(showVotiScrutinio && periodoSelezionato === 3 && numericoCorso <= 2) && (
                              <Fragment>
                                <TableCell
                                  align="center"
                                  sx={{
                                    p: 0,
                                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    sx={{
                                      backgroundColor: sezioneScrutinioAttiva
                                        ? "white"
                                        : "rgba(245, 245, 245, 1)",
                                      px: 2,
                                      height:
                                        studente?.cognome.length +
                                          studente?.nome.length >
                                          40
                                          ? "100px"
                                          : "80px",
                                    }}
                                  >
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      {studente?.provaDiPassaggio !== undefined ? (
                                        <Typography variant="caption" sx={{ color: changeColorVoto(studente?.provaDiPassaggio) }}>
                                          {returnVotoConverted(studente?.provaDiPassaggio)}
                                        </Typography>
                                      ) : (
                                        <Typography variant="caption">-</Typography>
                                      )}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    p: 0,
                                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    sx={{
                                      backgroundColor: sezioneScrutinioAttiva
                                        ? "white"
                                        : "rgba(245, 245, 245, 1)",
                                      px: 2,
                                      height:
                                        studente?.cognome.length +
                                          studente?.nome.length >
                                          40
                                          ? "100px"
                                          : "80px",
                                    }}
                                  >
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      {(studente?.esitoScrutinio !== undefined && studente?.esitoScrutinio != null) ? (
                                        <Typography variant="caption" sx={{ color: studente?.esitoScrutinio === true ? "green" : "red" }}>
                                          {studente?.esitoScrutinio === true ? "Idoneo" : "Non idoneo"}
                                        </Typography>
                                      ) : (
                                        <Typography variant="caption">-</Typography>
                                      )}
                                    </Box>
                                  </Box>
                                </TableCell>
                              </Fragment>
                            )}
                            {showVotiScrutinio && (
                              <TableCell
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "white"
                                      : "rgba(245, 245, 245, 1)",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                  }}
                                >
                                  <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <Checkbox
                                      disabled
                                      checked={studente?.iniziato?.isIniziato}
                                    />
                                    {/* {studente?.iniziato?.dataInizio !== undefined ? (
                                                                    <Typography variant={mobile ? 'caption' : 'body1'}>{studente?.iniziato?.dataInizio !== null ? convertToItalianTime(studente?.iniziato?.dataInizio) : '-'}</Typography>
                                                                ) : (
                                                                    <Typography variant={mobile ? 'caption' : 'body1'}>-</Typography>
                                                                )} */}
                                  </Box>
                                </Box>
                              </TableCell>
                            )}
                            {showVotiScrutinio && (
                              <TableCell
                                align="center"
                                sx={{
                                  p: 0,
                                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  sx={{
                                    backgroundColor: sezioneScrutinioAttiva
                                      ? "white"
                                      : "rgba(245, 245, 245, 1)",
                                    px: 2,
                                    height:
                                      studente?.cognome.length +
                                        studente?.nome.length >
                                        40
                                        ? "100px"
                                        : "80px",
                                  }}
                                >
                                  <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <Checkbox
                                      disabled
                                      checked={studente?.concluso?.isConcluso}
                                    />
                                    {/* {studente?.concluso?.dataConcluso !== undefined ? (
                                                                    <Typography variant={mobile ? 'caption' : 'body1'}>{studente?.concluso?.dataConcluso !== null ? convertToItalianTime(studente?.concluso?.dataConcluso) : '-'}</Typography>
                                                                ) : (
                                                                    <Typography variant={mobile ? 'caption' : 'body1'}>-</Typography>

                                                                )} */}
                                  </Box>
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">
              Al momento non è possibile visualizzare il corso selezionato.
            </Typography>
            <Button
              onClick={openCambiaClasse}
              sx={{ width: { xs: "100%", md: "unset" }, mt: 1 }}
              variant="contained"
              startIcon={<ArrowBack />}
            >
              Torna alla selezione del corso
            </Button>
          </Box>
        )
      ) : (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"90vh"}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
        </Box>
      )}

      <Dialog
        sx={{ marginTop: "7vh" }}
        fullWidth
        maxWidth="xl"
        open={selezionaClasseDialogOpen}
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Seleziona classe e periodo di riferimento</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <SelezioneClassiTutor
            changeIdClasseSelezionata={changeIdClasseSelezionata}
            riepilogoAlunno={true}
            showOnlyPeriodi={true}
            registro={"riepilogoDidatticoAlunni"}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        sx={{ marginTop: "7vh" }}
        open={elencoDocumentiScrutiniOpen}
      >
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }} variant="h6">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Elenco documenti
          </Typography>
          <IconButton onClick={openElencoScrutiniDocumentazione} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ElencoDocumentiScrutinio
            closeDialog={openElencoScrutiniDocumentazione}
            listDocumenti={documentiScrutinio}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button fullWidth variant="contained" color="primary" onClick={openElencoScrutiniDocumentazione}>Chiudi finestra</Button>
        </DialogActions>
      </Dialog>

      <Dialog sx={{ marginTop: "7vh" }} fullScreen open={openDialogModifica}>
        <DialogContent>
          <ModificaVotiCondottaTutor
            closeDialog={changeOpenDialogModifica}
            conferma={changeConfermaModifica}
            nomeCorso={nomeClasseSelezionata}
            listAlunni={listAlunniSupporto}
            tutor={true}
          />
        </DialogContent>
      </Dialog>

      <Dialog sx={{ marginTop: "7vh" }} fullScreen maxWidth="none" open={dettaglioAlunnoOpen}>
        <DialogContent>
          <SchedaAlunno
            isUltimoPeriodo={isUltimoPeriodo}
            showConcludi={showConcludi}
            isScrutinioConcluso={riaperturaScrutinio}
            indexAlunno={indexAlunnoSelezionato}
            listAlunni={listAlunni}
            closeDettaglioSavedScrutinio={closeSchedaAlunnoSavedScrutinio}
            closeDettaglio={closeSchedaAlunno}
            nomeStato={nomeStato}
            nomeCorso={nomeClasseSelezionata}
            nomeSede={nomeSede}
            idCorso={idCorsoSelezionato}
            periodoSelezionato={trimestreSelezionato}
            alunno={alunnoSelezionato}
            showIniziaScrutinio={showIniziaScrutinioAlunno}
            idScrutinio={idScrutinio}
            numericoCorso={numericoCorso}
          />
        </DialogContent>
      </Dialog>

      <Dialog fullScreen sx={{ marginTop: "7vh" }} open={modificaVotiOpen}>
        <DialogContent>
          <InserimentoVotiPrescrutinio
            nomeCorso={nomeClasseSelezionata}
            idCorso={idCorsoSelezionato}
            idPeriodo={trimestreSelezionato?.id}
            listAlunni={listAlunni}
            listMaterie={materie}
            save={changeDialogInserisciVotiOpen}
            onClose={closeDialogInserisciVotiOpen}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="md"
        sx={{ marginTop: "7vh" }}
        open={riaperturaScrutinioDialogOpen}
      >
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }} variant="h6">
          <Typography color="error" variant="h6" sx={{ fontWeight: 'bold' }}>
            Riapertura straordinaria dello scrutinio
          </Typography>
          <IconButton onClick={closeDialogRiaperturaScrutinio} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Typography variant="body1">
            Per poter riaprire lo scrutinio è necessario inserire il{" "}
            <b>Pin di conferma</b> e indicare la{" "}
            <b>motivazione della riapertura straordinaria</b>.
          </Typography>
          <Divider sx={{ my: 2 }} />

          <TextField
            fullWidth
            inputRef={inputMotivazioneRef}
            onChange={changeTestoMotivazione}
            onBlur={changeTestoMotivazioneBlur}
            placeholder="Scrivi qui la motivazione"
            multiline
            rows={4}
            label={"Motivazione"}
            error={isMotivazioneError}

          />
          <Divider sx={{ my: 2 }} />
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
            mt={2}
          >
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Pin di conferma
              </InputLabel>
              <OutlinedInput
                type={showPin ? "text" : "password"}
                inputRef={refPin}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={showPasswordInput}>
                      {showPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={changePin}
                onBlur={changePinBlur}
                // onBlur={changePin}
                label="Pin di conferma"
                error={isPinError}
              />
            </FormControl>
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={"end"}
              mt={{ xs: 2, md: 0 }}
            >
              <Button
                variant="outlined"
                color="error"
                sx={{ ml: { xs: 0, md: 2 } }}
                onClick={closeDialogRiaperturaScrutinio}
              >
                chiudi e annulla
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 } }}
                onClick={openConfirmDialogRiapertura}
              >
                conferma e prosegui
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={confirmDialogRiaperturaOpen}>
        <DialogTitle>Conferma riapertura straordinaria scrutinio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confermi di voler procedere con la riapertura dello scrutinio?
            Questa azione è irreversibile.
          </DialogContentText>
        </DialogContent>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"end"}
          alignItems={"center"}
          m={2}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={openConfirmDialogRiapertura}
            sx={{ ml: { xs: 0, md: 2 } }}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={confermaRiaperturaScrutinio}
            sx={{ ml: { xs: 0, md: 2 } }}
          >
            Conferma e prosegui
          </Button>
        </Box>
      </Dialog>
      <Dialog open={showAccettaPrescrutinio}>
        <DialogTitle>Conferma accettazione prescrutinio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confermi di voler utilizzare i voti espressi durante il prescrutinio
            per lo studente{" "}
            <b>
              {alunnoAccettaPrescrutinio?.cognome +
                " " +
                alunnoAccettaPrescrutinio?.nome}
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions> */}
        <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
          <Button
            variant="outlined"
            color="error"
            onClick={chiudiAccettaPrescrutinio}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={confermaAccettaPrescrutinio}
          >
            Conferma e prosegui
          </Button>
        </Box>
        {/* </DialogActions> */}
      </Dialog>

      <Dialog open={isDialogCollegioFormatoriOpen} fullScreen sx={{ marginTop: "7vh" }}>
        <DialogTitle variant="h6">
          Collegio formatori
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ElencoCollegioFormatori closeDialog={openDialogCollegioFormatori} saveAndCloseCollegioFormatori={saveAndCloseCollegioFormatori}
            idCorso={idCorsoSelezionato} periodo={periodoSelezionato} />
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={10000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>{t("name")} - Elenco alunni con riepilogo didattico</title>
      </Helmet>
    </Fragment>
  );
}
