import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress, DialogContent, DialogTitle, IconButton } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Add, ArrowBack, Clear, Close, Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
// import CryptoJS from 'crypto-js';

import 'react-virtualized/styles.css';

import { RicercaAPI } from '../../../api/ricercaApi';
import Breadcrumb from '../../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../../shared/utilityMethods';
import { TirociniAPI } from '../../../api/tirociniApi';
import TableConvenzioni from './tableConvenzioni';
import DialogConferma from '../../shared/dialogConferma';
import DettaglioConvenzione from './dettaglioConvenzione';

import { useLocation, useNavigate } from "react-router-dom";
import LegendaTirocini from '../legendaTirocini';
import ElencoDocumentiScaricabili from '../elencoDocumentiScaricabili';
import { UtilityAPI } from '../../../api/utilityApi';

export default function GestioneConvenzione(props) {

    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [sediList, setSediList] = useState([]);
    // eslint-disable-next-line no-unused-vars
    // const [sediListExport, setSediListExport] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);
    // const [searchLoaded, setSearchLoaded] = useState(false);


    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [annoFormativo, setAnnoFormativo] = useState("");
    const [annoFormativoPreSelected, setAnnoFormativoPreSelected,] = useState("");

    const [dialogAggiungiAziendaOpen, setDialogAggiungiAziendaOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [idAzienda, setIdAzienda] = useState(-1);
    const [idConvenzione, setIdConvenzione] = useState(-1);

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [ragioneSocialeValid, setRagioneSocialeValid] = useState(true);
    const [ragioneSocialeMessageError, setRagioneSocialeMessageError] = useState('');

    const [nomeRapLegale, setNomeRapLegale] = useState('');
    const [nomeRapLegaleValid, setNomeRapLegaleValid] = useState(true);
    const [nomeRapLegaleMessageError, setNomeRapLegaleMessageError] = useState('');

    const [pIva, setPIva] = useState('');
    const [pIvaValid, setPIvaValid] = useState(true);
    const [pIvaMessageError, setPIvaMessageError] = useState('');

    const [CF, setCF] = useState('');
    const [CFValid, setCFValid] = useState(true);
    const [CFMessageError, setCFMessageError] = useState('');

    const [codAteco, setCodAteco] = useState('');
    const [codAtecoValid, setCodAtecoValid] = useState(true);
    const [codAtecoMessageError, setCodAtecoMessageError] = useState('');

    const [protocolloConvenzione, setProtocolloConvenzione] = useState('');
    const [protocolloConvenzioneValid, setProtocolloConvenzioneValid] = useState(true);
    const [protocolloConvenzioneMessageError, setProtocolloConvenzioneMessageError] = useState('');

    const [sedeSelected, setSedeSelected] = useState(-1);

    const [listConvenzioni, setListConvenzioni] = useState([]);

    const [idSettore, setIdSettore] = useState(-1);
    const [settori, setSettori] = useState([]);

    const [statoConvenzione, setStatoConvenzione] = useState(1); //Impostata di default a Attiva

    const [confirmAlertDeleteIsOpen, setConfirmAlertDeleteIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [pivaAziendaLocationState, setPivaAziendaLocationState] = useState(-1);
    const [addConvenzioneFromAzienda, setAddConvenzioneFromAzienda] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);

    const [documentiConvenzione, setDocumentiConvenzione] = useState([]);
    const [documentiDialogOpen, setDocumentiDialogOpen] = useState(false);
    const [protocolloConvenzioneSelezionata, setProtocolloConvenzoneSelezionata] = useState("");

    const [debugMode, setDebugMode] = useState(false);

    const [isConfermaLoading, setIsConfermaLoading] = useState(false);
    const [listAnniFormativi, setListAnniFormativi] = useState([]);

    useEffect(() => {
        getAnniFormativi();
        // eslint-disable-next-line eqeqeq
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        getDatiPrecompilati()
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        setAnnoFormativoPreSelected(annoFormativoTmp);
        getSedi(annoFormativoTmp);
        if (location.state !== null && location.state.pIva !== undefined) {
            if (location.state.createNew !== undefined && location.state.createNew === true) {
                openAggiungiConvenzione();
                setAddConvenzioneFromAzienda(true)
            } else {
                setAddConvenzioneFromAzienda(false)
            }
            setPIva(location.state.pIva);
            setPivaAziendaLocationState(location.state.pIva); //Se diverso da -1 abilito il pulsante torna indietro
            ricercaConvenzione(annoFormativoTmp, location.state.pIva)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])

    function goBackToGestioneAziende() {
        navigate("/tirocini/gestione-aziende", { state: { pIva: pivaAziendaLocationState } })
    }

    function validate(e) {
        e.preventDefault()
        startSearch()
    }

    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
        }
        else {
            setSedeSelected(-1);
        }
    };

    function changeStatoConvenzione(e) {
        setStatoConvenzione(e.target.value)
    }


    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {

        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse

        setRagioneSociale('');
        setRagioneSocialeValid(true);
        setRagioneSocialeMessageError('');

        setNomeRapLegale('');
        setNomeRapLegaleValid(true);
        setNomeRapLegaleMessageError('');

        setPIva('');
        setPIvaValid(true);
        setPIvaMessageError('');

        setCF('');
        setCFValid(true);
        setCFMessageError('');

        setCodAteco('');
        setCodAtecoValid(true);
        setCodAtecoMessageError('');

        setSedeSelected(-1);
        setIdSettore(-1);

        setAnnoFormativo(annoFormativoPreSelected);

        setSearchStarted(false)
        // setSearchLoaded(false);

        setTimeout(() => {
            setListConvenzioni([]);
            setHasLoaded(false)
        }, 200)
    }

    function openAggiungiConvenzione() {
        setDialogAggiungiAziendaOpen(prev => !prev);
        //setListConvenzioni([]);
        //removeFilters();
    }

    function closeDialogAggiungiConvenzione(idAzienda) { //Alla creazione 
        setDialogAggiungiAziendaOpen(prev => !prev);
        downloadConvenzione(idAzienda, undefined);
    }

    async function getSedi(annoFormativo) {
        setIsConfermaLoading(true)
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setIsConfermaLoading(false)
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])
                // if (result.sedi.length === 1) {
                //     setSedeSelected(result?.sedi[0]?.id ?? -1)
                // }
            })
            .catch(function (error) {
            })


    }

    function changeIdSettore(e) {
        setIdSettore(e.target.value)
    }

    function changeRagioneSociale(e) {
        let ragioneSocialeTmp = e.target.value;
        setRagioneSociale(ragioneSocialeTmp);
        if (ragioneSocialeTmp.length > 2 || ragioneSocialeTmp === "") {
            setRagioneSocialeMessageError('');
            setRagioneSocialeValid(true);
        }
        else {
            setRagioneSocialeValid(false);
            setRagioneSocialeMessageError('Min. 3 caratteri.');
        }
    }

    function changeNomeRapLegale(e) {
        let nomeRapLegaleTmp = e.target.value;
        setNomeRapLegale(nomeRapLegaleTmp)
        if (nomeRapLegaleTmp.length > 2 || nomeRapLegaleTmp === "") {
            setNomeRapLegaleMessageError('');
            setNomeRapLegaleValid(true);
        }
        else {
            setNomeRapLegaleValid(false);
            setNomeRapLegaleMessageError('Min. 3 caratteri.');
        }
    }

    function changeCodAteco(e) {
        let codAtecoTmp = e.target.value;
        setCodAteco(codAtecoTmp)
        if (codAtecoTmp.length > 2 || codAtecoTmp === "") {
            setCodAtecoMessageError('');
            setCodAtecoValid(true);
        }
        else {
            setCodAtecoValid(false);
            setCodAtecoMessageError('Min. 3 caratteri.');
        }
    }

    function changeProtocolloConvenzione(e) {
        let protocolloConvenzioneTmp = e.target.value;
        setProtocolloConvenzione(protocolloConvenzioneTmp)
        if (protocolloConvenzioneTmp.length === 20 || protocolloConvenzioneTmp === "") {
            setProtocolloConvenzioneMessageError('');
            setProtocolloConvenzioneValid(true);
        }
        else {
            setProtocolloConvenzioneValid(false);
            setProtocolloConvenzioneMessageError('Il numero protocollo si compone di 20 caratteri.');
        }
    }

    function changeCF(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCF(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setCFValid(true);
            setCFMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
                setCFValid(true);
                setCFMessageError('')
            } else {
                // setPIvaValid(false);
                setCFValid(false);
                setCFMessageError('Il C.F si compone di 11 o 16 caratteri.')
            }
        }
    }

    function changePIva(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^0-9]/g, '');

        setPIva(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setPIvaValid(true);
            setPIvaMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11) {
                setPIvaValid(true);
                setPIvaMessageError('')
            } else {
                // setPIvaValid(false);
                setPIvaValid(false);
                setPIvaMessageError('La P.Iva si compone di 11 caratteri.')
            }
        }

    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function openVisualizzaDettaglioConvensione(idConvenzione) {
        setIsVisualizza(true);
        setIdConvenzione(idConvenzione);
        setTimeout(openAggiungiConvenzione(), 300);
        removeFilters();
    }

    function changeIdConvenzione(idConvenzione) {
        setIdConvenzione(idConvenzione)
    }
    function closeAlertConfirmDelete() {
        setConfirmAlertDeleteIsOpen(false);
        setIdConvenzione(-1);
    }

    function deleteConvenzione(idConvenzione) {
        setConfirmAlertDeleteIsOpen(true);
        setIdConvenzione(idConvenzione);
    }

    // function copyConvenzione(idConvenzione) {
    //     setIsVisualizza(false);
    //     openVisualizzaDettaglioConvensione(idConvenzione);
    // }

    function copyConvenzione(idConvenzione) {
        setIsVisualizza(false);
        setIdConvenzione(idConvenzione);
        setTimeout(openAggiungiConvenzione(), 300);
    }

    function visualizzaListProgettiConvenzione(protocolloConvenzione) {
        navigate("/tirocini/gestione-progetti-formativi", { state: { protocolloConvenzione: protocolloConvenzione } })
    }

    function addProgettoItem(protocolloConvenzione) {
        navigate("/tirocini/gestione-progetti-formativi", { state: { protocolloConvenzione: protocolloConvenzione, aggiungiProgettoFormativo: true } })
    }

    function changeAnnoFormativo(e) {
        let annoFormativoTmp = e.target.value;
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
    }

    function gestioneFiltroRicerca() {
        // eslint-disable-next-line eqeqeq
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (ragioneSocialeValid &&
                nomeRapLegaleValid &&
                pIvaValid &&
                CFValid &&
                codAtecoValid &&
                protocolloConvenzioneValid
            ) return false
            else return true
        }
        else {
            if (ragioneSocialeValid &&
                nomeRapLegaleValid &&
                pIvaValid &&
                CFValid &&
                codAtecoValid &&
                protocolloConvenzioneValid &&
                (ragioneSociale !== '' ||
                    nomeRapLegale !== '' ||
                    pIva !== '' ||
                    CF !== '' ||
                    codAteco !== '' ||
                    idSettore !== -1 ||
                    protocolloConvenzione !== ''
                )
            ) return false
            else return true
        }
    }

    async function getAnniFormativi() {

        UtilityAPI.getAnniFormativi()
            .then((result) => {
                setListAnniFormativi([...result?.anniFormativi])
            })
            .catch(function (error) {
            })
    }

    function downloadConvenzione(idConvenzione, convenzioneSelezionata) {
        setDocumentiDialogOpen(true);
        setProtocolloConvenzoneSelezionata(convenzioneSelezionata)
        TirociniAPI.getElencoDocumentiConvenzione(idConvenzione)
            .then((result) => {
                setDocumentiConvenzione([...result]);
            })
            .catch(function (error) {
            })
    }

    function openLegendaDialog() {
        setDialogLegendaOpen(prev => !prev);
    }

    async function getDatiPrecompilati() {
        TirociniAPI.getDatiPrecompilati()
            .then((result) => {
                let datiPrecompilatiTmp = result;
                setSettori([...datiPrecompilatiTmp?.settori]);
            })
            .catch(function (error) {
            })

    }


    async function ricercaConvenzione(annoFormativo, pIva) {
        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri

        let obj = {
            annoFormativo: annoFormativo,
            protocolloConvenzione: protocolloConvenzione !== '' ? ragioneSociale : null,
            denominazioneAzienda: ragioneSociale !== '' ? ragioneSociale : null,
            pIva: pIva !== '' ? pIva : null,
            CF: CF !== '' ? CF : null,
            sedeCiof: sedeSelected !== -1 ? sedeSelected : null,
            codiceAteco: codAteco !== '' ? codAteco : null,
            nomeRappresentante: nomeRapLegale !== '' ? nomeRapLegale : null,
            settore: idSettore !== -1 ? idSettore : null,
            stato: statoConvenzione !== -1 ? statoConvenzione : null,
        }
        TirociniAPI.ricercaConvenzione(obj)
            .then((result) => {
                setListConvenzioni([...result]);
                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })

    }

    async function deleteConvenzioneById() {
        setButtonConfermaDisabled(true)
        let obj = {
            idConvenzione: idConvenzione
        }
        TirociniAPI.deleteConvenzioneById(obj)
            .then((result) => {
                setIdConvenzione(-1);
                let msgTmp = result.msg ?? "Convenzione eliminata con successo.";
                openAlertMsg(msgTmp, 'success');
                closeAlertConfirmDelete();
                ricercaConvenzione(annoFormativo, pIva);
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante l'eliminazione della convenzione. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante l'eliminazione delle convenzione. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }



    return (
        <Fragment>

            {!isInsideOtherComponent ? (
                <Breadcrumb sections={["Gestione convenzioni"]} isTirocinio={true} />
            ) : null}


            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca convenzioni</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />

                    </Box>
                    <Divider/>
                    <Collapse in={filtriRicercaNotCollapsed} sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                        <form onSubmit={validate} style={{ width: '100%' }}>
                            <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={ragioneSociale}
                                        value={ragioneSociale}
                                        label={'Ragione sociale azienda'}
                                        onChange={changeRagioneSociale}
                                        sx={{ width: '100%' }}
                                        error={!ragioneSocialeValid}
                                        helperText={ragioneSocialeMessageError}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={nomeRapLegale ?? ''}
                                        value={nomeRapLegale ?? ''}
                                        label={"Nome rappresentante legale"}
                                        onChange={changeNomeRapLegale}
                                        sx={{ width: '100%' }}
                                        error={!nomeRapLegaleValid}
                                        helperText={nomeRapLegaleMessageError}
                                    />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <TextField
                                        error={!pIvaValid ? true : false}
                                        defaultValue={pIva ?? ''}
                                        value={pIva ?? ''}
                                        label={"P.Iva azienda"}
                                        onChange={changePIva}
                                        sx={{ width: '100%' }}
                                        helperText={pIvaMessageError}
                                        inputProps={{ maxLength: 11 }}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        error={!CFValid ? true : false}
                                        defaultValue={CF ?? ''}
                                        value={CF ?? ''}
                                        label={"C.F. azienda"}
                                        onChange={changeCF}
                                        sx={{ width: '100%' }}
                                        helperText={CFMessageError}
                                        inputProps={{ maxLength: 16 }}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={codAteco ?? ''}
                                        value={codAteco ?? ''}
                                        label={"Codice Ateco azienda"}
                                        onChange={changeCodAteco}
                                        sx={{ width: '100%' }}
                                        error={!codAtecoValid}
                                        helperText={codAtecoMessageError}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel>
                                            Settore azienda</InputLabel>
                                        <Select
                                            label='Settore azienda '
                                            value={idSettore ?? -1}
                                            onChange={changeIdSettore}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {settori.map((settore, index) => (
                                                <MenuItem
                                                    key={settore?.id}
                                                    value={settore?.id}
                                                >
                                                    {settore?.descrizione}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="sede-label">
                                            Seleziona sede Ciofs
                                        </InputLabel>
                                        <Select
                                            data-testid='sedeRicercaSelect'
                                            label="Seleziona sede Ciofs"
                                            labelId="sede-label"
                                            value={sedeSelected}
                                            onChange={changeSede}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {sediList.map((sede) => (
                                                <MenuItem
                                                    key={sede.id}
                                                    value={sede.id}
                                                >
                                                    {sede.nomeSede}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="anniFormativi-label">
                                            Anno formativo *
                                        </InputLabel>
                                        <Select
                                            data-testid='anniFormativiSelect'
                                            label="Anno formativo *"
                                            labelId="anniFormativi-label"
                                            value={annoFormativo !== -1 ? annoFormativo : listAnniFormativi[0].id}
                                            onChange={changeAnnoFormativo}
                                        >
                                            {listAnniFormativi.map((anno) => (
                                                <MenuItem
                                                    key={anno.id}
                                                    value={anno.id}
                                                >
                                                    {anno.nomeVisualizzato}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <TextField
                                        defaultValue={protocolloConvenzione ?? ''}
                                        value={protocolloConvenzione ?? ''}
                                        label={"Protocollo convenzione"}
                                        onChange={changeProtocolloConvenzione}
                                        sx={{ width: '100%' }}
                                        error={!protocolloConvenzioneValid}
                                        helperText={protocolloConvenzioneMessageError}
                                        inputProps={{ maxLength: 20 }}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel>
                                            Stato convenzione  </InputLabel>
                                        <Select
                                            label='Stato convenzione'
                                            value={statoConvenzione}
                                            onChange={changeStatoConvenzione}
                                        // disabled={!modifica}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            <MenuItem
                                                key={1}
                                                value={1}
                                            >
                                                Attiva
                                            </MenuItem>
                                            <MenuItem
                                                key={0}
                                                value={0}
                                            >
                                                Non attiva
                                            </MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato (escluso il campo "Stato convenzione").</Alert>
                    </Box>)}
                    {filtriRicercaNotCollapsed && (<Divider />)}

                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', lg: "space-between" } }}>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            {pivaAziendaLocationState !== -1 && (
                                <Button startIcon={<ArrowBack />} sx={{ height: 'fit-content', mr: { xs: 0, lg: 1 }, mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} variant='outlined' onClick={goBackToGestioneAziende}>Torna alla gestione aziende</Button>
                            )}
                            {searchStarted ? (
                                <Button sx={{ height: 'fit-content', mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                    Rimuovi filtri
                                </Button>
                            ) : (
                                <span></span>
                            )}
                        </Box>
                        <Box sx={{ width: { xs: '0px', md: '15px' } }}></Box>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={() => ricercaConvenzione(annoFormativo, pIva)} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca() || isConfermaLoading} variant="contained">
                                Avvia ricerca
                            </Button>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={openAggiungiConvenzione} endIcon={<Add />} color="success" variant="contained">
                                Aggiungi convenzione
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                        {hasLoaded ? (
                            listConvenzioni.length > 0 ?
                                (
                                    <Fragment>
                                        <TableConvenzioni
                                            list={listConvenzioni}
                                            deleteItem={deleteConvenzione}
                                            visualizzaItem={openVisualizzaDettaglioConvensione}
                                            visualizzaListProgettiItem={visualizzaListProgettiConvenzione}
                                            addProgettoItem={addProgettoItem}
                                            copyItem={copyConvenzione}
                                            downloadItem={downloadConvenzione}
                                            openLegendaDialog={openLegendaDialog}
                                        />
                                    </Fragment>
                                )
                                :
                                (
                                    <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Alert sx={{ mb: 2 }} severity="info">La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Alert>
                                    </Box>
                                )

                        ) : (
                            <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress sx={{ mb: 4 }} />
                                <Typography >Caricamento in corso...</Typography>
                            </Box>
                        )}
                        {/* {risultatiRicerca.length > 0 ?
                            (<TreeViewRicerca openDettaglioAlunno={openDettaglioAlunno} risultatiRicerca={risultatiRicerca} showCheckbox={showCheckbox} checkedRow={checkedRow} />)
                            : (<Typography align='center'> La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Typography>)} */}
                    </Paper>
                </Collapse>
            </Container>

            <Dialog fullWidth fullScreen maxWidth={'xs'} open={dialogAggiungiAziendaOpen} sx={{ mt: '8vh' }}>
                <DettaglioConvenzione
                    addConvenzioneFromAzienda={addConvenzioneFromAzienda}
                    pivaAziendaLocationState={pivaAziendaLocationState}
                    closeDialog={openAggiungiConvenzione}
                    closeDialogAggiungiConvenzione={closeDialogAggiungiConvenzione}
                    idConvenzione={idConvenzione}
                    changeIdConvenzione={changeIdConvenzione}
                    isVisualizza={isVisualizza}
                />

            </Dialog>

            <Dialog fullWidth maxWidth={'lg'} open={dialogLegendaOpen} sx={{ mt: '8vh' }} onClose={openLegendaDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Legenda</Typography>
                    <IconButton onClick={openLegendaDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaTirocini data={'convenzioni'} />
                </DialogContent>
            </Dialog>

            <DialogConferma
                isDialogOpen={confirmAlertDeleteIsOpen}
                title={'Conferma eliminazione convenzione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler procedere con l'eliminazione la convenzione? Una volta confermata, l'operazione non potrà essere annullata.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={closeAlertConfirmDelete}
                conferma={deleteConvenzioneById}
                disabledButton={buttonConfermaDisabled}
            />
            {
                !isInsideOtherComponent ? (
                    <Helmet>
                        <title>{t("name")} - Gestione convenzioni</title>
                    </Helmet>
                ) : null
            }

            <Dialog open={documentiDialogOpen} fullWidth maxWidth="md">
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Documenti convenzione</Typography>
                    <IconButton onClick={() => { setDocumentiDialogOpen(false) }}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoDocumentiScaricabili closeDialog={() => { setDocumentiDialogOpen(false) }} listDocumenti={documentiConvenzione} protocolloConvenzioneSelezionata={protocolloConvenzioneSelezionata} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    );
}