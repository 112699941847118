import * as React from 'react';

import { Fragment, useState, useEffect, useRef } from "react"
import { Link, Paper, Button, FormControl, TextField, Box, Collapse, Container, Select, Chip, Typography, Dialog, DialogContent, DialogTitle, IconButton, Breadcrumbs, useTheme, Divider, CircularProgress, DialogActions } from '@mui/material';
import { Download, Warning, Search, Close } from "@mui/icons-material";
import { DateTime } from "luxon";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { ColloquiAPI } from '../../../api/colloquiApi';
import LoadingComponent from '../../shared/loadingComponent';
import { useNavigate, useLocation } from 'react-router-dom';

import { convertDataDiNascita } from '../../shared/metodiDateUtility';
import { RicercaAPI } from '../../../api/ricercaApi';
import Breadcrumb from '../../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../../shared/utilityMethods';

export default function ElencoColloqui(props) {

    const navigate = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();

    const [colloquiInCorso, setColloquiInCorso] = useState([]);
    const [storicoColloqui, setStoricoColloqui] = useState([]);
    const [isOpenDialogSelSede, setIsOpenDialogSelSede] = useState(true);
    const [sede, setSede] = useState(null);
    const [idSede, setIdSede] = useState(0);
    const [nomeSede, setNomeSede] = useState('');
    const [idSedeSelected, setIdSedeSelected] = useState(0);
    const [sediList, setSediList] = useState([]);
    const [annoFormativo, setAnnoFormativo] = useState(''); // TODO: da reimpostare a ''

    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadedSediList, setHasLoadedSediList] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        if (location?.state?.sede !== undefined) {
            let sedeTmp = location.state.sede;
            setSede(sedeTmp);
            setIdSede(sedeTmp.id);
            setNomeSede(sedeTmp.nomeSede);
            setHasLoadedSediList(true);
            setIsOpenDialogSelSede(false);
            getSedi(annoFormativoTmp);
            getRiepilogoColloqui(sedeTmp.id, annoFormativoTmp);
        } else {
            getSedi(annoFormativoTmp);
        }

    }, [])

    useEffect(() => {
        if (props.idSede !== undefined)
            setIdSede(props.idSede)
    }, [props.idSede])

    useEffect(() => {
        if (props.annoFormativo !== undefined)
            setAnnoFormativo(props.annoFormativo)
    }, [props.annoFormativo])

    async function getRiepilogoColloqui(idSede, annoFormativo) {
        const obj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }
        setIsLoading(true);

        ColloquiAPI.riepilogoColloqui(obj)
            .then((result) => {
                let colloquiTmp = result;
                setStoricoColloqui(colloquiTmp.colloqui);
                setColloquiInCorso(colloquiTmp.colloquiOggi);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                }
            })
    }

    async function getSedi(annoFormativo) {

        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setSediList(result.sedi);
                setHasLoadedSediList(true);
                if (result.sedi.length === 1) {
                    setIdSedeSelected(result.sedi[0].id);
                    setSede(result.sedi[0])
                    setIdSede(result.sedi[0].id);
                    setNomeSede(result.sedi[0].nomeSede);
                    setIsOpenDialogSelSede(false);
                    getRiepilogoColloqui(result.sedi[0].id, annoFormativo);
                }
            })
            .catch(function (error) {
            })
    }

    function selectColloquio(colloquio, ordineColloquio) {
        navigate("/colloqui/dettaglio", { state: { colloquio: colloquio, ordineColloquio: ordineColloquio, sede: sede } })
    }

    function storicoColloquiStringSet(date, isInCorso, linkable, ordineColloquio) {
        let dateFormatted = convertDataDiNascita(date);
        let storicoColloquiStringTmp = '';

        switch (isInCorso) {
            case true: storicoColloquiStringTmp =
                // linkable ?
                <Typography variant='body2' >Il colloquio del {dateFormatted} è <b> IN CORSO</b></Typography>
                //     :
                // <Typography variant='body2' >{ordineColloquio + '° colloquio programmato il ' + dateFormatted}</Typography>
                break;
            case false: storicoColloquiStringTmp =
                linkable ?
                    <Typography variant='body2' >{'Visualizza colloquio del ' + dateFormatted}</Typography>
                    :
                    <Typography variant='body2' >{ordineColloquio + '° colloquio programmato il ' + dateFormatted}</Typography>
                break;
            default: storicoColloquiStringTmp = ''
        }

        return storicoColloquiStringTmp;
    }

    function goToHome() {
        navigate("/")
    }

    function confirmSede() {
        setIdSede(idSedeSelected);
        setNomeSede(sede.nomeSede);
        setIsOpenDialogSelSede(false);
        getRiepilogoColloqui(idSedeSelected, annoFormativo);
    }

    function changeIdSede(sede) {
        setSede(sede)
        setIdSedeSelected(sede.id);
    }

    function openDialogSelSede() {
        setIsOpenDialogSelSede(true);
    }

    return (
        <Fragment>
            {idSede !== 0 && (<Box>
                <Breadcrumb sections={["Modulo colloqui", "Annotazioni colloquio"]} />

                {!isLoading ? (

                    <Box sx={{ px: { xs: 2, md: 5 } }}>

                        <Box sx={{ my: 2, display: "flex", alignItems: 'center' }}>
                            <FormControl>
                                <TextField value={nomeSede} disabled label="Sede" />
                            </FormControl>
                            {sediList.length > 1 && (<Button onClick={() => openDialogSelSede()} sx={{ ml: 2 }} variant="contained" >Cambia sede</Button>)}
                        </Box>
                        <Typography variant="h4" my={2}>Colloqui in corso</Typography>
                        <Box sx={{ p: 2 }}>
                            {colloquiInCorso.length > 0 ? (<Box>
                                {colloquiInCorso.map((colloquio, index) =>
                                    <Fragment key={"in-corso" + index}>
                                        <Box display={"flex"} alignItems={"center"} my={2}>
                                            {!mobile ? (
                                                <Box onClick={() => selectColloquio(colloquio, 0)}>
                                                    <Chip sx={{ p: 1, mr: 1, cursor: 'pointer' }} color="primary" variant="outlined" label={'Visualizza colloquio'} icon={<Search />} />
                                                </Box>
                                            ) : (
                                                <IconButton onClick={() => selectColloquio(colloquio, 0)} color="primary" sx={{ mr: 2, border: '1px solid' }}><Search /></IconButton>
                                            )}
                                            <Typography>{colloquio?.codiceCorso + ' - ' + colloquio?.nomeCorso + ' (' + colloquio?.CodSimon + ')'}</Typography>
                                            {/* <Chip sx={{ p: 1, ml: 1, cursor: 'pointer' }} color="primary" variant="outlined" label={'Visualizza colloquio'} icon={<Search />} /> */}
                                        </Box>
                                        {(index + 1) < storicoColloqui.length && (
                                            <Divider />
                                        )}
                                    </Fragment>
                                )}
                            </Box>) : (
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} sx={{ p: 2 }}>
                                    <Typography>Non ci sono colloqui in corso.</Typography>
                                </Box>
                            )}
                        </Box>

                        <Typography variant="h4" my={2}>Storico colloqui</Typography>
                        <Box sx={{ p: 2 }}>
                            {storicoColloqui.length > 0 ? (<Box>
                                {storicoColloqui.map((colloquio, index) => (
                                    <Fragment key={"storico" + index} >
                                        <Box marginY={2}>
                                            <Typography>{colloquio?.codiceCorso + ' - ' + colloquio?.nomeCorso + ' (' + colloquio?.CodSimon + ')'}</Typography>
                                            {/* <Button>visualizza colloquio del {colloquio}</Button> */}
                                            <Box sx={{ display: "flex", ml: 3 }}>
                                                <Box sx={{ borderRight: '1px dotted #282828' }}> </Box>
                                                <Box>
                                                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 1.5 }} onClick={() => colloquio?.primoColloquio?.data !== null && colloquio?.primoColloquio?.linkable && !colloquio?.primoColloquio?.isInCorso ? selectColloquio(colloquio, 1) : null}>
                                                        <Box sx={{ width: '20px', borderBottom: '1px dotted #282828' }}> </Box>
                                                        <Chip disabled={colloquio?.primoColloquio?.data === null || !colloquio?.primoColloquio?.linkable || colloquio?.primoColloquio?.isInCorso} sx={{ p: 1, ml: 1, cursor: 'pointer' }} color="primary" variant="outlined"
                                                            label={colloquio?.primoColloquio?.data !== null ?
                                                                storicoColloquiStringSet(colloquio?.primoColloquio?.data, colloquio?.primoColloquio?.isInCorso, colloquio?.primoColloquio?.linkable, 1)
                                                                : '1° colloquio non programmato'}
                                                            icon={colloquio?.primoColloquio?.data !== null && colloquio?.primoColloquio?.linkable && !colloquio?.primoColloquio?.isInCorso ? <Search /> : null} />
                                                    </Box>
                                                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 1.5 }} onClick={() => colloquio?.secondoColloquio?.data !== null && colloquio?.secondoColloquio?.linkable && !colloquio?.secondoColloquio?.isInCorso ? selectColloquio(colloquio, 2) : null}>
                                                        <Box sx={{ width: '20px', borderBottom: '1px dotted #282828' }}> </Box>
                                                        <Chip disabled={colloquio?.secondoColloquio?.data === null || !colloquio?.secondoColloquio?.linkable || colloquio?.secondoColloquio?.isInCorso} sx={{ p: 1, ml: 1, cursor: 'pointer' }} color="primary" variant="outlined"
                                                            label={colloquio?.secondoColloquio?.data !== null ?
                                                                storicoColloquiStringSet(colloquio?.secondoColloquio?.data, colloquio?.secondoColloquio?.isInCorso, colloquio?.secondoColloquio?.linkable, 2)
                                                                : '2° colloquio non programmato'}
                                                            icon={colloquio?.secondoColloquio?.data !== null && colloquio?.secondoColloquio?.linkable && !colloquio?.secondoColloquio?.isInCorso ? <Search /> : null} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {(index + 1) < storicoColloqui.length && (
                                            <Divider />
                                        )}
                                    </Fragment>
                                ))}
                            </Box>
                            ) : (
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} sx={{ p: 2 }}>
                                    <Typography>Non ci sono colloqui programmati.</Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <LoadingComponent isLoading={isLoading} timeoutLoading={1500} testoCaricamento={"Caricamento colloqui in corso..."} />
                )}
            </Box>)}
            {hasLoadedSediList ? (<Dialog fullWidth maxWidth={"xs"} open={isOpenDialogSelSede} sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>{'Seleziona la sede'}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {sediList.length > 0 ? (
                        <Fragment>
                            <Box sx={{ height: '35vh', overflowY: 'auto' }}>

                                {sediList.map((sede, index) => (
                                    sede != null ? (
                                        <Box key={sede?.id + '_sede'} sx={{ cursor: 'pointer', }}
                                            onClick={() => changeIdSede(sede)}
                                        >
                                            <Box sx={{ minWidth: '200px', background: (sede?.id === idSedeSelected) ? '#1976D2' : 'white', color: (sede?.id === idSedeSelected) ? 'white' : 'black', py: 1, paddingLeft: 1, borderBottom: index + 1 === sediList.length ? '' : 'solid rgba(224, 224, 224, 1) 1px' }}>
                                                <Typography>
                                                    {sede?.nomeSede}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Typography textAlign={"center"} color="red">
                                                Non sono presenti sedi disponibili alla consultazione.
                                            </Typography>
                                        </Box>
                                    )
                                ))}
                            </Box>
                            {/* <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                            <Button sx={{ mt: 2 }} onClick={confirmSede} variant="contained" fullWidth color="success" disabled={idSedeSelected === 0}>Conferma e prosegui</Button> */}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Typography textAlign={"center"} color="red">Non sono presenti sedi disponibili alla consultazione.</Typography>
                            {/* <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button> */}
                        </Fragment>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    {sediList.length > 0 ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                            <Button onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                            <Button sx={{ mt: 2 }} onClick={confirmSede} variant="contained" fullWidth color="success" disabled={idSedeSelected === 0}>Conferma e prosegui</Button>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                            <Button onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                        </Box>
                    )}
                </DialogActions>
            </Dialog>) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100vh"}>
                    <CircularProgress />
                </Box>
            )}

            <Helmet>
                <title>{t("name")} - Elenco colloqui</title>
            </Helmet>
        </Fragment>
    )
}