import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

//{ sendToken: true } sulle chiamate che vogliono l'autenticazione


export const RicercaAPI = {

    getSedi: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/sedi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getClassiBySede: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/corsiSedeAnno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getAlunni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/ricerca`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getMaterieByClasse: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/elencoMateriePerCorso`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDocenti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/docenti/ricerca`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getAlunniBes: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/ricerca`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(RicercaAPI)