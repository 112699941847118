import React from 'react';
import { useState, useEffect, Fragment, useRef } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Skeleton, Button, Dialog, DialogContent, DialogTitle, Alert, IconButton, Divider, Snackbar, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso'
import { EventiAPI } from '../../../../api/eventiApi';
import { convertDataDiNascita } from '../../../shared/metodiDateUtility';
import DettaglioExAnte from './dettaglioExAnte';
import { Add, Close, Edit, Visibility } from '@mui/icons-material';
import AggiuntaExante from './aggiuntaExAnte';
import { getAnnoFormativo } from '../../../shared/utilityMethods';

export default function ExAnte(props) {

    const tableVirtuoso = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [annoFormativo, setAnnoFormativo] = useState("20232024");
    const [idAlunno, setIdAlunno] = useState(-1);
    const [nomeAlunno, setNomeAlunno] = useState("");

    const [idDocente, setIdDocente] = useState(-1)
    const [idCorso, setIdCorso] = useState(-1)
    const [isAggiungiDialogOpen, setIsAggiungiDialogOpen] = useState(false);

    const [isDettaglioDialogOpen, setIsDettaglioDialogOpen] = useState(false)
    const [objVotoSelezionato, setObjVotoSelezionato] = useState(undefined)
    const [indexRowVotoSelezionato, setIndexRowVotoSelezionato] = useState(-1);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [erroMsg, setErrorMsg] = useState("Errore durante il recupero dei voti.");

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [listVoti, setListVoti] = useState([]);
    const [objPermessiExAnte, setObjPermessiExAnte] = useState({
        insertExAnte: true,
        prerequisitiPassaggio: false,
        prerequisitiRecupero: false
    })
    const [showInserisciVoto, setShowInserisciVoto] = useState(false);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };



    useEffect(() => {
        if (props.nomeAlunno !== undefined)
            setNomeAlunno(props.nomeAlunno);
    }, [props.nomeAlunno])

    useEffect(() => {
        if (props.idDocente !== undefined)
            setIdDocente(props.idDocente)
    }, [props.idDocente])

    useEffect(() => {
        if (props.idAlunno !== undefined) {
            let annoFormativoTmp = getAnnoFormativo();
            setAnnoFormativo(annoFormativoTmp);
            setIdAlunno(props.idAlunno);
            getVotiExAnte(props.idAlunno, annoFormativoTmp)
        }
    }, [props.idAlunno])

    useEffect(() => {
        if (props.idCorso !== undefined) {
            setIdCorso(props.idCorso);
        }
    }, [props.idCorso])

    function closeDialogDettaglio() {
        getVotiExAnte(idAlunno, annoFormativo);
        setIsDettaglioDialogOpen(false);

    }

    function openDialogAggiunta() {
        setIsAggiungiDialogOpen(true)
    }
    function closeDialogAggiunta() {
        setIsAggiungiDialogOpen(false);
    }
    function closeAlert() {
        setAlertIsOpen(false);
    }

    function votoAggiunto() {
        closeDialogAggiunta();
        getVotiExAnte(idAlunno, annoFormativo);

        setAlertIsOpen(true);
        setAlertMsg("Ex ante aggiunto correttamente");
        setAlertSeverity("success")
    }

    function exAnteModificato() {
        closeDialogDettaglio();
        getVotiExAnte(idAlunno, annoFormativo);

        setAlertMsg("Ex ante modificato correttamente");
        setAlertSeverity("success");
        setAlertIsOpen(true);

    }

    function exAnteEliminato(msg, severity) {
        closeDialogDettaglio();
        getVotiExAnte(idAlunno, annoFormativo);

        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertIsOpen(true);
    }

    async function getVotiExAnte(idAlunnoTmp, annoFormativoTmp) {
        let dataObj = {
            annoFormativo: annoFormativoTmp,
            idAlunno: idAlunnoTmp
        }
        setHasLoaded(false)
        EventiAPI.getElencoExAnteAlunno(dataObj)
            .then((result) => {

                setObjPermessiExAnte({
                    insertExAnte: result?.insertExAnte ?? true,
                    prerequisitiPassaggio: result?.prerequisitiPassaggio ?? false,
                    prerequisitiRecupero: result?.prerequisitiRecupero ?? false
                })
                setDataInizioPeriodo(result.dataInizioPeriodo)
                setDataFinePeriodo(result.dataFinePeriodo)
                setShowInserisciVoto(result?.showInserisciVoto);
                setHasError(false);
                let listVotiTmp = result?.comunicazioni;
                listVotiTmp.sort((a, b) => {
                    let dateA = new Date(a.data_comunicazione), dateB = new Date(b.data_comunicazione);
                    return dateB - dateA; // sort in descending order
                });
                setListVoti([...listVotiTmp]);
                setHasLoaded(true)
                if (indexRowVotoSelezionato !== -1) {
                    setTimeout(() => {
                        if (tableVirtuoso.current) {
                            tableVirtuoso.current.scrollToIndex({
                                index: indexRowVotoSelezionato,
                                behavior: "instant"
                            })
                        }
                    }, 200)
                }
            })
            .catch((error) => {
                /*MOSTRARE ERRORE */
                setErrorMsg(error?.response?.data?.data[0]?.msg ?? "Errore durante il recupero dei voti.")
                setHasLoaded(true);
                setHasError(true);
            })

    }

    function fixedHeaderContent() {
        return (
            <TableRow>
                <TableCell variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    {!isMobile ? (<b>Data inserimento</b>) : (<b>Data</b>)}
                </TableCell>
                <TableCell variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    <b>Docente</b>
                </TableCell>
                <TableCell variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    <b>Materia</b>
                </TableCell>
                <TableCell align="center" variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    <b>Voto</b>
                </TableCell>
                <TableCell align="center" variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    {/*AZIONI*/}
                </TableCell>
            </TableRow>
        );
    }
    function openDettaglioVoto(obj, index) {
        setIsDettaglioDialogOpen(true);
        setObjVotoSelezionato(obj);
        setIndexRowVotoSelezionato(index);

        setTimeout(() => {
            if (tableVirtuoso.current) {
                tableVirtuoso.current.scrollToIndex({
                    index: index,
                    behavior: "instant"
                })
            }
        }, 200)
    }

    function rowContent(index, voto) {
        return (
            <Fragment>
                <TableCell >
                    {convertDataDiNascita(voto?.data_comunicazione)}
                </TableCell>
                <TableCell  >
                    {voto?.contenuto?.docente?.cognomeDocente ?? "-"}   {voto?.contenuto?.docente?.nomeDocente ?? "-"}
                </TableCell>
                <TableCell  >
                    {!isMobile ? (
                        voto?.contenuto?.materia?.nomeMateria ?? "-"
                    ) : (
                        voto?.contenuto?.materia?.nomeMateriaBreve + "..."
                    )}
                </TableCell>
                <TableCell align="center">
                    {voto?.contenuto?.voto ?? "-"}
                </TableCell>
                <TableCell>
                    {!isMobile ? (
                        <Button size='small' fullWidth variant={voto?.contenuto?.editable ? "contained" : "outlined"} endIcon={voto?.contenuto?.editable ? <Edit /> : <Visibility />}
                            onClick={() => openDettaglioVoto(voto, index)}>
                            {voto?.contenuto?.editable ? "Modifica " : "Dettaglio"}
                        </Button>
                    ) : (
                        <IconButton color="primary" sx={{ border: '1px solid'}}  onClick={() => openDettaglioVoto(voto, index)}>
                            {voto?.contenuto?.editable ? <Edit /> : <Visibility />}
                        </IconButton>
                    )}
                </TableCell>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    {showInserisciVoto && (
                        <Button onClick={openDialogAggiunta} endIcon={<Add />} fullWidth variant="contained" color="success" sx={{ mb: 2 }}>
                            Aggiungi nuovo ex ante
                        </Button>
                    )}
                    <Paper sx={{ height: 400, width: '100%' }}>
                        {hasError ? (
                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Alert severity="error">{erroMsg}</Alert>
                            </Box>
                        ) : (
                            <TableVirtuoso
                                ref={tableVirtuoso}
                                data={listVoti}
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={fixedHeaderContent}
                                itemContent={rowContent}
                            />
                        )}
                    </Paper>

                </Fragment>
            ) : (
                <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress sx={{ mb: 4 }} />
                    <Typography >Caricamento in corso...</Typography>
                </Box>
            )}
            <Dialog open={isDettaglioDialogOpen} fullWidth maxWidth="md">
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Dettaglio Ex ante ({nomeAlunno})
                    </Typography>
                    <IconButton onClick={closeDialogDettaglio} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DettaglioExAnte annoFormativo={annoFormativo} objPermessiExAnte={objPermessiExAnte} idAlunno={idAlunno} cantModify={objVotoSelezionato?.contenuto?.editable ?? false} exAnteEliminato={exAnteEliminato}
                        comunicazione={objVotoSelezionato} closeDialogDettaglio={closeDialogDettaglio} exAnteModificato={exAnteModificato} />
                </DialogContent>
            </Dialog>

            <Dialog open={isAggiungiDialogOpen} fullWidth maxWidth="md" sx={{mt:'8vh'}}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Aggiunta nuovo ex ante
                    </Typography>
                    <IconButton onClick={closeDialogAggiunta} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <AggiuntaExante annoFormativo={annoFormativo} dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} objPermessiExAnte={objPermessiExAnte} idAlunno={idAlunno} idDocente={idDocente} idCorso={idCorso} closeDialog={closeDialogAggiunta}
                        isDialog={true} alunniSelezionati={[{ id: idAlunno, nome: nomeAlunno }]} votoAggiunto={votoAggiunto} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}