import { Box, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, Breadcrumbs, Link } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"
import ElencoAlunniSelezionabili from "../elencoAlunniSelezionabili";

import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { DateTime, Interval } from "luxon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import { RegistroAPI } from "../../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../../shared/metodiDateUtility";
import { EventiAPI } from "../../../api/eventiApi";
import { PresenzeAPI } from "../../../api/presenzeApi";
import { RicercaAPI } from "../../../api/ricercaApi";
import DialogConferma from "../../shared/dialogConferma";
import { Check } from "@mui/icons-material";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";

export default function AggiuntaNota(props) {

    const [categorieList, setCategorieList] = useState([]);

    const inputNotaRef = useRef(null);

    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [data, setData] = useState(null);

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)

    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    const [categoriaSelected, setCategoriaSelected] = useState(-1);

    const [testoNota, setTestoNota] = useState("");
    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)
    const [dataDiInteresse, setDataDiInteresse] = useState(null);
    const [hasDataFinePeriodo, setHasDataFinePeriodo] = useState(false);


    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        setDataInizioPeriodo(inizioPeriodoTmp ?? null);
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
        setDataFinePeriodo(finePeriodoTmp ?? null);
        setHasDataFinePeriodo(!objDateInteresse?.isAnnoFormativoCorrente ?? false)
        getCategorieNota()
    }, [])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.alunniSelezionati !== undefined && props.dataRichiesta !== undefined) {
            let objDateInteresse = getDataInizioFinePeriodo(DateTime.fromSQL(props.dataRichiesta));
            let dataRichiesta = objDateInteresse.dataDiInteresse;
            getAlunniByClasseID(props.idCorso, props.idSede, props.alunniSelezionati, dataRichiesta);

            setData(dataRichiesta)
            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            if (props.idMateria != undefined)
                setIdMateria(props.idMateria)
            if (props.idMateria !== -1) //Se è -1 allora sto aggiungendo una nota ma non sono docente
                getOreLezione(dataRichiesta, props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati);
        }
    }, [props.idCorso, props.idSede, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.dataRichiesta]);

    useState(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
        }
    }, [props.nota]);


    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    async function getCategorieNota() {

        EventiAPI.getCategorieNota()
            .then((result) => {
                setCategorieList([...result])
            })
            .catch((error) => {
                if (error.response) { }
            })
    }

    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, listAlunniTmp, alunniSelezionatiTmp) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)

        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            idMateria: idMateria
        }

        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                getAlunniPresenti(listAlunniTmp, dataTmp, alunniSelezionatiTmp);
            })
            .catch((error) => {
            })
    }



    async function insertNota() {


        setPulsanteConfermaDisabled(true);


        let alunniSelezionatiId = []
        for (let a of alunniSelezionati) {
            alunniSelezionatiId.push(a.id)
        }

        let dataTmp = data;
        let formattedData = convertDataGetProssimeLezione(dataTmp, true)



        const obj = {
            note: testoNota,
            idCategoria: categoriaSelected,
            idAlunno: alunniSelezionatiId,
            data: formattedData,
            is_interaClasse: alunniSelezionati.length === alunniSelezionabiliNumber ? true : false,
            idClasse: idCorso,
            idUtente: idDocente
        }

        EventiAPI.insertNota(obj)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Nota aggiunta", "success");
                } else {
                    setAlunniSelezionati([]);
                    setTestoNota("");

                    if (inputNotaRef != null)
                        inputNotaRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Nota aggiunta");
                    setAlertIsOpen(true);
                }
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setConfirmAlertIsOpen(false);

            })
            .catch((error) => {
                if (error.response) { }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");
                let errorTmp = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione"
                setAlertMsg(errorTmp);
                setAlertIsOpen(true);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)

            })

    }


    function getAlunniByClasseID(idCorso, idSede, alunniSelezionati, dataRichiestaTmp) { //Viene chiamato solo dal dettaglio alunno
        let dataObj = {
            idSede: idSede,
            idCorso: idCorso,
        }

        RicercaAPI.getAlunni(dataObj)
            .then((result) => {
                let risultati = result.sedi[0]?.corsi[0]?.alunni;
                let newRisultati = [];
                if (risultati !== undefined) {
                    for (let alunno of risultati) {
                        let newObj = {
                            id: alunno?.idAlunno,
                            nome: alunno?.nomeAlunno,
                            cognome: alunno?.cognomeAlunno,
                            iscrittoDal: alunno?.corsoDal ?? null,
                            iscrittoAl: alunno?.corsoAl ?? null
                        }
                        newRisultati.push(newObj);
                    }
                    getAlunniPresenti(newRisultati, dataRichiestaTmp, alunniSelezionati);
                }
                setListAlunni([...newRisultati]);
                setGetAlunniIsLoading(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setGetAlunniIsLoading(false);
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }



    function selectAlunni(listAlunni) {
        if (listAlunni !== undefined)
            setAlunniSelezionati([...listAlunni]);
    }
    function changeTestoNota(e) {
        let value = e.target.value;

        if (value.startsWith(' ')) {
            value = value.substring(1);
        }
        //inputNotaRef.value = value;
        setTestoNota(value)
    }


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        //setAlunniSelezionati([])
        setData(value);
        if (idMateria !== -1)
            getOreLezione(value, idCorso, idDocente, idMateria, listAlunni, alunniSelezionati);
        else getAlunniPresenti(listAlunni, value, alunniSelezionati);
        //isAlunnoIscrittoInData(value, listAlunni);
    }


    function changeCategoria(e) {
        setCategoriaSelected(e.target.value)
    }

    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp ?? []; // setAlunniNonSelezionabili([...listTmp]);
        } else return []
    }
    async function getAlunniPresenti(alunniTmp, dataRichiesta, alunniSelezionati) {

        let dataSql = convertToSqlFormat(dataRichiesta);
        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataObj = {
            "data": dataSql,
            "idAlunno": idAlunni
        }
        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                let alunniNonSelezionabiliTmp = [];
                for (let alunno of result.alunni) {
                    if (alunno?.stato_presenza === "A") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);
                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        alunniNonSelezionabiliTmp.push(objAlunno)
                    }
                }
                let luxonDataRichiesta = DateTime.fromISO(dataRichiesta);
                let newAlunni = isAlunnoIscrittoInData(luxonDataRichiesta, alunniTmp);
                for (let alunno of newAlunni) {
                    alunniNonSelezionabiliTmp.push(alunno);
                }

                setAlunniNonSelezionabili([...alunniNonSelezionabiliTmp]);
                let arrayTmp = alunniNonSelezionabiliTmp;
                let arraySelTmp = []


                let alunniSelezionabiliNumber = alunniTmp.length - alunniNonSelezionabiliTmp.length
                if (alunniSelezionabiliNumber < 0)
                    alunniSelezionabiliNumber = 0;

                setAlunniSelezionabiliNumber(alunniSelezionabiliNumber)
                for (let alunno of alunniSelezionati) {
                    if (!arrayTmp.includes(alunno) && !alunniNonSelezionabiliTmp.some(a => a.id === alunno.id)) arraySelTmp.push(alunno);
                }
                setAlunniSelezionati([...arraySelTmp]);
                selectAlunni(arraySelTmp);

            })
            .catch((error) => {
            })

    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">Nota disciplinare</Typography>
                    </Breadcrumbs>) : null}
                <Typography variant="h4" mt={1}>Aggiungi nota disciplinare</Typography>
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili checkAssenze={true} selectAlunni={selectAlunni} alunniSelezionabiliNumber={alunniSelezionabiliNumber}
                                        listAlunniSelezionati={alunniSelezionati} alunniNonSelezionabili={alunniNonSelezionabili} listAlunni={listAlunni} />
                                ) : (
                                    <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Box>
                                    <Grid container rowSpacing={2} columnSpacing={2}
                                    // sx={{ width: '100%', pt: 2 }}
                                    >
                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                                <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                                    minDate={dataInizioPeriodo}
                                                    maxDate={hasDataFinePeriodo ? dataFinePeriodo : DateTime.now()}
                                                    label="Data"
                                                    value={data}
                                                    format="dd/MM/yyyy"
                                                    onChange={(value) => changeData(value)}
                                                    dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                            </LocalizationProvider>
                                        </Grid>


                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="categoria-label">
                                                    {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                                    Categoria</InputLabel>
                                                <Select
                                                    data-testid='selCategoriaNotaSelect'
                                                    label='Categoria '
                                                    labelId="materia-label"
                                                    value={categoriaSelected}
                                                    onChange={changeCategoria}
                                                    style={{ maxWidth: '100vw' }}

                                                >
                                                    <MenuItem
                                                        key={-1}
                                                        value={-1}
                                                    >
                                                    </MenuItem>
                                                    {categorieList.map((categoria) => (
                                                        <MenuItem style={{ whiteSpace: 'normal' }}
                                                            key={categoria.id}
                                                            value={categoria.id}
                                                        >
                                                            {categoria.descrizione}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                    <TextField fullWidth sx={{ mt: 1 }} inputRef={inputNotaRef} value={testoNota}
                                        onChange={changeTestoNota} placeholder="Scrivi qui..." multiline rows={4} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                            {isDialog ? (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                            ) : null}
                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={testoNota === "" || alunniSelezionati.length === 0 || categoriaSelected === -1} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                color="success" variant="contained" >
                                Aggiungi nota disciplinare
                            </Button>
                        </Box>
                    </Fragment>
                ) : null}

            </Container>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={"Conferma aggiunta nota disciplinare"}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={'Confermi di voler aggiungere una nota disciplinare a'}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={insertNota}
                disabledButton={pulsanteConfermaDisabled}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}

