import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

//{ sendToken: true } sulle chiamate che vogliono l'autenticazione


export const RegistroAPI = {

    getPercentualeOreFirma: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/firma/orePercentuali`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    showPulsantiEvento: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/showPulsantiEvento`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deleteEvento: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/eliminaEvento`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRiepilogoFormativo: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/riepilogoAttivitaFormativa`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRiepilogoFormativoAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/riepilogoFormativoAlunno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRegistroDiClasse: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/classe/giornaliero`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRegistroDocente: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/docente/giornaliero`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRegistroDocenteDiSintesi: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/docente/sintesi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRegistroDiSintesi: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/classe/sintesi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getOrarioDocente: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/orarioDocente`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getProssimeLezioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/prossimeLezioni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    checkOraFirmata: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/firma/check`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    firmaOra: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/firma/inserisci`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    modificaFirmaOra: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/firma/modifica`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoClassiTutor: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/elencoClassiTutor`,
            method: 'POST',
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getPermessiRE: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/verificaPermessiRE`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoClassiDocente: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/elencoClassiDocente`,
            method: 'POST',
            data:obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoMateriePerClasseDocente: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/elencoMateriePerClasseDocente`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoMaterieCorsoPeriodoDocente: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registro/elencoMaterieCorsoPeriodoDocente`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoOreGiornata: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/firma/elencoOreGiorno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    
}
const cancelApiObject = defineCancelApiObject(RegistroAPI)