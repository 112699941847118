import React, { useState, useEffect, Fragment } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});



export default function NavSection({ permessi, isAdmin, navConfig, closeMenu, ...other }) {
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [open, setOpen] = useState(Array(navConfig.length).fill(false));
  const [idPermessi, setIdPermessi] = useState([])

  useEffect(() => {
    const permessiTmp = permessi.filter(permesso => permesso.value).map(permesso => permesso.id);
    setIdPermessi(permessiTmp)
  }, [permessi])

  useEffect(() => {
    const tmpOpenArr = navConfig.map((config, i) =>
      config.path ? !!matchPath({ path: config.path, end: false }, pathname) : false
    );
    setOpen(tmpOpenArr);
  }, [pathname, navConfig])

  const handleOpen = (index) => {
    setOpen(prevOpen => prevOpen.map((isOpen, i) => i === index ? !isOpen : false));
  };

  const checkPath = (path) => {
    return (path ? !!matchPath({ path, end: false }, pathname) : false)
  }

  const navigateUrl = (e, path) => {
    if (e.button === 0 && path !== pathname) {
      navigate(path, { state: { from: window.location.pathname } })
      closeMenu()
    }
  }

  function checkIdPermessi(idPermessi, permessiUtente) {
    if (permessiUtente.idPermesso !== undefined && permessiUtente.idPermesso !== null) {
      return idPermessi.some(valorePermesso => permessiUtente.idPermesso.includes(valorePermesso))
    } else return true
  }

  return (
    <Box {...other} sx={{ minWidth: "20vw" }}>
      <List disablePadding>
        {navConfig.map((item, index) => (
          checkIdPermessi(idPermessi, item) ? (
            <Box key={index + '_conf'}>
              {item.children !== undefined && (
                ((item?.classroomNeedToBeSelected === undefined) || (item?.classroomNeedToBeSelected === true && sessionStorage.getItem("classe") != null)) && (
                  <ListItemStyle onClick={() => handleOpen(index)} key={index}
                    sx={{ bgcolor: checkPath(item.path) === true ? 'rgba(145, 158, 171, 0.08)' : 'transparent', color: checkPath(item.path) === true ? '#003370' : '#637381' }}>

                    <ListItemIconStyle sx={{ color: checkPath(item.path) === true ? '#003370' : '#637381' }}>{item?.icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={item.title} />
                    {item.children !== undefined && (
                      <Box sx={{ width: 16, height: 16, ml: 1 }}>
                        {open[index] && (
                          <ArrowDropDownIcon />
                        )}
                        {!open[index] && (
                          <ArrowDropUpIcon />
                        )}
                      </Box>)}
                  </ListItemStyle>
                )
              )}
              {item.children === undefined && (
                <ListItemStyle key={item.title + index} onMouseDown={(e) => navigateUrl(e, item.path)}
                  sx={{ textTransform: "none", bgcolor: checkPath(item.path) === true ? 'rgba(145, 158, 171, 0.08)' : 'transparent', color: checkPath(item.path) === true ? '#003370' : '#637381' }}>
                  <ListItemIconStyle sx={{ color: checkPath(item.path) === true ? '#003370' : '#637381' }}>{item?.icon}</ListItemIconStyle>
                  <ListItemText disableTypography primary={item.title} />
                </ListItemStyle>
              )}
              <Collapse in={open[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children !== undefined && (
                    item.children.map((child, index) => {
                      const { title, path } = child;
                      return (
                        checkIdPermessi(idPermessi, child) ? (
                          <Fragment key={index}>
                            {(child?.isAdmin === undefined || child?.isAdmin === false || (child?.isAdmin === true && isAdmin === true)) && (
                              <ListItemStyle
                                key={child + '_' + index}
                                onMouseDown={(e) => navigateUrl(e, path)}
                                sx={{ textTransform: "none" }}
                              >

                                <ListItemIconStyle>
                                  <Box
                                    component="span"
                                    sx={{
                                      width: 4,
                                      height: 4,
                                      display: 'flex',
                                      borderRadius: '50%',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      bgcolor: checkPath(path) === true ? '#003370' : 'text.disabled',
                                    }}
                                  />
                                </ListItemIconStyle>

                                <ListItemText disableTypography primary={title} />
                              </ListItemStyle>
                            )
                            }</Fragment>
                        ) : null
                      )
                    })
                  )}
                </List>
              </Collapse>
            </Box>
          ) : null
        ))}
      </List>
    </Box>
  );
}

