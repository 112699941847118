import { Box, Divider, Typography } from "@mui/material";
import { Assignment, AssignmentLate, CalendarToday, Info, Description, DoneAll, AccessibilityNew } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


export default function LegendaRegistroSintesiTirocinio() {
    return (
        <Grid container >
            <Grid xs={12} md={6} sx={{
                pb: { xs: 4, md: 0 }, borderBottom: { xs: 'rgb(210,210,210) 1px solid', md: 'none' },
                borderRight: { md: 'rgb(210,210,210) 1px solid', xs: 'none' }
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 0, md: 2 } }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid grey', color: 'grey' }}>
                        <Typography fontWeight={'700'} >N.D</Typography>
                    </Box>
                    <Typography>Presenza non definita</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 0, md: 2 } }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #4caf50 solid', color: '#4caf50' }}>
                        <Typography fontWeight={'700'} >P</Typography>
                    </Box>
                    <Typography>Presente</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ef5350 solid', color: '#ef5350' }}>
                        <Typography fontWeight={'700'}>A</Typography>
                    </Box>
                    <Typography>Assente</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ff9800 solid', color: '#ff9800' }}>
                        <Typography fontWeight={'700'}>PP</Typography>
                    </Box>
                    <Typography>Presenza parziale</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ba68c8 solid', color: '#ba68c8' }}>
                        <Typography fontWeight={'700'}>AdG</Typography>
                    </Box>
                    <Typography>Assenza da giustificare</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #4169e1 solid' }}>
                        <FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.25rem', color: '#1976d2' }} />
                    </Box>
                    <Typography>Dettaglio</Typography>
                </Box>
            </Grid>
            <Grid xs={12} md={6} sx={{
                pb: { xs: 4, md: 0 }, pl: { xs: 0, md: 4 }
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #4169e1', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#4169e1" }} />
                    </Box>
                    <Typography>BES (PDP/B E.S.)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #1ebf24', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#1ebf24" }} />
                    </Box>
                    <Typography>BES (PDP/D S.A.)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #ffbfaa', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#ffbfaa" }} />
                    </Box>
                    <Typography>BES (P.E.I. ORDINARIO)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #ff7b5a', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#ff7b5a" }} />
                    </Box>
                    <Typography>BES (P.E.I. PERSONALIZZATO)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #d11507', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#d11507" }} />
                    </Box>
                    <Typography>BES (P.E.I. DIFFERENZIATO)</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}