import { Container, Typography } from "@mui/material";

export function EasterEgg(props) {
    return (
        <Container height={"80vh"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>

            <iframe title="tuta gold" autoPlay={true}src="https://open.spotify.com/embed/track/654Jxp6rUFgPckixlIusRJ?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <Typography sx={{ my: 2, width: '100%', textAlign: 'center' }}>
                “Maglia bianca, oro sui denti, blue jeans Non paragonarmi a una bitch così”
            </Typography>
            <img style={{width:'100%'}} src={"https://64.media.tumblr.com/a9ffd446ead345f7befee3af48266d16/d2b6e80868ea50a2-41/s540x810/14fda15a09112031fc4f22632757296f1699611f.gif"} alt="loading..." />
         
        </Container>
    )
}