import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const UtilityAPI = {

    dettaglioIdOrario: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/dettaglioIdOrario`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getTrimestriCorso: async function (idCorso, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/trimestre/${idCorso}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editTrimestri: async function (idCorso, obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/trimestre/edit/${idCorso}`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    loginGtm: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gtm/gtmLogin`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    logoutGtm: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gtm/gtmLogout`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getAnniFormativi: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/anniFormativi`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDocumentiExportExAnte: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/exportPDF/listaDocumenti`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    exportExAnte: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/exportPDF/CSV`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    exportCsvBes: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/exportcsv`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    retriveDataAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/getCorsoByAlunno`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getOreNonFirmate: async function ( cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/getOreNonFirmate`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoAlunni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/elencoAlunni`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDirettoreSede: async function ( cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/direttoreSede`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPermessiByAnnoFormativo: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/auth/ricalcolaPermessi`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

}
const cancelApiObject = defineCancelApiObject(UtilityAPI)