import { useEffect, useState } from "react";
import { Typography, Dialog, DialogContent, CircularProgress } from "@mui/material";
export default function LoadingComponent(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(true);
    const [testoCaricamento, setTestoCaricamento] = useState("Caricamento in corso...");
    const [maxWidth, setMaxWidth] = useState("false");

    useEffect(() => {
        if (props.isFullScreen !== undefined) {
            if (props.isFullScreen === false) {
                if (props.maxWidth == undefined) //Quando fullScreen è false, se non gli è stato dato un maxWidth glielo diamo uno noi di default
                    setMaxWidth("md")
            }
            setIsFullScreen(props.isFullScreen);
        }
    }, [props.isFullScreen])

    useEffect(() => {
        if (props.maxWidth !== undefined)
            setMaxWidth(props.maxWidth);
    }, [props.maxWidth])

    useEffect(() => {
        if (props.isLoading !== undefined) {
            if (props.isLoading === false) {
                let timeoutLoading = 1000;
                if (props.timeoutLoading !== undefined) timeoutLoading = props.timeoutLoading;
                setTimeout(() => {
                    setIsLoading(props.isLoading);
                }, timeoutLoading)
            }
            else setIsLoading(props.isLoading);

        }
    }, [props.isLoading, props.timeoutLoading])

    useEffect(() => {
        if (props.testoCaricamento !== undefined)
            setTestoCaricamento(props.testoCaricamento);
    }, [props.testoCaricamento])

    return (
        <Dialog fullScreen={isFullScreen} maxWidth={maxWidth} open={isLoading} sx={{ zIndex: 3000 }}>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
                <Typography sx={{ mt: 3, width: 'fit-content' }}>
                    {testoCaricamento}
                </Typography>
            </DialogContent>
        </Dialog>
    )
}