import * as React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Link, Paper, Button, FormControl, TextField, Box, Collapse, Container, Select, Typography, Dialog, DialogContent, DialogTitle, IconButton, Breadcrumbs, Alert } from '@mui/material';

import DettaglioAlunno from '../dettaglio-utente/dettaglioAlunno';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Clear } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import 'react-virtualized/styles.css';
import TreeViewRicerca from './treeViewRicerca';

import { getAnnoFormativo } from '../shared/utilityMethods';

import { RicercaAPI } from '../../api/ricercaApi';

export default function RicercaDocente(props) {

  const { t, i18n } = useTranslation();

  const inputNomeAlunnoRef = useRef(null);
  const inputCfRef = useRef(null);

  const [showSediChips, setShowSediChips] = useState(false);

  const [sediList, setSediList] = useState([]);
  const [classiList, setClassiList] = useState([]);
  const [sedeSelected, setSedeSelected] = useState(-1);
  const [classeSelected, setClasseSelected] = useState(-1);

  const [risultatiRicerca, setRisultatiRicerca] = useState([]);


  const [nomeDocente, setNomeDocente] = useState('');
  const [nomeValido, setNomeValido] = useState(true);
  const [nomeMessage, setNomeMessage] = useState('')
  const [cfDocente, setCfDocente] = useState('');
  const [cfValido, setCfValido] = useState(true);
  const [cfMessage, setCfMessage] = useState('')

  // const [selection, setSelection] = useState([]);


  const [searchStarted, setSearchStarted] = useState(false);
  // const [searchLoaded, // const [searchLoaded, setSearchLoaded] = useState(false);] = useState(false);

  //Modale alunno
  const [dialogDettaglioAlunnoOpen, setDialogDettaglioAlunnoOpen] = useState(false);
  const [idDocenteSelezionato, setIdDocenteSelezionato] = useState(-1);
  const [nomeDocenteSelezionato, setNomeDocenteSelezionato] = useState("");

  //Selezione
  const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(false);

  const [annoFormativo, setAnnoFormativo] = useState('');

  useEffect(() => {
    let annoFormativoTmp = getAnnoFormativo();
    setAnnoFormativo(annoFormativoTmp);
    getSedi(annoFormativoTmp);
  }, [])

  useEffect(() => {
    if (props.showSediChips !== undefined)
      setShowSediChips(props.showSediChips);
  }, [props.showSediChips])

  useEffect(() => {
    if (props.isInsideOtherComponent !== undefined)
      setIsInsideOtherComponent(props.isInsideOtherComponent);
  }, [props.isInsideOtherComponent])

  useEffect(() => {
    if (props.isFullWidth !== undefined)
      setIsFullWidth(props.isFullWidth);
   }, [props.isFullWidth])

  useEffect(() => {
    if (props.showCheckbox !== undefined)
      setShowCheckbox(props.showCheckbox);
  }, [props.showCheckbox])


  function validate(e) {
    e.preventDefault()
    if (sedeSelected !== -1) {
      startSearch()
      getDocenti();
    }
  }

  // function addSelectedItem(idItem) {
  //   if (!selection.includes(idItem)) {
  //     setSelection([...selection, idItem])
  //   }
  //   else {
  //     let index = selection.indexOf(idItem);
  //     let newSet = selection;
  //     newSet.splice(index, 1);
  //     setSelection([...newSet]);
  //   }
  // }


  function nomeSet(e) {
    if (e.target.value.length === 0) {
      setNomeDocente('');
      setNomeValido(true);
      setNomeMessage('');
    }
    else if (e.target.value.length > 2) {
      setNomeDocente(e.target.value);
      setNomeMessage('');
      setNomeValido(true);
    }
    else {
      setNomeValido(false);
      setNomeMessage('Min. 3 caratteri.');
    }
  };

  function cfSet(e) {
    let newCf = e.target.value;
    newCf = newCf.toUpperCase();
    inputCfRef.current.value = newCf;
    if (e.target.value.length === 0) {
      setCfDocente('');
      setCfValido(true);
      setCfMessage('');
    }
    else if (e.target.value.length === 16) {
      setCfDocente(e.target.value);
      setCfMessage('');
      setCfValido(true);
    }
    else {
      setCfValido(false);
      setCfMessage('Codice fiscale incorretto.');
    }
  };

  function condizioneSede() {
    if (sedeSelected === -1 && nomeDocente !== '' && nomeValido) return true
    else if (sedeSelected === -1 && cfDocente !== '' && cfValido) return true
    else return false
  }

  function changeSede(e) {
    if (e.target.value !== '') {

      setSedeSelected(e.target.value);
      getClassiBySede(e.target.value, annoFormativo);
    }
    else {
      setSedeSelected(-1);
      setClasseSelected(-1);
    }
  };

  function changeClasse(e) {
    if (e.target.value !== '') {
      setClasseSelected(e.target.value);
    }
    else {
      setClasseSelected(-1);
    }
  };

  function checkValidateInput() {
    // if (sedeSelected !== -1 && (nomeDocente === "" || (nomeValido && nomeDocente.length > 2)) || (cfValido === "" ||(cfValido && cfDocente.length === 16))) {
    if (sedeSelected !== -1 && ((nomeValido) && (cfValido))) {
      return false
    }
    else {

      return true
    }
  }

  function startSearch() {
    setSearchStarted(true)
  }

  function removeFilters() {
    setNomeDocente("");
    setCfDocente("");
    if (sediList.length > 1) {//Se è solo una sede non lo resettiamo 
      setSedeSelected(-1);
      setClassiList([]) //Essendoci una sola sede non tolgo l'elenco dei corsi, non ha senso fare un nuovo get
    }
    setClasseSelected(-1);
    setNomeMessage("");
    setNomeValido(true);
    setCfValido(true);
    setCfMessage("");
    inputNomeAlunnoRef.current.value = "";
    inputCfRef.current.value = "";
    // setSelection([])

    setSearchStarted(false)
    // setSearchLoaded(false);
  }



  function checkedRow(arraySelezionati) {
    if (props.passIdDestinatariSelezionati)
      props.passIdDestinatariSelezionati(arraySelezionati);
  }



  function openDettaglioAlunno(idAlunno, nomeDocente) {
    setIdDocenteSelezionato(idAlunno);
    setNomeDocenteSelezionato(nomeDocente);
    setDialogDettaglioAlunnoOpen(true)
  }

  function closeDettaglioAlunno() {
    setDialogDettaglioAlunnoOpen(false)
  }

  function setCheckedNodesFunction(value) {
    checkedNodesLevelPadre(value);
  }

  function checkedNodesLevelPadre(value) {
    if (props.setCheckedNodes) props.setCheckedNodes(value)
  }

  async function getSedi(annoFormativo) {

    RicercaAPI.getSedi({ annoFormativo: annoFormativo })
      .then((result) => {
        setSediList(result.sedi)
        if (result.sedi.length === 1) {
          setSedeSelected(result.sedi[0].id);
          getClassiBySede(result.sedi[0].id, annoFormativo);
        }
      })
      .catch(function (error) { });
  }
  async function getClassiBySede(idSede, annoFormativo) {
    let dataObj = {
      idSede: idSede,
      annoFormativo: annoFormativo
    }

    RicercaAPI.getClassiBySede(dataObj)
      .then((result) => {
        setClassiList(result.sedi)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }
      })
  }
  async function getDocenti() {
    let dataObj = {
      annoFormativo: annoFormativo.toString(),
      cognome: nomeDocente !== '' ? nomeDocente : null,
      codiceFiscale: cfDocente !== '' ? cfDocente : null,
      idSede: sedeSelected !== -1 ? sedeSelected : null,
      idCorso: classeSelected !== -1 ? classeSelected : null,
    }

    RicercaAPI.getDocenti(dataObj)
      .then((result) => {
        let risultati = [];
        for (let i = 0; i < result.sedi.length; i++) {
          let corsiTmp = [];

          for (let y = 0; y < result.sedi[i].corsi.length; y++) {
            let corsoTmp = {};
            corsoTmp.id = result.sedi[i].corsi[y].idCorso;
            corsoTmp.name = result.sedi[i].corsi[y].corso;
            let docentiTmp = [];
            for (let x = 0; x < result.sedi[i].corsi[y].docenti.length; x++) {
              let docenteTmp = {};
              docenteTmp.idSede = result.sedi[i].idSede;
              docenteTmp.idCorso = result.sedi[i].corsi[y].idCorso;
              docenteTmp.id = result.sedi[i].corsi[y].docenti[x].idUtente;

              docenteTmp.cf = result.sedi[i].corsi[y].docenti[x].codiceFiscale;
              docenteTmp.name = result.sedi[i].corsi[y].docenti[x].cognomeDocente + " " + result.sedi[i].corsi[y].docenti[x].nomeDocente;
              docenteTmp.type = "docente";
              docentiTmp.push(docenteTmp)
            }
            corsoTmp.type = "classe";
            corsoTmp.children = docentiTmp;
            corsiTmp.push(corsoTmp)
          }


          risultati.push({
            id: sedeSelected,
            name: result.sedi[i].sede,
            type: "sede",
            children: corsiTmp
          })

        }

        setRisultatiRicerca([...risultati])
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }
      })
  }


  return (
    <Fragment>

      {!isInsideOtherComponent ? (
        <Container maxWidth="xl">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
            <Typography color="text.disabled">Ricerca docenti</Typography>
          </Breadcrumbs>
          <Typography variant="h4" mt={1}>Ricerca docenti</Typography>
        </Container>
      ) : null}


      <Container disableGutters={isInsideOtherComponent} maxWidth={isFullWidth ? "false" : "xl"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 5 }}>
          <form onSubmit={startSearch} style={{ width: '100%' }}>
            <Grid container spacing={2} sx={{ width: '100%', pt: 2, px: 3 }}>
              <Grid xs={12} md={3}>
                <TextField sx={{ width: '100%' }} data-testid='nomeRicercaInput' error={(nomeValido) ? false : true}
                  label="Cognome" onChange={nomeSet} helperText={nomeMessage} inputRef={inputNomeAlunnoRef} />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField sx={{ width: '100%' }} data-testid='cfRicercaInput' inputRef={inputCfRef}
                  error={(cfValido) ? false : true} label="C.F." length={16} inputProps={{ maxLength: 16 }} onChange={cfSet} helperText={cfMessage} />
              </Grid>
              <Grid xs={12} md={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="sede-label">
                    Sede
                  </InputLabel>
                  <Select
                    data-testid='sedeRicercaSelect'
                    label="Sede"
                    labelId="sede-label"
                    value={sedeSelected}
                    onChange={changeSede}
                    disabled={sediList.length <= 1}
                  >
                    <MenuItem
                      key={-1}
                      value={-1}
                    >
                    </MenuItem>
                    {sediList.map((sede) => (
                      <MenuItem
                        key={sede.id}
                        value={sede.id}
                      >
                        {sede.nomeSede}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="classe-label">Corso</InputLabel>
                  <Select
                    // error={(ricercaValida) ? false : true}
                    disabled={sedeSelected !== -1 || classiList.length !== 0 ? false : true}
                    data-testid='classeRicercaSelect'
                    label="Corso"
                    labelId="classe-label"
                    value={classeSelected}
                    onChange={changeClasse}
                  >
                    <MenuItem
                      key={-1}
                      value={-1}
                    >
                    </MenuItem>
                    {classiList.map((classe) => (
                      <MenuItem style={{ whiteSpace: 'normal' }} key={classe.id} value={classe.id} sx={{ wordBreak: 'break-all' }}>
                        {classe.nomeCorso}
                      </MenuItem> // .nomeCorso
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ width: '100%', py: 2, px: 3 }} justifyContent={"end"}>
              {condizioneSede() && (<Grid xs={12} md={3}>
                <Box textAlign={'center'} color={'red'}>
                  <Typography variant='caption' sx={{ textAlign: 'center' }}>Per avviare la ricerca è necessario selezionare la sede.</Typography>
                </Box>
              </Grid>)}
              {searchStarted && (
                <Grid xs={12} md={2}>
                  <Button onClick={removeFilters} color='error' variant="outlined" fullWidth endIcon={<Clear />} type='submit' data-testid='rimuoviFiltriBtn' >
                    Rimuovi filtri
                  </Button>
                </Grid>
              )}
              <Grid xs={12} md={2}>
                <Button onClick={validate} color='success' fullWidth endIcon={<SearchIcon />} disabled={checkValidateInput()} type='submit' data-testid='ricercaBtn' variant="contained">
                  Avvia ricerca
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
          <Paper sx={{ p: 2, overflowY: 'auto' }}>
            {/* {!searchLoaded ? (
                <Box display={"flex"} height={"100%"} width={"100%"} flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                  <CircularProgress />
                </Box>
              ) : (
                <p>Caricato</p>
              )} */}

            {risultatiRicerca.length > 0 ?
              (<TreeViewRicerca showSediChips={showSediChips} setCheckedNodes={setCheckedNodesFunction} openDettaglioAlunno={openDettaglioAlunno} risultatiRicerca={risultatiRicerca} showCheckbox={showCheckbox} checkedRow={checkedRow} />)
              : (
                <Alert severity='info'>La ricerca non ha prodotto nessun risultato</Alert>
              )}

          </Paper>
        </Collapse>
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={dialogDettaglioAlunnoOpen}
        onClose={closeDettaglioAlunno}
      >
        <DialogTitle>
          {t("pages.ricercaAlunni.dettaglio")} ({nomeDocenteSelezionato})
          <IconButton
            onClick={closeDettaglioAlunno}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlinedIcon color="primary" />
          </IconButton>
        </DialogTitle>


        <DialogContent sx={{ pt: 0 }}>
          <DettaglioAlunno showBes={false} tabIndex={0} nomeAlunno={nomeDocenteSelezionato} id={idDocenteSelezionato} />
        </DialogContent>

      </Dialog>

      {!isInsideOtherComponent ? (
        <Helmet>
          <title>{t("name")} - {t("pages.ricercaAlunni.title")}</title>
        </Helmet>
      ) : null}

    </Fragment >
  );
}