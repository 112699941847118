import { Table, Box, Dialog, DialogTitle, DialogContent, Paper, Typography, Button, Container, TableHead, TableRow, TableCell, TableBody, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment } from "react"
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { Close } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";

import { EventiAPI } from "../../../api/eventiApi";
import { RicercaAPI } from "../../../api/ricercaApi";
import { convertISODate, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import ModificaCompiti from "./modificaCompiti";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";
export default function ElencoCompiti(props) {

    const [dataStart, setDataStart] = useState(null);
    const [dataEnd, setDataEnd] = useState(null);
    const [dataConsegnaStart, setDataConsegnaStart] = useState(null);
    const [dataConsegnaEnd, setDataConsegnaEnd] = useState(null);
    const [tipoRegistro, setTipoRegistro] = useState("classe")
    const [elencoAnnotazioni, setElencoAnnotazioni] = useState([]);
    const [annotazioneSel, setAnnotazioneSel] = useState({});
    const [annotazioneSelIndex, setAnnotazioneSelIndex] = useState(-1);
    const [idAlunno, setIdAlunno] = useState(null);
    const [idCorso, setIdCorso] = useState(-1);
    const [idMateria, setIdMateria] = useState(-1);
    const [idMateriaSelected, setIdMateriaSelected] = useState(-1);
    const [elencoMaterie, setElencoMaterie] = useState([]);
    const [permessiUtente, setPermessiUtente] = useState([])

    const [listAlunni, setListAlunni] = useState([]);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [showOnlyElenco, setShowOnlyElenco] = useState(false);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null);
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null);

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            setListAlunni([...props.listAlunni]);
        }
    }, [props.listAlunni])



    // useEffect(() => {
    //     if (props.dataRichiesta !== undefined) {
    //         let dataRichiesta = DateTime.fromSQL(props.dataRichiesta);
    //         setDataConsegnaStart(dataRichiesta);
    //         setDataConsegnaEnd(dataRichiesta);
    //     }
    // }, [props.dataRichiesta])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro)
    }, [props.tipoRegistro])

    useEffect(() => {
        if (props.alunniSelezionati !== undefined) {

        }
    }, [props.alunniSelezionati])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente]);
        }
    }, [props.permessiUtente])


    useEffect(() => {
        if (props.idCorso !== undefined && props.idMateria !== undefined && props.alunniSelezionati !== undefined) {
            let objDateInteresse = getDataInizioFinePeriodo()
            let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
            setDataInizioPeriodo(inizioPeriodoTmp ?? null);
            let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
            setDataFinePeriodo(finePeriodoTmp ?? null);

            setIdCorso(props.idCorso);
            getMaterieByClasse(props.idCorso);
            if (props.idMateria != null) {
                setIdMateria(props.idMateria);
                setIdMateriaSelected(props.idMateria);
            } else {
                setIdMateria(-1);
                setIdMateriaSelected(-1);
            }
            let idAlunnoTmp = null;
            if (props.alunniSelezionati.length === 1) {
                idAlunnoTmp = props.alunniSelezionati[0].id
                setIdAlunno(props.alunniSelezionati[0].id)
            }
            else {
                idAlunnoTmp = null;
                setIdAlunno(null)
            }

            let showOnlyElenco = props.showOnlyElenco ?? false;
            setShowOnlyElenco(showOnlyElenco)
            getElencoCompiti(idAlunnoTmp, props.idCorso, props.idMateria, false, showOnlyElenco)
        }
    }, [props.idCorso, props.idMateria, props.alunniSelezionati, props.showOnlyElenco, props.dataRichiesta])

    function changeDataStart(value) {
        setDataStart(value);
    }

    function changeDataEnd(value) {
        setDataEnd(value);
    }

    function changeDataConsegnaStart(value) {
        setDataConsegnaStart(value);
    }

    function changeDataConsegnaEnd(value) {
        setDataConsegnaEnd(value);
    }

    function changeMateria(e) {
        setIdMateriaSelected(e.target.value)
    }


    function filtro() {
        getElencoCompiti(idAlunno, idCorso, idMateriaSelected, false);
    }

    function rimuoviFiltro() {
        setDataEnd(null);
        setDataStart(null);
        setDataConsegnaEnd(null);
        setDataConsegnaStart(null);
        setIdMateriaSelected(idMateria)
        getElencoCompiti(idAlunno, idCorso, idMateria, true);
    }

    function openDialog(annotazione, index) {
        setAnnotazioneSel(annotazione);
        setAnnotazioneSelIndex(index);
        setDialogIsOpen(true);
    }

    function closeDialog() {
        setDialogIsOpen(false);
        getElencoCompiti(idAlunno, idCorso, idMateriaSelected, false)
    }


    function isSearchButtonDisabled() {
        if (((dataStart == null && dataEnd == null) || (dataStart != null && dataEnd != null)) &&
            ((dataConsegnaStart != null && dataConsegnaEnd != null) || (dataConsegnaStart == null && dataConsegnaEnd == null)))
            return false
        else return true
    }


    async function getElencoCompiti(idAlunno, idCorso, idMateria, skipDate, showOnlyElenco) {

        let dataStartTmp = null;
        let dataEndTmp = null;
        let dataStartConsegnaTmp = null;
        let dataEndConsegnaTmp = null;
        let soloInteraClasse = false;


        if (skipDate === undefined || skipDate == null || skipDate == false) {
            if (dataStart != null && dataEnd !== null) {
                let dataConverted = convertDataGetProssimeLezione(dataStart)
                dataStartTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataEnd)
                dataEndTmp = dataConvertedEnd;
            } else {
                setDataStart(null);
                setDataEnd(null)
            }

            if (dataConsegnaStart != null && dataConsegnaEnd != null) {
                let dataConverted = convertDataGetProssimeLezione(dataConsegnaStart)
                dataStartConsegnaTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataConsegnaEnd)
                dataEndConsegnaTmp = dataConvertedEnd;
            } else {
                if (!showOnlyElenco) {
                    setDataConsegnaStart(null)
                    setDataConsegnaEnd(null)
                } else {
                    soloInteraClasse = true;
                    if (props.dataRichiesta != null) {
                        let dateObject = DateTime.fromSQL(props.dataRichiesta);
                        let dataConverted = convertDataGetProssimeLezione(dateObject, true)
                        dataStartConsegnaTmp = dataConverted;
                        dataEndConsegnaTmp = dataConverted;
                        setDataConsegnaStart(dateObject)
                        setDataConsegnaEnd(dateObject)
                    }
                }
            }

        }

        let obj = {
            idCorso: idCorso,
            idMateria: idMateria === -1 ? null : idMateria,
            dataInserimentoDa: dataStartTmp,
            dataInserimentoA: dataEndTmp,
            dataAssegnazioneDa: dataStartConsegnaTmp,
            dataAssegnazioneA: dataEndConsegnaTmp,
            soloInteraClasse: soloInteraClasse,

        }

        if (idAlunno !== null)
            obj.idAlunno = idAlunno
        if (idCorso !== -1) {

            EventiAPI.elencoCompiti(obj)
                .then((result) => {
                    let annotazioniTmp = [];
                    for (let annotazione of result) {
                        let annotazioneTmp = annotazione;
                        if (annotazione.contenuto.note.length >= 30) {
                            let descBreve = annotazione.contenuto.note.slice(0, 30) + '...';
                            annotazioneTmp.contenuto.descrBreve = descBreve;
                        } else annotazioneTmp.contenuto.descrBreve = annotazione.contenuto.note;


                        annotazioniTmp.push(annotazioneTmp);
                    }
                    setElencoAnnotazioni([...annotazioniTmp])
                })
                .catch((error) => { })
        }
    }

    async function getMaterieByClasse(idClasse) {


        let dataObj = {
            idCorso: idClasse
        }

        RicercaAPI.getMaterieByClasse(dataObj)
            .then((result) => {
                // setHasLoaded(true);
                setElencoMaterie([...result?.materie])
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }


    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                <Grid container spacing={2} mt={{ xs: 2, md: 0 }}>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataEnd != null ? dataEnd : dataFinePeriodo}
                                label="Periodo (inserimento) dal"
                                value={dataStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataStart != null ? dataStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}
                                label="Periodo (inserimento) al"
                                value={dataEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataConsegnaEnd != null ? dataConsegnaEnd : dataFinePeriodo}
                                label="Periodo (consegna) dal"
                                value={dataConsegnaStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataConsegnaStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataConsegnaStart != null ? dataConsegnaStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}
                                label="Periodo (consegna) al"
                                value={dataConsegnaEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataConsegnaEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        {elencoMaterie.length > 0 ? (
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="materia-label">
                                    {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                    Seleziona Materia</InputLabel>
                                <Select
                                    data-testid='selMateriaSelect'
                                    label='Seleziona Materia '
                                    labelId="materia-label"
                                    value={idMateriaSelected}
                                    onChange={changeMateria}
                                    disabled={elencoMaterie.length === 0}
                                >
                                    <MenuItem key={-1} value={-1}>
                                        Tutte
                                    </MenuItem>
                                    {elencoMaterie.map((materia) => (
                                        <MenuItem key={materia.idMateria} value={materia.idMateria} >
                                            {materia.nomeMateria}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>) : (null)}
                        {elencoMaterie.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta.</Typography>) : null}
                    </Grid>
                    <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box mb={2}>
                            {((dataConsegnaStart == null && dataConsegnaEnd != null) || (dataConsegnaStart != null && dataConsegnaEnd == null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date di consegna</b> per applicare il filtro.
                                </Typography>
                            )}
                            {((dataStart != null && dataEnd == null) || (dataStart == null && dataEnd != null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date di inserimento</b> per applicare il filtro.
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'end', alignItems: 'center', width: { xs: '100%', md: 'fit-content' } }}>
                            <Button onClick={() => rimuoviFiltro()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="outlined" startIcon={<Close />}>
                                Rimuovi filtri
                            </Button>
                            <Button disabled={isSearchButtonDisabled()} onClick={() => filtro()} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' }, height: '100%' }}
                                variant="contained" startIcon={<FilterList />}>
                                Filtra
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
                <Paper sx={{ minHeight: '20vh', maxHeight: '60vh', px: 2, overflowY: 'scroll', mt: 4, mb: 1 }}>
                    {elencoAnnotazioni.length > 0 ? (

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data di inserimento</TableCell>
                                    <TableCell>Data di consegna</TableCell>
                                    <TableCell>Materia</TableCell>
                                    <TableCell>Descrizione breve</TableCell>
                                    <TableCell align="right"></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {elencoAnnotazioni.map((annotazione, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{convertISODate(annotazione?.data_comunicazione)}</TableCell>
                                        <TableCell>
                                            <Typography color={"primary"}>{annotazione?.contenuto?.dataLezione != null ? convertISODate(annotazione?.contenuto?.dataLezione) : "NON ASSEGNATA"}</Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {annotazione?.contenuto?.materia?.nomeMateriaBreve}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {annotazione?.contenuto?.descrBreve}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            <Button onClick={() => openDialog(annotazione, index)} size="small" variant="contained" data-testid='leggiComBtn'>Apri dettaglio</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    ) : (
                        <Box sx={{ width: '100%', height: '20vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography >Nessun compito assegnato nel periodo selezionato</Typography>
                        </Box>
                    )}

                </Paper>
                <Dialog fullWidth maxWidth={"xl"} open={dialogIsOpen} onClose={closeDialog} sx={{ mt: '8vh' }}>
                    <DialogTitle>
                        Dettaglio compito
                        {/* <IconButton onClick={closeDialog} sx={{ ml: 5, position: 'absolute', top: 5, right: 5 }}  >
                            <Close />
                        </IconButton> */}
                    </DialogTitle>
                    <DialogContent>
                        <ModificaCompiti tipoRegistro={tipoRegistro} permessiUtente={permessiUtente} compitoSel={annotazioneSel} listAlunni={listAlunni} closeDialog={closeDialog} />
                    </DialogContent>
                </Dialog>
            </Container>
        </Fragment>
    )



}