import { Fragment, useState, useEffect } from "react";
import { Box, Container, Button, CircularProgress, Divider, Dialog, DialogContent, DialogTitle, Typography, useTheme, Breadcrumbs, useMediaQuery, IconButton, Alert, Snackbar, Link, } from '@mui/material';
import ElencoArgomenti from "./argomenti/elencoArgomenti";
import SelezioneCorsoMateriaGiorno from "../registri/selezioneCorsoMateriaGiorno";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Breadcrumb from "../registri/shared/breadcrumb";
import { ArrowBack } from "@mui/icons-material";
import SelezioneClasseOperatore from "../registri/selezioneClasseOperatore";
import { getDataInizioFinePeriodo } from "../shared/utilityMethods";


export default function RiepilogoArgomentiSvolti(props) {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const [tipoRegistro, setTipoRegistro] = useState("classe")

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(false)

    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");
    const [codiceCorso, setCodiceCorso] = useState('');
    const [codiceSimon, setCodiceSimon] = useState('');

    //Periodo di interesse
    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [isDialog, setIsDialog] = useState(false);


    const [sedeUtente, setSedeUtente] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isDocente, setIsDocente] = useState(false);


    useEffect(() => {
        if (localStorage.getItem("doc"))
            setIsDocente(true)
        else setIsDocente(false)

        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog)

        } else {
            setTimeout(() => {
                setIsLoading(false)
                setDialogSelezionaClasseOpen(true)
            }, 500);
        }
    }, [])

    useEffect(() => {
        if (props.objRiepilogoArgomentiSvolti !== undefined) {
            let objRiepilogoArgomentiSvoltiTmp = props.objRiepilogoArgomentiSvolti;

            setIdCorsoSelezionato(objRiepilogoArgomentiSvoltiTmp?.idCorsoSelezionato);
            setIdMateriaSelezionata(objRiepilogoArgomentiSvoltiTmp?.idMateriaSelezionata);
            setNomeClasse(objRiepilogoArgomentiSvoltiTmp?.nomeClasse);
            // setDialogSelezionaClasseOpen(false);
            setCodiceCorso(objRiepilogoArgomentiSvoltiTmp?.codiceCorso);
            setCodiceSimon(objRiepilogoArgomentiSvoltiTmp?.codiceSimon);
            setPeriodoDiInteresseInizio(objRiepilogoArgomentiSvoltiTmp?.periodoDiInteresseInizio);
            setPeriodoDiInteresseFine(objRiepilogoArgomentiSvoltiTmp?.periodoDiInteresseFine);
            setTipoRegistro('docente')
            setIsLoading(false)
        }
    }, [props.objRiepilogoArgomentiSvolti])

    function changeIdClasseSelezionata(idCorso, dataInizio, dataFine, idMateria, nomeClasse, oreLezione, idOrario, isDocente, nomeMateria, oraMateria, codiceCorso, codiceSimon) {

        if (idCorso !== undefined) {
            setIdCorsoSelezionato(idCorso);
            setIdMateriaSelezionata(idMateria);
            setNomeClasse(nomeClasse);
            setDialogSelezionaClasseOpen(false);
            setCodiceCorso(codiceCorso);
            setCodiceSimon(codiceSimon);
            //setHasDocenteFunctions(isDocente);
            setPeriodoDiInteresseInizio(dataInizio);
            setPeriodoDiInteresseFine(dataFine);
        }


    }

    function changeSede(nome) {
        setSedeUtente(nome)
    }

    function changeCorsoSelezionato() {
        resetData()
        setDialogSelezionaClasseOpen(true);
    }

    function checkSelezioneClasseOperatore() {
        setIsDocente(false);
    }

    function resetData() {
        setIdCorsoSelezionato(-1);
        setIdMateriaSelezionata(-1);
        setNomeClasse('');
        setCodiceCorso('');
        setCodiceSimon('');
        setPeriodoDiInteresseInizio(null);
        setPeriodoDiInteresseFine(null);
    }


    return (
        <Fragment>
            {!isLoading ? (<Fragment>
                {(idCorsoSelezionato !== -1 && idMateriaSelezionata !== -1 && !dialogSelezionaClasseOpen) && (
                    <Fragment>
                        {!isDialog && (<Breadcrumb sections={["Riepilogo argomenti svolti", codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")"]} />)}

                        <Container maxWidth="xl">
                            {!isDialog && (<Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"} my={2}>
                                <Button onClick={changeCorsoSelezionato} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                    Torna alla selezione del corso
                                </Button>
                            </Box>)}
                            {!isDialog && (<Typography variant="h4" mt={1}>Riepilogo argomenti svolti</Typography>)}
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', my: 1 }}>
                                <Typography variant="caption">
                                    Corso:
                                </Typography>
                                <Typography color={'primary'}
                                    variant={mobile ? 'caption' : 'body1'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>{(codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()}</Typography>
                            </Box>
                        </Container>
                    </Fragment>)}
                {(idCorsoSelezionato !== -1 && idMateriaSelezionata !== -1 && !dialogSelezionaClasseOpen) && (
                    <ElencoArgomenti
                        riepilogo={true}
                        showOnlyElenco={false}
                        dataRichiesta={periodoDiInteresseInizio}
                        tipoRegistro={tipoRegistro}
                        permessiUtente={[]}
                        idDocente={-1}
                        idMateria={idMateriaSelezionata}
                        idCorso={idCorsoSelezionato}
                        alunniSelezionati={[]}
                        listAlunni={[]} />)}
            </Fragment>) : (
                <Box elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 2, height: '30vh' }}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                </Box>)}

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Seleziona corso di riferimento</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {isDocente ? (
                        <SelezioneCorsoMateriaGiorno
                            isConsulta={true}
                            tipoRegistro={tipoRegistro}
                            changeIdClasseSelezionata={changeIdClasseSelezionata}
                            changeSede={changeSede}
                            riepilogo={true}
                            checkSelezioneClasseOperatore={checkSelezioneClasseOperatore}
                        />
                    ) : (
                        // <SelezioneLezioneNew isConsulta={true} tipoRegistro={tipoRegistro} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} />
                        <SelezioneClasseOperatore dontShowDate={true} tipoRegistro={tipoRegistro} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} />
                    )}

                </DialogContent>
            </Dialog>
            <Helmet>
                <title>{t("name")} - Riepilogo argomenti svolti</title>
            </Helmet>
        </Fragment>
    )
}