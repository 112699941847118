import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Box, Container, Typography, Link, Divider, Tooltip, useTheme } from '@mui/material'
import { Home, NavigateNext } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Breadcrumb(props) {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [sections, setSections] = useState([]);
    const [disableGutters, setDisableGutters] = useState(false);
    const [isInContainer, setIsInContainer] = useState(false);
    const [isTirocinio, setIsTirocinio] = useState(false);

    useEffect(() => {
        if (props.sections !== undefined)
            setSections([...props.sections]);
    }, [props.sections])

    useEffect(() => {
        if (props.isInContainer !== undefined)
            setIsInContainer(props.isInContainer);
    }, [props.isInContainer])

    useEffect(() => {
        if (props.disableGutters !== undefined)
            setDisableGutters(props.disableGutters);
    }, [props.disableGutters])

    useEffect(() => {
        if (props.isTirocinio !== undefined)
            setIsTirocinio(props.isTirocinio);
    }, [props.isTirocinio])

    function goToHome(isModuloTirocinio) {
        navigate("/", { state: { isModuloTirocinio: isModuloTirocinio } });
    }

    return (
        <Fragment>
            <Container sx={{ overflowX: isMobile ? 'scroll' : 'unset' }} disableGutters={disableGutters} maxWidth={isInContainer ? "xl" : 'unset'}>
                <Box sx={{ width: isMobile ? 'max-content' : '100%' }} role="presentation" >
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                        {!isMobile ? (
                            <Tooltip title="Torna alla home" arrow>
                                <Link sx={{ cursor: 'pointer' }} underline="hover" key="1" color="primary" onClick={() => goToHome(false)}>
                                    Home
                                </Link>
                            </Tooltip>
                        ) : (
                            <Link key="1" color="primary" onClick={goToHome}>
                                <Home />
                            </Link>
                        )}
                        {isTirocinio && (<Tooltip title="Torna al modulo tirocini formativi" arrow>
                            <Link sx={{ cursor: 'pointer' }} underline="hover" key="1" color="primary" onClick={() => goToHome(true)}>
                                Modulo tirocini formativi
                            </Link>
                        </Tooltip>)}
                        {sections.length > 0 && (
                            sections.map((section, index) => (
                                <Typography sx={{ cursor: 'context-menu' }} key={index + 1} color="inherit">
                                    {section}
                                </Typography>
                            ))
                        )
                        }
                    </Breadcrumbs>
                </Box>
            </Container>
            <Divider sx={{ mt: 1, mb: 2 }} />
        </Fragment>
    )
}