import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Typography, useTheme, useMediaQuery, Alert, CircularProgress, Snackbar } from '@mui/material';
import { TirociniAPI } from "../../../api/tirociniApi";
import DialogConferma from "../../shared/dialogConferma";
import { Info } from "@mui/icons-material";
import RowInserimentoVotiTirocinio from "./rowInserimentoVotiTirocinio";


export default function InserimentoVotiTirocinio(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [nomeCorso, setNomeCorso] = useState("");

    const [idCorso, setIdCorso] = useState(-1);
    const [listAlunni, setListAlunni] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMsg, setAlertMsg] = useState("");

    const [isModificheNonSalvate, setIsModificheNonSalvate] = useState(false);
    const [canSaveVoti, setCanSaveVoti] = useState(true);

    const [isAlertConfirmOpen, setIsAlertConfirmOpen] = useState(false);

    const votiValutazioneGeneraleStageRef = useRef([])
    const votiFrequenzaRef = useRef([])
    const votiTenutaDocumentazioneRef = useRef([])
    const votiDisponibilitaPropostaFormativaRef = useRef([])

    useEffect(() => {
        if (props.nomeCorso !== undefined) {
            setNomeCorso(props.nomeCorso)
        }
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.listAlunni && props.idCorso) {
            votiValutazioneGeneraleStageRef.current = votiValutazioneGeneraleStageRef.current.slice(0, props.listAlunni.lenght);
            votiFrequenzaRef.current = votiFrequenzaRef.current.slice(0, props.listAlunni.lenght);
            votiTenutaDocumentazioneRef.current = votiTenutaDocumentazioneRef.current.slice(0, props.listAlunni.lenght);
            votiDisponibilitaPropostaFormativaRef.current = votiDisponibilitaPropostaFormativaRef.current.slice(0, props.listAlunni.lenght);
            setIdCorso(props.idCorso);
            getElencoVoti(props.idCorso);
        }
    }, [props.listAlunni, props.idCorso])

    function votiChanged(listAlunniTmp) {
        setListAlunni([...listAlunniTmp]);
        if (listAlunni.some(alunno => alunno?.valutazioni?.canSave === false)) {
            if (canSaveVoti === true)
                setCanSaveVoti(false);
        }
        else {
            if (canSaveVoti === false)
                setCanSaveVoti(true);
        }
    }



    function closeDialogModifica() {
        if (isModificheNonSalvate) {

            setIsAlertConfirmOpen(true);
        }
        else {
            if (props.closeDialog !== undefined)
                props.closeDialog()
        }
    }

    function confirmChiusuraDialogAlert() {
        setIsAlertConfirmOpen(false);
        if (props.closeDialog !== undefined)
            props.closeDialog()
    }



    async function getElencoVoti(idCorso) {
        let dataObj = {
            idCorso: idCorso
        }
        TirociniAPI.getVotiTirocinio(dataObj)
            .then((result) => {
                //  setIsError(false);

                setListAlunni([...result]);
                setHasLoaded(true);
                //gestioneVoti(result);

            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'inserimento dell'annotazione. Riprovare tra qualche istante."
                    setAlertMsg(errorMsg);
                    setAlertSeverity("error");
                    setIsAlertOpen(true);
                }
            })
    }
    async function confermaModifiche() {
        setIsModificheNonSalvate(false);
        setIsSaving(true);

        let arrayVotiTmp = [];
        for (let alunno of listAlunni) {
            arrayVotiTmp.push({
                idAlunno: alunno?.alunno?.id ?? -1,
                valutazioneGenerale: alunno?.valutazioni?.valutazioneGenerale ?? null,
                frequenza: alunno?.valutazioni?.frequenza ?? null,
                tenutaDocumentazione: alunno?.valutazioni?.tenutaDocumentazione ?? null,
                disponibilitaProposta: alunno?.valutazioni?.disponibilitaProposta ?? null,
            })
        }
        let dataObj = {
            idCorso: idCorso,
            voti: arrayVotiTmp
        }
        TirociniAPI.saveVotiTirocinio(dataObj)
            .then((result) => {
                props.votiSaved();
                setIsSaving(false);

            })
            .catch(function (error) {
                if (error.response) {
                    setIsModificheNonSalvate(true); //Se va in errore non ho salvato, quindi rimetto l'alert
                    setIsSaving(false);
                  
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'inserimento dell'annotazione. Riprovare tra qualche istante."
                    setAlertMsg(errorMsg);
                    setAlertSeverity("error");
                    setIsAlertOpen(true);
                }
            })

    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Box sx={{ mt: 3, mb: 3, width: '100%', overflowY: 'scroll', maxHeight: { xs: '65vh', md: '75vh' }, border: '1px solid #eaeaea', borderRadius: '15px' }}>
                        <Table sx={{ height: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" rowSpan={2}
                                        className="colonna-nomi row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}
                                    >
                                        <Box sx={{
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                            backgroundColor: 'white',
                                            height: '60px',
                                            px: 2,
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                        }}>
                                            <Typography color={'primary'} sx={{ fontWeight: 'bold' }}
                                                variant={"body2"} textAlign={"start"}>
                                                {nomeCorso}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}
                                        className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                    >
                                        <Box sx={{
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                            backgroundColor: 'white',
                                            height: '60px',
                                            px: 2,
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <Typography variant="body2" textAlign={"center"}>Valutazione generale stage</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}
                                        className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                    >                                            <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        height: '60px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                            <Typography variant="body2" textAlign={"center"}>Frequenza</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}
                                        className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                    >                                            <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        height: '60px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                            <Typography variant="body2" textAlign={"center"}>Tenuta documentazione</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}
                                        className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                    >                                            <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        height: '60px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                            <Typography variant="body2" textAlign={"center"}>Disponibilità alla proposta formativa</Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listAlunni.map((alunno, index) => (
                                    <RowInserimentoVotiTirocinio votiChanged={votiChanged} alunno={alunno} indexAlunno={index} listAlunni={listAlunni} />
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"}>
                        {!canSaveVoti ? (
                            <Alert severity="warning"><b>Attenzione:</b> per poter continuare con il salvataggio delle modifiche, devono essere compilati tutti i valori.  </Alert>
                        ) : (<span></span>)}
                        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={'end'}>
                            <Button disabled={isSaving} variant='outlined' color="error" sx={{ height: 'fit-content', ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={closeDialogModifica}>
                                {isModificheNonSalvate ? "Chiudi finestra e annulla modifiche" : "Chiudi finestra"}
                            </Button>
                            <Button variant='contained' disabled={isSaving || !canSaveVoti} color="success" sx={{ height: 'fit-content', ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={confermaModifiche}>
                                conferma e salva
                            </Button>
                        </Box>
                    </Box>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento voti...</Typography>
                </Box>
            )}


            <DialogConferma
                isDialogOpen={isAlertConfirmOpen}
                title={"Modifiche non salvate"}
                icon={<Info sx={{ fontSize: '5rem', my: 2 }} color={"primary"} />}
                text={"Ci sono delle modifiche non salvate. Procedendo alla chiusura della finestra, le modifiche andranno perse. Sei sicuro di voler continuare?"}
                showAlunni={false}
                confermaButton={"Conferma e procedi senza salvare"}
                severity={"primary"}
                closeDialog={() => { setIsAlertConfirmOpen(false) }}
                conferma={confirmChiusuraDialogAlert}
                disabledButton={false}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isAlertOpen} autoHideDuration={1500} onClose={() => { setIsAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setIsAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment>);
}