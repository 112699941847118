import { Fragment, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Container, Snackbar, Alert, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput, Paper, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthAPI } from '../../api/authApi';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Logo from "../../img/logo.png";

export default function RecuperoPin(props) {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [tokenValid, setTokenValid] = useState(false)

    const [showPassword, setShowPassword] = useState(false)

    const [newPin, setNewPin] = useState('');
    const [newPinValid, setNewPinValid] = useState(true)
    const [newPinMessage, setNewPinMessage] = useState('')

    const [confermaNewPin, setConfermaNewPin] = useState('')
    const [confermaNewPinValid, setConfermaNewPinValid] = useState(true)
    const [confermaNewPinMessage, setConfermaNewPinMessage] = useState('')

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token')

    const [errorMessage, setErrorMessage] = useState('');
    const [pinIsModificato, setPinIsModificato] = useState(false);

    useEffect(() => {
        if (urlToken !== undefined) verificaScadenzaTokenRecuperoPin()
    }, [])

    async function verificaScadenzaTokenRecuperoPin() {

        let obj = {
            token: urlToken,
        }

        AuthAPI.verificaScadenzaTokenRecuperoPin(obj)
            .then((result) => {
                setTokenValid(result.isAValidToken);
                setErrorMessage('il token è scaduto')
                if (result.isAValidToken === false) openAlertMsg('Spiacenti, il token è scaduto', 'error');
            })
            .catch((error) => {
                openAlertMsg('Spiacenti, qualcosa è andato storto', 'error')
                setErrorMessage('qualcosa è andato storto')
            })
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function closeAlertMsg() {
        // navigate("/")
    }

    async function cambioPin() {

        let obj = {
            token: urlToken,
            nuovoPin: newPin
        }

        AuthAPI.cambioPin(obj)
            .then((result) => {
                openAlertMsg('Pin modificato', 'success');
                setPinIsModificato(true)
                setTimeout(goToHome, 2000);
            })
            .catch((error) => {
                openAlertMsg('Spiacenti, qualcosa è andato storto', 'error')
            })
    }

    function validate() {
        if (newPin !== "" && confermaNewPin === newPin && newPin.length >= 4) {
            cambioPin();
            setConfermaNewPinMessage('');
            setNewPinMessage('')
            setConfermaNewPinValid(true);
            setNewPinValid(true);
        };
        if (newPin.length < 4 || newPin === "") {
            setNewPinValid(false);
            if (newPin.length < 4) setNewPinMessage('minimo 4 caratteri');
            if (newPin === "") setNewPinMessage(t("pages.login.blank"));
        } else {
            setNewPinValid(true);
            setNewPinMessage('')
        }
        if (confermaNewPin !== newPin) {
            setConfermaNewPinValid(false);
            setConfermaNewPinMessage('I pin non corrispondono');
        } else {
            setConfermaNewPinValid(true);
            setConfermaNewPinMessage('');
        }
    }

    function newPinSet(e) {
        let newPinTmp = e.target.value;
        let newPinNoSpaceTmp = newPinTmp.replaceAll(" ", "");
        setNewPin(newPinNoSpaceTmp)
    }

    function confermaNewPinSet(e) {
        let newPinTmp = e.target.value;
        let newPinNoSpaceTmp = newPinTmp.replaceAll(" ", "");
        setConfermaNewPin(newPinNoSpaceTmp);
    }

    function goToHome() {
        navigate("/home")
    }

    function showPasswordInput() {
        setShowPassword(prev => !prev);
    }

    return (
        <Fragment>
            <Container fixed sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                {!pinIsModificato ? (<Paper component="form" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4, width: { xs: '100vw', md: '40vw' } }}>
                    <img src={Logo} alt="CIOFS logo" height="70" />
                    {tokenValid ? (
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography mt={2} ><b>Recupero PIN</b></Typography>
                            <Box display={"flex"} flexDirection={'column'} justifyContent={"center"} sx={{ my: 2 }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password" color={!newPinValid ? 'error' : 'primary'}>Nuovo Pin</InputLabel>
                                    <OutlinedInput type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={showPasswordInput}  >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={newPinSet}
                                        label="Nuovo Pin"
                                        error={!newPinValid}
                                        required
                                        value={newPin}
                                    />
                                </FormControl>
                                <Typography variant='caption' sx={{ color: 'red' }}>{newPinMessage}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={'column'} justifyContent={"center"} >
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password" color={!confermaNewPinValid ? 'error' : 'primary'}>Conferma nuovo pin</InputLabel>
                                    <OutlinedInput type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={showPasswordInput}  >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={confermaNewPinSet}
                                        label="Conferma nuovo pin"
                                        error={(confermaNewPinValid) ? false : true}
                                        required
                                        value={confermaNewPin}
                                    />
                                </FormControl>
                                <Typography variant='caption' sx={{ color: 'red' }}>{confermaNewPinMessage}</Typography>
                            </Box>





                            <Button disabled={newPin === '' || confermaNewPin === ''} data-testid='loginBtn' onClick={validate} variant="contained" sx={{ mt: 2 }}>Conferma</Button>
                        </Box>
                    ) :
                        (<Box style={{ display: 'flex', flexDirection: 'column', }}>
                            <Typography sx={{ mt: 2 }}>
                                <b>Spiacenti, {errorMessage}.</b>
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                La preghiamo di ripetere l'operazione di recupero del pin.
                            </Typography>
                            <Button fullWidth sx={{ mt: 5 }} onClick={goToHome} startIcon={<ArrowBackIcon />} data-testid='backHomeBtn' variant="contained">
                                {t("pages.errorPage.backToHome")}
                            </Button>
                        </Box>)}
                </Paper>) : (<Paper component="form" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4 }}>
                    <img src={Logo} alt="CIOFS logo" height="70" />
                    <Box style={{ display: 'flex', flexDirection: 'column', }}>
                        <Typography sx={{ mt: 2 }}>
                            <b>Pin modificato.</b>
                        </Typography>
                    </Box>
                </Paper>
                )}
            </Container>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { closeAlertMsg() }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>{t("name")} - Recupero pin</title>
            </Helmet>
        </Fragment>
    );
}