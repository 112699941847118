import { Alert, Box, Button, Card, Collapse, DialogActions, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, SnackbarContent, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, ArrowForward, Check, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomDatePicker from "../../../shared/customDatePicker";
import OrarioFormativo from "../orarioFormativo";
import CustomTimePicker from "../../../shared/customTimePicker";

export default function VariazioneTutor(props) {
    const [idProgetto, setIdProgetto] = useState(-1);
    const [dataInizioVariazione, setDataInizioVariazione] = useState(null);
    // const [dataFineVariazione, setDataFineVariazione] = useState(null);

    const [nomeTutor, setNomeTutor] = useState('');
    const [cognomeTutor, setCognomeTutor] = useState('');
    const [telefonoTutor, setTelefonoTutor] = useState('');
    const [mailTutor, setMailTutor] = useState('');

    const [operazioneEffettuata, setOperazioneEffettuata] = useState('Variazione');
    const [idOperazioneEffettuata, setIdOperazioneEffettuata] = useState(1);

    const [soggettoModificato, setSoggettoModificato] = useState('Tutor Formativo');
    const [idSoggettoModificato, setIdSoggettoModificato] = useState(1);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);

    useEffect(() => {
        if (props.idProgetto !== undefined) {
            setIdProgetto(props.idProgetto);
        }
    }, [props.idProgetto])

    function changeDataInizioVariazione(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataInizioVariazione(utcDateTimeTmp);
    }

    // function changeDataFineVariazione(e) {
    //     let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
    //     setDataFineVariazione(utcDateTimeTmp);
    // }

    function changeCognomeTutor(e) {
        setCognomeTutor(e.target.value)
    }

    function changeNomeTutor(e) {
        setNomeTutor(e.target.value)
    }

    function changeTelefonoTutor(e) {
        setTelefonoTutor(e.target.value)
    }
    function changeMailTutor(e) {
        setMailTutor(e.target.value)
    }

    function changeRadioSelectOperazioneEffettuata(e) {
        let valueTmp = parseInt(e.target.value);
        setIdOperazioneEffettuata(valueTmp);
        if (valueTmp === 0) setOperazioneEffettuata('Inserimento');
        else setOperazioneEffettuata('Variazione');
    }

    function changeRadioSelectSoggettoModificato(e) {
        let valueTmp = parseInt(e.target.value);
        setIdSoggettoModificato(valueTmp);
        if (valueTmp === 0) setSoggettoModificato('Tutor Aziendale');
        else setSoggettoModificato('Tutor Formativo');
    }

    function closeVariazioneTutorDialog() {
        if (props.closeVariazioneTutorDialog) props.closeVariazioneTutorDialog()
    }

    function openAlertMsg(msg, severity) {
        if (props.openAlertMsg) props.openAlertMsg(msg, severity)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function validateFormData() {
        if (nomeTutor !== ''
            &&
            cognomeTutor !== ''
            &&
            telefonoTutor !== ''
            &&
            mailTutor !== ''
            &&
            // (
            dataInizioVariazione !== null
            // &&
            // dataFineVariazione !== null)
        ) return false
        else return true
    }

    async function variazioneProgetto() {
        setButtonConfermaDisabled(true);
        let obj = {
            idProgetto: idProgetto,
            tipologia: 3,
            dataInizioVariazione: dataInizioVariazione,
            dataFineVariazione: null,
            variazione: {
                operazioneEffettuata: operazioneEffettuata,
                soggettoModificato: soggettoModificato,
                nome: nomeTutor,
                cognome: cognomeTutor,
                telefono: telefonoTutor,
                mail: mailTutor,
            }
        }
        TirociniAPI.variazioneProgetto(obj)
            .then((result) => {
                setConfirmAlertIsOpen(false);
                closeVariazioneTutorDialog()
                let msgTmp = result.msg ?? 'Salvataggio variazione effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                setConfirmAlertIsOpen(false);

                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio variazione. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio variazione. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>
            <Box sx={{ py: 2, px: 4, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Paper elevation={1} sx={{ p: 2 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Data inizio variazione tutor</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={1}>
                        <Grid xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Typography textAlign={"center"} >Data inizio variazione</Typography>
                                            </TableCell>
                                            <TableCell
                                            // sx={{ border: '1px solid rgb(220,220,220)' }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <CustomDatePicker selectedValue={dataInizioVariazione}
                                                        // maxDate={dataFineVariazione}
                                                        // maxDate={dataFineTirocinio !== null ? dataFineTirocinio.minus({ days: 1 }) : null} 
                                                        label={"Giorno di inizio"} isFullWidth={false} onChangeValue={changeDataInizioVariazione} />
                                                </Box>
                                            </TableCell>
                                            {/* <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <CustomDatePicker selectedValue={dataFineVariazione}
                                                        minDate={dataInizioVariazione}
                                                        // minDate={dataInizioTirocinio !== null ? dataInizioTirocinio.plus({ days: 1 }) : null} 
                                                        label={"Giorno di fine"} isFullWidth={false} onChangeValue={changeDataFineVariazione} />
                                                </Box>
                                            </TableCell> */}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                            {dataInizioVariazione === null && (
                                <Alert sx={{ mt: 2 }} severity="error">Per proseguire è necessario indicare inizio della variazione.</Alert>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Prospetto variazione tutor</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={1}>
                        <Grid xs={12} md={6} >
                            <Box sx={{ p: 2, border: 'solid 1px rgb(220,220,220)', borderRadius: '6px' }} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Typography>Seleziona l'operazione da effettuare:</Typography>
                                <RadioGroup
                                    defaultValue={idOperazioneEffettuata}
                                    value={idOperazioneEffettuata}
                                    sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}
                                    onChange={changeRadioSelectOperazioneEffettuata}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label="Inserimento" />
                                    <FormControlLabel value={1} control={<Radio />} label="Variazione" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6} >
                            <Box sx={{ p: 2, border: 'solid 1px rgb(220,220,220)', borderRadius: '6px' }} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Typography>Seleziona il soggetto:</Typography>
                                <RadioGroup
                                    defaultValue={idSoggettoModificato}
                                    value={idSoggettoModificato}
                                    sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}
                                    onChange={changeRadioSelectSoggettoModificato}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label="Tutor aziendale" />
                                    <FormControlLabel value={1} control={<Radio />} label="Tutor formativo" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Anagrafica tutor</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={1}>
                        <Grid xs={12} md={6}>
                            <TextField
                                defaultValue={nomeTutor ?? ''}
                                value={nomeTutor ?? ''}
                                required
                                label={"Nome"}
                                onChange={changeNomeTutor}
                                sx={{ width: '100%' }}
                            // error={nomeTutor === ''}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                defaultValue={cognomeTutor ?? ''}
                                value={cognomeTutor ?? ''}
                                required
                                label={'Cognome'}
                                onChange={changeCognomeTutor}
                                sx={{ width: '100%' }}
                            // error={cognomeTutor === ''}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                defaultValue={mailTutor ?? ''}
                                value={mailTutor ?? ''}
                                required
                                label={"E-mail"}
                                onChange={changeMailTutor}
                                sx={{ width: '100%' }}
                            // error={mailTutor === ''}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                defaultValue={telefonoTutor ?? ''}
                                value={telefonoTutor ?? ''}
                                required
                                label={"Telefono"}
                                onChange={changeTelefonoTutor}
                                sx={{ width: '100%' }}
                            // error={telefonoTutor === ''}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Divider />
            <DialogActions>
                <Box sx={{ mx: { xs: 2, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: '100%', }}>
                    <Button
                        sx={{ width: { xs: '100%', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mr: { xs: 0, md: 1 } }}
                        onClick={closeVariazioneTutorDialog}
                        variant="outlined" fullWidth color={'error'} >Chiudi e annulla variazione</Button>
                    <Button
                        sx={{ width: { xs: '100%', md: 'fit-content' } }}
                        onClick={() => setConfirmAlertIsOpen(true)}
                        disabled={validateFormData()}
                        variant="contained" fullWidth color={'success'} >Conferma variazione</Button>
                </Box>
            </DialogActions>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma salvataggio'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler salvare la variazione tutor? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={variazioneProgetto}
                disabledButton={buttonConfermaDisabled}
            />
        </Fragment >)
}