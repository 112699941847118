import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {DialogActions, Divider, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, useTheme, Breadcrumbs, useMediaQuery, IconButton, Alert, Snackbar, Link, InputLabel, Select, MenuItem, FormControl, } from '@mui/material';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import { ArrowBack, EditCalendar, Close } from "@mui/icons-material";
import { DateTime } from "luxon";

import EventoChip from "../registri/shared/eventoChip";

import {  convertISODate, convertDataGetProssimeLezione, convertDataDiNascita } from '../shared/metodiDateUtility';
import { RegistroAPI } from "../../api/registroApi";
import Legende from "../registri/shared/legende";
import DettaglioAlunno from "../dettaglio-utente/dettaglioAlunno";
import SelezioneClassiTutor from "./selezioneClassiTutor";

export default function RiepilogoAttivitaFormativa(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true)
    const [permessiUtente, setPermessiUtente] = useState([]);

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");
    const [nomeMateria, setNomeMateria] = useState("");
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);


    const [listAlunni, setListAlunni] = useState([]);
    const [dialogDettaglioRegistroOpen, setDialogDettaglioRegistroOpen] = useState(false);

    const [trimestri, setTrimestri] = useState([]);
    const [trimestreSelezionatoAppoggio, setTrimestreSelezionatoAppoggio] = useState(-1);
    const [trimestreSelezionato, setTrimestreSelezionato] = useState(-1);

    //Periodo di interesse
    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");

    const [changeTrimestreOpen, setChangeTrimestreOpen] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    //DETTAGLIO ALUNNO
    const [dettaglioAlunnoOpen, setDettaglioAlunnoOpen] = useState(false);
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(null);
    const [indexTabToOpen, setIndexTabToOpen] = useState(0);
    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [sedeUtente, setSedeUtente] = useState('');

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)
        }

        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);

        }

        if (localStorage.getItem("sede")) {
            let sedeTmp = localStorage.getItem("sede");
            let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let sedeParsed = JSON.parse(decryptedSede);
            setSedeUtente(sedeParsed.id)
        }

    }, [])



    function goToSelezionaOra() {
        setDialogSelezionaClasseOpen(true);
    }


    function changeIdClasseSelezionata(idClasse, dataInizio, dataFine, idMateria, nomeClasse, trimestri, trimestreSelezionato, nomeMateria) {
        if (idClasse !== undefined) {
            let idCorsoCrypted = CryptoJS.AES.encrypt(idClasse.toString(), process.env.REACT_APP_PRIVATE_KEY).toString();
            sessionStorage.setItem("classe", idCorsoCrypted);
            setIdClasseSelezionata(idClasse);
            setIdMateriaSelezionata(idMateria);
            setNomeClasse(nomeClasse);
            setDialogSelezionaClasseOpen(false);

            setNomeMateria(nomeMateria)
            setTrimestri([...trimestri])
            setTrimestreSelezionato(trimestreSelezionato);
            setTrimestreSelezionatoAppoggio(trimestreSelezionato);

            getRiepilogoClasse(idClasse, dataInizio, dataFine, idMateria);
        }
    }


    function openCambiaTrimestreDialog() {
        setTrimestreSelezionatoAppoggio(trimestreSelezionato)
        setChangeTrimestreOpen(prev => !prev)
    }

    function changeTrimestre(e) {
        setTrimestreSelezionatoAppoggio(e.target.value);
    }




    function closeDettaglioAlunno() {
        setDettaglioAlunnoOpen(false);
        getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function eventoModificato(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
        getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }
    function vediDettaglioAlunno(id, indexTab, nome, cognome, ritardo) {
        setOpenDialogRitardo(ritardo)
        setIdAlunnoSelezionato(id);
        setNomeAlunnoSelezionato(nome + " " + cognome);
        setDettaglioAlunnoOpen(true);
        setIndexTabToOpen(indexTab);
    }



    function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
        let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
        let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

        let dataInizioObject = DateTime.fromISO(dataInizioTmp);
        let dataFineObject = DateTime.fromISO(dataFineTmp);

        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
        if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        else if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
        {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else return null
    }


    async function getRiepilogoClasse(idClasseSelezionata, dataDiInteresseInizio, dataDiInteresseFine, idMateria) {
        let dataObj = {
            dataRichiestaDa: dataDiInteresseInizio,
            dataRichiestaA: dataDiInteresseFine,
            idCorso: idClasseSelezionata,
            idMateria: idMateria
        }
        setPeriodoDiInteresseInizio(dataDiInteresseInizio);
        setPeriodoDiInteresseFine(dataDiInteresseFine);

        setFormattedPeriodoDiInteresseInizio(convertISODate(dataDiInteresseInizio));
        setFormattedPeriodoDiInteresseFine(convertISODate(dataDiInteresseFine));
        RegistroAPI.getRiepilogoFormativo(dataObj)
            .then((result) => {
                if (result.alunni.length > 0) {
                    let alunniTmp = result.alunni;
                    for (let i = 0; i < alunniTmp.length; i++) {
                        alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

                    }
                    setListAlunni([...alunniTmp])
                    setHasLoaded(true)
                }
            })
            .catch(function (error) { })
    }


    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function changeSede(nome) {
        setSedeUtente(nome)
    }

    function changeNomeMateria(nome) {
        setNomeMateria(nome)
    }

    function getRegistro() {
        getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function confirmChangeTrimestre() {
        setTrimestreSelezionato(trimestreSelezionatoAppoggio)
        let dataInizio = trimestri[trimestreSelezionatoAppoggio].dataInizio;
        let dataFine = trimestri[trimestreSelezionatoAppoggio].dataFine
        getRiepilogoClasse(idClasseSelezionata, dataInizio, dataFine, idMateriaSelezionata);
        setChangeTrimestreOpen(false);

    }

    return (
        <Fragment>
            {(idClasseSelezionata !== -1 && dialogSelezionaClasseOpen === false) || (dialogDettaglioRegistroOpen === true) ? (
                hasLoaded ? (
                    <Box sx={{ px: 5 }}>
                        <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
                            <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                            <Typography color="text.disabled">Riepilogo attività formativa</Typography>
                            <Typography color="text.disabled">{nomeClasse}</Typography>
                        </Breadcrumbs>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                            <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                Torna alla selezione della classe
                            </Button>
                            <Button variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} startIcon={<EditCalendar />} onClick={openCambiaTrimestreDialog}>
                                Cambia trimestre
                            </Button>
                            {/* <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                Mostra legenda
                            </Button> */}
                        </Box>
                        <Box px={{ xs: 1, md: 5 }} display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: "start", md: 'start' }} mt={2}>
                            <Box sx={{ width: { xs: '100%', md: 'unset' }, mr: { xs: 0, md: 2 } }}>
                                <Typography variant="caption">
                                    Corso:
                                </Typography>
                                <Typography color={'primary'}
                                    variant={mobile ? 'caption' : 'body1'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>
                                    {(nomeClasse ?? "").toUpperCase()}
                                </Typography>
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
                                <Typography variant="caption">
                                    Periodo:
                                </Typography>
                                <Typography variant={mobile ? 'caption' : 'body1'} sx={{ fontWeight: { xs: 'bold', md: '' } }}>
                                    {trimestri[trimestreSelezionato].nome + " (" + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + ")"}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                            <TableContainer sx={{ width: '95%' }} component={Paper}>
                                <Table >
                                    <TableHead>
                                        {/* <TableRow>
                                            <TableCell style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                                <Typography variant="caption">
                                                    Corso:
                                                </Typography>
                                                <Typography color={'primary'}
                                                    variant={mobile ? 'caption' : 'body1'}
                                                    sx={{ fontWeight: 'bold', mr: 1 }}>
                                                    {(nomeClasse ?? "").toUpperCase()}
                                                </Typography>
                                                <Typography variant="caption">
                                                    Periodo:
                                                </Typography>
                                                <Typography>
                                                    {trimestri[trimestreSelezionato].nome + " (" + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + ")"}
                                                </Typography>
                                                <Typography variant="caption">
                                                    Materia:
                                                </Typography>
                                                <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                    variant={mobile ? 'caption' : 'body1'}>
                                                    {nomeMateria}
                                                </Typography>
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            {/* <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                                <Typography variant="caption">
                                                    Corso:
                                                </Typography>
                                                <Typography color={'primary'}
                                                    variant={mobile ? 'caption' : 'body1'}
                                                    sx={{ fontWeight: 'bold', mr: 1 }}>
                                                    {(nomeClasse ?? "").toUpperCase()}
                                                </Typography>
                                                <Typography variant="caption">
                                                    Periodo:
                                                </Typography>
                                                <Typography>
                                                    {trimestri[trimestreSelezionato].nome + " (" + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + ")"}
                                                </Typography>
                                                <Typography variant="caption">
                                                    Materia:
                                                </Typography>
                                                <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                    variant={mobile ? 'caption' : 'body1'}>
                                                    {nomeMateria}
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell className='colonna-nomi row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2, zIndex: 4 }}>
                                                <Typography variant="caption">
                                                    Materia:
                                                </Typography>
                                                <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                    variant={mobile ? 'caption' : 'body1'}>
                                                    {nomeMateria}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign="center">Presenze</Typography></TableCell>
                                            {/* <TableCell className='row-head' style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign="center">Media</Typography></TableCell> */}
                                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Voti scritti</Typography></TableCell>
                                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Voti pratici</Typography></TableCell>
                                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Voti orali</Typography></TableCell>
                                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Valutazioni</Typography></TableCell>
                                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Accertamento prerequisiti</Typography></TableCell>

                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {listAlunni.map((alunno, index) => (
                                            <TableRow style={{ height: '80px' }} key={alunno.id}>
                                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    <Box sx={{ backgroundColor: 'white', px: 2, py: 5.5 }}>
                                                        <Box display={"flex"} alignItems={"center"}>
                                                            <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                                            <Typography variant={mobile ? 'caption' : 'body1'} onClick={() => vediDettaglioAlunno(alunno.id, 0, alunno.nome, alunno.cognome, false)} sx={{ cursor: 'pointer', mr: 2, fontWeight: alunno.isMaggiorenne ? 'bold' : 'unset' }}>
                                                                {(alunno.cognome).toUpperCase() + " " + (alunno.nome).toUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                        {alunno.msgIscrizione != null ? (
                                                            <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                        ) : null}
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    <Box sx={{ ml: 2 }}>
                                                        {alunno?.presenze?.orePresenzaMateria !== undefined ? (
                                                            <Typography width={"fit-content"}>Ore di presenza: <b>{alunno?.presenze?.orePresenzaMateria}</b></Typography>
                                                        ) : null}
                                                        {alunno?.presenze?.oreTotaliMateria !== undefined ? (
                                                            <Typography width={"fit-content"}>Ore assegnate: <b>{alunno?.presenze?.oreTotaliMateria}</b></Typography>
                                                        ) : null}
                                                        {alunno?.presenze?.oreMateriaPerAnno !== undefined ? (
                                                            <Typography width={"fit-content"}>Ore totali: <b>{alunno?.presenze?.oreMateriaPerAnno}</b></Typography>
                                                        ) : null}
                                                    </Box>

                                                </TableCell>
                                                {/* <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}></TableCell> */}
                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno?.comunicazioni?.votiScritti.length > 0 ? (
                                                        <Box display="flex" justifyContent={'space-around'}>
                                                            {alunno?.comunicazioni?.votiScritti.map((comunicazione) => (
                                                                < Fragment key={alunno.id + comunicazione.id} >
                                                                    <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno.comunicazioni?.votiPratici.length > 0 ? (
                                                        <Box display="flex" justifyContent={'space-around'}>
                                                            {alunno?.comunicazioni?.votiPratici.map((comunicazione) => (
                                                                < Fragment key={alunno.id + comunicazione.id} >
                                                                    <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno?.comunicazioni?.votiOrali.length > 0 ? (
                                                        <Box display="flex" justifyContent={'space-around'}>
                                                            {alunno?.comunicazioni?.votiOrali.map((comunicazione) => (
                                                                < Fragment key={alunno.id + comunicazione.id} >
                                                                    <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno?.comunicazioni?.valutazioni.length > 0 ? (
                                                        <Box display="flex" justifyContent={'space-around'}>
                                                            {alunno?.comunicazioni?.valutazioni.map((comunicazione) => (
                                                                < Fragment key={alunno.id + comunicazione.id} >
                                                                    <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno?.comunicazioni?.accertamentoPrerequisiti.length > 0 ? (
                                                        <Box display="flex" justifyContent={'space-around'}>
                                                            {alunno?.comunicazioni?.accertamentoPrerequisiti.map((comunicazione) => (
                                                                < Fragment key={alunno.id + comunicazione.id} >
                                                                    <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento registro...</Typography>
                    </Box>
                )
            ) : null
            }

            <Dialog fullWidth maxWidth={"md"} open={changeTrimestreOpen}>
                <DialogTitle>Cambia trimestre di interesse</DialogTitle>
                <DialogContent>
                    <FormControl sx={{ width: '100%', mt: 2 }} >
                        <InputLabel id="materia-label"> Seleziona trimestre</InputLabel>
                        <Select
                            label='Seleziona trimestre '
                            value={trimestreSelezionatoAppoggio}
                            onChange={changeTrimestre}
                        >
                            <MenuItem
                                key={-1}
                                value={-1}
                            >
                            </MenuItem>
                            {trimestri.map((trimestre, index) => (
                                <MenuItem
                                    key={trimestre.ordinale}
                                    value={index}
                                >
                                    {trimestre.nome} {"(" + convertISODate(trimestre.dataInizio) + " - " + convertISODate(trimestre.dataFine) + ")"}
                                </MenuItem>
                            ))}
                        </Select>
                        {trimestri.length === 0 ? (<Typography color="error" variant="caption">Nessun trimestre impostato per la classe selezionato</Typography>) : null}
                    </FormControl>
                    <Box display="flex" justifyContent="end" sx={{ mt: 2 }}>
                        <Button sx={{ mr: 2 }} variant="outlined" color="error" onClick={openCambiaTrimestreDialog}>Chiudi</Button>
                        <Button variant="contained" onClick={confirmChangeTrimestre}>Conferma</Button>
                    </Box>

                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: 8 }}>
                <DialogTitle>Seleziona classe e materia di riferimento</DialogTitle>
                <DialogContent>
                    <SelezioneClassiTutor sedeUtente={sedeUtente} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} changeNomeMateria={changeNomeMateria} registro={''}/>
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dettaglioAlunnoOpen} onClose={closeDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({nomeAlunnoSelezionato})</Typography>
                    <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno showBes={false} tabIndex={indexTabToOpen} idDocente={idDocente} id={idAlunnoSelezionato} idCorso={idClasseSelezionata}
                        nomeAlunno={nomeAlunnoSelezionato} ritardo={openDialogRitardo} closeDettaglioAlunno={closeDettaglioAlunno} openAlertMsg={openAlertMsg} />
                </DialogContent>
            </Dialog>


            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <span>Legenda</span>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Legende />
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


            <Helmet>
                <title>{t("name")} - Riepilogo attività formativa</title>
            </Helmet>
        </Fragment >
    )
}

// const VirtualizedTable = ({ arrayGiorni, nomeMateriaSelezionata, nomeClasse, mobile }) => {
//     const rowRenderer = ({ index, key, style }) => (
//         <TableRow key={key} style={style}>
//             <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
//                 <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
//                     <Typography color={'primary'}
//                         variant={mobile ? 'caption' : 'body1'}
//                         sx={{ fontWeight: 'bold', mr: 1 }}>{(nomeClasse ?? "").toUpperCase()}</Typography>
//                 </Box>
//             </TableCell>
//             {arrayGiorni.map((giorno) => (
//                 <TableCell className={giorno?.chiusura ? 'disabledCell' : ""} key={"dettaglio_" + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
//                     <Fragment>
//                         <Box justifyContent={"center"} alignItems={"center"} display="flex" flexDirection={"column"} >
//                             {giorno?.chiusura === false ? (
//                                 <Box onClick={() => openDettaglioRegistro(giorno.data)} width={"30px"} height={"30px"}
//                                     border={"1px solid #1976d2"}
//                                     borderColor={"primary"}
//                                     backgroundColor={"white"}
//                                     display={"flex"} alignItems={"center"} justifyContent={"center"}
//                                     sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
//                                 </Box>
//                             ) : null}
//                         </Box>
//                     </Fragment>
//                 </TableCell>
//             ))}
//         </TableRow>
//     );

//     return (
//         <AutoSizer>
//             {({ height, width }) => (
//                 <>
//                     <TableHead>
//                         <TableRow>
//                             <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
//                                 <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
//                                     variant={mobile ? 'caption' : 'body1'}>{nomeMateriaSelezionata.toUpperCase()}</Typography>
//                             </TableCell>
//                             {arrayGiorni.map((giorno) => (
//                                 <TableCell className='row-head' key={"giorno_" + giorno?.data} style={{ backgroundColor: 'white', p: 2, textAlign: 'center', minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
//                                     <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{getDayAndMonth(giorno?.data)}</Typography>
//                                     <Typography variant="subtitle2">{getDayOfTheWeek(giorno?.data)}</Typography>
//                                     {giorno?.chiusura ? (
//                                         <Typography color="primary" variant="subtitle2"><b>CHIUSURA</b></Typography>

//                                     ) : null}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     </TableHead>

//                     <List
//                         height={height}
//                         rowCount={arrayGiorni.length}
//                         rowHeight={48}
//                         rowRenderer={rowRenderer}
//                         width={width}
//                     />
//                 </>
//             )}
//         </AutoSizer>
//     );
// };