import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const BesAPI = {
    insertBes: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/modifica`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deleteBes: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/rimuovi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    visualizzaBesOperatori: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/visualizza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getElencoOperatori: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/elencoOperatori`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    visualizzaBesDocenti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/visualizzaCorso`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getBesList: async function ( cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/BES/elenchiFE2`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    // getElencoOperatori: async function (cancel = false) {
    //     const response = await api.request({
    //         sendToken: true,
    //         url: `/alunni/BES/elencoOperatori`,
    //         method: "GET",
    //         signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    //     })

    //     return response.data
    // },
}
const cancelApiObject = defineCancelApiObject(BesAPI)