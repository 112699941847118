import React from 'react';
import { useState, useEffect, Fragment } from "react"
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box, Typography, Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { convertISODate } from '../../shared/metodiDateUtility';


export default function SchedaAlunnoNoteAnnotazioni(props) {

    const [listAnnotazioni, setListAlunni] = useState([]);
    const [listNote, setListNote] = useState([]);

    const [dettaglioAnnotazioneOpen, setDettaglioAnnotazioneOpen] = useState(false);
    const [dettaglioNotaOpen, setDettaglioNotaOpen] = useState(false);

    const [annotazioneSel, setAnnotazioneSel] = useState(null)
    const [notaSel, setNotaSel] = useState(null)

    useEffect(() => {
        if (props.listAnnotazioni !== undefined) {
            setListAlunni([...props.listAnnotazioni])
        }
    }, [props.listAnnotazioni])

    useEffect(() => {
        if (props.listNote !== undefined) {
            setListNote([...props.listNote])
        }
    }, [props.listNote])

    function openDettaglioAnnotazione(annotazione) {
        setAnnotazioneSel(annotazione);
        setDettaglioAnnotazioneOpen(true);
    }

    function closeDettaglioAnnotazione() {
        setDettaglioAnnotazioneOpen(false);
    }

    function openDettaglioNota(nota) {
        setNotaSel(nota);
        setDettaglioNotaOpen(true);
    }

    function closeDettaglioNota() {
        setDettaglioNotaOpen(false);
    }

    return (
        <Fragment>
            <Grid container sx={{ mt: 2, width: '100%' }} spacing={2}>
                <Grid xs={12} md={6} >
                    <Paper sx={{ p: 2, mb: 1 }}>
                        <Typography variant="h6">Elenco annotazioni</Typography>
                    </Paper>
                    <Paper sx={{ height: '30vh', px: 2, overflowY: 'scroll' }}>
                        {listAnnotazioni.length > 0 ? (

                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Data di inserimento</TableCell>
                                        <TableCell>Data lezione</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listAnnotazioni.map((annotazione, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">{convertISODate(annotazione?.created_at)}</TableCell>
                                            <TableCell component="th" scope="row">{convertISODate(annotazione?.contenuto?.dataLezione)}</TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                                <Button size="small" variant="contained" data-testid='leggiComBtn' onClick={() => openDettaglioAnnotazione(annotazione)}>Apri dettaglio</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        ) : (
                            <Box sx={{ width: '100%', height: '30vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography >Nessun'annotazione</Typography>
                            </Box>
                        )}
                    </Paper>
                </Grid>
                <Grid xs={12} md={6} >
                    <Paper sx={{ p: 2, mb: 1 }}>
                        <Typography variant="h6">Elenco note</Typography>
                    </Paper>
                    <Paper sx={{ height: '30vh', px: 2, overflowY: 'scroll' }}>
                        {listNote.length > 0 ? (
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Data di inserimento</TableCell>
                                        <TableCell>Nota di classe</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listNote.map((nota, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {convertISODate(nota?.data_comunicazione)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {nota?.contenuto?.isInteraClasse ? "SI" : "NO"}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                                <Button size="small" onClick={() => openDettaglioNota(nota)} variant="contained" data-testid='leggiComBtn'>Apri dettaglio</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        ) : (
                            <Box sx={{ width: '100%', height: '30vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography >Nessuna nota disciplinare</Typography>
                            </Box>
                        )}

                    </Paper>
                </Grid>
            </Grid>

            <Dialog fullWidth maxWidth={"md"} open={dettaglioAnnotazioneOpen} onClose={closeDettaglioAnnotazione} sx={{ mt: '8vh' }}>
                <DialogTitle>
                    Dettaglio annotazione
                </DialogTitle>
                <DialogContent>
                    <Typography><b>Data di inserimento:</b>  {convertISODate(annotazioneSel?.created_at)} {'(Inserito da: ' + annotazioneSel?.created_by_obj?.nome + ')'};</Typography>
                    {(annotazioneSel?.modified_at !== null) && (
                        <Typography><b>Data di modifica:</b>  {convertISODate(annotazioneSel?.modified_at)}  {'(Modificato da: ' + annotazioneSel?.modified_by_obj?.nome + ')'};</Typography>
                    )}
                    {(annotazioneSel?.contenuto?.isInteraClasse !== undefined && annotazioneSel?.contenuto?.isInteraClasse === true) && (
                        <Typography color="primary">ANNOTAZIONE DI CLASSE</Typography>
                    )}
                    <Box sx={{ mt: 2, backgroundColor: 'white', height: 'fit-content' }} className="fakeInputBoxFullWidth">
                        <Typography textAlign={"start"}> {annotazioneSel?.contenuto?.note}</Typography>
                    </Box>
                    <Button variant="outlined" fullWidth sx={{ mt: 2 }} onClick={closeDettaglioAnnotazione}>Chiudi dettaglio annotazione</Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dettaglioNotaOpen} onClose={closeDettaglioNota} sx={{ mt: '8vh' }}>
                <DialogTitle>
                    Dettaglio nota
                </DialogTitle>
                <DialogContent>
                    <Typography><b>Data di inserimento:</b>  {convertISODate(notaSel?.created_at)} {'(Inserito da: ' + notaSel?.created_by_obj?.nome + ')'};</Typography>
                    {(notaSel?.modified_at !== null) && (
                        <Typography><b>Data di modifica:</b>  {convertISODate(notaSel?.modified_at)}  {'(Modificato da: ' + notaSel?.modified_by_obj?.nome + ')'};</Typography>
                    )}
                    {(notaSel?.contenuto?.isInteraClasse !== undefined && notaSel?.contenuto?.isInteraClasse === true) && (
                        <Typography color="primary">NOTA DI CLASSE</Typography>
                    )}
                    {notaSel?.contenuto?.categoria?.descrizione !== undefined && (
                        <Typography><b>Categoria:</b>  {notaSel?.contenuto?.categoria?.descrizione}</Typography>
                    )}
                    <Box sx={{ mt: 2, backgroundColor: 'white', height: 'fit-content' }} className="fakeInputBoxFullWidth">
                        <Typography textAlign={"start"}> {notaSel?.contenuto?.note}</Typography>
                    </Box>
                    <Button variant="outlined" fullWidth sx={{ mt: 2 }} onClick={closeDettaglioNota}>Chiudi dettaglio nota</Button>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}
