import { useEffect, useState } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { ArrowBack, Help } from "@mui/icons-material";
import RowAlunnoDettaglio from "./rowAlunnoDettaglioValidazione";
import { TirociniAPI } from "../../../../api/tirociniApi";
import { convertDataDiNascita } from "../../../shared/metodiDateUtility";

export default function DettaglioGiornataValidazione(props) {

    const [canEditPresenze, setCanEditPresenze] = useState(false);
    const [listAlunni, setListAlunni] = useState([]);
    const [nomeCorso, setNomeCorso] = useState("");
    const [dataDettaglio, setDataDettaglio] = useState("");
    const [idCorso, setIdCorso] = useState(0);

    const [isSaving, setIsSaving] = useState(false);

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    useEffect(() => {
        if (props.idCorso) setIdCorso(props.idCorso)
    }, [props.idCorso])

    useEffect(() => {
        if (props.idCorso && props.dataDettaglio) {
            getDettaglioGiornata(props.idCorso, props.dataDettaglio);
        }
    }, [props.idCorso, props.dataDettaglio])

    useEffect(() => {
        if (props.nomeCorso) setNomeCorso(props.nomeCorso);
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.dataDettaglio) setDataDettaglio(props.dataDettaglio);
    }, [props.dataDettaglio])

    function closeDialogDettaglioGiornaliero() {
        if (props.closeDialogDettaglio) props.closeDialogDettaglio();
    }

    async function getDettaglioGiornata(idCorso, data) {
        let dataObj = {
            idCorso: idCorso,
            data: data
        }
        TirociniAPI.getDettaglioGiornalieroPresenze(dataObj)
            .then((result) => {
                setCanEditPresenze(result?.canEditPresenze)
                setListAlunni([...result?.alunni])
            })
            .catch(function (error) {
                if (error.response) { }
            })
    }

    function openDialogLegenda() {
        if (props.openDialogLegenda)
            props.openDialogLegenda()
    }

    function modificaValidazioneAlunni(alunno, indexAlunno) {
        let listAlunniTmp = [...listAlunni]
        listAlunniTmp[indexAlunno] = alunno
        setListAlunni([...listAlunniTmp])
    }

    async function savePresenzeAlunni() {
        let dataObj = {
            "idCorso": idCorso,
            "data": dataDettaglio,
            "alunni": listAlunni
        }
        TirociniAPI.saveValidazioniPresenze(dataObj)
            .then((result) => {
                if (props.validazioniSalvate)
                    props.validazioniSalvate()
                else {
                    setAlertSeverity("success");
                    setAlertMsg("Modifiche salvate correttamente");
                    setIsAlertOpen(true)
                    setIsSaving(false);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio delle modifiche. Riprovare tra qualche istante."
                    setAlertSeverity("error");
                    setAlertMsg(errorMsg);
                    setIsAlertOpen(true)
                    setIsSaving(false);
                }
            })

    }

    return (
        <Box>
            <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: 'start', md: 'space-between' }} width={'100%'}>
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Button disabled={isSaving} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />} onClick={closeDialogDettaglioGiornaliero}>
                        Torna indietro
                    </Button>
                    <Button disabled={isSaving} onClick={openDialogLegenda} startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" >
                        Mostra legenda
                    </Button>
                </Box>
                <Box sx={{ width: '100%', mt: { xs: 1, md: 0 }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'end' }}>
                    <Button disabled={isSaving} sx={{ width: { xs: '100%', md: 'unset' } }} variant="outlined" color="error" onClick={closeDialogDettaglioGiornaliero}>
                        Annulla modifiche
                    </Button>

                    <Button disabled={isSaving} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="contained" color="success" onClick={savePresenzeAlunni}>
                        Conferma validazione
                    </Button>
                </Box>
            </Box>
            <Box sx={{ border: '1px solid #eaeaea', borderRadius: '15px', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center', overflow: 'scroll' }}>
                <TableContainer sx={{ width: '100%' }} >
                    <Table sx={{ minWidth: 100 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"
                                    className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                                    <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        borderRight: '1px solid black',
                                        backgroundColor: 'white',
                                        height: '70px',
                                        px: 1,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                    }}>
                                        <Typography ><b>Giornata del {convertDataDiNascita(dataDettaglio)}</b></Typography>
                                        <Typography color={'primary'}
                                            variant={"caption"}
                                            sx={{ fontWeight: 'bold', textAlign: 'start' }}>
                                            {(nomeCorso ?? "").toUpperCase()}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className="colonna-nomi row-head" sx={{ p: 0, zIndex: 6 }}>
                                    <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        borderRight: '1px solid black',
                                        backgroundColor: 'white',
                                        height: '70px',
                                        px: 1,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Typography variant="body2"> Giornata</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className="colonna-nomi row-head" sx={{ p: 0, zIndex: 6 }}>
                                    <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        height: '70px',
                                        px: 1,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Typography variant="body2">Validata</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                                    <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        height: '70px',
                                        px: 1,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Typography variant="body2">1^ Fascia</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                                    <Box sx={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        height: '70px',
                                        px: 1,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Typography variant="body2">2^ Fascia</Typography>
                                    </Box>
                                </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {listAlunni.map((alunno, index) => (
                                <RowAlunnoDettaglio idCorso={idCorso} canEditPresenze={canEditPresenze} alunno={alunno} indexAlunno={index} dataDettaglio={dataDettaglio} modificaValidazione={modificaValidazioneAlunni} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={isAlertOpen} autoHideDuration={10000} onClose={() => { setIsAlertOpen(false) }}>
                <Alert onClose={() => { setIsAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Box>
    )
}


