import { useState, useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import CustomIconButton from "../../shared/customIconButton";
import { Delete } from "@mui/icons-material";
import { convertDataDiNascita } from "../../shared/metodiDateUtility";
import { TirociniAPI } from "../../../api/tirociniApi";
import DialogConferma from "../../shared/dialogConferma";
import { Fragment } from "react";

export default function TableElencoVariazioni(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);
    const [idProgetto, setIdProgetto] = useState(-1);
    const [idVariazione, setIdVariazione] = useState(-1);

    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [codiceProgetto, setCodiceProgetto] = useState('');

    useEffect(() => {
        if (props.codiceProgetto) {
            setCodiceProgetto(props.codiceProgetto);
        }
    }, [props.codiceProgetto])

    useEffect(() => {
        if (props.idProgetto !== undefined) {
            setIdProgetto(props.idProgetto);
            elencoVariazioni(props.idProgetto)
        }
    }, [props.idProgetto])

    function closeDialog() {
        if (props.closeDialog) props.closeDialog();
    }

    function openAlertMsg(msg, severity) {
        if (props.openAlertMsg) props.openAlertMsg(msg, severity);
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
        setIdVariazione(-1);
    }
    function openAlertConfirmInserimento(idVariazione) {
        setIdVariazione(idVariazione);
        setConfirmAlertIsOpen(true);
    }

    async function elencoVariazioni(idProgetto) {
        let obj = {
            idProgetto: idProgetto
        }
        TirociniAPI.elencoVariazioni(obj)
            .then((result) => {
                setList(result)
                setTimeout(() => {
                    setIsLoading(false)
                }, 100);
            })
            .catch(function (error) {
            })
    }

    async function eliminaVariazioneProgetto() {
        setButtonConfermaDisabled(true);
        let obj = {
            idVariazione: idVariazione
        }
        TirociniAPI.eliminaVariazioneProgetto(obj)
            .then((result) => {
                setButtonConfermaDisabled(false);
                let msgTmp = result.msg ?? "Modifica dello stato del progetto avvenuta con successo..";
                openAlertMsg(msgTmp, 'success');
                closeDialog();
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante la modifica di stato. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante la modifica di stato. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })
    }

    return (
        <Fragment>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Typography >Cod. progetto: <b>{(codiceProgetto ?? '').toUpperCase()}</b></Typography>
            </Paper>
            <Paper>
                <TableContainer sx={{ width: '100%' }} >
                    <Table sx={{ maxHeight: '40vh' }}>
                        <TableHead>
                            <TableRow >

                                <TableCell align="center"
                                    className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                >
                                    <Box sx={{
                                        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        // px: 1,
                                        minHeight: '40px',
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" textAlign={"center"}><b>Tipologia variazione</b></Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                >
                                    <Box sx={{
                                        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        // px: 1,
                                        minHeight: '40px',
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" textAlign={"center"}><b>Data inizio</b></Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                >
                                    <Box sx={{
                                        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        // px: 1,
                                        minHeight: '40px',
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" textAlign={"center"}><b>Data fine</b></Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center"
                                    className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                >
                                    <Box sx={{
                                        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: 'white',
                                        // px: 1,
                                        minHeight: '40px',
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                    }}>
                                        <Typography variant="body2" textAlign={"center"}><b>Creato da</b></Typography>
                                    </Box>
                                </TableCell>
                                {/* <TableCell align="center"
                                    className=" row-head" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                >
                                    <Box sx={{
                                        p: 1,
                                        backgroundColor: 'white',
                                        // px: 1,
                                        display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center'
                                    }}>
                                        <Typography variant={mobile ? "caption" : "body1"} textAlign={"center"}><b>Elimina</b></Typography>
                                    </Box>
                                </TableCell> */}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {list.map((item, index) => (
                                <TableRow style={{ height: '40px' }} key={item?.id}>

                                    <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {!isLoading ? (
                                            <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                                <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%', textAlign: "center", fontWeight: 'bold' }}>
                                                    {(item?.tipologia ?? '').toUpperCase()}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Skeleton sx={{ mx: 2 }} />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {!isLoading ? (
                                            <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                                <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%' }} textAlign={'center'}>
                                                    {convertDataDiNascita(item?.dataInizioVariazione ?? '')}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Skeleton sx={{ mx: 2 }} />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {!isLoading ? (
                                            <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                                <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%' }} textAlign={'center'}>
                                                    {convertDataDiNascita(item?.dataFineVariazione ?? '')}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Skeleton sx={{ mx: 2 }} />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {!isLoading ? (
                                            <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                                <Typography variant={mobile ? 'caption' : 'body2'} sx={{ ml: 2 }}>
                                                    {(item?.creatoDa?.nome ?? '').toUpperCase() + " (" + (item?.creatoDa?.titolo ?? '') + ')'}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Skeleton sx={{ mx: 2 }} />
                                        )}
                                    </TableCell>
                                    {/* <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>
                                            {item?.canBeDeleted && (<CustomIconButton variant="contained" icon={<Delete sx={{ color: 'white' }} />} color="error" mr={1} buttonClicked={() => openAlertConfirmInserimento(item?.id)} />)}
                                        </Box>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <DialogConferma
                    isDialogOpen={confirmAlertIsOpen}
                    title={'Conferma cambio di stato'}
                    icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                    text={`Confermi di voler eliminare la variazione selezionata? `}
                    showAlunni={false}
                    confermaButton={'Elimina'}
                    severity={'error'}
                    closeDialog={closeAlertConfirmInserimento}
                    conferma={eliminaVariazioneProgetto}
                    disabledButton={buttonConfermaDisabled}
                />
            </Paper>
        </Fragment>
    )
}