import { useEffect, useState } from "react"
import { Checkbox, Typography, Box } from '@mui/material';
import CryptoJS from 'crypto-js';

export default function InfoUtente(props) {

    const [decryptedIdUtente, setDecryptedIdUtente] = useState('');
    const [decryptedPermessi, setDecryptedPermessi] = useState([]);
    const [decryptedRuolo, setDecryptedRuolo] = useState('');
    const [decryptedRuoliObj, setDecryptedRuoliObj] = useState({});
    const [decryptedUsr, setDecryptedUsr] = useState('');
    const [decryptedSede, setDecryptedSede] = useState('');

    useEffect(() => {
        getDataUtente();
    }, []);


    function getDataUtente() {
        let idTmp = localStorage.getItem("id");
        let decryptedId = CryptoJS.AES.decrypt(idTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        setDecryptedIdUtente(decryptedId)
        let permessiTmp = localStorage.getItem("permessi");
        let decryptedPermessiTmp = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let permessi = JSON.parse(decryptedPermessiTmp)
        permessi.sort((firstItem, secondItem) => firstItem.id - secondItem.id);
        setDecryptedPermessi(permessi)
        let ruoloTmp = localStorage.getItem("ruolo");
        let decryptedRuoloTmp = CryptoJS.AES.decrypt(ruoloTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        setDecryptedRuolo(decryptedRuoloTmp)
        let ruoliObjTmp = localStorage.getItem("ruoliObj");
        let decrypteRuoliObjTmp = CryptoJS.AES.decrypt(ruoliObjTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let ruoli = JSON.parse(decrypteRuoliObjTmp)
        setDecryptedRuoliObj(ruoli)
        let usrTmp = localStorage.getItem("usr");
        let decrypteUsrTmp = CryptoJS.AES.decrypt(usrTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        setDecryptedUsr(decrypteUsrTmp)
        let sedeTmp = localStorage.getItem("sede");
        let decrypteSedeTmp = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let nomeSede = JSON.parse(decrypteSedeTmp).nome
        setDecryptedSede(nomeSede)
    }

    return (
        <Box>
            <Typography sx={{ width: 'fit-content' }}><b>Utente:</b> {decryptedUsr}</Typography>
            <Typography sx={{ width: 'fit-content' }}><b>ID utente:</b> {decryptedIdUtente}</Typography>
            <Typography sx={{ width: 'fit-content' }}><b>Sede:</b> {decryptedSede}</Typography>
            <Typography sx={{ width: 'fit-content' }}><b>Ruolo:</b> {decryptedRuolo}</Typography>
            <Typography sx={{ width: 'fit-content' }}><b>Ruoli:</b></Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isTutor ?? false} sx={{ p: 0, mr: 1 }} />isTutor</Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isDocente ?? false} sx={{ p: 0, mr: 1 }} />isDocente </Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isDirettore ?? false} sx={{ p: 0, mr: 1 }} />isDirettore </Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isOpSegreteria ?? false} sx={{ p: 0, mr: 1 }} />isOpSegreteria</Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isCoordinatore ?? false} sx={{ p: 0, mr: 1 }} />isCoordinatore </Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isReferenteBES ?? false} sx={{ p: 0, mr: 1 }} />isReferenteBES</Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><Checkbox size="small" disabled checked={decryptedRuoliObj?.isAmministratore ?? false} sx={{ p: 0, mr: 1 }} />isAmministratore</Typography>
            <Typography sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}><b>Permessi:</b></Typography>
            {
                decryptedPermessi.map((permesso, index) =>
                    <Typography key={permesso?.id + '_permesso'} sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                        <Checkbox size="small" disabled checked={permesso?.value} sx={{ p: 0, mr: 1 }} />
                        {permesso?.nome + ' (id: ' + permesso?.id + ')'}
                    </Typography>
                )
            }
        </Box>
    )
}