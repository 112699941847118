import axios from "axios"
import CryptoJS from 'crypto-js';
import { UtilityAPI } from "../utilityApi";
import { axiosCalls } from "./axiosCalls";

export const api = axios.create({
    // withCredentials: true,
    baseURL: process.env.REACT_APP_FETCH_URL,
})

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
    const statusCode = error.response?.status

    if (statusCode === 403) {
        if (localStorage.getItem('refresh')) {
            const encryptedRefreshToken = localStorage.getItem('refresh');
            const decryptedRefreshToken = CryptoJS.AES.decrypt(encryptedRefreshToken, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            await getRefreshToken(decryptedRefreshToken);
            return api.request(error.config);
        } else logout();
    } else if (statusCode && statusCode == 401) {
        //console.error(error)
        logout();
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(response => {
    let urlTmp = response?.request?.responseURL;
    urlTmp = urlTmp.replace(process.env.REACT_APP_FETCH_URL, "/");
    let callTmp = axiosCalls.find(call => call.request.url === urlTmp)
    let indexCall = axiosCalls.indexOf(callTmp)
    if (axiosCalls[indexCall] !== undefined)
    axiosCalls[indexCall].response = response;
    return response;
}, (error) => {
    let urlTmp = error?.response?.request?.responseURL;
    urlTmp = urlTmp.replace(process.env.REACT_APP_FETCH_URL, "/");
    let callTmp = axiosCalls.find(call => call.request.url === urlTmp)
    let indexCall = axiosCalls.indexOf(callTmp)
    if (axiosCalls[indexCall] !== undefined)
        axiosCalls[indexCall].response = error.response;
    return errorHandler(error)
})

api.interceptors.request.use((config) => {
    let objTmp = {}
    objTmp.request = config;
    axiosCalls.push(objTmp);
    if (config.headers['Send-Token'] || config.sendToken) {
        const encryptedToken = localStorage.getItem('tkn');
        const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        config.headers.Authorization = `Bearer ${decryptedToken}`;

    }
    return config;
});


const getRefreshToken = async (refreshToken) => {
    try {
        const response = await axios.post(process.env.REACT_APP_FETCH_URL + '/auth/refreshToken', { refreshToken });

        let refreshTokenTmp = response.data.refreshToken;
        let encryptedRefreshToken = CryptoJS.AES.encrypt(refreshTokenTmp, process.env.REACT_APP_PRIVATE_KEY).toString();
        localStorage.setItem("refresh", encryptedRefreshToken);

        let token = response.data.refreshToken;
        let encryptedToken = CryptoJS.AES.encrypt(token, process.env.REACT_APP_PRIVATE_KEY).toString();
        localStorage.setItem("tkn", encryptedToken);

    } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 404) {
            logout();
        }
    }
}


function logout() {
    let idTmp = localStorage.getItem("id");
    let decryptedId = CryptoJS.AES.decrypt(idTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
    let dataObj = {
        idUtente: decryptedId
    }
    localStorage.clear();
    if (process.env.REACT_APP_IS_DEBUG == "true") {
        window.location.replace(process.env.REACT_APP_HOME_URL + "login");
    } else {
        let gtmUrl = process.env.REACT_APP_GTM_URL + "?page=logout"
        window.location.replace(gtmUrl);
    }
}