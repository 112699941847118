import { useState, useEffect, Fragment } from "react"
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { RegistroAPI } from "../../api/registroApi";
import { convertDataGetProssimeLezione } from "../shared/metodiDateUtility";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../shared/utilityMethods";
import CustomDatePicker from "../shared/customDatePicker";

export default function SelezioneLezioneNew(props) {

    const navigate = useNavigate();

    const [elencoCorsi, setElencoCorsi] = useState([])
    const [tipoRegistro, setTipoRegistro] = useState("classe")
    const [isConsulta, setIsConsulta] = useState(false);

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [idOrario, setIdOrario] = useState(-1);
    const [oraMateria, setOraMateria] = useState('');

    const [nomeClasseSelezionata, setNomeClasseSelezionata] = useState("");
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState("");
    const [codiceCorso, setCodiceCorso] = useState("");
    const [codiceSimon, setCodiceSimon] = useState("");
    const [sedeSelezionata, setSedeSelezionata] = useState("");

    const [modalitaSelezionata, setModalitaSelezionata] = useState("corsi")
    const [dialogElencoMaterieOpen, setDialogElencoMaterieOpen] = useState(false);

    const [dataDiInteresse, setDataDiInteresse] = useState(null);

    const [inizioPeriodo, setInizioPeriodo] = useState(null);
    const [finePeriodo, setFinePeriodo] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)

            let annoFormativoTmp = getAnnoFormativo();            
            let objDateInteresse = getDataInizioFinePeriodo();
            setDataDiInteresse(objDateInteresse?.dataDiInteresse ?? null)
            getClassiDocente(annoFormativoTmp);
        }
    }, [])

    useEffect(() => {
        if (props.tipoRegistro !== undefined) {
            setTipoRegistro(props.tipoRegistro);
        }
    }, [props.tipoRegistro])

    useEffect(() => {
        if (props.inizioPeriodo !== undefined && props.finePeriodo !== undefined) {
            setInizioPeriodo(props.inizioPeriodo)
            setFinePeriodo(props.finePeriodo)
        }
    }, [props.inizioPeriodo, props.finePeriodo])

    useEffect(() => {
        if (props.isConsulta !== undefined)
            setIsConsulta(props.isConsulta)
    }, [props.isConsulta])

    function changeDataDiInteresseSelezioneClasse(value) {
        setDataDiInteresse(value);
    }
    function changeOrarioSelezionato(idClasse, idMateria, nomeClasse, idOrario, sede, nomeMateria, oraMateria, codiceCorso, codiceSimon) {
        setIdOrario(idOrario);
        setOraMateria(oraMateria);
        setIdClasseSelezionata(idClasse);
        setIdMateriaSelezionata(idMateria);
        setNomeClasseSelezionata(nomeClasse);
        setNomeMateriaSelezionata(nomeMateria)
        setCodiceSimon(codiceSimon)
        setCodiceCorso(codiceCorso)
        setSedeSelezionata(sede);
    }
    function changeCorsoSelezionato(idClasse, nomeClasse, codiceCorso, codiceSimon, nomeSede) { //Viene chiamato dal selettore dei corsi (elenco corsi)
        setIdClasseSelezionata(idClasse);
        setNomeClasseSelezionata(nomeClasse);
        setCodiceSimon(codiceSimon)
        setCodiceCorso(codiceCorso)
        setSedeSelezionata(nomeSede);
    }
    function changeModalita(e) {
        setIdOrario(-1);
        setOraMateria(-1);
        setIdClasseSelezionata(-1);
        setIdMateriaSelezionata(-1);
        setNomeClasseSelezionata("");
        setCodiceSimon("")
        setCodiceCorso("")
        setModalitaSelezionata(e.target.value);
    }
    function goToElencoMaterie() {
        setDialogElencoMaterieOpen(true);
    }
    function confirmMateria(idMateria) {
        setIdMateriaSelezionata(idMateria);
        confirmClasse(idMateria);
        setDialogElencoMaterieOpen(false);
    }
    function confirmClasse(idMateria) {
        let dataInizio = dataDiInteresse;//.startOf('week') //dataDiInteresse.toISO();
        let dataFine = dataDiInteresse.endOf('week') //dataDiInteresse.plus({ days: 7 });
        dataFine = dataFine.toISO();
        dataInizio = dataInizio.toISO();
        props.changeSede(sedeSelezionata);
        if (modalitaSelezionata === "classe") {
            props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateria, nomeClasseSelezionata, [], null, true, "", "", codiceCorso, codiceSimon);
        } else {
            let oreClasseSelezionata = generateArrayMaterieInClasseSelezionata();
            props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateria, nomeClasseSelezionata, oreClasseSelezionata, idOrario, true, nomeMateriaSelezionata, oraMateria, codiceCorso, codiceSimon);
        }
    }
    function goToHome() {
        navigate("/")
    }
    function closeMaterieDialog() {
        setDialogElencoMaterieOpen(false);
    }
    function generateArrayMaterieInClasseSelezionata() {
        let arrayOreTmp = [];
        for (let lezione of elencoCorsi) {
            if (lezione != null) {
                if (lezione?.idCorso === idClasseSelezionata) {
                    arrayOreTmp.push(lezione)
                }
            }
        }
        return arrayOreTmp;
    }
    async function getClassiDocente(annoFormativoTmp) {
        let dataObj = {
            annoFormativo: annoFormativoTmp
        }
        RegistroAPI.getElencoClassiDocente(dataObj)
            .then((result) => {
                // Rimuovo eventuali duplicati
                let elencoClassiTmp = result.filter((obj, index, self) =>
                    index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
                );
                elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
                setElencoCorsi([...elencoClassiTmp])
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }


    return (
        <Fragment>
            <Box display={"flex"} justifyContent={"start"} alignItems={"center"} sx={{ mb: 1 }}>
                <CustomDatePicker
                    getDatePeriodo={true}
                    selectedValue={dataDiInteresse}
                    onChangeValue={changeDataDiInteresseSelezioneClasse}
                    label="Data"
                    isFullWidth={false}
                />
            
                {!isConsulta && ( //In consulta non posso scegliere orario giornaliero, quindi nascondo tutto questo pezzo
                    <ToggleButtonGroup
                        color="primary"
                        value={modalitaSelezionata}
                        exclusive
                        onChange={changeModalita}
                        sx={{ height: '100%', ml: 3 }}
                    >
                        <ToggleButton sx={{ p: 1.5 }} value="corsi">Elenco corsi</ToggleButton>
                        <ToggleButton sx={{ p: 1.5 }} value="orario">Orario giornaliero</ToggleButton>
                    </ToggleButtonGroup>
                )}
            </Box>
            {modalitaSelezionata === "corsi" ? (
                <ElencoCorsiDocente elencoCorsi={elencoCorsi} changeCorsoSelezionato={changeCorsoSelezionato} />
            ) : (
                <ElencoOrarioDocente idDocente={idDocente} dataDiInteresse={dataDiInteresse} changeOrarioSelezionato={changeOrarioSelezionato} />
            )}
            <Box>
                <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                {(modalitaSelezionata === "corsi" && tipoRegistro === "docente") ? (
                    <Button sx={{ mt: 2 }} onClick={goToElencoMaterie} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Prosegui alla selezione della materia</Button>
                ) : (
                    <Button sx={{ my: 2 }} onClick={() => confirmClasse(idMateriaSelezionata)} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Conferma e prosegui</Button>
                )}
            </Box>

            <Dialog fullWidth maxWidth={"md"} open={dialogElencoMaterieOpen}>
                <DialogTitle>Seleziona materia</DialogTitle>
                <DialogContent>
                    <ElencoMaterieCorso codiceCorso={codiceCorso} nomeCorsoSelezionato={nomeClasseSelezionata} sedeCorso={sedeSelezionata} confirmMateria={confirmMateria} closeMaterieDialog={closeMaterieDialog} idCorso={idClasseSelezionata} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}


function ElencoCorsiDocente(props) {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [elencoCorsi, setElencoCorsi] = useState([])
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);

    useEffect(() => {
        if (props.elencoCorsi !== undefined) {
            setElencoCorsi([...props.elencoCorsi]);
            setHasLoaded(true)
        }
    }, [props.elencoCorsi])

    function changeIdClasseSelezionata(idClasse, nomeClasse, codiceCorso, codiceSimon, nomeSede) {
        setIdClasseSelezionata(idClasse);
        if (props.changeCorsoSelezionato)
            props.changeCorsoSelezionato(idClasse, nomeClasse, codiceCorso, codiceSimon, nomeSede)
    }

    return (
        <Fragment>
            {hasLoaded ? (
                elencoCorsi.length > 0 ? (
                    <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
                        <Table >
                            <TableBody>
                                {elencoCorsi.map((classe, index) => (
                                    <TableRow key={classe?.corso?.idCorso} sx={{ cursor: 'pointer' }} onClick={() => changeIdClasseSelezionata(classe?.corso?.idCorso, classe?.corso?.nomeCorso, classe?.corso?.codiceCorso, classe?.corso?.codiceSimon, classe?.sede?.nomeSede, classe?.sede?.idSede,)}>
                                        <TableCell sx={{ minWidth: '200px', background: (classe?.corso?.idCorso === idClasseSelezionata) ? '#1976D2' : 'white', color: (classe?.corso?.idCorso === idClasseSelezionata) ? 'white' : 'black' }}>
                                            <Typography>
                                                {classe?.corso?.codiceCorso ? classe?.corso?.codiceCorso + " - " : ""}{classe?.corso?.nomeCorso ?? ""} {classe?.corso?.codiceSimon ? "(" + classe?.corso?.codiceSimon + ") " : ""} - {classe?.sede?.nomeSede} {(classe?.corso?.idCorso === idClasseSelezionata) ? "- SELEZIONATA" : ""}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                ) : (
                    <Typography textAlign={"center"} color="red">Al momento non è possibile visualizzare l'elenco dei corsi.</Typography>
                )
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                    <CircularProgress />
                </Box>
            )}
        </Fragment>
    )
}
function ElencoOrarioDocente(props) {

    useEffect(() => {
        if (props.idDocente !== undefined && props.dataDiInteresse !== undefined) {
            getOreLezioneGiorno(props.dataDiInteresse, props.idDocente)
        }
    }, [props.idDocente, props.dataDiInteresse])




    const [hasLoaded, setHasLoaded] = useState(false)
    const [classiInGiornata, setClassiInGiornata] = useState([])
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idOrario, setIdOrario] = useState(-1);




    function changeIdClasseSelezionata(idClasse, idMateria, nomeClasse, idOrario, sede, nomeMateria, oraMateria, codiceCorso, codiceSimon) {
        let idOrarioString = idOrario.toString();
        let idOrarioCrypted = CryptoJS.AES.encrypt(idOrarioString, process.env.REACT_APP_PRIVATE_KEY).toString();

        localStorage.setItem("oraLez", idOrarioCrypted);
        setIdOrario(idOrario);
        setIdClasseSelezionata(idClasse);

        if (props.changeOrarioSelezionato)
            props.changeOrarioSelezionato(idClasse, idMateria, nomeClasse, idOrario, sede, nomeMateria, oraMateria, codiceCorso, codiceSimon)

    }

    async function getOreLezioneGiorno(dataRichiesta, idDocente) {

        let formattedData = convertDataGetProssimeLezione(dataRichiesta, false)
        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
        }

        RegistroAPI.getOrarioDocente(dataObj) //getOrarioDocente
            .then((result) => {
                // if(result.length > 0)props.changeSede(result[0].sede.nomeSede)
                if (result.length > 0) {
                    let ultimaOraDocente = result[result.length - 1].ora;
                    let numberMaxOfHours = 9;
                    if (ultimaOraDocente > 8) {
                        numberMaxOfHours = ultimaOraDocente + 1
                    }
                    let oreArray = new Array();
                    for (let i = 1; i < numberMaxOfHours; i++) {
                        let pushed = false;
                        for (let ora of result) {
                            if (ora.ora === i) {
                                oreArray.push(ora);
                                pushed = true
                                break;
                            }
                        }
                        if (pushed === false)
                            oreArray.push(null)

                    }

                    setClassiInGiornata([...oreArray]);
                } else setClassiInGiornata([]);
                setHasLoaded(true);
            })
            .catch((error) => {
                if (error.response) {
                    setClassiInGiornata([]);
                    setHasLoaded(true);
                }
            })
    }

    return (
        <Fragment>
            {hasLoaded ? (
                classiInGiornata.length > 0 ? (
                    <Fragment>
                        <Box sx={{ height: '35vh', overflowY: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Typography> ORA DI LEZIONE - AULA </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>CLASSE</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {classiInGiornata.map((classe, index) => (
                                        classe != null ? (
                                            <TableRow key={classe.idOrario} sx={{ cursor: 'pointer' }} onClick={() => changeIdClasseSelezionata(classe?.idCorso, classe?.materia.idMateria, classe?.nomeCorso, classe?.idOrario, classe?.sede.nomeSede, classe?.materia.nomeMateria, classe?.ora, classe?.codiceCorso, classe?.codiceSimon)}>
                                                <TableCell sx={{ textAlign: 'center', borderRight: '1px solid rgba(224, 224, 224, 1)', background: (classe?.idOrario === idOrario) ? '#1976D2' : 'white', color: (classe?.idOrario === idOrario) ? 'white' : 'black' }}>
                                                    <Typography textAlign={"start"}> <b>{classe?.ora}^ ora </b> ({classe?.materia?.nomeMateria}) {classe?.isSupplenza === true ? " - SUPPLENZA" : null}</Typography>
                                                    <Typography textAlign={"start"} ><b>Aula: </b>{classe?.nomeAula}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ minWidth: '200px', background: (classe?.idOrario === idOrario) ? '#1976D2' : 'white', color: (classe?.idOrario === idOrario) ? 'white' : 'black' }}>
                                                    <Typography>
                                                        {classe?.codiceCorso + " - " + classe?.nomeCorso + " (" + classe?.codiceSimon + ")"}  {(classe?.idOrario === idOrario) ? "- SELEZIONATA" : ""}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow key={index}>
                                                <TableCell sx={{ textAlign: 'center', backgroundColor: '#e6e6e6', borderRight: '1px solid white', color: "#0000007d" }}>{index + 1}^ora</TableCell>
                                                <TableCell sx={{ textAlign: 'center', background: '#e6e6e6' }}>-</TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>

                    </Fragment>
                ) : (
                    <Fragment>
                        <Typography textAlign={"center"} color="red">Nessuna lezione in programma nella data selezionata</Typography>
                    </Fragment>
                )
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                    <CircularProgress />
                </Box>
            )}
        </Fragment>
    )
}
function ElencoMaterieCorso(props) {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [elencoMaterie, setElencoMaterie] = useState([]);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeCorsoSelezionato, setNomeCorsoSelezionato] = useState("");
    const [sedeCorso, setSedeCorso] = useState("");

    useEffect(() => {
        if (props.idCorso !== undefined)
            getElencoMateriePerClasseDocente(props.idCorso);
    }, [props.idCorso])

    useEffect(() => {
        if (props.nomeCorsoSelezionato !== undefined && props.codiceCorso !== undefined)
            setNomeCorsoSelezionato(props.codiceCorso + " - " + props.nomeCorsoSelezionato);
    }, [props.nomeCorsoSelezionato, props.codiceCorso])

    useEffect(() => {
        if (props.sedeCorso !== undefined)
            setSedeCorso(props.sedeCorso);
    }, [props.sedeCorso])

    async function getElencoMateriePerClasseDocente(idClasse) {
        let dataObj = {
            idCorso: parseInt(idClasse),
            showMateriaValutazione: true
        }

        RegistroAPI.getElencoMateriePerClasseDocente(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setElencoMaterie([...result?.materie])
            })
            .catch(function (error) {
                if (error.response) { }
            })
    }

    function changeIdMateriaSelezionata(idMateria, nomeMateria) {
        setIdMateriaSelezionata(idMateria);
        //setNomeMateriaSelezionata(nomeMateria);
        //if (props.changeNomeMateria) props.changeNomeMateria(nomeMateria);
    }

    function closeMaterieDialog() {
        if (props.closeMaterieDialog) {
            setIdMateriaSelezionata(-1);
            props.closeMaterieDialog();
        }
    }

    function confirmMateria() {
        if (props.confirmMateria)
            props.confirmMateria(idMateriaSelezionata);
    }


    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Typography><b>Corso selezionato:</b> {nomeCorsoSelezionato} ({sedeCorso})</Typography>
                    <Divider sx={{ mt: 1 }} />
                    {elencoMaterie.length > 0 ? (
                        <Box maxHeight={"30vh"} style={{ overflow: "scroll" }}>
                            <Table>
                                <TableBody>
                                    {elencoMaterie.map((materia, index) => (
                                        <TableRow key={materia.idMateria} sx={{ cursor: 'pointer' }} onClick={() => changeIdMateriaSelezionata(materia?.idMateria, materia?.nomeMateria)}>
                                            <TableCell sx={{ minWidth: '200px', background: (materia.idMateria === idMateriaSelezionata) ? '#1976D2' : 'white', color: (materia.idMateria === idMateriaSelezionata) ? 'white' : 'black' }}>
                                                {materia?.nomeMateria}  {(materia.idMateria === idMateriaSelezionata) ? "- SELEZIONATA" : ""}
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    ) : (
                        <Typography textAlign={"center"} color="red">Nessuna materia associata alla classe selezionata</Typography>
                    )}
                    <Button sx={{ mt: 2 }} onClick={closeMaterieDialog} variant="outlined" fullWidth color="info" >Torna alla selezione del corso</Button>
                    <Button sx={{ mt: 2 }} onClick={confirmMateria} variant="contained" fullWidth color="success" disabled={idMateriaSelezionata === -1}>Conferma e prosegui</Button>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                    <CircularProgress />
                </Box>
            )}
        </Fragment>
    )
}