import { CloudUpload } from "@mui/icons-material";
import { Box, Button, Snackbar, Alert, Typography, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

export default function UploadButton(props) {

    const [fileToUpload, setFileToUpload] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [maxFileDimension, setMaxFileDimension] = useState(0); //A 0 vuol dire senza limiti
    const [showAlertError, setShowAlertError] = useState(false);
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        if (props.maxFileDimension !== undefined)
            setMaxFileDimension(props.maxFileDimension);
    }, [props.maxFileDimension])

    useEffect(() => {
        if (props.isUploadingFile !== undefined)
            setIsUploading(props.isUploadingFile);
    }, [props.isUploadingFile])


    function changeFileToUpload(e) {
        const fileToUpload = e.target.files[0];
        if (fileToUpload.type !== 'application/pdf') {
            setUploadErrorMsg("Il file aggiunto non è un PDF ");
            setShowAlertError(true);
        } else {
            if (maxFileDimension !== 0 && fileToUpload.size < maxFileDimension) { //Se è 0 salta il controllo ovviamente
                if (uploadErrorMsg === true) setShowAlertError(false);
                setFileToUpload(fileToUpload);
                if (props.fileChanged) props.fileChanged(fileToUpload);
            } else {
                let maxDimensionMB = convertBytesToMB();
                setUploadErrorMsg("Il file non può superare una dimensone di " + maxDimensionMB + "mb");
                setShowAlertError(true);
            }
        }
    }

    function convertBytesToMB() {
        const MB = maxFileDimension / (1024 * 1024);
        return MB.toFixed(2);
    }


    return (
        <Fragment>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    disabled={isUploading}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                >
                    {fileToUpload !== null ? "Cambia " : "carica "}   file (pdf)
                    <VisuallyHiddenInput type="file" accept=".pdf" onChange={changeFileToUpload} />
                </Button>
                <Typography sx={{ ml: 2 }}>{fileToUpload?.name ?? ""}</Typography>
            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={showAlertError} autoHideDuration={7000} onClose={() => { setShowAlertError(false) }} >
                <Alert onClose={() => { setShowAlertError(false) }} severity={"error"} sx={{ width: '100%' }}>
                    {uploadErrorMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}