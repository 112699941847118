import { Link, Breadcrumbs, Container, Typography, Select, MenuItem, InputLabel, FormControl, Box, Button, CircularProgress, TextField, Paper, TableCell, TableRow, TableContainer, TableBody, TableHead, Snackbar, Alert, Dialog, DialogContent, DialogTitle } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { Fragment, useState, forwardRef, useEffect } from "react"
import { TableVirtuoso } from "react-virtuoso";
import RowCorsoColloquio from "./rowCorsoGestioneCalendarioColloqui";
import { Table } from "react-virtualized";
import TableCalendarioColloqui from "./tableCalendarioColloqui";
import { ColloquiAPI } from "../../../api/colloquiApi";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import LoadingComponent from "../../shared/loadingComponent";
import { copyArrayWithoutReference } from '../../shared/utilityMethods';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { RicercaAPI } from '../../../api/ricercaApi';
import Breadcrumb from "../../registri/shared/breadcrumb";

import { getAnnoFormativo } from "../../shared/utilityMethods";

export default function GestioneCalendarioColloqui() {


    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [modificheNonSalvate, setModificheNonSalvate] = useState(false);
    const [indexSalvataggio, setIndexSalvataggio] = useState(0); //Quando salvo aumento l'index cosi modifico facilmente i props su rowCorso
    const [isSalvataggioLoading, setIsSalvataggioLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [listCorsi, setListCorsi] = useState([]);
    const [listCorsiToPass, setListCorsiToPass] = useState([]); //Viene popolato solo ai get, serve perchè al passaggio dei props dalla tabella 
    //a questo componente, aggiornando la lista si verifica un comportamento anomalo; per qualche secondo tutti i campi data diventavano bianchi

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [sede, setSede] = useState(null);
    const [idSede, setIdSede] = useState(0);
    const [nomeSede, setNomeSede] = useState('');
    const [idSedeSelected, setIdSedeSelected] = useState(0);
    const [sediList, setSediList] = useState([]);
    const [annoFormativo, setAnnoFormativo] = useState('');

    const [hasLoadedSediList, setHasLoadedSediList] = useState(false);

    const [isOpenDialogSelSede, setIsOpenDialogSelSede] = useState(true);

    const [dataInizioAnnoFormativo, setDataInizioAnnoFormativo] = useState(null);
    const [dataFineAnnoFormativo, setDataFineAnnoFormativo] = useState(null);
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);
    
    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp)
        let annoFormativoObjTmp = JSON.parse(sessionStorage.getItem("annoFormativoObj"));
        if (annoFormativoObjTmp !== undefined && annoFormativoObjTmp !== null) {
            let dataInizioAnnoFormativoTmp = DateTime.fromISO(annoFormativoObjTmp.dataDA);
            setDataInizioAnnoFormativo(dataInizioAnnoFormativoTmp);
            let dataFineAnnoFormativoTmp = DateTime.fromISO(annoFormativoObjTmp.dataA);
            setDataFineAnnoFormativo(dataFineAnnoFormativoTmp);
            setIsAnnoFormativoCorrente(annoFormativoObjTmp?.isAnnoFormativoCorrente ?? true)

        }
    }, [])

    async function getSedi(annoFormativo) {

        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setSediList(result.sedi);
                setHasLoadedSediList(true);
                if (result.sedi.length === 1) {
                    setIdSedeSelected(result.sedi[0].id);
                    setSede(result.sedi[0])
                    setIdSede(result.sedi[0].id);
                    setNomeSede(result.sedi[0].nomeSede);
                    setIsOpenDialogSelSede(false);
                    getElencoColloqui(result.sedi[0].id, annoFormativo);
                }
            })
            .catch(function (error) {
                alertOpen(true);
                alertMsg('Spiacenti, non è al momento possibile utilizzare il servizio richiesto. Riprovare tra qualche istante.');
                alertSeverity('error');
            })
    }

    async function getElencoColloqui(idSede, annoFormativo) {
        let dataObj = {
            "idSede": idSede,
            "annoFormativo": annoFormativo
        }
        setIsLoading(true);
        ColloquiAPI.getColloqui(dataObj)
            .then((result) => {
                let corsiTmp = copyArrayWithoutReference(result?.corsi);
                let corsiToPassTmp = copyArrayWithoutReference(result?.corsi);
                setListCorsi([...corsiTmp]);
                setListCorsiToPass([...corsiToPassTmp])
                setIsLoading(false)
                setTimeout(() => setIndexSalvataggio(prev => prev + 1), 200);
            })
            .catch(function (error) {
                setIsLoading(false);
            })
    }

    async function saveColloqui() {
        let dataObj = {
            "idSede": idSede,
            "corsi": listCorsi
        }
        setIsSalvataggioLoading(true);
        setModificheNonSalvate(false); //questo andrebbe messo al then

        ColloquiAPI.setColloqui(dataObj)
            .then((result) => {
                let listCorsiTmp = result.corsi;
                for (let i = 0; i < listCorsi.length; i++) {
                    if (listCorsiTmp[i].primoColloquio) {
                        listCorsiTmp[i].primoColloquio.isEditato = false;
                    }
                    if (listCorsiTmp[i].secondoColloquio) {
                        listCorsiTmp[i].secondoColloquio.isEditato = false;
                    }
                }
                setIsSalvataggioLoading(false);
                //setListCorsi([...listCorsiTmp])
                setListCorsi([...listCorsiTmp]);
                setListCorsiToPass([...listCorsiTmp])
                setTimeout(() => {
                    setIndexSalvataggio(prev => prev + 1);
                }, 200)
                openAlertMsg('Date salvate correttamente', 'success')
            })
            .catch(function (error) {
                console.log(error);
                setIsSalvataggioLoading(false);
                openAlertMsg('Errore durante il salvataggio dei colloqui', 'error')
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? 'Errore durante il salvataggio dei colloqui'
                    openAlertMsg(errorMsg, 'error')
                } else openAlertMsg('Errore durante il salvataggio dei colloqui', 'error')

            })
    }

    function changedDate(idCorso, objPrimoColloquio, objSecondoColloquio) {
        let listCorsiTmp = copyArrayWithoutReference(listCorsi);
        let indexCorso = listCorsi.findIndex(corso => corso.idCorso === idCorso);
        if (indexCorso !== -1) {
            listCorsiTmp[indexCorso].primoColloquio = objPrimoColloquio;
            listCorsiTmp[indexCorso].secondoColloquio = objSecondoColloquio;
        }

        setListCorsi([...listCorsiTmp]);

        for (let corso of listCorsiTmp) {
            if (corso.primoColloquio.isEditato !== undefined && corso.primoColloquio.isEditato === true) {
                setModificheNonSalvate(true);
                return;
            }
            if (corso.secondoColloquio.isEditato !== undefined && corso.secondoColloquio.isEditato === true) {
                setModificheNonSalvate(true);
                return;
            }
        }
        setModificheNonSalvate(false);

    }
    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function goToHome() {
        navigate("/")
    }

    function confirmSede() {
        setIdSede(idSedeSelected);
        setNomeSede(sede.nomeSede);
        setIsOpenDialogSelSede(false);
        getElencoColloqui(idSedeSelected, annoFormativo);

        //TODO: verifica chiusura alert mancato salvataggio
        setModificheNonSalvate(false)
    }

    function changeIdSede(sede) {
        setSede(sede)
        setIdSedeSelected(sede.id);
    }

    function openDialogSelSede() {
        setIsOpenDialogSelSede(true);
    }

    return (
        <Fragment>
            {idSede !== 0 && (<Box>
                <Breadcrumb sections={["Modulo colloqui" , "Gestione calendario"]} />

                <Box sx={{ px: { xs: 1, md: 4 } }}>
                    <Box sx={{ my: 2, display: "flex", alignItems: 'center' }}>
                        <Box sx={{ my: 2, display: "flex", alignItems: 'center' }}>
                            <FormControl>
                                <TextField value={nomeSede} disabled label="Sede" />
                            </FormControl>
                            {sediList.length > 1 && (<Button onClick={() => openDialogSelSede()} sx={{ ml: 2 }} variant="contained" >Cambia sede</Button>)}
                        </Box>
                        {isAnnoFormativoCorrente && (
                        <Button disabled={isSalvataggioLoading || !modificheNonSalvate} onClick={saveColloqui} sx={{ ml: 2 }} variant="contained" color="success">Salva modifiche</Button>
                        )}
                        {modificheNonSalvate && (
                            <Typography sx={{ ml: 2 }} color="#ed6c02"><b>Attenzione:</b> Hai delle modifiche non salvate!</Typography>
                        )}
                    </Box>
                    <TableCalendarioColloqui isAnnoFormativoCorrente={isAnnoFormativoCorrente} indexSalvataggio={indexSalvataggio} corsi={listCorsiToPass} changedDate={changedDate} dataDA={dataInizioAnnoFormativo} dataA={dataFineAnnoFormativo} />
                </Box>
                <LoadingComponent isLoading={isLoading} timeoutLoading={1000} testoCaricamento={"Caricamento in corso..."} />
            </Box>)}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
            {hasLoadedSediList ? (<Dialog open={isOpenDialogSelSede} sx={{ mt: '8vh' }}>
                <DialogTitle>Seleziona la sede</DialogTitle>
                <DialogContent>
                    {sediList.length > 0 ? (
                        <Fragment>
                            <Box sx={{ height: '35vh', overflowY: 'auto' }}>

                                {sediList.map((sede, index) => (
                                    sede != null ? (
                                        <Box key={sede?.id} sx={{ cursor: 'pointer', }}
                                            onClick={() => changeIdSede(sede)}
                                        >
                                            <Box sx={{ minWidth: '200px', background: (sede?.id === idSedeSelected) ? '#1976D2' : 'white', color: (sede?.id === idSedeSelected) ? 'white' : 'black', py: 1, paddingLeft: 1, borderBottom: index + 1 === sediList.length ? '' : 'solid rgba(224, 224, 224, 1) 1px' }}>
                                                <Typography>
                                                    {sede?.nomeSede}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Typography textAlign={"center"} color="red">
                                                Non sono presenti sedi disponibili alla consultazione.
                                            </Typography>
                                        </Box>
                                    )
                                ))}
                            </Box>
                            <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                            <Button sx={{ mt: 2 }} onClick={confirmSede} variant="contained" fullWidth color="success" disabled={idSedeSelected === 0}>Conferma e prosegui</Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Typography textAlign={"center"} color="red">Non sono presenti sedi disponibili alla consultazione.</Typography>
                            <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>

                        </Fragment>
                    )}
                </DialogContent>
            </Dialog>) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100vh"}>
                    <CircularProgress />
                </Box>
            )}

            <Helmet>
                <title>{t("name")} - Calendario colloqui</title>
            </Helmet>
        </Fragment>
    )
}