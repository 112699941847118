import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

export function LogoutPage() {

    useEffect(() => {
        if (localStorage.getItem("tkn")) {
            localStorage.clear();
            window.location.replace(process.env.REACT_APP_HOME_URL + "login");
        } else {
            window.location.replace(process.env.REACT_APP_GTM_URL + "?page=login");
        }
    }, [])

    return (
        <Box sx={{ height: '80vh', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
            <Typography>Caricamento in corso...</Typography>
        </Box>
    )
}