import { Box, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, IconButton, Alert, FormControl, Select, OutlinedInput, InputAdornment } from "@mui/material";
import { debounce } from '@mui/material/utils';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"
import ElencoAlunniSelezionabili from "../elencoAlunniSelezionabili";

import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";
import CryptoJS from 'crypto-js';
import { convertISODate, convertDataGetProssimeLezione, convertToSqlFormat } from "../../shared/metodiDateUtility";

import { VisibilityOff, Visibility, Delete, Edit } from "@mui/icons-material";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { PresenzeAPI } from "../../../api/presenzeApi";
import DialogConferma from "../../shared/dialogConferma";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";

export default function ModificaNoteDisciplinari(props) {

    const [categorieList, setCategorieList] = useState([]);
    const [categoriaSelected, setCategoriaSelected] = useState(-1);

    const [permessiUtente, setPermessiUtente] = useState([]);

    const [data, setData] = useState(null);
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);
    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);

    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [notaSel, setNotaSel] = useState("");
    const [idNota, setIdNota] = useState(-1);

    const [modificaMode, setModificaMode] = useState(false);

    const inputDescrizioneCompitiRef = useRef(null);
    const [testoNota, setTestoNota] = useState("");
    const inputCommentoModificaRef = useRef(null);
    const [commentoModifica, setCommentoModifica] = useState("");

    const [showPulsanteEdit, setShowPulsanteEdit] = useState(false);
    const [showPulsanteDelete, setShowPulsanteDelete] = useState(false);
    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] = useState(false);
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)
    const [hasDataFinePeriodo, setHasDataFinePeriodo] = useState(false);

    const [pinUtente, setPinUtente] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    const refPinConfermaBes = useRef(null);

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        setDataInizioPeriodo(inizioPeriodoTmp ?? null);
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
        setDataFinePeriodo(finePeriodoTmp ?? null);
        setHasDataFinePeriodo(!objDateInteresse?.isAnnoFormativoCorrente ?? false)
        getCategorieNota()
    }, []);

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined) {
            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
        }
    }, [props.idCorso, props.idDocente, props.idMateria]);

    useEffect(() => {
        if (props.notaSel != undefined && props.listAlunni != undefined && props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
            getDataNote(props.permessiUtente, props.listAlunni, props.idDocente);
        }
    }, [props.notaSel, props.listAlunni, props.permessiUtente, props.idDocente]);



    async function getCategorieNota() {

        EventiAPI.getCategorieNota()
            .then((result) => {
                setCategorieList(result)
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    function getDataNote(permessiUtenteTmp, listAlunni, idDocente) {

        setNotaSel(props.notaSel)
        const date = DateTime.fromISO(props.notaSel?.data_comunicazione);
        setData(props.notaSel?.data_comunicazione != null ? date : null);

        // isAlunnoIscrittoInData(props.notaSel?.contenuto?.dataLezione != null ? date : null, listAlunni)

        setTestoNota(props.notaSel?.contenuto?.note);
        setCommentoModifica(props.notaSel?.contenuto?.commento ?? ""); //TODO: da verificare
        setIdNota(props.notaSel?.id);
        setCategoriaSelected(props.notaSel?.contenuto?.categoria?.id)
        let idMateriaTmp = props.notaSel?.contenuto?.materia?.idMateria;
        let idDocenteTmp = idDocente;
        let idCorsoTmp = props.notaSel?.albero_destinatari?.idCorso[0];
        let arrayIdAlunno = props.notaSel?.albero_destinatari?.idAlunno;
        let idOrarioTmp = props.notaSel?.contenuto?.idOrario;
        let alunniSelezionatiTmp = []
        if (arrayIdAlunno.length > 0) {
            for (let idAlunno of arrayIdAlunno) {
                alunniSelezionatiTmp.push(props.listAlunni.filter(a => a.id === idAlunno)[0])
            }
        } else { //In questo caso è tutta la classe
            alunniSelezionatiTmp = props.listAlunni;
        }

        setAlunniSelezionati([...alunniSelezionatiTmp])
        setIdMateria(idMateriaTmp);
        setIdDocente(idDocenteTmp);
        setIdCorso(idCorsoTmp);

        canAccessButtons(props.notaSel, permessiUtenteTmp)

        getAlunniPresenti(listAlunni, date);
        getOreLezione(date, idCorsoTmp, idDocenteTmp, idMateriaTmp, listAlunni, alunniSelezionatiTmp);

        // if (props.notaSel?.contenuto?.dataLezione != null) getOreLezione(props.notaSel?.contenuto?.dataLezione != null ? date : null, idCorsoTmp, idDocenteTmp, idMateriaTmp, idOrarioTmp, listAlunni);
    }


    useEffect(() => {
        if (isDialog === false) {
            if (sessionStorage.getItem("classe") != null) {
                let encryptedIdCorso = sessionStorage.getItem("classe");
                let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                //TODO: Poi imposterò questo ID
                setAlertNoIdIsOpen(false);

            } else {
                //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
                setAlertNoIdIsOpen(true);
            }
        }
    }, [isDialog])

    useState(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
            setMaterie([...materie, props.nota.materia])
        }
    }, [props.nota]);

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            if (props.listAlunni == null)
                getAlunniByClasseID();
            else {
                setListAlunni([...props.listAlunni]);
                setGetAlunniIsLoading(false);
            }
        }
    }, [props.listAlunni])


    useEffect(() => {
        if (props.alunniSelezionati !== undefined) {
            setAlunniSelezionati([...props.alunniSelezionati])
        }
    }, [props.alunniSelezionati])

    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, listAlunniTmp, alunniSelezionatiTmp) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)

        getAlunniPresenti(listAlunniTmp, dataTmp, alunniSelezionatiTmp);


    }

    async function getAlunniPresenti(alunniTmp, dataRichiesta, alunniSelezionati, oraSelezionata) {

        let dataSql = convertToSqlFormat(dataRichiesta);

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataObj = {
            "data": dataSql,
            "idAlunno": idAlunni
        }

        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                let alunniNonSelezionabiliTmp = [];
                for (let alunno of result.alunni) {
                    if (alunno?.stato_presenza === "A") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);
                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        alunniNonSelezionabiliTmp.push(objAlunno)
                    }
                }

                let newAlunni = isAlunnoIscrittoInData(dataRichiesta, alunniTmp);
                for (let alunno of newAlunni) {
                    alunniNonSelezionabiliTmp.push(alunno);
                }

                setAlunniNonSelezionabili([...alunniNonSelezionabiliTmp]);
                let arrayTmp = alunniNonSelezionabiliTmp;
                let arraySelTmp = []


                let alunniSelezionabiliNumber = alunniTmp.length - alunniNonSelezionabiliTmp.length
                if (alunniSelezionabiliNumber < 0)
                    alunniSelezionabiliNumber = 0;

                setAlunniSelezionabiliNumber(alunniSelezionabiliNumber)
                for (let alunno of alunniSelezionati) {
                    if (!arrayTmp.includes(alunno)) arraySelTmp.push(alunno);
                }
                setAlunniSelezionati([...arraySelTmp]);
                selectAlunni(arraySelTmp);
            })
            .catch((error) => {
            })

    }


    function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);

            })
    }

    async function modificaNota() {

        let alunniSelezionatiId = []
        for (let a of alunniSelezionati) {
            alunniSelezionatiId.push(a.id)
        }

        let dataTmp = data;
        let formattedData = convertDataGetProssimeLezione(dataTmp, true)


        const obj = {
            note: testoNota,
            idCategoria: categoriaSelected,
            idAlunno: alunniSelezionatiId,
            data: formattedData,
            is_interaClasse: alunniSelezionati.length === alunniSelezionabiliNumber ? true : false,
            idClasse: idCorso,
            idUtente: idDocente,

            pin: pinUtente,
            commento: commentoModifica ?? ""
        }

        EventiAPI.modificaNota(obj, idNota)
            .then((result) => {
                if (isDialog) {
                    setAlertSeverity("success");
                    setAlertMsg("Nota modificata");
                    setAlertIsOpen(true);
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Nota modificata", "success");
                } else {
                    setAlunniSelezionati([]);
                    setTestoNota("");

                    if (inputDescrizioneCompitiRef != null)
                        inputDescrizioneCompitiRef.current.value = "";

                    if (inputCommentoModificaRef != null)
                        inputCommentoModificaRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Nota modificata");
                    setAlertIsOpen(true);

                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Nota modificata", "success");
                }
                setConfirmAlertIsOpen(false);
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                    if (error.response.status === 420) {
                        setAlertMsg("Pin errato.");
                    } else {
                        let errorTmp = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione"
                        setAlertMsg(errorTmp);
                    }
                }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");

                setAlertIsOpen(true);
            })
    }

    function logout() {
        if (props.logout) props.logout()
    }

    function selectAlunni(listAlunni) {
        if (listAlunni !== undefined)
            setAlunniSelezionati([...listAlunni]);
    }
    function changeTestoVerifica(e) {
        setTestoNota(e.target.value)
    }
    function changeCommentoModifica(e) {
        let value = e.target.value;
        if (value.startsWith(' ')) {
            value = value.substring(1);
        }
        setCommentoModifica(value)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria, listAlunni, alunniSelezionati);
    }

    function changeOra(e) {
        setOraSelected(e.target.value)
    }

    function annullaModifiche() {
        getDataNote(permessiUtente, listAlunni, idDocente);
        setModificaMode(false);
    }

    function changeCategoria(e) {
        setCategoriaSelected(e.target.value)
    }

    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp;
            // setAlunniNonSelezionabili([...listTmp]);
        }
        else {
            let listTmp = [];
            for (let alunno of listAlunni) {
                listTmp.push(alunno);
            }
            return listTmp;
            // setAlunniNonSelezionabili([...listTmp]);
        }
    }
    function isAlunnoIscrittoInDataNew(data, listAlunni) {
        let dataConvertedIso = data.toUTC().toISO()
        let listTmp = [];
        for (let alunno of listAlunni) {
            let iscrittoDalIso = alunno?.iscrittoDal;
            let iscrittoAlIso = alunno?.iscrittoAl;

            let startDate = DateTime.fromISO(iscrittoDalIso);
            let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
            let someDate = DateTime.fromISO(dataConvertedIso);
            let interval = Interval.fromDateTimes(startDate, endDate);

            if (!interval.contains(someDate)) {
                listTmp.push(alunno);
            }
        }
        return listTmp;// setAlunniNonSelezionabili([...listTmp]);

    }

    function modalitaModificaOn() {
        setModificaMode(true);
        getOreLezione(data, idCorso, idDocente, idMateria, listAlunni, alunniSelezionati);
        // getOreLezione(data,idCorso, idDocente, idMateria, oraSelected, listAlunni)
        // isAlunnoIscrittoInData(data, listAlunni)
    }

    async function canAccessButtons(objEvento, permessiUtenteTmp) {
        let idEventoTmp = objEvento?.id;
        let isEditable = objEvento?.contenuto?.editable;

        let dataObj = {
            idEvento: idEventoTmp
        }
        //&& permessiUtenteTmp.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) IL CONTROLLO DEL RUOLO E' PASSATO AL BE
        if (idEventoTmp !== -1 && isEditable) {
            RegistroAPI.showPulsantiEvento(dataObj)
                .then((result) => {

                    setShowPulsanteEdit(result?.showPulsanteEdit ?? false)
                    setShowPulsanteDelete(result?.showPulsanteDelete ?? false)
                })
                .catch(function (error) { })
        }
    }

    async function deleteEvento() {
        let dataObj = {
            idEvento: notaSel?.id,
            tipo: notaSel?.tipologia_comunicazioni
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.eventoAggiunto) {
                    props.eventoAggiunto("Nota disciplinare eliminata", "success");

                }
                closeDialog();
            })
            .catch(function (error) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto(error.response.data.data[0].msg, "error");
            })
    }

    const changePin = debounce((event) => {
        setPinUtente(event.target.value);
    }, 100);

    function showPasswordInput() {
        setShowPassword(prev => !prev);
    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            {!modificaMode && (<Grid xs={12} md={12}>
                                <Typography><b>Data di inserimento:</b>  {convertISODate(notaSel?.created_at)} {'(Inserito da: ' + notaSel?.created_by_obj?.nome + ')'};</Typography>
                            </Grid>)}
                            {(!modificaMode && notaSel?.modified_at !== null) && (<Grid xs={12} md={12}>
                                <Typography><b>Data di modifica:</b>  {convertISODate(notaSel?.modified_at)}  {'(Modificato da: ' + notaSel?.modified_by_obj?.nome + ')'};</Typography>
                                <Typography><b>Motivazione della modifica:</b> {commentoModifica}</Typography>
                            </Grid>)}
                            {(!modificaMode && alunniSelezionati.length > 0) && (<Grid xs={12} md={12}>
                                <Typography><b>Destinatari:</b>

                                    {notaSel?.contenuto?.isInteraClasse ? (
                                        <Typography><b>Tutta la classe</b></Typography>
                                    ) : (
                                        <Box sx={{ p: 1, border: '1px solid #bdbdbd', borderRadius: '5px', maxHeight: '10vh', overflowY: 'scroll' }}>
                                            {alunniSelezionati.map((alunno) => (
                                                <Typography key={alunno.id}>{alunno.nome} {alunno.cognome}</Typography>
                                            ))}
                                        </Box>
                                    )}

                                </Typography>
                            </Grid>)}
                            {modificaMode && (<Grid xs={12} md={6}
                            // mt={{ xs: 3, md: 0 }}
                            >
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili alunniSelezionabiliNumber={alunniSelezionabiliNumber} selectAlunni={selectAlunni} alunniNonSelezionabili={alunniNonSelezionabili} listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                                ) : (
                                    <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Grid>)}
                            <Grid xs={12} md={modificaMode ? 6 : 12}>
                                <Box>

                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                                <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                                    minDate={dataInizioPeriodo}
                                                    maxDate={dataFinePeriodo}
                                                    disabled={!modificaMode}
                                                    label="Data"
                                                    value={data}
                                                    format="dd/MM/yyyy"
                                                    onChange={(value) => changeData(value)}
                                                    dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            {categorieList.length > 0 ? (
                                                <FormControl sx={{ width: '100%' }}>
                                                    <InputLabel id="categoria-label"> Categoria</InputLabel>
                                                    <Select
                                                        disabled={!modificaMode}
                                                        data-testid='selCategoriaNotaSelect'
                                                        label='Categoria '
                                                        labelId="materia-label"
                                                        value={categoriaSelected}
                                                        onChange={changeCategoria}
                                                    >
                                                        <MenuItem
                                                            key={-1}
                                                            value={-1}
                                                        >
                                                        </MenuItem>
                                                        {categorieList.map((categoria) => (
                                                            <MenuItem
                                                                key={categoria.id}
                                                                value={categoria.id}
                                                            >
                                                                {categoria.descrizione}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <Select disabled fullWidth >
                                                    <MenuItem
                                                        key={-1}
                                                        value={undefined}
                                                    >
                                                        Caricamento...
                                                    </MenuItem>
                                                </Select>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                    <TextField fullWidth sx={{ mt: 1 }} inputRef={inputDescrizioneCompitiRef} value={testoNota} disabled={!modificaMode}
                                        onChange={changeTestoVerifica} placeholder="Scrivi qui..." multiline rows={4} label={'Descrizione'} />
                                </Grid>
                                {modificaMode && (<Grid xs={12} md={12} sx={{ pt: 1 }}>
                                    {/* <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}> */}
                                    <FormControl sx={{ width: { xs: '100%', md: '50%' }, mt: 1 }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Pin di conferma modifica</InputLabel>
                                        <OutlinedInput type={showPassword ? 'text' : 'password'}
                                            inputRef={refPinConfermaBes}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={showPasswordInput}  >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={changePin}
                                            // onBlur={changePinConfermaBes}
                                            label="Pin di conferma modifica"
                                        />
                                    </FormControl>
                                    {/* </Grid> */}
                                </Grid>)}
                                {modificaMode && (<Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                    <TextField fullWidth sx={{ mt: 1 }} inputRef={inputCommentoModificaRef} value={commentoModifica} disabled={!modificaMode}
                                        onChange={changeCommentoModifica} placeholder="Scrivi qui..." multiline rows={2} label={'Commento Modifica'} />
                                </Grid>)}
                            </Grid>
                        </Grid>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"} sx={{ mt: 3, width: '100%' }}>
                            <Box>
                                {showPulsanteDelete && !modificaMode ? (
                                    <Button onClick={() => { setConfirmDeleteDialogIsOpen(true) }} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Elimina nota disciplinare</Button>
                                ) : null}
                            </Box>
                            <Box>
                                {showPulsanteEdit ? (
                                    <Fragment>
                                        {!modificaMode ? (
                                            <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">
                                                chiudi dettaglio
                                            </Button>
                                        ) : (
                                            <Button onClick={() => annullaModifiche()} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">
                                                Annulla modifiche
                                            </Button>
                                        )}

                                        {!modificaMode ? (
                                            <Button color="success" onClick={() => modalitaModificaOn()} disabled={testoNota === "" || alunniSelezionati.length === 0 || categoriaSelected === -1} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                                variant="contained" >
                                                Modifica nota disciplinare
                                            </Button>
                                        ) : (
                                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={testoNota === "" || alunniSelezionati.length === 0 || categoriaSelected === -1 || pinUtente === "" || commentoModifica === ''}
                                                sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} color="success" variant="contained" >
                                                Conferma Modifica
                                            </Button>
                                        )}
                                    </Fragment>
                                ) : (
                                    <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">chiudi dettaglio</Button>
                                )}

                            </Box>
                        </Box>
                    </Fragment>
                ) : null
                }

            </Container >

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma modifica nota disciplinare'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare la nota disciplinare per `}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={modificaNota}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confirmDeleteDialogIsOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare la nota disciplinare? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfirmDeleteDialogIsOpen(false) }}
                conferma={deleteEvento}
                disabledButton={false}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertIsOpen} autoHideDuration={6000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}