import { Box, Link, Typography,  Container, Breadcrumbs, Tab, Tabs } from "@mui/material";
import { useState, useEffect} from "react"
import { useTranslation } from 'react-i18next';

import { RegistroAPI } from "../../../api/registroApi";
import ElencoNoteDisciplinari from "./elencoNoteDisciplinari";
import AggiuntaNota from "./aggiuntaNota";

export default function GestioneNoteDisciplinari(props) {
    const { t, i18n } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [isDialog, setIsDialog] = useState(false);

    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);

    const [isTuttaLaClasse, setIsTuttaLaClasse] = useState(false);
    const [showOnlyElenco, setShowOnlyElenco] = useState(false);

    const [dataRichiesta, setDataRichiesta] = useState(null);

    const [idDocente, setIdDocente] = useState(-1);
    const [idMateria, setIdMateria] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);


    const [tipoRegistro, setTipoRegistro] = useState("classe");


    const [permessiUtente, setPermessiUtente] = useState([]);
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")
    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])
    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente]);
        }
    }, [props.permessiUtente])

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            if (props.listAlunni == null)
                getAlunniByClasseID(); //TODO
            else {
                setListAlunni([...props.listAlunni]);
            }
        }
    }, [props.listAlunni])


    useEffect(() => {
        if (props.dataRichiesta !== undefined)
            setDataRichiesta(props.dataRichiesta);
    }, [props.dataRichiesta])

    useEffect(() => {
        setIdDocente(props.idDocente)
    }, [props.idDocente])

    useEffect(() => {
        if (props.idMateria !== undefined)
            setIdMateria(props.idMateria)
        if (props.idCorso !== undefined) {
            setIdCorso(props.idCorso)
        }
    }, [props.idMateria, props.idCorso])

    useEffect(() => {
        if (props.isTuttaLaClasse !== undefined) {
            setIsTuttaLaClasse(props.isTuttaLaClasse)
        }
    }, [props.isTuttaLaClasse])

    useEffect(() => {
        if (props.showOnlyElenco !== undefined)
            setShowOnlyElenco(props.showOnlyElenco)
    }, [props.showOnlyElenco])

    useEffect(() => {
        if (props.dataRichiesta !== undefined)
            setDataRichiesta(props.dataRichiesta)
    }, [props.dataRichiesta])
    



    useEffect(() => {
        if (props.tipoRegistro !== undefined) {
            setTipoRegistro([props.tipoRegistro])
        }
    }, [props.tipoRegistro])

    useEffect(() => {
        if (props.alunniSelezionati !== undefined) {
            setAlunniSelezionati([...props.alunniSelezionati])
        }
    }, [props.alunniSelezionati])

    useEffect(() => {
        if (props.isDialog)
            setIsDialog(props.isDialog);
    }, [props.isDialog])

    useEffect(() => {
        if (props.tabIndex)
            setTabIndex(props.tabIndex);
    }, [props.tabIndex])

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }

    function eventoAggiunto(testo, severity) {
        if (props.eventoAggiunto)
            props.eventoAggiunto(testo, severity);
    }

    function changeTabIndex(event, newValue) {
        setTabIndex(newValue);
    }

    function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
            })
    }


    return (
        <Container maxWidth="xl">
            {!isDialog ? (
                <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
                    <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                    <Typography color="text.disabled">Modulo comunicazioni</Typography>
                    <Typography color="text.disabled">{tabIndex === 0 ? 'Elenco note' : 'Aggiungi nota'}</Typography>
                </Breadcrumbs>
            ) : null}
            {!showOnlyElenco && (

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={changeTabIndex} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    <Tab label='Elenco note' />
                    <Tab label='Aggiungi nota' />
                </Tabs>
            </Box>
            )}
            <Box sx={{ p: { xs: 0, md: 5 } }}>
                {tabIndex === 0 && (
                    <ElencoNoteDisciplinari showOnlyElenco={showOnlyElenco} dataRichiesta={dataRichiesta} tipoRegistro={tipoRegistro} permessiUtente={permessiUtente} idDocente={idDocente} idMateria={tipoRegistro === "docente" ? idMateria : null} idCorso={idCorso} alunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                )}
                {tabIndex === 1 && (
                    <AggiuntaNota dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog} isTuttaLaClasse={isTuttaLaClasse}
                        isDialog={true} listAlunni={listAlunni} alunniSelezionati={alunniSelezionati} />
                )}
            </Box>
        </Container>
    )
}