import { useState, useEffect, Fragment, useRef } from "react"
import { debounce } from '@mui/material/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MenuItem, FormControl, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, Snackbar, Alert, OutlinedInput, InputLabel, InputAdornment, Select, Divider } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getDayMonthAndYearErrorDialog } from "../shared/metodiDateUtility";
import { RegistroAPI } from "../../api/registroApi";

export default function ElencoOreDaFirmare(props) {

    const refPinConfermaOra = useRef(null);

    const [dataRichiesta, setDataRichiesta] = useState(null) //YYYY-MM-DD
    const [elencoOreFirme, setElencoOreFirme] = useState([]);
    const [hasElencoOreFirmeLoaded, setHasElencoOreFirmeLoaded] = useState(false);
    const [idOrarioFirmaElenco, setIdOrarioFirmaElenco] = useState(-1)
    const [orarioSelezionatoObj, setOrarioSelezionatoObj] = useState(null)
    const [modificaMode, setModificaMode] = useState(false);
    const [typeLezione, setTypeLezione] = useState(-1);
    const [pinConfermaOra, setPinConfermaOra] = useState("");
    const [idDocente, setIdDocente] = useState(-1)

    const [dialogFirmaOpen, setDialogFirmaOpen] = useState(false)
    const [showPasswordPinOra, setShowPasswordPinOra] = useState(false);
    const [showFirmaOraDialog, setShowFirmaOraDialog] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    useEffect(() => {
        if (props.dataRichiesta !== undefined) {
            setDataRichiesta(props.dataRichiesta);
            getOreGiornataFirma(props.dataRichiesta);
        }
    }, [props.dataRichiesta])

    useEffect(() => {
        if (props.idDocente !== undefined) {
            setIdDocente(props.idDocente);
        }
    }, [props.idDocente])

    function changeIdOrarioFirmaElenco(objOrario) {
        setIdOrarioFirmaElenco(objOrario?.idOrario ?? -1);
        setOrarioSelezionatoObj(objOrario);
    }

    function closeDialogElencoOre() {
        if (props.closeDialogElencoOre)
            props.closeDialogElencoOre()
    }

    function openDialogFirma(orarioSelezionatoObjTmp) {
        let isOpen = !dialogFirmaOpen;
    
        if (isOpen) {
            setOrarioSelezionatoObj(orarioSelezionatoObjTmp)
            if (orarioSelezionatoObjTmp?.check) { //Se è un'ora firmata setto il tipo di lezione (select)
                if (orarioSelezionatoObjTmp?.tipoOra == "teoria")
                    setTypeLezione(0);
                else setTypeLezione(1);
            }
        } else {
            setOrarioSelezionatoObj(null);
            setIdOrarioFirmaElenco(-1)
            setModificaMode(false);
        }

        setDialogFirmaOpen(isOpen);

    }

    function changeTypeLezione(e) {
        setTypeLezione(e.target.value)
    }

    const changePinConfermaOraLength = debounce((event) => {
        setPinConfermaOra(event.target.value);
    }, 100);

    function firmaOraPinDialog() {
        setPinConfermaOra("");
        setShowFirmaOraDialog(prev => !prev);
        setModificaMode(false);
    }
    function confirmOraPin() {
        //se il pin è corretto
        if (pinConfermaOra.length >= 1) {
            firmaOra();
        }
        //else openAlertMsg("Il campo PIN non può essere lasciato vuoto", "error")

    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function showPasswordInput() {
        setShowPasswordPinOra(prev => !prev);
    }

    async function getOreGiornataFirma(dataRichiesta) {
        let dataObj = {
            data: dataRichiesta,
        }

        setHasElencoOreFirmeLoaded(false);

        RegistroAPI.getElencoOreGiornata(dataObj)
            .then((result) => {
                setHasElencoOreFirmeLoaded(true);
                setElencoOreFirme([...result.ore]);
            })
            .catch((error) => { 
                setHasElencoOreFirmeLoaded(true);
                setElencoOreFirme([]);
            })

    }

    async function firmaOra() {
        let tipoOra = typeLezione === 0 ? "teoria" : "pratica";
        let dataObj = {
            pin: pinConfermaOra,
            idDocente: idDocente,
            idOrario: orarioSelezionatoObj.idOrario,
            idCorso: orarioSelezionatoObj.idCorso,
            dataLezione: dataRichiesta, //2022-10-04
            tipoOra: tipoOra,
            idMateria: orarioSelezionatoObj.idMateria
        }
        if (orarioSelezionatoObj.idOrario !== -1) {
            RegistroAPI.firmaOra(dataObj)
                .then((result) => {
                    getOreGiornataFirma(dataRichiesta);
                    openDialogFirma(); //Chiudo dialog
                    openAlertMsg("Presenza firmata correttamente", "success")
                })
                .catch((err) => {
                    let errorMsg = "";
                    if (err.response.status === 420) {
                        errorMsg = err?.response?.data?.data[0]?.msg ?? "Pin errato";
                    } else {
                        errorMsg = "Pin errato";
                    }
                    openAlertMsg(errorMsg, "error")
                })
        } else {
        }
    }

    async function modificaFirmaOra() {
        let tipoOraTmp = typeLezione === 0 ? "teoria" : "pratica";
        let dataObj = {
            dataLezione: dataRichiesta, //YYYY-MM-DD
            idDocente: idDocente,
            idOrario: orarioSelezionatoObj.idOrario,
            idCorso: orarioSelezionatoObj.idCorso,
            tipoOra: tipoOraTmp,
            idMateria: orarioSelezionatoObj.idMateria

        }
        RegistroAPI.modificaFirmaOra(dataObj)
            .then((result) => {
                getOreGiornataFirma(dataRichiesta);
                openDialogFirma(); //Chiudo dialog
                openAlertMsg("Ora firma modificata", "success")
            })
            .catch((error) => {
                openAlertMsg("Errore durante modifica della firma dell'ora", "error")
            })

    }


    return (
        <Fragment>
            {dataRichiesta !== null && (
                <Typography variant="h6" sx={{ mb: 2 }}>Ore di lezione in programma per il <b>{getDayMonthAndYearErrorDialog(dataRichiesta)}</b></Typography>
            )}
            {
                hasElencoOreFirmeLoaded ? (
                    <Box>
                        {elencoOreFirme.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Typography>ORA (MATERIA)</Typography>
                                        </TableCell>
                                      
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Typography>CORSO</Typography>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {elencoOreFirme.map((ora, index) => (
                                        <TableRow key={ora.idOrario} >
                                            <TableCell sx={{ textAlign: 'center', borderRight: '1px solid rgba(224, 224, 224, 1)', background: (ora?.idOrario === idOrarioFirmaElenco) ? '#1976D2' : 'white', color: (ora?.idOrario === idOrarioFirmaElenco) ? 'white' : 'black' }}>
                                                <Typography textAlign={"start"}> <b>{ora?.numeroOra ?? '0'}^ ora </b> ({ora?.nomeMateria})</Typography>
                                                {ora?.check && (
                                                    <Typography variant="subtitle2" textAlign={"start"} sx={{ mt: 1, color:'#1976d2'}}>ORA FIRMATA ({ora?.tipoOra})</Typography> 
                                                )}
                                            </TableCell>

                                     
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', minWidth: '200px', background: (ora?.idOrario === idOrarioFirmaElenco) ? '#1976D2' : 'white', color: (ora?.idOrario === idOrarioFirmaElenco) ? 'white' : 'black' }}>
                                                <Typography>
                                                    {ora?.codiceCorso + " - " + ora?.nomeCorso + " (" + ora?.codSimon + ")"}  {(ora?.idOrario === idOrarioFirmaElenco) ? "- SELEZIONATA" : ""}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{  textAlign: 'center', background: (ora?.idOrario === idOrarioFirmaElenco) ? '#1976D2' : 'white' }}>
                                                {ora?.check ? (
                                                    <Fragment>
                                                        <Button variant="contained" color="primary" onClick={() => openDialogFirma(ora)}>Modifica firma</Button>

                                                        {/* <Typography sx={{ color: (ora?.idOrario === idOrarioFirmaElenco) ? 'white' : 'black' }} variant="body2">PRESENZA FIRMATA</Typography>
                                                        <Typography sx={{ color: (ora?.idOrario === idOrarioFirmaElenco) ? 'white' : 'black' }} variant="body2">({ora?.tipoOra})</Typography> */}

                                                    </Fragment>
                                                ) : (
                                                    <Button variant="contained" color="success" onClick={() => openDialogFirma(ora)}>Firma presenza</Button>
                                                    // <Typography sx={{ color: (ora?.idOrario === idOrarioFirmaElenco) ? 'white' : '#1976d2' }} variant="body1"><b>DA FIRMARE</b></Typography>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>


                        ) : (
                            <Box className="centered-column-direction" >
                                <CircularProgress />
                                <Typography sx={{ mt: 3 }}>Non ci sono ore di lezione nella data selezionata.</Typography>
                            </Box>
                        )}
                        <Box sx={{ mt: 4 }}>
                            <Button variant="outlined" color="error" fullWidth onClick={closeDialogElencoOre}>Chiudi finestra</Button>
                            {/* <Button variant="contained" color="success" disabled={idOrarioFirmaElenco === -1 || !hasElencoOreFirmeLoaded} fullWidth sx={{ mt: 2 }} onClick={openDialogFirma}>Conferma e prosegui</Button> */}
                        </Box>
                    </Box>
                ) : (
                    <Box className="centered-column-direction">
                        <CircularProgress />
                        <Typography variant="body2" sx={{ mt: 3 }}>Caricamento elenco...</Typography>
                    </Box>
                )
            }

            <Dialog open={dialogFirmaOpen} hideBackdrop fullWidth maxWidth="sm">
                <DialogTitle>{!orarioSelezionatoObj?.check ? "Conferma firma" : 'Modifica tipologia di lezione'}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" >
                        {!orarioSelezionatoObj?.check ? 'Per poter firmare la presenza ' : 'La presenza è stata firmata '} <b>({orarioSelezionatoObj?.numeroOra}^ora - {orarioSelezionatoObj?.nomeMateria}, del {getDayMonthAndYearErrorDialog(dataRichiesta)})</b>{!orarioSelezionatoObj?.check ? ' è necessario inserire il pin di conferma e selezionare la tipologia di lezione' : null}.
                    </Typography>
                    {orarioSelezionatoObj?.check && (
                    <Typography><b>Tipologia lezione</b>: {orarioSelezionatoObj?.tipoOra}</Typography>
                    )}
                    <Divider sx={{ my: 1 }} />
                    <Typography><b>Ore totali:</b> {orarioSelezionatoObj?.percentuali?.oreAssegnate ?? 0} </Typography> 
                    <Typography><b>Ore  teoria</b> (firmate): {orarioSelezionatoObj?.percentuali?.teoria?.numeroOre ?? 0} - {(orarioSelezionatoObj?.percentuali?.teoria?.percentuale ?? 0) + "%"}</Typography>
                    <Typography sx={{ mb: 2 }}><b>Ore pratica</b> (firmate): {orarioSelezionatoObj?.percentuali?.pratica?.numeroOre ?? 0} - {(orarioSelezionatoObj?.percentuali?.pratica?.percentuale ?? 0) + "%"}</Typography>

                    {(modificaMode || !orarioSelezionatoObj?.check) && (<Box display={"flex"} justifyContent={"center"} sx={{ mt: 5, mb: 2 }}>
                        <FormControl sx={{ width: '50%' }}>
                            <InputLabel >
                                Tipologia di lezione
                            </InputLabel>
                            <Select
                                label='Tipologia di lezione '
                                value={typeLezione}
                                onChange={changeTypeLezione}
                            >
                                <MenuItem key={-1} value={-1} >
                                </MenuItem>
                                <MenuItem key={0} value={0} >
                                    Teorico
                                </MenuItem>
                                <MenuItem key={1} value={1} >
                                    Pratico
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>)}

                    {!orarioSelezionatoObj?.check && (<Box display={"flex"} justifyContent={"center"} sx={{ mt: 2, mb: 5 }}>
                        <FormControl sx={{ width: '50%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Pin di conferma</InputLabel>
                            <OutlinedInput type={showPasswordPinOra ? 'text' : 'password'}
                                inputRef={refPinConfermaOra}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={showPasswordInput}  >
                                            {showPasswordPinOra ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={changePinConfermaOraLength}
                                label="Pin di conferma"
                            />
                        </FormControl>
                    </Box>)}

                    <Box display={"flex"} justifyContent={"end"}>
                        {orarioSelezionatoObj?.check ? (
                            <Box sx={{ mt: 1 }}>
                                {!modificaMode ? (
                                    <Button onClick={openDialogFirma} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">chiudi dettaglio</Button>
                                ) : (<Button onClick={firmaOraPinDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla modifiche</Button>)}
                                {!modificaMode ? (
                                    <Button onClick={() => setModificaMode(true)} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} variant="contained" >
                                        Modifica tipologia di lezione
                                    </Button>
                                ) : (
                                    <Button onClick={modificaFirmaOra} disabled={(typeLezione === -1) ? true : false} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                        color="success" variant="contained" >
                                        Conferma Modifica
                                    </Button>)
                                }
                            </Box>)
                            :
                            (
                                <Box>
                                    <Button variant="outlined" color="error" onClick={openDialogFirma}>Annulla</Button>
                                    <Button sx={{ ml: 1, px: 5 }} variant="contained"
                                        disabled={(typeLezione === -1 || pinConfermaOra === '') ? true : false}
                                        color="success" onClick={confirmOraPin}>Conferma</Button>
                                </Box>
                            )
                        }
                    </Box>
                </DialogContent>
            </Dialog>


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    )
}