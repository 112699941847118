import { Alert, Box, Button, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import CustomDatePicker from "../../../shared/customDatePicker";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowForward, Check, Edit, ExpandLess, ExpandMore } from "@mui/icons-material";
import { convertDataGetProssimeLezione } from "../../../shared/metodiDateUtility";
import { DateTime } from "luxon";

export default function AnagraficaAzienda(props) {

    const [idAzienda, setIdAzienda] = useState(-1);
    const [natureGiuridiche, setNatureGiuridiche] = useState([]);
    const [settori, setSettori] = useState([]);
    const [tipologieStakeholders, setTipologieStakeholders] = useState([]);

    const [dataExist, setDataExist] = useState(false);
    const [isPIvaEditable, setIsPIvaEditable] = useState(true);
    const [isCFEditable, setIsCFEditable] = useState(true);

    const [aziendaFormCollapsed, setAziendaLegaleFormCollapsed] = useState(true);
    const [sedeLegaleFormCollapsed, setSedeLegaleFormCollapsed] = useState(true);
    const [rapLegaleFormCollapsed, setRapLegaleFormCollapsed] = useState(true);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [pIvaValid, setPIvaValid] = useState(true);
    const [pIvaMessageError, setPIvaMessageError] = useState('');
    const [CFValid, setCFValid] = useState(true);
    const [CFMessageError, setCFMessageError] = useState('');
    const [CFRapLegaleValid, setCFRapLegaleValid] = useState(true);
    const [CFRapLegaleMessageError, setCFRapLegaleMessageError] = useState('');

    // AZIENDA
    const [pIva, setPIva] = useState('');
    const [CF, setCF] = useState('');
    const [ragioneSociale, setRagioneSociale] = useState('');
    const [ragioneSocialeValid, setRagioneSocialeValid] = useState(true);
    const [codAteco, setCodAteco] = useState('');
    const [codAtecoValid, setCodAtecoValid] = useState(true);
    const [idNaturaGiuridica, setIdNaturaGiuridica] = useState(-1);
    const [idTipologiaStakeholder, setIdTipologiaStakeholder] = useState(-1);
    const [idSettore, setIdSettore] = useState(-1);
    const [saveErrorAzienda, setSaveErrorAzienda] = useState(false);
    const [saveMsgErrorAzienda, setSaveMsgErrorAzienda] = useState("");

    // RAPPRESENTANTE LEGALE
    const [idRapLegale, setIdRapLegale] = useState(-1);
    const [CFRapLegale, setCFRapLegale] = useState('');
    const [nomeRapLegale, setNomeRapLegale] = useState('');
    const [nomeRapLegaleValid, setNomeRapLegaleValid] = useState(true);
    const [cognomeRapLegale, setCognomeRapLegale] = useState('');
    const [cognomeRapLegaleValid, setCognomeRapLegaleValid] = useState(true);
    const [dataNascitaRapLegale, setDataNascitaRapLegale] = useState(null);
    const [luogoNascitaRapLegale, setLuogoNascitaRapLegale] = useState('');
    const [luogoNascitaRapLegaleValid, setLuogoNascitaRapLegaleValid] = useState(true);
    const [saveErrorRapLegale, setSaveErrorRapLegale] = useState(false);
    const [saveMsgErrorRapLegale, setSaveMsgErrorRapLegale] = useState('');

    // SEDE LEGALE
    const [idSedeLegale, setIdSedeLegale] = useState(-1);
    const [indirizzoSedeLegale, setIndirizzoSedeLegale] = useState('');
    const [indirizzoSedeLegaleValid, setIndirizzoSedeLegaleValid] = useState(true);
    const [capSedeLegale, setCapSedeLegale] = useState('');
    const [capSedeLegaleValid, setCapSedeLegaleValid] = useState(true);
    const [capSedeLegaleErrorMsg, setCapSedeLegaleErrorMsg] = useState('');
    const [cittaSedeLegale, setCittaSedeLegale] = useState('');
    const [cittaSedeLegaleValid, setCittaSedeLegaleValid] = useState(true);
    const [mailSedeLegale, setMailSedeLegale] = useState('');
    const [sitoSedeLegale, setSitoSedeLegale] = useState('');
    const [telefonoSedeLegale, setTelefonoSedeLegale] = useState('');
    const [pecSedeLegale, setPecSedeLegale] = useState('');
    const [faxSedeLegale, setFaxSedeLegale] = useState('');
    const [saveErrorSedeLegale, setSaveErrorSedeLegale] = useState(false);
    const [saveMsgErrorSedeLegale, setSaveMsgErrorSedeLegale] = useState('');

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [isModificaMode, setIsModificaMode] = useState(false);

    useEffect(() => {
        if (props.isModificaMode !== undefined)
            setIsModificaMode(props.isModificaMode)
    }, [props.isModificaMode])

    useEffect(() => {
        if (props.isCanEdit !== undefined)
            setIsCanEdit(props.isCanEdit)
    }, [props.isCanEdit])

    useEffect(() => {
        setIsConfermaLoading(false);
        getDatiPrecompilati();
    }, []);

    useEffect(() => {
        if (props.idAzienda !== undefined) {
            setIdAzienda(props.idAzienda);
        }
    }, [props.idAzienda])

    useEffect(() => {
        if (props.dataExist !== undefined)
            setDataExist(props.dataExist);
    }, [props.dataExist])

    useEffect(() => {
        if (props.dataExist !== undefined && props.azienda !== undefined && props.radioSelectPIvaCF !== undefined && props.cfPiva !== undefined) {
            let aziendaTmp = props.azienda;

            if (props.dataExist) {
                setIdAzienda(aziendaTmp?.id)
                setPIva(aziendaTmp?.pIva ?? '');
                setCF(aziendaTmp?.CF ?? '');
                setCodAteco(aziendaTmp?.codAteco ?? '');
                setIdNaturaGiuridica(aziendaTmp?.idNaturaGiuridica ?? -1);
                setIdTipologiaStakeholder(aziendaTmp?.idTipologiaStakeholder ?? -1);
                setIdSettore(aziendaTmp?.idSettore ?? -1);
                setRagioneSociale(aziendaTmp?.ragioneSociale ?? '')
            } else {
                let radioSelectPIvaCFTmp = props.radioSelectPIvaCF;
                if (radioSelectPIvaCFTmp === 0) {
                    setPIva(props.cfPiva);
                    setIsPIvaEditable(false);
                }
                else {
                    setCF(props.cfPiva);
                    setIsCFEditable(false);
                }
            }
        }
    }, [props.dataExist, props.azienda, props.radioSelectPIvaCF, props.cfPiva]);

    useEffect(() => {
        if (props.responsabileLegale !== undefined) {
            let responsabileLegaleTmp = props.responsabileLegale;
            setIdRapLegale(responsabileLegaleTmp?.id ?? null)
            setCFRapLegale(responsabileLegaleTmp?.CF ?? '');
            setNomeRapLegale(responsabileLegaleTmp?.nome ?? '');
            setCognomeRapLegale(responsabileLegaleTmp?.cognome ?? '');
            setDataNascitaRapLegale(responsabileLegaleTmp?.dataNascita ?? null);
            setLuogoNascitaRapLegale(responsabileLegaleTmp?.luogoNascita ?? '');
        }
    }, [props.responsabileLegale]);

    useEffect(() => {
        if (props.sedeLegale !== undefined) {
            let sedeLegaleTmp = props.sedeLegale;
            setIdSedeLegale(sedeLegaleTmp?.id ?? null)
            setIndirizzoSedeLegale(sedeLegaleTmp?.indirizzo ?? '');
            setCapSedeLegale(sedeLegaleTmp?.cap ?? '');
            setCittaSedeLegale(sedeLegaleTmp?.citta ?? '');
            setMailSedeLegale(sedeLegaleTmp?.mail ?? '');
            setSitoSedeLegale(sedeLegaleTmp?.sito ?? '');
            setTelefonoSedeLegale(sedeLegaleTmp?.telefono ?? '');
            setPecSedeLegale(sedeLegaleTmp?.pec ?? '');
            setFaxSedeLegale(sedeLegaleTmp?.fax ?? '')
        }
    }, [props.sedeLegale]);

    function nexTab() {
        if (props.nextTab) props.nextTab()
    }
    function closeDialogAggiungiAzienda() {
        if (props.closeDialog)
            props.closeDialog()
    }
    function changeIdAzienda(value) {
        if (props.changeIdAzienda) props.changeIdAzienda(value)
    }

    async function getDatiPrecompilati() {
        TirociniAPI.getDatiPrecompilati()
            .then((result) => {
                let datiPrecompilatiTmp = result;
                setSettori([...datiPrecompilatiTmp?.settori]);
                setNatureGiuridiche([...datiPrecompilatiTmp?.natureGiuridiche]);
                setTipologieStakeholders([...datiPrecompilatiTmp?.tipologieStakeholders]);
            })
            .catch(function (error) {
            })

    }

    async function salvataggioAzienda() {
        setButtonConfermaDisabled(true)
        setIsConfermaLoading(true);
        let obj = {
            pIva: pIva,
            CF: CF !== '' ? CF : null,
            ragioneSociale: ragioneSociale,
            codAteco: codAteco,
            idNaturaGiuridica: idNaturaGiuridica !== -1 ? idNaturaGiuridica : null,
            idTipologiaStakeholder: idTipologiaStakeholder !== -1 ? idTipologiaStakeholder : null,
            idSettore: idSettore !== -1 ? idSettore : null,
        }
        TirociniAPI.salvataggioAzienda(obj)
            .then((result) => {
                let idAziendaTmp = result.idAzienda;
                setIdAzienda(idAziendaTmp);
                salvataggioRappresentanteLegaleAzienda(idAziendaTmp);
                setSaveErrorAzienda(false);
                setSaveErrorRapLegale(false);
                setSaveErrorSedeLegale(false);
                setSaveMsgErrorAzienda('')
                setSaveMsgErrorRapLegale('')
                setSaveMsgErrorSedeLegale('')


            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error")
                }
                setIsConfermaLoading(false);

                setSaveErrorAzienda(true);
                setSaveErrorRapLegale(true);
                setSaveErrorSedeLegale(true);
                setSaveMsgErrorAzienda("Errore durante il salvataggio dei dati dell'Agenzia. Riprovare tra qualche istante.")
                setSaveMsgErrorRapLegale("Errore durante il salvataggio dei dati del Rappresentante legale. Riprovare tra qualche istante.")
                setSaveMsgErrorSedeLegale("Errore durante il salvataggio dei dati della Sede legale. Riprovare tra qualche istante.")
                setButtonConfermaDisabled(false)
            })

    }

    async function salvataggioRappresentanteLegaleAzienda(idAzienda) {
        let obj = {
            id: idRapLegale !== -1 ? idRapLegale : null,
            CF: CFRapLegale,
            nome: nomeRapLegale,
            cognome: cognomeRapLegale,
            dataNascita: dataNascitaRapLegale,
            luogoNascita: luogoNascitaRapLegale,
            idAzienda: idAzienda,
            idRuolo: 0
        }
        TirociniAPI.salvataggioRappresentanteLegaleAzienda(obj)
            .then((result) => {
                salvataggioSedeAzienda(idAzienda);
                setSaveErrorRapLegale(false);
                setSaveErrorSedeLegale(false);
                setSaveMsgErrorRapLegale('')
                setSaveMsgErrorSedeLegale('')
            })
            .catch(function (error) {
                closeAlertConfirmInserimento();
                setIsConfermaLoading(false);

                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio dell'anagrafica. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio dell'anagrafica. Riprovare tra qualche istante.", "error");
                }
                setSaveErrorRapLegale(true);
                setSaveErrorSedeLegale(true);
                setSaveMsgErrorRapLegale("Errore durante il salvataggio dei dati del Rappresentante legale. Riprovare tra qualche istante.")
                setSaveMsgErrorSedeLegale("Errore durante il salvataggio dei dati della Sede legale. Riprovare tra qualche istante.")
                setButtonConfermaDisabled(false)
            })

    }

    async function salvataggioSedeAzienda(idAzienda) {
        let obj = {
            id: idSedeLegale !== -1 ? idSedeLegale : null,
            indirizzo: indirizzoSedeLegale,
            cap: capSedeLegale,
            citta: cittaSedeLegale,
            mail: mailSedeLegale !== '' ? mailSedeLegale : null,
            sito: sitoSedeLegale !== '' ? sitoSedeLegale : null,
            telefono: telefonoSedeLegale !== '' ? telefonoSedeLegale : null,
            pec: pecSedeLegale !== '' ? pecSedeLegale : null,
            fax: faxSedeLegale !== '' ? faxSedeLegale : null,
            idAzienda: idAzienda,
            idTipologia: 0
        }
        TirociniAPI.salvataggioSedeAzienda(obj)
            .then((result) => {
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                changeIdAzienda(idAzienda);
                nexTab();
                setSaveErrorSedeLegale(false);
                setSaveMsgErrorSedeLegale('')
                setButtonConfermaDisabled(false)
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio della sede. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio della sede. Riprovare tra qualche istante.", "error");
                }
                setIsConfermaLoading(false);

                setSaveErrorSedeLegale(true);
                setSaveMsgErrorSedeLegale("Errore durante il salvataggio dei dati della Sede legale. Riprovare tra qualche istante.")
                setButtonConfermaDisabled(false)
            })

    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function onChangeValueDataNascita(e) {
        let formattedDataTmp = convertDataGetProssimeLezione(e, true);
        setDataNascitaRapLegale(formattedDataTmp)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeRagioneSociale(e) {
        let valueTmp = e.target.value;
        setRagioneSociale(valueTmp)
        if (valueTmp !== '') {
            setRagioneSocialeValid(true)
        } else {
            setRagioneSocialeValid(false)
        }
    }

    function changeCodAteco(e) {
        let valueTmp = e.target.value;
        setCodAteco(valueTmp)
        if (valueTmp !== '') {
            setCodAtecoValid(true)
        } else {
            setCodAtecoValid(false)
        }
    }

    function changeIdNaturaGiuridica(e) {
        setIdNaturaGiuridica(e.target.value)
    }

    function changeIdTipologiaStakeholder(e) {
        setIdTipologiaStakeholder(e.target.value)
    }

    function changeIdSettore(e) {
        setIdSettore(e.target.value)
    }

    function changeNomeRapLegale(e) {
        let valueTmp = e.target.value;
        setNomeRapLegale(valueTmp)
        if (valueTmp !== '') {
            setNomeRapLegaleValid(true)
        } else {
            setNomeRapLegaleValid(false)
        }
    }

    function changeCognomeRapLegale(e) {
        let valueTmp = e.target.value;
        setCognomeRapLegale(valueTmp)
        if (valueTmp !== '') {
            setCognomeRapLegaleValid(true)
        } else {
            setCognomeRapLegaleValid(false)
        }
    }

    function changeLuogoNascitaRapLegale(e) {
        let valueTmp = e.target.value;
        setLuogoNascitaRapLegale(valueTmp)
        if (valueTmp !== '') {
            setLuogoNascitaRapLegaleValid(true)
        } else {
            setLuogoNascitaRapLegaleValid(false)
        }
    }

    function changeIndirizzoSedeLegale(e) {
        let valueTmp = e.target.value;
        setIndirizzoSedeLegale(valueTmp)
        if (valueTmp !== '') {
            setIndirizzoSedeLegaleValid(true)
        } else {
            setIndirizzoSedeLegaleValid(false)
        }
    }
    function changeCapSedeLegale(e) {
        let valueTmp = e.target.value
        let valueNoExtraCharactersTmp = valueTmp.replace(/[^0-9]/g, '');
        setCapSedeLegale(valueNoExtraCharactersTmp);
        if (valueNoExtraCharactersTmp.length === 5) {
            setCapSedeLegaleValid(true);
            setCapSedeLegaleErrorMsg('');
        } else {
            setCapSedeLegaleValid(false);
            setCapSedeLegaleErrorMsg('Il cap deve essere composto da 5 caratteri.');
        }
    }
    function changeCittaSedeLegale(e) {
        let valueTmp = e.target.value;
        setCittaSedeLegale(valueTmp)
        if (valueTmp !== '') {
            setCittaSedeLegaleValid(true)
        } else {
            setCittaSedeLegaleValid(false)
        }
    }
    function changeMailSedeLegale(e) {
        setMailSedeLegale(e.target.value)
    }
    function changePecSedeLegale(e) {
        setPecSedeLegale(e.target.value)
    }
    function changeSitoSedeLegale(e) {
        setSitoSedeLegale(e.target.value)
    }
    function changeTelefonoSedeLegale(e) {
        setTelefonoSedeLegale(e.target.value)
    }
    function changeFaxSedeLegale(e) {
        setFaxSedeLegale(e.target.value)
    }

    function changeCF(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCF(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setCFValid(true);
            setCFMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
                setCFValid(true);
                setCFMessageError('')
            } else {
                // setPIvaValid(false);
                setCFValid(false);
                setCFMessageError('Il C.F si compone di 11 o 16 caratteri')
            }
        }
    }

    function changePIva(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^0-9]/g, '');

        setPIva(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp.length === 11) {
            setPIvaValid(true);
            setPIvaMessageError('')
        } else {
            // setPIvaValid(false);
            setPIvaValid(false);
            setPIvaMessageError('La P.Iva si compone di 11 caratteri')
        }

    }

    function changeCFRespLegale(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCFRapLegale(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
            setCFRapLegaleValid(true);
            setCFRapLegaleMessageError('')
        } else {
            // setPIvaValid(false);
            setCFRapLegaleValid(false);
            setCFRapLegaleMessageError('Il C.F si compone di 11 o 16 caratteri')
        }
    }

    function validateFormData() {
        if (ragioneSociale !== ''
            &&
            (pIva !== '' && pIvaValid)
            &&
            ((CF !== '' && CFValid) || (CF === '' && CFValid))
            &&
            // codAteco !== ''
            // &&
            cognomeRapLegale !== ''
            &&
            nomeRapLegale !== ''
            &&
            // (CFRapLegale !== '' && CFRapLegaleValid)
            // &&
            indirizzoSedeLegale !== ''
            &&
            (capSedeLegale !== '' && capSedeLegale.length === 5)
            &&
            cittaSedeLegale !== ''
            //&&
            // dataNascitaRapLegale !== null
            // &&
            // dataNascitaRapLegale !== ''
            // &&
            // luogoNascitaRapLegale !== ''
        ) return false
        else return true
    }

    function proseguiSenzaConferma() {
        nexTab();
        changeIdAzienda(idAzienda);
    }

    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                {(dataExist && !isModificaMode) && (
                    <Alert sx={{ mb: 2 }} severity="info">Risulta già un'azienda nel nostro sistema con la stessa partita IVA o codice fiscale inserito.</Alert>
                )}
                <Paper elevation={1} sx={{ p: 2, border: saveErrorAzienda ? 'red 1px solid' : '' }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Azienda</Typography>
                            <Typography textAlign={'start'} variant="caption" sx={{ ml: 2, color: 'red' }}>{saveMsgErrorAzienda}</Typography>
                        </Box>
                        <IconButton onClick={() => setAziendaLegaleFormCollapsed(prev => !prev)}>
                            {rapLegaleFormCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Box>
                    <Collapse in={aziendaFormCollapsed}>
                        <Grid container spacing={2} mt={2}>
                            <Grid xs={12} md={12}>
                                <TextField
                                    disabled={!isCanEdit}
                                    error={ragioneSociale === '' && !ragioneSocialeValid}
                                    defaultValue={ragioneSociale}
                                    value={ragioneSociale}
                                    required
                                    label={'Ragione sociale'}
                                    onChange={changeRagioneSociale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled={idAzienda !== -1 || !isCanEdit || !isPIvaEditable}
                                    error={!pIvaValid ? true : false}
                                    inputProps={{ maxLength: 11 }}
                                    defaultValue={pIva ?? ''}
                                    value={pIva ?? ''}
                                    required
                                    label={"P.Iva"}
                                    onChange={changePIva}
                                    sx={{ width: '100%' }}
                                    helperText={pIvaMessageError}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled={!isCanEdit || !isCFEditable}
                                    error={!CFValid ? true : false}
                                    inputProps={{ maxLength: 16 }}
                                    defaultValue={CF ?? ''}
                                    value={CF ?? ''}
                                    label={"C.F."}
                                    onChange={changeCF}
                                    sx={{ width: '100%' }}
                                    helperText={CFMessageError}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled={!isCanEdit}
                                    // error={codAteco === "" && !codAteco}
                                    defaultValue={codAteco ?? ''}
                                    value={codAteco ?? ''}
                                    label={"Codice Ateco"}
                                    onChange={changeCodAteco}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>
                                        Natura giuridica</InputLabel>
                                    <Select
                                        label='Natura giuridica '
                                        value={idNaturaGiuridica ?? -1}
                                        onChange={changeIdNaturaGiuridica}
                                        disabled={!isCanEdit}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                            ...
                                        </MenuItem>
                                        {natureGiuridiche.map((naturaGiuridica, index) => (
                                            <MenuItem
                                                key={naturaGiuridica?.id}
                                                value={naturaGiuridica?.id}
                                            >
                                                {naturaGiuridica?.descrizione}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>
                                        Tipologia stakeholder</InputLabel>
                                    <Select
                                        label='Tipologia stakeholder '
                                        value={idTipologiaStakeholder ?? -1}
                                        onChange={changeIdTipologiaStakeholder}
                                        disabled={!isCanEdit}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                            ...
                                        </MenuItem>
                                        {tipologieStakeholders.map((tipo, index) => (
                                            <MenuItem
                                                key={tipo?.id}
                                                value={tipo?.id}
                                            >
                                                {tipo?.descrizione}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>
                                        Settore</InputLabel>
                                    <Select
                                        label='Settore '
                                        value={idSettore ?? -1}
                                        onChange={changeIdSettore}
                                        disabled={!isCanEdit}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                            ...
                                        </MenuItem>
                                        {settori.map((settore, index) => (
                                            <MenuItem
                                                key={settore?.id}
                                                value={settore?.id}
                                            >
                                                {settore?.descrizione}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, mt: 2, border: saveErrorRapLegale ? 'red 1px solid' : '' }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Rappresentante legale</Typography>
                            <Typography textAlign={'start'} variant="caption" sx={{ ml: 2, color: 'red' }}>{saveMsgErrorRapLegale}</Typography>
                        </Box>
                        <IconButton onClick={() => setRapLegaleFormCollapsed(prev => !prev)}>
                            {rapLegaleFormCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Box>
                    <Collapse in={rapLegaleFormCollapsed}>
                        <Grid container spacing={2} mt={2}>
                            <Grid xs={12} md={6}>
                                <TextField
                                    error={nomeRapLegale === "" && !nomeRapLegaleValid}
                                    disabled={!isCanEdit}
                                    defaultValue={nomeRapLegale ?? ''}
                                    value={nomeRapLegale ?? ''}
                                    required
                                    label={"Nome"}
                                    onChange={changeNomeRapLegale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    error={cognomeRapLegale === "" && !cognomeRapLegaleValid}
                                    disabled={!isCanEdit}
                                    defaultValue={cognomeRapLegale ?? ''}
                                    value={cognomeRapLegale ?? ''}
                                    required
                                    label={'Cognome'}
                                    onChange={changeCognomeRapLegale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>

                            <Grid xs={12} md={6}>
                                <TextField
                                    disabled={!isCanEdit}
                                    // error={!CFRapLegaleValid}
                                    defaultValue={CFRapLegale ?? ''}
                                    value={CFRapLegale ?? ''}
                                    // required
                                    label={"C.F."}
                                    onChange={changeCFRespLegale}
                                    sx={{ width: '100%' }}
                                    helperText={CFRapLegaleMessageError}
                                    inputProps={{ maxLength: 16 }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <CustomDatePicker
                                    year={true}
                                    maxDate={DateTime.now()}
                                    label={"Data di nascita"}
                                    isDisabled={!isCanEdit}
                                    selectedValue={dataNascitaRapLegale ?? null}
                                    onChangeValue={onChangeValueDataNascita} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    // error={luogoNascitaRapLegale === "" && !luogoNascitaRapLegaleValid}
                                    disabled={!isCanEdit}
                                    // required
                                    defaultValue={luogoNascitaRapLegale ?? ''}
                                    value={luogoNascitaRapLegale ?? ''}
                                    label={"Luogo di nascita"}
                                    onChange={changeLuogoNascitaRapLegale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, mt: 2, border: saveErrorSedeLegale ? 'red 1px solid' : '' }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Sede legale</Typography>
                            <Typography textAlign={'start'} variant="caption" sx={{ ml: 2, color: 'red' }}>{saveMsgErrorSedeLegale}</Typography>
                        </Box>
                        <IconButton onClick={() => setSedeLegaleFormCollapsed(prev => !prev)}>
                            {sedeLegaleFormCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Box>
                    <Collapse in={sedeLegaleFormCollapsed}>
                        <Grid container spacing={2} mt={2}>
                            <Grid xs={12} md={6}>
                                <TextField
                                    error={indirizzoSedeLegale === "" && !indirizzoSedeLegaleValid}
                                    defaultValue={indirizzoSedeLegale ?? ''}
                                    value={indirizzoSedeLegale ?? ''}
                                    required
                                    label={'Indirizzo'}
                                    onChange={changeIndirizzoSedeLegale}
                                    sx={{ width: '100%' }}
                                    disabled={!isCanEdit}
                                />
                            </Grid>
                            <Grid xs={12} md={2}>
                                <TextField
                                    error={(!capSedeLegaleValid && capSedeLegale === '') || !capSedeLegaleValid}
                                    defaultValue={capSedeLegale ?? ''}
                                    value={capSedeLegale ?? ''}
                                    required
                                    label={"Cap"}
                                    onChange={changeCapSedeLegale}
                                    sx={{ width: '100%' }}
                                    disabled={!isCanEdit}
                                    helperText={capSedeLegaleErrorMsg}
                                    inputProps={{ maxLength: 5 }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    error={cittaSedeLegale === "" && !cittaSedeLegaleValid}
                                    defaultValue={cittaSedeLegale ?? ''}
                                    value={cittaSedeLegale ?? ''}
                                    required
                                    label={"Città"}
                                    onChange={changeCittaSedeLegale}
                                    sx={{ width: '100%' }}
                                    disabled={!isCanEdit}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    defaultValue={mailSedeLegale ?? ''}
                                    value={mailSedeLegale ?? ''}
                                    label={"Mail"}
                                    onChange={changeMailSedeLegale}
                                    sx={{ width: '100%' }}
                                    disabled={!isCanEdit}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    defaultValue={pecSedeLegale ?? ''}
                                    value={pecSedeLegale ?? ''}
                                    label={"Pec"}
                                    onChange={changePecSedeLegale}
                                    sx={{ width: '100%' }}
                                    disabled={!isCanEdit}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled={!isCanEdit}
                                    defaultValue={sitoSedeLegale ?? ''}
                                    value={sitoSedeLegale ?? ''}
                                    label={"Sito web"}
                                    onChange={changeSitoSedeLegale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled={!isCanEdit}
                                    defaultValue={telefonoSedeLegale ?? ''}
                                    value={telefonoSedeLegale ?? ''}
                                    label={"Telefono"}
                                    onChange={changeTelefonoSedeLegale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled={!isCanEdit}
                                    defaultValue={faxSedeLegale ?? ''}
                                    value={faxSedeLegale ?? ''}
                                    label={"Fax"}
                                    onChange={changeFaxSedeLegale}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
            </Box>
            <Divider />

            <Box sx={{ mt: 2, display: "flex", justifyContent: "end", alignItems: "center", px: 2 }}>
                {/* {validateFormData() ? (
                    <Alert severity="error">Alcune informazioni obbligatorie non sono state inserite.</Alert>
                ) : (
                    <span></span>
                )} */}
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: 'unset' } }}>
                    <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }} onClick={closeDialogAggiungiAzienda} variant="outlined" fullWidth color={'error'} >Chiudi procedura di {dataExist ? 'modifica' : 'inserimento'}</Button>
                    {isCanEdit ? (
                        <Button endIcon={<ArrowForward />}
                            disabled={validateFormData() || isConfermaLoading}
                            sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                            onClick={() => setConfirmAlertIsOpen(true)}
                            variant="contained" fullWidth color={'success'} >Conferma e prosegui</Button>
                    )
                        :
                        (
                            <Button endIcon={<ArrowForward />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                onClick={proseguiSenzaConferma}
                                variant="contained" fullWidth color={'success'} >Prosegui</Button>
                        )
                    }
                </Box>
            </Box>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={dataExist ? 'Conferma modifica azienda' : 'Conferma aggiunta azienda'}
                icon={dataExist ? <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" /> : <Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={dataExist ? `Confermi di voler modificare i dati dell'azienda? Una volta confermato, non potrai annullare l'operazione.` : `Confermi di voler aggiungere l'azienda? `}
                showAlunni={false}
                confermaButton={dataExist ? 'Conferma modifica' : 'Conferma'}
                severity={dataExist ? 'warning' : 'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={salvataggioAzienda}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}