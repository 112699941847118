import * as React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Divider, Paper, Button, FormControl, TextField, Box, Collapse, Container, Select, Typography, Dialog, DialogContent, DialogTitle, IconButton, CircularProgress } from '@mui/material';
import DettaglioAlunno from '../dettaglio-utente/dettaglioAlunno';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Clear, Close } from '@mui/icons-material';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import 'react-virtualized/styles.css';
import TreeViewRicerca from './treeViewRicerca';

import { getAnnoFormativo } from '../shared/utilityMethods';

import { RicercaAPI } from '../../api/ricercaApi';
import { DateTime } from 'luxon';
import CryptoJS from 'crypto-js';
import Breadcrumb from '../registri/shared/breadcrumb';

export default function RicercaStudente(props) {

  const { t, i18n } = useTranslation();

  const inputNomeAlunnoRef = useRef(null);
  const inputCfRef = useRef(null);

  const [isBesPage, setIsBesPage] = useState(false);

  const [isDialog, setIsDialog] = useState(false);


  const [sediList, setSediList] = useState([]);
  const [classiList, setClassiList] = useState([]);
  const [sedeSelected, setSedeSelected] = useState(-1);
  const [classeSelected, setClasseSelected] = useState(-1);


  const [idCorsoDettaglioAlunno, setIdCorsoDettaglioAlunno] = useState(-1);

  const [risultatiRicerca, setRisultatiRicerca] = useState([]);

  const [nomeAlunno, setNomeAlunno] = useState('');
  const [nomeValido, setNomeValido] = useState(true);
  const [nomeMessage, setNomeMessage] = useState('')
  const [cfAlunno, setCfAlunno] = useState('');
  const [cfValido, setCfValido] = useState(true);
  const [cfMessage, setCfMessage] = useState('')

  // const [selection, setSelection] = useState([]);


  const [searchStarted, setSearchStarted] = useState(false);
  // const [searchLoaded, setSearchLoaded] = useState(false);

  //Modale alunno
  const [dialogDettaglioAlunnoOpen, setDialogDettaglioAlunnoOpen] = useState(false);
  const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(-1);
  const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");

  //Selezione
  const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // const [checkedNodes, setCheckedNodes] = useState(new Set());

  const [permessiUtente, setPermessiUtente] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [annoFormativo, setAnnoFormativo] = useState("");
  const [isFullWidth, setIsFullWidth] = useState(false);

  useEffect(() => {
    let annoFormativoTmp = getAnnoFormativo();
    setAnnoFormativo(annoFormativoTmp);
    getSedi(annoFormativoTmp);
    if (localStorage.getItem("permessi")) {
      let permessiTmp = localStorage.getItem("permessi");
      let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
      let permessiParsed = JSON.parse(decryptedPermessi);
      setPermessiUtente(permessiParsed);
    }

    //Gestione anno formativo selezionato
    // let annoFormativoTmp = ""; 
    // if (sessionStorage.getItem("annoFormativo")){
    //   annoFormativoTmp = sessionStorage.getItem("annoFormativo");
    // } else {
    //   annoFormativoTmp = process.env.REACT_APP_ANNO_SCOLASTICO;
    // }
    // setAnnoFormativo(annoFormativoTmp)
  }, [])

  useEffect(() => {
    if (props.isFullWidth !== undefined)
      setIsFullWidth(props.isFullWidth);
  }, [props.isFullWidth])

  useEffect(() => {
    if (props.isDialog !== undefined)
      setIsDialog(props.isDialog)
  }, [props.isDialog])

  useEffect(() => {
    if (props.isBes !== undefined)
      setIsBesPage(props.isBes)
  }, [props.isBes])

  useEffect(() => {
    if (props.isBesPage !== undefined)
      setIsBesPage(props.isBesPage)
  }, [props.isBesPage])

  useEffect(() => {
    if (props.isInsideOtherComponent !== undefined)
      setIsInsideOtherComponent(props.isInsideOtherComponent);
  }, [props.isInsideOtherComponent])

  useEffect(() => {
    if (props.showCheckbox !== undefined)
      setShowCheckbox(props.showCheckbox);
  }, [props.showCheckbox])

  function setCheckedNodesFunction(value) {
    // setCheckedNodes(value);
    checkedNodesLevelPadre(value);
  }

  function checkedNodesLevelPadre(value) {
    if (props.setCheckedNodes) props.setCheckedNodes(value)
  }


  function validate(e) {
    e.preventDefault()
    if (sedeSelected !== -1) {
      startSearch()
      getAlunni();
    }
  }

  // function addSelectedItem(idItem) {
  //   if (!selection.includes(idItem)) {
  //     setSelection([...selection, idItem])
  //   }
  //   else {
  //     let index = selection.indexOf(idItem);
  //     let newSet = selection;
  //     newSet.splice(index, 1);
  //     setSelection([...newSet]);
  //   }
  // }


  function nomeSet(e) {
    if (e.target.value.length === 0) {
      setNomeAlunno('');
      setNomeValido(true);
      setNomeMessage('');
    }
    else if (e.target.value.length > 2) {
      setNomeAlunno(e.target.value);
      setNomeMessage('');
      setNomeValido(true);
    }
    else {
      setNomeValido(false);
      setNomeMessage('Min. 3 caratteri.');
    }
  };

  function cfSet(e) {
    let newCf = e.target.value;
    newCf = newCf.toUpperCase();
    inputCfRef.current.value = newCf;
    if (e.target.value.length === 0) {
      setCfAlunno('');
      setCfValido(true);
      setCfMessage('');
    }
    else if (e.target.value.length === 16) {
      setCfAlunno(e.target.value);
      setCfMessage('');
      setCfValido(true);
    }
    else {
      setCfValido(false);
      setCfMessage('Codice fiscale incorretto.');
    }
  };

  function changeSede(e) {
    if (e.target.value !== '') {

      setSedeSelected(e.target.value);
      getClassiBySede(e.target.value, annoFormativo);
    }
    else {
      setSedeSelected(-1);
      setClasseSelected(-1);
    }
  };

  function changeClasse(e) {
    if (e.target.value !== '') {
      setClasseSelected(e.target.value);
    }
    else {
      setClasseSelected(-1);
    }
  };

  function checkValidateInput() {

    // if (sedeSelected !== -1 && (nomeAlunno === "" || (nomeValido && nomeAlunno.length > 2)) || (cfAlunno === "" ||(cfValido && cfAlunno.length === 16))) {
    if (sedeSelected !== -1 && ((nomeValido) && (cfValido))) {

      return false
    }
    else {
      return true
    }
  }

  function startSearch() {
    setSearchStarted(true)
  }

  function removeFilters() {
    setNomeAlunno("");
    setCfAlunno("");

    if (sediList.length === 1) 
      setSedeSelected(sediList[0].id);
    else {
      setSedeSelected(-1);
      setClassiList([])
    }
    setClasseSelected(-1);
   
    setNomeMessage("");
    setNomeValido(true);
    setCfValido(true);
    setCfMessage("");
    inputNomeAlunnoRef.current.value = "";
    inputCfRef.current.value = "";
    // setSelection([])

    setSearchStarted(false)
    // setSearchLoaded(false);
  }



  function checkedRow(idArray) {
    if (props.passIdDestinatariSelezionati)
      props.passIdDestinatariSelezionati(idArray);
  }



  function openDettaglioAlunno(idAlunno, nomeAlunno) {
    setIdAlunnoSelezionato(idAlunno);
    setNomeAlunnoSelezionato(nomeAlunno);
    setDialogDettaglioAlunnoOpen(true)

  }

  function closeDettaglioAlunno() {
    setDialogDettaglioAlunnoOpen(false)
  }


  async function getSedi(annoFormativo) {

    RicercaAPI.getSedi({ annoFormativo: annoFormativo })
      .then((result) => {
        setSediList(result.sedi)
        if (result.sedi.length === 1) {
          setSedeSelected(result.sedi[0].id);
          getClassiBySede(result.sedi[0].id, annoFormativo);
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }


      })

  }

  async function getClassiBySede(idSede, annoFormativo) {
    let dataObj = {
      idSede: idSede,
      annoFormativo: annoFormativo
    }

    RicercaAPI.getClassiBySede(dataObj)
      .then((result) => {
        setClassiList(result.sedi)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }
      })
  }

  async function getAlunni() {
    let dataObj = {
      cognome: nomeAlunno !== '' ? nomeAlunno : null,
      codiceFiscale: cfAlunno !== '' ? cfAlunno : null,
      idSede: sedeSelected !== -1 ? sedeSelected : null,
      idCorso: classeSelected !== -1 ? classeSelected : null,
      annoFormativo: annoFormativo
    }
    setHasLoaded(false);

    RicercaAPI.getAlunni(dataObj)
      .then((result) => {
        let risultati = [];
        for (let i = 0; i < result.sedi.length; i++) {
          let corsiTmp = [];

          for (let y = 0; y < result.sedi[i].corsi.length; y++) {
            let corsoTmp = {};
            corsoTmp.id = result.sedi[i].corsi[y].idCorso;
            corsoTmp.name = result.sedi[i].corsi[y].corso;

            let alunniTmp = [];
            for (let x = 0; x < result.sedi[i].corsi[y].alunni.length; x++) {
              let alunnoTmp = {};
              alunnoTmp.id = result.sedi[i].corsi[y].alunni[x].idAlunno;
              alunnoTmp.cf = result.sedi[i].corsi[y].alunni[x].codiceFiscale;
              alunnoTmp.name = result.sedi[i].corsi[y].alunni[x].cognomeAlunno + " " + result.sedi[i].corsi[y].alunni[x].nomeAlunno;
              alunnoTmp.type = "studente";
              alunniTmp.push(alunnoTmp)
            }
            corsoTmp.type = "classe";
            corsoTmp.codiceCorso = result.sedi[i].corsi[y].codiceCorso;
            corsoTmp.children = alunniTmp;
            corsiTmp.push(corsoTmp)
          }

          corsiTmp.sort((a, b) => a.codiceCorso - b.codiceCorso);

          risultati.push({
            id: sedeSelected,
            name: result.sedi[i].sede,
            type: "sede",
            children: corsiTmp
          })

        }
        setRisultatiRicerca([...risultati])
        setTimeout(() => {
          setHasLoaded(true);
        }, 200)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }
      })
  }

  function condizioneSede() {
    if (sedeSelected === -1 && nomeAlunno !== '' && nomeValido) return true
    else if (sedeSelected === -1 && cfAlunno !== '' && cfValido) return true
    else return false
  }


  return (
    <Fragment>

      {!isInsideOtherComponent && !isDialog ? (
        <Breadcrumb sections={["Ricerca alunni"]} />
      ) : null}

      <Container disableGutters={isInsideOtherComponent} maxWidth={isFullWidth ? "false" : "xl"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 5 }}>
          <form onSubmit={startSearch} style={{ width: '100%' }}>
            <Grid container spacing={2} sx={{ width: '100%', pt: 2, px: 3 }}>
              <Grid xs={12} md={3}>
                <TextField sx={{ width: '100%' }} data-testid='nomeRicercaInput' error={(nomeValido) ? false : true}
                  label="Cognome" onChange={nomeSet} helperText={nomeMessage} inputRef={inputNomeAlunnoRef} />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField sx={{ width: '100%' }} data-testid='cfRicercaInput' inputRef={inputCfRef}
                  error={(cfValido) ? false : true} label="C.F." length={16} inputProps={{ maxLength: 16 }} onChange={cfSet} helperText={cfMessage} />
              </Grid>
              <Grid xs={12} md={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="sede-label">
                    Sede
                  </InputLabel>
                  <Select disabled={sediList.length === 1 && sedeSelected !== -1} label="Sede" labelId="sede-label" value={sedeSelected} onChange={changeSede}  >
                    <MenuItem key={-1} value={-1} >
                    </MenuItem>
                    {sediList.map((sede) => (
                      <MenuItem key={sede.id} value={sede.id}  >
                        {sede.nomeSede}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="classe-label">
                    Corso
                  </InputLabel>
                  <Select disabled={sedeSelected !== -1 || classiList.length !== 0 ? false : true} label="Corso"
                    labelId="classe-label" value={classeSelected} onChange={changeClasse}  >
                    <MenuItem key={-1} value={-1}  >
                    </MenuItem>
                    {classiList.map((classe,index) => (
                        <MenuItem style={{ whiteSpace: 'normal' }} value={classe.id} sx={{ wordBreak: 'break-all' }}>
                          {classe?.codiceCorso ?? ""} - {classe?.nomeCorso ?? ""}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ width: '100%', py: 2, px: 3 }} justifyContent={"end"}>
              {condizioneSede() && (<Grid xs={12} md={3}>
                <Box textAlign={'center'} color={'red'}>
                  <Typography variant='caption' sx={{ textAlign: 'center' }}>Per avviare la ricerca è necessario selezionare la sede.</Typography>
                </Box>
              </Grid>)}
              {searchStarted && (
                <Grid xs={12} md={2}>
                  <Button onClick={removeFilters} variant="outlined" fullWidth endIcon={<Clear />} type='submit' data-testid='rimuoviFiltriBtn' color="error" >
                    Rimuovi filtri
                  </Button>
                </Grid>
              )}
              <Grid xs={12} md={2}>
                <Button onClick={validate} fullWidth endIcon={<SearchIcon />} disabled={checkValidateInput()} type='submit' data-testid='ricercaBtn' variant="contained" color="success">
                  Avvia ricerca
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
          <Paper sx={{ p: 2, overflowY: 'auto', height: { xs: '40vh', md: '50vh' } }}>
            {hasLoaded ? (
              risultatiRicerca.length > 0 ?
                (<TreeViewRicerca 
                isMultipleSedi={sediList.length !== 1} 
                setCheckedNodes={setCheckedNodesFunction} 
                openDettaglioAlunno={openDettaglioAlunno} 
                risultatiRicerca={risultatiRicerca} 
                showCheckbox={showCheckbox} 
                checkedRow={checkedRow} />)
                : (<Typography align='center'>Nessun dato disponibile.</Typography>)
            ) : (
              <Box display={"flex"} height={"100%"} width={"100%"} flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                <CircularProgress />
                <Typography sx={{ mt: 1 }}>Caricamento in corso...</Typography>
              </Box>
            )}
          </Paper>
        </Collapse>
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={dialogDettaglioAlunnoOpen}
        onClose={closeDettaglioAlunno}
        sx={{ mt: '8vh' }}
      >
        <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({nomeAlunnoSelezionato})</Typography>
          <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ pt: 0 }}>
          <DettaglioAlunno idCorso={idCorsoDettaglioAlunno} canTouchAssenze={!isBesPage ? true : false} comesFromTracciato={isBesPage} dataAttuale={DateTime.local()} showBes={isBesPage} tabIndex={isBesPage || isDialog ? 4 : 0} nomeAlunno={nomeAlunnoSelezionato} id={idAlunnoSelezionato} permessiUtente={permessiUtente} tipo={true} />
        </DialogContent>

      </Dialog>

      {!isInsideOtherComponent ? (
        <Helmet>
          <title>{t("name")} - {t("pages.ricercaAlunni.title")}</title>
        </Helmet>
      ) : null}

    </Fragment >
  );
}