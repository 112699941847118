import { Navigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

export default function Protected({ 
    permessoNecessario, children }) {

    let isLoggedIn = false;
    if (localStorage.getItem("log"))
     isLoggedIn = Boolean(CryptoJS.AES.decrypt(localStorage.getItem("log"), process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8));
    let permessi = [];
    if (localStorage.getItem("permessi"))
    permessi = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("permessi"), process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8));

    return isLoggedIn ? (
            permessoNecessario === undefined || 
            permessi.some(permesso => permesso.nome === permessoNecessario && permesso.value === true) ? (
                children
            ) : (
                <Navigate to="/home" replace />
            )
        ) : <Navigate to="/login" replace />

}