import { Fragment, useEffect, useState } from "react";
import { Button, FormControl, TextField, Container, Select, Snackbar, Alert, Box, Typography } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { itIT } from "@mui/x-date-pickers/locales";

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { RegistroAPI } from "../../../api/registroApi";
import { EventiAPI } from "../../../api/eventiApi";
import { convertDataGetProssimeLezione, convertISODate } from "../../shared/metodiDateUtility";
import { UtilityAPI } from "../../../api/utilityApi";
import DialogConferma from "../../shared/dialogConferma";
import { Delete, Edit } from "@mui/icons-material";

export default function EventoVotoRegistro(props) {

    const [comunicazione, setComunicazione] = useState(null);

    const [data, setData] = useState(null);
    const [exAnte, setExAnte] = useState(false);
    const [voto, setVoto] = useState('');
    const [tipologiaVoto, setTipologiaVoto] = useState('');
    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [idVoto, setIdVoto] = useState(-1);
    const [valutazione, setValutazione] = useState('');
    const [valutazioneValida, setValutazioneValida] = useState(true);
    const [valutazioneMessaggio, setValutazioneMessaggio] = useState('');
    const [oraSelected, setOraSelected] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);

    const [isVoto, setIsVoto] = useState(null);

    const [alunno, setAlunno] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertMsg, setAlertMsg] = useState("");

    const [permessiUtente, setPermessiUtente] = useState([]);
    const [utenteCanEdit, setUtenteCanEdit] = useState(false);

    const [modificaMode, setModificaMode] = useState(false);
    const [typeVoto, setTypeVoto] = useState(-1);


    const [cantModify, setCantModify] = useState(false);

    const [showPulsanteEdit, setShowPulsanteEdit] = useState(false);
    const [showPulsanteDelete, setShowPulsanteDelete] = useState(false);
    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] = useState(false);
    const [confirmEditDialogIsOpen, setConfirmEditDialogIsOpen] = useState(false);

    const [canChangeColorVoto, setCanChangeColorVoto] = useState(false);


    const [elencoMaterieCorsoPeriodoDocente, setElencoMaterieCorsoPeriodoDocente] = useState([])
    const [materiaAlternativa, setMateriaAlternativa] = useState(null)
    const [idMateriaAlternativa, setIdMateriaAlternativa] = useState(-1)
    const [idMateriaPreSelezionata, setIdMateriaPreSelezionata] = useState(-1)

    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Non è possibile continuare con il salvataggio. C'è un errore.")

    useEffect(() => {
        if (sessionStorage.getItem("matDoc")) {
            let matDocTmp = sessionStorage.getItem("matDoc");
            setIdMateriaPreSelezionata(parseInt(matDocTmp));
        }
    }, [])

    useEffect(() => {
        if (props.cantModify !== undefined)
            setCantModify(props.cantModify)
    }, [props.cantModify])

    useEffect(() => {
        if (props.canChangeColorVoto !== undefined)
            setCanChangeColorVoto(props.canChangeColorVoto)
    }, [props.canChangeColorVoto])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
            if (props.permessiUtente.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) {
                setUtenteCanEdit(true)
            } else setUtenteCanEdit(false)
        }
    }, [props.permessiUtente])


    useEffect(() => {
        let cantModify = false;
        if (props.comunicazione !== undefined && props.cantModify !== undefined && props.permessiUtente !== undefined) {
            cantModify = props.cantModify
            resetModifiche(cantModify, props.permessiUtente);
        }
        else if (props.comunicazione !== undefined && props.permessiUtente !== undefined) {
            resetModifiche(cantModify, props.permessiUtente);

        }

    }, [props.comunicazione, props.cantModify, props.permessiUtente]);

    useEffect(() => {
        if (props.alunno !== undefined) {
            setAlunno(props?.alunno)
        }
    }, [props.alunno])

    function changeVoto(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            // newStr = newValue.replace(/(\d)[a-zA-Z]/g, '$1');
            // newStr = newValue.replace(/[-/]/g, "");
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr === 0) newStr = 'N.V.';
        } else {
            newStr = ""; //resetta a ''
        }

        setVoto(newStr);
    }

    function changeData(value) {
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria);
    }

    function annullaModifiche() {
        setModificaMode(false);
        resetModifiche(cantModify, permessiUtente);
    }

    function closeAlert() {
        setAlertOpen(false);
    }

    function resetModifiche(cantModify, permessiUtenteTmp) {
        if (props?.comunicazione !== undefined) {
            setShowErrorMsg(false); //Nel dubbio nascondo i messaggi di errore
            setErrorMsg("")

            setComunicazione(props?.comunicazione)
            //2022-10-02T22:00:00.000Z - FORMATO ISO
            let newDataTmp = DateTime.fromISO(props?.comunicazione?.contenuto?.dataLezione);
            setIdVoto(props?.comunicazione?.id)
            setOraSelected(props?.comunicazione?.contenuto?.idOrario)
            setData(newDataTmp);
            if (props.comunicazione.contenuto.tipologia !== undefined) {
                if (props.comunicazione.contenuto.tipologia == "Scritta")
                    setTypeVoto(0);
                if (props.comunicazione.contenuto.tipologia == "Orale")
                    setTypeVoto(1);
                if (props.comunicazione.contenuto.tipologia == "Pratica")
                    setTypeVoto(2);
            }

            setExAnte(props?.comunicazione?.contenuto?.is_ex_ante)
            setVoto(props?.comunicazione?.contenuto?.voto ?? '');
            setIsVoto(props?.comunicazione?.contenuto?.voto === undefined ? false : true)
            setValutazione(props?.comunicazione?.contenuto?.tipologia ?? -1);
            if (props?.comunicazione?.contenuto?.voto !== undefined) setTipologiaVoto(props?.comunicazione?.contenuto?.tipologia)
            // setMaterie([...materie, props.comunicazione.materia])
            // let typeValutazione = props?.comunicazione?.tipologia_comunicazioni === 4 ? 0 : 1;
            setIdMateria(props?.comunicazione?.contenuto?.materia?.idMateria);
            setIdCorso(props?.comunicazione?.albero_destinatari?.idCorso[0]);
            setIdDocente(props?.comunicazione?.contenuto?.docente?.idDocente);
            canAccessButtons(props.comunicazione, permessiUtenteTmp)
            if (sessionStorage.getItem("matDoc")) {
                let idCorsoTmp = props?.comunicazione?.albero_destinatari?.idCorso[0];
                let idMateria = props?.comunicazione?.contenuto?.materia?.idMateria;
                let matDocTmp = parseInt(sessionStorage.getItem("matDoc"));
                if (matDocTmp === 101) getElencoMaterieCorsoPeriodoDocente(idCorsoTmp, newDataTmp, idMateria);
            }

            if (!cantModify)
                getOreLezione(props?.comunicazione?.contenuto?.dataLezione, props?.comunicazione?.albero_destinatari?.idCorso[0], props?.comunicazione?.contenuto?.docente?.idDocente, props?.comunicazione?.contenuto?.materia?.idMateria, props?.comunicazione?.contenuto?.idOrario)
            else
                getDettaglioOrarioRiepilogoFormativo(props?.comunicazione?.contenuto?.idOrario)
        }
    }

    function changeOra(e, o) {
        setOraSelected(e.target.value);
        //setIdMateria(o.props.idmateria);
    }

    function changeTypeVoto(e) {
        setTypeVoto(e.target.value)
    }

    function changeValutazione(e) {
        let newValue = e.target.value;
        const regExValutazioneNegativo = idMateria === 97 ? /[^rRiIpP]/g : /[^cCmMdDrRpP]/g;
        let newStr = newValue.replace(regExValutazioneNegativo, '');
        const regExValutazione = idMateria === 97 ? /[rRiIpP]/g : /[cCmMdDrRpP]/g;
        // const regExValutazione = /[cCmMpPrRdD]/g;

        if (regExValutazione.test(newValue) === false) {
            setValutazione(newStr.toUpperCase());
            setValutazioneValida(false);
            setValutazioneMessaggio(idMateria === 97 ? 'Valori ammissibili: R/I/P' : 'Valori ammissibili: C/M/P/R/D');
        } else {
            setValutazione(newStr.toUpperCase());
            setValutazioneValida(true);
            setValutazioneMessaggio('');
        }
    }

    async function getElencoMaterieCorsoPeriodoDocente(idCorso, data, idMateria) {
        let obj = {
            "idCorso": idCorso,
            "data": data
        }
        RegistroAPI.getElencoMaterieCorsoPeriodoDocente(obj)
            .then((result) => {

                setElencoMaterieCorsoPeriodoDocente([...result]);
                let materiaTmp = result.find((materia) => materia.idMateria === idMateria);
                setMateriaAlternativa(materiaTmp);
                if (idMateria !== 101) setIdMateriaAlternativa(idMateria);
                else {
                    setIdMateriaAlternativa(-1);
                    setErrorMsg("Non è possibile lasciare il campo 'materia' vuoto")
                    setShowErrorMsg(true);
                }

                // if (result.length === 1) {
                //     setMateriaAlternativa(result[0]);
                //     setIdMateriaAlternativa(result[0].idMateria)
                // }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) props.logout();
                }
                // console.error(error)
            })
    }

    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, idOrario) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)

        let idMateriaPreSelezionataTmp = idMateria
        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            // idMateria: idMateriaPreSelezionataTmp === 101 ? idMateriaPreSelezionataTmp : idMateria
        }

        if (sessionStorage.getItem("matDoc")) {
            idMateriaPreSelezionataTmp = parseInt(sessionStorage.getItem("matDoc"));
            dataObj.idMateria = idMateriaPreSelezionataTmp === 101 ? idMateriaPreSelezionataTmp : idMateria
        } else {
            dataObj.idMateria = idMateria
        }

        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                setElencoOre([...prossimeOreTmp]);
                if (prossimeOreTmp.length > 0) {
                    if (prossimeOreTmp.some(ora => ora.idOrario === idOrario)) {
                        setIdMateria(prossimeOreTmp[0].materia.idMateria)
                        setOraSelected(idOrario);
                    }
                    else {
                        setOraSelected(-1);
                        // setIdMateria(-1);
                    }
                }
                else {
                    setOraSelected(-1);
                    // setIdMateria(-1);
                }
            })
            .catch((error) => {
                // console.error(error)
            })
    }

    async function getDettaglioOrarioRiepilogoFormativo(idOrario) {
        let dataObj = {
            idOrario: idOrario,
        }

        UtilityAPI.dettaglioIdOrario(dataObj)
            .then((result) => {
                setElencoOre([result]);

            })
            .catch((error) => {
                // console.error(error)
            })
    }

    async function modificaVoto() {
        let tipologiaTmp = "Scritta";
        if (props.comunicazione.contenuto.tipologia !== undefined) {
            if (typeVoto === 0)
                tipologiaTmp = "Scritta";
            if (typeVoto === 1)
                tipologiaTmp = "Orale";
            if (typeVoto === 2)
                tipologiaTmp = "Pratica";
        }


        const obj = {
            voto: voto === 'N.V.' ? '0' : voto,
            note: '',
            idAlunno: [alunno.id],
            idOrario: oraSelected,
            is_ex_ante: exAnte,
            tipologia: tipologiaTmp,
        }


        if (sessionStorage.getItem("matDoc")) {
            let materiaAlternativaTmp = {
                "idMateria": materiaAlternativa?.idMateria,
                "nomeBreve": materiaAlternativa?.nomeBreve,
                "nomeMateria": materiaAlternativa?.nomeMateria
            }
            let idMateriaPreSelezionataTmp = parseInt(sessionStorage.getItem("matDoc"));
            if (idMateriaPreSelezionataTmp === 101) obj.materiaAlternativa = materiaAlternativaTmp;
        }


        // if (idMateriaPreSelezionata === 101) obj.materiaAlternativa = materiaAlternativaTmp;

        EventiAPI.modificaVoto(obj, idVoto)
            .then((result) => {
                aggiornaRegistro("Voto modificato", "success")
                if (props.closeDialog) props.closeDialog()
            })
            .catch((error) => {
                let errorMsg = "";

                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = "Al momento non è possibile completare l'operazione";
                }

                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }

    async function modificaValutazione() {

        const obj = {
            tipologia: valutazione,
            grado: '',
            idAlunno: [alunno.id],
            idOrario: oraSelected,
        }

        if (sessionStorage.getItem("matDoc")) {
            let materiaAlternativaTmp = {
                "idMateria": materiaAlternativa?.idMateria,
                "nomeBreve": materiaAlternativa?.nomeBreve,
                "nomeMateria": materiaAlternativa?.nomeMateria
            }
            let idMateriaPreSelezionataTmp = parseInt(sessionStorage.getItem("matDoc"));
            if (idMateriaPreSelezionataTmp === 101) obj.materiaAlternativa = materiaAlternativaTmp;
        }

        EventiAPI.modificaValutazione(obj, idVoto)
            .then((result) => {
                aggiornaRegistro("Valutazione modificata", "success")
                if (props.closeDialog) props.closeDialog()
            })
            .catch((error) => {
                let errorMsg = "";

                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = "Al momento non è possibile completare l'operazione";
                }

                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }

    function closeAlert() {
        setAlertOpen(false)
    }

    function aggiornaRegistro(alertMsg, alertSeverity) {
        if (props.aggiornaRegistro) props.aggiornaRegistro(alertMsg, alertSeverity)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }

    async function canAccessButtons(objEvento, permessiUtenteTmp) {
        let idEventoTmp = objEvento?.id;
        let isEditable = objEvento?.contenuto?.editable;

        let dataObj = {
            idEvento: idEventoTmp
        }
        if (idEventoTmp !== -1 && isEditable && permessiUtenteTmp.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) {
            RegistroAPI.showPulsantiEvento(dataObj)
                .then((result) => {

                    setShowPulsanteEdit(result?.showPulsanteEdit ?? false)
                    setShowPulsanteDelete(result?.showPulsanteDelete ?? false)
                })
                .catch(function (error) { })
        }
    }

    async function deleteEvento() {
        let dataObj = {
            idEvento: comunicazione?.id,
            tipo: comunicazione?.tipologia_comunicazioni
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.eventoAggiunto) {
                    let msgSuccess = isVoto ? 'Voto eliminato' : 'Valutazione eliminata';
                    props.eventoAggiunto(msgSuccess, "success");
                }

            })
            .catch(function (error) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto(error.response.data.data[0].msg, "error");
            })
    }

    function changeIdMateriaAlternativa(e) {
        let idMateriaTmp = e.target.value;
        setIdMateriaAlternativa(idMateriaTmp)
        let materiaTmp = elencoMaterieCorsoPeriodoDocente.find((materia) => materia.idMateria === idMateriaTmp);
        setMateriaAlternativa(materiaTmp);

        if (e.target.value !== -1) {
            setShowErrorMsg(false);
            setErrorMsg("")
        } else {
            setShowErrorMsg(true);
            setErrorMsg("Non è possibile lasciare il campo 'materia' vuoto")
        }
    }

    function isButtonSalvaDisabled() {
        if (voto === '' && (valutazione === -1 || valutazione === '' || valutazione.length > 2) || (isVoto && typeVoto === -1)
            || (oraSelected === -1 || elencoOre.length === 0) || (idMateriaPreSelezionata === 101 && materiaAlternativa === null) || (idMateriaPreSelezionata === 101 && idMateriaAlternativa === -1)
        )
            return true
        else return false
    }

    return (

        <Fragment>
            <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Snackbar onClose={closeAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen}>
                    <Alert sx={{ width: '100%', mb: 2 }} severity={alertSeverity} >
                        {alertMsg}
                    </Alert>
                </Snackbar>

                <form style={{ width: '100%' }}>
                    <Grid container sx={{ width: '100%', pt: 2 }}>
                        {(!modificaMode && comunicazione?.created_at !== undefined) && (<Grid xs={12} md={12} mb={2}>
                            <Typography><b>Data di inserimento:</b>  {convertISODate(comunicazione?.created_at)} {'(Inserito da: ' + comunicazione?.created_by_obj?.nome + ')'};</Typography>
                        </Grid>)}
                        {(!modificaMode && comunicazione?.modified_at !== null && comunicazione?.modified_at !== undefined) && (<Grid xs={12} md={12} mb={2}>
                            <Typography><b>Data di modifica:</b>  {convertISODate(comunicazione?.modified_at)}  {'(Modificato da: ' + comunicazione?.modified_by_obj?.nome + ')'};</Typography>
                        </Grid>)}
                        <Grid xs={12} md={12} mb={2}>
                            <TextField sx={{ width: '100%' }} data-testid='dataValutazioneInput'
                                label="Alunno" disabled value={alunno?.nome + ' ' + alunno?.cognome}
                            />
                        </Grid>
                        <Grid container xs={12} md={12} spacing={2} >
                            <Grid xs={12} md={6}>
                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                    <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                        disabled
                                        label="Data"
                                        maxDate={DateTime.now()}
                                        value={data}
                                        format="dd/MM/yyyy"
                                        onChange={(value) => changeData(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid xs={12} md={6} mb={2}>
                                {elencoOre.length > 0 ? (
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="materia-label">
                                            Seleziona ora</InputLabel>
                                        <Select
                                            data-testid='selMateriaNotaSelect'
                                            label='Seleziona ora '
                                            labelId="materia-label"
                                            value={oraSelected}
                                            onChange={changeOra}
                                            disabled
                                        >
                                            {elencoOre.map((ora) => (
                                                <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                    {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta, la data selezionata non verrà salvata</Typography>) : null}
                                    </FormControl>
                                ) : (
                                    <Select disabled fullWidth >
                                        <MenuItem
                                            key={-1}
                                            value={undefined}
                                        >
                                            Caricamento...
                                        </MenuItem>
                                    </Select>
                                )}

                            </Grid >
                        </Grid>
                        <Grid container xs={12} md={12} spacing={2}>
                            {isVoto && (<Grid xs={12} md={6}>
                                {/* <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                    label="Tipologia voto"
                                    onChange={changeVoto}
                                    value={tipologiaVoto}
                                /> */}
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="typeVoto-label">
                                        {/* {classeName === '' ? t("pages.ricercaAlunni.classe") : t("pages.ricercaAlunni.classeS")} */}
                                        Tipologia di prova
                                    </InputLabel>
                                    <Select
                                        disabled={!modificaMode}
                                        data-testid='selTypeVotoSelect'
                                        labelId="typeVoto-label"
                                        label='Tipologia di prova'
                                        value={typeVoto}
                                        onChange={changeTypeVoto}
                                    >
                                        <MenuItem key={-1} value={-1} >

                                        </MenuItem>
                                        <MenuItem key={0} value={0} >
                                            Scritta
                                        </MenuItem>
                                        <MenuItem key={1} value={1} >
                                            Orale
                                        </MenuItem>
                                        <MenuItem key={2} value={2} >
                                            Pratica
                                        </MenuItem>


                                    </Select>
                                </FormControl>
                            </Grid>)}
                            < Grid xs={12} md={6}  >
                                {isVoto ? (
                                    <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                        label="Voto"
                                        disabled={!modificaMode}
                                        onChange={changeVoto}
                                        value={voto === '0' ? 'N.V.' : voto}
                                        inputProps={{ maxLength: 4 }}
                                    // helperText={nomeMessage} 
                                    />
                                ) : (
                                    <TextField sx={{ width: '100%' }} data-testid='valutazioneInput'
                                        label="Valutazione"
                                        inputProps={{ maxLength: 1 }}
                                        onChange={changeValutazione}
                                        value={valutazione}
                                        helperText={valutazioneMessaggio}
                                        error={!valutazioneValida}
                                        disabled={!modificaMode}
                                    />

                                )}
                            </Grid >
                            {idMateriaPreSelezionata === 101 && (<Grid xs={12} md={isVoto ? 12 : 6} sx={{ width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="selMateria-label">
                                        Materia </InputLabel>
                                    <Select
                                        data-testid='selTypeValutazioneSelect'
                                        labelId="selMateria-label"
                                        label='Materia  '
                                        error={modificaMode && idMateriaAlternativa === -1}
                                        value={idMateriaAlternativa}
                                        onChange={changeIdMateriaAlternativa}
                                        disabled={(elencoMaterieCorsoPeriodoDocente.length < 2 && idMateriaAlternativa === -1 && !modificaMode) || !modificaMode}
                                    >
                                        <MenuItem key={-1} value={-1} >

                                        </MenuItem>
                                        {elencoMaterieCorsoPeriodoDocente.map((materia, index) => (
                                            <MenuItem key={materia?.idMateria} value={materia?.idMateria}>
                                                {materia?.nomeMateria}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>)}
                        </Grid>
                        {/* {isVoto && (<Grid xs={12} md={6}>
                            <FormGroup >
                                <FormControlLabel disabled={!modificaMode} control={
                                    <Checkbox onChange={changeExAnte} data-testid='exAnteValutazioneCheckBox' checked={exAnte} />
                                } label="Ex ante/accertamento prerequisiti" />
                            </FormGroup>
                        </Grid>)
                        } */}
                    </Grid >

                    {(showErrorMsg && modificaMode) && (
                        <Alert sx={{ my: 2 }} severity="error">{errorMsg}</Alert>
                    )}
                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"} sx={{ mt: 3, width: '100%' }}>

                        <Box>
                            {showPulsanteDelete && !modificaMode ? (
                                <Button onClick={() => { setConfirmDeleteDialogIsOpen(true) }} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Elimina {isVoto ? 'voto' : 'valutazione'}</Button>
                            ) : null}
                        </Box>
                        <Box>
                            {showPulsanteEdit ? (
                                <Box>
                                    {!modificaMode ? (
                                        <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">
                                            chiudi dettaglio
                                        </Button>
                                    ) : (
                                        <Button onClick={() => annullaModifiche()} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">
                                            Annulla modifiche
                                        </Button>
                                    )}


                                    {!modificaMode ? (
                                        <Button onClick={() => setModificaMode(true)} disabled={((voto === '' && valutazione === -1) || valutazione === '')} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                            variant="contained" >
                                            Modifica {isVoto ? 'voto' : 'valutazione'}
                                        </Button>
                                    ) : (
                                        <Button
                                            // onClick={isVoto ? modificaVoto : modificaValutazione} 
                                            onClick={() => { setConfirmEditDialogIsOpen(true) }}
                                            disabled={isButtonSalvaDisabled()}
                                            sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} color="success" variant="contained" data-testid='modificaValutazioneBtn' >
                                            Conferma Modifica
                                        </Button>
                                    )}
                                </Box>
                            ) : (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">chiudi dettaglio</Button>
                            )}
                        </Box>
                    </Box>
                </form >
            </Container >

            <DialogConferma
                isDialogOpen={confirmDeleteDialogIsOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare ${isVoto ? "il voto?" : "la valutazione?"} Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfirmDeleteDialogIsOpen(false) }}
                conferma={deleteEvento}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confirmEditDialogIsOpen}
                title={'Conferma modifica'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare ${isVoto ? "il voto?" : "la valutazione?"} Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={() => { setConfirmEditDialogIsOpen(false) }}
                conferma={isVoto ? modificaVoto : modificaValutazione}
                disabledButton={false}
            />

        </Fragment >


        // <Box display={"flex"} alignItems={"center"}>
        //     <TextField disabled={!modificaVoto} type="number" onChange={changeVoto} value={voto} InputProps={{
        //         endAdornment: <InputAdornment position="start">/ 60</InputAdornment>,
        //     }} />
        //     {votoModificabile ? (
        //         <Fragment>
        //             {modificaVoto ? (
        //                 <Button sx={{ ml: 2 }} startIcon={<Edit />} color="success" variant="contained">Conferma modifiche</Button>
        //             ) : (
        //                 <Button sx={{ ml: 2 }} onClick={modificaVotoMetodo} startIcon={<Edit />} variant="contained">Modifica voto</Button>
        //             )}
        //         </Fragment>
        //     ) : null}
        // </Box>
    )
}