import { useState, useEffect, Fragment } from "react"
import { Box, Button, CircularProgress, Typography, Snackbar, Alert, useTheme, Chip } from '@mui/material';
import { convertDataDiNascita } from "../../shared/metodiDateUtility";
import { Download, Warning } from "@mui/icons-material";
import axios from "axios";
import CryptoJS from 'crypto-js';

export default function ElencoDocumentiScrutinio(props) {

    const [listDocumenti, setListDocumenti] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [canDownload, setCanDownload] = useState(false);


    const [fileDownloading, setFileDownloading] = useState("-1");

    const [alertMsg, setAlertMsg] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        if (props.listDocumenti !== undefined) {
            setListDocumenti([...props.listDocumenti]);
            setTimeout(() => {
                setHasLoaded(true);
            }, 500)
        }
    }, [props.listDocumenti])

    async function downloadDocumento(idDocumento) {
        setCanDownload(false);
        let encryptedToken = localStorage.getItem('tkn');
        let decryptedToken = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        setFileDownloading(idDocumento);
        axios.get(process.env.REACT_APP_FETCH_URL + "/test/scaricaDocumentoScrutinio/" + idDocumento, {
            responseType: 'arraybuffer',
            headers: { Authorization: `Bearer ${decryptedToken}` }
        })
            .then((response) => {
                let contentDisposition = response.headers['content-disposition'];
                let filename = "";
                if (contentDisposition) {
                    setAlertMsg("Download in corso");
                    setAlertSeverity("info");
                    setAlertOpen(true);
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                setCanDownload(true)
                exportFile(response.data, filename, 'application/pdf');//;charset=utf-8;
            })
            .catch((error) => {
                setAlertMsg("Errore durante il download del documento");
                setAlertSeverity("error");
                setAlertOpen(true);
                setTimeout(() => {
                    setFileDownloading("-1");
                }, 500)

            });
    }

    const exportFile = (data, fileName, type) => {

        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setTimeout(() => {
            setFileDownloading("-1");
        }, 500)
    };

    function closeDialog() {
        props.closeDialog();
    }

    return (
        <Fragment>
            {hasLoaded ? (
                listDocumenti.length > 0 ? (
                    <Fragment>
                        {listDocumenti.map((scrutinio, index) => (
                            <Fragment key={index}>
                                <Typography sx={{ mt: 2 }} variant="body1">Scrutinio del <b> {convertDataDiNascita(scrutinio?.dataScrutinio)}</b></Typography>
                                {scrutinio?.documenti.length > 0 ? (
                                    <Box sx={{ display: "flex", ml: 3 }}>
                                        <Box sx={{ borderRight: '1px dotted #282828' }}> </Box>
                                        <Box>
                                            {scrutinio?.documenti.map((documento) => (
                                                <Box display={"flex"} alignItems={"center"} sx={{ mt: 1.5 }}>
                                                    <Box sx={{ width: '20px', borderBottom: '1px dotted #282828' }}> </Box>
                                                    <Chip disabled={!documento?.disponibile} onClick={() => (downloadDocumento(documento?.idDocumento))} sx={{ p: 1, ml: 1, cursor: 'pointer' }} color="primary" variant="outlined" label={documento?.nomeVisualizzato} icon={<Download />} />
                                                    {!documento?.disponibile && (
                                                        //  <Chip color="error"sx={{p:1,ml:1}}  variant="outlined" label={"  Documento non ancora generato"} icon={<Warning />} />

                                                        <Typography sx={{ ml: 2, display: "flex", alignItems: 'center' }} variant="subtitle2" color="error">
                                                            <Warning sx={{ mr: 1 }} />
                                                            Documento non ancora generato
                                                        </Typography>
                                                    )}
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>) : (
                                    <Box sx={{ display: "flex", ml: 3, mt: 1 }}>
                                        <Box sx={{ borderRight: '1px dotted #282828' }}> </Box>
                                        <Typography variant="subtitle2" sx={{ p: 1, ml: 1 }}>Nessun documento generato per lo scrutinio</Typography>
                                    </Box>
                                )}
                            </Fragment>
                        ))}
                    </Fragment>
                ) : (
                    <Typography>Nessun documento trovato per il corso selezionato.</Typography>
                )
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"30vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento documenti...</Typography>
                </Box>
            )}
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={alertOpen} autoHideDuration={1500} onClose={() => { setAlertOpen(false); }} >
                <Alert onClose={() => { setAlertOpen(false); }} severity={alertSeverity} sx={{ width: "100%" }} >
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}