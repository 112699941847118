import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, Divider, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button } from '@mui/material';
import { Close} from "@mui/icons-material";
import RowPresenzaTutor from "./rowPresenzaTutor";
import DettaglioVisita from "../presenze-tutor-tirocinio/dettaglioVisita";
// import DettaglioVisita from "./dettaglioVisita";

export default function TableValidazionePresenzeTutorTirocinio(props) {

    const [listVisite, setListVisite] = useState([]);

    const [isDettaglioVisitaOpen, setIsDettaglioVisitaOpen] = useState(false);
    const [idVisitaSelezionata, setIdVisitaSelezionata] = useState(-1);

    const [indexAllValidate, setIndexAllValidate] = useState(0);

    useEffect(() => {
        if (props.listVisite)
            setListVisite([...props.listVisite]);
    }, [props.listVisite])

    useEffect(() => { 
        if(props.indexAllValidate)
            setIndexAllValidate(props.indexAllValidate)
    }, [props.indexAllValidate])


    function openDettaglioAlunno(alunno, showBes) {
        if (props.openDettaglioAlunno)
            props.openDettaglioAlunno(alunno, showBes)
    }

    function viewDettaglioVisita(presenza) {
        setIsDettaglioVisitaOpen(true);
        setIdVisitaSelezionata(presenza.idPresenza);
    }

    function modificaValidazioneAlunni(visita, indexVisita) {
        props.modificaValidazioneAlunni(visita, indexVisita);
    }

    return (
        <Fragment>
            <TableContainer sx={{ width: '100%', maxHeight: {xs:'100%', md:'65vh'} }} component={Paper}>
                <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell align="center"
                                className="row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Data
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Ora inizio
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Ora fine
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Alunno
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Stato presenza alunno
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Validata
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="colonna-nomi row-head" sx={{ minWidth: '100px', p: 0, zIndex: 13 }}>
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '70px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <Typography
                                        variant={"caption"}
                                        sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Annullata
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {listVisite.map((visita, index) => (
                            <RowPresenzaTutor indexAllValidate={indexAllValidate} indexVisita={index} visita={visita} openDettaglioAlunno={openDettaglioAlunno} viewDettaglioVisita={viewDettaglioVisita} modificaValidazioneAlunni={modificaValidazioneAlunni} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioVisitaOpen} onClose={() => setIsDettaglioVisitaOpen(false)}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio visita</Typography>
                    <IconButton onClick={() => setIsDettaglioVisitaOpen(false)} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioVisita idPresenza={idVisitaSelezionata} /> 
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button variant="contained" fullWidth color="primary" onClick={() => setIsDettaglioVisitaOpen(false)}>Chiudi dettaglio</Button>
                </DialogActions>
            </Dialog>

        </Fragment>
    )
}

