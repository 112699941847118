import { Button, Container, TextField, Box, Typography, Divider, Paper } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Logo from "../../img/logo.png";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function FormAssistenza() {

    const location = useLocation();
    const navigate = useNavigate();

    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [email, setEmail] = useState("");
    const [oggetto, setOggetto] = useState("");
    const [descrizione, setDescrizione] = useState("");
    const [dati, setDati] = useState("");
    const [isButtonDisabledSubmit, setIsButtonDisabledSubmit] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        let previousPageUrlTmp = location.state?.from;

        let isLoggedTmp = localStorage.getItem('log') || null;
        let decryptIsLogged = isLoggedTmp !== null ? CryptoJS.AES.decrypt(isLoggedTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8) : null;
        setIsLogged(Boolean(decryptIsLogged))
        if (decryptIsLogged === 'true') {
            let nomeTmp = localStorage.getItem("usr");
            let decryptedNome = CryptoJS.AES.decrypt(nomeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let annoFormativo = sessionStorage.getItem("annoFormativo");
            /* eslint-disable no-restricted-globals */
            var larghezzaSchermo = screen.width;
            var altezzaSchermo = screen.height;
            /* eslint-enable no-restricted-globals */
            setDati(`nomeUtente:${decryptedNome},annoFormativo:${annoFormativo},url:${previousPageUrlTmp},risoluzione:${larghezzaSchermo}x${altezzaSchermo}`)
        } else {
            setDati('Utente non loggato')
        }
    }, [location])

    function changeNome(e) { setNome(e.target.value) }
    function changeCognome(e) { setCognome(e.target.value) }
    function changeEmail(e) {
        const value = e.target.value;
        setEmail(value);
        // Verifica se l'email è valida
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        setErrorEmail(!isValidEmail);
    }
    function changeOggetto(e) { setOggetto(e.target.value) }
    function changeDescrizione(e) { setDescrizione(e.target.value) }


    function isButtonDisabled() {
        if (
            nome === '' ||
            cognome === '' ||
            email === '' ||
            errorEmail ||
            oggetto === '' ||
            descrizione === ''
        ) return true;
        else return false;
    }

    function changeIsButtonDisabledSubmit(event) {
        // event.preventDefault();
        setIsButtonDisabledSubmit(true);
    }

    function backToHome() {
        navigate('/home')
    }

    return (
        <Box sx={{ backgroundColor: !isLogged ? '#03539c' : 'transparent' }}>
            <Container fixed sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: !isLogged ? '100vh' : '80vh' }}>
                <Paper elevation={!isLogged ? 4 : 0} component="form" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', padding: !isLogged ? 4 : 0 }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                        {!isLogged && (<Box textAlign={'center'}><img src={Logo} alt="CIOFS logo" height="60" /></Box>)}
                        <Typography variant="h4" sx={{ mb: 3 }}>Invio richiesta assistenza</Typography>
                    </Box>
                    <form
                        name="zsWebToCase_23449000000531009"
                        id="zsWebToCase_23449000000531009"
                        action="https://desk.zoho.eu/support/WebToCase"
                        method="POST"
                        enctype="multipart/form-data"
                        onSubmit={(e) => changeIsButtonDisabledSubmit(e)}
                    >
                        <input
                            type="hidden"
                            name="xnQsjsdp"
                            value="edbsn487d4feca5d2db266bcc9cd7f673dddd"
                        />
                        <input
                            type="hidden"
                            name="xmIwtLD"
                            value="edbsnf97452c3f08ea9006f0e365a32497895bc12dabba9568de126f61689633d0dd8"
                        />
                        <input type="hidden" name="xJdfEaS" value="" />
                        <input type="hidden" name="actionType" value="Q2FzZXM=" />
                        <input
                            type="hidden"
                            value='&#x7b;"JSON_VALUES"&#x3a;&#x7b;&#x7d;,"JSON_SELECT_VALUES"&#x3a;&#x7b;&#x7d;,"JSON_MAP_DEP_LABELS"&#x3a;&#x5b;&#x5d;&#x7d;'
                        />
                        <input
                            type="hidden"
                            name="returnURL"
                            value="https&#x3a;&#x2f;&#x2f;dock-reg.ciofslazio.it&#x2f;"
                        />
                        <Box sx={{ overflowY: 'scroll', overflowX: "hidden", height: '55vh', paddingY: 1 }}>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={6}>
                                    <TextField name="First Name" fullWidth type="text" variant='outlined' defaultValue="" value={nome} label="Nome" onChange={changeNome} inputProps={{ maxLength: 120 }} />
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <TextField name="Contact Name" fullWidth type="text" variant='outlined' defaultValue="" value={cognome} label="Cognome" onChange={changeCognome} inputProps={{ maxLength: 120 }} />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField name="Email" fullWidth type="email" variant='outlined' defaultValue="" value={email} label="Email" onChange={changeEmail} helperText={errorEmail ? "Inserisci un'email valida" : ''} error={errorEmail} inputProps={{ maxLength: 120 }} />
                                </Grid>
                                <Grid xs={12}>
                                    <Divider fullWidth sx={{ my: 1 }} />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField name="Subject" fullWidth type="text" variant='outlined' defaultValue="" value={oggetto} label="Oggetto" onChange={changeOggetto} inputProps={{ maxLength: 255 }} />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField minRows={4} name="Description" fullWidth multiline maxRows={20} type="text" variant='outlined' defaultValue="" value={descrizione} label="Descrizione" onChange={changeDescrizione} inputProps={{ maxLength: 3000 }} />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField name="Dati" fullWidth type="text" variant='outlined' value={dati} label="Dati" sx={{ display: 'none' }} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={2} display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'right'} alignItems={'center'}>
                            <Button sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'auto' } }} onClick={backToHome} startIcon={<ArrowBackIcon />} data-testid='backHomeBtn' variant="outlined">
                                torna alla home
                            </Button>
                            <Button sx={{ width: { xs: '100%', md: 'auto' } }} disabled={isButtonDisabled() || isButtonDisabledSubmit} type="submit" variant="contained" color="success">Invia richiesta assistenza</Button>
                        </Box>
                    </form>
                </Paper>
            </Container >
        </Box>
    )
}