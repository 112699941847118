import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  it: {
    translation: {
      name: 'Ciofs',
      menu: {
        logout: 'Logout',
        close: 'Chiudi menu',
      },
      pages: {
        login: {
          title: 'Login',
          log: 'Accedi',
          forgotpassword: 'Hai dimenticato la password?',
          errorLogin: 'Email o Password errati',
          errorEmail: 'Email errata',
          blank: 'Campo vuoto',
        },
        home: {
          title: 'Home',
          link: 'Collegamenti rapidi',
        },
        ricercaAlunni: {
          title: 'Ricerca alunni',
          name: 'Nome',
          cf: 'C.F',
          sede: 'Seleziona sede',
          sedeS: 'Sede',
          classe: 'Seleziona classe',
          classeS: 'Classe',
          ricercaBtn: 'Avvia ricerca',
          rimuoviFiltriBtn: 'Rimuovi filtri',
          dettaglio: 'Dettaglio alunno',
        },
        registroClasse: {
          title: 'Registro di classe',
        },
        registroDocente: {
          title: 'Registro del docente',
        },
        elencoComunicazioni: {
          title: 'Elenco comunicazioni',
        },
        newComunicazioni: {
          title: 'Nuova comunicazione',
        },
        modaleDettAlunno: {
          anagrafica: 'Anagrafica',
          assenze: 'Assenze',
          duale: 'Duale',
          voti: 'Voti e valutazioni',
          bes: 'Bes',
        },
        anagrafica: {
          title: 'Anagrafica',
          dati: 'DATI ALUNNO',
          note: 'Elenco note disciplinari',
          nome: 'Nome',
          cf: 'C.F.',
          dataNascita: 'Data di nascita',
          luogoNascita: 'Luogo di nascita',
          maggiorenne: 'Maggiorenne',
          classe: 'Classe',
          tutori: 'DATI TUTORI',
          ritardo_uscita: 'INSERISCI RITARDO O USCITA anticipata',
          ritardoBtn: 'Aggiungi ritardo',
          uscitaBtn: 'Aggiungi uscita anticipata',
        },
        assenze: {
          title: 'Assenze',
          assenzaGiustificata: 'Assenze giustificate',
          assenzaDaGiustificare: 'Assenze da giustificare',
          assenzaDal: 'Assente',
        },
        bes: {
          title: 'Bes',
        },
        ritardo_uscita: {
          title: 'Presenze parziali',
        },
        duale: {
          title: 'Duale',
        },
        voti: {
          title: 'Voti e valutazioni',
        },
        comunicazioni: {
          invia: 'Assegna Compiti',
          comDaLeggere: 'Comunicazioni da leggere',
          comInviate: 'Comunicazioni inviate',
          oggetto: 'Oggetto comunicazione - ',
          leggiBtn: 'Leggi comunicazione',
          detBtn: 'Apri dettaglio',
          destinatari: 'Destinatari'
        },
        addCompiti: {
          title: 'Assegna Compiti',
        },
        addNota: {
          title: 'Aggiunta Nota',
        },
        addProgramma: {
          title: 'Aggiungi programma lezione',
        },
        addVerifica: {
          title: 'Assegna compiti',
        },
        addVoti: {
          title: 'Aggiungi voto/valutazione',
        },
        errorPage: {
          title: '404',
          pageMessage: "Sembra ci sia stato un errore.",
          backToHome: "Torna alla home"
        },
      },
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "it", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;