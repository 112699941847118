import { Fragment, useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, CircularProgress, Dialog, DialogContent, Typography, Alert, Snackbar } from '@mui/material';
import { PresenzeAPI } from "../../api/presenzeApi";
import { convertDataGetProssimeLezione, getDayAndMonth } from "../shared/metodiDateUtility";
import { useTranslation } from 'react-i18next';
import DialogFirma from "./dialogFirma";

export default function DettaglioMonitoraggioFirme(props) {

    const [idCorso, setIdCorso] = useState(.1);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [listDocentiOre, setListDocentiOre] = useState([]);
    const [nomeCorso, setNomeCorso] = useState("");

    const [dataRichiesta, setDataRichiesta] = useState(null);

    const [dataRichiestaConverted, setDataRichiestaConverted] = useState("");
    const [objOraSelezionata, setObjOraSelezionata] = useState(null);

    const [isDialogFirmaOpen, setIsDialogFirmaOpen] = useState(false);

    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [canFirmareDettaglio, setCanFirmareDettaglio] = useState(false);

    useEffect(() => {
        if (props.dataRichiesta !== undefined && props.idCorso !== undefined && props.nomeCorso !== undefined) {
            setNomeCorso(props.nomeCorso);
            getDettaglioFirme(props.dataRichiesta, props.idCorso);
            setIdCorso(props.idCorso)
            let dataRichiestaFormatted = getDayAndMonth(props.dataRichiesta);
            setDataRichiestaConverted(dataRichiestaFormatted)
            setDataRichiesta(props.dataRichiesta)
            setCanFirmareDettaglio(props.canFirmareDettaglio);
        }
    }, [props.dataRichiesta, props.idCorso, props.nomeCorso, props.canFirmareDettaglio])


    function openDialogFirma(objOra) {
        setObjOraSelezionata(objOra)
        let isOpenTmp = !isDialogFirmaOpen;
        setIsDialogFirmaOpen(isOpenTmp);

        if (!isOpenTmp)
            getDettaglioFirme(dataRichiesta, idCorso);
    }



    function oraFirmataFunction() {
        setIsDialogFirmaOpen(false);
        getDettaglioFirme(dataRichiesta, idCorso);
        setAlertOpen(true);
        setAlertMsg("Ora firmata correttamente")
        setAlertSeverity("success");
    }

    function chiudiDettaglio() {
        if (props.closeDettaglioMonitoraggio)
            props.closeDettaglioMonitoraggio();
    }

    async function getDettaglioFirme(dataRichiesta, idCorso) {

        let dataRichiestaConverted = convertDataGetProssimeLezione(dataRichiesta, true)

        let dataObj = {
            "dataRichiesta": dataRichiestaConverted,
            "idCorso": idCorso,
        }
        setHasLoaded(false);
        PresenzeAPI.getDettaglioMonitoraggioFirme(dataObj)
            .then((result) => {
                setHasLoaded(true);
                let listOreTmp = result.ore;
                let listDocentiTmp = result.docenti;
                let listDocentiOreTmp = [];

                for (let ora of listOreTmp) {
                    let indexDocente = listDocentiTmp.findIndex(docente => docente.idDocente === ora.idDocente);
                    if (indexDocente !== -1) {
                        let newObjDocente = {
                            idDocente: listDocentiTmp[indexDocente]?.idDocente ?? "",
                            nomeDocente: listDocentiTmp[indexDocente]?.nomeDocente ?? "",
                            cognomeDocente: listDocentiTmp[indexDocente]?.cognomeDocente ?? "",
                            hasLezione: listDocentiTmp[indexDocente]?.hasLezione ?? false,
                            oraFirmata: ora?.firmata ?? false,
                            ora: ora?.ora ?? 0,
                            idOrario: ora?.idOrario ?? -1,
                            nomeMateria: ora?.nomeMateria,
                            idMateria: ora?.idMateria,
                            is_supplenza: ora?.is_supplenza ?? false
                        }
                        listDocentiOreTmp.push(newObjDocente);
                    }
                }

                setListDocentiOre([...listDocentiOreTmp])
            })
            .catch(function (error) {
                setHasLoaded(true);
                setListDocentiOre([]);
                let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante la ricerca delle ore"
                setErrorMsg(errorMsg)
                setHasError(true)
            })
    }





    return (
        <Fragment>
            {hasLoaded ? (
                !hasError ? (
                    <Fragment>
                        {/* <Typography variant="h6">Dettaglio del giorno <b>{dataRichiestaConverted} </b></Typography> */}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='row-head' style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                        <Typography color={'primary'} fontWeight={'bold'}>{nomeCorso}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <Typography textAlign={"center"}>Ora lezione</Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <Typography textAlign={"center"}>Ora firmata</Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listDocentiOre.map((docente) => (
                                    <TableRow>
                                        <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Box sx={{ backgroundColor: 'white', p: 2 }}>
                                                <Typography >{docente?.nomeDocente + " " + docente?.cognomeDocente}</Typography>
                                                <Typography variant="subtitle2" color="secondary">
                                                    {docente?.nomeMateria ?? ""}
                                                    {(docente?.is_supplenza !== undefined && docente?.is_supplenza) && (
                                                        <b> - SUPPLENZA</b>
                                                    )}
                                                </Typography>
                                             
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Typography textAlign={"center"}>{docente?.ora}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Typography sx={{ color: docente?.oraFirmata ? 'black' : 'red' }} textAlign={"center"}>{docente?.oraFirmata ? "Sì" : "No"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {(!docente?.oraFirmata && canFirmareDettaglio) && (
                                                <Button variant="contained" onClick={() => openDialogFirma(docente)}>
                                                    Firma ora
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Button sx={{ mt: 2 }} variant="contained" fullWidth color="primary" onClick={chiudiDettaglio} >Chiudi finestra</Button>

                    </Fragment>
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="h6" textAlign={"center"} color="error">{errorMsg}</Typography>
                        <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={chiudiDettaglio}>Chiudi finestra</Button>
                    </Box>
                )
            ) : (
                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                    <Typography sx={{ mt: 3, width: 'fit-content' }}>Caricamento in corso...</Typography>
                </Box>
            )}

            <Dialog open={isDialogFirmaOpen}>
                <DialogContent>
                    <DialogFirma oraFirmata={oraFirmataFunction} nomeCorso={nomeCorso} dataRichiesta={dataRichiesta}
                        idCorso={idCorso} objOraSelezionata={objOraSelezionata} isMonitoraggio={true} closeDialog={openDialogFirma} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}