import { Alert, Autocomplete, Box, Button, Divider, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, ArrowForward, Check } from "@mui/icons-material";
import { getAnnoFormativo } from "../../../shared/utilityMethods";

export default function AnagraficaAltriDati(props) {

    // eslint-disable-next-line no-unused-vars
    const [annoFormativo, setAnnoFormativo] = useState('');
    const [idProgetto, setIdProgetto] = useState(-1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [listTutorSede, setListTutorSede] = useState([]);
    const [listTutorAzienda, setListTutorAzienda] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [idAzienda, setIdAzienda] = useState(-1);
    // eslint-disable-next-line no-unused-vars
    const [idSede, setIdSede] = useState(-1);
    const [tutorFormativi, setTutorFormativi] = useState([]);
    const [tutorFormativiValid, setTutorFormativiValid] = useState(true);
    const [tutorAziendali, setTutorAziendali] = useState([]);
    const [tutorAziendaliValid, setTutorAziendaliValid] = useState(true);
    const [posizioneINAIL, setPosizioneINAIL] = useState('');
    const [polizzaAssicurativa, setPolizzaAssicurativa] = useState('');
    const [posizioneRCD, setPosizioneRCD] = useState('');

    const [posizioneINAILValid, setPosizioneINAILValid] = useState(true);
    const [polizzaAssicurativaValid, setPolizzaAssicurativaValid] = useState(true);
    const [posizioneRCDValid, setPosizioneRCDValid] = useState(true);

    //Fissi


    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);

    useEffect(() => {
        if (props.isVisualizza !== undefined) {
            setIsVisualizza(props.isVisualizza)
        }
    }, [props.isVisualizza])


    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            setIdProgetto(props.idProgettoFormativo);
            if (props.idProgettoFormativo !== -1) getAltriDati(props.idProgettoFormativo);
        }
    }, [props.idProgettoFormativo])


    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        setIsConfermaLoading(false);
    }, []);


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function validateFormData() {
        if (
            posizioneINAIL !== ''
            &&
            posizioneRCD !== ''
            &&
            polizzaAssicurativa !== ''
            &&
            tutorAziendali.length > 0
            &&
            tutorFormativi.length > 0
        ) return false
        else return true
    }

    function confermaProsegui() {
        if (props.nextTab) props.nextTab()
    }

    function changePosizioneINAIL(e) {
        let valueTmp = e.target.value;
        setPosizioneINAIL(valueTmp)
        if (valueTmp !== '') {
            setPosizioneINAILValid(true)
        } else {
            setPosizioneINAILValid(false)
        }
    }

    function changePolizzaAssicurativa(e) {
        let valueTmp = e.target.value;
        setPolizzaAssicurativa(valueTmp)
        if (valueTmp !== '') {
            setPolizzaAssicurativaValid(true)
        } else {
            setPolizzaAssicurativaValid(false)
        }
    }

    function changePosizioneRCD(e) {
        let valueTmp = e.target.value;
        setPosizioneRCD(valueTmp)
        if (valueTmp !== '') {
            setPosizioneRCDValid(true)
        } else {
            setPosizioneRCDValid(false)
        }
    }

    function previousTab() {
        if (props.previousTab) {
            props.previousTab();
        }
    }

    function changeTutorAliendali(e, value) {
        //TODO: soluzione alternativa
        let listTutorAziendaliTmp = []
        for (let item of value) {
            let isPresent = listTutorAziendaliTmp.some((i) => i.id === item.id)
            if (!isPresent) listTutorAziendaliTmp.push(item);
            else listTutorAziendaliTmp = listTutorAziendaliTmp.filter((i) => i.id !== item.id);
        }
        if (listTutorAziendaliTmp.length !== 0) setTutorAziendaliValid(true)
        else setTutorAziendaliValid(false)
        setTutorAziendali(...[listTutorAziendaliTmp]);

        // if (value.length !== 0) setTutorAziendaliValid(true)
        // else setTutorAziendaliValid(false)
        // setTutorAziendali(...[value]);
    }

    function changeTutorFormativi(e, value) {
        //TODO: soluzione alternativa
        let listTutorFormativiTmp = []
        for (let item of value) {
            let isPresent = listTutorFormativiTmp.some((i) => i.idFormatore === item.idFormatore);
            if (!isPresent) listTutorFormativiTmp.push(item);
            else listTutorFormativiTmp = listTutorFormativiTmp.filter((i) => i.idFormatore !== item.idFormatore);
        }
        if (listTutorFormativiTmp.length !== 0) setTutorFormativiValid(true)
        else setTutorFormativiValid(false)
        setTutorFormativi(...[listTutorFormativiTmp]);

        // if (value.length !== 0) setTutorFormativiValid(true)
        //     else setTutorFormativiValid(false)
        //     setTutorFormativi(...[value]);
    }

    async function getAltriDati(idProgetto) {
        let obj = {
            idProgetto: idProgetto
        }
        TirociniAPI.getAltriDati(obj)
            .then((result) => {
                let objProgetto = result;
                setIsCanEdit(objProgetto?.isEditable ?? true);
                setIdAzienda(objProgetto?.idAzienda ?? -1);
                if (objProgetto?.idAzienda !== undefined && objProgetto?.idAzienda !== null) ricercaPersoneAzienda(objProgetto?.idAzienda)
                setIdSede(objProgetto?.idSede ?? -1);
                if (objProgetto?.idSede !== undefined && objProgetto?.idSede !== null) elencoTutorSede(objProgetto?.idSede);
                setTutorFormativi(objProgetto?.dati?.tutorFormativo ?? []);
                setTutorAziendali(objProgetto?.dati?.tutorAziendali ?? []);
                setPosizioneINAIL(objProgetto?.dati?.posizioneINAIL ?? '');
                setPolizzaAssicurativa(objProgetto?.dati?.polizzaAssicurativa ?? '');
                setPosizioneRCD(objProgetto?.dati?.posizioneRCD ?? '');
            })
            .catch(function (error) {
            })

    }

    async function elencoTutorSede(idSede) {
        let obj = {
            idSede: idSede
        }
        TirociniAPI.elencoTutorSede(obj)
            .then((result) => {
                let ListTutorSedeTmp = result;
                setListTutorSede([...ListTutorSedeTmp])
            })
            .catch(function (error) {
            })

    }

    async function ricercaPersoneAzienda(idAzienda) {
        let obj = {
            idAzienda: idAzienda,
            idRuolo: 1
        }
        TirociniAPI.ricercaPersoneAzienda(obj)
            .then((result) => {
                let ListTutorAziendaTmp = result;
                setListTutorAzienda([...ListTutorAziendaTmp])
            })
            .catch(function (error) {
            })

    }

    async function saveAltriDati() {
        setButtonConfermaDisabled(true);
        let obj = {
            "idProgetto": idProgetto,
            "dati": {
                "tutorFormativo": tutorFormativi,
                "tutorAziendali": tutorAziendali,
                "posizioneINAIL": posizioneINAIL,
                "polizzaAssicurativa": polizzaAssicurativa,
                "posizioneRCD": posizioneRCD
            },
        }
        TirociniAPI.saveAltriDati(obj)
            .then((result) => {
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                confermaProsegui();
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Paper elevation={1} sx={{ p: 2, mt: isVisualizza ? 2 : 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Altri dati</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={2}>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={posizioneINAIL ?? ''}
                                value={posizioneINAIL ?? ''}
                                label={"INAIL posizione n° "}
                                required
                                disabled={!isCanEdit}
                                onChange={changePosizioneINAIL}
                                sx={{ width: '100%' }}
                                error={(posizioneINAIL === null || posizioneINAIL === '') && !posizioneINAILValid}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={posizioneRCD ?? ''}
                                value={posizioneRCD ?? ''}
                                label={"R.C.D. posizione n° "}
                                required
                                disabled={!isCanEdit}
                                onChange={changePosizioneRCD}
                                sx={{ width: '100%' }}
                                error={(posizioneRCD === null || posizioneRCD === '') && !posizioneRCDValid}

                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={polizzaAssicurativa ?? ''}
                                value={polizzaAssicurativa ?? ''}
                                label={"Polizza Assicurativa n°"}
                                required
                                disabled={!isCanEdit}
                                onChange={changePolizzaAssicurativa}
                                sx={{ width: '100%' }}
                                error={(polizzaAssicurativa === null || polizzaAssicurativa === '') && !polizzaAssicurativaValid}
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Autocomplete
                                multiple
                                onChange={changeTutorFormativi}
                                options={[...listTutorSede]}
                                getOptionLabel={(option) => option.cognome.toUpperCase() + ' ' + option.nome.toUpperCase()}
                                // getOptionSelected={(option) => tutorFormativi.some((tutor) => tutor === option)}
                                defaultValue={tutorFormativi}
                                value={tutorFormativi}
                                disabled={!isCanEdit || listTutorSede.length === 0}
                                renderInput={(params) => (
                                    <TextField
                                        error={tutorFormativi.length === 0 && !tutorFormativiValid}
                                        disabled={!isCanEdit}
                                        {...params}
                                        label="Tutor formativi *"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Autocomplete
                                multiple
                                onChange={changeTutorAliendali}
                                options={[...listTutorAzienda]}
                                getOptionLabel={(option) => option.cognome.toUpperCase() + ' ' + option.nome.toUpperCase()}
                                defaultValue={tutorAziendali}
                                value={tutorAziendali}
                                disabled={!isCanEdit || listTutorAzienda.length === 0}
                                renderInput={(params) => (
                                    <TextField
                                        error={tutorAziendali.length === 0 && !tutorAziendaliValid}
                                        disabled={!isCanEdit}
                                        {...params}
                                        label="Tutor aziendali *"
                                    />
                                )}
                            />
                        </Grid>
                        {listTutorAzienda.length === 0 && (
                            <Grid xs={12}>
                                <Alert severity="warning">Non è possibile procedere con il salvataggio in quanto non è stato assegnato nessun tutor aziendale.</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Box>
            <Divider />
            <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                    onClick={closeDialog}
                    variant="outlined" fullWidth color={'error'} >{isCanEdit ? 'Chiudi procedura di inserimento' : 'Torna a gestione progetto formativo'}</Button>
                <Button startIcon={<ArrowBack />}
                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                    onClick={previousTab}
                    variant="contained" fullWidth color={'secondary'} > torna indietro</Button>
                <Button endIcon={<ArrowForward />}
                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                    onClick={() => isCanEdit ? setConfirmAlertIsOpen(true) : confermaProsegui()}
                    disabled={validateFormData()}
                    variant="contained" fullWidth color={'success'} >{isCanEdit ? 'Conferma e prosegui' : 'Prosegui'}</Button>
            </Box>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma salvataggio'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler salvare i dati del tirocinante? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveAltriDati}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}