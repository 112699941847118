import { useState, useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
export default function TestEvento(props) {

    const [idEvento, setIdEvento] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (props.idEvento !== undefined) {
            setIdEvento(props.idEvento);
            //getEventoByID(props.idEvento);
            //setIsLoaded(true)
        }
    }, [props.idEvento])

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} minHeight={"20vh"}>
            {!isLoaded && (
                <CircularProgress />
            )}
        </Box>
    )
}