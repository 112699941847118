import { Fragment, useEffect, useState, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, Paper, Breadcrumbs, Link, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Select, MenuItem, Typography, Divider, FormControl, InputLabel, OutlinedInput, InputAdornment, DialogActions, Snackbar, Alert } from '@mui/material';
import { PresenzeAPI } from "../../api/presenzeApi";
import { RicercaAPI } from "../../api/ricercaApi";
import { convertDataGetProssimeLezione, convertISODate, convertToItalianTime, convertToItalianDateTime, getDayAndMonth, getDayOfTheWeek, convertDataDiNascita } from "../shared/metodiDateUtility";
import { DateTime } from "luxon";
import { ArrowBack, Close, Edit, EditCalendar, Help, ZoomIn, DeleteOutline } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import RangeDate from "../shared/rangeDate";
import DettaglioMonitoraggioFirme from "./dettaglioMonitoraggioFirme";
import Breadcrumb from "../registri/shared/breadcrumb";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../shared/utilityMethods";

export default function SintesiMonitoraggioFirme(props) {

    const { t } = useTranslation();
    const tableRef = useRef();


    const [idSede, setIdSede] = useState(-1);
    const [nomeSedeSelezionata, setNomeSedeSelezionata] = useState("");
    const [hasListSediLoaded, setHasListSediLoaded] = useState(false);
    const [listSedi, setListSedi] = useState([]);
    const [isDialogSediOpen, setIsDialogSediOpen] = useState(false);
    const [changePeriodoDiInteresseOpen, setChangePeriodoDiInteresseOpen] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState("20232024")
    const [dataRichiestaDa, setDataRichiestaDa] = useState(null)
    const [dataRichiestaA, setDataRichiestaA] = useState(null)
    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");


    const [hasLoaded, setHasLoaded] = useState(false);
    const [listCorsi, setListCorsi] = useState([]);
    const [arrayGiorni, setArrayGiorni] = useState([]);

    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [dataRichiestaDettaglio, setDataRichiestaDettaglio] = useState("");
    const [nomeCorsoDettaglio, setNomeCorsoDettaglio] = useState("");

    const [isDialogLegendaOpen, setIsDialogLegendaOpen] = useState(false);
    const [isDialogDettaglioOpen, setIsDialogDettaglioOpen] = useState(false)
    const [canFirmareDettaglio, setCanFirmareDettaglio] = useState(false);

    const [isDialogFirmeDuplicateOpen, setIsDialogFirmeDuplicateOpen] = useState(false);
    const [giorniOreDuplicate, setGiorniOreDuplicate] = useState([]);
    const [isConfermaDeleteFirma, setIsConfermaDeleteFirma] = useState(false);
    const [docenteOraSel, setDocenteOraSel] = useState(null);
    const [giornoSel, setGiornoSel] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');
    const [countOreDuplicate, setCountOreDuplicate] = useState(0);

    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();

        let startOfWeek = objDateInteresse?.inizioPeriodo//.startOf('week');
        let endOfWeek = objDateInteresse?.inizioPeriodo.endOf('week');

        if (objDateInteresse?.isAnnoFormativoCorrente) {
            startOfWeek = DateTime.local().startOf('week');
            endOfWeek = DateTime.local().endOf('week');
        }

        let startOfWeekConverted = convertDataGetProssimeLezione(startOfWeek, true);
        let endOfWeekConverted = convertDataGetProssimeLezione(endOfWeek, true);
        let formattedDateInizio = convertISODate(startOfWeekConverted)
        let formattedDateFine = convertISODate(endOfWeekConverted)

        setFormattedPeriodoDiInteresseInizio(formattedDateInizio);
        setFormattedPeriodoDiInteresseFine(formattedDateFine);

        setDataRichiestaDa(startOfWeekConverted);
        setDataRichiestaA(endOfWeekConverted);
        setDataRichiestaDa(startOfWeekConverted);
        setDataRichiestaA(endOfWeekConverted);

        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(startOfWeekConverted, endOfWeekConverted, annoFormativoTmp);
    }, [])

    function changeSede(e) {
        setIdSede(e.target.value);
    }

    function confirmSede() {
        setIsDialogSediOpen(false);
        getSintesiFirme(idSede, annoFormativo);
    }

    function openDialogSelezioneSede() { }
    function openPeriodoDiInteresseDialog() {
        setChangePeriodoDiInteresseOpen(true)
    }
    function openDialogLegenda() {
        setIsDialogLegendaOpen(prev => !prev);
    }
    function openDialogFirmeDuplicate(open) {
        if (open) getOreDuplicate()
        setIsDialogFirmeDuplicateOpen(prev => !prev);
    }

    async function getOreDuplicate() {
        let dataObj = {
            annoFormativo: annoFormativo
        }
        PresenzeAPI.getOreDuplicate(dataObj)
            .then((result) => {
                let giorniTmp = result.giorni;
                setGiorniOreDuplicate([...giorniTmp])
            })
            .catch(function (error) {
            })
    }

    function selectDocenteOraSel(giorno, docenteOraSel) {
        setIsConfermaDeleteFirma(true);
        setGiornoSel(giorno);
        setDocenteOraSel(docenteOraSel);
    }

    function resetDatiConfermaEliminaFirma() {
        setIsConfermaDeleteFirma(false);
        setGiornoSel(null);
        setDocenteOraSel(null);
    }


    async function deleteFirma(idFirma) {

        let obj = {
            "idFirma": idFirma
        }

        PresenzeAPI.deleteFirma(obj)
            .then((result) => {
                openDialogFirmeDuplicate(false);
                resetDatiConfermaEliminaFirma();
                getSintesiFirme(idSede, annoFormativo, dataRichiestaDa, dataRichiestaA);
                setAlertMsg("Firma eliminata correttamente")
                setAlertSeverity("success")
                setAlertOpen(true)
            })
            .catch(function (error) {
                setAlertMsg("Errore durante l'eliminazione della firma")
                setAlertSeverity("error")
                setAlertOpen(true)
            })
    }

    function changePeriodoDiInteresse(dataInizioFormatted, dataFineFormatted) {

        setChangePeriodoDiInteresseOpen(false);
        let formattedInizioTmp = convertISODate(dataInizioFormatted);
        let formattedFineTmp = convertISODate(dataFineFormatted);

        let dataRichiestaDaTmp = convertDataGetProssimeLezione(dataInizioFormatted, true);
        let dataRichiestaATmp = convertDataGetProssimeLezione(dataFineFormatted, true);

        setFormattedPeriodoDiInteresseInizio(formattedInizioTmp);
        setDataRichiestaDa(dataRichiestaDaTmp);
        if (dataFineFormatted != null) {
            setDataRichiestaA(dataRichiestaATmp);
            setFormattedPeriodoDiInteresseFine(formattedFineTmp);
        }

        getSintesiFirme(idSede, annoFormativo, dataRichiestaDaTmp, dataRichiestaATmp);
    }

    function openDettaglioGiorno(corsoObj, dataRichiesta, canOpenDettaglio) {

        setDataRichiestaDettaglio(dataRichiesta);
        setIdCorsoSelezionato(corsoObj?.idCorso);
        setNomeCorsoDettaglio(corsoObj?.codiceCorso + " - " + corsoObj?.nomeCorso + " (" + corsoObj?.CodSimon + ")")
        setIsDialogDettaglioOpen(true);
        setCanFirmareDettaglio(canOpenDettaglio);

    }

    function closeDialogDettaglio() {
        setIsDialogDettaglioOpen(false);
        getSintesiFirme(idSede, annoFormativo, dataRichiestaDa, dataRichiestaA);
    }


    async function getSedi(dataRichiestaDaTmp, dataRichiestaATmp, annoFormativoTmp) {
        RicercaAPI.getSedi()
            .then((result) => {
                setListSedi([...result.sedi]);
                setHasListSediLoaded(true);
                if (result.sedi.length === 1) {
                    setIdSede(result.sedi[0].id);
                    setNomeSedeSelezionata(result.sedi[0].nomeSede);
                    setIsDialogSediOpen(false);
                    getSintesiFirme(result.sedi[0].id, annoFormativoTmp, dataRichiestaDaTmp, dataRichiestaATmp)
                } else {
                    setIsDialogSediOpen(true)
                }
            })
            .catch(function (error) {
            })
    }
    async function getSintesiFirme(idSedeTmp, annoFormativoTmp, dataRichiestaDaTmp, dataRichiestaATmp) {
        let dataObj = {
            "dataRichiestaDa": dataRichiestaDaTmp,
            "dataRichiestaA": dataRichiestaATmp,
            "idSede": idSedeTmp,
            "annoFormativo": annoFormativoTmp
        }
        setHasLoaded(false);
        PresenzeAPI.getSintesiMonitoraggioFirme(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setCountOreDuplicate(result?.countOreDuplicate ?? 0);
                setListCorsi([...result.corsi]);

                let arrayDateTmp = [];
                //Qui aggiungo tutte le date che trovo, poi in seguito elimino i doppioni e faccio il sort
                for (let corso of result.corsi) {
                    for (let i = 0; i < corso.dateLezione.length; i++) {
                        arrayDateTmp.push(corso.dateLezione[i].data)
                    }
                }
                arrayDateTmp.sort();
                let arrayDateCleaned = [...new Set(arrayDateTmp)]; //Pulisco dai doppioni

                setArrayGiorni([...arrayDateCleaned]);
            })
            .catch(function (error) {
                setHasLoaded(true);
                setListCorsi([]);
            })
    }

    function ReturnCellGiornoCorso({ corsoObj, giorno, indexCorso, indexGiorno }) {
        let dateCorso = corsoObj?.dateLezione ?? [];
        let indexData = dateCorso.findIndex(d => d.data === giorno);
        return (
            indexData !== -1 ? (
                <TableCell className={dateCorso[indexData]?.chiusura ? 'disabledCell' : ""} key={"dettaglio_" + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        {!corsoObj?.dateLezione[indexData]?.chiusura && ( //Se non è giorno di chiusura mostro i dati
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                <Box width={"20px"} height={"20px"}
                                    border={"1px solid #1976d2"}
                                    borderColor={"primary"}
                                    backgroundColor={"white"}
                                    display={"flex"} alignItems={"center"} justifyContent={"center"}
                                    sx={{ mb: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}
                                    onClick={() => openDettaglioGiorno(corsoObj, corsoObj?.dateLezione[indexData]?.data, corsoObj?.dateLezione[indexData]?.canOpenDettaglio)}>
                                    <ZoomIn />
                                </Box>
                                <Typography sx={{ color: (corsoObj?.dateLezione[indexData]?.oreFirmate === corsoObj?.dateLezione[indexData]?.oreLezione) ? 'green' : 'black' }}>
                                    {corsoObj?.dateLezione[indexData]?.oreFirmate}/{corsoObj?.dateLezione[indexData]?.oreLezione}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </TableCell >
            ) : (
                <TableCell className={'disabledCell'} key={"dettaglio_" + indexCorso + indexGiorno + "disabled"} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                </TableCell>
            )
        )
    }

    return (
        <Fragment>
            <Breadcrumb sections={["Monitoraggio firme", nomeSedeSelezionata]} />

            <Box sx={{ px: 5 }}>
                <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                    {listSedi.length > 1 && (
                        <Button onClick={openDialogSelezioneSede} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                            Cambia sede ({nomeSedeSelezionata})
                        </Button>
                    )}
                    <Button sx={{ ml: { xs: 0, md: listSedi.length > 1 ? 2 : 0 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                        Cambia periodo di interesse ({formattedPeriodoDiInteresseInizio}
                        {formattedPeriodoDiInteresseFine != null && formattedPeriodoDiInteresseFine !== formattedPeriodoDiInteresseInizio ? (
                            " - " + formattedPeriodoDiInteresseFine
                        ) : ""})
                    </Button>
                    <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                        Mostra legenda
                    </Button>
                    <Button disabled={countOreDuplicate === 0} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<Edit />} onClick={() => openDialogFirmeDuplicate(true)}>
                        Firme Duplicate ({countOreDuplicate ?? 0})
                    </Button>
                </Box>

                {!isDialogSediOpen && (
                    hasListSediLoaded ? (
                        <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                            {(listCorsi.length > 0 && arrayGiorni.length > 0) ? (
                                <TableContainer ref={tableRef} sx={{ width: '100%' }} component={Paper}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                                    <Typography color={'primary'} fontWeight={'bold'}>{nomeSedeSelezionata.toUpperCase()}</Typography>
                                                </TableCell>
                                                {arrayGiorni.map((giorno) => (
                                                    <TableCell className='row-head' key={"giorno_" + giorno} style={{ backgroundColor: 'white', p: 2, textAlign: 'center', minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{getDayAndMonth(giorno)}</Typography>
                                                        <Typography variant="subtitle2">{getDayOfTheWeek(giorno)}</Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {listCorsi.map((corso, indexCorso) => (
                                                <TableRow style={{ height: '80px' }} key={corso?.idCorso} >
                                                    <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <Box sx={{ backgroundColor: 'white', p: 2 }}>
                                                            <Typography ><b>{corso?.codiceCorso}</b> - {corso?.nomeCorso} ({corso?.CodSimon})</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {arrayGiorni.map((giorno, indexGiorno) => (
                                                        <ReturnCellGiornoCorso corsoObj={corso} giorno={giorno} indexCorso={indexCorso} indexGiorno={indexGiorno} />))}
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            ) : (
                                <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography variant="h5">Per il periodo selezionato (<b>{formattedPeriodoDiInteresseInizio}  {formattedPeriodoDiInteresseFine != null ? ("-" + formattedPeriodoDiInteresseFine) : ""}</b>) non ci sono lezioni in programma.</Typography>
                                    <Button sx={{ mt: 2 }} variant="contained" onClick={openPeriodoDiInteresseDialog} startIcon={<EditCalendar />}>
                                        Cambia periodo di interesse
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ height: '90vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                            <Typography sx={{ mt: 3, width: 'fit-content' }}>Caricamento in corso...</Typography>
                        </Box>
                    )
                )
                }
            </Box>

            <Dialog open={isDialogDettaglioOpen} sx={{ mt: '8vh' }} fullWidth maxWidth="md" onClose={closeDialogDettaglio}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Dettaglio del giorno ({getDayAndMonth(dataRichiestaDettaglio)})
                    </Typography>
                    <IconButton onClick={closeDialogDettaglio} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DettaglioMonitoraggioFirme canFirmareDettaglio={canFirmareDettaglio} closeDettaglioMonitoraggio={closeDialogDettaglio} nomeCorso={nomeCorsoDettaglio} idCorso={idCorsoSelezionato} dataRichiesta={dataRichiestaDettaglio} />
                </DialogContent>
            </Dialog>

            <Dialog open={isDialogSediOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Seleziona sede
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl sx={{ width: '100%', mt: 1 }}>
                        <InputLabel id="sede-label">
                            Sede
                        </InputLabel>
                        <Select disabled={!hasListSediLoaded} label="Sede" value={idSede} onChange={changeSede} >
                            <MenuItem key={-1} value={-1} >
                            </MenuItem>
                            {listSedi.map((sede) => (
                                <MenuItem key={sede.id} value={sede.id} >
                                    {sede.nomeSede}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button sx={{ mt: 1 }} onClick={confirmSede} disabled={idSede === -1} fullWidth variant="contained" color="success">
                        Conferma e prosegui
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={changePeriodoDiInteresseOpen} sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Seleziona il periodo di interesse
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RangeDate canSelectSingleDay={true} dataDiInteresseInizio={dataRichiestaDa} dataDiInteresseFine={dataRichiestaA} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog open={isDialogLegendaOpen} onClose={openDialogLegenda}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={openDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"}

                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #1976d2', color: '#1976d2' }}>
                            <ZoomIn sx={{ fontSize: "2rem" }} />

                        </Box>
                        <Typography>Apri dettaglio giornata</Typography>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"}
                            // backgroundColor={"error.light"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid grey', color: 'grey' }}>
                            <ZoomIn sx={{ fontSize: "2rem" }} />

                        </Box>
                        <Typography>Dettaglio giornata non visionabile</Typography>
                    </Box>

                    <Button fullWidth sx={{ mt: 2 }} variant="contained" color="primary" onClick={openDialogLegenda}>Chiudi finestra</Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth open={isDialogFirmeDuplicateOpen} onClose={() => openDialogFirmeDuplicate(false)}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} variant="h6">
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {!isConfermaDeleteFirma ? 'Firme duplicate' : 'Conferma elimina firma'}
                    </Typography>
                    {!isConfermaDeleteFirma && (<IconButton onClick={() => openDialogFirmeDuplicate(false)} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>)}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ overflowY: 'scroll', maxHeight: '40vh' }}>
                    {!isConfermaDeleteFirma ? (
                        <Fragment>
                            {giorniOreDuplicate.map((giorno, indexGiorno) =>
                                <Fragment>
                                    <Typography key={indexGiorno + '_giorni'} sx={{ mt: 1, fontWeight: '800' }} >{convertDataDiNascita(giorno?.dataLezione) + ' - ' + giorno?.ora + '° ora'} </Typography>
                                    {giorno?.docentiOra.map((docenteOra, indexDocenteOra) =>
                                        <Box sx={{ ml: 2, my: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Typography key={indexGiorno + '_giorni_' + indexDocenteOra + '_docenteOra'}>{(docenteOra?.cognomeFormatore ?? '').toUpperCase() + ' ' + (docenteOra?.nomeFormatore ?? '').toUpperCase() + ' (' + docenteOra?.nomeMateria + ')'}</Typography>
                                                <Button size="small" color="error" sx={{ mr: 1 }} variant="outlined" startIcon={<DeleteOutline />} onClick={() => selectDocenteOraSel(giorno, docenteOra)}>
                                                    Elimina firma
                                                </Button>
                                            </Box>
                                            <Typography variant="subtitle2">Firmata il: {convertToItalianDateTime(docenteOra?.orarioFirma)}</Typography>


                                        </Box>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {(giornoSel !== null && docenteOraSel !== null) && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <DeleteOutline color='error' sx={{ fontSize: '10rem' }} />
                                    <Typography>Confermi di voler eliminare la seguente firma? Una volta confermato, non potrai annullare l'operazione.</Typography>
                                    <Box>
                                        <Typography sx={{ mt: 1, fontWeight: '800' }} >{(docenteOraSel?.cognomeFormatore ?? '').toUpperCase() + ' ' + (docenteOraSel?.nomeFormatore ?? '').toUpperCase() + ' - ' + docenteOraSel?.nomeMateria} {' (' + convertDataDiNascita(giornoSel?.dataLezione ?? '') + ' - ' + (giornoSel?.ora ?? '') + '° ora)'}</Typography>
                                    </Box>
                                </Box>
                            )}
                        </Fragment>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    {!isConfermaDeleteFirma ? (
                        <Button fullWidth sx={{ m: 2 }} variant="contained" color="primary" onClick={() => openDialogFirmeDuplicate(false)}>Chiudi finestra</Button>
                    ) : (
                        <Box sx={{ m: 2, width: '100%' }}>
                            <Button fullWidth variant="contained" color="error" onClick={() => deleteFirma(docenteOraSel?.idFirma)}>Conferma elimina firma</Button>
                            <Button fullWidth sx={{ mt: 1 }} variant="outlined" color="error" onClick={() => resetDatiConfermaEliminaFirma()}>torna a firme duplicate</Button>
                        </Box>
                    )}
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertOpen} autoHideDuration={1500} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Monitoraggio firme</title>
            </Helmet>
        </Fragment >
    )
}