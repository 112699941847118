import { Box, Paper, Typography, Snackbar, Alert, Button, Dialog, DialogTitle, DialogContent, IconButton, Divider, DialogActions } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Close, Delete } from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { itIT } from "@mui/x-date-pickers/locales";
import { DesktopTimePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";

import { convertDataDiNascita, convertToSqlFormat } from "../../shared/metodiDateUtility";
import { PresenzeAPI } from "../../../api/presenzeApi";
import { UserAPI } from "../../../api/userApi";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../../shared/utilityMethods";

export default function PresenzeParziali(props) {

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [idAlunno, setIdAlunno] = useState(-1)
    const [idDocente, setIdDocente] = useState(-1)

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null); 
    const [dataAttuale, setDataAttuale] = useState(null);

    const [isValidata, setIsValidata] = useState(false);
    const { t } = useTranslation();

    const [elencoPresenzeParziali, setElencoPresenzeParziali] = useState([])
    const [presenzaParzialeSel, setPresenzaParzialeSel] = useState(null)

    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
    const [openDialogUscita, setOpenDialogUscita] = useState(false);

    const [permessiUtente, setPermessiUtente] = useState([]);
    const [canAddUA, setCanAddUA] = useState(false);
    const [canAddRitardo, setCanAddRitardo] = useState(false);
    const [comesFromConsultaRegistro, setComesFromConsultaRegistro] = useState(false);
    const [tipoRegistro, setTipoRegistro] = useState('nessun registro')

    //Dati alunno
    const [nomeAlunno, setNomeAlunno] = useState("");
    const [cognomeAlunno, setCognomeAlunno] = useState("");

    const [data, setData] = useState(null);
    const [oraIngresso, setOraIngresso] = useState(null);
    const [oraUscita, setOraUscita] = useState(null);

    const [modificaMode, setModificaMode] = useState(false);

    const [idCorso, setIdCorso] = useState(-1);

    const [isOpenDialogEliminaPresenzeParzialiRitardo, setIsOpenDialogEliminaPresenzeParzialiRitardo] = useState(false)
    const [isOpenDialogEliminaPresenzeParzialiUscita, setIsOpenDialogEliminaPresenzeParzialiUscita] = useState(false)

    const [isLoading, setIsLoading] = useState(true);

    const [inizioPeriodo, setInizioPeriodo] = useState(null)
    const [finePeriodo, setFinePeriodo] = useState(null)
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);

    useEffect(() => {
        let date = DateTime.local();
        let roundedOrario = date.startOf('minute').plus({ minutes: Math.round(date.minute / 5) * 5 - date.minute });

        setData(DateTime.now())
        setOraIngresso(roundedOrario)
        setOraUscita(roundedOrario)


        let objDateInteresse = getDataInizioFinePeriodo();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        setInizioPeriodo(inizioPeriodoTmp ?? null);
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
        setFinePeriodo(finePeriodoTmp ?? null);

        setIsAnnoFormativoCorrente(objDateInteresse?.isAnnoFormativoCorrente ?? true)
    }, [])

    useEffect(() => {
        if (props.permessiUtente !== undefined && props.tipo !== undefined && props.canTouchAssenze) {

            let permessiTmp = props.permessiUtente;
            setPermessiUtente(permessiTmp);
            if (props.canTouchAssenze) {
                if (permessiTmp.some(i => i.nome === "canAddUA" && i.value === true)) setCanAddUA(true);
                if (permessiTmp.some(i => i.nome === "canAddRitardo" && i.value === true)) setCanAddRitardo(true);
            } else {
                setCanAddUA(false);
                setCanAddRitardo(false);
            }


        }
    }, [props.permessiUtente, props.tipo, props.tipoRegistro, props.canTouchAssenze])

    useEffect(() => {
        if (props.idAlunno !== undefined && props.dataAttuale !== undefined) {
            let dataTmp = convertToSqlFormat(props.dataAttuale != null ? props.dataAttuale : DateTime.local());
            setIdAlunno(props.idAlunno)
            let annoFormativoTmp = getAnnoFormativo();
            getDettaglioAlunno(props.idAlunno, annoFormativoTmp)

            let objDateInteresse = getDataInizioFinePeriodo(dataTmp);
            let dataDiInteresseTmp = objDateInteresse?.dataDiInteresse ?? DateTime.now();
            let inizioPeriodoTmp = convertToSqlFormat(objDateInteresse?.inizioPeriodo ?? null)
            let dataDiInteresse = convertToSqlFormat(dataDiInteresseTmp);
            setDataAttuale(dataDiInteresse)
            if (objDateInteresse?.isAnnoFormativoCorrente !== undefined && objDateInteresse?.isAnnoFormativoCorrente)
                getPresenzeParziali(null, dataDiInteresse, props.idAlunno, props.presenzaObj);
            else {
                setDataInizioPeriodo(inizioPeriodoTmp);
                getPresenzeParziali(inizioPeriodoTmp, dataDiInteresse, props.idAlunno, props.presenzaObj);
            }


        }
    }, [props.idAlunno, props.dataAttuale, props.presenzaObj])

    useEffect(() => {
        if (props.idDocente !== undefined)
            setIdDocente(props.idDocente);
    }, [props.idDocente])

    useEffect(() => {
        if (props.idCorso !== undefined)
            setIdCorso(props.idCorso);
    }, [props.idCorso])

    useEffect(() => {
        if (props.comesFromConsultaRegistro !== undefined)
            setComesFromConsultaRegistro(props.comesFromConsultaRegistro);
    }, [props.comesFromConsultaRegistro])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro);
    }, [props.tipoRegistro])

    function openDialogEliminaPresenzeParziali(presenzaParzialeSel) {

        setPresenzaParzialeSel(presenzaParzialeSel);
        const dateTmp = DateTime.fromISO(presenzaParzialeSel.data);
        setData(dateTmp);
        let oraString;
        if (presenzaParzialeSel.stato_presenza === 'UA') {
            let dataString = presenzaParzialeSel.data.split('T')[0] + 'T'//
            setIsOpenDialogEliminaPresenzeParzialiRitardo(false);
            setIsOpenDialogEliminaPresenzeParzialiUscita(true);
            oraString = presenzaParzialeSel.orario_uscita_reale.split(':').slice(0, 2).join(':');
            dataString += oraString;
            let oraDataObj = DateTime.fromISO(dataString);
            setOraUscita(oraDataObj)
        }
        if (presenzaParzialeSel.stato_presenza === 'ER') {
            let dataString = presenzaParzialeSel.data.split('T')[0] + 'T'//
            setIsOpenDialogEliminaPresenzeParzialiRitardo(true);
            setIsOpenDialogEliminaPresenzeParzialiUscita(false);
            oraString = presenzaParzialeSel.orario_ingresso_reale.split(':').slice(0, 2).join(':');
            dataString += oraString;
            let oraDataObj = DateTime.fromISO(dataString);
            setOraIngresso(oraDataObj)
        }
        if (presenzaParzialeSel.stato_presenza === 'ER/UA') {
            setOpenDialogRitardo(true);
            setOpenDialogUscita(true);
            setIsOpenDialogEliminaPresenzeParzialiRitardo(true);
            setIsOpenDialogEliminaPresenzeParzialiUscita(true);
            if (presenzaParzialeSel.orario_ingresso_reale !== null) {
                let dataStringER = presenzaParzialeSel.data.split('T')[0] + 'T'//
                let oraStringER = presenzaParzialeSel.orario_ingresso_reale.split(':').slice(0, 2).join(':');
                dataStringER += oraStringER;
                let oraDataObjER = DateTime.fromISO(dataStringER);
                setOraIngresso(oraDataObjER);
            }
            if (presenzaParzialeSel.orario_uscita_reale !== null) {
                let dataStringUA = presenzaParzialeSel.data.split('T')[0] + 'T'//
                let oraStringUA = presenzaParzialeSel.orario_uscita_reale.split(':').slice(0, 2).join(':');
                dataStringUA += oraStringUA;
                let oraDataObjUA = DateTime.fromISO(dataStringUA);
                setOraUscita(oraDataObjUA);
            }
        }
    }

    function openDialogRitardoUscita(presenzaParzialeSel) {
        setPresenzaParzialeSel(presenzaParzialeSel);
        const dateTmp = DateTime.fromISO(presenzaParzialeSel.data);
        setData(dateTmp);
        let oraString;
        setIsValidata(presenzaParzialeSel?.validata ?? false);
        if (presenzaParzialeSel.stato_presenza === 'UA') {
            let dataString = presenzaParzialeSel.data.split('T')[0] + 'T'//
            setOpenDialogUscita(true);
            setOpenDialogRitardo(false);
            oraString = presenzaParzialeSel.orario_uscita_reale.split(':').slice(0, 2).join(':');
            dataString += oraString;
            let oraDataObj = DateTime.fromISO(dataString);
            setOraUscita(oraDataObj)
        }
        if (presenzaParzialeSel.stato_presenza === 'ER') {
            let dataString = presenzaParzialeSel.data.split('T')[0] + 'T'//
            setOpenDialogRitardo(true);
            setOpenDialogUscita(false);
            oraString = presenzaParzialeSel.orario_ingresso_reale.split(':').slice(0, 2).join(':');
            dataString += oraString;
            let oraDataObj = DateTime.fromISO(dataString);
            setOraIngresso(oraDataObj)
        }
        if (presenzaParzialeSel.stato_presenza === 'ER/UA') {
            setOpenDialogRitardo(true);
            setOpenDialogUscita(true);
            if (presenzaParzialeSel.orario_ingresso_reale !== null) {
                let dataStringER = presenzaParzialeSel.data.split('T')[0] + 'T'//
                let oraStringER = presenzaParzialeSel.orario_ingresso_reale.split(':').slice(0, 2).join(':');
                dataStringER += oraStringER;
                let oraDataObjER = DateTime.fromISO(dataStringER);
                setOraIngresso(oraDataObjER);
            }
            if (presenzaParzialeSel.orario_uscita_reale !== null) {
                let dataStringUA = presenzaParzialeSel.data.split('T')[0] + 'T'//
                let oraStringUA = presenzaParzialeSel.orario_uscita_reale.split(':').slice(0, 2).join(':');
                dataStringUA += oraStringUA;
                let oraDataObjUA = DateTime.fromISO(dataStringUA);
                setOraUscita(oraDataObjUA);
            }
        }
    }

    function closeDialogEliminaPresenzeParziali() {
        setIsOpenDialogEliminaPresenzeParzialiRitardo(false);
        setIsOpenDialogEliminaPresenzeParzialiUscita(false);

        let date = DateTime.local();
        let roundedOrario = date.startOf('minute').plus({ minutes: Math.round(date.minute / 5) * 5 - date.minute });

        setOraIngresso(roundedOrario);
        setOraUscita(roundedOrario);
        setData(DateTime.now())

        getPresenzeParziali(dataInizioPeriodo, dataAttuale, idAlunno);
    }

    function confermaEliminaPresenzaParziale() {
        deletePresenzeParziali()
    }

    function closeDialog() {
        setDialogIsOpen(false);
        setOpenDialogRitardo(false);
        setOpenDialogUscita(false);
        setModificaMode(false);

        getPresenzeParziali(dataInizioPeriodo, dataAttuale, idAlunno);

        let date = DateTime.local();
        let roundedOrario = date.startOf('minute').plus({ minutes: Math.round(date.minute / 5) * 5 - date.minute });

        setOraIngresso(roundedOrario);
        setOraUscita(roundedOrario);
        setData(DateTime.now())

        getPresenzeParziali(dataInizioPeriodo, dataAttuale, idAlunno);

    }

    function changeData(value) {
        setData(value);
    }

    function changeOraIngresso(value) {
        setOraIngresso(value)
    }

    function changeOraUscita(value) {
        setOraUscita(value)
    }

    function aggiuntaRitardoUscita() {
        addRitardoUscita()
    }

    function addRitardoUscita() {
        let dataFormatted = data.toFormat('yyyy-MM-dd'); //convertDataGetProssimeLezione(data, true) //
        //let testData = convertDataGetProssimeLezione(dataFormatted, false);
        let oraIngressoFormatted = oraIngresso !== null ? oraIngresso.toFormat('HH:mm:ss') : '';
        let oraUscitaFormatted = oraUscita !== null ? oraUscita.toFormat('HH:mm:ss') : '';
        // let dataObj;
        let arrayObj = [];
        if ((openDialogRitardo && !openDialogUscita) || (openDialogRitardo && openDialogUscita)) {
            let dataObj = {
                "data": dataFormatted, //yyyy-MM-dd
                "idAlunno": idAlunno,
                "statoPresenza": "ER",
                "orarioIngressoReale": oraIngressoFormatted,
                "idUtenteAggiornamento": idDocente,
                "note": ""
            }
            arrayObj.push(dataObj)
        }
        if ((!openDialogRitardo && openDialogUscita) || (openDialogRitardo && openDialogUscita)) {
            let dataObj = {
                "data": dataFormatted, //yyyy-MM-dd
                "idAlunno": idAlunno,
                "statoPresenza": "UA",
                "orarioUscitaReale": oraUscitaFormatted,
                "idUtenteAggiornamento": idDocente,
                "note": ""

            }
            arrayObj.push(dataObj)
        }

        modificaRitardoUscita(arrayObj)
    }


    async function modificaRitardoUscita(data) {

        for (let dato of data) {
            PresenzeAPI.aggiungiRitardoUscita(dato)
                .then((result) => {
                    closeDialog();
                    setAlertSeverity("success");
                    if (openDialogRitardo && !openDialogUscita) setAlertMsg('Ritardo modificato correttamente');
                    if (!openDialogRitardo && openDialogUscita) setAlertMsg('Uscita anticipata modificata correttamente');
                    if (openDialogRitardo && openDialogUscita) setAlertMsg('Modifiche correttamente eseguite');
                    setAlertOpen(true);
                })
                .catch((error) => {
                    let errorMsg = "";
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                    setAlertSeverity("error");
                    setAlertMsg(errorMsg);
                    setAlertOpen(true);
                })
        }
    }




    async function getDettaglioAlunno(idStudente, annoFormativoTmp) {

        let dataObj = {
            "idAlunno": idStudente,
            "annoFormativo": annoFormativoTmp
        }
        if (idStudente !== -1) {
            UserAPI.getDettaglioAlunno(dataObj)
                .then((result) => {
                    setNomeAlunno(result[0]?.nome);
                    setCognomeAlunno(result[0]?.cognome);
                })
                .catch((error) => {
                    if (error.response.status === 403) props.logout();
                })
        }
    }


    async function getPresenzeParziali(dataDal, dataAl, idAlunno, presenzaObj) {
        let dataObj = {
            "dataDal": dataDal,
            "dataAl": dataAl,
            idAlunno: idAlunno
        }
        PresenzeAPI.getPresenzeParziali(dataObj)
            .then((result) => {
                let presenzeParzialiTmp = result;
                presenzeParzialiTmp.sort((a, b) => new Date(b.data) - new Date(a.data));
                setElencoPresenzeParziali([...presenzeParzialiTmp])
                if (props.presenzaObj !== undefined && props.presenzaObj != null) {
                    let index = presenzeParzialiTmp.findIndex(p => p.id === presenzaObj.id);
                    if (index !== -1) {
                        openDialogRitardoUscita(presenzeParzialiTmp[index])

                    }
                }
                setIsLoading(false)
            })
            .catch((err) => {
            })
    }

    async function deletePresenzeParziali() {
        let dataObj = {
            "idPresenzaParziale": presenzaParzialeSel?.id,
            "idCorso": idCorso
        }

        PresenzeAPI.deletePresenzeParziali(dataObj)
            .then((result) => {
                setAlertSeverity("success");
                setAlertMsg('Presenza parziale eliminata.');
                setAlertOpen(true);

                setIsOpenDialogEliminaPresenzeParzialiRitardo(false);
                setIsOpenDialogEliminaPresenzeParzialiUscita(false);
                setOpenDialogRitardo(false);
                setOpenDialogUscita(false);
                setPresenzaParzialeSel(null);

                getPresenzeParziali(null, dataAttuale, idAlunno);
            })
            .catch((err) => {
                let errorMsg = "";
                errorMsg = err?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }

    function modalitaModificaOn() {
        setModificaMode(true);
    }

    function annullaModifiche() {
        setModificaMode(false);
        openDialogRitardoUscita(presenzaParzialeSel);

    }

    function isButtonSaveDisabled() {
        if (oraIngresso !== null && oraUscita !== null)
            return false
        else return true;
    }

    return (
        <Fragment>

            <Typography sx={{ mt: 2, mb: 1 }}>Presenze parziali</Typography>
            {!isLoading ? (<Fragment>
                {elencoPresenzeParziali.length !== 0 ? (<Paper variant="outlined" sx={{ minHeight: '10vh', p: 2, overflowX: 'scroll' }}>
                    {elencoPresenzeParziali.map((pp, index) => (
                        <Box key={index} display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <Box width={"20px"} height={"20px"}
                                border={"1px solid grey"}
                                borderColor={'warning.light'}
                                backgroundColor={"white"}
                                display={"flex"} alignItems={"center"} justifyContent={"center"}
                                sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                                <Typography fontWeight={"bold"} color={'warning.light'} sx={{ fontSize: pp.stato_presenza !== 'ER/UA' ? '1rem' : '0.65rem' }}>{pp.stato_presenza}</Typography>
                            </Box>
                            <Typography mx={2}>Data <b>{convertDataDiNascita(pp.data)} </b></Typography>
                            {pp.stato_presenza === 'UA' && (<Typography>Uscita anticipata alle ore: <b>{pp.orario_uscita_reale.slice(0, -3)}</b></Typography>)}
                            {pp.stato_presenza === 'ER' && (<Typography>Ingresso alle ore: <b>{pp.orario_ingresso_reale.slice(0, -3)}</b></Typography>)}
                            {pp.stato_presenza === 'ER/UA' && (<Box><Typography>Ingresso alle ore: <b>{pp.orario_ingresso_reale.slice(0, -3)}</b> </Typography><Typography>Uscita anticipata alle ore: <b>{pp.orario_uscita_reale.slice(0, -3)}</b> </Typography></Box>)}

                            <Button onClick={() => openDialogRitardoUscita(pp)} sx={{ ml: 3 }} size="small" variant="contained" data-testid='openDialogPresenzePrazialiBtn'>Vedi Dettaglio</Button>

                            {(pp.rimuoviUAER && comesFromConsultaRegistro && tipoRegistro === 'classe') && (
                                <Button onClick={() => openDialogEliminaPresenzeParziali(pp)} sx={{ ml: 3 }} size="small" variant="contained" data-testid='openDialogEliminaPresenzePrazialiBtn' color="error">Elimina</Button>
                            )}
                        </Box>
                    ))}
                </Paper>) : (<Paper variant="outlined" sx={{ minHeight: '10vh', p: 2, overflowX: 'scroll' }}>Non vi sono presenze parziali.</Paper>)}
            </Fragment>) : (<Fragment>
                <Paper variant="outlined" sx={{ minHeight: '10vh', p: 2, overflowX: 'scroll' }}>
                    <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                        <Box width={"20px"} height={"20px"}
                            border={"1px solid grey"}
                            borderColor={'rgb(220,220,220)'}
                            backgroundColor={"white"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                            <Typography fontWeight={"bold"} color={'rgb(220,220,220)'} sx={{ fontSize: '1rem' }}>X</Typography>
                        </Box>
                        <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />


                        <Button disabled sx={{ px: 6, ml: 3 }} size="small" variant="contained" data-testid='openDialogPresenzePrazialiBtn'>{'      .'}</Button>
                        <Button disabled sx={{ px: 6, ml: 3 }} size="small" variant="contained" data-testid='openDialogEliminaPresenzePrazialiBtn' color="error">{'      .'}</Button>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                        <Box width={"20px"} height={"20px"}
                            border={"1px solid grey"}
                            borderColor={'rgb(220,220,220)'}
                            backgroundColor={"white"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                            <Typography fontWeight={"bold"} color={'rgb(220,220,220)'} sx={{ fontSize: '1rem' }}>X</Typography>
                        </Box>
                        <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />


                        <Button disabled sx={{ px: 6, ml: 3 }} size="small" variant="contained" data-testid='openDialogPresenzePrazialiBtn'>{'      .'}</Button>
                        <Button disabled sx={{ px: 6, ml: 3 }} size="small" variant="contained" data-testid='openDialogEliminaPresenzePrazialiBtn' color="error">{'      .'}</Button>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                        <Box width={"20px"} height={"20px"}
                            border={"1px solid grey"}
                            borderColor={'rgb(220,220,220)'}
                            backgroundColor={"white"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                            <Typography fontWeight={"bold"} color={'rgb(220,220,220)'} sx={{ fontSize: '1rem' }}>X</Typography>
                        </Box>
                        <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                        <Button disabled sx={{ px: 6, ml: 3 }} size="small" variant="contained" data-testid='openDialogPresenzePrazialiBtn'>{'      .'}</Button>
                        <Button disabled sx={{ px: 6, ml: 3 }} size="small" variant="contained" data-testid='openDialogEliminaPresenzePrazialiBtn' color="error">{'      .'}</Button>
                    </Box>
                </Paper>
            </Fragment>)}
            <Dialog hideBackdrop open={openDialogRitardo || openDialogUscita} maxWidth="md" onClose={closeDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    {openDialogRitardo && !openDialogUscita ? 'Dettaglio ritardo ' : null} {!openDialogRitardo && openDialogUscita ? 'Dettaglio uscita anticipata ' : null} {openDialogRitardo && openDialogUscita ? 'Dettaglio ritardo e uscita anticipata ' : null}{'(' + nomeAlunno + " " + cognomeAlunno + ')'}
                    <IconButton onClick={closeDialog} sx={{ ml: 5, position: 'absolute', top: 5, right: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid xs={12} md={openDialogRitardo && openDialogUscita ? 12 : 6} sx={{ width: '100%', pt: 2 }}>
                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                    minDate={inizioPeriodo}
                                    maxDate={isAnnoFormativoCorrente === false ? DateTime.now() : finePeriodo}
                                    label="Data"
                                    value={data}
                                    format="dd/MM/yyyy"
                                    onChange={(value) => changeData(value)}
                                    dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                    disabled={!modificaMode}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {openDialogRitardo && (<Grid xs={12} md={6} sx={{ width: '100%', pt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DesktopTimePicker
                                    sx={{ width: '100%' }}
                                    label={'Ora entrata'}
                                    views={['hours', 'minutes']}
                                    format="HH:mm"
                                    ampm={false}
                                    onChange={(value) => changeOraIngresso(value)}
                                    value={oraIngresso}
                                    disabled={!modificaMode}
                                />
                            </LocalizationProvider>
                        </Grid>)}
                        {openDialogUscita && (<Grid xs={12} md={6} sx={{ width: '100%', pt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DesktopTimePicker
                                    sx={{ width: '100%' }}
                                    label={'Ora uscita'}
                                    views={['hours', 'minutes']}
                                    format="HH:mm"
                                    ampm={false}
                                    onChange={(value) => changeOraUscita(value)}
                                    value={oraUscita}
                                    disabled={!modificaMode}
                                />
                            </LocalizationProvider>
                        </Grid>)}
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box display="flex" sx={{ flexDirection: 'column', width: '100%', p: 2 }}>
                        {canAddRitardo || canAddUA ? (
                            <Box width={"100%"}>
                                {/* TODO: */}
                                {(presenzaParzialeSel?.rimuoviUAER && comesFromConsultaRegistro && tipoRegistro === 'classe') ? (
                                    <Button fullWidth onClick={() => openDialogEliminaPresenzeParziali(presenzaParzialeSel)} sx={{ mb: 1, width: '100%' }} variant="contained" data-testid='openDialogEliminaPresenzePrazialiBtn' color="error">Elimina</Button>
                                ) : (<span></span>)}
                                {presenzaParzialeSel?.rimuoviUAER && ( //Se è possibile eliminare una presenza, allora la posso modificare
                                    <Box> {/*Pulsante modifica */}
                                        {!modificaMode ? (
                                            <Button fullWidth onClick={closeDialog} sx={{ mb: 1, width: '100%' }} color="error" variant="outlined">chiudi dettaglio</Button>
                                        ) : (
                                            <Button fullWidth onClick={annullaModifiche} sx={{ mb: 1, width: '100%' }} color="error" variant="outlined">Annulla modifiche</Button>
                                        )}
                                        {!modificaMode ? (<Button fullWidth disabled={isValidata} onClick={() => modalitaModificaOn()}
                                            sx={{ width: '100%' }}
                                            variant="contained" >
                                            Modifica
                                        </Button>) : (<Button fullWidth onClick={() => aggiuntaRitardoUscita()} disabled={isButtonSaveDisabled()}
                                            color="success" variant="contained" >
                                            Conferma Modifica
                                        </Button>)}
                                    </Box>
                                )}
                            </Box>

                        ) : (
                            <Button onClick={closeDialog} color="error" variant="outlined">chiudi dettaglio</Button>
                        )}
                    </Box>
                </DialogActions>
            </Dialog>


            <Dialog hideBackdrop open={isOpenDialogEliminaPresenzeParzialiRitardo || isOpenDialogEliminaPresenzeParzialiUscita} maxWidth="md" onClose={closeDialogEliminaPresenzeParziali}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>
                        {isOpenDialogEliminaPresenzeParzialiRitardo && !isOpenDialogEliminaPresenzeParzialiUscita ? 'Elimina ritardo ' : null} {!isOpenDialogEliminaPresenzeParzialiRitardo && isOpenDialogEliminaPresenzeParzialiUscita ? 'Elimina uscita anticipata ' : null} {isOpenDialogEliminaPresenzeParzialiRitardo && isOpenDialogEliminaPresenzeParzialiUscita ? 'Elimina ritardo e uscita anticipata ' : null}{'(' + nomeAlunno + " " + cognomeAlunno + ')'}
                    </Typography>
                    {/* <IconButton onClick={closeDialogEliminaPresenzeParziali} sx={{ ml: 5, position: 'absolute', top: 5, right: 5 }}  >
                        <Close />
                    </IconButton> */}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Confermi di voler eliminare la seguente presenza parziale:</Typography>
                    <Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />
                    <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                        <Box width={"20px"} height={"20px"}
                            border={"1px solid grey"}
                            borderColor={'warning.light'}
                            backgroundColor={"white"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                            <Typography fontWeight={"bold"} color={'warning.light'} sx={{ fontSize: presenzaParzialeSel?.stato_presenza !== 'ER/UA' ? '1rem' : '0.65rem' }}>{presenzaParzialeSel?.stato_presenza}</Typography>
                        </Box>
                        <Typography mx={2}>Data <b>{convertDataDiNascita(presenzaParzialeSel?.data)} </b></Typography>
                        {presenzaParzialeSel?.stato_presenza === 'UA' && (<Typography>Uscita anticipata alle ore: <b>{presenzaParzialeSel?.orario_uscita_reale.slice(0, -3)}</b></Typography>)}
                        {presenzaParzialeSel?.stato_presenza === 'ER' && (<Typography>Ingresso alle ore: <b>{presenzaParzialeSel?.orario_ingresso_reale.slice(0, -3)}</b></Typography>)}
                        {presenzaParzialeSel?.stato_presenza === 'ER/UA' && (<Box><Typography>Ingresso alle ore: <b>{presenzaParzialeSel?.orario_ingresso_reale.slice(0, -3)}</b> </Typography><Typography>Uscita anticipata alle ore: <b>{presenzaParzialeSel?.orario_uscita_reale.slice(0, -3)}</b> </Typography></Box>)}
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box display="flex" sx={{ flexDirection: 'column', width: '100%', p: 2 }}>
                        <Button onClick={closeDialogEliminaPresenzeParziali} sx={{ mb: 1 }} color="error" variant="outlined">chiudi</Button>
                        <Button onClick={confermaEliminaPresenzaParziale} color="error" variant="contained">conferma</Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>{t("name")} - {t("pages.assenze.title")}</title>
            </Helmet>
        </Fragment>
    )
}