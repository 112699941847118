import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import {  Paper, FormControl, Box, Collapse, Container, Select, InputLabel, MenuItem, Typography, Dialog, DialogContent } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import 'react-virtualized/styles.css';

import RegistroValidazione from './registroValidazione';
import { RicercaAPI } from '../../api/ricercaApi';
import Breadcrumb from '../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../shared/utilityMethods';


export default function ValidazionePresenze(props) {

  const { t } = useTranslation();

  const [sedeSelectedID, setSedeSelectedID] = useState(-1)

  const [sediList, setSediList] = useState([]);
  const [classiList, setClassiList] = useState([]);
  const [sedeName, setSedeName] = useState('');
  const [corsoSelected, setCorsoSelected] = useState({});

  const [modal, setModal] = useState(false);

  const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

  const [annoFormativo, setAnnoFormativo] = useState("");

  useEffect(() => {
    let annoFormativoTmp = getAnnoFormativo();
    setAnnoFormativo(annoFormativoTmp);
    getSedi(annoFormativoTmp);
  }, [])

  useEffect(() => {
    if (props.isInsideOtherComponent !== undefined)
      setIsInsideOtherComponent(props.isInsideOtherComponent);
  }, [props.isInsideOtherComponent])


  async function getSedi(annoFormativo) {

    RicercaAPI.getSedi({ annoFormativo: annoFormativo })
      .then((result) => {
        setSediList([...result.sedi])
        if (result.sedi.length === 1) {
          setSedeName(result.sedi[0].nomeSede);
          setSedeSelectedID(result.sedi[0].id);
          getClassiBySede(result.sedi[0].id, annoFormativo);
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }


      })

  }

  async function getClassiBySede(idSede, annoFormativo) {
    let dataObj = {
      idSede: idSede,
      annoFormativo: annoFormativo
    }

    RicercaAPI.getClassiBySede(dataObj)
      .then((result) => {
        setClassiList(result.sedi)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403)
            if (props.logout())
              props.logout();
        }
      })
  }

  function changeSede(e) {
    if (e.target.value !== undefined) {
      //setSedeName(e.target.value.nomeSede);
      let index = sediList.findIndex(sede => sede.id === e.target.value);
      if (index !== -1)
        setSedeName(sediList[index].nomeSede);
      setSedeSelectedID(e.target.value)
      getClassiBySede(e.target.value, annoFormativo);
    }
    else {
      setSedeName('');
      setSedeSelectedID(-1)

      // setClasseName('');
    }
  };

  function handleCloseModal() {
    setModal(false)
  }

  function handleOpenModal() {
    setModal(true)
  }

  function openModal(idItem) {
    // if (idItem !== undefined) {
    handleOpenModal()
    // setIdModal(idItem)
    // }
  }



  function selCorso(corso) {
    setCorsoSelected(corso)
    openModal(corso.id)
  }

  function logout() {
    if (props.logout) props.logout()
  }

  return (
    <Fragment>

      <Breadcrumb sections={["Validazione presenze"]} />

      <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        {/* <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 5 }}> */}
        <Grid width={{ xs: '100%', md: '20vw' }} mt={5}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="sede-label">
              Seleziona sede
            </InputLabel>
            <Select
              data-testid='sedeRicercaSelect'
              label={"Seleziona sede"}
              labelId="sede-label"
              value={sedeSelectedID}
              onChange={changeSede}
            >
              {sediList.map((sede) => (
                <MenuItem
                  key={sede.id}
                  value={sede.id}
                >
                  {sede.nomeSede}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Collapse in={sedeSelectedID !== -1} sx={{ mt: 2, width: '100%' }}>
          <Paper sx={{ py: 2, px: 4, mt: isInsideOtherComponent ? 0 : 5, height: '50vh', overflowY: 'scroll' }}>
            {classiList.map((corso) => (
              <Box key={corso.id}
                onClick={() => selCorso(corso)}
                sx={{ cursor: 'pointer', p: 2 }}
              >
                <Typography>
                  {corso?.codiceCorso ? corso?.codiceCorso + " - " : ""}{corso?.nomeCorso ?? ""} {corso?.codiceSimon ? "(" + corso?.codiceSimon + ") " : ""}
                </Typography>
              </Box>
            )
            )}
          </Paper>
        </Collapse>
      </Container>

      <Dialog fullScreen open={modal} onClose={handleCloseModal} >
        <DialogContent>
          <RegistroValidazione tipoRegistro={"docente"} logout={logout} corso={corsoSelected} closeDialog={handleCloseModal} sedeUtente={sedeName} />
        </DialogContent>
      </Dialog>

      {!isInsideOtherComponent ? (
        <Helmet>
          <title>{t("name")} - validazione presenze</title>
        </Helmet>
      ) : null}

    </Fragment >
  );
}