import { useState, useEffect, Fragment } from 'react';
import { Box, Typography, IconButton, Checkbox, Chip } from '@mui/material';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import 'react-virtualized/styles.css';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';


export default function TreeViewRicerca(props) {

    const [isMultipleSedi, setIsMultipleSedi] = useState(false);

    const [showSedeChips, setShowSedeChips] = useState(false);

    const [dataList, setDataList] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState(new Set());
    const [arrayCheckedNodes, setArrayCheckedNodes] = useState([]);
    const [checkboxVisible, setCheckboxVisible] = useState(true);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 20,
    });

    useEffect(() => {
        console.log(props.risultatiRicerca);
        if (props.risultatiRicerca !== undefined) {
            setDataList([...props.risultatiRicerca])
            if (props.risultatiRicerca.length === 1) //Se c'è una sola sede
            {
                expandedNodes.add(props.risultatiRicerca[0].id);
                setExpandedNodes(new Set(expandedNodes));
            }
        }
    }, [props.risultatiRicerca])

    useEffect(() => {
        if (props.showSediChips !== undefined) {
            setShowSedeChips(props.showSediChips);
        }
    }, [props.showSediChips])

    useEffect(() => {
        if (props.isMultipleSedi !== undefined)
            setIsMultipleSedi(props.isMultipleSedi);
    }, [props.isMultipleSedi])

    useEffect(() => {
        if (props.showCheckbox !== undefined)
            setCheckboxVisible(props.showCheckbox)
    }, [props.showCheckbox])

    const handleExpandClick = (node) => {
        if (expandedNodes.has(node.id)) {
            expandedNodes.delete(node.id);
        } else {
            expandedNodes.add(node.id);
        }
        setExpandedNodes(new Set(expandedNodes));
    };

    const handleCheckClick = (node) => {
        let arrayCheckedNodesTmp = arrayCheckedNodes;
        if (arrayCheckedNodes.some(n => n.id === node.id && n.idCorso === node.idCorso)) {
            let index = arrayCheckedNodesTmp.findIndex(n => n.id === node.id && n.idCorso === node.idCorso);
            arrayCheckedNodesTmp.splice(index, 1);
        } else {
            arrayCheckedNodesTmp.push(node)
        }
        setArrayCheckedNodes([...arrayCheckedNodesTmp]);
        setCheckedNodesFunction(arrayCheckedNodesTmp); //Cosa fa?

    };

    const checkChildren = (node) => {
        let arrayCheckedNodesTmp = arrayCheckedNodes;
        if (node.children) {
            for (const child of node.children) {
                arrayCheckedNodesTmp.push(child);
                checkChildren(child);
            }
        }
        passCheckedIdToParent(arrayCheckedNodesTmp);

    };


    const checkIfAllChildrenAreSelected = (node) => {
        let arrayOfCheckedChildren = [];

        if (node.children) {
            for (const child of node.children) {
                if (arrayCheckedNodes.some(n => n.id === child.id && n.idCorso === child.idCorso))
                    arrayOfCheckedChildren.push(child.id);
            }
            if (arrayOfCheckedChildren.length > 0 && arrayOfCheckedChildren.length === node.children.length) {
                return true
            }
            else { return false; }
        } else return false
    };

    const checkIfIndeterminateSate = (node) => {
        let arrayOfCheckedChildren = [];
        if (node.children) {
            for (const child of node.children) {
                if (arrayCheckedNodes.some(n => n.id === child.id && n.idCorso === child.idCorso)) {
                    arrayOfCheckedChildren.push(child.id);
                }
            }
            if (arrayOfCheckedChildren.length > 0 && arrayOfCheckedChildren.length < node.children.length) {
                return true
            }
            else { return false; }
        } else return false
    };

    function setCheckedNodesFunction(value) {
        if (props.setCheckedNodes) props.setCheckedNodes(value)
    }

    const checkAllChildren = (node) => {
        let arrayCheckedNodesTmp = [...arrayCheckedNodes];

        const areAllChildrenChecked = (children) => {
            return children.every(child =>
                arrayCheckedNodesTmp.some(n => n.id === child.id && n.idCorso === child.idCorso)
            );
        };

        const toggleChildren = (children, shouldCheck) => {
            for (const child of children) {
                const isChecked = arrayCheckedNodesTmp.some(n => n.id === child.id && n.idCorso === child.idCorso);

                if (shouldCheck && !isChecked) {
                    arrayCheckedNodesTmp.push(child);
                } else if (!shouldCheck && isChecked) {
                    arrayCheckedNodesTmp = arrayCheckedNodesTmp.filter(n => n.id !== child.id || n.idCorso !== child.idCorso);
                }

                if (child.children) {
                    toggleChildren(child.children, shouldCheck);
                }
            }
        };

        const allChildrenChecked = areAllChildrenChecked(node.children);
        toggleChildren(node.children, !allChildrenChecked);

        setArrayCheckedNodes([...arrayCheckedNodesTmp]);
        setCheckedNodesFunction(arrayCheckedNodesTmp);
        passCheckedIdToParent(arrayCheckedNodesTmp);
    }


    const uncheckChildren = (node) => {
        let arrayCheckedNodesTmp = arrayCheckedNodes;

        if (node.children) {
            for (const child of node.children) {
                let index = arrayCheckedNodesTmp.findIndex(n => n.id === child.id && n.idCorso === child.idCorso);
                arrayCheckedNodesTmp.splice(index, 1);
                uncheckChildren(child);
            }
        }

        passCheckedIdToParent(arrayCheckedNodesTmp);

    };


    const getVisibleNodes = (nodes, depth = 0) => {
        let visibleNodes = [];
        for (const node of nodes) {
            visibleNodes.push({ ...node, depth });
            if (expandedNodes.has(node.id) && node.children) {
                visibleNodes = visibleNodes.concat(getVisibleNodes(node.children, depth + 1));
            }
        }
        return visibleNodes;
    };

    function openDettaglioAlunno(idAlunno, nomeAlunno) {
        if (props.openDettaglioAlunno !== undefined)
            props.openDettaglioAlunno(idAlunno, nomeAlunno)
    }


    const rowRenderer = ({ index, key, parent, style }) => {
        const node = getVisibleNodes(dataList)[index];
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (
                    <div onLoad={measure} style={{ ...style, paddingLeft: node.depth * 20, paddingTop: 10, paddingBottom: 10 }} >
                        {node.children ? (
                            /*Non mostro la chip della sede, tanto non serve. Non capita mai che ci siano più sedi visibili, vengono filtrate tramite la select */
                            (node?.type === "sede" && showSedeChips === false) ? null :
                                (
                                    <Box display={"flex"} alignItems={"center"} >
                                        {checkboxVisible ?
                                            ((node?.type !== "sede" || showSedeChips === true) ? (
                                                <Checkbox indeterminate={checkIfIndeterminateSate(node)} checked={checkIfAllChildrenAreSelected(node)} onChange={() => checkAllChildren(node)} />
                                            ) : (<Box sx={{ ml: 2 }}></Box>))
                                            : null
                                        }

                                        <Chip color={node?.type === "sede" ? 'primary' : 'default'}
                                            variant={expandedNodes.has(node.id) ? "contained" : "outlined"}
                                            onClick={() => handleExpandClick(node)}
                                            label={
                                                <Box display={"flex"} alignItems={"center"} >
                                                    {expandedNodes.has(node.id) ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                                                    <Typography >{node?.codiceCorso !== undefined ? node?.codiceCorso + " - " : ""} {node.name}</Typography>
                                                </Box>
                                            } />
                                    </Box>
                                )
                        ) : (

                            <Box display={"flex"} alignItems={"center"}>
                                {checkboxVisible ? (<Checkbox checked={arrayCheckedNodes.some(n => n.id === node.id && n.idCorso === node.idCorso)} onChange={() => handleCheckClick(node)} />) : null}
                                {node?.type === "studente" ? (
                                    <Typography sx={{ cursor: 'pointer', ml: 2 }} onClick={() => openDettaglioAlunno(node?.id, node?.name)}> {node.name}  (<b>{node?.cf}</b>)</Typography>
                                ) : (
                                    <Typography style={{ color: node.type === "classe" ? "#1976d2" : 'black' }}> {node.name}</Typography>
                                )}
                            </Box>
                        )}
                    </div>
                )}
            </CellMeasurer>
        );
    };


    function passCheckedIdToParent(checkedID) {
        if (props.checkedRow)
            props.checkedRow(checkedID)
    }

    return (
        <Box sx={{ height: { xs: '40vh', md: '50vh' } }}>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        deferredMeasurementCache={cache}
                        height={height}
                        rowCount={getVisibleNodes(dataList).length}
                        rowHeight={cache.rowHeight}
                        rowRenderer={rowRenderer}
                        width={width}
                    />
                )}
            </AutoSizer>
        </Box>

    )
}
