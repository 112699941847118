import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ColloquiAPI = {
    getColloqui: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/getColloqui`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    setColloqui: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/setColloqui`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    riepilogoColloqui: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/riepilogoColloqui`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    riepilogoColloquiCorso: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/riepilogoColloquiCorso`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getAnnotazioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/getAnnotazioni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    addAnnotazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/addAnnotazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editAnnotazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/modificaAnnotazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    addPresenza: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/addPresenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deletePresenza: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/deletePresenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getTipologiePresenze: async function (token, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/colloquiGenitori/getTipologiePresenze`,
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(ColloquiAPI)