import { Box, Typography } from "@mui/material";
import { Description, Search, Edit, Person, FormatListBulleted, CopyAll, Download, Add, Delete, Archive, Start, List } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";


export default function LegendaTirocini(props) {

    const [data, setData] = useState('')

    useEffect(() => {
        if (props.data !== undefined) setData(props.data)
    }, [props.data])

    return (
        <Box sx={{ maxHeight: '40vh' }}>
            <Grid container px={1} pb={{ xs: 2, md: 0 }}>
                <Grid xs={12} md={6}

                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Search color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Apri dettaglio</Typography>
                    </Box>


                </Grid>
                {data === "aziende" && (
                    <Grid xs={12} md={6}>
                        <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                            <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                                border={'1px solid #1976d3'}
                                display={"flex"} alignItems={"center"} justifyContent={"center"}
                                sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                                <Edit color='primary' sx={{ fontSize: '1.8rem' }} />
                            </Box>
                            <Typography>Modifica dati</Typography>
                        </Box>
                    </Grid>
                )}
                {data === 'convenzioni' && (<Grid xs={12} md={6}
                // sx={{
                //     pb: { xs: 4, md: 0 },
                // }}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#9c27b0'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <CopyAll sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>Copia convenzione</Typography>
                    </Box>
                </Grid>)}

                {(data === "aziende") && (
                    <Grid xs={12} md={6}>
                        <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                            <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                                border={'1px solid #1976d3'}
                                display={"flex"} alignItems={"center"} justifyContent={"center"}
                                sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                                <Add color='primary' sx={{ fontSize: '1.8rem' }} />
                            </Box>
                            <Typography>Crea nuova convenzione</Typography>
                        </Box>
                    </Grid>
                )}
                {(data === 'convenzioni' || data === "aziende") && (
                    <Grid xs={12} md={6} >
                        <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                            <Box width={"30px"} height={"30px"} backgroundColor={'#d32f2f'}
                                display={"flex"} alignItems={"center"} justifyContent={"center"}
                                sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                                <Delete sx={{ fontSize: '1.8rem', color: 'white' }} />
                            </Box>
                            <Typography>Elimina{data === "aziende" ? ' azienda' : ' convenzione'}</Typography>
                        </Box>
                    </Grid>
                )}



                {data === 'progetti' && (<Grid xs={12} md={6}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#d32f2f'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Delete sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>Elimina variazione</Typography>
                    </Box>
                </Grid>)}
                {data === 'progetti' && (<Grid xs={12} md={6}
                // sx={{
                //     pb: { xs: 4, md: 0 },
                // }}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #d32f2f'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Archive color="error" sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Archivia progetto formativo</Typography>
                    </Box>
                </Grid>)}
                {data === 'progetti' && (<Grid xs={12} md={6}
                // sx={{
                //     pb: { xs: 4, md: 0 },
                // }}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #9c27b0'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Edit color="secondary" sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Variazione progetto formativo</Typography>
                    </Box>
                </Grid>)}
                {data === 'progetti' && (<Grid xs={12} md={6}
                // sx={{
                //     pb: { xs: 4, md: 0 },
                // }}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #9c27b0'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <List color="secondary" sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Lista variazioni</Typography>
                    </Box>
                </Grid>)}
                {data === 'progetti' && (<Grid xs={12} md={6}
                // sx={{
                //     pb: { xs: 4, md: 0 },
                // }}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #2e7d32'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Start color="success" sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Modifica stato</Typography>
                    </Box>
                </Grid>)}
                {data !== 'aziende' && (<Grid xs={12} md={6}
                // sx={{
                //     pb: { xs: 4, md: 0 }
                // }}
                >

                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#2e7d32'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Download sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>Scarica documentazione</Typography>
                    </Box>
                </Grid>)}
                {(data === 'convenzioni' || data === 'aziende') && (<Grid xs={12} md={6}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <FormatListBulleted sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>{data === 'convenzioni' ? 'Elenco progetti formativi' : 'Elenco convenzioni'}</Typography>
                    </Box>
                </Grid>)}
                {data === 'convenzioni' && (<Grid xs={12} md={6}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Add color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Aggiunta progetto formativo alla convenzione</Typography>
                    </Box>
                </Grid>)}


                {/* <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 2, md: 0 } }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #4caf50'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Typography sx={{ color: '#4caf50', fontSize: '1.8rem', fontWeight: 'bold' }} >P</Typography>
                        </Box>
                        <Typography>Presenza</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Person color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Confermata presenza</Typography>
                    </Box> */}


            </Grid>
        </Box >
    )
}