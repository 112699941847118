import { Table, Box, Dialog, DialogTitle, DialogContent, Paper, Typography, Button, Container, InputLabel, MenuItem, Select, FormControl, TableCell, TableRow, TableHead, TableBody, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment } from "react"
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { ArrowDownward, ArrowUpward, Close } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { EventiAPI } from "../../../api/eventiApi";
import { RicercaAPI } from "../../../api/ricercaApi";
import { convertDataGetProssimeLezione, convertISODate, convertDataDiNascita } from "../../shared/metodiDateUtility";
import ModificaProgrammi from "./modificaArgomenti";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";
export default function ElencoArgomenti(props) {

    const [dataStart, setDataStart] = useState(null);
    const [dataEnd, setDataEnd] = useState(null);
    const [dataLezioneStart, setDataLezioneStart] = useState(null);
    const [dataLezioneEnd, setDataLezioneEnd] = useState(null);
    const [tipoRegistro, setTipoRegistro] = useState("classe")

    const [elencoArgomenti, setElencoArgomenti] = useState([]);
    const [argomentoSel, setargomentoSel] = useState({});
    const [idCorso, setIdCorso] = useState(-1);
    const [idMateria, setIdMateria] = useState(-1);
    const [idMateriaSelected, setIdMateriaSelected] = useState(-1);
    const [elencoMaterie, setElencoMaterie] = useState([]);
    const [permessiUtente, setPermessiUtente] = useState([]);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [riepilogo, setRiepilogo] = useState(false);

    const [isDataCaricamentoUp, setIsDataCaricamentoUp] = useState(true);
    const [isDataLezioneUp, setIsDataLezioneUp] = useState(true);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null);
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null);

    useEffect(() => {
        if (props.riepilogo !== undefined) {
            setRiepilogo(props.riepilogo);
        }
    }, [props.riepilogo])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente]);
        }
    }, [props.permessiUtente])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro)
    }, [props.tipoRegistro])


    useEffect(() => {
        if (props.idCorso !== undefined && props.idMateria !== undefined) {

            let objDateInteresse = getDataInizioFinePeriodo();
            let dataDiInteresseTmp = objDateInteresse?.dataDiInteresse ?? null;
            let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
            setDataInizioPeriodo(inizioPeriodoTmp ?? null);
            let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
            setDataFinePeriodo(finePeriodoTmp ?? null);

            setIdCorso(props.idCorso);
            getMaterieByClasse(props.idCorso);
            if (props.idMateria != null) {
                setIdMateria(props.idMateria);
                setIdMateriaSelected(props.idMateria);
            } else {
                setIdMateria(-1);
                setIdMateriaSelected(-1);
            }
            let showOnlyElenco = props.showOnlyElenco ?? false;
            if (objDateInteresse?.isAnnoFormativoCorrente)
                getElencoArgomenti(props.idCorso, props.idMateria, false, showOnlyElenco)
            else {
                getElencoArgomenti(props.idCorso, props.idMateria, true, showOnlyElenco, true)
            }
        }
    }, [props.idCorso, props.idMateria])

    function changeDataStart(value) {
        setDataStart(value);
    }

    function changeDataEnd(value) {
        setDataEnd(value);
    }
    function changeDataLezioneStart(value) {
        setDataLezioneStart(value)
    }
    function changeDataLezioneEnd(value) {
        setDataLezioneEnd(value)
    }

    function changeMateria(e) {
        setIdMateriaSelected(e.target.value)
    }

    function isSearchButtonDisabled() {
        if (((dataStart == null && dataEnd == null) || (dataStart != null && dataEnd != null)) && (
            (dataLezioneStart == null && dataLezioneEnd == null) || (dataLezioneStart != null && dataLezioneEnd != null)))
            return false
        else return true
    }


    function filtro() {
        getElencoArgomenti(idCorso, idMateriaSelected, false);
    }

    function rimuoviFiltro() {
        setDataEnd(null);
        setDataStart(null);
        setDataLezioneStart(null);
        setDataLezioneEnd(null)
        setIdMateriaSelected(idMateria)
        //TODO:
        let objDateInteresse = getDataInizioFinePeriodo();
        if (objDateInteresse?.isAnnoFormativoCorrente)
            getElencoArgomenti(idCorso, idMateria, true);
        else //Se non è l'anno formativo corrente non posso lasciare tutto vuoto, quindi gli imposto il primo mese come valore id default
            getElencoArgomenti(idCorso, idMateria, true, undefined, true)

    }

    function openDialog(argomento, index) {
        setargomentoSel(argomento);
        setDialogIsOpen(true);
    }

    function closeDialog() {
        setDialogIsOpen(false);
        getElencoArgomenti(idCorso, idMateriaSelected, false);
    }


    async function getElencoArgomenti(idCorso, idMateria, skipDate, showOnlyElenco, getDatePeriodoPassato) {

        let dataStartTmp = null;
        let dataEndTmp = null;
        let dataStartLezioneTmp = null;
        let dataEndLezioneTmp = null;
        let soloInteraClasse = false;

        if (skipDate === undefined || skipDate == null || skipDate == false) {
            if (dataStart != null && dataEnd !== null) {
                let dataConverted = convertDataGetProssimeLezione(dataStart)
                dataStartTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataEnd)
                dataEndTmp = dataConvertedEnd;
            } else {
                setDataStart(null);
                setDataEnd(null)
            }


            if (dataLezioneStart != null && dataLezioneEnd != null) {
                let dataConverted = convertDataGetProssimeLezione(dataLezioneStart)
                dataStartLezioneTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataLezioneEnd)
                dataEndLezioneTmp = dataConvertedEnd;
            }
            else {
                if (!showOnlyElenco) {
                    setDataLezioneStart(null);
                    setDataLezioneEnd(null)
                }
                else {
                    soloInteraClasse = true;
                    if (props.dataRichiesta != null) {
                        let dateObject = DateTime.fromSQL(props.dataRichiesta);
                        let dataConverted = convertDataGetProssimeLezione(dateObject, true)
                        dataStartLezioneTmp = dataConverted;
                        dataEndLezioneTmp = dataConverted;
                        setDataLezioneStart(dateObject)
                        setDataLezioneEnd(dateObject)
                    }
                }
            }
        }

        if (getDatePeriodoPassato !== undefined && getDatePeriodoPassato) { //Se sto in un periodo passato gli pre-seleziono l'ultima settimana
            let objDateInteresse = getDataInizioFinePeriodo();
            let startOfWeek = objDateInteresse?.inizioPeriodo.startOf('month');
            let endOfWeek = objDateInteresse?.inizioPeriodo.endOf('month');

            dataStartLezioneTmp = convertDataGetProssimeLezione(startOfWeek, true);
            dataEndLezioneTmp = convertDataGetProssimeLezione(endOfWeek, true);

            setDataLezioneStart(startOfWeek)
            setDataLezioneEnd(endOfWeek)
        }
        let obj = {
            idCorso: idCorso,
            idMateria: idMateria === -1 ? null : idMateria,
            dataRichiestaDa: dataStartTmp,
            dataRichiestaA: dataEndTmp,
            dataLezioneDa: dataStartLezioneTmp,
            dataLezioneA: dataEndLezioneTmp,
            soloInteraClasse: soloInteraClasse,

        }
        if (idCorso !== -1) {
            EventiAPI.elencoArgomenti(obj)
                .then((result) => {
                    let annotazioniTmp = [];
                    for (let argomento of result) {
                        let argomentoTmp = argomento;
                        if (argomento.contenuto.contenuto.length >= 30) {
                            let descBreve = argomento.contenuto.contenuto.slice(0, 30) + '...';
                            argomentoTmp.contenuto.descrBreve = descBreve;
                        } else argomentoTmp.contenuto.descrBreve = argomento.contenuto.contenuto;


                        annotazioniTmp.push(argomentoTmp);
                    }
                    setElencoArgomenti([...annotazioniTmp])
                })
                .catch((error) => {
                    if (error.response.status === 401) console.log("Errore di autenticazione")
                    if (error.response.status === 403) props.logout();
                })
        }
    }

    async function getMaterieByClasse(idClasse) {


        let dataObj = {
            idCorso: idClasse
        }

        RicercaAPI.getMaterieByClasse(dataObj)
            .then((result) => {
                setElencoMaterie([...result?.materie]);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    function sortElencoArgomenti(dataCaricamento) {
        dataCaricamento ? setIsDataCaricamentoUp(prev => !prev) : setIsDataLezioneUp(prev => !prev)
        // console.log(elencoArgomenti)
        let elencoArgomentiTmp = elencoArgomenti;
        elencoArgomentiTmp.sort((a, b) => {
            let dataA = new Date(dataCaricamento ? a.data_comunicazione : a.contenuto?.dataLezione);
            let dataB = new Date(dataCaricamento ? b.data_comunicazione : b.contenuto?.dataLezione);
            return dataCaricamento ? (isDataCaricamentoUp ? (dataB - dataA) : (dataA - dataB)) : (isDataLezioneUp ? (dataB - dataA) : (dataA - dataB))
        });

        setElencoArgomenti([...elencoArgomentiTmp]);
    }


    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                <Grid container spacing={2} mt={{ xs: 2, md: 0 }}>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataEnd != null ? dataEnd : dataFinePeriodo}
                                label="Periodo (inserimento) dal"
                                value={dataStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataStart != null ? dataStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}

                                // disabled={!modifica}
                                label="Periodo (inserimento) al"
                                value={dataEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>

                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataLezioneEnd != null ? dataLezioneEnd :  dataFinePeriodo}
                                label="Periodo (lezione) dal"
                                value={dataLezioneStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataLezioneStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataLezioneStart != null ? dataLezioneStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}
                                label="Periodo (lezione) al"
                                value={dataLezioneEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataLezioneEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={2.4}>
                        {elencoMaterie.length > 0 ? (
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="materia-label">
                                    {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                    Seleziona Materia</InputLabel>
                                <Select
                                    data-testid='selMateriaSelect'
                                    label='Seleziona Materia '
                                    labelId="materia-label"
                                    value={idMateriaSelected}
                                    onChange={changeMateria}
                                    disabled={elencoMaterie.length === 0}
                                >
                                    <MenuItem key={-1} value={-1}>
                                        Tutte
                                    </MenuItem>
                                    {elencoMaterie.map((materia) => (
                                        <MenuItem key={materia.idMateria} value={materia.idMateria} >
                                            {materia.nomeMateria}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>) : (null)}
                        {elencoMaterie.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta.</Typography>) : null}
                    </Grid>
                    <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box mb={2}>
                            {((dataStart != null && dataEnd == null) || (dataStart == null && dataEnd != null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date di inserimento</b> per applicare il filtro.
                                </Typography>
                            )}
                            {((dataLezioneStart != null && dataLezioneEnd == null) || (dataLezioneStart == null && dataLezioneEnd != null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date di lezione</b> per applicare il filtro.
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: { xs: '100%', md: 'fit-content' } }}>
                            <Button onClick={() => rimuoviFiltro()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="outlined" startIcon={<Close />}
                                disabled={dataLezioneStart === null && dataLezioneEnd === null && dataStart === null && dataEnd === null && idMateria === idMateriaSelected}
                            >
                                Rimuovi filtri
                            </Button>
                            <Button disabled={isSearchButtonDisabled()} onClick={() => filtro()} sx={{ mt: { xs: 1, md: 0 }, ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="contained" startIcon={<FilterList />}>
                                Filtra
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Paper sx={{ minHeight: '20vh', maxHeight: riepilogo ? '38vh' : '32vh', px: 2, overflowY: 'scroll', mt: 4, mb: 1 }}>
                    {elencoArgomenti.length > 0 ? (

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='row-head' sx={{ backgroundColor: 'white' }}>
                                        Data di inserimento
                                        <IconButton sx={{ ml: 0.25 }} onClick={() => sortElencoArgomenti(true)}>
                                            {isDataCaricamentoUp ? <ArrowDownward sx={{ width: '15px', height: '15px' }} /> :
                                                <ArrowUpward sx={{ width: '15px', height: '15px' }} />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className='row-head' sx={{ backgroundColor: 'white' }}>
                                        Data lezione
                                        {/* <IconButton sx={{ ml: 0.25 }} onClick={() => sortElencoArgomenti(false)}>
                                            {isDataLezioneUp ? <ArrowDownward sx={{ width: '15px', height: '15px' }} /> :
                                                <ArrowUpward sx={{ width: '15px', height: '15px' }} />}
                                        </IconButton> */}
                                    </TableCell>
                                    <TableCell className='row-head' sx={{ backgroundColor: 'white' }}>Materia</TableCell>
                                    <TableCell className='row-head' sx={{ backgroundColor: 'white' }}>Descrizione breve</TableCell>
                                    <TableCell className='row-head' align="right" sx={{ backgroundColor: 'white' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {elencoArgomenti.map((argomento, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{convertISODate(argomento?.data_comunicazione)}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {convertISODate(argomento?.contenuto?.dataLezione)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {argomento?.contenuto?.materia?.nomeMateriaBreve}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {argomento?.contenuto?.descrBreve}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            <Button onClick={() => openDialog(argomento, index)} size="small" variant="contained" data-testid='leggiComBtn'>Apri dettaglio</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    ) : (
                        <Box sx={{ width: '100%', height: '20vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography >Nessun argomento inserito nel periodo selezionato</Typography>
                        </Box>
                    )}

                </Paper>
                <Dialog fullWidth maxWidth={"md"} open={dialogIsOpen} onClose={closeDialog} sx={{ mt: '8vh' }}>
                    <DialogTitle>
                        Dettaglio argomento

                    </DialogTitle>
                    <DialogContent>
                        <ModificaProgrammi tipoRegistro={tipoRegistro} permessiUtente={permessiUtente} programmaSel={argomentoSel} closeDialog={closeDialog} />
                    </DialogContent>
                </Dialog>
            </Container>
        </Fragment>
    )



}