import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import RowCorsoGestioneCalendarioColloqui from './rowCorsoGestioneCalendarioColloqui';

export default function TableCalendarioColloqui(props) {

    const [listCorsi, setListCorsi] = useState([]);
    const [indexSalvataggio, setIndexSalvataggio] = useState(0);

    const [dataInizioAnnoFormativo, setDataInizioAnnoFormativo] = useState(null);
    const [dataFineAnnoFormativo, setDataFineAnnoFormativo] = useState(null);
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);

    useEffect(() => {
        if (props.isAnnoFormativoCorrente !== undefined) {
            setIsAnnoFormativoCorrente(props.isAnnoFormativoCorrente);
        }
    }, [props.isAnnoFormativoCorrente]);

    useEffect(() => {
        if (props.dataDA !== undefined) {
            setDataInizioAnnoFormativo(props.dataDA);
        }
    }, [props.dataDA]);

    useEffect(() => {
        if (props.dataA !== undefined) {
            setDataFineAnnoFormativo(props.dataA);
        }
    }, [props.dataA]);

    useEffect(() => {
        if (props.corsi !== undefined) {
            setListCorsi([...props.corsi]);
        }
    }, [props.corsi]);

    useEffect(() => {
        if (props.indexSalvataggio !== undefined) {
            setIndexSalvataggio(props.indexSalvataggio);
        }
    }, [props.indexSalvataggio]);

    function changedDate(idCorso, objPrimoColloquio, objSecondoColloquio) {
        props.changedDate(idCorso, objPrimoColloquio, objSecondoColloquio)
    }

    return (
        <Paper style={{ width: '100%' }}>
            <TableContainer component={Paper} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                <Table sx={{ minWidth: 650 }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Corsi</TableCell>
                            <TableCell align="center">Data prima colloquio</TableCell>
                            <TableCell align="center">Data secondo colloquio</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listCorsi.map((corso, index) => (
                            <RowCorsoGestioneCalendarioColloqui isAnnoFormativoCorrente={isAnnoFormativoCorrente} key={index} indexSalvataggio={indexSalvataggio} corso={corso} changedDate={changedDate} dataDA={dataInizioAnnoFormativo} dataA={dataFineAnnoFormativo} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}


