import { Alert, Box, Button, Card, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, ArrowForward, Check, ExpandLess, ExpandMore } from "@mui/icons-material";
import { getAnnoFormativo } from "../../../shared/utilityMethods";
import CustomDatePicker from "../../../shared/customDatePicker";
import { DateTime } from "luxon";

export default function AnagraficaAzienda(props) {

    const [annoFormativo, setAnnoFormativo] = useState('');
    const [idProgetto, setIdProgetto] = useState(-1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [listSedi, setListSedi] = useState([])

    const [buttonAddSedeLegale, setButtonAddSedeLegale] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    //Azienda
    const [pIva, setPIva] = useState('');
    const [CF, setCF] = useState('');
    const [ragioneSociale, setRagioneSociale] = useState('');
    // const [CFRapLegale, setCFRapLegale] = useState('');
    const [nomeRapLegale, setNomeRapLegale] = useState('');
    const [cognomeRapLegale, setCognomeRapLegale] = useState('');
    const [dataNascitaRapLegale, setDataNascitaRapLegale] = useState(null);
    const [luogoNascitaRapLegale, setLuogoNascitaRapLegale] = useState('');
    const [indirizzoSedeLegale, setIndirizzoSedeLegale] = useState('');
    const [cittaSedeLegale, setCittaSedeLegale] = useState('');
    const [telefonoSedeLegale, setTelefonoSedeLegale] = useState('');
    const [faxSedeLegale, setFaxSedeLegale] = useState('');

    const [sedeOccasionale, setSedeOccasionale] = useState(false);

    const [azienda, setAzienda] = useState(null);
    const [aziendaFormCollapsed, setAziendaLegaleFormCollapsed] = useState(true);

    const [idSedeOperativa, setIdSedeOperativa] = useState(-1);
    const [idSedeOperativaValid, setIdSedeOperativaValid] = useState(true);
    const [sedeOperativa, setSedeOperativa] = useState(null);

    const [isVariazione, setIsVariazione] = useState(false);
    const [isVariazioneSede, setIsVariazioneSede] = useState(false);
    const [dataSede, setDataSede] = useState(null);

    const [dataInizioVariazione, setDataInizioVariazione] = useState(null)
    const [dataFineVariazione, setDataFineVariazione] = useState(null)

    const [dataInizio, setDataInizio] = useState(null)
    const [dataFine, setDataFine] = useState(null)

    useEffect(() => {
        if (props.isVariazione !== undefined) {
            setIsVariazione(props.isVariazione);
        }
    }, [props.isVariazione])

    useEffect(() => {
        if (props.isVariazioneSede !== undefined) {
            setIsVariazioneSede(props.isVariazioneSede);
        }
    }, [props.isVariazioneSede])

    useEffect(() => {
        if (props.dataSede) {
            setDataSede(props.dataSede);
            setDataFineVariazione(props.dataSede?.dataFineVariazione);
            setDataInizioVariazione(props.dataSede?.dataInizioVariazione);
            setIdSedeOperativa(props.dataSede?.sedeOperativa?.idSedeOperativaScelta);
        }
    }, [props.dataSede])

    useEffect(() => {
        if (props.isVisualizza !== undefined) {
            setIsVisualizza(props.isVisualizza)
        }
    }, [props.isVisualizza])


    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            setIdProgetto(props.idProgettoFormativo);
            if (props.idProgettoFormativo !== -1) {
                getAziendaByIdProgetto(props.idProgettoFormativo);
                getDateAttualiProgetto(props.idProgettoFormativo);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idProgettoFormativo])


    useEffect(() => {
        // elencoTitoliStudio();
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        setIsConfermaLoading(false);
    }, []);


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function validateFormData() {
        if (idSedeOperativa !== -1
        ) return false
        else return true
    }

    function changeIdSedeOperativa(e) {
        let valueTmp = e.target.value;
        setIdSedeOperativa(valueTmp)
        if (valueTmp !== -1) {
            setIdSedeOperativaValid(true)
        } else {
            setIdSedeOperativaValid(false)
        }
        let sedeOperativaTmp = listSedi.filter(sede => sede.id === valueTmp);
        setSedeOperativa(sedeOperativaTmp)
    }

    function confermaProsegui() {
        if (props.nextTab) props.nextTab()
    }

    function changeSediOccasionali() {
        setSedeOccasionale(prev => !prev)
    }

    function previousTab() {
        if (props.previousTab) {
            props.previousTab();
        }
    }

    function changeDataInizioVariazione(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataInizioVariazione(utcDateTimeTmp);
    }

    function changeDataFineVariazione(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataFineVariazione(utcDateTimeTmp);
    }


    function closeVariazione() {
        if (props.closeVariazione) props.closeVariazione();
    }

    function changeDataSede() {
        let data = {
            dataInizioVariazione: dataInizioVariazione,
            dataFineVariazione: dataFineVariazione,
            sedeOperativa: {
                idSedeOperativaScelta: idSedeOperativa
            }
        }

        if (props.changeDataSede) props.changeDataSede(data);
        if (props.nextTab) props.nextTab();
    }

    async function ricercaSediAzienda(idAzienda) {
        let obj = {
            "idAzienda": idAzienda,
            "idTipologia": 1 // fisso
        }
        TirociniAPI.ricercaSediAzienda(obj)
            .then((result) => {
                let ListSediTmp = result;
                if (ListSediTmp.length > 0) setButtonAddSedeLegale(false);
                else setButtonAddSedeLegale(true);
                setListSedi([...ListSediTmp])

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
            })

    }

    async function getDateAttualiProgetto(idProgetto) {
        let obj = {
            "idProgetto": idProgetto,
        }
        TirociniAPI.getDateAttualiProgetto(obj)
            .then((result) => {
                setDataFine(result.dataFine);
                setDataInizio(result.dataInizio);
            })
            .catch(function (error) {
            })

    }

    async function getAziendaByIdProgetto(idProgetto) {
        let obj = {
            idProgetto: idProgetto
        }
        TirociniAPI.getAziendaByIdProgetto(obj)
            .then((result) => {
                let objProgetto = result;
                ricercaSediAzienda(objProgetto?.azienda?.azienda?.id);
                setAzienda(objProgetto?.azienda);
                setPIva(objProgetto?.azienda?.azienda?.pIva ?? '');
                setCF(objProgetto?.azienda?.azienda?.CF ?? '');
                setRagioneSociale(objProgetto?.azienda?.azienda?.ragioneSociale ?? '');
                setNomeRapLegale(objProgetto?.azienda?.responsabileLegale?.nome ?? '');
                setCognomeRapLegale(objProgetto?.azienda?.responsabileLegale?.cognome ?? '');
                setDataNascitaRapLegale(objProgetto?.azienda?.responsabileLegale?.dataNascita ?? null);
                setLuogoNascitaRapLegale(objProgetto?.azienda?.responsabileLegale?.luogoNascita ?? '');
                setIndirizzoSedeLegale(objProgetto?.azienda?.sedeLegale?.indirizzo ?? '');
                setCittaSedeLegale(objProgetto?.azienda?.sedeLegale?.citta ?? '');
                setTelefonoSedeLegale(objProgetto?.azienda?.sedeLegale?.telefono ?? '');
                setFaxSedeLegale(objProgetto?.azienda?.sedeLegale?.fax ?? '');
                setSedeOperativa(objProgetto?.idSedeOperativaScelta ?? null);
                setIdSedeOperativa(objProgetto?.idSedeOperativaScelta ?? -1);
                setIsCanEdit(objProgetto?.isEditable ?? null);
                setSedeOccasionale(objProgetto?.sedeOccasionale);
            })
            .catch(function (error) {
            })

    }

    async function saveSedeOperativaAziendaProgetto() {
        setButtonConfermaDisabled(true);
        let obj = {
            idProgetto: idProgetto,
            azienda: azienda,
            idSedeOperativaScelta: idSedeOperativa ?? null,
            sedeOccasionale: sedeOccasionale
        }
        TirociniAPI.saveSedeOperativaAziendaProgetto(obj)
            .then((result) => {
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                confermaProsegui();
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                {(isVariazioneSede && isVariazione) && (<Paper elevation={1} sx={{ p: 2 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Durata variazione</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={1}>
                        <Grid xs={12} md={12}>
                            <Card>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Typography textAlign={"center"} >Durata della variazione</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <CustomDatePicker selectedValue={dataInizioVariazione}
                                                        minDate={dataInizio}
                                                        maxDate={dataFineVariazione}
                                                        // maxDate={dataFineTirocinio !== null ? dataFineTirocinio.minus({ days: 1 }) : null} 
                                                        label={"Giorno di inizio"} isFullWidth={false} onChangeValue={changeDataInizioVariazione} />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <CustomDatePicker selectedValue={dataFineVariazione}
                                                        minDate={dataInizioVariazione}
                                                        isDisabled={dataInizioVariazione === null}
                                                        // minDate={dataInizioTirocinio !== null ? dataInizioTirocinio.plus({ days: 1 }) : null} 
                                                        label={"Giorno di fine"} isFullWidth={false} onChangeValue={changeDataFineVariazione} />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                            {(dataFineVariazione === null || dataInizioVariazione === null) && (
                                <Alert sx={{ mt: 2 }} severity="error">Per proseguire è necessario indicare inizio e fine della variazione.</Alert>
                                // <Typography color={'error'} sx={{ mt: 2 }} variant="caption">Indicare inizio e fine della variazione.</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Paper>)}
                <Paper elevation={1} sx={{ p: 2, mt: (isVariazioneSede && isVariazione) ? 2 : 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Azienda</Typography>
                        </Box>
                        <IconButton onClick={() => setAziendaLegaleFormCollapsed(prev => !prev)}>
                            {!aziendaFormCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Box>
                    <Collapse in={aziendaFormCollapsed}>
                        <Grid container spacing={2} mt={2}>
                            <Grid xs={12} md={6}>
                                <TextField
                                    disabled
                                    defaultValue={ragioneSociale}
                                    value={ragioneSociale}
                                    label={'Ragione sociale'}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    inputProps={{ maxLength: 11 }}
                                    defaultValue={pIva ?? ''}
                                    value={pIva ?? ''}
                                    label={"P.Iva"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={CF ?? ''}
                                    value={CF ?? ''}
                                    label={"C.F."}
                                    sx={{ width: '100%' }}
                                    inputProps={{ maxLength: 16 }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    defaultValue={cittaSedeLegale ?? ''}
                                    value={cittaSedeLegale ?? ''}
                                    label={"Città sede legale"}
                                    sx={{ width: '100%' }}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    defaultValue={indirizzoSedeLegale ?? ''}
                                    value={indirizzoSedeLegale ?? ''}
                                    label={'Indirizzo sede legale'}
                                    sx={{ width: '100%' }}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={telefonoSedeLegale ?? ''}
                                    value={telefonoSedeLegale ?? ''}
                                    label={"Telefono"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={faxSedeLegale ?? ''}
                                    value={faxSedeLegale ?? ''}
                                    label={"Fax"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={nomeRapLegale ?? ''}
                                    value={nomeRapLegale ?? ''}
                                    label={"Nome rappresentante legale"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={cognomeRapLegale ?? ''}
                                    value={cognomeRapLegale ?? ''}
                                    label={'Cognome rappresentante legale'}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <CustomDatePicker
                                    maxDate={DateTime.now()}
                                    label={"Data di nascita rapresentante legale"}
                                    isDisabled
                                    selectedValue={dataNascitaRapLegale ?? null}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={luogoNascitaRapLegale ?? ''}
                                    value={luogoNascitaRapLegale ?? ''}
                                    label={"Luogo di nascita rapresentante legale"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Sede operativa</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={2}>
                        <Grid xs={12} md={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel color={idSedeOperativa === -1 && !idSedeOperativaValid ? 'error' : 'primary'}>
                                    Sede operativa *</InputLabel>
                                <Select
                                    label='Sede operativa *'
                                    value={idSedeOperativa ?? -1}
                                    onChange={changeIdSedeOperativa}
                                    disabled={!isCanEdit && !isVariazione}
                                    error={idSedeOperativa === -1 && !idSedeOperativaValid}
                                >
                                    <MenuItem
                                        key={-1}
                                        value={-1}
                                    >
                                        ...
                                    </MenuItem>
                                    {listSedi.map((sede, index) => (
                                        <MenuItem
                                            key={sede?.id}
                                            value={sede?.id}
                                        >
                                            {(sede?.indirizzo ?? '').toUpperCase() + ', ' + (sede?.cap ?? '').toUpperCase() + ', ' + (sede?.citta ?? '').toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                <Switch
                                    disabled={!isCanEdit}
                                    checked={sedeOccasionale} onChange={changeSediOccasionali} label="" />
                                <Typography>Il tirocinio potrà essere svolto anche presso sedi occazionali connesse all'attività aziendale.</Typography>

                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Divider />
            {
                isVariazione ?
                    (
                        <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                            <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={closeVariazione}
                                variant="outlined" fullWidth color={'error'} >Annulla e chiudi variazione</Button>
                            <Button endIcon={<ArrowForward />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                onClick={changeDataSede}
                                disabled={validateFormData() || (dataFineVariazione === null || dataInizioVariazione === null)}
                                variant="contained" fullWidth color={'success'} >Conferma e prosegui</Button>
                        </Box>
                    )
                    :
                    (
                        <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                            <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={closeDialog}
                                variant="outlined" fullWidth color={'error'} >{isCanEdit ? 'Chiudi procedura di inserimento' : 'Torna a gestione progetto formativo'}</Button>
                            <Button startIcon={<ArrowBack />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={previousTab}
                                variant="contained" fullWidth color={'secondary'} > torna indietro</Button>
                            <Button endIcon={<ArrowForward />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                onClick={() => isCanEdit ? setConfirmAlertIsOpen(true) : confermaProsegui()}
                                disabled={validateFormData()}
                                variant="contained" fullWidth color={'success'} >{isCanEdit ? 'Conferma e prosegui' : 'Prosegui'}</Button>
                        </Box>
                    )}

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma salvataggio'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler salvare i dati relativi alla sede operativa? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveSedeOperativaAziendaProgetto}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}