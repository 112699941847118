import { Box, Button, Typography, Container } from "@mui/material";
import { useState, useEffect, Fragment } from "react"

import AggiuntaVoto from "./aggiuntaVoto";
import InserimentoComunicazioni from "../comunicazioni/inserimentoComunicazioni"
import GestioneCompiti from "./compiti/gestioneCompiti";
import GestioneVerifiche from "./verifiche/gestioneVerifiche";
import GestioneArgomenti from "./argomenti/gestioneArgomenti";
import GestioneAnnotazioni from "./annotazioni/gestioneAnnotazioni";
import GestioneNoteDisciplinari from "./noteDisciplinari/gestioneNoteDisciplinari";
import AggiuntaPrerequisiti from "./aggiuntaPrerequisiti";

export default function AggiuntaEventi(props) {


    const [permessiUtente, setPermessiUtente] = useState([]);

    const [listAlunni, setListAlunni] = useState([]);
    const [listAlunniSelezionati, setListAlunniSelezionati] = useState([]);
    const [isTuttaLaClasse, setIsTuttaLaClasse] = useState(false);
    const [tipoRegistro, setTipoRegistro] = useState('classe');
    const [typeLezione, setTypeLezione] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");

    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [idMateria, setIdMateria] = useState(-1);
    const [dataRichiesta, setDataRichiesta] = useState(null);

    const [mostraSchermataAggiunta, setMostraSchermataAggiunta] = useState(false);
    const [indexComponenteAggiunta, setIndexComponenteAggiunta] = useState(0);
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")
    const [canAddEventsPostPeriodo, setCanAddEventsPostPeriodo] = useState(false);

    const [objPermessiExAnte, setObjPermessiExAnte] = useState({
        insertExAnte: true,
        prerequisitiPassaggio: false,
        prerequisitiRecupero: false
    })

   useEffect(() => {
       if (props.canAddEventsPostPeriodo !== undefined)
           setCanAddEventsPostPeriodo(props?.canAddEventsPostPeriodo ?? false)
    }, [props.canAddEventsPostPeriodo])

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined) {
            setDataInizioPeriodo(props.dataInizioPeriodo)
        }
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.objPermessiExAnte !== undefined) {
            setObjPermessiExAnte(props.objPermessiExAnte)
        }
    }, [props.objPermessiExAnte])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.typeLezione !== undefined) {
            setTypeLezione(props.typeLezione);
        }
    }, [props.typeLezione])

    useEffect(() => {
        if (props.isTuttaClasse !== undefined) {
            setIsTuttaLaClasse(props.isTuttaClasse);
        }
    }, [props.isTuttaClasse])

    useEffect(() => {
        if (props.idMateria !== undefined) {
            setIdMateria(props.idMateria)
        }
    }, [props.idMateria])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
        }
    }, [props.permessiUtente])

    useEffect(() => {
        if (props.idDocente !== undefined) {
            setIdDocente(props.idDocente)
        }
    }, [props.idDocente])

    useEffect(() => {
        if (props.idCorso !== undefined) {
            setIdCorso(props.idCorso)
        }
    }, [props.idCorso])

    useEffect(() => {
        if (props.nomeClasse !== undefined) {
            setNomeClasse(props.nomeClasse)
        }
    }, [props.nomeClasse])

    useEffect(() => {
        if (props.dataRegistro !== undefined)
            setDataRichiesta(props.dataRegistro)
    }, [props.dataRegistro])

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            setListAlunni([...props.listAlunni])
        }
    }, [props.listAlunni])

    useEffect(() => {
        if (props.alunniSelezionati !== undefined) {
            setListAlunniSelezionati([...props.alunniSelezionati])
        }
    }, [props.alunniSelezionati])

    useEffect(() => {
        if (props.tipoRegistro !== undefined) {
            setTipoRegistro(props.tipoRegistro)
        }
    }, [props.tipoRegistro])

    function logout() {
        if (props.logout)
            props.logout()
    }

    function aggiungiCompiti() {
        setIndexComponenteAggiunta(0);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Assegna compiti")

    }
    function aggiungiVerifica() {
        setIndexComponenteAggiunta(1);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Assegna verifica")
    }
    function aggiungiNota() {
        setIndexComponenteAggiunta(2);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Aggiungi nota")
    }

    function aggiungiVoto() {
        setIndexComponenteAggiunta(3);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Assegna voto/valutazione")
    }

    function aggiungiProgramma() {
        setIndexComponenteAggiunta(4);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Assegna programma")
    }

    function aggiungiAnnotazione() {
        setIndexComponenteAggiunta(6);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Aggiungi annotazione")
    }

    function aggiuntaPrerequisiti() {
        setIndexComponenteAggiunta(8);
        setMostraSchermataAggiunta(true);
        if (props.changeIndexComponenteAggiunta)
            props.changeIndexComponenteAggiunta("Aggiungi comunicazione")
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }

    function eventoAggiunto(testo, severity) {
        if (props.eventoAggiunto)
            props.eventoAggiunto(testo, severity);
    }

    return (
        !mostraSchermataAggiunta ? (
            <Fragment>
                <Box className="centered-column-direction">
                    {tipoRegistro === 'docente' && (
                        <Button onClick={aggiungiAnnotazione} fullWidth sx={{ mb: 3 }} color="inherit" variant="contained">
                            Gestione annotazioni
                        </Button>
                    )}
                    {/*Se canAddEventsPostPeriodo è true allora posso inserire solo annotazioni e note */}
                    {!canAddEventsPostPeriodo && (
                        <Fragment>
                            {isTuttaLaClasse && (
                                <Button onClick={aggiungiProgramma} fullWidth sx={{ mb: 3 }} color="success" variant="contained">
                                    Gestione argomenti svolti
                                </Button>
                            )}
                            <Button onClick={aggiungiCompiti} fullWidth sx={{ mb: 3 }} color="primary" variant="contained">
                                Gestione compiti
                            </Button>
                            {tipoRegistro === 'docente' && (
                                <Button onClick={aggiungiVoto} fullWidth sx={{ mb: 3 }} color="secondary" variant="contained">
                                    Gestione voti/valutazioni
                                </Button>
                            )}
                            <Button onClick={aggiungiVerifica} fullWidth sx={{ mb: 3 }} color="warning" variant="contained">
                                Gestione verifiche
                            </Button>
                        </Fragment>
                    )}
                    <Button onClick={aggiungiNota} fullWidth sx={{ mb: 3 }} color="error" variant="contained">
                        Gestione nota disciplinare
                    </Button>
                </Box>
            </Fragment>
        ) : (
            <Fragment>
                {indexComponenteAggiunta === 0 && (
                    <GestioneCompiti dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog} isTuttaLaClasse={isTuttaLaClasse}
                        isDialog={true} listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} logout={logout} tipoRegistro={tipoRegistro} />
                )}
                {indexComponenteAggiunta === 1 && (
                    <GestioneVerifiche dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog} isDialog={true} listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} logout={logout}
                        tipoRegistro={tipoRegistro} isTuttaLaClasse={isTuttaLaClasse} />
                )}
                {indexComponenteAggiunta === 2 && (
                    <GestioneNoteDisciplinari dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog} isTuttaLaClasse={isTuttaLaClasse}
                        isDialog={true} listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} logout={logout} tipoRegistro={tipoRegistro} />
                )}
                {indexComponenteAggiunta === 3 && (
                    <AggiuntaVoto dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} typeLezione={typeLezione} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog}
                        isDialog={true} listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} logout={logout} isTuttaLaClasse={isTuttaLaClasse} tipoRegistro={tipoRegistro} />
                )}
                {indexComponenteAggiunta === 4 && (
                    <GestioneArgomenti dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} nomeClasse={nomeClasse} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog}
                        isDialog={true} listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} logout={logout} isTuttaLaClasse={isTuttaLaClasse} tipoRegistro={tipoRegistro} />
                )}
                {indexComponenteAggiunta === 5 && (
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                        <Typography variant="h4" mt={1} mb={3}>Aggiungi comunicazione</Typography>
                        <InserimentoComunicazioni dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog}
                            isDialog={true} logout={logout} tipoRegistro={tipoRegistro} />
                    </Container>
                )}
                {indexComponenteAggiunta === 6 && (
                    <GestioneAnnotazioni dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} permessiUtente={permessiUtente} nomeClasse={nomeClasse} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog}
                        listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} isDialog={true} logout={logout} isTuttaLaClasse={isTuttaLaClasse} tipoRegistro={tipoRegistro} />
                )}
                {indexComponenteAggiunta === 8 && (
                    <AggiuntaPrerequisiti objPermessiExAnte={objPermessiExAnte} dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} dataRichiesta={dataRichiesta} typeLezione={typeLezione} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idCorso} idMateria={idMateria} eventoAggiunto={eventoAggiunto} closeDialog={closeDialog}
                        isDialog={true} listAlunni={listAlunni} alunniSelezionati={listAlunniSelezionati} logout={logout} isTuttaLaClasse={isTuttaLaClasse} tipoRegistro={tipoRegistro} />
                )}


            </Fragment>
        )
    )
}