import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Typography, useTheme, useMediaQuery, Switch, Alert, } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import Presenza from "../registri/presenza"

import { PresenzeAPI } from "../../api/presenzeApi";
import { DateTime, Interval } from "luxon";


export default function DettaglioValidazione(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("md"));

    const [listAlunni, setListAlunni] = useState([]);
    // const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);
    const [listValidati, setListValidati] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [allPresenzeAreValidate, setAllPresenzeAreValidate] = useState(true);

    const [idDocente, setIdDocente] = useState(-1)

    const [dataRichiestaSql, setDataRichiestaSql] = useState("");
    const [data, setData] = useState("");

    const [corso, setCorso] = useState({});
    const [showAppelloNonFatto, setShowAppelloNonFatto] = useState(true);


    useEffect(() => {
        if (props.listAlunni !== undefined && props.dataRichiesta !== undefined) {

            if (props.dataRichiesta !== '') {
                let dataTmp = props.dataRichiesta.split('-').reverse();
                setData(dataTmp[0] + '/' + dataTmp[1] + '/' + dataTmp[2]);


                setDataRichiestaSql(props.dataRichiesta);
                //setListAlunni([...props.listAlunni])
                getPresenzeDettaglio(props.listAlunni, props.dataRichiesta);
            }

        }
    }, [props.listAlunni, props.dataRichiesta])

    useEffect(() => {
        setCorso(props.corso);
    }, [props.corso])

    useEffect(() => {
        setIdDocente(props.idDocente);
    }, [props.idDocente])

    function closeModal() {
        if (props.closeModal)
            props.closeModal();
    }

    function presAssValidate(alertMsg, alertSeverity) {

        if (props.presAssValidate) props.presAssValidate(alertMsg, alertSeverity)
    }

    function allValid() {
        if (listAlunni.length !== listValidati.length) {
            let listaValidiTmp = []
            for (let a of listAlunni) {
                listaValidiTmp.push(a.id)
            }
            setListValidati([...listaValidiTmp])
        }
        else {
            let listaValidiTmp = []
            listaValidiTmp = listAlunni.filter((alunno) => alunno?.presenza?.validata === true); //Toglie tutti quelli che non sono validati
            let arrayID = [];
            for (let a of listaValidiTmp) {
                arrayID.push(a.id)
            }
            setListValidati([...arrayID])
        }
    }

    function selectValidazione(e, idAlunno) {
        let listaValidi = listValidati;
        if (listaValidi.includes(idAlunno)) {
            let index = listaValidi.findIndex((alunno) => alunno === idAlunno);
            listaValidi.splice(index, 1);
        } else {
            listaValidi.push(idAlunno)
        }
        setListValidati([...listaValidi])
    }



    function isAlunnoIscrittoInData(dataCella, alunno) {
        let dataSql = DateTime.fromSQL(dataCella);
        let dataConvertedIso = dataSql.toUTC().toISO()
        let iscrittoDalIso = alunno?.iscrittoDal;
        let iscrittoAlIso = alunno?.iscrittoAl;

        let startDate = DateTime.fromISO(iscrittoDalIso);
        let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
        let someDate = DateTime.fromISO(dataConvertedIso);
        let interval = Interval.fromDateTimes(startDate, endDate);
        if (interval.contains(someDate)) {
            return true
        } else return false;
    }

    async function getPresenzeDettaglio(alunniTmp, dataRichiesta) {
        let idAlunni = [];

        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataObj = {
            "data": dataRichiesta,
            "idAlunno": idAlunni
        }

        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                let newAlunniArray = alunniTmp;
                let alunniValidati = [];
                if (result.alunni.length === 0)
                    setShowAppelloNonFatto(false)
                if (result.alunni.length > 0) {
                    let arrayAppello = result.alunni;
                    for (let i = 0; i < newAlunniArray.length; i++) {
                        for (let j = 0; j < arrayAppello.length; j++) {
                            if (newAlunniArray[i].id === arrayAppello[j].idAlunno) {
                                let uscitaConverted = null;
                                let ingressoConverted = null;
                                if (arrayAppello[j].orario_uscita_reale != null)
                                    uscitaConverted = arrayAppello[j].orario_uscita_reale.substring(0, arrayAppello[j].orario_uscita_reale.length - 3)
                                if (arrayAppello[j].orario_ingresso_reale)
                                    ingressoConverted = arrayAppello[j].orario_ingresso_reale.substring(0, arrayAppello[j].orario_ingresso_reale.length - 3)

                                let objPresenza = {
                                    id: arrayAppello[j].id,
                                    data: arrayAppello[j].data,
                                    statoPresenza: arrayAppello[j].stato_presenza,
                                    note: arrayAppello[j].note,
                                    orario_uscita_reale: uscitaConverted,
                                    orario_ingresso_reale: ingressoConverted,
                                    validata: arrayAppello[j].validata
                                }
                                newAlunniArray[i].presenza = objPresenza;
                                if (arrayAppello[j].validata)
                                    alunniValidati.push(newAlunniArray[i].id)

                            }
                        }
                    }

                    for (let i = 0; i < newAlunniArray.length; i++) {
                        if (newAlunniArray[i].presenza === undefined) {
                            newAlunniArray[i].presenza = { statoPresenza: "ND" }
                        }
                    }

                    if (alunniValidati.length > 0 && alunniValidati.length === (alunniTmp.length - isAlunnoIscrittoInDataList(dataRichiesta, alunniTmp)))
                        setAllPresenzeAreValidate(true)
                    else setAllPresenzeAreValidate(false)

                    setListValidati([...alunniValidati]);
                    setListAlunni([...newAlunniArray])
                    setHasLoaded(true)
                }
            })
            .catch((error) => {

                console.error("Errore durante il get delle presenze")

            })
    }

    function isAlunnoIscrittoInDataList(data, listAlunni) {
        if (data !== null) {
            let dataSql = DateTime.fromSQL(data);
            let dataConvertedIso = dataSql.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp.length;
        }
    }



    async function validaPresenze() {

        let listValidatiFiltered = [];
        for (let i = 0; i < listAlunni.length; i++) {
            if (!listAlunni[i]?.presenza?.validata && listAlunni[i]?.presenza?.statoPresenza !== "ND" && listValidati.includes(listAlunni[i]?.id) && isAlunnoIscrittoInData(dataRichiestaSql, listAlunni[i])) //Se non è stata validata in precedenza
                listValidatiFiltered.push(listAlunni[i]?.id)
        }

        let dataObj = {
            data: dataRichiestaSql,
            idAlunno: listValidatiFiltered,
            idUtenteAggiornamento: idDocente
        };

        PresenzeAPI.validaPresenze(dataObj)
            .then((result) => {
                presAssValidate("Valutazione aggiunta", "success");
                closeModal()
            })
            .catch((error) => {
                presAssValidate("Al momento non è possibile completare l'operazione", "error");
            })
    }

    return (
        showAppelloNonFatto ? (
            <Grid container rowSpacing={2} columnSpacing={2}>
                {hasLoaded ? (
                    <Fragment>
                        {/* <Grid xs={12} md={4} sx={{ width: '100%', pt: 2 }}>
                            <TextField data-testid='dataRichiestaDettaglioInput' disabled
                                label="Data" value={data} sx={{ width: '100%' }} />
                        </Grid> */}
                        {tablet && (<Grid xs={12} md={8} sx={{ width: '100%', pt: { xs: 1, md: 3 }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'end' }}>
                            <Button sx={{ width: { xs: '100%', md: 'unset' }, mr: { xs: 0, md: 1 } }} variant='outlined' color="error" onClick={closeModal}>
                                Annulla e torna al riepilogo
                            </Button>
                            {!allPresenzeAreValidate ? (
                                <Button sx={{ mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="contained" onClick={allValid}>
                                    {listValidati.length === listAlunni.length ? 'Deseleziona' : 'Seleziona'} tutta la classe
                                </Button>) :
                                null}
                        </Grid>)}
                        <Grid xs={12} sx={{ width: '100%', pt: 2 }}>
                            <Typography variant="caption" fontWeight={'bold'}>Corso:</Typography>
                            <Typography color={'primary'}
                                variant={mobile ? 'caption' : 'body1'}
                                sx={{ fontWeight: 'bold', mr: 1 }}>{corso?.codiceCorso + ' - ' + (corso?.nomeCorso ?? "").toUpperCase() + ' (' + corso?.codiceSimon + ')'}</Typography>
                        </Grid>
                        <Grid xs={12} >
                            <TableContainer component={Paper} sx={{ mt: 2, height: { xs: 'unset', md: '60vh' }, overflow: { xs: 'unset', md: 'scroll' } }}>
                                <Table>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{ backgroundColor: 'white', p: 2 }} display={'flex'} flexDirection={'column'}>
                                                    <Typography variant="caption" fontWeight={'bold'}>Data:</Typography>
                                                    <Typography color={'secondary'}
                                                        variant={mobile ? 'caption' : 'body1'}
                                                        sx={{ fontWeight: 'bold', mr: 1 }}>{data}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
                                                    <Typography
                                                        textAlign={"center"}
                                                        variant={mobile ? 'caption' : 'body1'}
                                                        sx={{ fontWeight: 'bold', mr: 1, width: '100%' }}>{'Pres/Ass'.toUpperCase()}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
                                                    <Typography
                                                        textAlign={"center"}
                                                        variant={mobile ? 'caption' : 'body1'}
                                                        sx={{ fontWeight: 'bold', mr: 1, width: '100%' }}>{'Valida'.toUpperCase()}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
                                                    <Typography
                                                        textAlign={"center"}
                                                        variant={mobile ? 'caption' : 'body1'}
                                                        sx={{ fontWeight: 'bold', mr: 1, width: '100%' }}>{'Validata'.toUpperCase()}</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        {listAlunni.map((alunno, index) => (
                                            <TableRow key={alunno.id} >
                                                <TableCell
                                                    className={(isAlunnoIscrittoInData(dataRichiestaSql, alunno) === false) ? 'colonna- nomi disabledCell' : "colonna-nomi"}
                                                    sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', px: 2 }}>
                                                        <Box display={"flex"} alignItems={"center"}>
                                                            <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                                            <Typography variant={mobile ? 'caption' : 'body1'} sx={{ mr: 2, fontWeight: alunno.isMaggiorenne ? 'bold' : 'unset' }}>
                                                                {(alunno.cognome).toUpperCase() + " " + (alunno.nome).toUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                        {/* {alunno.msgIscrizione != null ? (
                                                        <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                    ) : null} */}
                                                    </Box>
                                                    {alunno?.msgIscrizione != null ? (
                                                        <Box sx={{ px: 2, backgroundColor: 'white' }}>
                                                            <Typography color="primary" variant="subtitle2">{alunno?.msgIscrizione}</Typography>
                                                        </Box>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell
                                                    className={(isAlunnoIscrittoInData(dataRichiestaSql, alunno) === false) ? 'colonna- nomi disabledCell' : "colonna-nomi"}
                                                    sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno.presenza !== undefined && isAlunnoIscrittoInData(dataRichiestaSql, alunno) ? (
                                                        <Presenza alunno={alunno} isRegistroDettaglio={true} />
                                                    ) : null}
                                                </TableCell>
                                                <TableCell
                                                    className={(isAlunnoIscrittoInData(dataRichiestaSql, alunno) === false) ? 'colonna- nomi disabledCell' : "colonna-nomi"}
                                                    sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', alignItems: 'center' }}>
                                                    {alunno?.presenza !== undefined && alunno?.presenza?.statoPresenza !== "ND" && isAlunnoIscrittoInData(dataRichiestaSql, alunno) ? (

                                                        <Box className="spacebetween-direction" sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Switch disabled={alunno?.presenza.validata} checked={listValidati.includes(alunno.id)} onChange={(e) => { selectValidazione(e, alunno.id) }}></Switch>
                                                        </Box>
                                                    ) : null}

                                                </TableCell>
                                                <TableCell
                                                    className={(isAlunnoIscrittoInData(dataRichiestaSql, alunno) === false) ? 'colonna- nomi disabledCell' : "colonna-nomi"}
                                                    sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {alunno?.presenza?.validata !== undefined && isAlunnoIscrittoInData(dataRichiestaSql, alunno) ? (

                                                        <Typography color={alunno?.presenza?.validata ? "primary" : 'black'} textAlign={"center"}>{alunno?.presenza?.validata ? "Sì" : "No"}</Typography>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid xs={12}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} sx={{ height: '50vh', width: '100%' }} >
                            <CircularProgress />
                            <Typography sx={{ mt: 2 }} textAlign={"center"}>Caricamento...</Typography>
                        </Box>
                    </Grid>
                )}
                {allPresenzeAreValidate ? (
                    <Grid xs={12} md={12} sx={{ width: '100%', pt: { xs: 1, md: 3 }, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <Button sx={{ width: { xs: '100%', md: 'unset' } }} variant='contained' color="primary" onClick={closeModal}>
                            Chiudi dettaglio
                        </Button>
                    </Grid>
                ) : (
                    <Grid xs={12} md={12} sx={{ width: '100%', pt: { xs: 1, md: 3 }, flexDirection: { xs: 'column', md: 'row' }, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <Button sx={{ width: { xs: '100%', md: 'unset' }, mt: { xs: 1, md: 0 } }} variant='outlined' color="error" onClick={closeModal}>
                            Annulla e torna al riepilogo
                        </Button>
                        {!allPresenzeAreValidate ? (
                            <Button sx={{ width: { xs: '100%', md: 'unset' }, ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 } }} variant="contained" onClick={allValid}>
                                {listValidati.length === listAlunni.length ? 'Deseleziona' : 'Seleziona'} tutta la classe
                            </Button>) :
                            null}
                        <Button sx={{ width: { xs: '100%', md: 'unset' }, ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 } }} variant="contained" color="success" onClick={validaPresenze} disabled={!listValidati.length > 0}>
                            Conferma validazione
                        </Button>
                    </Grid>
                )}

            </Grid >
        ) : (
            <Fragment>
                <Alert severity="error">Nella data selezionata non è stato effettuato l'appello.</Alert>
                <Button sx={{ mt: 2 }} fullWidth variant='contained' color="primary" onClick={closeModal}>Torna alla vista di sintesi</Button>
            </Fragment>
        )
    )
}