import { useState, useEffect, Fragment } from "react"
import { Alert, Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../shared/utilityMethods";
import CryptoJS from "crypto-js";
import { TirociniAPI } from "../../api/tirociniApi";
import { ArrowBack, Home } from "@mui/icons-material";
import CustomDatePicker from "../shared/customDatePicker";
import { RicercaAPI } from "../../api/ricercaApi";

export default function ElencoCorsiAndTutor(props) {

    const [hasLoaded, setHasLoaded] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState(-1);

    const [sediList, setSediList] = useState([]);
    const [idSedeSelezionata, setIdSedeSelezionata] = useState(-1);

    const [listCorsi, setListCorsi] = useState([]);

    const [showDateRange, setShowDateRange] = useState(false);
    const [dataDiInteresse, setDataDiInteresse] = useState(null);

    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [nomeCorsoSelezionato, setNomeCorsoSelezionato] = useState("");
    const [codiceCorsoSelezionato, setCodiceCorsoSelezionato] = useState("");
    const [sedeSelezionata, setSedeSelezionata] = useState("");

    const [showSelectTutorTab, setShowSelectTutorTab] = useState(false);
    const [listTutor, setListTutor] = useState([]);
    const [idFormatoreSelezionato, setIdFormatoreSelezionato] = useState(-1);

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp); //All'interno del getSedi, c'è il getCorsi
    }, [])

    useEffect(() => {
        if (props.showDateRange !== undefined) {
            setShowDateRange(props.showDateRange);
            if (props.showDateRange === true) {
                // if (props.dataDiInteresseInizio === undefined || props.dataDiInteresseInizio === null){
                let objDateInteresse = getDataInizioFinePeriodo();
                setDataDiInteresse(objDateInteresse?.dataDiInteresse ?? null);
                // } else {
                //     setDataDiInteresse(props.dataDiInteresseInizio);
                // }
            }
        }
    }, [props.showDateRange, props.dataDiInteresseInizio, props.dataDiInteresseFine])

    function changeDataDiInteresse(value) {
        setDataDiInteresse(value);
    }

    function changeSede(e) {
        setIdSedeSelezionata(e.target.value);
        getElencoCorsi(annoFormativo, e.target.value)
    }

    function goToHome() {
        if (props.goToHome) props.goToHome();
    }

    function selectCorso(idCorso, nomeCorso, codiceCorso, nomeSede) {
        setIdCorsoSelezionato(idCorso);
        setNomeCorsoSelezionato(nomeCorso)
        setCodiceCorsoSelezionato(codiceCorso)
        setSedeSelezionata(nomeSede)
    }

    function selectFormatore(idFormatore) {
        setIdFormatoreSelezionato(idFormatore)
    }

    function changeIdCorsoSelezionato() {
        setShowSelectTutorTab(true);
        getElencoTutor();

    }

    function goBackToSelezioneCorso() {
        setListTutor([]);
        setShowSelectTutorTab(false);
        setIdFormatoreSelezionato(-1);
    }

    function confirmCorsoAndFormatore() {
        if (props.changeCorsoSelezionato)
            props.changeCorsoSelezionato(idCorsoSelezionato, nomeCorsoSelezionato, codiceCorsoSelezionato, "", sedeSelezionata, dataDiInteresse, idFormatoreSelezionato)
    }


    async function getSedi(annoFormativo) {
        if (localStorage.getItem("sede")) {
            let idSede = localStorage.getItem("sede");
            let decryptedIdSede = CryptoJS.AES.decrypt(idSede, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let parsedSede = JSON.parse(decryptedIdSede);
            decryptedIdSede = parseInt(parsedSede.id);
            if (decryptedIdSede === 1) { //Sede legale
                RicercaAPI.getSedi({ annoFormativo: annoFormativo })
                    .then((result) => {
                        if (result.sedi.length === 1) {
                            setIdSedeSelezionata(result?.sedi[0]?.id);
                            getElencoCorsi(annoFormativo, result?.sedi[0]?.id)
                        } else {
                            getElencoCorsi(annoFormativo, decryptedIdSede);
                        }
                        setSediList([...result.sedi])
                    })
            } else {
                let sediListTmp = [{
                    id: parsedSede?.id,
                    nomeSede: parsedSede.nome
                }]
                setIdSedeSelezionata(parsedSede?.id);
                setSediList([...sediListTmp])
                getElencoCorsi(annoFormativo, parsedSede?.id,);
            }

        }
    }
    async function getElencoCorsi(annoFormativoTmp, idSedeTmp) {
        let dataObj = {
            idSede: idSedeTmp,
            annoFormativo: annoFormativoTmp
        }
        TirociniAPI.getElencoCorsiFormatori(dataObj)
            .then((result) => {
                // Rimuovo eventuali duplicati
                setHasLoaded(true);
                setHasErrors(false);
                setListCorsi([...result]);
            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    setHasErrors(true);
                }
            })
    }
    async function getElencoTutor() {
        let dataObj = {
            idCorso: idCorsoSelezionato
        }
        TirociniAPI.elencoTutorCorso(dataObj)
            .then((result) => {
                setListTutor([...result]);
            })
            .catch(function (error) {
                if (error.response) {
                    setListTutor([])
                }
            })
    }

    return (
        <Fragment>
            {hasLoaded ? (
                !hasErrors ? (
                    <Fragment>
                        {!showSelectTutorTab ? (
                            <Box>
                                <Box display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
                                    {showDateRange && (
                                        <CustomDatePicker
                                            getDatePeriodo={true}
                                            selectedValue={dataDiInteresse}
                                            onChangeValue={changeDataDiInteresse}
                                            label="Data di interesse"
                                            isFullWidth={false}
                                        />
                                    )}
                                    {sediList.length > 1 && ( /*Se c'è una sola sede non mostro il select */
                                        <FormControl sx={{ width: '100%', ml: showDateRange === false ? 3 : 0 }}>
                                            <InputLabel id="sede-label">
                                                Sede
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                disabled={sediList.length <= 1}
                                                data-testid='sedeRicercaSelect'
                                                label="Sede"
                                                labelId="sede-label"
                                                value={idSedeSelezionata}
                                                onChange={changeSede}
                                            >
                                                {sediList.map((sede) => (
                                                    <MenuItem
                                                        key={sede.id}
                                                        value={sede.id}
                                                    >
                                                        {sede.nomeSede}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                                {listCorsi.length > 0 ? (
                                    <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
                                        <Table >
                                            <TableBody>
                                                {listCorsi.map((corso) => (
                                                    <TableRow key={corso?.corso?.idCorso} sx={{ cursor: 'pointer' }} onClick={() => selectCorso(corso?.corso?.idCorso, corso?.corso?.nomeCorso, corso?.corso?.codiceCorso, corso?.sede?.nomeSede)}>
                                                        <TableCell sx={{ minWidth: '200px', display: "flex", alignItems: 'center', background: (corso?.corso?.idCorso === idCorsoSelezionato) ? '#1976D2' : 'white', color: (corso?.corso?.idCorso === idCorsoSelezionato) ? 'white' : 'black' }}>

                                                            {/* {corso?.corso?.idCorso === idCorsoSelezionato ? (
                                                        <CheckBox sx={{ mr: 1 }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlank sx={{ mr: 1 }} />
                                                    )} */}
                                                            <Typography>
                                                                {corso?.corso?.codiceCorso ? corso?.corso?.codiceCorso + " - " : ""}{corso?.corso?.nomeCorso ?? ""} {corso?.corso?.codiceSimon ? "(" + corso?.corso?.codiceSimon + ") " : ""} - {corso?.sede?.nomeSede}
                                                                {/* {(corso?.corso?.idCorso === idCorsoSelezionato) ? "- SELEZIONATO" : ""} */}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                ) : (
                                    <Alert severity="info">Nessun corso associato all'utente.</Alert>
                                )}
                                <Button disabled={idCorsoSelezionato === -1} onClick={changeIdCorsoSelezionato} sx={{ mt: 2 }} fullWidth variant="contained" color="success">Conferma corso e procedi</Button>
                                <Button onClick={goToHome} sx={{ mt: 2 }} startIcon={<Home />} fullWidth variant="outlined">Torna alla home</Button>
                            </Box>
                        ) : (
                            <Box>
                                <Typography sx={{ mb: 2 }}><b>Corso</b>: <span style={{ color: '#1976d2' }}>{nomeCorsoSelezionato}</span></Typography>
                                <Divider sx={{ mb: 1 }} />
                                {listTutor.length > 0 ? (
                                    <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
                                        <Table >
                                            <TableBody>
                                                {listTutor.map((tutor) => (
                                                    <TableRow key={tutor?.idFormatore} sx={{ cursor: 'pointer' }} onClick={() => selectFormatore(tutor?.idFormatore)}>
                                                        <TableCell sx={{ minWidth: '200px', display: "flex", alignItems: 'center', background: (tutor?.idFormatore === idFormatoreSelezionato) ? '#1976D2' : 'white', color: (tutor?.idFormatore === idFormatoreSelezionato) ? 'white' : 'black' }}>
                                                            <Typography>
                                                                {tutor?.codiceFormatore} - {tutor?.nome} {tutor?.cognome}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                ) : (
                                    <Alert severity="info">Nessun formatore associato al corso selezionato.</Alert>
                                )}
                                <Button disabled={idFormatoreSelezionato === -1} onClick={confirmCorsoAndFormatore} sx={{ mt: 2 }} fullWidth variant="contained" color="success">Conferma formatore e procedi</Button>
                                <Button onClick={goBackToSelezioneCorso} sx={{ mt: 2 }} startIcon={<ArrowBack />} fullWidth variant="outlined">Torna alla selezione del corso</Button>
                            </Box>
                        )}
                    </Fragment>
                ) : (
                    <Alert severity="error"> Errore durante il recupero dei corsi. Riprovare tra qualche momento. </Alert>
                )
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                    <CircularProgress />
                </Box>
            )}
        </Fragment>
    )
}