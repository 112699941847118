import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useEffect, useState } from "react";
import { DateTime } from 'luxon';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

/**
*
*
 * @param {boolean} disableKeyboard -Se impostato a true disabilita la keyboard.
 * @param {boolean} isStyleMod -Se impostato a true versione più piccola del timePicker.
 * @param {string | null} label - Label del timePicker.
 * @param {obj} position - Posizione del timePicker nel componente.
 * @param {boolean} showError -Se impostato a true consente di mostrare errore.
 * @param {boolean} isDisabled -Se impostato a true il timePicker è disabilitato.
 * @param {boolean} maxTimeMinusFive -Se impostato a true tolgo 5 minuti al maxTime.
 * @param {boolean} minTimeMinusFive -Se impostato a true tolgo 5 minuti al minTime.
 * @param {string} referenceDate - Imposta valore date di riferimento (dateUTC).
 * @param {string} value - Imposta valore time di riferimento (dateUTC).
 * @param {string} minTime - Imposta valore minimo time (dateUTC).
 * @param {string} maxTime - Imposta valore massimo time (dateUTC).
 * @param {string} showResetButton - Mostra il pulsante per resettare il valore
 * @param {function(obj,obj)} timeChanged - Funzione in output richiamata al cambio del valore del timePicker, variabili: value = valore modificato e position = Posizione del timePicker nel componente.
  * @param {function()} resetValue - Funzione in output che resetta il valore.

*/
export default function CustomTimePicker(props) {

    const [isDisabled, setIsDisabled] = useState(false);
    const [value, setValue] = useState(null);
    const [referenceDate, setReferenceDate] = useState(null);
    const [minTime, setMinTime] = useState(null)
    const [maxTime, setMaxTime] = useState(null)
    const [label, setLabel] = useState('')
    const [isStyleMod, setIsStyleMod] = useState(false)
    const [data, setData] = useState(null);
    const [increaseDataHour, setIncreaseDataHour] = useState(true);

    const [position, setPosition] = useState(null)
    const [showError, setShowError] = useState(false);

    const [maxTimeMinusFive, setMaxTimeMinusFive] = useState(false); //Se impostata a true toglie 5 minuti dal maxDate
    const [minTimeMinusFive, setMinTimeMinusFive] = useState(false);

    const [disableKeyboard, setDisableKeyboard] = useState(false);
    const [showResetButton, setShowResetButton] = useState(false);
    const [isFullWidth, setIsFullWidth] = useState(false);

    useEffect(() => {
        if (props.data !== undefined) setData(props.data)
    }, [props.data])


    useEffect(() => {
        if (props.increaseDataHour !== undefined)
            setIncreaseDataHour(props.increaseDataHour);
    }, [props.increaseDataHour])

    useEffect(() => {
        if (props.isFullWidth !== undefined)
            setIsFullWidth(props.isFullWidth);
    }, [props.isFullWidth])

    useEffect(() => {
        if (props.disableKeyboard !== undefined) setDisableKeyboard(props.disableKeyboard)
    }, [props.disableKeyboard])

    useEffect(() => {
        if (props.isStyleMod !== undefined) setIsStyleMod(props.isStyleMod)
    }, [props.isStyleMod])

    useEffect(() => {
        if (props.label !== undefined) setLabel(props.label)
    }, [props.label])

    useEffect(() => {
        if (props.position !== undefined) setPosition(props.position)
    }, [props.position])

    useEffect(() => {
        if (props.showError !== undefined) setShowError(props.showError)
    }, [props.showError])

    useEffect(() => {
        if (props.showResetButton !== undefined) setShowResetButton(props.showResetButton);
    }, [props.showResetButton])

    useEffect(() => {
        if (props.maxTimeMinusFive !== undefined)
            setMaxTimeMinusFive(props.maxTimeMinusFive)
    }, [props.maxTimeMinusFive])

    useEffect(() => {
        if (props.minTimeMinusFive !== undefined)
            setMinTimeMinusFive(props.minTimeMinusFive)
    }, [props.minTimeMinusFive])

    useEffect(() => {
        if (props.isDisabled !== undefined) {
            setIsDisabled(props.isDisabled)
        }
    }, [props.isDisabled])

    useEffect(() => {
        if (props.referenceDate !== undefined)
            if (props.referenceDate !== null) {
                setReferenceDate(DateTime.fromISO(props.referenceDate))
            }
    }, [props.referenceDate])

    useEffect(() => {
        if (props.value !== undefined) {
            if (props.value !== null)
                setValue(DateTime.fromISO(props.value));
            else setValue(null)
        }
    }, [props.value])

    useEffect(() => {
        if (props.minTime !== undefined) {
            if (props.minTime !== null) setMinTime(DateTime.fromISO(props.minTime));
            else setMinTime(null)
        }
    }, [props.minTime])

    useEffect(() => {
        if (props.maxTime !== undefined) {
            if (props.maxTime !== null)
                setMaxTime(DateTime.fromISO(props.maxTime));
            else setMaxTime(null)
        }
    }, [props.maxTime])

    function resetValue() {
        if (props.resetValue) props.resetValue();
    }

    function changeTime(e) {
        let valueTmp = e;
        if (data !== undefined && data !== null) {
            let dataLezione = DateTime.fromISO(data, { zone: 'utc' });
            let newDateTime;
            if (increaseDataHour === true)
                newDateTime = dataLezione.plus({ days: 1 })
            else {
                newDateTime = dataLezione;
            }
            let yearTmp = newDateTime.year;
            let monthTmp = newDateTime.month;
            let dayTmp = newDateTime.day;
            valueTmp = valueTmp.set({ year: yearTmp, month: monthTmp, day: dayTmp });
        }
        // if (maxTime !== null && valueTmp > maxTime) { //TODO: Sto controllo rompe il cazzo in una maniera impressionante
        //     valueTmp = maxTime;
        // }
        // if (minTime !== null && valueTmp < minTime){
        //     valueTmp = minTime}
        setValue(valueTmp)
        if (props.timeChanged) props.timeChanged(valueTmp, position);
    }

    function maxTimeFunction() {
        if (maxTime != null) {
            if (maxTimeMinusFive === true) {
                return maxTime.minus({ minutes: 5 })
            } else return maxTime
        } else return null;
    }

    function minTimeFunction() {
        if (minTime != null) {
            if (minTimeMinusFive === true) {
                return minTime.plus({ minutes: 5 })
            } else return minTime
        } else return null;
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", width: isFullWidth ? '100%' : 'unset' }}>

            <LocalizationProvider dateAdapter={AdapterLuxon}>
                {isStyleMod ?
                    (<DesktopTimePicker
                        slotProps={{
                            textField: {
                                error: showError,
                                size: 'small', readOnly: disableKeyboard
                            },
                        }}
                        label={label}
                        views={['hours', 'minutes']}
                        format="HH:mm"
                        ampm={false}
                        value={value}
                        onChange={changeTime}
                        maxTime={maxTimeFunction()}
                        minTime={minTimeFunction()}
                        referenceDate={referenceDate}
                        // onError={isError}
                        sx={{
                            width: '100%',
                            '& .MuiSvgIcon-root': {
                                backgroundColor: 'none',
                                width: '100%',
                                display: 'none'
                            },
                            '& .MuiIconButton-root': {
                                position: 'absolute',
                                borderRadius: '0',
                                backgroundColor: 'transparent',
                                width: '100%',
                                height: '40px'
                            },
                            '& .MuiInputAdornment-root': {
                                position: 'absolute',
                                marginRight: '8px',
                                marginLeft: '0px',
                                width: '100%'

                            },
                            '& .MuiInputBase-root': {
                                padding: '0px',
                                borderRadius: (showResetButton && value !== null) ? '5px 0px 0px 5px' : '5px',
                            },
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                            '& .MuiOutlinedInput-input': {
                                fontSize: '0.9rem',
                                padding: '2px 0px 2px 2px',
                            },
                        }}
                        disabled={isDisabled}
                    />) : (
                        <DesktopTimePicker
                            slotProps={{
                                textField: {
                                    // error: showError,
                                    size: 'small', readOnly: disableKeyboard
                                },
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiSvgIcon-root': {
                                    backgroundColor: 'none',
                                    width: '100%',
                                    display: 'none'
                                },
                                '& .MuiIconButton-root': {
                                    position: 'absolute',
                                    borderRadius: '0',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    height: '40px'
                                },
                                '& .MuiInputAdornment-root': {
                                    position: 'absolute',
                                    marginRight: '8px',
                                    marginLeft: '0px',
                                    width: '100%'
                                },
                                '& .MuiInputBase-root': {
                                    borderRadius: (showResetButton && value !== null) ? '5px 0px 0px 5px' : '5px',
                                },
                            }}
                            label={label}
                            views={['hours', 'minutes']}
                            format="HH:mm"
                            ampm={false}
                            value={value}
                            onChange={changeTime}
                            maxTime={maxTimeFunction()}
                            minTime={minTimeFunction()}
                            referenceDate={referenceDate}
                            disabled={isDisabled}
                        // onError={isError}
                        />
                    )}
            </LocalizationProvider>

            {(showResetButton && value !== null) && (
                <Box sx={{ border: '1px solid #bdbdbd', borderLeft: '0px', borderRadius: '0px 5px 5px 0px', height: isStyleMod ? '23px' : '38px', display: "flex", alignItems: "center" }}>
                    <IconButton disabled={value === null} onClick={resetValue}>
                        <Close sx={{ fontSize: '1rem' }} />
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}