import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress, DialogTitle, IconButton, DialogContent } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Add, Clear, Close, Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
// import CryptoJS from 'crypto-js';

import 'react-virtualized/styles.css';

import { RicercaAPI } from '../../../api/ricercaApi';
import Breadcrumb from '../../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../../shared/utilityMethods';
import { TirociniAPI } from '../../../api/tirociniApi';
import DettaglioAzienda from './dettaglioAzienda';
import TableAziende from './tableAziende';
import DialogConferma from '../../shared/dialogConferma';
import { useLocation } from 'react-router-dom';
import LegendaTirocini from '../legendaTirocini';

export default function GestioneAziende(props) {

    const { t } = useTranslation();
    const location = useLocation();

    const [sediList, setSediList] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);


    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [dialogAggiungiAziendaOpen, setDialogAggiungiAziendaOpen] = useState(false);

    const [idAzienda, setIdAzienda] = useState(-1);

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [ragioneSocialeValid, setRagioneSocialeValid] = useState(true);
    const [ragioneSocialeMessageError, setRagioneSocialeMessageError] = useState('');

    const [nomeRapLegale, setNomeRapLegale] = useState('');
    const [nomeRapLegaleValid, setNomeRapLegaleValid] = useState(true);
    const [nomeRapLegaleMessageError, setNomeRapLegaleMessageError] = useState('');

    const [pIva, setPIva] = useState('');
    const [pIvaValid, setPIvaValid] = useState(true);
    const [pIvaMessageError, setPIvaMessageError] = useState('');

    const [CF, setCF] = useState('');
    const [CFValid, setCFValid] = useState(true);
    const [CFMessageError, setCFMessageError] = useState('');

    const [codAteco, setCodAteco] = useState('');
    const [codAtecoValid, setCodAtecoValid] = useState(true);
    const [codAtecoMessageError, setCodAtecoMessageError] = useState('');

    const [statoAzienda, setStatoAzienda] = useState(1); //Default ad "Attivo"

    // eslint-disable-next-line no-unused-vars
    const [sedeSelected, setSedeSelected] = useState(-1);

    const [listAziende, setListAziende] = useState([]);

    const [idSettore, setIdSettore] = useState(-1);
    const [settori, setSettori] = useState([]);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [isModificaMode, setIsModificaMode] = useState(false);

    const [confirmAlertDeleteIsOpen, setConfirmAlertDeleteIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);

    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        getDatiPrecompilati()
        let annoFormativoTmp = getAnnoFormativo();
        //setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);

        if (location.state !== null && location.state.pIva !== undefined) {
            setPIva(location.state.pIva);
            ricercaAziende(location.state.pIva)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])


    function validate(e) {
        e.preventDefault()
        startSearch()
    }



    // function changeSede(e) {
    //     if (e.target.value !== '') {
    //         setSedeSelected(e.target.value);
    //     }
    //     else {
    //         setSedeSelected(-1);
    //     }
    // };


    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {
        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse

        setRagioneSociale('');
        setRagioneSocialeValid(true);
        setRagioneSocialeMessageError('');

        setNomeRapLegale('');
        setNomeRapLegaleValid(true);
        setNomeRapLegaleMessageError('');

        setPIva('');
        setPIvaValid(true);
        setPIvaMessageError('');

        setCF('');
        setCFValid(true);
        setCFMessageError('');

        setCodAteco('');
        setCodAtecoValid(true);
        setCodAtecoMessageError('');

        setStatoAzienda(-1);

        if (sediList.length === 1) {
            setSedeSelected(sediList[0]?.id ?? -1)
        } else setSedeSelected(-1);

        setIdSettore(-1);


        setSearchStarted(false)

        setTimeout(() => {
            setListAziende([]);
            setHasLoaded(false)
        }, 200)
    }

    function openAggiungiAzienda() {
        setDialogAggiungiAziendaOpen(prev => !prev);
        // setListAziende([]);
        // setSearchStarted(false)
        setIsModificaMode(false)
    }

    function changeStatoAziendeFiltro(e) {
        setStatoAzienda(e.target.value)
    }

    function changeIdSettore(e) {
        setIdSettore(e.target.value)
    }

    function changeRagioneSociale(e) {
        let ragioneSocialeTmp = e.target.value;
        setRagioneSociale(ragioneSocialeTmp);
        if (ragioneSocialeTmp.length > 2 || ragioneSocialeTmp === "") {
            setRagioneSocialeMessageError('');
            setRagioneSocialeValid(true);
        }
        else {
            setRagioneSocialeValid(false);
            setRagioneSocialeMessageError('Min. 3 caratteri.');
        }
    }

    function changeNomeRapLegale(e) {
        let nomeRapLegaleTmp = e.target.value;
        setNomeRapLegale(nomeRapLegaleTmp)
        if (nomeRapLegaleTmp.length > 2 || nomeRapLegaleTmp === "") {
            setNomeRapLegaleMessageError('');
            setNomeRapLegaleValid(true);
        }
        else {
            setNomeRapLegaleValid(false);
            setNomeRapLegaleMessageError('Min. 3 caratteri.');
        }
    }

    function changeCodAteco(e) {
        let codAtecoTmp = e.target.value;
        setCodAteco(codAtecoTmp)
        if (codAtecoTmp.length > 2 || codAtecoTmp === "") {
            setCodAtecoMessageError('');
            setCodAtecoValid(true);
        }
        else {
            setCodAtecoValid(false);
            setCodAtecoMessageError('Min. 3 caratteri.');
        }
    }

    function changeCF(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCF(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setCFValid(true);
            setCFMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
                setCFValid(true);
                setCFMessageError('')
            } else {
                // setPIvaValid(false);
                setCFValid(false);
                setCFMessageError('Il C.F si compone di 11 o 16 caratteri')
            }
        }
    }

    function changePIva(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^0-9]/g, '');

        setPIva(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setPIvaValid(true);
            setPIvaMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11) {
                setPIvaValid(true);
                setPIvaMessageError('')
            } else {
                // setPIvaValid(false);
                setPIvaValid(false);
                setPIvaMessageError('La P.Iva si compone di 11 caratteri')
            }
        }

    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function openModificaAzienda(idAzienda, canEdit) {
        setIdAzienda(idAzienda);
        setTimeout(openAggiungiAzienda(), 300);
        setIsCanEdit(canEdit);
        setIsModificaMode(true);
    }

    function changeIdAzienda(idAzienda) {
        setIdAzienda(idAzienda)
    }

    function closeAlertConfirmDelete() {
        setConfirmAlertDeleteIsOpen(false);
        setIdAzienda(-1);
    }

    function deleteAzienda(idAzienda) {
        setConfirmAlertDeleteIsOpen(true);
        setIdAzienda(idAzienda);
    }
    function openLegendaDialog() {
        setDialogLegendaOpen(prev => !prev);
    }

    function gestioneFiltroRicerca() {
        // eslint-disable-next-line eqeqeq
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (ragioneSocialeValid &&
                nomeRapLegaleValid &&
                pIvaValid &&
                CFValid &&
                codAtecoValid
            ) return false
            else return true
        }
        else {
            if (ragioneSocialeValid &&
                nomeRapLegaleValid &&
                pIvaValid &&
                CFValid &&
                codAtecoValid &&
                (ragioneSociale !== '' ||
                    nomeRapLegale !== '' ||
                    pIva !== '' ||
                    CF !== '' ||
                    codAteco !== '' ||
                    idSettore !== -1
                )
            ) return false
            else return true
        }
    }

    async function getSedi(annoFormativo) {
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])

                if (result.sedi.length === 1) {
                    setSedeSelected(result?.sedi[0]?.id ?? -1)
                }
            })
            .catch(function (error) {
            })
    }
    async function getDatiPrecompilati() {
        TirociniAPI.getDatiPrecompilati()
            .then((result) => {
                let datiPrecompilatiTmp = result;
                setSettori([...datiPrecompilatiTmp?.settori]);
            })
            .catch(function (error) {
            })

    }
    async function ricercaAziende(pivaLocationState) {
        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri
        let pivaTmp = pivaLocationState !== undefined ? pivaLocationState : pIva; //Se esiste 
        let obj = {
            denominazioneAzienda: ragioneSociale !== '' ? ragioneSociale : null,
            pIva: pivaTmp !== '' ? pivaTmp : null,
            CF: CF !== '' ? CF : null,
            //sedeCiof: sedeSelected !== -1 ? sedeSelected : null,
            codiceAteco: codAteco !== '' ? codAteco : null,
            nomeRappresentante: nomeRapLegale !== '' ? nomeRapLegale : null,
            settore: idSettore !== -1 ? idSettore : null,
            stato: statoAzienda !== -1 ? statoAzienda : null,
        }
        TirociniAPI.ricercaAziende(obj)
            .then((result) => {
                setListAziende([...result]);
                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })

    }
    async function deleteAziendaById() {
        setButtonConfermaDisabled(true)
        let obj = {
            idAzienda: idAzienda
        }
        TirociniAPI.deleteAziendaById(obj)
            .then((result) => {
                setIdAzienda(-1);
                let msgTmp = result.msg ?? "Azienda eliminata con successo.";
                openAlertMsg(msgTmp, 'success');
                closeAlertConfirmDelete();
                ricercaAziende(undefined);
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante l'eliminazione dell'azienda. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante l'eliminazione dell'azienda. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>

            {!isInsideOtherComponent ? (
                <Breadcrumb sections={[ "Gestione aziende"]} isTirocinio={true} />
            ) : null}


            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca aziende</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />

                    </Box>
                    <Collapse in={filtriRicercaNotCollapsed}>
                        <Divider />
                        <Box sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                            <form onSubmit={validate} style={{ width: '100%' }}>
                                <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            defaultValue={ragioneSociale}
                                            value={ragioneSociale}
                                            label={'Ragione sociale azienda'}
                                            onChange={changeRagioneSociale}
                                            sx={{ width: '100%' }}
                                            error={!ragioneSocialeValid}
                                            helperText={ragioneSocialeMessageError}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            defaultValue={nomeRapLegale ?? ''}
                                            value={nomeRapLegale ?? ''}
                                            label={"Nome rappresentante legale"}
                                            onChange={changeNomeRapLegale}
                                            sx={{ width: '100%' }}
                                            error={!nomeRapLegaleValid}
                                            helperText={nomeRapLegaleMessageError}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={4}>
                                        <TextField
                                            error={!pIvaValid ? true : false}
                                            defaultValue={pIva ?? ''}
                                            value={pIva ?? ''}
                                            label={"P.Iva azienda"}
                                            onChange={changePIva}
                                            sx={{ width: '100%' }}
                                            helperText={pIvaMessageError}
                                            inputProps={{ maxLength: 11 }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            error={!CFValid ? true : false}
                                            defaultValue={CF ?? ''}
                                            value={CF ?? ''}
                                            label={"C.F. azienda"}
                                            onChange={changeCF}
                                            sx={{ width: '100%' }}
                                            helperText={CFMessageError}
                                            inputProps={{ maxLength: 16 }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            defaultValue={codAteco ?? ''}
                                            value={codAteco ?? ''}
                                            label={"Codice Ateco azienda"}
                                            onChange={changeCodAteco}
                                            sx={{ width: '100%' }}
                                            error={!codAtecoValid}
                                            helperText={codAtecoMessageError}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel>
                                                Settore azienda</InputLabel>
                                            <Select
                                                label='Settore azienda '
                                                value={idSettore ?? -1}
                                                onChange={changeIdSettore}
                                            >
                                                <MenuItem
                                                    key={-1}
                                                    value={-1}
                                                >
                                                    ...
                                                </MenuItem>
                                                {settori.map((settore, index) => (
                                                    <MenuItem
                                                        key={settore?.id}
                                                        value={settore?.id}
                                                    >
                                                        {settore?.descrizione}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel>
                                                Stato azienda</InputLabel>
                                            <Select
                                                label='Stato azienda'
                                                value={statoAzienda}
                                                onChange={changeStatoAziendeFiltro}
                                            // disabled={!modifica}
                                            >
                                                <MenuItem
                                                    key={-1}
                                                    value={-1}
                                                >
                                                    ...
                                                </MenuItem>
                                                <MenuItem
                                                    key={1}
                                                    value={1}
                                                >
                                                    Attivo
                                                </MenuItem>
                                                <MenuItem
                                                    key={0}
                                                    value={0}
                                                >
                                                    Non attivo
                                                </MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid xs={12} md={4}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="sede-label">
                                                Sede Ciofs
                                            </InputLabel>
                                            <Select
                                                data-testid='sedeRicercaSelect'
                                                label="Sede Ciofs"
                                                labelId="sede-label"
                                                value={sedeSelected}
                                                onChange={changeSede}
                                                disabled={sediList.length <= 1}
                                            >
                                                <MenuItem
                                                    key={-1}
                                                    value={-1}
                                                >
                                                    ...
                                                </MenuItem>
                                                {sediList.map((sede) => (
                                                    <MenuItem
                                                        key={sede.id}
                                                        value={sede.id}
                                                    >
                                                        {sede.nomeSede}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                                </Grid>
                            </form>
                        </Box>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato (escluso il campo "Stato azienda").</Alert>
                    </Box>)}
                    <Divider />
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', md: "space-between" } }}>
                        {searchStarted ? (
                            <Button sx={{ height: 'fit-content', px: { xs: 0, md: 5 }, width: { xs: '100%', md: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                Rimuovi filtri
                            </Button>
                        ) : (
                            <span></span>
                        )}
                        <Box sx={{ width: { xs: '100%', md: 'fit-content' } }}>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} onClick={() => ricercaAziende(undefined)} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca()} variant="contained">
                                Avvia ricerca
                            </Button>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} onClick={openAggiungiAzienda} endIcon={<Add />} color="success" variant="contained">
                                Aggiungi azienda
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                        {hasLoaded ? (
                            listAziende.length > 0 ?
                                (
                                    <Fragment>
                                        <TableAziende list={listAziende} deleteItem={deleteAzienda} visualizzaItem={openModificaAzienda} openLegendaDialog={openLegendaDialog} />
                                    </Fragment>
                                )
                                :
                                (
                                    <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Alert sx={{ mb: 2 }} severity="info">La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Alert>
                                    </Box>
                                )

                        ) : (
                            <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress sx={{ mb: 4 }} />
                                <Typography >Caricamento in corso...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Collapse>
            </Container>

            <Dialog fullWidth fullScreen maxWidth={'xs'} open={dialogAggiungiAziendaOpen} sx={{ mt: '8vh' }}>
                <DettaglioAzienda closeDialog={openAggiungiAzienda} idAzienda={idAzienda} isCanEdit={isCanEdit} isModificaMode={isModificaMode} changeIdAzienda={changeIdAzienda} />
            </Dialog>

            <Dialog fullWidth maxWidth={'lg'} open={dialogLegendaOpen} sx={{ mt: '8vh' }} onClose={openLegendaDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Legenda</Typography>
                    <IconButton onClick={openLegendaDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaTirocini data={'aziende'} />
                </DialogContent>
            </Dialog>

            <DialogConferma
                isDialogOpen={confirmAlertDeleteIsOpen}
                title={'Conferma eliminazione azienda'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler procedere con l'eliminazione dei dati della azienda? Una volta confermata, l'operazione non potrà essere annullata.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={closeAlertConfirmDelete}
                conferma={deleteAziendaById}
                disabledButton={buttonConfermaDisabled}
            />
            {
                !isInsideOtherComponent ? (
                    <Helmet>
                        <title>{t("name")} - Gestione aziende convenzionate</title>
                    </Helmet>
                ) : null
            }

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    );
}