import { useState, useEffect, Fragment, useRef, useCallback } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, useTheme, Breadcrumbs, useMediaQuery, IconButton, Alert, Snackbar, Link, } from '@mui/material';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import { ArrowBack, Help, EditCalendar, ZoomIn, Close } from "@mui/icons-material";
import { DateTime, Interval } from "luxon";

import RegistroDiClasse from "./registroDiClasse";
import EventoChip from "./shared/eventoChip";

import { convertDateObjectToString, convertISODate, getDayOfTheWeek, getDayAndMonth, getDayMonthAndYearErrorDialog, convertDataGetProssimeLezione, convertDataDiNascita } from '../shared/metodiDateUtility';
import RegistroDocente from "./registroDocente";
import Presenza from "./presenza";
import { PresenzeAPI } from "../../api/presenzeApi";
import { RegistroAPI } from "../../api/registroApi";
import SelezioneClasseOperatore from "./selezioneClasseOperatore";
import { useLocation } from "react-router-dom";
import Legende from "./shared/legende";
import DettaglioAlunno from "../dettaglio-utente/dettaglioAlunno";

import { AutoSizer, Grid, Column, CellMeasurerCache, CellMeasurer, MultiGrid, ScrollSync, List } from 'react-virtualized';
import RangeDate from "../shared/rangeDate";
import SelezioneLezioneNew from "./selezioneLezioneNew";

export default function TestVirtualized(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const tableRef = useRef();

    const [loadedCells, setLoadedCells] = useState({});
    const [scrollToColumn, setScrollToColumn] = useState(0);


    const [tipoRegistro, setTipoRegistro] = useState("classe")
    const [tipo, setTipo] = useState(false)

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true)

    const [isDocente, setIsDocente] = useState(true);


    const [permessiUtente, setPermessiUtente] = useState([]);

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [idOrarioSelezionato, setIdOrarioSelezionato] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");
    const [nomeMateria, setNomeMateria] = useState("");
    const [oraMateria, setOraMateria] = useState('');

    const [oreDiLezioneClasseSelezionata, setOreDiLezioneClasseSelezionata] = useState([]);
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState("");
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);


    const [dataRichiestaDettaglio, setDataRichiestaDettaglio] = useState(null);


    const [arrayGiorni, setArrayGiorni] = useState([])
    const [listAlunni, setListAlunni] = useState([]);
    const [dialogDettaglioRegistroOpen, setDialogDettaglioRegistroOpen] = useState(false);


    //Periodo di interesse
    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false);

    const [hasDocenteFunctions, setHasDocenteFunctions] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    //DETTAGLIO ALUNNO
    const [dettaglioAlunnoOpen, setDettaglioAlunnoOpen] = useState(false);
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(null);
    const [indexTabToOpen, setIndexTabToOpen] = useState(0);
    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [sedeUtente, setSedeUtente] = useState('');

    const [oreFirmate, setOreFirmate] = useState();

    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            minHeight: 80,
        })
    );

    function getColumnWidth(width) {
        const minWidth = 200;
        const numColumns = Math.max(1, Math.floor(width / minWidth));
        return width / numColumns;
    }


    useEffect(() => {
        setDialogDettaglioRegistroOpen(false)
        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);

        }

        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)
        }

        //TODO: Gestire controllo quando vengono direttamente dall'url
        if (location?.state?.isDocente != undefined)
            setIsDocente(location.state.isDocente)
        else {
            setIsDocente(true)
        }

    }, [])

    useEffect(() => {
        setTipoRegistro(props.tipoRegistro);
    }, [props.tipoRegistro])

    function goToSelezionaOra() {
        setDialogSelezionaClasseOpen(true);
    }

    function openDettaglioRegistro(dataRichiesta) {
        setDataRichiestaDettaglio(dataRichiesta)
        setDialogDettaglioRegistroOpen(true);
    }

    function changeIdClasseSelezionata(idClasse, dataInizio, dataFine, idMateria, nomeClasse, oreLezione, idOrario, isDocente, nomeMateria) {
        if (idClasse !== undefined) {
            let idCorsoCrypted = CryptoJS.AES.encrypt(idClasse.toString(), process.env.REACT_APP_PRIVATE_KEY).toString();
            sessionStorage.setItem("classe", idCorsoCrypted);
            setIdClasseSelezionata(idClasse);
            setIdMateriaSelezionata(idMateria);
            setNomeClasse(nomeClasse);
            setDialogSelezionaClasseOpen(false);
            setHasDocenteFunctions(isDocente);
            setPeriodoDiInteresseInizio(dataInizio);
            setPeriodoDiInteresseFine(dataFine);
            setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
            setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));

            setNomeMateriaSelezionata(nomeMateria)
            setOreDiLezioneClasseSelezionata([...oreLezione])

            // for (let ora of oreLezione) {
            //     if (ora?.materia?.idMateria === idMateria) {
            //         setNomeMateriaSelezionata(ora?.materia?.nomeMateria);
            //         break;
            //     }
            // }

            getRegistroDiSintesi(idClasse, dataInizio, dataFine, idMateria);
        }
    }




    //Periodo di interesse
    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let dataInizioTmp = dataInizio ?? new DateTime().toISO();
        let dataFineTmp = dataFine ?? new DateTime().toISO();

        setPeriodoDiInteresseInizio(dataInizioTmp);
        setPeriodoDiInteresseFine(dataFineTmp);

        setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
        setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));
        setChangePeriodoInteresseOpen(false)


        changeIdClasseSelezionata(idClasseSelezionata, dataInizioTmp, dataFineTmp, idMateriaSelezionata, nomeClasse, oreDiLezioneClasseSelezionata, null, hasDocenteFunctions, nomeMateriaSelezionata)
    }
    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }
    function closeRegistro() {
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
        setDialogDettaglioRegistroOpen(false)
    }
    function closeDettaglioAlunno() {
        setDettaglioAlunnoOpen(false);
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function eventoModificato(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }
    function vediDettaglioAlunno(id, indexTab, nome, cognome, ritardo) {
        setOpenDialogRitardo(ritardo)
        setIdAlunnoSelezionato(id);
        setNomeAlunnoSelezionato(nome + " " + cognome);
        setDettaglioAlunnoOpen(true);
        setIndexTabToOpen(indexTab);
    }

    function isAlunnoIscrittoInData(dataCella, alunno) {
        let dataSql = DateTime.fromSQL(dataCella);
        let dataConvertedIso = dataSql.toUTC().toISO()
        let iscrittoDalIso = alunno?.iscrittoDal;
        let iscrittoAlIso = alunno?.iscrittoAl;

        let startDate = DateTime.fromISO(iscrittoDalIso);
        let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
        let someDate = DateTime.fromISO(dataConvertedIso);
        let interval = Interval.fromDateTimes(startDate, endDate);
        if (interval.contains(someDate)) {
            return true
        } else return false;
    }

    function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
        let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
        let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

        let dataInizioObject = DateTime.fromISO(dataInizioTmp);
        let dataFineObject = DateTime.fromISO(dataFineTmp);

        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
        if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        else if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
        {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else return null
    }

    async function getRegistroDiSintesi(idClasseSelezionata, dataDiInteresseInizio, dataDiInteresseFine, idMateria) {
        let dataObj = {
            dataRichiestaDa: convertDataGetProssimeLezione(dataDiInteresseInizio, true),
            dataRichiestaA: convertDataGetProssimeLezione(dataDiInteresseFine, true),
            idCorso: idClasseSelezionata
        }

        if (tipoRegistro == "docente") {
            dataObj.idMateria = idMateria;

            getPercentualeOreFirma(idOrarioSelezionato, idDocente, idClasseSelezionata, idMateria);

            RegistroAPI.getRegistroDocenteDiSintesi(dataObj)
                .then((result) => {
                    let alunniTmp = result.alunni;
                    let arrayGiorniTmp = result.giorni;

                    for (let i = 0; i < arrayGiorniTmp.length; i++) {
                        let dataIso = arrayGiorniTmp[i].data;
                        let dataConverted = convertDateObjectToString(new Date(dataIso));
                        //Passare dataISO a oggetto Date 
                        arrayGiorniTmp[i].dataIso = dataIso;
                        arrayGiorniTmp[i].data = dataConverted

                        let eventiGiorno = arrayGiorniTmp[i]?.comunicazioni;
                        for (let y = 0; y < eventiGiorno.length; y++) {
                            let evento = eventiGiorno[y];
                            if (evento?.tipologia_comunicazioni === 1 || evento?.tipologia_comunicazioni === 4 || evento?.tipologia_comunicazioni === 5 || evento?.tipologia_comunicazioni === 7 || evento?.tipologia_comunicazioni === 8) {
                                let idAlunni = evento?.albero_destinatari?.idAlunno;

                                if (idAlunni.length > 0 && idAlunni.length !== listAlunni.length) {

                                    for (let id of idAlunni) {
                                        let indexTmp = alunniTmp.findIndex(al => al.id === id)
                                        if (indexTmp !== -1) {
                                            if (indexTmp !== -1) {
                                                let eventoTmp = evento;
                                                eventoTmp.indexColonna = i;
                                                alunniTmp[indexTmp].comunicazioni.push(evento);
                                            }
                                        }
                                    }
                                    // arrayGiorniTmp[i].comunicazioni = arrayGiorniTmp[i]?.comunicazioni.splice(y, 0);
                                }
                            }
                        }

                    }

                    for (let i = 0; i < alunniTmp.length; i++) {
                        alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

                    }



                    getPresenzeSintesi(alunniTmp, arrayGiorniTmp, dataDiInteresseInizio, dataDiInteresseFine);


                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403)
                            if (props.logout())
                                props.logout();
                    }


                })
        } else {
            RegistroAPI.getRegistroDiSintesi(dataObj)
                .then((result) => {
                    let alunniTmp = result.alunni;
                    let arrayGiorniTmp = result.giorni;

                    for (let i = 0; i < arrayGiorniTmp.length; i++) {
                        let dataIso = arrayGiorniTmp[i].data;
                        let dataConverted = convertDateObjectToString(new Date(dataIso));
                        //Passare dataISO a oggetto Date 
                        arrayGiorniTmp[i].data = dataConverted
                        arrayGiorniTmp[i].dataIso = dataIso;
                        let eventiGiorno = arrayGiorniTmp[i]?.comunicazioni;
                        for (let y = 0; y < eventiGiorno.length; y++) {
                            let evento = eventiGiorno[y];

                            if (evento?.tipologia_comunicazioni === 1 || evento?.tipologia_comunicazioni === 2 || evento?.tipologia_comunicazioni === 3) {
                                let idAlunni = evento?.albero_destinatari?.idAlunno;
                                if ((idAlunni.length !== listAlunni.length && idAlunni.length > 0)) {
                                    for (let id of idAlunni) {
                                        let indexTmp = alunniTmp.findIndex(al => al.id === id)
                                        if (indexTmp !== -1) {
                                            if (indexTmp !== -1) {
                                                let eventoTmp = evento;
                                                eventoTmp.indexColonna = i;
                                                alunniTmp[indexTmp].comunicazioni.push(evento);
                                            }
                                        }
                                    }
                                } //else ok nota/verifica è per tutta la classe

                            }
                        }
                    }

                    for (let i = 0; i < alunniTmp.length; i++) {

                        alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

                        if (alunniTmp[i].comunicazioni[0].length > 0) {
                            for (let y = 0; y < alunniTmp[i].comunicazioni[0].length; y++) {
                                for (let x = 0; x < arrayGiorniTmp.length; x++) {
                                    let data = DateTime.fromISO(alunniTmp[i].comunicazioni[0][y].data_comunicazione);
                                    let sqlDate = data.toFormat('yyyy-MM-dd');
                                    if (arrayGiorniTmp[x].data === sqlDate) {
                                        alunniTmp[i].comunicazioni[0][y].indexColonna = x;
                                    }
                                }
                            }
                        }
                    }
                    getPresenzeSintesi(alunniTmp, arrayGiorniTmp, dataDiInteresseInizio, dataDiInteresseFine);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403)
                            if (props.logout())
                                props.logout();
                    }


                })
        }

    }

    async function getPercentualeOreFirma(idOrario, idDocente, idCorso, idMateria) {
        let dataObj = {
            idOrario: idOrario,
            idDocente: idDocente,
            idCorso: idCorso,
            idMateria: idMateria
        }
        RegistroAPI.getPercentualeOreFirma(dataObj)
            .then((result) => {
                setOreFirmate(result)
            })
            .catch((error) => { })

    }

    async function getPresenzeSintesi(alunniTmp, arrayGiorniTmp, dataInizio, dataFine) {

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataInizioConverted = DateTime.fromISO(dataInizio);
        let dataInizioSql = dataInizioConverted.toISODate();


        let dataFineConverted = DateTime.fromISO(dataFine);
        let dataFineSql = dataFineConverted.toISODate();


        let dataObj = {
            "dataDal": dataInizioSql,
            "dataAl": dataFineSql,
            "idAlunno": idAlunni
        }


        let alunniArray = alunniTmp;

        PresenzeAPI.getPresenzeSintesi(dataObj)
            .then((result) => {
                if (result.alunni.length > 0) {
                    let arrayAppello = result.alunni;
                    for (let i = 0; i < alunniArray.length; i++) {
                        alunniArray[i].presenze = [];
                        alunniArray[i].giustificate = [];

                        for (let j = 0; j < arrayAppello.length; j++) {
                            if (alunniArray[i].id === arrayAppello[j].idAlunno) {
                                let statoPresenzaTmp = arrayAppello[j].stato_presenza;
                                let uscitaConverted = null;
                                let ingressoConverted = null;
                                if (arrayAppello[j].orario_uscita_reale != null) {
                                    uscitaConverted = arrayAppello[j].orario_uscita_reale.substring(0, arrayAppello[j].orario_uscita_reale.length - 3);
                                    if (tipoRegistro === "docente") statoPresenzaTmp = "P"

                                }
                                if (arrayAppello[j].orario_ingresso_reale != null) {
                                    ingressoConverted = arrayAppello[j].orario_ingresso_reale.substring(0, arrayAppello[j].orario_ingresso_reale.length - 3)
                                    if (tipoRegistro === "docente") statoPresenzaTmp = "P"
                                }

                                if (tipoRegistro === "classe" || (tipoRegistro === "docente" && statoPresenzaTmp !== "P" && statoPresenzaTmp !== "ER")) {
                                    let objPresenza = {
                                        id: arrayAppello[j].id,
                                        data: arrayAppello[j].data,
                                        statoPresenza: statoPresenzaTmp,
                                        note: arrayAppello[j].note,
                                        orario_uscita_reale: uscitaConverted,
                                        orario_ingresso_reale: ingressoConverted
                                    }
                                    alunniArray[i].presenze.push(objPresenza)
                                }
                            }
                        }
                    }
                }
                //setListAlunni([...alunniTmp]);
                setArrayGiorni([...arrayGiorniTmp]);
                getAssenzeGiustificate(alunniTmp, dataInizio, dataFine, arrayGiorniTmp)

            })
            .catch((error) => { })
    }
    async function getAssenzeGiustificate(alunniTmp, dataInizio, dataFine, giorniTmp) {

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataInizioConverted = DateTime.fromISO(dataInizio);
        let dataInizioSql = dataInizioConverted.toISODate();


        let dataFineConverted = DateTime.fromISO(dataFine);
        let dataFineSql = dataFineConverted.toISODate();

        let alunniArray = alunniTmp;

        let dataObj = {
            "dataDal": dataInizioSql,
            "dataAl": dataFineSql,
            "idAlunno": idAlunni
        }


        PresenzeAPI.getAssenzeGiustificate(dataObj)
            .then((result) => {

                if (result.alunni.length > 0) {
                    for (let i = 0; i < result.alunni.length; i++) {
                        let indexAlunno = alunniArray.findIndex(alunno => alunno.id === result?.alunni[i]?.idAlunno)
                        if (indexAlunno !== -1) {

                            let indexGiorno = giorniTmp.findIndex(giorno => giorno?.dataIso === result?.alunni[i]?.dataGiustificazione)
                            let objGiustificata = {
                                idAlunno: result.alunni[i]?.idAlunno,
                                dataGiustificazione: result.alunni[i]?.dataGiustificazione,
                                statoPresenza: result.alunni[i]?.dataGiustificazione,
                                indexColonna: indexGiorno
                            }
                            alunniArray[indexAlunno].giustificate.push(objGiustificata)
                        }
                    }

                }

                setScrollToColumn(alunniArray.length - 1);
                setTimeout(()=>{
                    setScrollToColumn(10);
                },500)
                setListAlunni([...alunniArray]);
                setHasLoaded(true)

            })
            .catch((error) => {
                if (error.response.status === 403) props.logout();
                else {
                    console.error("Errore durante il get delle presenze di sintesi")

                }
            })
    }

    function getRegistro() {
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }
    function eventoAggiunto(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity)
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }
    function eventoModificato(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function changeSede(nome) {
        setSedeUtente(nome)
    }

    function changeTipo() {
        setTipo(true);
    }


    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function changeSede(nome) {
        setSedeUtente(nome)
    }



    function cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
        let content;
        if (rowIndex === 0) {
            if (columnIndex > 0)
                content = renderCellGiorno(arrayGiorni[columnIndex]);
            else content =
                <Box style={{ ...style, top: `${style.top}px`, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white', textAlign: 'center' }}>
                    <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }} variant={mobile ? 'caption' : 'body1'}>
                        {nomeMateriaSelezionata !== undefined ? nomeMateriaSelezionata.toUpperCase() : ""}
                    </Typography>
                </Box>
        }
        else if (rowIndex === 1) {
            if (columnIndex === 0) {
                content = 
                    <Box style={{ ...style, left: `${style.left}px` }}>
                    <Typography variant="caption">Corso:</Typography>
                    <Typography color={'primary'}
                        variant={mobile ? 'caption' : 'body1'}
                        sx={{ fontWeight: 'bold', mr: 1 }}>{(nomeClasse ?? "").toUpperCase()}</Typography>
                    <Typography variant="caption" sx={{ mt: 1 }}>Sede:</Typography>
                    <Typography
                        variant={mobile ? 'caption' : 'subtitle2'}
                        sx={{ fontWeight: 'bold', mr: 1 }}>{sedeUtente !== '' ? sedeUtente : ''}</Typography>
                </Box>
            }
            else if (tipoRegistro === "classe") {
                {
                    <Box width={"30px"} height={"30px"}
                        border={"1px solid #1976d2"}
                        borderColor={"primary"}
                        backgroundColor={"white"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                        <ZoomIn />
                    </Box>
                }
            }
        }
        else if (columnIndex === 0 && rowIndex > 0) {
            content = renderCellAlunno(listAlunni[rowIndex - 1]);
        } else if (rowIndex > 1 && columnIndex > 0) {
            content = renderCellAlunnoGiorno(listAlunni[rowIndex - 1], arrayGiorni[columnIndex], rowIndex - 1, columnIndex - 1);
        }

        return (
            <CellMeasurer
                cache={cache.current}
                columnIndex={columnIndex}
                parent={parent}
                rowIndex={rowIndex}
                key={key} 
            >
                {({ measure }) => (
                    <Box onLoad={measure} sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }} key={key} style={style}>
                        {content}
                    </Box>
                )}
            </CellMeasurer>
        );
    }


    function renderCellGiorno(giorno) {
        return (
            <Box textAlign={"center"} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white', textAlign: 'center' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{getDayAndMonth(giorno?.data)}</Typography>
                <Typography variant="subtitle2">{getDayOfTheWeek(giorno?.data)}</Typography>
                {giorno?.chiusura ? (
                    <Typography color="primary" variant="subtitle2"><b>CHIUSURA</b></Typography>
                ) : null}
            </Box>
        )
    }

    function renderCellAlunno(alunno) {
        return (
            <Box sx={{ backgroundColor: 'white', p: 2 }}>
                <Typography variant={mobile ? 'caption' : 'body1'} onClick={() => vediDettaglioAlunno(alunno.id, 0, alunno.nome, alunno.cognome, false)} sx={{ cursor: 'pointer', mr: 2, fontWeight: alunno.isMaggiorenne ? 'bold' : 'unset' }}> {(alunno.cognome).toUpperCase() + " " + (alunno.nome).toUpperCase()}</Typography>
                {alunno.msgIscrizione != null ? (
                    <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                ) : null}
            </Box>
        )
    }

    function renderCellAlunnoGiorno(alunno, giorno, indexAlunno, indexGiorno) {
        return (

            <Box sx={{ height: '100%', display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} className={(isAlunnoIscrittoInData(giorno?.data, alunno) === false) || (giorno?.chiusura) ? 'disabledCell' : ""} key={"alunno_" + alunno?.id + giorno?.data} >
                {isAlunnoIscrittoInData(giorno?.data, alunno) === false ? (
                    <span></span>
                ) : (
                    <Box justifyContent={"center"} display="flex">

                        {tipoRegistro === "classe" ? (
                            alunno?.comunicazioni[0] !== undefined && (
                                alunno?.comunicazioni[0].length > 0 ? (
                                    alunno?.comunicazioni[0].map((comunicazione) => (
                                        < Fragment key={alunno.id + comunicazione.id} >
                                            {comunicazione?.indexColonna === indexGiorno ? (
                                                <EventoChip idDocente={idDocente} tipoRegistro={tipoRegistro} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                            ) : null}
                                        </Fragment>
                                    ))
                                ) : null
                            )
                        ) : (
                            alunno.comunicazioni.length > 0 ? (
                                alunno.comunicazioni.map((comunicazione) => (
                                    <Fragment key={alunno.id + comunicazione.id} >
                                        {comunicazione?.indexColonna === indexGiorno ? (
                                            <EventoChip idDocente={idDocente} tipoRegistro={tipoRegistro} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                        ) : null}
                                    </Fragment>
                                ))
                            ) : null
                        )}


                        {alunno.presenze != undefined && (
                            alunno?.presenze.map((presenza, index) => (
                                presenza?.data == giorno?.dataIso ? (
                                    <Box key={index} onClick={() => presenza.statoPresenza !== "P" ? vediDettaglioAlunno(alunno.id, 1, alunno.nome, alunno.cognome, false) : null} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: presenza.statoPresenza !== "P" ? 'pointer' : 'default' }}>
                                        <Presenza key={"pres_" + index} presenza={presenza} isRegistroDettaglio={false} />
                                    </Box>
                                ) : null))
                        )}

                    </Box>
                )}
            </Box>
        )
    }



    function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
        let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
        let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

        let dataInizioObject = DateTime.fromISO(dataInizioTmp);
        let dataFineObject = DateTime.fromISO(dataFineTmp);

        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
        if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        else if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
        {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else return null
    }
    function isAlunnoIscrittoInData(dataCella, alunno) {
        let dataSql = DateTime.fromSQL(dataCella);
        let dataConvertedIso = dataSql.toUTC().toISO()
        let iscrittoDalIso = alunno?.iscrittoDal;
        let iscrittoAlIso = alunno?.iscrittoAl;

        let startDate = DateTime.fromISO(iscrittoDalIso);
        let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
        let someDate = DateTime.fromISO(dataConvertedIso);
        let interval = Interval.fromDateTimes(startDate, endDate);
        if (interval.contains(someDate)) {
            return true
        } else return false;
    }





    return (
        <Fragment>
            {(idClasseSelezionata !== -1 && dialogSelezionaClasseOpen === false) || (dialogDettaglioRegistroOpen === true) ? (
                hasLoaded ? (
                    <Box sx={{ px: 5 }}>
                        <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
                            <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                            <Typography color="text.disabled">{tipoRegistro === "classe" ? "Registro di classe" : "Registro del docente"}</Typography>
                            <Typography color="text.disabled">{nomeClasse}</Typography>
                            <Typography color="text.disabled">Vista di sintesi ({formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine})</Typography>
                        </Breadcrumbs>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                            <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                Torna alla selezione della classe
                            </Button>
                            <Button sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                Cambia periodo di interesse ({formattedPeriodoDiInteresseInizio}
                                {formattedPeriodoDiInteresseFine != null && formattedPeriodoDiInteresseFine !== formattedPeriodoDiInteresseInizio ? (
                                    " - " + formattedPeriodoDiInteresseFine
                                ) : ""})
                            </Button>
                            <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                Mostra legenda
                            </Button>
                        </Box>

                        <Box sx={{ height: '80vh', p: 5 }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <MultiGrid
                                        ref={tableRef}
                                        columnCount={arrayGiorni.length}
                                        columnWidth={getColumnWidth(width)}
                                        deferredMeasurementCache={cache.current}
                                        height={height}
                                        rowHeight={cache.current.rowHeight}
                                        rowCount={listAlunni.length + 1}
                                        cellRenderer={cellRenderer}
                                        width={width}
                                        fixedRowCount={1} 

                                        fixedColumnCount={1} 
                                        overscanRowCount={listAlunni.length}
                                    />
                                )}
                            </AutoSizer>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento registro...</Typography>
                    </Box>
                )
            ) : null
            }

            <Dialog open={changePeriodoDiInteresseOpen} >
                <DialogTitle>Seleziona il periodo di interesse</DialogTitle>
                <DialogContent>
                    <RangeDate idClasse={idClasseSelezionata} dataDiInteresseInizio={periodoDiInteresseInizio} dataDiInteresseFine={periodoDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen}>
                <DialogTitle mt={3}>Seleziona classe di riferimento</DialogTitle>
                <DialogContent>
                    {isDocente ? (
                        <SelezioneLezioneNew tipoRegistro={tipoRegistro} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} />
                    ) : (
                        <SelezioneClasseOperatore tipoRegistro={tipoRegistro} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} />
                    )}
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dettaglioAlunnoOpen} onClose={closeDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <span>Dettaglio alunno ({nomeAlunnoSelezionato})</span>
                    <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno  tabIndex={indexTabToOpen} idDocente={idDocente} id={idAlunnoSelezionato}
                        nomeAlunno={nomeAlunnoSelezionato} ritardo={openDialogRitardo} closeDettaglioAlunno={closeDettaglioAlunno} openAlertMsg={openAlertMsg} />
                </DialogContent>
            </Dialog>

            <Dialog fullScreen maxWidth="none" open={dialogDettaglioRegistroOpen}>
                <DialogTitle>{tipoRegistro == "classe" ? 'Registro di classe' : 'Registro del docente'} ({getDayMonthAndYearErrorDialog(dataRichiestaDettaglio)})</DialogTitle>
                {tipoRegistro == "classe" ? (
                    <RegistroDiClasse permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} nomeClasse={nomeClasse} hasDocenteFunctions={hasDocenteFunctions}
                        dataRichiesta={dataRichiestaDettaglio} closeRegistro={closeRegistro} oreDiLezioneClasseSelezionata={oreDiLezioneClasseSelezionata} sedeUtente={sedeUtente} nomeMateriaSelezionata={nomeMateriaSelezionata} />
                ) : (
                    <RegistroDocente permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} nomeClasse={nomeClasse} hasDocenteFunctions={hasDocenteFunctions}
                        dataRichiesta={dataRichiestaDettaglio} closeRegistro={closeRegistro} nomeMateria={nomeMateriaSelezionata} sedeUtente={sedeUtente} />
                )}
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <span>Legenda</span>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Legende />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


            <Helmet>
                <title>{t("name")} - {t("pages.registroClasse.title")}</title>
            </Helmet>
        </Fragment>
    );
}