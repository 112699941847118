import { Fragment, useEffect, useState } from "react";
import { Button, FormControl, TextField, Container, Select, Snackbar, Alert, Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { RegistroAPI } from "../../../api/registroApi";
import { EventiAPI } from "../../../api/eventiApi";
import { convertDataGetProssimeLezione, convertISODate } from "../../shared/metodiDateUtility";
import { UtilityAPI } from "../../../api/utilityApi";
import DialogConferma from "../../shared/dialogConferma";
import { Delete, Edit } from "@mui/icons-material";

export default function EventoPrerequisitoRegistro(props) {

    const [comunicazione, setComunicazione] = useState(null);
    // const [isDocente, setIsDocente] = useState(true);

    const [data, setData] = useState(null);
    const [isModificaMode, setIsModificaMode] = useState(false);
    const [votoModificabile, setVotoModificabile] = useState(false);
    const [exAnte, setExAnte] = useState(false);
    const [voto, setVoto] = useState('');
    const [tipologiaVoto, setTipologiaVoto] = useState('');
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [idVoto, setIdVoto] = useState(-1);
    const [materiaSelected, setMateriaSelected] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);

    const [isVoto, setIsVoto] = useState(null);

    const [alunno, setAlunno] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertMsg, setAlertMsg] = useState("");

    const [permessiUtente, setPermessiUtente] = useState([]);
    const [utenteCanEdit, setUtenteCanEdit] = useState(false);

    const [modificaMode, setModificaMode] = useState(false);


    const [cantModify, setCantModify] = useState(false);

    const [showPulsanteEdit, setShowPulsanteEdit] = useState(false);
    const [showPulsanteDelete, setShowPulsanteDelete] = useState(false);
    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] = useState(false);
    const [confirmEditDialogIsOpen, setConfirmEditDialogIsOpen] = useState(false);

    useEffect(() => {
        if (props.cantModify !== undefined)
            setCantModify(props.cantModify)
    }, [props.cantModify])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
            if (props.permessiUtente.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) {
                setUtenteCanEdit(true)
            } else setUtenteCanEdit(false)
        }
    }, [props.permessiUtente])


    useEffect(() => {
        let cantModify = false;
        if (props.comunicazione !== undefined && props.cantModify !== undefined && props.permessiUtente !== undefined) {
            cantModify = props.cantModify
            resetModifiche(cantModify, props.permessiUtente);
        }
        else if (props.comunicazione !== undefined && props.permessiUtente !== undefined)
            resetModifiche(cantModify, props.permessiUtente);

    }, [props.comunicazione, props.cantModify, props.permessiUtente]);

    useEffect(() => {
        if (props.alunno !== undefined) {
            setAlunno(props?.alunno)
        }
    }, [props.alunno])

    function changeVoto(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            // newStr = newValue.replace(/(\d)[a-zA-Z]/g, '$1');
            // newStr = newValue.replace(/[-/]/g, "");
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr === 0) newStr = 'N.V.';
        } else {
            newStr = ""; //resetta a ''
        }

        setVoto(newStr);
    }
    function changeExAnte() {
        setExAnte(prev => !prev)
    }
    function changeMateria(e) {
        setMateriaSelected(e.target.value)
    }
    function changeData(value) {
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria);
    }

    function annullaModifiche() {
        setModificaMode(false);
        resetModifiche(cantModify, permessiUtente);
        setIsModificaMode(false);
    }

    function closeAlert() {
        setAlertOpen(false);
    }

    function resetModifiche(cantModify, permessiUtenteTmp) {
        if (props?.comunicazione !== undefined) {

            setComunicazione(props?.comunicazione)
            //2022-10-02T22:00:00.000Z - FORMATO ISO
            let newDataTmp = DateTime.fromISO(props?.comunicazione?.contenuto?.dataLezione);
            setIdVoto(props?.comunicazione?.id)
            setOraSelected(props?.comunicazione?.contenuto?.idOrario)
            setData(newDataTmp);

            setExAnte(props?.comunicazione?.contenuto?.is_ex_ante)
            setVotoModificabile(props?.comunicazione?.contenuto?.editable ?? false);
            setVoto(props?.comunicazione?.contenuto?.voto ?? '');
            setIsVoto(props?.comunicazione?.contenuto?.voto === undefined ? false : true)
            if (props?.comunicazione?.contenuto?.voto !== undefined) setTipologiaVoto(props?.comunicazione?.contenuto?.tipologia)
            // setMaterie([...materie, props.comunicazione.materia])
            setMateriaSelected(props?.comunicazione?.contenuto?.materia.idMateria);
            // let typeValutazione = props?.comunicazione?.tipologia_comunicazioni === 4 ? 0 : 1;
            setIdMateria(props?.comunicazione?.contenuto?.materia?.idMateria);
            setIdCorso(props?.comunicazione?.albero_destinatari?.idCorso[0]);
            setIdDocente(props?.comunicazione?.contenuto?.docente?.idDocente);

            canAccessButtons(props.comunicazione, permessiUtenteTmp)

            if (!cantModify)
                getOreLezione(props?.comunicazione?.contenuto?.dataLezione, props?.comunicazione?.albero_destinatari?.idCorso[0], props?.comunicazione?.contenuto?.docente?.idDocente, props?.comunicazione?.contenuto?.materia?.idMateria, props?.comunicazione?.contenuto?.idOrario)
            else
                getDettaglioOrarioRiepilogoFormativo(props?.comunicazione?.contenuto?.idOrario)
        }
    }

    function changeOra(e, o) {
        setOraSelected(e.target.value);
        // setIdMateria(o.props.idmateria);
    }



    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, idOrario) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)

        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            idMateria: idMateria
        }

        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                setElencoOre([...prossimeOreTmp]);
                if (prossimeOreTmp.length > 0) {
                    if (prossimeOreTmp.some(ora => ora.idOrario === idOrario)) {
                        setIdMateria(prossimeOreTmp[0].materia.idMateria)
                        setOraSelected(idOrario);
                    }
                    else {
                        setOraSelected(-1);
                        // setIdMateria(-1);
                    }
                }
                else {
                    setOraSelected(-1);
                    // setIdMateria(-1);
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    async function getDettaglioOrarioRiepilogoFormativo(idOrario) {
        let dataObj = {
            idOrario: idOrario,
        }

        UtilityAPI.dettaglioIdOrario(dataObj)
            .then((result) => {
                setElencoOre([result]);

            })
            .catch((error) => {
                console.error(error)
            })
    }

    async function modificaVoto() {

        const obj = {
            voto: voto === 'N.V.' ? '0' : voto,
            note: '',
            idAlunno: [alunno.id],
            idOrario: oraSelected,
            is_ex_ante: exAnte,
            tipologia: tipologiaVoto
        }

        EventiAPI.modificaPrerequisito(obj, idVoto)
            .then((result) => {
                aggiornaRegistro("Voto modificato", "success")
                if (props.closeDialog) props.closeDialog()
            })
            .catch((error) => {
                let errorMsg = "";

                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = "Al momento non è possibile completare l'operazione";
                }

                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }



    function closeAlert() {
        setAlertOpen(false)
    }

    function aggiornaRegistro(alertMsg, alertSeverity) {
        if (props.aggiornaRegistro) props.aggiornaRegistro(alertMsg, alertSeverity)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }

    async function canAccessButtons(objEvento, permessiUtenteTmp) {
        let idEventoTmp = objEvento?.id;
        let isEditable = objEvento?.contenuto?.editable;

        let dataObj = {
            idEvento: idEventoTmp
        }
        if (idEventoTmp !== -1 && isEditable && permessiUtenteTmp.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) {
            RegistroAPI.showPulsantiEvento(dataObj)
                .then((result) => {

                    setShowPulsanteEdit(result?.showPulsanteEdit ?? false)
                    setShowPulsanteDelete(result?.showPulsanteDelete ?? false)
                })
                .catch(function (error) { })
        }
    }

    async function deleteEvento() {
        let dataObj = {
            idEvento: comunicazione?.id,
            tipo: comunicazione?.tipologia_comunicazioni
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.eventoAggiunto) {
                    let msgSuccess = "Voto eliminato";
                    props.eventoAggiunto(msgSuccess, "success");
                }

            })
            .catch(function (error) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto(error.response.data.data[0].msg, "error");
            })
    }

    return (

        <Fragment>
            <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Snackbar onClose={closeAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen}>
                    <Alert sx={{ width: '100%', mb: 2 }} severity={alertSeverity} >
                        {alertMsg}
                    </Alert>
                </Snackbar>

                <form style={{ width: '100%' }}>

                    <Grid container sx={{ width: '100%', pt: 2 }}>

                        {!modificaMode && (<Grid xs={12} md={12} mb={2}>
                            <Typography><b>Data di inserimento:</b>  {convertISODate(comunicazione?.created_at)} {'(Inserito da: ' + comunicazione?.created_by_obj?.nome + ')'};</Typography>
                        </Grid>)}

                        {(!modificaMode && comunicazione?.modified_at !== null) && (<Grid xs={12} md={12} mb={2}>
                            <Typography><b>Data di modifica:</b>  {convertISODate(comunicazione?.modified_at)}  {'(Modificato da: ' + comunicazione?.modified_by_obj?.nome + ')'};</Typography>
                        </Grid>)}
                        <Grid xs={12} md={12} mb={2}>
                            <TextField sx={{ width: '100%' }} data-testid='dataValutazioneInput'
                                label="Alunno" disabled value={alunno?.nome + ' ' + alunno?.cognome}
                            />
                        </Grid>
                        <Grid container xs={12} md={12} spacing={2}>
                            <Grid xs={12} md={6} >
                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                    <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                        disabled
                                        label="Data"
                                        maxDate={DateTime.now()}
                                        value={data}
                                        format="dd/MM/yyyy"
                                        onChange={(value) => changeData(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid xs={12} md={6} mb={2}>
                                {/* {oraSelected !== -1 ? ( */}
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="materia-label">
                                        Seleziona ora</InputLabel>
                                    <Select
                                        data-testid='selMateriaNotaSelect'
                                        label='Seleziona ora '
                                        labelId="materia-label"
                                        value={oraSelected}
                                        onChange={changeOra}
                                        disabled
                                    >
                                        {elencoOre.map((ora) => (
                                            <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta, la data selezionata non verrà salvata</Typography>) : null}
                                </FormControl>
                                {/* ) : (
                                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                            )} */}
                            </Grid >
                        </Grid>
                        <Grid container xs={12} md={12} spacing={2}>
                            {isVoto && (<Grid xs={12} md={6} >
                                <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                    label="Tipologia voto"
                                    disabled
                                    onChange={changeVoto}
                                    value={tipologiaVoto}
                                // helperText={nomeMessage} 
                                />
                            </Grid>)}
                            < Grid xs={12} md={6} >
                                <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                    label="Voto"
                                    disabled={!modificaMode}
                                    onChange={changeVoto}
                                    value={voto === '0' ? 'N.V.' : voto}
                                // helperText={nomeMessage} 
                                />

                            </Grid >
                        </Grid>

                    </Grid >


                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"} sx={{ mt: 3, width: '100%' }}>
                        <Box>
                            {showPulsanteDelete && !modificaMode ? (
                                <Button onClick={() => { setConfirmDeleteDialogIsOpen(true) }} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Elimina voto</Button>
                            ) : null}
                        </Box>
                        <Box>
                            {showPulsanteEdit ? (
                                <Box>
                                    {!modificaMode ? (
                                        <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">
                                            chiudi dettaglio
                                        </Button>
                                    ) : (
                                        <Button onClick={() => annullaModifiche()} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">
                                            Annulla modifiche
                                        </Button>
                                    )}

                                    {!modificaMode ? (
                                        <Button onClick={() => setModificaMode(true)} disabled={voto === ''} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                            variant="contained" >
                                            Modifica voto
                                        </Button>
                                    ) : (
                                        <Button onClick={() => { setConfirmEditDialogIsOpen(true) }} disabled={voto === '' && (oraSelected === -1 || elencoOre.length === 0)}
                                            sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} color="success" variant="contained" data-testid='modificaValutazioneBtn' >
                                            Conferma Modifica
                                        </Button>
                                    )}
                                </Box>
                            ) : (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">chiudi dettaglio</Button>
                            )}
                        </Box>
                    </Box>
                </form >
            </Container >

            <DialogConferma
                isDialogOpen={confirmDeleteDialogIsOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare il voto? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfirmDeleteDialogIsOpen(false) }}
                conferma={deleteEvento}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confirmEditDialogIsOpen}
                title={'Conferma modifica'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare il voto? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={() => { setConfirmEditDialogIsOpen(false) }}
                conferma={modificaVoto}
                disabledButton={false}
            />
        </Fragment >


        // <Box display={"flex"} alignItems={"center"}>
        //     <TextField disabled={!modificaVoto} type="number" onChange={changeVoto} value={voto} InputProps={{
        //         endAdornment: <InputAdornment position="start">/ 60</InputAdornment>,
        //     }} />
        //     {votoModificabile ? (
        //         <Fragment>
        //             {modificaVoto ? (
        //                 <Button sx={{ ml: 2 }} startIcon={<Edit />} color="success" variant="contained">Conferma modifiche</Button>
        //             ) : (
        //                 <Button sx={{ ml: 2 }} onClick={modificaVotoMetodo} startIcon={<Edit />} variant="contained">Modifica voto</Button>
        //             )}
        //         </Fragment>
        //     ) : null}
        // </Box>
    )
}