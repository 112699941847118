import { DateTime } from "luxon";
import { convertDataDiNascita, convertDataGetProssimeLezione } from "./metodiDateUtility";

export function copyArrayWithoutReference(obj) { //Utilizzare quando ci sono array che mantengono i riferimenti
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let copy = obj.constructor();
    for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
            copy[attr] = copyArrayWithoutReference(obj[attr]);
        }
    }
    return copy;
}


/**
* Prende in entrata un voto (stringa), se il secondo parametro è true, restituisce il voto convertito in intero
* se il voto è N.V. o / ritorna 0 o -1
*
 * @param {string | null} votoTmp - Il voto in entrata.
 * @param {boolean} returnInt -Se impostato a true ritorna il valore come intero.
 * @param {boolean} canAcceptVotoSlash -Se impostato a true può accettare il voto "/".
 * @param {boolean} canAcceptNV -Se impostato a true può accettare il voto "N.V.".
 * @param {int} maxNumberToReturnTmp Il numero massimo che può accettare l'input.
 * @param {boolean} notReturnEmptyString -Se impostato a true ritorna il null invece che una stringa vuota.

 * @returns {number | string} Ritorna il voto a stringa o intero
 */
export function regexInserimentoVoto(votoTmp, returnInt, canAcceptVotoSlash, canAcceptNV, maxNumberToReturnTmp, notReturnEmptyString) {

    if (votoTmp !== undefined && votoTmp !== null) {
        let votoParsed;
        if (typeof (votoTmp) != "string") { // Se non è stringa lo converto
            votoParsed = votoTmp.toString();
        } else {
            votoParsed = votoTmp;
        }

        let maxNumberToReturn = maxNumberToReturnTmp !== undefined ? maxNumberToReturnTmp : 30;
        let newValue = votoParsed;
        let newStr = "";

        if (newValue === "N.V") { // Se la stringa è così allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true && canAcceptNV) {
            newStr = 'N.V.';
        } else if ((canAcceptVotoSlash !== undefined && canAcceptVotoSlash) && newValue === "/") {
            newStr = "/";
        } else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, ''); // Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, ''); // Rimuove lo 0 se è seguito da un numero 01 => 1
            if (parseInt(newStr) > maxNumberToReturn) newStr = maxNumberToReturn.toString(); // Non permette di superare il voto massimo (di default 30)
            if (newStr == 0 && canAcceptNV) newStr = 'N.V.';
        }


        if (returnInt !== undefined && returnInt == true) {
            let votoParsed = 0;
            if (newStr == "N.V." || newStr == "N.V") {
                votoParsed = 0;
            } else if (newStr == "/") {
                votoParsed = -1;
            } else {
                votoParsed = parseInt(newStr); // Se non è né N.V. né / allora è un numero
            }
            return votoParsed;
        } else {
            return newStr !== "" ? newStr : (notReturnEmptyString === true ? null : "");
        }
    } else {
        return notReturnEmptyString === true ? null : "";
    }
}

/**
* Prende in entrata un voto (intero), lo converte in stringa, in base ai parametri ritorna N.V. e /
*
 * @param {int | null} votoTmp - Il voto in entrata.
 * @param {boolean} canAcceptVotoSlash -Se impostato a true può accettare il voto "/".
 * @param {boolean} canAcceptNV -Se impostato a true può accettare il voto "N.V.".
 * @param {int} maxNumberToReturnTmp Il numero massimo che può accettare l'input.
 * @param {boolean} notReturnEmptyString -Se impostato a true ritorna il null invece che una stringa vuota.

 * @returns {number | string} Ritorna il voto a stringa o intero
 */
export function regexGetVoto(votoTmp, canAcceptVotoSlash, canAcceptNV, maxNumberToReturnTmp, notReturnEmptyString) {
    let votoParsed = "";
    if (votoTmp !== undefined && votoTmp !== null) {
        if (typeof (votoTmp) != "string") { //Se non è stringa lo converto
            votoParsed = votoTmp.toString()
        } else votoParsed = votoTmp;

        let maxNumberToReturn = maxNumberToReturnTmp !== undefined ? maxNumberToReturnTmp : 30
        let newValue = votoParsed;
        let newStr = notReturnEmptyString && notReturnEmptyString === true ? null : "";

        if (votoTmp == 0) { 
            newStr = "N.V."
        } 
        else if (canAcceptVotoSlash !== undefined && canAcceptVotoSlash === true && votoTmp == -1) {
            newStr = "/"
        }
        else if (/[0-9]/g.test(votoTmp) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > maxNumberToReturn) newStr = maxNumberToReturn.toString(); //non permette di superare il voto massimo (di default 30)
            if (newStr == 0 && canAcceptNV) newStr = 'N.V.'
        } else {
            newStr = notReturnEmptyString && notReturnEmptyString === true ? null : "";
        }
       return newStr;
    } else return notReturnEmptyString && notReturnEmptyString === true ? null : "";
}

/**
* Prende in entrata un voto, restituisce "green" se il voto è maggiore o uguale a 18, altrimenti restituisce "red".
* Se il voto è / o N.V. o null restituisce "black"
*
* @param {string | int} votoTmp - Il voto in entrata.
* @returns {string} Ritorna il colore
*/
export function changeColorVoto(votoTmp) {
    if (votoTmp == "/" || votoTmp == "N.V." || votoTmp == "N.V" || votoTmp == "" || votoTmp == null || votoTmp == -1 || votoTmp == undefined) return "black";
    else if (votoTmp < 18) return "red"
    else return "green"
}


/**
*Ritorna l'anno formativo sotto forma di stringa o l'oggetto dell'anno formativo selezionato
*
* @param {bool} getAnnoFormativoObj - Se si vuole l'oggetto dell'anno formativo invece che solo la stringa (id) dell'anno formativo
* @returns {string} Ritorna l'id dell'anno formativo o l'oggetto
*/
export function getAnnoFormativo(getAnnoFormativoObj) {
    let annoFormativoTmp = '';
    if (getAnnoFormativoObj == undefined || !getAnnoFormativoObj) {
        if (sessionStorage.getItem("annoFormativo")) {
            annoFormativoTmp = sessionStorage.getItem("annoFormativo");
        } else {
            annoFormativoTmp = process.env.REACT_APP_ANNO_SCOLASTICO;
        }
        return annoFormativoTmp
    } else {
        let annoFormativoObjTmp = null;
        if (sessionStorage.getItem("annoFormativoObj")) {
            annoFormativoObjTmp = sessionStorage.getItem("annoFormativoObj");
        }
        return annoFormativoObjTmp
    }
}

/**
*Ritorna l'anno formativo sotto forma di stringa o l'oggetto dell'anno formativo selezionato
* @param {int} dataDiInteresseProps - Se si ha una data di interesse

*/
export function getDataInizioFinePeriodo(dataDiInteresseProps) {
    let dataDiInteresse = null;

    if (sessionStorage.getItem("annoFormativoObj")) {

        let decryptedAnnoFormativoObj = sessionStorage.getItem("annoFormativoObj");
        let annoFormativoObjParsed = JSON.parse(decryptedAnnoFormativoObj);
        let inizioPeriodoTmp = DateTime.fromISO(annoFormativoObjParsed?.dataDA ?? null)
        let finePeriodoTmp = DateTime.fromISO(annoFormativoObjParsed?.dataA ?? null)
        let isAnnoFormativoCorrenteTmp = annoFormativoObjParsed?.isAnnoFormativoCorrente;
      
        if (isAnnoFormativoCorrenteTmp) {
            let dataTmp = dataDiInteresseProps !== undefined ? dataDiInteresseProps : DateTime.now()
            dataDiInteresse = dataTmp;
        } else {
            dataDiInteresse = finePeriodoTmp;
        }

        let objToReturn = {
            inizioPeriodo: inizioPeriodoTmp,
            finePeriodo: finePeriodoTmp,
            dataDiInteresse: dataDiInteresse,
            isAnnoFormativoCorrente: isAnnoFormativoCorrenteTmp
        }

        return objToReturn;

    } else {
        let dataTmp = dataDiInteresseProps !== undefined ? dataDiInteresseProps : DateTime.now()
        dataDiInteresse = dataTmp;
        let objToReturn = {
            inizioPeriodo: null,
            finePeriodo: null,
            dataDiInteresse: dataDiInteresse,
            isAnnoFormativoCorrente: true
        }
        return objToReturn;
    }
}


export function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
    let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
    let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

    let dataInizioObject = DateTime.fromISO(dataInizioTmp);
    let dataFineObject = DateTime.fromISO(dataFineTmp);

    let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
    let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);

    if (alunno?.isRitirato == 1) {
        if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        else //if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
        {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
    } else return "";
    // else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
    //   return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
    // }
    // else return null
}