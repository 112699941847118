import { Box, useTheme, Step, StepLabel, Stepper, useMediaQuery, CircularProgress, Typography, DialogTitle, Divider } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import VerificaEsistenzaAzienda from "./dettaglio-azienda/verificaEsistenzaAzienda";
import AnagraficaAzienda from "./dettaglio-azienda/anagraficaAzienda";
import TutorAzienda from "./dettaglio-azienda/tutorAzienda";
import SediAzienda from "./dettaglio-azienda/sediAzienda";
import { TirociniAPI } from "../../../api/tirociniApi";


export default function DettaglioAzienda(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [azienda, setAzienda] = useState(null);
    const [responsabileLegale, setResponsabileLegale] = useState(null);
    const [sedeLegale, setSedeLegale] = useState(null);
    const [cfPiva, setCfPiva] = useState('');
    const [radioSelectPIvaCF, setRadioSelectPIvaCF] = useState(0); // 0 = P.iva - 1= C.F.
    const [idAzienda, setIdAzienda] = useState(-1);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataExist, setDataExist] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [isModificaMode, setIsModificaMode] = useState(false);

    useEffect(() => {
        if (props.isModificaMode !== undefined) {
            setIsModificaMode(props.isModificaMode)
        }
    }, [props.isModificaMode])

    useEffect(() => {
        if (props.isCanEdit !== undefined)
            setIsCanEdit(props.isCanEdit)
    }, [props.isCanEdit])

    useEffect(() => {
        if (props.idAzienda !== undefined) {
            if (tabIndex < 2) {
                setIdAzienda(props.idAzienda)
                if (props.idAzienda === -1) setTabIndex(0)
                else {
                    setTabIndex(1)
                    getAziendaById(props.idAzienda)
                }

                setTimeout(() => { setHasLoaded(true) }, 100)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idAzienda])

    function changeIdAzienda(idAzienda) {
        setIdAzienda(idAzienda)
        if (props.changeIdAzienda) props.changeIdAzienda(idAzienda)
    }

    function changeCfPiva(cfPivaTmp, radioSelectPIvaCF, objAzienda) {
        setTabIndex(1);
        setCfPiva(cfPivaTmp); //Setter spostato sopra perchè causava problemi
        setRadioSelectPIvaCF(radioSelectPIvaCF);
        setDataExist(objAzienda[0].dataExist)
        setAzienda(objAzienda[0].azienda ?? null)
        setIdAzienda(objAzienda[0].azienda.id ?? -1)
        setResponsabileLegale(objAzienda[0].responsabileLegale ?? null)
        setSedeLegale(objAzienda[0].sedeLegale ?? null)

    }

    function previousTab() {
        setTabIndex(prev => prev - 1);
    }

    function nextTab() {
        setTabIndex(prev => prev + 1);
    }

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
        setTabIndex(0);
        setAzienda(null)
        changeIdAzienda(-1)
        setResponsabileLegale(null)
        setSedeLegale(null)
        setRadioSelectPIvaCF(0);
        setCfPiva('');
    }

    async function getAziendaById(idAzienda) {
        let obj = {
            idAzienda: idAzienda
        }
        TirociniAPI.getAziendaById(obj)
            .then((result) => {
                let objAzienda = result;
                setAzienda(objAzienda[0].azienda ?? null)
                setResponsabileLegale(objAzienda[0].responsabileLegale ?? null)
                setSedeLegale(objAzienda[0].sedeLegale ?? null)
                setDataExist(true);
            })
            .catch(function (error) {
            })

    }

    return (
        <Fragment>
            {/* <Typography variant='h6' sx={{ my: 2, mx: 3, textAlign: 'start', fontWeight: 'bold' }}>Aggiungi azienda</Typography>
            <Divider /> */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 2 }}>
                {/* <Tabs value={tabIndex}
                    // onChange={changeTabIndex} 
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label='Controllo Presenza' disabled />
                    <Tab label='Anagrafica' disabled />
                    <Tab label='Tutor' disabled />
                    <Tab label='Sedi' disabled />
                </Tabs> */}

                <Stepper activeStep={tabIndex} alternativeLabel={isMobile}>
                    <Step key={0}>
                        <StepLabel>Controllo presenza</StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Dati azienda</StepLabel>
                    </Step>
                    <Step key={2}>
                        <StepLabel>Tutor</StepLabel>
                    </Step>
                    <Step key={3}>
                        <StepLabel>Sedi</StepLabel>
                    </Step>
                </Stepper>
            </Box>
            <Box sx={{ p: 0 }}>
                {hasLoaded ? (
                    <Fragment>
                        {tabIndex === 0 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Azienda: verifica azienda</Typography>
                                </DialogTitle>
                                <Divider />
                                <VerificaEsistenzaAzienda
                                    changeCfPiva={changeCfPiva}
                                    closeDialog={closeDialog} />
                            </Fragment>
                        )}
                        {tabIndex === 1 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Azienda: dati azienda</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaAzienda
                                    idAzienda={idAzienda}
                                    changeIdAzienda={changeIdAzienda}
                                    nextTab={nextTab}
                                    closeDialog={closeDialog}
                                    azienda={azienda}
                                    responsabileLegale={responsabileLegale}
                                    sedeLegale={sedeLegale}
                                    cfPiva={cfPiva}
                                    radioSelectPIvaCF={radioSelectPIvaCF}
                                    dataExist={dataExist}
                                    isCanEdit={isCanEdit}
                                    isModificaMode={isModificaMode}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 2 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Azienda: tutor azienda</Typography>
                                </DialogTitle>
                                <Divider />
                                <TutorAzienda
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                    closeDialog={closeDialog}
                                    idAzienda={idAzienda}
                                    dataExist={dataExist}
                                    isCanEdit={isCanEdit}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 3 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Azienda: sedi azienda</Typography>
                                </DialogTitle>
                                <Divider />
                                <SediAzienda
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                    closeDialog={closeDialog}
                                    idAzienda={idAzienda}
                                    dataExist={dataExist}
                                    isCanEdit={isCanEdit}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress sx={{ mb: 4 }} />
                        <Typography >Caricamento in corso...</Typography>
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}