import { Box, useTheme, Step, StepLabel, Stepper, useMediaQuery, CircularProgress, Typography, DialogTitle, Divider, Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
// import VerificaEsistenzaAzienda from "./dettaglio-azienda/verificaEsistenzaAzienda";
// import AnagraficaAzienda from "./dettaglio-azienda/anagraficaAzienda";
// import TutorAzienda from "./dettaglio-azienda/tutorAzienda";
// import SediAzienda from "./dettaglio-azienda/sediAzienda";
import { TirociniAPI } from "../../../api/tirociniApi";
import RicercaConvenzione from "./dettaglio-progetto-formativo/ricercaConvenzione";
import RicercaAlunno from "./dettaglio-progetto-formativo/ricercaAlunno";
import AnagraficaTirocinante from "./dettaglio-progetto-formativo/anagraficaTirocinante";
import AnagraficaAzienda from "./dettaglio-progetto-formativo/anagraficaAzienda";
import OrarioFormativo from "./orarioFormativo";
import GestioneOrario from "./dettaglio-progetto-formativo/gestioneOrario";
import AnagraficaAltriDati from "./dettaglio-progetto-formativo/anagraficaAltriDati";
import AnagraficaObiettiviModalita from "./dettaglio-progetto-formativo/anagraficaObiettiviModalita";

export default function DettaglioProgettoFormativo(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [idAzienda, setIdAzienda] = useState(-1);
    const [idAlunno, setIdAlunno] = useState(-1);
    const [idConvenzione, setIdConvenzione] = useState(-1);
    const [idProgettoFormativo, setIdProgettoFormativo] = useState(-1);
    const [protocolloConvenzione, setProtocolloConvenzione] = useState(undefined);
    const [tabIndex, setTabIndex] = useState(0);
    const [hasLoaded, setHasLoaded] = useState(true);

    const [isVisualizza, setIsVisualizza] = useState(false);

    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            setIdProgettoFormativo(props.idProgettoFormativo);
            if (props.idProgettoFormativo === -1) {
                setTabIndex(0);
            }
            else {
                setTabIndex(2);
            }
            setTimeout(() => { setHasLoaded(true) }, 100)
        }
    }, [props.idProgettoFormativo])

    useEffect(() => {
        if (props.protocolloConvenzione !== undefined)
            setProtocolloConvenzione(props.protocolloConvenzione);
    }, [props.protocolloConvenzione])

    useEffect(() => {
        if (props.idConvenzione !== undefined) {
            setIdConvenzione(props.idConvenzione)
        }
    }, [props.idConvenzione])

    function changeIdConvenzione(idConvenzione) {
        setIdConvenzione(idConvenzione)
        if (props.changeIdConvenzione) props.changeIdConvenzione(idConvenzione)
    }

    function changeIdAlunno(idAlunno) {
        setIdAlunno(idAlunno)
    }

    // function changeTabIndex(event, newValue) {
    //     setTabIndex(newValue);
    // }


    function previousTab() {
        setTabIndex(prev => prev - 1);
    }

    function nextTab() {
        setTabIndex(prev => prev + 1);
    }

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
        setTabIndex(0);
        changeIdConvenzione(-1);
        changeIdProgettoFormativo(-1);
        // setIsVisualizza(false);
    }

    function changeIdProgettoFormativo(idProgettoFormativo) {
        setIdProgettoFormativo(idProgettoFormativo);
        if (props.changeIdProgettoFormativo) props.changeIdProgettoFormativo(idProgettoFormativo);
    }

    function proceduraConclusa() {
        if (props.proceduraConclusa) props.proceduraConclusa(idProgettoFormativo);
    }

    return (
        <Fragment>
            {!isVisualizza && (<Box sx={{ borderBottom: 1, borderColor: 'divider', p: 2 }}>
                <Stepper activeStep={tabIndex} alternativeLabel={isMobile}>
                    <Step key={0}>
                        <StepLabel>Selezione convenzione</StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Selezione alunno</StepLabel>
                    </Step>
                    <Step key={2}>
                        <StepLabel>Dati tirocinante</StepLabel>
                    </Step>
                    <Step key={3}>
                        <StepLabel>Dati azienda e sede</StepLabel>
                    </Step>
                    <Step key={4}>
                        <StepLabel>Orario attività formativa</StepLabel>
                    </Step>
                    <Step key={5}>
                        <StepLabel>Altri dati</StepLabel>
                    </Step>
                    <Step key={6}>
                        <StepLabel>Obiettivi e modalità </StepLabel>
                    </Step>
                </Stepper>
            </Box>)}
            <Box sx={{ p: 0 }}>
                {hasLoaded ? (
                    <Fragment>
                        {tabIndex === 0 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: selezione convenzione</Typography>
                                </DialogTitle>
                                <Divider />
                                <RicercaConvenzione
                                    protocolloConvenzione={protocolloConvenzione}
                                    closeDialog={closeDialog}
                                    changeIdConvenzione={changeIdConvenzione}
                                    nextTab={nextTab}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 1 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: selezione alunno</Typography>
                                </DialogTitle>
                                <Divider />
                                <RicercaAlunno
                                    closeDialog={closeDialog}
                                    idConvenzione={idConvenzione}
                                    changeIdAlunno={changeIdAlunno}
                                    changeIdConvenzione={changeIdConvenzione}
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                    changeIdProgettoFormativo={changeIdProgettoFormativo}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 2 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: dati tirocinante</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaTirocinante
                                    idConvenzione
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeDialog={closeDialog}
                                    nextTab={nextTab}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 3 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: dati Azienda e sede</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaAzienda
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeDialog={closeDialog}
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 4 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: orario attività formativa</Typography>
                                </DialogTitle>
                                <Divider />
                                <GestioneOrario
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeDialog={closeDialog}
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 5 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: altri dati</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaAltriDati
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeDialog={closeDialog}
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 6 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: obiettivi e modalità</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaObiettiviModalita
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeDialog={closeDialog}
                                    proceduraConclusa={proceduraConclusa}
                                    // nextTab={nextTab}
                                    previousTab={previousTab}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress sx={{ mb: 4 }} />
                        <Typography >Caricamento in corso...</Typography>
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}