import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon';
import { convertDataScrutinio } from '../../shared/metodiDateUtility';
import { WarningAmber } from '@mui/icons-material';

export default function RowCorsoGestioneCalendarioColloqui(props) {
    const [corso, setCorso] = useState(null);

    const [objPrimoColloquio, setObjPrimoColloquio] = useState(null);
    const [objSecondoColloquio, setObjSecondoColloquio] = useState(null);

    const [dataPrimoColloquio, setDataPrimoColloquio] = useState(null);
    const [dataSecondoColloquio, setDataSecondoColloquio] = useState(null);

    const [dataPrimoColloquioDefaultValue, setDataPrimoColloquioDefaultValue] = useState(null);
    const [dataSecondoColloquioDefaultValue, setDataSecondoColloquioDefaultValue] = useState(null);

    const [dataInizioAnnoFormativo, setDataInizioAnnoFormativo] = useState(null);
    const [dataFineAnnoFormativo, setDataFineAnnoFormativo] = useState(null);

    // let minDate = DateTime.local();

    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true)

    useEffect(()=>{
        if(props.isAnnoFormativoCorrente !== undefined)
            setIsAnnoFormativoCorrente(props?.isAnnoFormativoCorrente ?? true)
    },[props.isAnnoFormativoCorrente])

    useEffect(() => {
        setCorso(props.corso);
        if (props?.corso?.primoColloquio !== undefined) {
            setObjPrimoColloquio(props?.corso?.primoColloquio)
            if (props?.corso?.primoColloquio?.data != null) {
                let dataObjLuxon = DateTime.fromISO(props?.corso?.primoColloquio?.data);
                setDataPrimoColloquio(dataObjLuxon);
                setDataPrimoColloquioDefaultValue(dataObjLuxon);
            }
        }
        if (props?.corso?.secondoColloquio !== undefined) {
            setObjSecondoColloquio(props?.corso?.secondoColloquio);
            if (props?.corso?.secondoColloquio?.data != null) {
                let dataObjLuxon = DateTime.fromISO(props?.corso?.secondoColloquio?.data);
                setDataSecondoColloquio(dataObjLuxon);
                setDataSecondoColloquioDefaultValue(dataObjLuxon);
            }
        }
    }, [])

    useEffect(() => {
        setCorso(props.corso);
        if (props?.corso?.primoColloquio !== undefined) {
            setObjPrimoColloquio(props?.corso?.primoColloquio)
            if (props?.corso?.primoColloquio?.data != null) {
                let dataObjLuxon = DateTime.fromISO(props?.corso?.primoColloquio?.data);
                setDataPrimoColloquio(dataObjLuxon);
                setDataPrimoColloquioDefaultValue(dataObjLuxon);
            } else {
                setDataPrimoColloquio(null);
                setDataPrimoColloquioDefaultValue(null);
            }
        }
        if (props?.corso?.secondoColloquio !== undefined) {
            setObjSecondoColloquio(props?.corso?.secondoColloquio);
            if (props?.corso?.secondoColloquio?.data != null) {
                let dataObjLuxon = DateTime.fromISO(props?.corso?.secondoColloquio?.data);
                setDataSecondoColloquio(dataObjLuxon);
                setDataSecondoColloquioDefaultValue(dataObjLuxon);
            }
            else {
                setDataSecondoColloquio(null);
                setDataSecondoColloquioDefaultValue(null);
            }
        }
    }, [props.indexSalvataggio])

    useEffect(() => {
        if (props.dataDA !== undefined) {
            setDataInizioAnnoFormativo(props.dataDA);
        }
    }, [props.dataDA]);

    useEffect(() => {
        if (props.dataA !== undefined) {
            setDataFineAnnoFormativo(props.dataA);
        }
    }, [props.dataA]);

    function changeDataPrimoColloquio(value) {
        setDataPrimoColloquio(value);

        let objColloquioTmp = objPrimoColloquio;
        objColloquioTmp.data = convertDataScrutinio(value, true);

        if (dataPrimoColloquioDefaultValue == null || (dataPrimoColloquioDefaultValue != null && !dataPrimoColloquioDefaultValue.hasSame(value, 'millisecond'))) //Se quando modifico metto la stessa data che c'era prima allora non è stato realmente editato
            objColloquioTmp.isEditato = true;
        else objColloquioTmp.isEditato = false;

        props.changedDate(props?.corso?.idCorso, objColloquioTmp, objSecondoColloquio)
    }

    function changeDataSecondoColloquio(value) {
        setDataSecondoColloquio(value);

        let objColloquioTmp = objSecondoColloquio;
        objColloquioTmp.data = convertDataScrutinio(value, true);

        if (dataSecondoColloquioDefaultValue == null || (dataSecondoColloquioDefaultValue != null && !dataSecondoColloquioDefaultValue.hasSame(value, 'millisecond'))) //Se quando modifico metto la stessa data che c'era prima allora non è stato realmente editato
            objColloquioTmp.isEditato = true;
        else objColloquioTmp.isEditato = false;

        props.changedDate(props?.corso?.idCorso, objPrimoColloquio, objColloquioTmp)
    }

    return (
        <TableRow>
            <TableCell align="center" sx={{ minWidth: '100px', borderRight: '2px solid black' }} >
                <Typography textAlign={"start"}>
                    {corso?.codiceCorso} - {corso?.nomeCorso} ({corso?.CodSimon})
                </Typography>
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '100px', borderRight: '2px solid black', }} >
                <Box sx={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ borderRadius: '6px', border: (corso?.primoColloquio?.isEditato !== undefined && corso?.primoColloquio?.isEditato === true) ? '1px solid #ed6c02' : '0px solid', p: 1, width: 'fit-content' }}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['year', 'month', 'day']}
                                sx={{
                                    '& input': { textAlign: 'center' }
                                }}
                                value={dataPrimoColloquio}
                                disabled={!corso?.primoColloquio?.editable || !isAnnoFormativoCorrente}
                                format="dd/MM/yyyy"
                                minDate={dataInizioAnnoFormativo}
                                maxDate={dataSecondoColloquio != null ? dataSecondoColloquio.minus({ days: 1 }) : dataFineAnnoFormativo}
                                onChange={(value) => changeDataPrimoColloquio(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}                            />
                        </LocalizationProvider>
                        {(corso?.primoColloquio?.isEditato !== undefined && corso?.primoColloquio?.isEditato === true) && (
                            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <WarningAmber sx={{ color: '#ed6c02', mr: 1 }} />
                                <Typography color="#ed6c02" variant="subtitle2" >
                                    Data modificata (<b>non salvata</b>)
                                </Typography>
                            </Box>
                        )}
                        {!corso?.primoColloquio?.editable && (
                            <Typography sx={{ mt: 1 }} variant="subtitle2" color="primary">Colloquio già svolto</Typography>
                        )}
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '100px', borderRight: '2px solid black', }} >
                <Box sx={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ borderRadius: '6px', border: (corso?.secondoColloquio?.isEditato !== undefined && corso?.secondoColloquio?.isEditato === true) ? '1px solid #ed6c02' : '0px solid', p: 1, width: 'fit-content' }}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['year', 'month', 'day']}
                                sx={{
                                    '& input': { textAlign: 'center' }
                                }}
                                value={dataSecondoColloquio}
                                disabled={!corso?.secondoColloquio?.editable || !isAnnoFormativoCorrente}
                                format="dd/MM/yyyy"
                                minDate={dataPrimoColloquio != null ? (dataPrimoColloquio.plus({ days: 1 }) > dataInizioAnnoFormativo ? dataPrimoColloquio.plus({ days: 1 }) : dataInizioAnnoFormativo) : dataInizioAnnoFormativo}
                                maxDate={dataFineAnnoFormativo}
                                onChange={(value) => changeDataSecondoColloquio(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}                            />
                        </LocalizationProvider>
                        {(corso?.secondoColloquio?.isEditato !== undefined && corso?.secondoColloquio?.isEditato === true) && (
                            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <WarningAmber sx={{ color: '#ed6c02', mr: 1 }} />
                                <Typography color="#ed6c02" variant="subtitle2" >
                                    Data modificata (<b>non salvata</b>)
                                </Typography>
                            </Box>)}
                        {!corso?.secondoColloquio?.editable && (
                            <Typography sx={{ mt: 1 }} variant="subtitle2" color="primary">Colloquio già svolto</Typography>
                        )}
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    );
};