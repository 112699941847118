import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { TirociniAPI } from "../../../../api/tirociniApi";


export default function VerificaEsistenzaAzienda(props) {

    const [pIvaCFValid, setPIvaCFValid] = useState(true);
    const [pIvaCFMessageError, setPIvaCFMessageError] = useState('');
    const [cfPiva, setCfPiva] = useState('');

    const [radioSelectPIvaCF, setRadioSelectPIvaCF] = useState(0);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    function changeCfPivaProps(objAzienda) {
        if (props.changeCfPiva) props.changeCfPiva(cfPiva, radioSelectPIvaCF, objAzienda)
    }

    function changeRadioSelectPIvaCF(e) {
        let valueTmp = parseInt(e.target.value)
        setRadioSelectPIvaCF(valueTmp);
        setCfPiva('')
        setPIvaCFValid(true);
        setPIvaCFMessageError('')
    }

    function changecfPiva(e) {
        let valueTmp = e.target.value

        validatecfPiva(valueTmp)
    }

    function validatecfPiva(value) {
        let valueNoSpaceTmp = value.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        if (radioSelectPIvaCF === 1) valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');
        else valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^0-9]/g, '');

        setCfPiva(valueNoExtraCharactersTmp);

        if (radioSelectPIvaCF === 0 && valueNoExtraCharactersTmp.length === 11) {
            setPIvaCFValid(true);
            setPIvaCFMessageError('')
        }
        else if (radioSelectPIvaCF === 1 && (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16)) {
            setPIvaCFValid(true);
            setPIvaCFMessageError('')
        }
        else {
            setPIvaCFValid(false);
            setPIvaCFMessageError(radioSelectPIvaCF === 0 ? 'La P.Iva si compone di 11 caratteri' : 'Il C.F si compone di 11 o 16 caratteri')
        }
    }
    function closeDialogAggiungiAzienda() {
        if (props.closeDialog) props.closeDialog()
    }

    async function verificaEsistenzaAzieda() {

        setIsConfermaLoading(true);
        let obj = {
            "pIva": radioSelectPIvaCF === 0 ? cfPiva : null,
            "CF": radioSelectPIvaCF === 1 ? cfPiva : null
        }

        TirociniAPI.verificaEsistenzaAzieda(obj)
            .then((result) => {
                changeCfPivaProps(result);
            })
            .catch(function (error) {
                setIsConfermaLoading(false);
            })
    }
    return (
        <Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 4 }}>
                <Typography textAlign={'center'}>Prima di proseguire, indicare P.Iva o C.F. dell'azienda che si intende aggiungere.</Typography>
                <RadioGroup
                    defaultValue={radioSelectPIvaCF}
                    value={radioSelectPIvaCF}
                    sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}
                    onChange={changeRadioSelectPIvaCF}
                >
                    <FormControlLabel value={0} control={<Radio />} label="P.Iva" />
                    <FormControlLabel value={1} control={<Radio />} label="C.F." />
                </RadioGroup>
                <TextField
                    error={!pIvaCFValid ? true : false}
                    defaultValue={cfPiva}
                    value={cfPiva}
                    required
                    inputProps={{ maxLength: radioSelectPIvaCF === 0 ? 11 : 16 }}
                    label={radioSelectPIvaCF === 0 ? "P.Iva" : "C.F."}
                    onChange={changecfPiva}
                    sx={{ mt: 2, width: '100%' }}
                    helperText={pIvaCFMessageError}
                />
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: 'unset' }, mt: 2, mr: { xs: 0, md: 4 } }}>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }} onClick={closeDialogAggiungiAzienda} variant="outlined" fullWidth color={'error'} >Annulla e chiudi</Button>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }} onClick={verificaEsistenzaAzieda} variant="contained" fullWidth color={'success'} disabled={cfPiva === '' || !pIvaCFValid || isConfermaLoading}>Conferma e prosegui</Button>
            </Box>
        </Fragment>
    )
}