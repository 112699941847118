import { useEffect, useState } from "react";
import { Box, Typography, } from "@mui/material";
import Badge from '@mui/material/Badge';
import { Edit } from "@mui/icons-material";


export default function PresenzaTirocini(props) {

    const [objTipoPresenza, setObjTipoPresenza] = useState(null);
    const [isSmallSize, setIsSmallSize] = useState(false);
    const [showEdit, setShowEdit] = useState(false);


    const tipoPresenzeList = [
        {
            id: 0,
            tipo: 'non-definito',
            colorChip: 'default',
            bgColor: 'grey',
            descrizione: 'Non definito',
            textIcon: 'N.D',
            variant: 'outlined',
        },
        {
            id: 1,
            tipo: 'assente',
            colorChip: 'error',
            bgColor: 'error.light',
            descrizione: 'Dettaglio assenza',
            textIcon: 'A',
            variant: 'outlined',
        },
        {
            id: 2,
            tipo: 'presenza-parziale',
            colorChip: 'warning',
            bgColor: 'warning.light',
            descrizione: 'Dettaglio presenza parziale',
            textIcon: 'PP',
            variant: 'outlined',
        },
        {
            id: 3,
            tipo: 'presente',
            colorChip: 'success',
            bgColor: 'success.light',
            descrizione: 'Dettaglio presenza',
            textIcon: 'P',
            variant: 'outlined',
        },
        {
            id: null,
            tipo: 'non-definita',
            colorChip: '#ccc',
            bgColor: '#ccc',
            textIcon: ' / ',
            variant: 'outlined',
        },
    ];

    useEffect(() => {
        if (props.showEdit) setShowEdit(props?.showEdit ?? false)
    }, [props.showEdit])

    useEffect(() => {
        if (props.presenza !== undefined) {
            let objPresenzaTmp = props?.presenza;
            getIndexBasedOnId(objPresenzaTmp !== null ? objPresenzaTmp.idStato : null);
        }
    }, [props.presenza])

    useEffect(() => { //Quando cambio l'index mi aggiorna i dati, senno avevo problemi
        if (props.indexChanges !== undefined) {
            let objPresenzaTmp = props?.presenza;
            getIndexBasedOnId(objPresenzaTmp !== null ? objPresenzaTmp.idStato : null);
        }
    }, [props.indexChanges])

    useEffect(() => {
        if (props.isSmallSize !== undefined)
            setIsSmallSize(props?.isSmallSize ?? false)
    }, [props.isSmallSize])

    function getIndexBasedOnId(idStato) {
        switch (idStato) {
            case 0: //ND
                setObjTipoPresenza(tipoPresenzeList[0]);
                break;
            case 1: //A
                setObjTipoPresenza(tipoPresenzeList[1]);
                break;
            case 2: //PP
                setObjTipoPresenza(tipoPresenzeList[2]);
                break;
            case 3: //P
                setObjTipoPresenza(tipoPresenzeList[3]);
                break;
            case null: // "/"
                setObjTipoPresenza(tipoPresenzeList[4]);
                break;
        }
    }

    function openEdit() {
        if (props.openEdit)
            props.openEdit();
    }

    return (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            {(!showEdit || objTipoPresenza.id === 0) ? (
                <Box width={"30px"} height={"30px"}
                    border={objTipoPresenza?.variant === "outlined" ? "1px solid grey" : "none"}
                    borderColor={objTipoPresenza?.bgColor}
                    backgroundColor={objTipoPresenza?.variant === "contained" ? objTipoPresenza?.bgColor : "white"}
                    display={"flex"} alignItems={"center"} justifyContent={"center"}
                    sx={{ mx: 1, p: isSmallSize ? "4px" : "8px", borderRadius: '100%', color: 'white' }}>
                    {objTipoPresenza?.icon !== undefined ? (
                        objTipoPresenza?.icon
                    ) : (
                        <Typography fontWeight={"bold"} color={objTipoPresenza?.bgColor} sx={{ fontSize: isSmallSize ? '0.8rem' : '1rem' }}>
                            {objTipoPresenza?.textIcon}
                        </Typography>
                    )}
                </Box>
            ) : (
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <Box onClick={openEdit} sx={{ cursor: 'pointer' }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: 'white', width: 22, height: 22, color: "#1976d2", borderRadius: '100%', border: `2px solid #1976d2` }}>
                                <Edit sx={{ fontSize: '1rem' }} />
                            </Box>
                        </Box>
                    }>

                    <Box width={"30px"} height={"30px"}
                        border={objTipoPresenza?.variant === "outlined" ? "1px solid grey" : "none"}
                        borderColor={objTipoPresenza?.bgColor}
                        backgroundColor={objTipoPresenza?.variant === "contained" ? objTipoPresenza?.bgColor : "white"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: isSmallSize ? "4px" : "8px", borderRadius: '100%', color: 'white' }}>
                        {objTipoPresenza?.icon !== undefined ? (
                            objTipoPresenza?.icon
                        ) : (
                            <Typography fontWeight={"bold"} color={objTipoPresenza?.bgColor} sx={{ fontSize: isSmallSize ? '0.8rem' : '1rem' }}>
                                {objTipoPresenza?.textIcon}
                            </Typography>
                        )}
                    </Box>
                </Badge>
            )}
        </Box>
    );



}



