import { Button, Container, Typography, Box } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { Check } from "@mui/icons-material";

import * as React from 'react';
import { Fragment } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Logo from "../../img/logo.png";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2/Grid2';


export default function ConfermaTicket() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function backToHome() {
        navigate('/home')
    }

    return (
        <Fragment>
            <Container fixed sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                <Box mb={{ xs: 2, md: 0 }}><img src={Logo} alt="CIOFS logo" height="60" /></Box>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid xs={12} md={4} display={{ xs: 'none', md: 'block' }}>
                        <Box width={'100%'} textAlign={'center'}>
                            <Check sx={{ fontSize: '18rem' }} color="success" />
                        </Box>
                    </Grid>
                    <Grid xs={12} md={8}>
                        <Typography textAlign={{ xs: 'center', md: 'start' }} variant="h4">La tua richiesta di assistenza è stata inoltrata correttamente.</Typography>
                        <Button fullWidth sx={{ mt: 5 }} onClick={backToHome} startIcon={<ArrowBackIcon />} data-testid='backHomeBtn' variant="contained">
                            torna alla home
                        </Button>
                    </Grid>
                </Grid>


            </Container>
            <Helmet>
                <title>{t("name")} - ConfermaTicket</title>
            </Helmet>
        </Fragment>
    )
}