import { Fragment, useEffect, useRef, useState } from "react"
import { ScrutiniAPI } from "../../../api/scrutiniApi";
import {
    Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Box, Typography, useTheme, useMediaQuery, Button, Alert, CircularProgress,  Snackbar,
    TextField
} from '@mui/material'; 
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export default function ElencoCollegioFormatori(props) {


    const [idCorso, setIdCorso] = useState("");
    const [periodo, setPeriodo] = useState("");

    const [elencoDocenti, setElencoDocenti] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [relazione, setRelazione] = useState("");

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const refRelazione = useRef(null)

    const [isScrutinioConcluso, setIsScrutinioConcluso] = useState(false);

    useEffect(() => {
        if (props.idCorso !== undefined && props.periodo !== undefined) {
            setIdCorso(props.idCorso);
            setPeriodo(props.periodo);
            getElencoDocenti(props.idCorso, props.periodo);
        }
    }, [props.idCorso, props.periodo])

    function changePresenza(index) {
        let elencoDocentiTmp = elencoDocenti;
        elencoDocentiTmp[index].presenza = !elencoDocentiTmp[index].presenza;
        setElencoDocenti([...elencoDocentiTmp]);
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }

    function changeRelazione(e){
        if (refRelazione != null)
            refRelazione.current.value = e.target.value;
    }

    async function getElencoDocenti(idCorso, periodo) {
        let dataObj = {
            "idCorso": idCorso,
            "periodo": periodo
        };

        ScrutiniAPI.getElencoCollegioFormatori(dataObj)
            .then((result) => {
                let docentiTmp = result?.docenti ?? []
                // docentiTmp.sort((a, b) => {
                //     if (a.cognomeDocente > b.cognomeDocente) {
                //         return 1;
                //     }
                //     if (a.cognomeDocente < b.cognomeDocente) {
                //         return -1;
                //     }
                //     return 0;
                // });
                setIsScrutinioConcluso(result?.isScrutinioConcluso ?? false);
                setRelazione(result?.relazioneCollegio ?? null)
                if(refRelazione.current != null)
                refRelazione.current.value = result?.relazioneCollegio ?? null
                setElencoDocenti([...docentiTmp]);
                setHasLoaded(true)
            })
            .catch(function (error) {
                setAlertMsg(
                    error?.response?.data?.data[0]?.msg ??
                    "Errore durante il recupero dei dati del collegio formatori"
                );
                setAlertSeverity("error");
                setAlertOpen(true);
                setHasLoaded(true)
            });
    }

    async function saveElencoCollegioFormatori() {
        let dataObj = {
            "idCorso": idCorso,
            "periodo": periodo,
            "relazioneCollegio": refRelazione?.current?.value ?? null,
            "docenti": elencoDocenti
        };

        ScrutiniAPI.savePresenzeCollegioFormatori(dataObj)
            .then(() => {
                if (props.saveAndCloseCollegioFormatori){
                    props.saveAndCloseCollegioFormatori("Modifiche salvate correttamente")
                } else {
                    setAlertMsg("Modifiche salvate correttamente");
                    setAlertSeverity("success");
                    setAlertOpen(true);
                }
            })
            .catch(function (error) {
                setAlertMsg( error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio delle presenze");
                setAlertSeverity("error");
                setAlertOpen(true);
            });
    }

    return (
        hasLoaded ? (
            <Fragment>
                <Grid container spacing={2} alignItems={"center"} sx={{ display: "flex", alignItems: 'start' }}>
                    <Grid xs={12}>
                        <TextField sx={{ mt: 1, width: '100%' }} inputRef={refRelazione} defaultValue={relazione} disabled={isScrutinioConcluso}
                            onChange={changeRelazione} multiline rows={4} label={'Relazione collegio'} />
                    </Grid>
                    <Grid xs={12} md={4} >
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Presente</TableCell>
                                    <TableCell >Nome e cognome</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {elencoDocenti.map((docente, index) => (
                                    ((!isTablet && index < (elencoDocenti.length / 3)) || isMobile || (isTablet && index < Math.round(elencoDocenti.length / 2))) && (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                <Checkbox disabled={isScrutinioConcluso} color="success" onChange={() => changePresenza(index)} checked={docente?.presenza ?? false}></Checkbox>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{docente?.cognomeDocente + " " + docente?.nomeDocente}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    {!isMobile && (
                        <Grid xs={12} md={4} sx={{ borderLeft: !isMobile ? '2px solid black' : '0px', borderRight: !isMobile ? '2px solid black' : '0px' }}>
                            <Table >
                                {!isMobile && (
                                    <TableHead>

                                        <TableRow>
                                            <TableCell align="center">Presente</TableCell>
                                            <TableCell >Nome e cognome</TableCell>
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {elencoDocenti.map((docente, index) => (
                                        ((!isMobile && isTablet && index >= Math.round(elencoDocenti.length / 2)) || (!isMobile && !isTablet && index >= elencoDocenti.length / 3 && index < (elencoDocenti.length - (elencoDocenti.length / 3)))) && (

                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    <Checkbox disabled={isScrutinioConcluso} color="success" onChange={() => changePresenza(index)} checked={docente?.presenza ?? false}></Checkbox>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{docente?.cognomeDocente + " " + docente?.nomeDocente}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    )}

                    {!isMobile && !isTablet && (
                        <Grid xs={12} md={4}  >
                            <Table >
                                <TableHead>
                                    <TableRow >
                                        <TableCell align="center">Presente</TableCell>
                                        <TableCell >Cognome e nome</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {elencoDocenti.map((docente, index) => (
                                        (!isMobile && !isTablet && index >= (elencoDocenti.length - (elencoDocenti.length / 3))) && (
                                            <TableRow>
                                                <TableCell align="center">
                                                    <Checkbox disabled={isScrutinioConcluso} color="success" onChange={() => changePresenza(index)} checked={docente?.presenza ?? false}></Checkbox>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{docente?.cognomeDocente + " " + docente?.nomeDocente}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    )}
                    
                </Grid>
                {isScrutinioConcluso ? (
                    <Button sx={{mt:3}} fullWidth variant="contained" color="primary" onClick={closeDialog}>Chiudi finestra</Button>

                ) : (
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end", mt: 5 }}>
                    <Button variant="outlined" color="error" onClick={closeDialog}>Annulla e chiudi finestra</Button>
                    <Button variant="contained" color="success" onClick={saveElencoCollegioFormatori} sx={{ ml: 2 }}>Salva presenze</Button>
                </Box>
                )}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                        {alertMsg}
                    </Alert>
                </Snackbar>
            </Fragment>
        ) : (
            <Box sx={{height:'80vh'}} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress sx={{ mb: 4 }} />
                <Typography >Caricamento in corso...</Typography>
            </Box>
        )
    )
}