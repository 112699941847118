import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

//{ sendToken: true } sulle chiamate che vogliono l'autenticazione

export const EventiAPI = {
    elencoNote: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoNoteDisciplinari`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertNota: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `test/nuovaNotaDisciplinare`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaNota: async function (obj, idNota, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `test/modificaNotaDisciplinare/${idNota}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getCategorieNota: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `test/categorieNotaDisciplinare`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertCompiti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovoCompito`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaCompiti: async function (obj, idCompito, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaCompito/${idCompito}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    elencoCompiti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoCompiti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertVerifica: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovaVerifica`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaVerifica: async function (obj, idVerifica, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaVerifica/${idVerifica}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    elencoVerifiche: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoVerifiche`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertVoto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovoVoto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaVoto: async function (obj, idVoto, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaVoto/${idVoto}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertValutazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovaValutazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaValutazione: async function (obj, idValutazione, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaValutazione/${idValutazione}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertPrerequisito: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovoAccertamentoPrerequisiti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaPrerequisito: async function (obj, id, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaAccertamentoPrerequisiti/${id}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    elencoArgomenti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoArgomentiSvolti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertArgomentiSvolti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovoArgomentoSvolto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaArgomentiSvolti: async function (obj, idArgomentiSvolti, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaArgomentoSvolto/${idArgomentiSvolti}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertAnnotazioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovaAnnotazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaAnnotazioni: async function (obj, idAnnotazione, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/modificaAnnotazione/${idAnnotazione}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    elencotAnnotazioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoAnnotazioni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getElencoExAnteAlunno: async function(obj, cancel = false){
        const response = await api.request({
            sendToken: true,
            url: `/test/getExAnte`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    insertExante: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/nuovoAccertamentoPrerequisiti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(EventiAPI)