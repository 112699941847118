import { useState, useEffect, Fragment, useRef } from "react"
import { Box, Button, InputLabel, OutlinedInput, InputAdornment, Dialog, DialogContent, DialogTitle, Typography, IconButton, Alert, Snackbar, FormControl, TextField, Divider, DialogActions, Chip } from '@mui/material';
import { AccessibilityNewRounded, Close, Timer, Visibility, VisibilityOff } from "@mui/icons-material";
import { debounce } from '@mui/material/utils';
import { BesAPI } from "../../api/besApi";

export default function BesButton(props) {

    const refPinConfermaBes = useRef(null);

    const [idCorso, setIdCorso] = useState(-1);
    const [listAlunni, setListAlunni] = useState([]);
    const [pinBesTmp, setPinBesTmp] = useState("")

    const [showBes, setShowBes] = useState(false);
    const [showBesPinDialog, setShowBesPinDialog] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (props.listAlunni) setListAlunni(props.listAlunni);
    }, [props.listAlunni])

    useEffect(() => {
        if (props.idCorso) setIdCorso(props.idCorso);
    }, [props.idCorso])

    function besPinDialog() {
        setPinBesTmp("");
        setShowBesPinDialog(prev => !prev);
    }
    function confirmBesPin() {
        let date = new Date();

        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');

        let dataRichiesta = `${year}-${month}-${day}`;

        if (pinBesTmp.length >= 1) {
            let dataObj = {
                pin: pinBesTmp,
                idCorso: idCorso,
                dataRichiesta: dataRichiesta
            }
            BesAPI.visualizzaBesDocenti(dataObj)
                .then((result) => {
                    setShowBes(true);
                    setShowBesPinDialog(false);
                    addBesToArray(listAlunni, result);
                })
                .catch((err) => {
                    console.log(err);
                    let errorMsg = "";
                    if (err?.response?.status === 420) {
                        errorMsg = err?.response?.data?.data[0]?.msg ?? "Pin errato";
                    } else {
                        errorMsg = "Pin errato";
                    }
                    if (props.openAlertMsg) props.openAlertMsg(errorMsg, "error")

                })
        } else if (props.openAlertMsg) props.openAlertMsg("Il campo PIN non può essere lasciato vuoto", "error");
    }
    function addBesToArray(alunni, result) {
        let alunniTmp = alunni;
        for (let i = 0; i < alunniTmp.length; i++) {
            let indexArrayBes = result.findIndex(a => a.id === alunniTmp[i].id)
            if (indexArrayBes !== -1) {
                alunniTmp[i].hasBes = result[indexArrayBes]?.hasBes ?? false;
                let interventoAttivo = result[indexArrayBes]?.BESData?.documentazioneConsegnataBes?.interventoAttivo;
                alunniTmp[i].besObj = {
                    id: alunniTmp[i].id,
                    hasBes: result[indexArrayBes]?.hasBes,
                    colorBes: selColorBes(interventoAttivo),
                    BESData: result[indexArrayBes]?.BESData
                }
            } else {
                alunniTmp[i].besObj = {
                    hasBes: false
                }
            }
        }
        if (props.besConfirmed) props.besConfirmed(alunniTmp);

    }
    function selColorBes(valore) {
        let colorTmp;
        switch (valore) {
            case '0':
                colorTmp = '#4169e1';
                break;
            case '1':
                colorTmp = '#1ebf24';
                break;
            case '2':
                colorTmp = '#ffbfaa';
                break;
            case '3':
                colorTmp = '#ff7b5a';
                break;
            case '4':
                colorTmp = '#d11507';
                break;
            default:
                colorTmp = '';
        }
        return colorTmp
    }
    function stopViewBes() {
        setShowBes(false)
        if (props.stopBes) props.stopBes()
    }

    function showPasswordInput() {
        setShowPassword(prev => !prev);
    }

    const changePinConfermaBesLength = debounce((event) => {
        setPinBesTmp(event.target.value);
    }, 100);

    return (
        <Fragment>

            {!showBes ? (
                <Button startIcon={<AccessibilityNewRounded/>} disabled={showBes} variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={besPinDialog}>
                    Mostra bes
                </Button>
            ) : (
                    <Chip sx={{ ml: 1 }} icon={<Timer/>} label={
                        <Typography >Bes visibili per <TimerBes startTimer={showBes} stopViewBes={stopViewBes} /></Typography>
                  } color="primary" variant="outlined" />
            )}
            <Dialog hideBackdrop open={showBesPinDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Mostra Bes</Typography>
                    <IconButton onClick={besPinDialog} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Alert severity="info">
                        Dopo l'inserimento del codice di conferma, sarà possibile visualizzare i bes degli studenti per <b>5 minuti</b>,
                        dopodiché sarà necessario ripetere l'operazione di inserimento del codice.
                    </Alert>
                    <Box display={"flex"} justifyContent={"center"} sx={{ mt: 5 }}>
                        <FormControl sx={{ width: '50%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Pin di conferma</InputLabel>
                            <OutlinedInput type={showPassword ? 'text' : 'password'}
                                inputRef={refPinConfermaBes}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={showPasswordInput}  >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={changePinConfermaBesLength}
                                // onBlur={changePinConfermaBes}
                                label="Pin di conferma"
                            />
                        </FormControl>
                    </Box>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                        <Button variant="outlined" color="error" onClick={besPinDialog} fullWidth>Annulla e chiudi finestra</Button>
                        <Button fullWidth sx={{ mt: 1 }}
                            disabled={pinBesTmp.length === 0}
                            variant="contained" color="success" onClick={confirmBesPin}>Conferma pin</Button>
                    </Box>
                </DialogActions>
            </Dialog>

        </Fragment>
    )
}

function TimerBes({ startTimer, stopViewBes }) {


    const [timeLeft, setTimeLeft] = useState(5 * 60);
    const minutes = Math.floor(timeLeft / 60);
    const seconds = String(timeLeft % 60).padStart(2, '0');


    useEffect(() => {
        if (startTimer && timeLeft > 0) {
            setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        }
        else if (timeLeft <= 0) {
            stopViewBes();
            setTimeLeft(5 * 60)
        }
    }, [startTimer, timeLeft, stopViewBes]);

    return (
        startTimer === true ? (
            <span><b>{minutes}:{seconds}</b> minuti </span>
        ) : null
    );
}