import { useState, useEffect, Fragment, useRef } from "react"
import {Alert, Snackbar, MenuItem, FormControl, Box, Button, IconButton, Typography, OutlinedInput, InputLabel, InputAdornment, Select, Divider } from '@mui/material';
import { debounce } from '@mui/material/utils';

import { getDayMonthAndYearErrorDialog } from "../shared/metodiDateUtility";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { RegistroAPI } from "../../api/registroApi";
import { PresenzeAPI } from "../../api/presenzeApi";

export default function DialogFirma(props) {

    const refPinConfermaOra = useRef(null);


    const [isMonitoraggio, setIsMonitoraggio] = useState(false);
    const [modificaMode, setModificaMode] = useState(false);

    const [oraFirmata, setOraFirmata] = useState(false);
    const [showFirmaOraDialog, setShowFirmaOraDialog] = useState(false);
    const [pinConfermaOra, setPinConfermaOra] = useState("");
    const [showPasswordPinOra, setShowPasswordPinOra] = useState(false);
    const [typeLezione, setTypeLezione] = useState(-1);
    const [oraSelezionata, setOraSelezionata] = useState(1);
    const [oreFirmate, setOreFirmate] = useState();
    const [nomeCorso, setNomeCorso] = useState('');
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState(null);
    const [dataRichiesta, setDataRichiesta] = useState(null)

    const [idDocente, setIdDocente] = useState(-1)
    const [idOrario, setIdOrario] = useState(-1)
    const [idCorso, setIdCorso] = useState(-1)
    const [idMateria, setIdMateria] = useState(-1)

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);


    useEffect(() => {
        if (props.objOraSelezionata) {
            setOraFirmata(props?.objOraSelezionata?.oraFirmata ?? false)
            setOraSelezionata(props?.objOraSelezionata?.ora ?? 1)
            setNomeMateriaSelezionata(props?.objOraSelezionata?.nomeMateria ?? "")
            setIdDocente(props?.objOraSelezionata?.idDocente ?? -1)
            setIdOrario(props?.objOraSelezionata?.idOrario ?? -1)
            setIdMateria(props?.objOraSelezionata?.idMateria ?? -1)
        }
    }, [props.objOraSelezionata])

    useEffect(() => {
        if (props.nomeCorso)
            setNomeCorso(props.nomeCorso);
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.dataRichiesta)
            setDataRichiesta(props.dataRichiesta);
    }, [props.dataRichiesta])

    useEffect(() => {
        if (props.idCorso)
            setIdCorso(props?.idCorso ?? -1)
    }, [props.idCorso])

    useEffect(() => {
        if (props.isMonitoraggio)
            setIsMonitoraggio(props.isMonitoraggio)
    }, [props.isMonitoraggio])

    function changeTypeLezione(e) {
        setTypeLezione(e.target.value)
    }

    function showPasswordInput() {
        setShowPasswordPinOra(prev => !prev);
    }

    function firmaOraPinDialog() {
        setPinConfermaOra("");
        setShowFirmaOraDialog(prev => !prev);
        setModificaMode(false);
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }

    function confirmOraPin() {
        if (pinConfermaOra.length >= 1) {
            firmaOra();
        }
        else {
           openAlertMsg("Il campo PIN non può essere lasciato vuoto", "error");
        }
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function closeDialog(){
        if (props.closeDialog) {
            props.closeDialog();
        }
    }

    const changePinConfermaOraLength = debounce((event) => {
        setPinConfermaOra(event.target.value);
    }, 100);

    async function modificaFirmaOra() {
        let tipoOra = typeLezione === 0 ? "teoria" : "pratica";
        let dataObj = {
            dataLezione: dataRichiesta, //2022-10-04
            idDocente: idDocente,
            idOrario: idOrario,
            idCorso: idCorso,
            tipoOra: tipoOra,
            idMateria: idMateria

        }
        RegistroAPI.modificaFirmaOra(dataObj)
            .then((result) => {
                setOraFirmata(true);
                if (props.closeDialog)
                    props.closeDialog();
                if (props.openAlertMsg)
                    props.openAlertMsg("Ora firma modificata", "success")
            })
            .catch((error) => {
                if (props.openAlertMsg)
                    props.openAlertMsg("Errore durante modifica della firma dell'ora", "error")
            })

    }
    async function firmaOra() {
        let tipoOra = typeLezione === 0 ? "teoria" : "pratica";
        let dataObj = {

            pin: pinConfermaOra,
            idDocente: idDocente,
            idOrario: idOrario,
            idCorso: idCorso,
            dataLezione: dataRichiesta, //2022-10-04
            tipoOra: tipoOra,
            idMateria: idMateria
        }
        if (idOrario !== -1) {
            PresenzeAPI.firmaOraMonitoraggioFirme(dataObj)
                .then((result) => {
                    setOraFirmata(true);
                    setShowFirmaOraDialog(false);
                    if (props.oraFirmata)
                        props.oraFirmata("Ora firmata", "success")
                })
                .catch((err) => {
                    let errorMsg = "";
                    if (err.response.status === 420) {
                        errorMsg = err?.response?.data?.data[0]?.msg ?? "Pin errato";
                    } else {
                        errorMsg = "Pin errato";
                    }
                    openAlertMsg(errorMsg, "error")
                })
        } else {
            openAlertMsg("Nessuna lezione associata al docente", "error")
        }
    }

    return (
        <Fragment>
            {nomeCorso !== "" && (
                <Fragment>
                    <Typography color="primary"><b>{nomeCorso}</b> </Typography>
                    <Divider sx={{ my: 2 }} />
                </Fragment>
            )}
            <Typography variant="body1" >
                {!oraFirmata ? 'Per poter firmare la presenza ' : 'La presenza è stata firmata '} <b>({oraSelezionata}^ora - {nomeMateriaSelezionata}, del {getDayMonthAndYearErrorDialog(dataRichiesta)})</b>{!oraFirmata ? ' è necessario inserire il pin di conferma e selezionare la tipologia di lezione' : null}.
            </Typography>
            {!isMonitoraggio && ( //Queste informazioni non le ho se provengo da monitoraggio
                <Fragment>
                    <Divider sx={{ my: 1 }} />
                    <Typography><b>Ore totali:</b> {oreFirmate?.oreAssegnate ?? 0} </Typography>
                    <Typography><b>Ore  teoria</b> (firmate): {oreFirmate?.teoria?.numeroOre ?? 0} - {(oreFirmate?.teoria?.percentuale ?? 0) + "%"}</Typography>
                    <Typography sx={{ mb: 2 }}><b>Ore pratica</b> (firmate): {oreFirmate?.pratica?.numeroOre ?? 0} - {(oreFirmate?.pratica?.percentuale ?? 0) + "%"}</Typography>
                </Fragment>
            )}
            {
                (modificaMode || !oraFirmata) && (<Box display={"flex"} justifyContent={"center"} sx={{ mt: 5, mb: 2 }}>
                    <FormControl sx={{ width: '50%' }}>
                        <InputLabel id="typeLezione-label">
                            Tipologia di lezione
                        </InputLabel>
                        <Select
                            label='Tipologia di lezione '
                            value={typeLezione}
                            onChange={changeTypeLezione}
                        >
                            <MenuItem key={-1} value={-1} >

                            </MenuItem>
                            <MenuItem key={0} value={0} >
                                Teorico
                            </MenuItem>
                            <MenuItem key={1} value={1} >
                                Pratico
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>)
            }
            {
                !oraFirmata && (<Box display={"flex"} justifyContent={"center"} sx={{ mt: 2, mb: 5 }}>
                    <FormControl sx={{ width: '50%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Pin di conferma</InputLabel>
                        <OutlinedInput type={showPasswordPinOra ? 'text' : 'password'}
                            inputRef={refPinConfermaOra}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={showPasswordInput}  >
                                        {showPasswordPinOra ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={changePinConfermaOraLength}
                            label="Pin di conferma"
                        />
                    </FormControl>
                </Box>)
            }
            <Box display={"flex"} justifyContent={"end"}>
                {oraFirmata ? (
                    <Box sx={{ mt: 1 }}>
                        {!modificaMode ? (
                            <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">chiudi dettaglio</Button>
                        ) : (<Button onClick={firmaOraPinDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla modifiche</Button>)}
                        {!modificaMode ? (<Button onClick={() => setModificaMode(true)}
                            sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                            variant="contained" >
                            Modifica tipologia di lezione
                        </Button>) : (<Button onClick={() => modificaFirmaOra()} disabled={(typeLezione === -1) ? true : false} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                            color="success" variant="contained" >
                            Conferma Modifica
                        </Button>)}
                    </Box>)
                    :
                    (
                        <Box>
                            <Button variant="outlined" color="error" onClick={closeDialog}>Annulla</Button>
                            <Button sx={{ ml: 1, px: 5 }} variant="contained"
                                disabled={(typeLezione === -1 || pinConfermaOra === '') ? true : false}
                                // disabled={(typeLezione === -1 || !valuePinConfermaOra) ? true : false} //TODO: 
                                color="success" onClick={confirmOraPin}>Conferma</Button>
                        </Box>
                    )
                }
            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}