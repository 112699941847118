import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Divider, Chip, Skeleton } from '@mui/material';
import { AccessibilityNewRounded, Search } from "@mui/icons-material";
import { convertDataDiNascita, convertToSqlFormat, getDayOfTheWeek } from "../../shared/metodiDateUtility";
import PresenzaTirocini from "../presenzaTirocini";

export default function TableValidazionePresenze(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [listAlunni, setListAlunni] = useState([]);
    const [listDays, setListDays] = useState([]);
    const [nomeCorso, setNomeCorso] = useState("");

    useEffect(() => {
        if (props.listAlunni) {
            setListAlunni([...props.listAlunni]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100)
        }
    }, [props.listAlunni])

    useEffect(() => {
        if (props.listDays) {
            let listDaysTmp = [];
            if (props.listDays.length > 0) {
                for (let giorno of props.listDays) {
                    let objTmp = {};
                    objTmp.dataNotFormatted = giorno.dataLezione;
                    objTmp.dataLezione = convertDataDiNascita(giorno.dataLezione);
                    let dataSql = convertToSqlFormat(giorno.dataLezione);
                    objTmp.giornoSettimana = getDayOfTheWeek(dataSql);
                    listDaysTmp.push(objTmp);
                }
            }
            setListDays([...listDaysTmp]);
        }
    }, [props.listDays])

    useEffect(() => {
        if (props.nomeCorso) setNomeCorso(props.nomeCorso);
    }, [props.nomeCorso])

    function openDettaglioGiornata(data) {
        if (props.openDialogDettaglio)
            props.openDialogDettaglio(data)
    }

    function openDettaglioAlunno(alunno, showBes) {
        if (props.openDettaglioAlunno)
            props.openDettaglioAlunno(alunno, showBes)
    }

    function calcolaColorePercentuale(percentualeString) {
        if (percentualeString !== undefined) {
            let nuovaString = percentualeString.split("%", 2);
            let percentualeIntera = parseInt(nuovaString);
            if (percentualeIntera > 25) return "green";
            else return "red";
        } else return "black";
    }

    return (

        <TableContainer sx={{ width: '100%' }} component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
                <TableHead>
                    <TableRow >
                        <TableCell align="center"
                            className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 13 }}>
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '140px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                            }}>
                                <Typography color={'primary'}
                                    variant={"caption"}
                                    sx={{ fontWeight: 'bold', textAlign: 'start' }}>
                                    {(nomeCorso ?? "").toUpperCase()}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className=" colonna-nomi row-head-secondColumn_mod" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 12 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '140px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <Typography variant="body2" ><b>Ore </b></Typography>
                                <Typography variant='caption' >assenze/svolte/totale</Typography>

                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className=" colonna-nomi row-head-secondColumn_mod" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 12 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '140px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <Typography variant="body2" ><b>Ore validate</b></Typography>
                                <Typography variant='caption' >validate/totali</Typography>

                            </Box>
                        </TableCell>

                        {listDays.map((giornata) => (
                            <TableCell align="center"
                                className=" colonna-nomi row-head-secondColumn_mod" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 12 }}
                            >
                                {/* row-head-secondColumn */}
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '140px',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                    textAlign: 'center'
                                }}>
                                    <Typography variant="body2" ><b>{giornata?.dataLezione ?? ""}</b></Typography>
                                    <Typography variant='caption' >{giornata?.giornoSettimana ?? ""}</Typography>

                                    <Box width={"30px"} height={"30px"} onClick={() => openDettaglioGiornata(giornata?.dataNotFormatted)}
                                        border={"1px solid #1976d2"}
                                        borderColor={"primary"}
                                        backgroundColor={"white"}
                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                        sx={{ mt: 2, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                        <Search />
                                    </Box>
                                </Box>
                            </TableCell>
                        ))}


                    </TableRow>
                </TableHead>

                <TableBody>
                    {listAlunni.map((alunno, index) => (
                        <TableRow style={{ height: '40px' }} key={alunno?.id}>
                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', px: 2, py: 2 }}>

                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                            <Typography onClick={() => openDettaglioAlunno(alunno, false)} variant={mobile ? 'caption' : 'body2'} sx={{ cursor: 'pointer', mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                                {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                            </Typography>

                                            {alunno?.besObj?.hasBes && (
                                                <Box onClick={() => openDettaglioAlunno(alunno, true)} width={"10px"} height={"10px"} sx={{ cursor: 'pointer', p: 1, mr: 1, borderRadius: '100%', border: '1px solid ' + alunno?.besObj?.colorBes, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1976d2', ml: 1 }}   >
                                                    <AccessibilityNewRounded sx={{ color: alunno?.besObj?.colorBes }} />
                                                </Box>
                                            )}
                                        </Box>
                                        {alunno.msgIscrizione != null ? (
                                            <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                        ) : null}
                                    </Box>
                                ) : (
                                    <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: 'white', px: 2, py: 2 }}>
                                        <Typography variant="body2">{alunno?.oreMateria?.oreAssenza}/{alunno?.oreMateria?.oreSvolte}/{alunno?.oreMateria?.oreTotali}  </Typography>
                                        <Typography color={calcolaColorePercentuale(alunno?.oreMateria?.percentuale)} variant="caption">
                                            {alunno?.oreMateria?.percentuale}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: 'white', px: 2, py: 2 }}>
                                        <Typography variant="body2">{alunno?.oreValidate?.oreValidate}/{alunno?.oreValidate?.oreTotali}  </Typography>
                                        <Typography variant="caption" color={calcolaColorePercentuale(alunno?.oreValidate?.percentuale)} >{alunno?.oreValidate?.percentuale}</Typography>
                                    </Box>
                                ) : (
                                    <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            {alunno.presenze.map((giornata) => (
                                <TableCell sx={{ p: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        giornata.statoPresenza !== null && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                                                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ height: '100%' }} >
                                                    <PresenzaTirocini isSmallSize={true} presenza={giornata} />
                                                </Box>
                                                <Typography color={giornata?.validata ? "primary" : "black"} sx={{ mt: 1 }} variant="caption">{giornata?.validata ? "VALIDATA" : "NON VALIDATA"}</Typography>
                                            </Box>
                                        )
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                            ))}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

