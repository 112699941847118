import { Box, IconButton, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

/**
*
 * @param {number | undefined} ml - margine sinistro (0-5)
 * @param {number | undefined} mr - margine destro (0-5)
 * @param {component } icon - l'icona che viene renderizzata, va passata a mo di componente es. "<Delete.../>""
 * @param {string} color - Il colore del pulsante (primary, secondary, success...)
 * @param {string} variant - contained/variant
 * 
 * @param {function()} buttonClicked - Funzione in output richiamata al click del pulsante
 */

export default function CustomIconButton(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [icon, setIcon] = useState(null);
    const [color, setColor] = useState("#1976d2")
    const [variant, setVariant] = useState("contained")
    const [hasLoaded, setHasLoaded] = useState(false);

    const [mr, setMr] = useState(0);
    const [ml, setMl] = useState(0);


    useEffect(() => {
        if (props.icon) {
            setIcon(props.icon);
            setHasLoaded(true)
        }
    }, [props.icon])

    useEffect(() => {
        if (props.color) {
            switch (props.color) {
                case "primary":
                    setColor("#1976d2");
                    break;
                case "secondary":
                    setColor("#9c27b0");
                    break;
                case "success":
                    setColor("#2e7d32");
                    break;
                case "error":
                    setColor("#d32f2f");
                    break;
                default:
                    setColor("#1976d2");
                    break;
            }
        }
    }, [props.color])

    useEffect(() => {
        if (props.variant) {
            setVariant(props.variant)
        }
    }, [props.variant])

    useEffect(() => {
        if (props.mr)
            setMr(props.mr)
    }, [props.mr])

    useEffect(() => {
        if (props.ml)
            setMl(props.ml)
    }, [props.ml])

    function buttonClicked() {
        if (props.buttonClicked)
            props.buttonClicked();
    }
    return (
        hasLoaded ? (
            <Box onClick={buttonClicked} sx={{
                mr: mr, ml: ml,
                width: mobile ? "30px" : "35px", height: mobile ? "30px" : "35px", display: "flex", alignItems: "center", background: variant === "contained" ? color : "white",
                justifyContent: "center", borderRadius: "5px", border: "1px solid " + color,
            }}>
                <IconButton>
                    {icon}
                </IconButton>
            </Box>
        ) : (
            <Skeleton variant="rectangular" width={30} height={30} />
        )
    )
}