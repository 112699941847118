import { useState, useEffect, Fragment } from "react"
import CryptoJS from 'crypto-js';

import Grid from '@mui/material/Unstable_Grid2';
import { Paper, Typography, Snackbar, Alert, Box, Divider, Button, Dialog, DialogContent, Skeleton } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { DateTime } from "luxon";
import { convertDataDiNascita } from "../../shared/metodiDateUtility";
import { UserAPI } from "../../../api/userApi";
import ElencoNote from "../../eventi/elencoNote";
import AggiuntaNota from "../../eventi/noteDisciplinari/aggiuntaNota";
import { Add } from "@mui/icons-material";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";

export default function AnagraficaAlunno(props) {

    // const [data, setData] = useState(null);
    // const [ora, setOra] = useState(null);
    const [idStudente, setIdStudente] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [note, setNote] = useState([]);
    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState("20232024")
    //Dati alunno
    const [sede, setSede] = useState("");
    const [corso, setCorso] = useState("");
    const [idSede, setIdSede] = useState(4);
    const [codiceFiscale, setCodiceFiscale] = useState("");
    const [nomeAlunno, setNomeAlunno] = useState("");
    const [cognomeAlunno, setCognomeAlunno] = useState("");
    const [dataNascitaAlunno, setDataNascitaAlunno] = useState("");
    const [comuneNascita, setComuneNascita] = useState("");
    const [provinciaNascita, setProvinciaNascita] = useState("");
    const [isMaggiorenne, setIsMaggiorenne] = useState(false);
    const [etaAlunno, setEtaAlunno] = useState(-1);
    const [cittadinanza, setCittadinanza] = useState("");

    const { t, i18n } = useTranslation();

    const [cellulare, setCellulare] = useState(null);
    const [emailIstituto, setEmailIstituto] = useState(null);
    const [emailPersonale, setEmailPersonale] = useState(null);
    const [tutori, setTutori] = useState([]);

    const [canAddNotaPersonaleNonDocente, setCanAddNotaPersonaleNonDocente] = useState(false);
    const [dialogAggiungiNotaOpen, setDialogAggiungiNotaOpen] = useState(false);
    const [idCorso, setIdCorso] = useState(-1)
    const [dataOggiSql, setDataOggiSql] = useState(null);
    const [genereAlunno, setGenereAlunno] = useState("-")
    const [isLoading, setIsLoading] = useState(true);

    const [alunniSelezionati, setAlunniSelezionati] = useState(null);
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            if (permessiParsed.some(p => p.nome === "canAddNotaPersonaleNonDocente" && p.value === true))
                setCanAddNotaPersonaleNonDocente(true)
            else setCanAddNotaPersonaleNonDocente(false);
        }
        let oggi = DateTime.local().toISODate();
        setDataOggiSql(oggi)

        let objDateInteresse = getDataInizioFinePeriodo();
        setIsAnnoFormativoCorrente(objDateInteresse?.isAnnoFormativoCorrente ?? true)

    }, [])

    useEffect(() => {
        if (props.ritardo !== undefined)
            setOpenDialogRitardo(props.ritardo);
    }, [props.ritardo])

    useEffect(() => {
        if (props.id !== undefined && props.annoFormativo !== undefined) {
            setIdStudente(props.id);
            getDettaglioAlunno(props.id, props.annoFormativo)
            setAnnoFormativo(props.annoFormativo)
        }
    }, [props.id, props.annoFormativo])




    useEffect(() => {
        setIdDocente(props.idDocente)
    }, [props.idDocente])


    function calculateEta(dataDiNascita) {

        var oggi = new Date();
        var dataNascita = new Date(dataDiNascita);
        var eta = oggi.getFullYear() - dataNascita.getFullYear();
        var m = oggi.getMonth() - dataNascita.getMonth();
        if (m < 0 || (m === 0 && oggi.getDate() < dataNascita.getDate())) {
            eta--;
        }
        return eta;
    }


    function openDialogAggiungiNota() {
        let isOpen = !dialogAggiungiNotaOpen
        setDialogAggiungiNotaOpen(isOpen)
        if (!isOpen) {
            getDettaglioAlunno(idStudente, annoFormativo); //Ricarico tutto perchè devo aggiornare l'elenco delle note
        }
    }

    function notaAggiunta(msg, severity) {
        openDialogAggiungiNota(); //Da qui chiudo la dialog e richiamo il get del dettaglio alunno
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    async function getDettaglioAlunno(idStudente, annoFormativoTmp) {

        let dataObj = {
            "idAlunno": idStudente,
            "annoFormativo": annoFormativoTmp
        }
        setHasLoaded(false)
        if (idStudente !== -1) {
            UserAPI.getDettaglioAlunno(dataObj)
                .then((result) => {
                    setHasLoaded(true)
                    setAlunniSelezionati([...result])
                    setCorso(result[0]?.corso)
                    setSede(result[0]?.sede)
                    let noteDisciplinariTmp = result[0]?.noteDisciplinari;
                    //noteDisciplinariTmp.sort((a, b) => b.id - a.id);
                    noteDisciplinariTmp.sort((a, b) => {
                        let dateA = new Date(a.data_comunicazione), dateB = new Date(b.data_comunicazione);
                        return dateB - dateA; // ordina in ordine decrescente
                    });
                    setNote([...noteDisciplinariTmp])
                    setGenereAlunno(result[0]?.genere)
                    setNomeAlunno(result[0]?.nome);
                    setCognomeAlunno(result[0]?.cognome);
                    setCodiceFiscale(result[0]?.codiceFiscale);
                    setComuneNascita(result[0]?.comuneNascita);
                    setProvinciaNascita(result[0]?.provinciaNascita);
                    setIsMaggiorenne(result[0]?.isMaggiorenne);
                    setCittadinanza(result[0]?.cittadinanza);
                    setIdCorso(result[0]?.idCorso)

                    let dataNascita = convertDataDiNascita(result[0]?.dataNascita)
                    setDataNascitaAlunno(dataNascita);
                    let eta = calculateEta(result[0]?.dataNascita);
                    setEtaAlunno(eta);

                    setCellulare(result[0]?.cellulare ?? null)
                    setEmailPersonale(result[0]?.emailPeronale ?? null)
                    setEmailIstituto(result[0]?.emailIstituto ?? null)
                    setTutori(result[0]?.tutori ?? [])

                    setIsLoading(false)

                })
                .catch((error) => {
                    setHasLoaded(true)
                    if (error.response.status === 403) props.logout();
                })
        }
    }

    return (
        <Fragment>
            <Grid container spacing={{ xs: 0, md: 2 }} sx={{ width: '100%', alignItems: 'start' }}>
                <Grid xs={12} md={6} container sx={{ px: { xs: 0, md: 3 } }}>
                    <Grid xs={12} md={12} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7" ><b>Corso</b>: </Typography>
                                <Typography variant="h7">{corso ?? ""} ({sede})</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}
                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7" ><b>{t("pages.anagrafica.nome")}</b>: </Typography>
                                <Typography variant="h7">{nomeAlunno ?? ""} {cognomeAlunno ?? ""}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}
                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7" ><b>ID</b>: </Typography>
                                <Typography variant="h7">{idStudente}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}
                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7"><b>{t("pages.anagrafica.cf")}</b>: </Typography>
                                <Typography variant="h7" >{codiceFiscale ?? ""}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}
                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7"  ><b>{t("pages.anagrafica.dataNascita")}</b>: </Typography>
                                <Typography variant="h7" >{dataNascitaAlunno ?? ""}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}

                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7"  ><b>{t("pages.anagrafica.luogoNascita")}</b>: </Typography>
                                <Typography variant="h7" >{comuneNascita ?? ""} ({provinciaNascita ?? ""})</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}

                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7"  ><b>Età</b>: </Typography>
                                <Typography variant="h7" >{etaAlunno != -1 ? etaAlunno : ""}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}

                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7"  ><b>Genere</b>: </Typography>
                                <Typography variant="h7" >{genereAlunno !== undefined ? genereAlunno : "-"}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}

                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7" ><b>{t("pages.anagrafica.maggiorenne")}</b>: </Typography>
                                <Typography variant="h7">{isMaggiorenne ? "Sì" : "No"}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}

                    </Grid>
                    <Grid xs={12} md={6} sx={{ mb: 1 }}>
                        {hasLoaded ? (
                            <Fragment>
                                <Typography variant="h7" ><b>Cittadinanza</b>: </Typography>
                                <Typography variant="h7">{cittadinanza ?? ""}</Typography>
                            </Fragment>
                        ) : (
                            <Skeleton variant="rounded" height={15} />
                        )}

                    </Grid>

                    {hasLoaded ? (
                        cellulare != null && (
                            <Grid xs={12} md={12} sx={{ mb: 1 }}>
                                <Fragment>
                                    <Typography variant="h7" ><b>Cellulare</b>: </Typography>
                                    <Typography variant="h7">{cellulare ?? ""}</Typography>
                                </Fragment>
                            </Grid>
                        )) : (
                        <Skeleton variant="rounded" height={15} />
                    )}

                    {hasLoaded ? (
                        emailPersonale != null && (
                            <Grid xs={12} md={12} sx={{ mb: 1 }}>
                                <Fragment>
                                    <Typography variant="h7" ><b>Email (personale)</b>: </Typography>
                                    <Typography variant="h7">{emailPersonale ?? ""}</Typography>
                                </Fragment>
                            </Grid>
                        )) : (
                        <Skeleton variant="rounded" height={15} />
                    )}

                    {hasLoaded ? (
                        emailIstituto != null && (
                            <Grid xs={12} md={12} sx={{ mb: 3 }}>
                                <Fragment>
                                    <Typography variant="h7" ><b>Email (d'istituto)</b>: </Typography>
                                    <Typography variant="h7">{emailIstituto ?? ""}</Typography>
                                </Fragment>
                            </Grid>
                        )) : (
                        <Skeleton variant="rounded" height={15} />
                    )}

                </Grid>
                <Grid xs={12} md={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {/*VUOTO - FORSE METTERE UN DIVIDER VERTICALE */}

                </Grid>
                <Grid xs={12} md={5} mt={{ xs: 2, md: 0 }} sx={{ width: '100%' }}>
                    <Typography variant="h6" ><b>{t("pages.anagrafica.note")}</b></Typography>
                    <Paper variant="outlined" sx={{ mt: 2, width: '100%', minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                        {note.length !== 0 ? (
                            <ElencoNote alunno={idStudente} note={note} isLoading={isLoading} />
                        ) :
                            (
                                <Typography variant="body2 " px={2}>Al momento non ci sono note disciplinari da visualizzare</Typography>
                            )
                        }
                    </Paper>

                    {(canAddNotaPersonaleNonDocente && isAnnoFormativoCorrente) && (
                        <Button sx={{ mt: 1 }} fullWidth variant="contained" color="success" onClick={openDialogAggiungiNota} endIcon={<Add />}>
                            Aggiungi nota disciplinare
                        </Button>
                    )}
                </Grid>
                {hasLoaded ? (
                    tutori.length > 0 && (
                        <Grid xs={12}>
                            <Typography variant="h6"><b>Tutori</b></Typography>
                            <Paper variant="outlined" sx={{ p: 2, mt: 2, width: '100%' }}>

                                {tutori.map((tutore, index) => (
                                    <Box sx={{ width: '100%' }}>
                                        <Typography><b>{tutore?.nomeTutore + " " + tutore?.cognomeTutore}</b> {" (" + tutore?.tipoTutore + ") "}</Typography>
                                        <Typography><b>Telefono</b>: {tutore?.telefonoTutore ?? "-"}</Typography>
                                        <Typography><b>Email</b>: {tutore?.emailTutore ?? "-"}</Typography>
                                        {(index + 1) < tutori.length && (
                                            <Divider sx={{ my: 2 }} />
                                        )}
                                    </Box>
                                ))}


                            </Paper>
                        </Grid>
                    )) : (
                    <Grid xs={12}>
                        <Typography variant="h6"><b>Tutori</b></Typography>
                        <Paper variant="outlined" sx={{ p: 2, mt: 2, width: '100%' }}>
                            <Skeleton variant="rounded" height={15} />
                            <Skeleton variant="rounded" height={15} sx={{ my: 2 }} />
                            <Skeleton variant="rounded" height={15} />
                        </Paper>
                    </Grid>
                )}
            </Grid>

            <Dialog open={dialogAggiungiNotaOpen} fullWidth maxWidth="lg">
                <DialogContent>
                    <AggiuntaNota eventoAggiunto={notaAggiunta} listAlunni={null} alunniSelezionati={[{ id: idStudente, nome: nomeAlunno, cognome: cognomeAlunno }]} dataRichiesta={dataOggiSql} idMateria={-1} idDocente={idDocente} idSede={idSede}
                        idCorso={idCorso} closeDialog={openDialogAggiungiNota} isTuttaLaClasse={false} isDialog={true} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - {t("pages.anagrafica.title")}</title>
            </Helmet>
        </Fragment>

    )
}