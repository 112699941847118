import { Fragment, useEffect, useState } from "react"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import { convertOra, convertToItalianTimeAndShowWeekDay } from "../../shared/metodiDateUtility";
import { ArrowDownward, WarningAmber } from "@mui/icons-material";
import PresenzaTirocini from "../presenzaTirocini";
import CustomTimePicker from "../../shared/customTimePicker";
import { DateTime } from "luxon";
export default function RowGiornataTirocinio(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [canEditPresenze, setCanEditPresenze] = useState(false);
    const [giornata, setGiornata] = useState(-1);
    const [indexGiornata, setIndexGiornata] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [indexChanges, setIndexChanges] = useState(0);

    const [dataDettaglio, setDataDettaglio] = useState(null);

    useEffect(() => {
        if (props.indexChanges !== undefined && props.giornata !== undefined && props.indexGiornata !== undefined) {
            let giornataTmp = props.giornata;
            let indexGiornataTmp = props.indexGiornata;
            setGiornata(giornataTmp);
            setIndexGiornata(indexGiornataTmp);
        }
    }, [props.indexChanges, props.giornata, props.indexGiornata])

    useEffect(() => {
        if (props.dataDettaglio !== undefined) setDataDettaglio(props.dataDettaglio);
    }, [props.dataDettaglio])

    useEffect(() => {
        if (props.canEditPresenze !== undefined) setCanEditPresenze(props.canEditPresenze);
    }, [props.canEditPresenze])

    useEffect(() => {
        if (props.giornata) {
            setGiornata(props.giornata);
            setIsLoading(false);
        }
        if (props.indexGiornata !== undefined) setIndexGiornata(props.indexGiornata);
    }, [props.giornata, props.indexGiornata])

    function modificaOrari(giornata) {
        if (props.modificaOrari !== undefined) props.modificaOrari(giornata, indexGiornata)
    }

    function returnAssenzaStyle(type, fasciaInteressata) {
        let isAssenza = false;
        if (fasciaInteressata === 0) isAssenza = giornata?.presenzaGiornata?.idStato !== 1
        if (fasciaInteressata === 1) isAssenza = giornata?.primaFascia?.presenzaFascia?.idStato !== 1
        if (fasciaInteressata === 2) isAssenza = giornata?.secondaFascia?.presenzaFascia?.idStato !== 1

        if (type === "border") {
            if (isAssenza) return "1px solid red";
            else return "1px solid green";
        }
        else if (type === "color") {
            if (isAssenza) return "red";
            else return "green";
        }
        else if (type === "text") {
            if (isAssenza) return " A ";
            else return " P ";
        }
        else return "";
    }

    function copiaDaOrarioPrevistoAEffettuato(fasciaInteressata) {
        let giornataTmp = { ...giornata };

        const copiaOrari = (fascia) => {
            if (giornataTmp[fascia].presenzaFascia.idStato !== 0) {
                giornataTmp[fascia].orarioEffettuato.oraInizio = giornataTmp[fascia].orarioPrevisto.oraInizio;
                giornataTmp[fascia].orarioEffettuato.oraFine = giornataTmp[fascia].orarioPrevisto.oraFine;
                giornataTmp[fascia].presenzaFascia.statoPresenza = "P";
                giornataTmp[fascia].presenzaFascia.idStato = 3;
                giornataTmp[fascia].isModified = true;
            }
        };

        switch (fasciaInteressata) {
            case 0:
                copiaOrari('primaFascia');
                copiaOrari('secondaFascia');
                giornataTmp.presenzaGiornata.statoPresenza = "P";
                giornataTmp.presenzaGiornata.idStato = 3;
                break;
            case 1:
                copiaOrari('primaFascia');
                if (giornataTmp.secondaFascia.presenzaFascia.idStato === 0 || giornataTmp.secondaFascia.presenzaFascia.idStato === 3) {
                    giornataTmp.presenzaGiornata.statoPresenza = "P";
                    giornataTmp.presenzaGiornata.idStato = 3;
                } else {
                    giornataTmp.presenzaGiornata.statoPresenza = "PP";
                    giornataTmp.presenzaGiornata.idStato = 2;
                }
                break;
            case 2:
                copiaOrari('secondaFascia');
                if (giornataTmp.primaFascia.presenzaFascia.idStato === 0 || giornataTmp.primaFascia.presenzaFascia.idStato === 3) {
                    giornataTmp.presenzaGiornata.statoPresenza = "P";
                    giornataTmp.presenzaGiornata.idStato = 3;
                } else {
                    giornataTmp.presenzaGiornata.statoPresenza = "PP";
                    giornataTmp.presenzaGiornata.idStato = 2;
                }
                break;
            default:
                giornataTmp.primaFascia.orarioEffettuato.oraInizio = null;
                giornataTmp.primaFascia.orarioEffettuato.oraFine = null;
                giornataTmp.secondaFascia.orarioEffettuato.oraInizio = null;
                giornataTmp.secondaFascia.orarioEffettuato.oraFine = null;
        }

        modificaOrari(giornataTmp);
        setGiornata(giornataTmp);
        setTimeout(() => {
            setIndexChanges(prev => prev + 1);
        }, 100);
    }
    //0 = tutta la giornata; 1=prima fascia; 2=seconda fascia
    function modificaPresenza(fasciaInteressata) {
        let giornataTmp = { ...giornata };
        if (fasciaInteressata === 0) {
            if (giornataTmp.presenzaGiornata.idStato === null || giornataTmp.presenzaGiornata.idStato === 2 || giornataTmp.presenzaGiornata.idStato === 3) {
                giornataTmp.presenzaGiornata.idStato = 1;
                giornataTmp.presenzaGiornata.statoPresenza = "A";
                giornataTmp.presenzaGiornata.isModified = true;

                if (giornataTmp.primaFascia.presenzaFascia.idStato !== 0) {
                    giornataTmp.primaFascia.presenzaFascia.statoPresenza = "A";
                    giornataTmp.primaFascia.presenzaFascia.idStato = 1;
                    giornataTmp.primaFascia.orarioEffettuato.oraInizio = null;
                    giornataTmp.primaFascia.orarioEffettuato.oraFine = null;
                    giornataTmp.primaFascia.isModified = true;

                }
                if (giornataTmp.secondaFascia.presenzaFascia.idStato !== 0) {
                    giornataTmp.secondaFascia.presenzaFascia.statoPresenza = "A";
                    giornataTmp.secondaFascia.presenzaFascia.idStato = 1;
                    giornataTmp.secondaFascia.orarioEffettuato.oraInizio = null;
                    giornataTmp.secondaFascia.orarioEffettuato.oraFine = null;
                    giornataTmp.secondaFascia.isModified = true;
                }

            } else {
                giornataTmp.presenzaGiornata.statoPresenza = "/";
                giornataTmp.presenzaGiornata.idStato = null;
                if (giornataTmp.primaFascia.presenzaFascia.idStato !== 0) {
                    giornataTmp.primaFascia.presenzaFascia.statoPresenza = "/";
                    giornataTmp.primaFascia.presenzaFascia.idStato = null;
                    giornataTmp.primaFascia.isModified = true;
                }
                if (giornataTmp.secondaFascia.presenzaFascia.idStato !== 0) {
                    giornataTmp.secondaFascia.presenzaFascia.statoPresenza = "/";
                    giornataTmp.secondaFascia.presenzaFascia.idStato = null;
                    giornataTmp.secondaFascia.isModified = true;
                }
            }

        } else if (fasciaInteressata === 1) {
            if (giornataTmp.primaFascia.presenzaFascia.idStato !== 0) {
                if (giornataTmp.primaFascia.presenzaFascia.idStato === null || giornataTmp.primaFascia.presenzaFascia.idStato === 2 || giornataTmp.primaFascia.presenzaFascia.idStato === 3) {
                    giornataTmp.primaFascia.presenzaFascia.statoPresenza = "A";
                    giornataTmp.primaFascia.presenzaFascia.idStato = 1;
                    giornataTmp.primaFascia.orarioEffettuato.oraInizio = null;
                    giornataTmp.primaFascia.orarioEffettuato.oraFine = null;
                } else {
                    giornataTmp.primaFascia.presenzaFascia.statoPresenza = "/";
                    giornataTmp.primaFascia.presenzaFascia.idStato = null;
                }

                if (giornataTmp.primaFascia.presenzaFascia.idStato === giornataTmp.secondaFascia.presenzaFascia.idStato) {
                    giornataTmp.presenzaGiornata.idStato = giornataTmp.primaFascia.presenzaFascia.idStato;
                    giornataTmp.presenzaGiornata.statoPresenza = giornataTmp.primaFascia.presenzaFascia.statoPresenza;
                } else {
                    giornataTmp.presenzaGiornata.idStato = 2;
                    giornataTmp.presenzaGiornata.statoPresenza = "PP";
                }

                giornataTmp.primaFascia.isModified = true;
                giornataTmp.presenzaGiornata.isModified = true;
            }
        }
        else if (fasciaInteressata === 2) {
            if (giornataTmp.secondaFascia.presenzaFascia.idStato !== 0) {
                if (giornataTmp.secondaFascia.presenzaFascia.idStato === null || giornataTmp.secondaFascia.presenzaFascia.idStato === 2 || giornataTmp.secondaFascia.presenzaFascia.idStato === 3) {
                    giornataTmp.secondaFascia.presenzaFascia.statoPresenza = "A";
                    giornataTmp.secondaFascia.presenzaFascia.idStato = 1;
                    giornataTmp.secondaFascia.orarioEffettuato.oraInizio = null;
                    giornataTmp.secondaFascia.orarioEffettuato.oraFine = null;
                } else {
                    giornataTmp.secondaFascia.presenzaFascia.statoPresenza = "/";
                    giornataTmp.secondaFascia.presenzaFascia.idStato = null;
                }
                if (giornataTmp.primaFascia.presenzaFascia.idStato === giornataTmp.secondaFascia.presenzaFascia.idStato) {
                    giornataTmp.presenzaGiornata.idStato = giornataTmp.primaFascia.presenzaFascia.idStato;
                    giornataTmp.presenzaGiornata.statoPresenza = giornataTmp.primaFascia.presenzaFascia.statoPresenza;
                } else {
                    giornataTmp.presenzaGiornata.idStato = 2;
                    giornataTmp.presenzaGiornata.statoPresenza = "PP";
                }
                giornataTmp.secondaFascia.isModified = true;
                giornataTmp.presenzaGiornata.isModified = true;
            }
        }

        if (fasciaInteressata !== 0) {
            if ((giornataTmp.primaFascia.presenzaFascia.idStato === giornataTmp.secondaFascia.presenzaFascia.idStato) || giornataTmp.primaFascia.presenzaFascia.idStato === 0 || giornataTmp.secondaFascia.presenzaFascia.idStato === 0) {
                if (giornataTmp.primaFascia.idStato !== 0) {
                    giornataTmp.presenzaGiornata.statoPresenza = giornataTmp.primaFascia.presenzaFascia.statoPresenza;
                    giornataTmp.presenzaGiornata.idStato = giornataTmp.primaFascia.presenzaFascia.idStato;
                } else {
                    giornataTmp.presenzaGiornata.statoPresenza = giornataTmp.secondaFascia.presenzaFascia.statoPresenza;
                    giornataTmp.presenzaGiornata.idStato = giornataTmp.secondaFascia.presenzaFascia.idStato;
                }
            } else {
                giornataTmp.presenzaGiornata.statoPresenza = "PP";
                giornataTmp.presenzaGiornata.idStato = 2;
            }
        }

        modificaOrari(giornataTmp)
        setGiornata(giornataTmp);
        setTimeout(() => {
            setIndexChanges(prev => prev + 1);
        }, 100)
    }

    function changeDateTimegiornata(position, data) {
        let fasciaInteressata = position.fascia;
        let posizione = position.posizione;
        let giornataTmp = { ...giornata };
        if (position !== null) {
            switch (fasciaInteressata) {
                case 1:
                    if (posizione === 1) giornataTmp.primaFascia.orarioEffettuato.oraInizio = data;
                    if (posizione === 2) giornataTmp.primaFascia.orarioEffettuato.oraFine = data;

                    if ((giornataTmp.primaFascia.orarioPrevisto.oraInizio === giornataTmp.primaFascia.orarioEffettuato.oraInizio) &&
                        (giornataTmp.primaFascia.orarioPrevisto.oraFine === giornataTmp.primaFascia.orarioEffettuato.oraFine)) {
                        giornataTmp.primaFascia.presenzaFascia.idStato = 3
                        giornataTmp.primaFascia.presenzaFascia.statoPresenza = "P"
                    } else {
                        giornataTmp.presenzaGiornata.statoPresenza = "PP";
                        giornataTmp.presenzaGiornata.idStato = 2;
                        giornataTmp.primaFascia.presenzaFascia.idStato = 2
                        giornataTmp.primaFascia.presenzaFascia.statoPresenza = "PP"
                    }
                    giornataTmp.presenzaGiornata.isModified = true
                    giornataTmp.primaFascia.isModified = true
                    break;
                case 2:
                    if (posizione === 1) giornataTmp.secondaFascia.orarioEffettuato.oraInizio = data;
                    if (posizione === 2) giornataTmp.secondaFascia.orarioEffettuato.oraFine = data;
                    if ((giornataTmp.secondaFascia.orarioPrevisto.oraInizio === giornataTmp.secondaFascia.orarioEffettuato.oraInizio) &&
                        (giornataTmp.secondaFascia.orarioPrevisto.oraFine === giornataTmp.secondaFascia.orarioEffettuato.oraFine)) {
                        giornataTmp.secondaFascia.presenzaFascia.idStato = 3
                        giornataTmp.secondaFascia.presenzaFascia.statoPresenza = "P"
                    } else {
                        giornataTmp.presenzaGiornata.statoPresenza = "PP";
                        giornataTmp.presenzaGiornata.idStato = 2;
                        giornataTmp.secondaFascia.presenzaFascia.idStato = 2
                        giornataTmp.secondaFascia.presenzaFascia.statoPresenza = "PP"
                    }
                    giornataTmp.presenzaGiornata.isModified = true
                    giornataTmp.secondaFascia.isModified = true
                    break;
                default:
            }

            if (fasciaInteressata !== 0) {
                if ((giornataTmp.primaFascia.presenzaFascia.idStato === giornataTmp.secondaFascia.presenzaFascia.idStato) || giornataTmp.primaFascia.presenzaFascia.idStato === 0 || giornataTmp.secondaFascia.presenzaFascia.idStato === 0) {
                    if (giornataTmp.primaFascia.idStato !== 0) {
                        giornataTmp.presenzaGiornata.statoPresenza = giornataTmp.primaFascia.presenzaFascia.statoPresenza;
                        giornataTmp.presenzaGiornata.idStato = giornataTmp.primaFascia.presenzaFascia.idStato;
                    } else {
                        giornataTmp.presenzaGiornata.statoPresenza = giornataTmp.secondaFascia.presenzaFascia.statoPresenza;
                        giornataTmp.presenzaGiornata.idStato = giornataTmp.secondaFascia.presenzaFascia.idStato;
                    }
                } else {
                    giornataTmp.presenzaGiornata.statoPresenza = "PP";
                    giornataTmp.presenzaGiornata.idStato = 2;
                }
            }
        }
        setGiornata(giornataTmp);
        modificaOrari(giornataTmp)

        setTimeout(() => {
            setIndexChanges(prev => prev + 1);
        }, 100)
    }

    function timeChanged(e, position) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        let dataTmp = DateTime.fromISO(utcDateTimeTmp);
        let dataLezione = DateTime.fromISO(giornata?.dataLezione, { zone: 'utc' });
        // Estrai l'anno, il mese e il giorno
        let yearTmp = dataLezione.year;
        let monthTmp = dataLezione.month;
        let dayTmp = dataLezione.day;
        // Cambia la data mantenendo l'orario
        let newDateTime = dataTmp.set({ year: yearTmp, month: monthTmp, day: dayTmp });
        newDateTime = newDateTime.plus({ days: 1 })
        let isoString = newDateTime.toUTC().toISO();
        changeDateTimegiornata(position, isoString);
    }

    return (
        <Fragment>
            <TableRow style={{ height: '40px' }}>
                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid black' }}>
                    {!isLoading ? (
                        <Box sx={{ backgroundColor: 'white', px: 2, py: 2 }}>
                            <Box display={"flex"} sx={{ alignItems: "center", justifyContent: 'space-between' }}>
                                <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                    <Typography sx={{ mr: 2 }}>  {(indexGiornata + 1) + ")"}</Typography>
                                    <Typography variant={mobile ? 'caption' : 'body2'}>
                                        {convertToItalianTimeAndShowWeekDay(giornata?.dataLezione)}
                                    </Typography>
                                </Box>
                                <Fragment>
                                    {(giornata?.presenzaGiornata?.idStato !== 0 && !giornata?.presenzaGiornata?.validata && canEditPresenze) && ( //0 è N.D.
                                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                            <Box width={"15px"} height={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => copiaDaOrarioPrevistoAEffettuato(0)}
                                                sx={{ cursor: 'pointer', mx: 1, p: '5px', borderRadius: '5px', color: '#1976d2', border: '1px solid #1976d2' }}>
                                                <ArrowDownward />
                                            </Box>
                                            <Box width={"15px"} height={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => modificaPresenza(0)}
                                                sx={{ mt: 1, cursor: 'pointer', mx: 1, p: '5px', borderRadius: '5px', color: returnAssenzaStyle("color", 0), border: returnAssenzaStyle("border", 0) }}>
                                                {returnAssenzaStyle("text", 0)}
                                            </Box>
                                        </Box>
                                    )}
                                </Fragment>
                            </Box>

                        </Box>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>

                <TableCell sx={{ borderRight: '1px solid black' }}>
                    {!isLoading ? (
                        <Box sx={{ backgroundColor: 'white', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <PresenzaTirocini isSmallSize={true} presenza={giornata?.presenzaGiornata} indexChanges={indexChanges} />
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent={"center"}>
                            <Skeleton variant="circular" width={40} height={40} sx={{ mx: 2 }} />
                        </Box>
                    )}
                </TableCell>

                <TableCell sx={{ py: 1.5, px: 1.5, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    {!isLoading ? (
                        <Grid container spacing={2} sx={{ display: "flex", alignItems: 'center' }}>
                            <Grid xs={3} sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                <PresenzaTirocini isSmallSize={true} presenza={giornata?.primaFascia?.presenzaFascia} indexChanges={indexChanges} />
                            </Grid>
                            <Grid xs={8}>
                                {giornata?.primaFascia?.presenzaFascia?.idStato === 0 ? (
                                    <Typography variant="body2" sx={{ ml: 2 }}><b>Fascia orario non definita.</b></Typography>
                                ) : (
                                    <Box >
                                        <Typography variant="body2">Orario previsto:</Typography>
                                        <Grid container>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(giornata?.primaFascia?.orarioPrevisto?.oraInizio)}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}>
                                            </Grid>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(giornata?.primaFascia?.orarioPrevisto?.oraFine)}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Typography sx={{ mt: 1 }} variant="body2">Orario effettuato:</Typography>
                                        {giornata?.primaFascia?.presenzaFascia?.idStato !== 1 ? (
                                            <Grid container>
                                                <Grid xs={12} md={5}>
                                                    <CustomTimePicker isStyleMod={true} position={{ fascia: 1, posizione: 1 }} isDisabled={!canEditPresenze || giornata?.presenzaGiornata?.validata}
                                                        value={giornata?.primaFascia?.orarioEffettuato?.oraInizio}
                                                        minTime={giornata?.primaFascia?.orarioPrevisto?.oraInizio}
                                                        maxTime={giornata?.primaFascia?.orarioEffettuato?.oraFine ?? giornata?.primaFascia?.orarioPrevisto?.oraFine}
                                                        timeChanged={timeChanged} referenceDate={dataDettaglio}
                                                        maxTimeMinusFive={true}
                                                        data={giornata?.dataLezione}
                                                        showError={giornata?.primaFascia?.orarioEffettuato?.oraInizio === null && giornata?.primaFascia?.orarioEffettuato?.oraFine !== null}
                                                        disableKeyboard={true}
                                                    />
                                                </Grid>
                                                <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}></Grid>
                                                <Grid xs={12} md={5}>
                                                        <CustomTimePicker isStyleMod={true} position={{ fascia: 1, posizione: 2 }} isDisabled={!canEditPresenze || giornata?.presenzaGiornata?.validata || giornata?.primaFascia?.orarioEffettuato?.oraInizio === null}
                                                        value={giornata?.primaFascia?.orarioEffettuato?.oraFine}
                                                        minTime={giornata?.primaFascia?.orarioEffettuato?.oraInizio ?? giornata?.primaFascia?.orarioPrevisto?.oraInizio}
                                                        maxTime={giornata?.primaFascia?.orarioPrevisto?.oraFine}
                                                        timeChanged={timeChanged} referenceDate={dataDettaglio}
                                                        minTimeMinusFive={true}
                                                        data={giornata?.dataLezione}
                                                        showError={giornata?.primaFascia?.orarioEffettuato?.oraInizio !== null && giornata?.primaFascia?.orarioEffettuato?.oraFine === null}
                                                        disableKeyboard={true}
                                                    />
                                                </Grid>
                                                {((giornata?.primaFascia?.orarioEffettuato?.oraInizio !== null && giornata?.primaFascia?.orarioEffettuato?.oraFine === null) ||
                                                    (giornata?.primaFascia?.orarioEffettuato?.oraInizio === null && giornata?.primaFascia?.orarioEffettuato?.oraFine !== null)) && (
                                                        <Grid xs={12} sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                                                            <WarningAmber color="error" sx={{ mr: 1 }} />
                                                            <Typography variant="caption" color="error">
                                                                Per poter salvare le modifiche è necessario compilare l'orario
                                                                <b>
                                                                    {(giornata?.primaFascia?.orarioEffettuato?.oraInizio !== null && giornata?.primaFascia?.orarioEffettuato?.oraFine === null) ? " di fine." : " di inizio."}
                                                                </b>
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        ) : (
                                            <Typography variant="body2" color="error"><b>Assente</b></Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                            <Grid xs={1}>
                                {/* Se è nd o assente non mostro la freccia*/}
                                {canEditPresenze && (<Fragment>
                                    {(giornata?.primaFascia?.presenzaFascia?.idStato !== 0 && !giornata?.presenzaGiornata?.validata) && (
                                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                            <Box width={"15px"} height={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => copiaDaOrarioPrevistoAEffettuato(1)}
                                                sx={{ cursor: 'pointer', mx: 1, p: '5px', borderRadius: '5px', color: '#1976d2', border: '1px solid #1976d2' }}>
                                                <ArrowDownward />
                                            </Box>
                                            <Box width={"15px"} height={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => modificaPresenza(1)}
                                                sx={{ mt: 1, cursor: 'pointer', mx: 1, p: '5px', borderRadius: '5px', color: returnAssenzaStyle("color", 1), border: returnAssenzaStyle("border", 1) }}>
                                                {returnAssenzaStyle("text", 1)}
                                            </Box>
                                        </Box>
                                    )}
                                </Fragment>)}
                            </Grid>
                        </Grid>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>

                <TableCell sx={{ py: 1, px: 1.5, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    {!isLoading ? (
                        <Grid container spacing={2} sx={{ display: "flex", alignItems: 'center' }}>
                            <Grid xs={3} sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                <PresenzaTirocini isSmallSize={true} presenza={giornata?.secondaFascia?.presenzaFascia} indexChanges={indexChanges} />
                            </Grid>
                            <Grid xs={8}>
                                {giornata?.secondaFascia?.presenzaFascia?.idStato === 0 ? (
                                    <Typography variant="body2" sx={{ ml: 2 }}><b>Fascia orario non definita.</b></Typography>
                                ) : (
                                    <Box >
                                        <Typography variant="body2">Orario previsto:</Typography>

                                        <Grid container>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(giornata?.secondaFascia?.orarioPrevisto?.oraInizio)}</Typography>
                                                </Box>                                                </Grid>
                                            <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}></Grid>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(giornata?.secondaFascia?.orarioPrevisto?.oraFine)}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>


                                        <Typography variant="body2" sx={{ mt: 1 }}>Orario effettuato:</Typography>
                                        {giornata?.secondaFascia?.presenzaFascia?.idStato !== 1 ? (

                                            <Grid container>
                                                <Grid xs={12} md={5}>
                                                    <CustomTimePicker isStyleMod={true} position={{ fascia: 2, posizione: 1 }} isDisabled={!canEditPresenze || giornata?.presenzaGiornata?.validata}
                                                        value={giornata?.secondaFascia?.orarioEffettuato?.oraInizio}
                                                        minTime={giornata?.secondaFascia?.orarioPrevisto?.oraInizio}
                                                        maxTime={giornata?.secondaFascia?.orarioEffettuato?.oraFine ?? giornata?.secondaFascia?.orarioPrevisto?.oraFine}
                                                        timeChanged={timeChanged} referenceDate={dataDettaglio}
                                                        maxTimeMinusFive={true}
                                                        data={giornata?.dataLezione}
                                                        showError={giornata?.secondaFascia?.orarioEffettuato?.oraInizio === null && giornata?.secondaFascia?.orarioEffettuato?.oraFine !== null}
                                                        disableKeyboard={true}
                                                    />
                                                </Grid>
                                                <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}></Grid>
                                                <Grid xs={12} md={5}>
                                                        <CustomTimePicker isStyleMod={true} position={{ fascia: 2, posizione: 2 }} isDisabled={!canEditPresenze || giornata?.presenzaGiornata?.validata || giornata?.secondaFascia?.orarioEffettuato?.oraInizio === null}
                                                        value={giornata?.secondaFascia?.orarioEffettuato?.oraFine}
                                                        minTime={giornata?.secondaFascia?.orarioEffettuato?.oraInizio ?? giornata?.primaFascia?.orarioPrevisto?.oraInizio}
                                                        maxTime={giornata?.secondaFascia?.orarioPrevisto?.oraFine}
                                                        timeChanged={timeChanged} referenceDate={dataDettaglio}
                                                        minTimeMinusFive={true}
                                                        data={giornata?.dataLezione}
                                                        showError={giornata?.secondaFascia?.orarioEffettuato?.oraInizio !== null && giornata?.secondaFascia?.orarioEffettuato?.oraFine === null}
                                                        disableKeyboard={true}
                                                    />
                                                </Grid>

                                                {((giornata?.secondaFascia?.orarioEffettuato?.oraInizio !== null && giornata?.secondaFascia?.orarioEffettuato?.oraFine === null) ||
                                                    (giornata?.secondaFascia?.orarioEffettuato?.oraInizio === null && giornata?.secondaFascia?.orarioEffettuato?.oraFine !== null)) && (
                                                        <Grid xs={12} sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                                                            <WarningAmber color="error" sx={{ mr: 1 }} />
                                                            <Typography variant="caption" color="error">
                                                                Per poter salvare le modifiche è necessario compilare l'orario
                                                                <b>
                                                                    {(giornata?.secondaFascia?.orarioEffettuato?.oraInizio !== null && giornata?.secondaFascia?.orarioEffettuato?.oraFine === null) ? " di fine." : " di inizio."}
                                                                </b>
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                            </Grid>) : (
                                            <Typography variant="body2" color="error"><b>Assente</b></Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                            <Grid xs={1}>
                                {canEditPresenze && (<Fragment>
                                    {(giornata?.secondaFascia?.presenzaFascia?.idStato !== 0 && !giornata?.presenzaGiornata?.validata) && (
                                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                            <Box width={"15px"} height={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => copiaDaOrarioPrevistoAEffettuato(2)}
                                                sx={{ cursor: 'pointer', mx: 1, p: '5px', borderRadius: '5px', color: '#1976d2', border: '1px solid #1976d2' }}>
                                                <ArrowDownward />
                                            </Box>
                                            <Box width={"15px"} height={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => modificaPresenza(2)}
                                                sx={{ mt: 1, cursor: 'pointer', mx: 1, p: '5px', borderRadius: '5px', color: returnAssenzaStyle("color", 2), border: returnAssenzaStyle("border", 2) }}>
                                                {returnAssenzaStyle("text", 2)}
                                            </Box>
                                        </Box>
                                    )}
                                </Fragment>)}
                            </Grid>
                        </Grid>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>
            </TableRow >
        </Fragment >
    )
}





