import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Divider, Box, Button, CircularProgress, FormControl, InputLabel, Select, Typography, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { RicercaAPI } from "../../api/ricercaApi";
import { RegistroAPI } from "../../api/registroApi";
import { UtilityAPI } from "../../api/utilityApi";
import { convertISODate } from "../shared/metodiDateUtility";
import RangeDate from "../shared/rangeDate";
import { ScrutiniAPI } from "../../api/scrutiniApi";

import { getAnnoFormativo } from "../shared/utilityMethods";


export default function SelezioneClassiTutor(props) {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [sedeSelezionataNome, setSedeSelezionataNome] = useState('');

    const [elencoClassi, setElencoClassi] = useState([]);

    const [nomeClasseSelezionata, setNomeClasseSelezionata] = useState("");
    const [codiceClasseSelezionata, setCodiceClasseSelezionata] = useState("");
    const [codiceSimonClasseSelezionata, setCodiceSimonClasseSelezionata] = useState("");
    const [hasLoaded, setHasLoaded] = useState(false)


    const [materieClasseSelezionata, setMaterieClasseSelezionata] = useState([])
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState('');


    const [trimestri, setTrimestri] = useState([]);
    const [trimestreSelezionato, setTrimestreSelezionato] = useState(-1)
    const [sedeUtente, setSedeUtente] = useState(-1);

    const [isRiepilogoAlunno, setIsRiepilogoAlunno] = useState(false)

    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [showOnlyPeriodi, setShowOnlyPeriodi] = useState(false);

    const [registro, setRegistro] = useState('');

    const [annoFormativo, setAnnoFormativo] = useState('');

    // useEffect(() => {
    //     getClassiTutor(); //TODO:
    //     // getClassiDocente();
    // }, [])

    //TODO: versione per POST
    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        if (props.riepilogoAlunno !== undefined)
            setIsRiepilogoAlunno(props.riepilogoAlunno);
    }, [props.riepilogoAlunno])

    useEffect(() => {
        if (props.showOnlyPeriodi !== undefined) {
            setShowOnlyPeriodi(props.showOnlyPeriodi)
        }
    }, [props.showOnlyPeriodi])

    // useEffect(() => {

    //     if (props.registro !== undefined) {
    //         setRegistro(props.registro)
    //         if (props.registro === 'didatticoSintesi') getClassiDocente();
    //         else if (props.registro === "riepilogoDidatticoAlunni") getClassiRiepilogoDidattico();
    //         else getClassiTutor();
    //     }
    // }, [props.registro])
    // TODO: versione POST
    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        if (props.registro !== undefined) {
            setAnnoFormativo(annoFormativoTmp);
            setRegistro(props.registro)
            if (props.registro === 'didatticoSintesi') getClassiDocente(annoFormativoTmp);
            else if (props.registro === "riepilogoDidatticoAlunni") getClassiRiepilogoDidattico(annoFormativoTmp);
            else getClassiTutor(annoFormativoTmp);
        }
    }, [props.registro])


    function changeIdClasseSelezionata(idClasse, nomeClasse, nomeSede, idSede, codice, codiceSimon) {
        setSedeUtente(idSede);
        setIdClasseSelezionata(idClasse);
        setNomeClasseSelezionata(nomeClasse);
        setCodiceClasseSelezionata(codice)
        setCodiceSimonClasseSelezionata(codiceSimon)
        setSedeSelezionataNome(nomeSede)
    }
    function changeIdMateriaSelezionata(idMateria, nomeMateria) {
        if (idMateria === 97) {
            let trimestreSelezionatoTmp = trimestri[0].ordinale;
            setTrimestreSelezionato(trimestreSelezionatoTmp);
        } 
        // else {
        //     setTrimestreSelezionato(-1);
        // }
        setIdMateriaSelezionata(idMateria);
        setNomeMateriaSelezionata(nomeMateria);
        if (props.changeNomeMateria) props.changeNomeMateria(nomeMateria);
    }

    function changeTrimestre(e) {
        setTrimestreSelezionato(e.target.value);
    }

    function goToElencoMaterie() {
        getTrimestri(idClasseSelezionata)
        registro === 'didatticoSintesi' ? getElencoMateriePerClasseDocente(idClasseSelezionata) : getMaterieByClasse(idClasseSelezionata);
        setTabIndex(1)
    }

    function confirmClasse() {
        let trimestreSelezionatoObj = trimestri[trimestreSelezionato - 1];

        let nomePeriodo = trimestreSelezionatoObj.nome;
        let dataInizio = trimestreSelezionatoObj.dataInizio;
        let dataFine = trimestreSelezionatoObj.dataFine;
        // let oreClasseSelezionata = generateArrayMaterieInClasseSelezionata();

        props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateriaSelezionata, nomeClasseSelezionata, trimestri, trimestreSelezionato, nomeMateriaSelezionata, sedeUtente, undefined, codiceClasseSelezionata, codiceSimonClasseSelezionata, nomePeriodo, sedeSelezionataNome);
    }

    // function generateArrayMaterieInClasseSelezionata() {
    //     let arrayOreTmp = [];
    //     for (let lezione of classiInGiornata) {
    //         if (lezione != null) {
    //             if (lezione?.idCorso === idClasseSelezionata) {
    //                 arrayOreTmp.push(lezione)
    //             }
    //         }
    //     }
    //     return arrayOreTmp;
    // }

    // async function getClassiTutor() {

    //     RegistroAPI.getElencoClassiTutor()
    //         .then((result) => {
    //             //Rimuovo eventuali duplicati
    //             let elencoClassiTmp = result.filter((obj, index, self) =>
    //                 index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
    //             );
    //             elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
    //             setElencoClassi([...elencoClassiTmp])
    //             setHasLoaded(true)
    //         })
    //         .catch(function (error) {
    //             if (error.response) {
    //             }
    //         })
    // }
    //TODO: versione POST
    async function getClassiTutor(annoFormativo) {
        let dataObj = {
            annoFormativo: annoFormativo
        }

        RegistroAPI.getElencoClassiTutor(dataObj)
            .then((result) => {
                //Rimuovo eventuali duplicati
                let elencoClassiTmp = result.filter((obj, index, self) =>
                    index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
                );
                elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
                setElencoClassi([...elencoClassiTmp])
                setHasLoaded(true)
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }

    async function getClassiRiepilogoDidattico(annoFormativo) {
        let dataObj = {
            annoFormativo: annoFormativo
        }
        ScrutiniAPI.getElencoClassiRiepilogoDidattico(dataObj)
            .then((result) => {
                //Rimuovo eventuali duplicati
                let elencoClassiTmp = result.filter((obj, index, self) =>
                    index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
                );
                elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
                setElencoClassi([...elencoClassiTmp])
                setHasLoaded(true)
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    // async function getClassiDocente() {

    //     RegistroAPI.getElencoClassiDocente()
    //         .then((result) => {
    //             // Rimuovo eventuali duplicati
    //             let elencoClassiTmp = result.filter((obj, index, self) =>
    //                 index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
    //             );
    //             elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
    //             setElencoClassi([...elencoClassiTmp])
    //             setHasLoaded(true)
    //         })
    //         .catch(function (error) {
    //             if (error.response) {
    //             }
    //         })
    // }
    //TODO: versione POST
    async function getClassiDocente(annoFormativo) {
        let dataObj = {
            annoFormativo: annoFormativo
        }
        RegistroAPI.getElencoClassiDocente(dataObj)
            .then((result) => {
                // Rimuovo eventuali duplicati
                let elencoClassiTmp = result.filter((obj, index, self) =>
                    index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
                );
                elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
                setElencoClassi([...elencoClassiTmp])
                setHasLoaded(true)
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }

    async function getMaterieByClasse(idClasse) {


        let dataObj = {
            idCorso: idClasse
        }

        RicercaAPI.getMaterieByClasse(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setMaterieClasseSelezionata([...result?.materie])
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    async function getElencoMateriePerClasseDocente(idClasse) {


        let dataObj = {
            idCorso: parseInt(idClasse)
        }

        RegistroAPI.getElencoMateriePerClasseDocente(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setMaterieClasseSelezionata([...result?.materie])
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    async function getTrimestri(idCorso) {

        UtilityAPI.getTrimestriCorso(idCorso)
            .then((result) => {
                if (result.trimestri.length > 0) {
                    setTrimestri([...result.trimestri])
                    setTrimestreSelezionato(result.trimestreCorrente[0].ordinale)
                } else {
                    setTrimestri([])
                }
            })
            .catch((error) => {
                if (error.response) {
                }
            })
    }



    function goToHome() {
        navigate("/")
    }

    function goBackToSelezioneClasse() {
        setTabIndex(0)
    }

    function changePeriodoDiInteresse(dataInizio, dataFine, trimestreNome) {

        let dataInizioTmp = dataInizio ?? new DateTime().toISO();
        let dataFineTmp = dataFine ?? new DateTime().toISO();

        setPeriodoDiInteresseInizio(dataInizioTmp);
        setPeriodoDiInteresseFine(dataFineTmp);

        props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateriaSelezionata, nomeClasseSelezionata, trimestri, trimestreSelezionato, nomeMateriaSelezionata, sedeUtente, false, codiceClasseSelezionata, codiceSimonClasseSelezionata, trimestreNome);

        // setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
        // setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));
        // setChangePeriodoInteresseOpen(false)

    }

    return (
        <Fragment>
            {tabIndex === 0 ? (
                <Fragment>

                    {hasLoaded ? (
                        elencoClassi.length > 0 ? (
                            <Fragment>
                                <Box sx={{ width: '100%', maxHeight: { xs: 'unset', md: '50vh' }, display: "flex", justifyContent: 'center', overflowY: 'scroll' }}>
                                    <Table sx={{ maxHeight: { xs: 'unset', md: '30vh' } }} >
                                        <TableBody>
                                            {elencoClassi.map((classe, index) => (
                                                <TableRow key={classe?.corso?.idCorso} sx={{ cursor: 'pointer' }} onClick={() => changeIdClasseSelezionata(classe?.corso?.idCorso, classe?.corso?.nomeCorso, classe?.sede?.nomeSede, classe?.sede?.idSede, classe?.corso?.codiceCorso, classe?.corso?.codiceSimon)}>
                                                    <TableCell sx={{ minWidth: '200px', background: (classe?.corso?.idCorso === idClasseSelezionata) ? '#1976D2' : 'white', color: (classe?.corso?.idCorso === idClasseSelezionata) ? 'white' : 'black' }}>
                                                        <Typography>
                                                            {classe?.corso?.codiceCorso ? classe?.corso?.codiceCorso + " - " : ""}{classe?.corso?.nomeCorso ?? ""} {classe?.corso?.codiceSimon ? "(" + classe?.corso?.codiceSimon + ") " : ""} - {classe?.sede?.nomeSede} {(classe?.corso?.idCorso === idClasseSelezionata) ? "- SELEZIONATA" : ""}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                                <Button sx={{ mt: 2 }} onClick={goToElencoMaterie} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Conferma e prosegui</Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Typography textAlign={"center"} color="red">Nessun corso associato </Typography>
                                <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                            </Fragment>
                        )
                    ) : (
                        <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                            <CircularProgress />
                        </Box>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    {!isRiepilogoAlunno ? (<Box>
                        <Typography><b>Corso selezionato:</b> {nomeClasseSelezionata} ({sedeSelezionataNome})</Typography>
                        {idMateriaSelezionata !== 97 && (<FormControl sx={{ width: '100%', mt: 2 }} > 
                        {/* idMateriaSelezionata = 97 tirocinio orientativo */}
                            <InputLabel id="materia-label"> Seleziona trimestre</InputLabel>
                            <Select
                                label='Seleziona trimestre '
                                value={trimestreSelezionato}
                                onChange={changeTrimestre}
                            >
                                <MenuItem
                                    key={-1}
                                    value={-1}
                                >
                                </MenuItem>
                                {trimestri.map((trimestre, index) => (
                                    <MenuItem
                                        key={trimestre.ordinale}
                                        // value={index}
                                        value={trimestre.ordinale}
                                    >
                                        {trimestre.nome} {"(" + convertISODate(trimestre.dataInizio) + " - " + convertISODate(trimestre.dataFine) + ")"}
                                    </MenuItem>
                                ))}
                            </Select>
                            {trimestri.length === 0 ? (<Typography color="error" variant="caption">Nessun trimestre impostato per il corso selezionato</Typography>) : null}
                        </FormControl>)
                        }
                        <Divider sx={{ mt: 2 }} />
                        {hasLoaded ? (
                            materieClasseSelezionata.length > 0 ? (
                                <Box maxHeight={"30vh"} style={{ overflow: "scroll" }}>
                                    <Table>
                                        <TableBody>
                                            {materieClasseSelezionata.map((materia, index) => (
                                                <TableRow key={materia.idMateria} sx={{ cursor: 'pointer' }} onClick={() => changeIdMateriaSelezionata(materia?.idMateria, materia?.nomeMateria)}>
                                                    <TableCell sx={{ minWidth: '200px', background: (materia.idMateria === idMateriaSelezionata) ? '#1976D2' : 'white', color: (materia.idMateria === idMateriaSelezionata) ? 'white' : 'black' }}>
                                                        {materia?.nomeMateria}  {(materia.idMateria === idMateriaSelezionata) ? "- SELEZIONATA" : ""}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                            ) : (
                                <Typography textAlign={"center"} color="red">Nessuna materia associata al corso selezionato</Typography>
                            )
                        ) : (
                            <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                                <CircularProgress />
                            </Box>
                        )}
                        <Box>
                            <Button sx={{ mt: 2 }} onClick={goBackToSelezioneClasse} variant="outlined" fullWidth color="info" >Torna alla selezione del corso</Button>
                            <Button sx={{ my: 2 }} disabled={idClasseSelezionata === -1 || idMateriaSelezionata === -1 || trimestreSelezionato === -1} onClick={confirmClasse} variant="contained" fullWidth color="success" >
                                Conferma e prosegui
                            </Button>
                        </Box>
                    </Box>
                    ) : (
                        <Fragment>
                            <FormControl sx={{ width: '100%', mt: 2 }} >
                                <InputLabel id="materia-label"> Seleziona trimestre</InputLabel>
                                <Select
                                    label='Seleziona trimestre '
                                    value={trimestreSelezionato}
                                    onChange={changeTrimestre}
                                >
                                    <MenuItem
                                        key={-1}
                                        value={-1}
                                    >
                                    </MenuItem>
                                    {trimestri.map((trimestre, index) => (
                                        <MenuItem
                                            key={trimestre.ordinale}
                                            // value={index}
                                            value={trimestre.ordinale}
                                        >
                                            {trimestre.nome} {"(" + convertISODate(trimestre.dataInizio) + " - " + convertISODate(trimestre.dataFine) + ")"}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {trimestri.length === 0 ? (<Typography color="error" variant="caption">Nessun trimestre impostato per il corso selezionato</Typography>) : null}
                            </FormControl>
                            <Box>
                                <Button sx={{ mt: 2 }} onClick={goBackToSelezioneClasse} variant="outlined" fullWidth color="info" >Torna alla selezione del corso</Button>
                                <Button sx={{ my: 2 }} disabled={idClasseSelezionata === -1 || trimestreSelezionato === -1} onClick={confirmClasse} variant="contained" fullWidth color="success" >
                                    Conferma e prosegui
                                </Button>
                            </Box>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}