import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, useTheme, useMediaQuery, Alert, Snackbar, TextField, Collapse } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CryptoJS from 'crypto-js';

import { ArrowBack } from "@mui/icons-material";
import { DateTime } from "luxon";

import EventoChip from "../../registri/shared/eventoChip";

import { convertISODate, convertDataGetProssimeLezione, convertDataDiNascita } from '../../shared/metodiDateUtility';
import { ScrutiniAPI } from "../../../api/scrutiniApi";
import SelezioneClassiTutor from "../../riepilogo-attivita-formativa/selezioneClassiTutor";
import ModificaVotiRegistroDidattico from "./modificaVotiRegistroDidattico";
import { copyArrayWithoutReference } from "../../shared/utilityMethods";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Breadcrumb from "../../registri/shared/breadcrumb";

export default function RegistroDidatticaSintesiMateria(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const moltiplicatoreRef = useRef(null);

    const { t } = useTranslation();

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true)
    const [permessiUtente, setPermessiUtente] = useState([]);

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");
    const [nomeMateria, setNomeMateria] = useState("");

    const [listAlunni, setListAlunni] = useState([]);
    const [listAlunniSupporto, setListAlunniSupporto] = useState([]);
    const [dialogDettaglioRegistroOpen, setDialogDettaglioRegistroOpen] = useState(false);

    const [trimestri, setTrimestri] = useState([]);
    const [trimestreSelezionato, setTrimestreSelezionato] = useState(-1);

    //Periodo di interesse
    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");

    const [hasLoaded, setHasLoaded] = useState(false);

    //DETTAGLIO ALUNNO
    const [moltiplicatore, setMoltiplicatore] = useState(1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [sedeUtente, setSedeUtente] = useState('');

    const [openDialogModifica, setOpenDialogModifica] = useState(false);
    const [openDialogModificaMoltiplicatore, setOpenDialogModificaMoltiplicatore] = useState(false);


    const [showOre, setShowOre] = useState(false)

    const [moltiplicatoreIsLoading, setMoltiplicatoreIsLoading] = useState(false);
    const [moltiplicatoreSupporto, setMoltiplicatoreSupporto] = useState('')
    const [moltiplicatoreHasError, setMoltiplicatoreHasError] = useState(false);
    const [moltiplicatoreErrorMsg, setMoltiplicatoreErrorMsg] = useState("");

    const [periodoComplessivo, setPeriodoComplessivo] = useState(null);
    const [periodoSelezionato, setPeriodoSelezionato] = useState(null);

    //Stato
    const [nomeStato, setNomeStato] = useState("-");
    const [canEditVotiProposti, setCanEditVotiProposti] = useState(true) //False
    const [showSezioneScrutino, setShowSezioneScrutinio] = useState(true) //false
    const [showMoltiplicatore, setShowMoltiplicatore] = useState(false)

    const [isError, setIsError] = useState(true);
    const [errorMsg, setErrorMsg] = useState("Al momento non è possibile visualizzare il corso selezionato.")
    const [votiCondottaDaAltraMateria, setVotiCondottaDaAltraMateria] = useState('');

    const [periodiPassati, setPeriodiPassati] = useState([]);
    useEffect(() => {
        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)
        }

        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);
        }

        if (localStorage.getItem("sede")) {
            let sedeTmp = localStorage.getItem("sede");
            let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let sedeParsed = JSON.parse(decryptedSede);
            setSedeUtente(sedeParsed.id)
        }

    }, [])

    function goToSelezionaOra() {
        setPeriodiPassati([])
        setDialogSelezionaClasseOpen(true);
    }

    function changeIdClasseSelezionata(idClasse, dataInizio, dataFine, idMateria, nomeClasse, trimestri, trimestreSelezionato, nomeMateria, sedeUtente, ignora, codiceClasseSelezionata, codiceSimonClasseSelezionata) {
        if (idClasse !== undefined) {
            let idCorsoCrypted = CryptoJS.AES.encrypt(idClasse.toString(), process.env.REACT_APP_PRIVATE_KEY).toString();
            sessionStorage.setItem("classe", idCorsoCrypted);
            setIdClasseSelezionata(idClasse);
            setIdMateriaSelezionata(idMateria);
            setNomeClasse(codiceClasseSelezionata + " - " + nomeClasse + " (" + codiceSimonClasseSelezionata + ")");
            setDialogSelezionaClasseOpen(false);

            setNomeMateria(nomeMateria)
            setTrimestri([...trimestri])
            setTrimestreSelezionato(trimestreSelezionato);

            getRegistroDidatticoMateria(idClasse, dataInizio, dataFine, idMateria, trimestreSelezionato)
          //  getPermessiStato(trimestreSelezionato, idClasse, idMateria)
        }
    }

    function eventoModificato(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
        // getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
        let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
        let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

        let dataInizioObject = DateTime.fromISO(dataInizioTmp);
        let dataFineObject = DateTime.fromISO(dataFineTmp);

        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
        if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        else if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
        {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else return null
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function changeSede(nome) {
        setSedeUtente(nome)
    }

    function changeNomeMateria(nome) {
        setNomeMateria(nome)
    }

    function getRegistro() { //TODO: Richiamta quando chiude i voti, ma non serve a nulla
        // getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
        //console.log('chiuso dettaglio')
    }



    function changeOpenDialogModifica() {
        let isOpen = !openDialogModifica;
        setOpenDialogModifica(isOpen)
        setHasLoaded(isOpen)
        if (isOpen === false) {
            getPermessiStato(trimestreSelezionato, idClasseSelezionata, idMateriaSelezionata)
            getRegistroDidatticoMateria(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata, trimestreSelezionato)
        }

    }

    function changeOpenDialogModificaMoltiplicatore() {
        setOpenDialogModificaMoltiplicatore(prev => !prev)
    }

    function changeOpenOre() {
        setShowOre(prev => !prev)
    }


    function changeConfermaModifica(voti) {
        editVotoProposto(voti)
        getPermessiStato(trimestreSelezionato, idClasseSelezionata, idMateriaSelezionata)
    }

    function changeMoltiplicatore(e) {
        //setMoltiplicatoreSupporto(e.target.value)
        // moltiplicatoreRef.current.value = e.target.value;

        let newValue = e.target.value;

        let newStr = "";

        if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            // if (newStr < minMoltiplicatore) newStr = minMoltiplicatore; //non permette di mettere moltiplicatore inferiore a min
            // if (newStr > maxMoltiplicatore) newStr = maxMoltiplicatore; //non permette di superare voto max
        } else {
            newStr = ""; //resetta a ''
        }

        moltiplicatoreRef.current.value = newStr
        setMoltiplicatoreSupporto(newStr)
    }

    function changeMoltiplicatoreBlur(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr < 0) newStr = "1";
            //  if (newStr < minMoltiplicatore) newStr = minMoltiplicatore.toString(); //non permette di mettere moltiplicatore inferiore a min
            // if (newStr > maxMoltiplicatore) newStr = maxMoltiplicatore.toString(); //non permette di superare voto max
        } else {
            newStr = ""; //resetta a ''
        }
        moltiplicatoreRef.current.value = newStr
        setMoltiplicatoreSupporto(newStr)
    }

    function confermaChangeMoltiplicatore() {

        editMoltiplicatore(moltiplicatoreRef.current.value);
        getPermessiStato(trimestreSelezionato, idClasseSelezionata, idMateriaSelezionata)
    }

    function calcolaColorePercentuale(percentualeString) {
        if (percentualeString !== undefined) {
            let nuovaString = percentualeString.split("%", 2);
            let percentualeIntera = parseInt(nuovaString);
            if (percentualeIntera < 25) return "green"
            else return "red";
        } else return "black"
    }

    async function editMoltiplicatore(moltiplicatoreTmp) {

        let dataObj = {
            idCorso: idClasseSelezionata,
            idMateria: idMateriaSelezionata,
            idPeriodo: trimestreSelezionato,
            moltiplicatore: parseInt(moltiplicatoreTmp)
        }
        setMoltiplicatoreIsLoading(true);

        ScrutiniAPI.editMoltiplicatore(dataObj)
            .then((result) => {
                setMoltiplicatoreHasError(false)
                let alunniTmp = listAlunni;
                for (let i = 0; i < alunniTmp.length; i++) {
                    let indexAlunno = result.findIndex(a => a?.idAlunno === alunniTmp[i]?.alunno?.id);
                    if (indexAlunno !== -1) {
                        alunniTmp[i].medie = result[indexAlunno].medie;
                    }
                    if (trimestreSelezionato === 3)
                        alunniTmp[i].votiFinali.mediaFinale = result[indexAlunno].mediaFinale
                }
                setMoltiplicatore(parseInt(moltiplicatoreTmp))
                setListAlunni([...alunniTmp])
                setListAlunniSupporto([...alunniTmp])
                setOpenDialogModificaMoltiplicatore(false)
                setMoltiplicatoreIsLoading(false);

            })
            .catch(function (error) {
                setMoltiplicatoreIsLoading(false);
                setMoltiplicatoreHasError(true)
                setMoltiplicatoreErrorMsg(error?.response?.data?.data[0]?.msg)
            })
    }

    async function editVotoProposto(arrayVoti) {

        let dataObj = {
            idCorso: idClasseSelezionata,
            idMateria: idMateriaSelezionata,
            idPeriodo: trimestreSelezionato,
            voti: arrayVoti
        }
        ScrutiniAPI.editVotoProposto(dataObj)
            .then((result) => {
                setOpenDialogModifica(false)
                setHasLoaded(true)

                if (trimestreSelezionato !== 3) {
                    let listAlunniTmp = listAlunniSupporto

                    for (let a of listAlunniTmp) {
                        let votiTmp = result.find(oggetto => oggetto.idAlunno === a.alunno.id)

                        a.votoProposto = votiTmp.votoMateria;
                        a.condotta.motivazione = votiTmp.motivazione;
                        a.condotta.interazione = votiTmp.interazione;
                        a.condotta.relazio = votiTmp.relazione;
                        a.condotta.mediaCondotta = votiTmp.mediaCondotta;
                    }
                    setVotiCondottaDaAltraMateria('')
                    setListAlunniSupporto([...listAlunniTmp])
                    setListAlunni([...listAlunniTmp])
                } else getRegistroDidatticoMateria(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata, trimestreSelezionato)

                setAlertMsg("Voti modificati correttamente");
                setAlertSeverity("success");
                setAlertOpen(true);



            })
            .catch(function (error) {
                setMoltiplicatoreIsLoading(false);
                setMoltiplicatoreHasError(true)
                setMoltiplicatoreErrorMsg(error?.response?.data?.data[0]?.msg)

                setAlertMsg(error?.response?.data?.data[0]?.msg);
                setAlertSeverity("error");
                setAlertOpen(true);
            })
    }

    async function getPermessiStato(idPeriodo, idCorso, idMateria) {

        console.log(idPeriodo, idCorso, idMateria)
        let idMateriaTmp = idMateria ?? -1;

        let dataObj = {
            idCorso: idCorso,
            idPeriodo: idPeriodo,
        }

        if (idMateriaTmp !== 97) {
            ScrutiniAPI.getPermessiStatoRegistroDidattica(dataObj)
                .then((result) => {
                    if (result?.nomeStato !== undefined)
                        setNomeStato(result?.nomeStato)
                    else setNomeStato("-")

                    //TODO: Togliere i commenti e riabilitare l'if
                    if (result?.canEditVotiProposti !== undefined)
                        setCanEditVotiProposti(result?.canEditVotiProposti)
                    else setCanEditVotiProposti(false)
                    if (result?.showSezioneScrutino !== undefined)
                        setShowSezioneScrutinio(result?.showSezioneScrutino)
                    else setShowSezioneScrutinio(false)
                    if (result?.showMoltiplicatore !== undefined)
                        setShowMoltiplicatore(result?.showMoltiplicatore)
                    else setShowMoltiplicatore(false)

                })
                .catch(function (error) {
                })
        } else {
            ScrutiniAPI.getPermessiStatoRegistroDidatticaTO(dataObj)
                .then((result) => {
                    if (result?.nomeStato !== undefined)
                        setNomeStato(result?.nomeStato)
                    else setNomeStato("-")

                    //TODO: Togliere i commenti e riabilitare l'if
                    if (result?.canEditVotiProposti !== undefined)
                        setCanEditVotiProposti(result?.canEditVotiProposti)
                    else setCanEditVotiProposti(false)
                    if (result?.showSezioneScrutino !== undefined)
                        setShowSezioneScrutinio(result?.showSezioneScrutino)
                    else setShowSezioneScrutinio(false)
                    if (result?.showMoltiplicatore !== undefined)
                        setShowMoltiplicatore(result?.showMoltiplicatore)
                    else setShowMoltiplicatore(false)
                })
                .catch(function (error) {
                })
        }
    }

    function trovaVotiCondottaDaAltraMateria(alunni) {
        for (let i = 0; i < alunni.length; i++) {
            if (alunni[i].votiCondottaDaAltraMateria !== undefined) {
                return alunni[i].votiCondottaDaAltraMateria;
            }
        }
        return '';
    }

    async function getRegistroDidatticoMateria(idCorso, dataDiInteresseInizio, dataDiInteresseFine, idMateria, idPeriodo) {
        let dataObj = {
            idPeriodo: idPeriodo.toString(),
            idCorso: idCorso.toString(),
            idMateria: idMateria.toString()
        }
        setPeriodoDiInteresseInizio(dataDiInteresseInizio);
        setPeriodoDiInteresseFine(dataDiInteresseFine);

        setFormattedPeriodoDiInteresseInizio(convertISODate(dataDiInteresseInizio));
        setFormattedPeriodoDiInteresseFine(convertISODate(dataDiInteresseFine));

        ScrutiniAPI.getRegistroDidatticoMateria(dataObj)
            .then((result) => {
                if (result.alunni.length > 0) {
                    setIsError(false);

                    let arrayPeriodiPassati = [];

                    let alunniTmp = copyArrayWithoutReference(result.alunni);
                    let votiCondottaDaAltraMateriaTmp = trovaVotiCondottaDaAltraMateria(alunniTmp);
                    setVotiCondottaDaAltraMateria(votiCondottaDaAltraMateriaTmp);

                    for (let i = 0; i < alunniTmp.length; i++) {

                        if (idMateria !== 97) {
                            if (arrayPeriodiPassati.length < alunniTmp[i].votiScrutiniPassati.length) {
                                arrayPeriodiPassati = alunniTmp[i].votiScrutiniPassati;
                            }
                        }

                        let votiScritti = alunniTmp[i]?.votiScritti;
                        if (votiScritti && votiScritti.length > 0) {
                            for (let voto of votiScritti) {
                                voto.contenuto = {
                                    tipologia_comunicazioni: 4,
                                    contenuto: voto.contenuto
                                };
                            }
                        }

                        let votiPratici = alunniTmp[i]?.votiPratici;

                        if (votiPratici && votiPratici.length > 0) {
                            for (let voto of votiPratici) {
                                voto.contenuto = {
                                    tipologia_comunicazioni: 4,
                                    contenuto: voto.contenuto
                                };
                            }
                        }

                        let votiOrali = alunniTmp[i]?.votiOrali;
                        if (votiOrali && votiOrali.length > 0) {
                            for (let voto of votiOrali) {
                                voto.contenuto = {
                                    tipologia_comunicazioni: 4,
                                    contenuto: voto.contenuto
                                };
                            }
                        }

                        let valutazioni = alunniTmp[i]?.valutazioni;
                        if (valutazioni && valutazioni.length > 0) {
                            for (let valutazione of valutazioni) {
                                valutazione.contenuto = {
                                    tipologia_comunicazioni: 5,
                                    contenuto: {
                                        docente: valutazione.docente,
                                        materia: valutazione.materia,
                                        idOrario: valutazione.idOrario,
                                        tipologia: valutazione.tipologia,
                                        dataLezione: valutazione.dataLezione
                                    }
                                };
                            }
                        }

                        alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i].alunno);

                    }

                    setListAlunni([...alunniTmp])
                    setListAlunniSupporto([...alunniTmp])

                    setMoltiplicatore(result?.moltiplicatoreMedia)
                    setMoltiplicatoreSupporto(result?.moltiplicatoreMedia)
                    setPeriodoSelezionato(result?.periodo?.periodoCorrente);
                    setPeriodoComplessivo(result?.periodo?.periodoComplessivo)
                    if (idMateria !== 97) { setPeriodiPassati([...arrayPeriodiPassati]) }
                    setHasLoaded(true)
                    getPermessiStato(idPeriodo, idCorso, idMateria)
                }
            })
            .catch(function (error) {
                getPermessiStato(idPeriodo, idCorso, idMateria);

                setListAlunni([])
                setListAlunniSupporto([])
                setHasLoaded(true);
                setIsError(true);

                let errorMsg = "";

                if (error.response.status === 404) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile visualizzare il corso selezionato.";
                } else {
                    errorMsg = "Al momento non è possibile visualizzare il corso selezionato.";
                }

                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }

    function returnVotoConverted(voto) {
        //{alunno?.condotta?.motivazione != 0 ? (alunno?.condotta?.motivazione ?? '-') : 'N.V.'}
        if (voto != null && voto !== undefined) {
            if (voto > 0) {
                return voto;
            } else if (voto === 0) {
                return "N.V."
            } else return "/"
        } else return "-"
    }

    function changeColorVoto(voto) {
        if (voto != null && voto !== undefined) {
            if (voto == -1) return "black"
            else if (voto >= 18) {
                return "green"
            } else return "red";
        }
    }

    return (
        <Fragment>
            {(idClasseSelezionata !== -1 && dialogSelezionaClasseOpen === false) || (dialogDettaglioRegistroOpen === true) ? (
                hasLoaded ? (
                    isError ? (
                        <Box sx={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography variant="h6">{errorMsg}</Typography>
                            <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' }, mt: 1 }} variant="contained" startIcon={<ArrowBack />}>
                                Torna alla selezione del corso
                            </Button>
                        </Box>
                    ) : (
                        <Fragment>
                            <Breadcrumb sections={["Registro didattico di sintesi della materia", nomeClasse]} />

                            <Box sx={{ px: 5 }}>


                                <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                                    <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                        Torna alla selezione del corso
                                    </Button>
                                    {/* <Button variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} startIcon={<EditCalendar />} onClick={openCambiaTrimestreDialog}>
                                Cambia trimestre
                            </Button> */}
                                    {canEditVotiProposti && (
                                        <Button variant='contained' color="success" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeOpenDialogModifica}>
                                            Modifica proposta di voto
                                        </Button>
                                    )}
                                    <Button variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeOpenOre}>
                                        {showOre ? "Nascondi ore lezione" : "Mostra ore lezione"}
                                    </Button>

                                </Box>
                                <Box px={1} display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: "start", md: 'start' }} mt={2}>
                                    <Box sx={{ width: { xs: '100%', md: 'unset' }, mr: { xs: 0, md: 2 } }}>
                                        <Typography variant="caption">
                                            Corso:
                                        </Typography>
                                        <Typography color={'primary'}
                                            variant={mobile ? 'caption' : 'body1'}
                                            sx={{ fontWeight: 'bold', mr: 1 }}>
                                            {(nomeClasse ?? "").toUpperCase()}
                                        </Typography>
                                    </Box>
                                    {idMateriaSelezionata !== 97 && (<Box sx={{ width: { xs: '100%', md: 'unset' }, mr: { xs: 0, md: 2 } }}>
                                        <Typography variant="caption">
                                            Periodo:
                                        </Typography>
                                        <Typography variant={mobile ? 'caption' : 'body1'} sx={{ fontWeight: { xs: 'bold', md: '' }, mr: 1 }}>
                                            {periodoSelezionato?.nome + " (" + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + ")"}
                                        </Typography>
                                    </Box>)}
                                    <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
                                        <Typography variant='caption'>Stato:</Typography>
                                        <Typography color="secondary" variant={mobile ? 'caption' : 'body1'}
                                            sx={{ fontWeight: 'bold', mr: 1 }}><b>{nomeStato ?? "-"}</b></Typography>
                                    </Box>
                                </Box>
                                <Collapse in={showOre}>
                                    <Grid container columnSpacing={2} sx={{ display: "flex", mt: 2, flexDirection: 'row', alignItems: "stretch" }}>
                                        {idMateriaSelezionata !== 97 && (<Grid xs={12} md={6} xl={4}>
                                            <Box sx={{ border: '1px solid #1976d2', p: 2, mb: { xs: 2, md: 0 }, borderRadius: '5px' }}>
                                                <Typography sx={{ mb: 1 }} variant="h6">{periodoSelezionato?.nome}</Typography>
                                                <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'start' }}>
                                                    <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' } }}>
                                                        <Box sx={{ mr: 1, mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                            <Typography><b>Ore svolte</b>: {periodoSelezionato?.ore?.svolte}</Typography>
                                                        </Box>
                                                        <Box sx={{ mr: 1, mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                            <Typography><b>Ore assegnate</b>: {periodoSelezionato?.ore?.assegnate}</Typography>
                                                        </Box>
                                                        <Box sx={{ mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                            <Typography><b>Ore rimanenti</b>: {periodoSelezionato?.ore?.rimanenti}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' } }}>
                                                        <Box sx={{ mt: { xs: 1, md: 1 }, display: "flex", flexDirection: "row" }}>
                                                            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', background: '#ff9800', p: 1, border: '2px solid #ed6c02', borderRadius: '10px' }}>
                                                                <Typography><b>Ore teoria</b>: {periodoSelezionato?.ore?.teoria?.ore} ({periodoSelezionato?.ore?.teoria?.percentuale})</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ mt: { xs: 1, md: 1 }, display: "flex", flexDirection: "row" }}>
                                                            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', background: '#4caf50', p: 1, border: '2px solid #2e7d32', borderRadius: '10px' }}>
                                                                <Typography><b>Ore pratica</b>: {periodoSelezionato?.ore?.pratica?.ore} ({periodoSelezionato?.ore?.pratica?.percentuale})</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>)}
                                        <Grid xs={12} md={6} xl={4}>
                                            <Box sx={{ border: '1px solid #1976d2', p: 2, mb: { xs: 2, md: 0 }, borderRadius: '5px' }}>
                                                <Typography sx={{ mb: 1 }} variant="h6">Complessivo</Typography>
                                                <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'start' }}>
                                                    <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' } }}>
                                                        <Box sx={{ mr: 1, mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                            <Typography><b>Ore svolte</b>: {periodoComplessivo?.ore?.svolte}</Typography>
                                                        </Box>
                                                        <Box sx={{ mr: 1, mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                            <Typography><b>Ore assegnate</b>: {periodoComplessivo?.ore?.assegnate}</Typography>
                                                        </Box>
                                                        <Box sx={{ mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                            <Typography><b>Ore rimanenti</b>: {periodoComplessivo?.ore?.rimanenti}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' } }}>
                                                        <Box sx={{ mt: { xs: 1, md: 1 }, display: "flex", flexDirection: "row" }}>
                                                            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', background: '#ff9800', p: 1, border: '2px solid #ed6c02', borderRadius: '10px' }}>
                                                                <Typography><b>Ore teoria</b>: {periodoComplessivo?.ore?.teoria?.ore} ({periodoComplessivo?.ore?.teoria?.percentuale})</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ mt: { xs: 1, md: 1 }, display: "flex", flexDirection: "row" }}>
                                                            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', background: '#4caf50', p: 1, border: '2px solid #2e7d32', borderRadius: '10px' }}>
                                                                <Typography><b>Ore pratica</b>: {periodoComplessivo?.ore?.pratica?.ore} ({periodoComplessivo?.ore?.pratica?.percentuale})</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Collapse>


                                <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '65vh' }, display: "flex", justifyContent: 'center' }}>
                                    <TableContainer sx={{ width: '100%' }} component={Paper}>
                                        <Table sx={{ minWidth: 100 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center" rowSpan={2}
                                                        className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, border: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',

                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                                        }}>
                                                            <Typography variant="caption">
                                                                Materia:
                                                            </Typography>
                                                            <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                                variant={mobile ? 'caption' : 'body1'}>
                                                                {nomeMateria}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {idMateriaSelezionata !== 97 && (<TableCell align="center" rowSpan={2}
                                                        className="row-head-secondColumn" sx={{ minWidth: '150px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 12 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                            textAlign: 'center'
                                                        }}>
                                                            <Typography >Ore ({trimestri[trimestreSelezionato - 1]?.nome})</Typography>
                                                            <Typography variant='caption' >assenze/svolte</Typography>
                                                        </Box>
                                                    </TableCell>)}
                                                    <TableCell align="center" rowSpan={2}
                                                        className="row-head" sx={{ minWidth: '150px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                            textAlign: 'center'
                                                        }}>
                                                            <Typography >Ore (complessive)</Typography>
                                                            <Typography variant='caption' >assenze/svolte/totale</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {idMateriaSelezionata === 97 ? (<TableCell align="center" rowSpan={2}
                                                        className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                        }}>
                                                            <Typography textAlign={"center"}>Voti</Typography>
                                                        </Box>
                                                    </TableCell>) :
                                                        (
                                                            <Fragment>
                                                                <TableCell align="center" rowSpan={2}
                                                                    className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                                >
                                                                    <Box sx={{
                                                                        backgroundColor: 'white',
                                                                        height: '100px',
                                                                        px: 2,
                                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                    }}>
                                                                        <Typography textAlign={"center"}>Voti scritti</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="center" rowSpan={2}
                                                                    className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                                >
                                                                    <Box sx={{
                                                                        backgroundColor: 'white',
                                                                        height: '100px',
                                                                        px: 2,
                                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                    }}>
                                                                        <Typography textAlign={"center"}>Voti pratici</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="center" rowSpan={2}
                                                                    className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                                >
                                                                    <Box sx={{
                                                                        backgroundColor: 'white',
                                                                        height: '100px',
                                                                        px: 2,
                                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                    }}>
                                                                        <Typography textAlign={"center"}>Voti orali</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                            </Fragment>)}
                                                    <TableCell align="center" rowSpan={2}
                                                        className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                        }}>
                                                            <Typography textAlign={"center"}>Valutazioni</Typography>
                                                        </Box>
                                                    </TableCell>

                                                    {(idMateriaSelezionata !== 96 && idMateriaSelezionata !== 97) && (<Fragment>
                                                        {periodiPassati.map((periodo, index) => (
                                                            <TableCell align="center" rowSpan={2}
                                                                className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: index === 0 ? '3px solid black' : '0px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: '#cecece',
                                                                    height: '100px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Voto scrutinio {periodo?.nomePeriodo}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        ))}
                                                    </Fragment>)}
                                                    <TableCell align="center" rowSpan={2}
                                                        className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: '3px solid black', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                        }}>
                                                            <Typography textAlign={"center"}>Media didattica</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {idMateriaSelezionata !== 97 ? (<TableCell align="center" rowSpan={2}
                                                        className="row-head" sx={{ minWidth: showMoltiplicatore ? '200px' : '150px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                        }}>
                                                            <Typography textAlign={"center"} mb={1}>Media delle valutazioni</Typography>
                                                            <Grid container spacing={1} width={'100%'}>
                                                                <Grid xs={12} md={showMoltiplicatore ? 6 : 12}>
                                                                    <TextField inputProps={{
                                                                        style: { textAlign: "center" }
                                                                    }} value={moltiplicatore} size="small" fullWidth disabled label='Moltiplicatore'></TextField>
                                                                </Grid>
                                                                {showMoltiplicatore && (
                                                                    <Grid xs={12} md={6}>
                                                                        <Button variant='contained' sx={{ height: '100%', fontSize: '0.8em' }} onClick={() => changeOpenDialogModificaMoltiplicatore()}>
                                                                            Modifica
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Box>
                                                    </TableCell>) :
                                                        (<Fragment>
                                                            <TableCell align="center" rowSpan={1}
                                                                className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: '3px solid green', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '50px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Frequenza</Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" rowSpan={1}
                                                                className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: '1px solid rgba(224,224,224,1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '50px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Comportamento</Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" rowSpan={1}
                                                                className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: '1px solid rgba(224,224,224,1)', borderRight: '3px solid green', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '50px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Partecipazione</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </Fragment>)
                                                    }
                                                    <TableCell align="center" rowSpan={2}
                                                        className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: !showSezioneScrutino ? '3px solid black' : '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                    >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '100px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                        }}>
                                                            <Typography textAlign={"center"}>Media suggerita</Typography>
                                                        </Box>
                                                    </TableCell>

                                                    {trimestreSelezionato === 3 && (
                                                        <TableCell align="center" rowSpan={2}
                                                            className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: '3px solid green', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                        >
                                                            <Box sx={{
                                                                backgroundColor: 'white',
                                                                height: '100px',
                                                                px: 2,
                                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                            }}>
                                                                <Typography textAlign={"center"}>Media finale suggerita</Typography>
                                                            </Box>
                                                        </TableCell>
                                                    )}
                                                    {showSezioneScrutino && (
                                                        <Fragment>
                                                            <TableCell align="center" rowSpan={2}
                                                                className="row-head" sx={{ minWidth: '100px', p: 0, borderLeft: trimestreSelezionato !== 3 ? '3px solid green' : '1px solid rgba(224,224,224,1)', borderRight: '3px solid green', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '100px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>{trimestreSelezionato === 3 ? "Voto finale proposto" : "Voto proposto"} </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" rowSpan={2}
                                                                className="row-head" sx={{ minWidth: votiCondottaDaAltraMateria !== '' ? '200px' : '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '100px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Motivazione e interesse</Typography>
                                                                    {votiCondottaDaAltraMateria !== '' && (<Typography variant="caption" textAlign={"center"}>{'(voti da ' + votiCondottaDaAltraMateria + ')'}</Typography>)}

                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" rowSpan={2}
                                                                className="row-head" sx={{ minWidth: votiCondottaDaAltraMateria !== '' ? '200px' : '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '100px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Interazione con il contesto</Typography>
                                                                    {votiCondottaDaAltraMateria !== '' && (<Typography variant="caption" textAlign={"center"}>{'(voti da ' + votiCondottaDaAltraMateria + ')'}</Typography>)}

                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" rowSpan={2}
                                                                className="row-head" sx={{ minWidth: votiCondottaDaAltraMateria !== '' ? '200px' : '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '100px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Relazione con gli altri</Typography>
                                                                    {votiCondottaDaAltraMateria !== '' && (<Typography variant="caption" textAlign={"center"}>{'(voti da ' + votiCondottaDaAltraMateria + ')'}</Typography>)}

                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center" rowSpan={2}
                                                                className="row-head" sx={{ minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                            >
                                                                <Box sx={{
                                                                    backgroundColor: 'white',
                                                                    height: '100px',
                                                                    px: 2,
                                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                                }}>
                                                                    <Typography textAlign={"center"}>Media condotta</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </Fragment>
                                                    )
                                                    }
                                                </TableRow>
                                                {idMateriaSelezionata === 97 && (<TableRow >
                                                    <TableCell align="center" rowSpan={1} colSpan={3}
                                                        className="secondRow-head" sx={{
                                                            minWidth: '300px', p: 0,
                                                            border: '1px solid rgba(224, 224, 224, 1)',
                                                            borderLeft: '3px solid green',
                                                            borderRight: '3px solid green',
                                                            zIndex: 5
                                                        }}>
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: '49px',
                                                            px: 2,
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                            <Grid container spacing={1} width={showMoltiplicatore ? '100%' : '50%'} >
                                                                <Grid xs={8} md={showMoltiplicatore ? 8 : 12}>
                                                                    <TextField inputProps={{
                                                                        style: { textAlign: "center", height: '15px' }
                                                                    }} value={moltiplicatore} size="small" fullWidth disabled label='Moltiplicatore'></TextField>
                                                                </Grid>
                                                                {showMoltiplicatore && (
                                                                    <Grid xs={4} md={4}>
                                                                        <Button variant='contained' sx={{ height: '100%', fontSize: '0.8em' }} onClick={() => changeOpenDialogModificaMoltiplicatore()}>
                                                                            Modifica
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>)}
                                            </TableHead>

                                            <TableBody>
                                                {listAlunni.map((alunno, index) => (
                                                    <TableRow style={{ height: '40px' }} key={alunno?.alunno?.id}>
                                                        <TableCell className="colonna-nomi" sx={{ backgroundColor: 'white', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{ backgroundColor: 'white', px: 2, py: 2 }}>

                                                                <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }}>
                                                                    <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                                                    <Typography variant={mobile ? 'caption' : 'body1'}
                                                                        // onClick={() => vediDettaglioAlunno(alunno.id, 0, alunno.nome, alunno.cognome, false)} 
                                                                        sx={{
                                                                            // cursor: 'pointer',
                                                                            mr: 2, fontWeight: alunno?.alunno?.isMaggiorenne ? 'bold' : 'unset'
                                                                        }}>
                                                                        {(alunno?.alunno?.cognome).toUpperCase() + " " + (alunno?.alunno?.nome).toUpperCase()}
                                                                    </Typography>
                                                                </Box>
                                                                {alunno.msgIscrizione != null ? (
                                                                    <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                        {idMateriaSelezionata !== 97 && (<TableCell className="colonna-nomi-secondColumn" sx={{ backgroundColor: 'white', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white', px: 2, py: 2 }}>
                                                                <Typography mr={1}> {alunno?.orePeriodo?.orePresenzaMateria + '/' + alunno?.orePeriodo?.oreTotaliMateria
                                                                    // + '/' + alunno?.orePeriodo?.oreMateriaPerAnno
                                                                }</Typography>
                                                                <Typography mr={1} color={calcolaColorePercentuale(alunno?.oreComplessive?.percentuale)}>{alunno?.orePeriodo?.percentuale}</Typography>
                                                            </Box>
                                                        </TableCell>)}
                                                        <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                                                                <Typography mr={1}> {alunno?.oreComplessive?.orePresenzaMateria + '/' + alunno?.oreComplessive?.oreTotaliMateria + '/' + alunno?.oreComplessive?.oreMateriaPerAnno}</Typography>
                                                                <Typography mr={1} color={calcolaColorePercentuale(alunno?.oreComplessive?.percentuale)}>{alunno?.oreComplessive?.percentuale}</Typography>
                                                            </Box>
                                                        </TableCell>
                                                        {/* <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}></TableCell> */}
                                                        {idMateriaSelezionata === 97 ? (<TableCell sx={{ px: 2, py: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {alunno?.votiScritti.length > 0 ? (
                                                                <Box display="flex" justifyContent={'center'}>
                                                                    {alunno?.votiScritti.map((comunicazione) => (
                                                                        comunicazione?.id !== undefined && (
                                                                            < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                            </Fragment>
                                                                        )
                                                                    ))}
                                                                </Box>
                                                            ) : null}
                                                            {alunno?.votiPratici.length > 0 ? (
                                                                <Box display="flex" justifyContent={'center'} mt={alunno?.votiScritti.length > 0 ? 1 : 0}>
                                                                    {alunno?.votiPratici.map((comunicazione) => (
                                                                        comunicazione?.id !== undefined && (
                                                                            < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                            </Fragment>
                                                                        )
                                                                    ))}
                                                                </Box>
                                                            ) : null}
                                                            {alunno?.votiOrali.length > 0 ? (
                                                                <Box display="flex" justifyContent={'center'} mt={(alunno?.votiScritti.length > 0 || alunno?.votiPratici.length > 0) ? 1 : 0}>
                                                                    {alunno?.votiOrali.map((comunicazione) => (
                                                                        comunicazione?.id !== undefined && (
                                                                            < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                            </Fragment>
                                                                        )
                                                                    ))}
                                                                </Box>
                                                            ) : null}
                                                        </TableCell>) : (
                                                            <Fragment>
                                                                <TableCell sx={{ px: 2, py: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    {alunno?.votiScritti.length > 0 ? (
                                                                        <Box display="flex" justifyContent={'center'}>
                                                                            {alunno?.votiScritti.map((comunicazione) => (
                                                                                comunicazione?.id !== undefined && (
                                                                                    < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                        <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                                    </Fragment>
                                                                                )
                                                                            ))}
                                                                        </Box>
                                                                    ) : null}
                                                                </TableCell>
                                                                <TableCell sx={{ px: 2, py: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    {alunno?.votiPratici.length > 0 ? (
                                                                        <Box display="flex" justifyContent={'center'}>
                                                                            {alunno?.votiPratici.map((comunicazione) => (
                                                                                comunicazione?.id !== undefined && (
                                                                                    < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                        <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                                    </Fragment>
                                                                                )
                                                                            ))}
                                                                        </Box>
                                                                    ) : null}
                                                                </TableCell>
                                                                <TableCell sx={{ px: 2, py: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    {alunno?.votiOrali.length > 0 ? (
                                                                        <Box display="flex" justifyContent={'center'}>
                                                                            {alunno?.votiOrali.map((comunicazione) => (
                                                                                comunicazione?.id !== undefined && (
                                                                                    < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                        <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                                    </Fragment>
                                                                                )
                                                                            ))}
                                                                        </Box>
                                                                    ) : null}
                                                                </TableCell>
                                                            </Fragment>
                                                        )}
                                                        <TableCell sx={{ px: 2, py: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {alunno?.valutazioni.length > 0 ? (
                                                                <Box display="flex" justifyContent={'start'}>
                                                                    {alunno?.valutazioni.map((comunicazione) => (
                                                                        comunicazione?.id !== undefined && (
                                                                            < Fragment key={alunno?.alunno?.id + comunicazione.id} >
                                                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                                                    <Typography color={'secondary'}>{comunicazione.peso}</Typography>
                                                                                    <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione.contenuto} alunno={alunno.alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                                </Box>
                                                                            </Fragment>
                                                                        )
                                                                    ))}
                                                                </Box>
                                                            ) : null}
                                                        </TableCell>

                                                        {(idMateriaSelezionata !== 96 && idMateriaSelezionata !== 97) && (<Fragment>
                                                            {alunno?.votiScrutiniPassati !== undefined && alunno?.votiScrutiniPassati.length > 0 && (
                                                                alunno?.votiScrutiniPassati.map((obj, index) => (
                                                                    <TableCell sx={{ background: '#cecece', px: 2, py: 1, borderLeft: index === 0 ? '3px solid black' : '0px', borderRight: '1px solid rgba(224, 224, 224, 1)' }} key={"voto" + alunno?.alunno.id + obj?.nomePeriodo}>
                                                                        <Typography textAlign={"center"} sx={{ color: changeColorVoto(obj?.voto) }}>{returnVotoConverted(obj?.voto)}</Typography>
                                                                    </TableCell>
                                                                ))
                                                            )}
                                                        </Fragment>)}
                                                        <TableCell sx={{ px: 2, borderLeft: '3px solid black', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                color: changeColorVoto(alunno?.medie?.mediaDidattica),
                                                                justifyContent: 'space-around'
                                                            }}>
                                                                {!moltiplicatoreIsLoading ? (
                                                                    <Typography>
                                                                        {returnVotoConverted(alunno?.medie?.mediaDidattica)}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography>-</Typography>
                                                                )}

                                                            </Box>
                                                        </TableCell>
                                                        {idMateriaSelezionata !== 97 ?
                                                            (
                                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        color: changeColorVoto(alunno?.medie?.mediaValutazioni),
                                                                        justifyContent: 'space-around'
                                                                    }}>
                                                                        {!moltiplicatoreIsLoading ? (
                                                                            <Typography>
                                                                                {returnVotoConverted(alunno?.medie?.mediaValutazioni)}
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography>-</Typography>
                                                                        )}
                                                                    </Box>
                                                                </TableCell>
                                                            )
                                                            :
                                                            (
                                                                <Fragment>
                                                                    <TableCell sx={{
                                                                        px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                                        borderLeft: '3px solid green',
                                                                    }}>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            color: changeColorVoto(alunno?.medie?.frequenza),
                                                                            justifyContent: 'space-around',
                                                                        }}>
                                                                            {!moltiplicatoreIsLoading ? (
                                                                                <Typography>
                                                                                    {returnVotoConverted(alunno?.medie?.frequenza)}
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography>-</Typography>
                                                                            )}
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            color: changeColorVoto(alunno?.medie?.comportamento),
                                                                            justifyContent: 'space-around'
                                                                        }}>
                                                                            {!moltiplicatoreIsLoading ? (
                                                                                <Typography>
                                                                                    {returnVotoConverted(alunno?.medie?.comportamento)}
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography>-</Typography>
                                                                            )}
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell sx={{ px: 2, borderRight: '3px solid green', }}>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            color: changeColorVoto(alunno?.medie?.partecipazione),
                                                                            justifyContent: 'space-around'
                                                                        }}>
                                                                            {!moltiplicatoreIsLoading ? (
                                                                                <Typography>
                                                                                    {returnVotoConverted(alunno?.medie?.partecipazione)}
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography>-</Typography>
                                                                            )}
                                                                        </Box>
                                                                    </TableCell>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                color: changeColorVoto(alunno?.medie?.mediaSuggerita),
                                                                justifyContent: 'space-around'
                                                            }}>
                                                                {!moltiplicatoreIsLoading ? (
                                                                    <Typography>
                                                                        {returnVotoConverted(alunno?.medie?.mediaSuggerita)}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography>-</Typography>
                                                                )}
                                                            </Box>
                                                        </TableCell>

                                                        {trimestreSelezionato === 3 && (<TableCell sx={{ px: 2, borderLeft: '3px solid green', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                color: changeColorVoto(alunno?.votiFinali?.mediaFinale),
                                                                justifyContent: 'space-around'
                                                            }}>
                                                                {!moltiplicatoreIsLoading ? (
                                                                    <Typography>
                                                                        {returnVotoConverted(alunno?.votiFinali?.mediaFinale)}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography>-</Typography>
                                                                )}
                                                            </Box>
                                                        </TableCell>)}

                                                        {showSezioneScrutino && (
                                                            <Fragment>
                                                                {trimestreSelezionato === 3 ? (
                                                                    <TableCell sx={{ px: 2, borderLeft: trimestreSelezionato !== 3 ? '3px solid green' : '1px solid rgba(224,224,224,1)', borderRight: '3px solid green' }}>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            color: changeColorVoto(alunno?.votiFinali?.propostoFinale),
                                                                            justifyContent: 'space-around'
                                                                        }}>
                                                                            <Typography>
                                                                                {returnVotoConverted(alunno?.votiFinali?.propostoFinale)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell sx={{ px: 2, borderLeft: '3px solid green', borderRight: '3px solid green' }}>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            color: changeColorVoto(alunno?.votoProposto),
                                                                            justifyContent: 'space-around'
                                                                        }}>
                                                                            <Typography>
                                                                                {returnVotoConverted(alunno?.votoProposto)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                )}

                                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        color: changeColorVoto(alunno?.condotta?.motivazione),

                                                                        justifyContent: 'space-around'
                                                                    }}>
                                                                        <Typography>
                                                                            {returnVotoConverted(alunno?.condotta?.motivazione)}
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        color: changeColorVoto(alunno?.condotta?.interazione),
                                                                        justifyContent: 'space-around'
                                                                    }}>
                                                                        <Typography>
                                                                            {returnVotoConverted(alunno?.condotta?.interazione)}
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        color: changeColorVoto(alunno?.condotta?.relazio),
                                                                        justifyContent: 'space-around'
                                                                    }}>
                                                                        <Typography>
                                                                            {returnVotoConverted(alunno?.condotta?.relazio)}
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        color: changeColorVoto(alunno?.condotta?.mediaCondotta),
                                                                        justifyContent: 'space-around'
                                                                    }}>
                                                                        <Typography>
                                                                            {returnVotoConverted(alunno?.condotta?.mediaCondotta)}
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                            </Fragment>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Fragment>
                    )
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                    </Box>
                )
            ) : null
            }

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: 8 }}>
                <DialogTitle>Seleziona corso e materia di riferimento</DialogTitle>
                <DialogContent>
                    <SelezioneClassiTutor sedeUtente={sedeUtente} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} changeNomeMateria={changeNomeMateria} registro={'didatticoSintesi'} />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullScreen open={openDialogModifica}>
                <DialogContent>
                    <ModificaVotiRegistroDidattico periodiPassati={periodiPassati} periodoSelezionato={trimestreSelezionato} idMateria={idMateriaSelezionata}
                        trimestreSelezionato={trimestreSelezionato} closeDialog={changeOpenDialogModifica} conferma={changeConfermaModifica} nomeMateria={nomeMateria} nomeCorso={nomeClasse} listAlunni={listAlunniSupporto} tutor={false} votiCondottaDaAltraMateria={votiCondottaDaAltraMateria} />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xs" open={openDialogModificaMoltiplicatore}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <span>Modifica moltiplicatore</span>
                    {/* <IconButton onClick={changeOpenDialogModificaMoltiplicatore} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton> */}
                </DialogTitle>
                <DialogContent>
                    {/* <Typography sx={{ mb: 3 }}>Per il <b>{trimestri[trimestreSelezionato - 1]?.nome}</b> è possibile impostare il valore del moltiplicatore tra <b>{minMoltiplicatore}</b> e <b>{maxMoltiplicatore}</b>.</Typography> */}
                    <Box sx={{
                        display: 'flex',
                        // justifyContent: 'space-around',
                        my: 2
                    }}>
                        {/* {alunno?.condotta?.motivazione ?? '-'} */}
                        <TextField
                            defaultValue={moltiplicatoreSupporto}
                            inputRef={moltiplicatoreRef}
                            onChange={changeMoltiplicatore}
                            onBlur={changeMoltiplicatoreBlur}
                            label={'moltiplicatore'}
                            fullWidth
                        />
                        {/* <input type="number" min={minMoltiplicatore} max={maxMoltiplicatore} value={moltiplicatoreSupporto} onChange={changeMoltiplicatore}>
                        </input> */}
                    </Box>
                    {(moltiplicatoreIsLoading && !moltiplicatoreHasError) && (
                        <Typography sx={{ my: 1 }}>Moltiplicatore modificato correttamente, il processo potrebbe impiegare un po' di tempo.</Typography>

                    )}
                    {moltiplicatoreHasError && (
                        <Typography sx={{ my: 1 }} color="error">{moltiplicatoreErrorMsg}</Typography>
                    )}
                    <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'end'}>
                        <Button variant='outlined' color="error" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeOpenDialogModificaMoltiplicatore}>
                            annulla
                        </Button>
                        <Button disabled={moltiplicatoreSupporto <= 0 || moltiplicatoreSupporto == ""} variant='contained' color="success" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={confermaChangeMoltiplicatore}>
                            conferma
                        </Button>
                        {/* <Button disabled={(moltiplicatoreSupporto == "" && !moltiplicatoreIsLoading) || (minMoltiplicatore > moltiplicatoreSupporto || maxMoltiplicatore < moltiplicatoreSupporto)} variant='contained' color="success" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={confermaChangeMoltiplicatore}>
                            conferma
                        </Button> */}
                    </Box>

                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


            <Helmet>
                <title>{t("name")} - Registro didattico sintesi materia</title>
            </Helmet>
        </Fragment >
    )
}
