import { useState, useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import CustomIconButton from "../../shared/customIconButton";
import { Add, Delete, Edit, FormatListBulleted, Info, Search } from "@mui/icons-material";

export default function TableAziende(props) {

    const navigate = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (props.list !== undefined) {
            setList([...props.list]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100);
        }
    }, [props.list])

    function deleteItem(idItem) {
        if (props.deleteItem) props.deleteItem(idItem)
    }

    function visualizzaItem(idItem, canEdit) {
        if (props.visualizzaItem) props.visualizzaItem(idItem, canEdit)
    }

    function navigateToGestioneConvenzioni(pIva) {
        navigate("/tirocini/gestione-convenzioni", { state: { pIva: pIva } })
    }

    function navigateToCreaConvenzione(pIva) {
        navigate("/tirocini/gestione-convenzioni", { state: { pIva: pIva, createNew: true } })
    }

    function openLegendaDialog() {
        if (props.openLegendaDialog) props.openLegendaDialog();
    }

    return (
        <Paper>
            <TableContainer sx={{ width: '100%' }} >
                <Table sx={{ maxHeight: '40vh' }}>
                    <TableHead>
                        <TableRow >

                            <TableCell align="center"
                                className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    backgroundColor: 'white',
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Azienda</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    backgroundColor: 'white',
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body1" textAlign={"center"}><b>Stato</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    backgroundColor: 'white',
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>P.Iva</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    backgroundColor: 'white',
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Rappresentante legale</b></Typography>
                                </Box>
                            </TableCell>

                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    backgroundColor: 'white',
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Convenzioni</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body1"} textAlign={"center"}><b>Azioni</b></Typography>
                                    <Button onClick={openLegendaDialog} variant="outlined" size="small" sx={{ ml: 1 }} startIcon={<Info />}>Mostra legenda</Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow style={{ height: '40px' }} key={item?.id}>

                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ fontWeight: 'bold', ml: 2 }}>
                                                {(item?.ragioneSociale ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ height: '100%' }} >
                                            <Typography color={item?.stato === "Attiva" ? 'primary' : 'unset'} variant={mobile ? 'caption' : 'body2'} sx={{ textAlign: "center" }}>
                                                {(item?.stato ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>

                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%' }} textAlign={'center'}>
                                                {item?.pIva ?? ''}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ ml: 2 }}>
                                                {(item?.cognomeRappresentante ?? '').toUpperCase() + " " + (item?.nomeRappresentante ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>
                                            {item?.stato === "Attiva" && (
                                                <CustomIconButton buttonClicked={() => navigateToCreaConvenzione(item?.pIva)} mr={item?.ConvenzioniCollegate !== 0 ? 1 : 0} color="primary" variant="outlined" icon={<Add color="primary" />} />
                                            )}
                                            {item?.ConvenzioniCollegate !== 0 && (
                                                <CustomIconButton icon={<FormatListBulleted sx={{ color: "white" }} />} color="primary" mr={item?.canDelete ? 2 : 0} buttonClicked={() => navigateToGestioneConvenzioni(item?.pIva)} />
                                            )}
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>

                                        <CustomIconButton variant="outlined" icon={item?.canEdit ? <Edit color="primary" /> : <Search color="primary" />} color="primary" mr={item?.canDelete ? 2 : 0} buttonClicked={() => visualizzaItem(item?.id, item?.canEdit)} />

                                        {item?.canDelete && (
                                            <CustomIconButton buttonClicked={() => deleteItem(item?.id)} icon={<Delete sx={{ color: "white" }} />} color="error" />
                                        )}

                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}