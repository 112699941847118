import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { TirociniAPI } from "../../../../api/tirociniApi";
import Grid from '@mui/material/Unstable_Grid2';
import { Add, ArrowBack, ArrowForward, Check, Close, Delete, Edit } from "@mui/icons-material";
import CustomDatePicker from "../../../shared/customDatePicker";
import { convertDataGetProssimeLezione } from "../../../shared/metodiDateUtility";
import DialogConferma from "../../../shared/dialogConferma";
import TableSediPersone from "./tableSediPersone";
import { DateTime } from 'luxon';

export default function TutorAzienda(props) {


    const [idAzienda, setIdAzienda] = useState(-1);
    const [listTutor, setListTutor] = useState([]);

    const [idPersona, setIdPersona] = useState(-1);
    const [CFTutor, setCFTutor] = useState('');
    const [CFTutorValid, setCFTutorValid] = useState(true);
    const [CFTutorMessageError, setCFTutorMessageError] = useState('');

    const [nomeTutor, setNomeTutor] = useState('');
    const [cognomeTutor, setCognomeTutor] = useState('');
    const [nomeTutorValid, setNomeTutorValid] = useState(true);
    const [cognomeTutorValid, setCognomeTutorValid] = useState(true);
    const [dataNascitaTutor, setDataNascitaTutor] = useState(null);
    const [luogoNascitaTutor, setLuogoNascitaTutor] = useState('');
    const [luogoNascitaTutorValid, setLuogoNascitaTutorValid] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [idRuolo, setIdRuolo] = useState(1);
    const [telefonoTutor, setTelefonoTutor] = useState('');
    const [mailTutor, setMailTutor] = useState('');
    const [tipologiaContrattoTutor, setTipologiaContrattoTutor] = useState('');
    const [scadenzaContrattoTutor, setScadenzaContrattoTutor] = useState("");
    const [livelloInquadramentoTutor, setLivelloInquadramentoTutor] = useState('');
    const [anniExpTutor, setAnniExpTutor] = useState('')

    const [dialogAggiungiTutorOpen, setDialogAggiungiTutorOpen] = useState(false);

    const [confirmAlertInsertIsOpen, setConfirmAlertInsertIsOpen] = useState(false);
    const [confirmAlertEditIsOpen, setConfirmAlertEditIsOpen] = useState(false);
    const [confirmAlertDeleteIsOpen, setConfirmAlertDeleteIsOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [canBeModified, setCanBeModified] = useState(true);

    const [currentDate, setCurrentDate] = useState(true);

    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [dataExist, setDataExist] = useState(false);

    useEffect(() => {
        if (props.dataExist !== undefined)
            setDataExist(props.dataExist);
    }, [props.dataExist])


    useEffect(() => {
        if (props.isCanEdit !== undefined)
            setIsCanEdit(props.isCanEdit)
    }, [props.isCanEdit])

    useEffect(() => {
        if (hasLoaded === true) setHasLoaded(false);
        let currentDateTmp = DateTime.now();
        setCurrentDate(currentDateTmp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.idAzienda !== undefined) {
            setIdAzienda(props.idAzienda);
            ricercaPersoneAzienda(props.idAzienda)
        }
    }, [props.idAzienda])

    function previousTab() {
        if (props.previousTab) props.previousTab();
    }


    function nextTab() {
        if (props.previousTab) props.nextTab();
    }

    function closeDialogAggiungiAzienda() {
        if (props.closeDialog) props.closeDialog()
    }



    async function ricercaPersoneAzienda(idAzienda) {
        let obj = {
            idAzienda: idAzienda,
            idRuolo: 1
        }
        TirociniAPI.ricercaPersoneAzienda(obj)
            .then((result) => {
                let ListTutorTmp = result;
                setListTutor([...ListTutorTmp])

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })

    }

    async function savePersoneAzienda() {
        setButtonConfermaDisabled(true)
        let obj = {
            id: idPersona !== -1 ? idPersona : null, // per inserimento null , per modifica va inserito l'idPersona
            CF: CFTutor,
            nome: nomeTutor,
            cognome: cognomeTutor,
            dataNascita: dataNascitaTutor,
            luogoNascita: luogoNascitaTutor,
            idAzienda: idAzienda,
            idRuolo: idRuolo, // 1 per il tutor
            telefono: telefonoTutor !== '' ? telefonoTutor : null,
            mail: mailTutor !== '' ? mailTutor : null,
            tipologiaContratto: tipologiaContrattoTutor !== '' ? tipologiaContrattoTutor : null,
            scadenzaContratto: scadenzaContrattoTutor !== '' && scadenzaContrattoTutor !== null ? scadenzaContrattoTutor : null,
            livelloInquadramento: livelloInquadramentoTutor !== '' ? livelloInquadramentoTutor : null,
            anniExp: anniExpTutor !== '' ? parseInt(anniExpTutor) : null
        }
        TirociniAPI.savePersoneAzienda(obj)
            .then((result) => {
                openAlertMsg(result?.msg ?? "Salvataggio effettuato con successo", 'success')
                ricercaPersoneAzienda(idAzienda);
                resetDataTutor();
                closeAlertConfirmInserimento();
                closeAlertConfirmEdit();
                closeDialogAggiungiTutor();
                setButtonConfermaDisabled(false)
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error")
                }
                closeAlertConfirmInserimento();
                setButtonConfermaDisabled(false)
            })

    }

    async function deletePersoneAzienda() {
        setButtonConfermaDisabled(true)
        let obj = {
            idPersona: idPersona
        }
        TirociniAPI.deletePersoneAzienda(obj)
            .then((result) => {
                openAlertMsg(result?.msg ?? "Tutor eliminato con successo", 'success');
                ricercaPersoneAzienda(idAzienda);
                closeAlertConfirmDelete();
                setButtonConfermaDisabled(false)
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'eliminazione del tutor. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante l'eliminazione del tutor. Riprovare tra qualche istante.", "error")
                }
                setButtonConfermaDisabled(false)
            })

    }

    function changeCognomeTutor(e) {
        let valueTmp = e.target.value;
        setCognomeTutor(valueTmp)
        if (valueTmp !== '') {
            setCognomeTutorValid(true)
        } else {
            setCognomeTutorValid(false)
        }
    }

    function changeNomeTutor(e) {
        let valueTmp = e.target.value;
        setNomeTutor(valueTmp)
        if (valueTmp !== '') {
            setNomeTutorValid(true)
        } else {
            setNomeTutorValid(false)
        }
    }
    function changeCFTutor(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCFTutor(valueNoExtraCharactersTmp);

        // if (valueNoExtraCharactersTmp === '') {
        //     setCFTutorValid(true);
        //     setCFTutorMessageError('')
        // } else {
        if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
            setCFTutorValid(true);
            setCFTutorMessageError('')
        } else {
            setCFTutorValid(false);
            setCFTutorMessageError('Il C.F si compone di 11 o 16 caratteri')
        }
        // }
    }
    function onChangeValueDataNascitaTutor(e) {
        let formattedDataTmp = convertDataGetProssimeLezione(e, true);
        setDataNascitaTutor(formattedDataTmp)
    }
    function onChangeValueScadenzaContrattoTutor(e) {
        setScadenzaContrattoTutor(e.target.value)
    }
    function changeLuogoNascitaTutor(e) {
        let valueTmp = e.target.value;
        setLuogoNascitaTutor(valueTmp)
        if (valueTmp !== '') {
            setLuogoNascitaTutorValid(true)
        } else {
            setLuogoNascitaTutorValid(false)
        }
    }
    function changeTelefonoTutor(e) {
        setTelefonoTutor(e.target.value)
    }
    function changeMailTutor(e) {
        setMailTutor(e.target.value)
    }
    function changeTipologiaContrattoTutor(e) {
        setTipologiaContrattoTutor(e.target.value)
    }
    function changeLivelloInquadramentoTutor(e) {
        setLivelloInquadramentoTutor(e.target.value)
    }

    function changeAnniExpTutor(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^0-9]/g, ''); //Ammette solo numeri
        valueNoExtraCharactersTmp = valueNoExtraCharactersTmp.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1

        setAnniExpTutor(valueNoExtraCharactersTmp);
    }



    function closeAlertConfirmInserimento() {
        setConfirmAlertInsertIsOpen(false)
        resetDataTutor()
    }

    function closeAlertConfirmDelete() {
        setConfirmAlertDeleteIsOpen(false)
        resetDataTutor()
    }

    function closeAlertConfirmEdit() {
        setConfirmAlertEditIsOpen(false)
    }

    function validateFormData() {
        if (
            cognomeTutor !== ''
            &&
            nomeTutor !== ''
            &&
            CFTutorValid
            // (CFTutor !== '' && CFTutorValid)
            // &&
            // dataNascitaTutor !== null
            // &&
            // dataNascitaTutor !== ''
            // &&
            // luogoNascitaTutor !== ''
        ) return false
        else return true
    }

    function openDialogAggiungiTutor() {
        setDialogAggiungiTutorOpen(prev => !prev);
    }

    function closeDialogAggiungiTutor() {
        setDialogAggiungiTutorOpen(false)
        setTimeout(() => {
            resetDataTutor()
        }, 200) //Cosi non abbiamo glitch grafici quando chiudiamo la dialog
    }

    function resetDataTutor() {
        setIdPersona(-1)
        setCFTutor('');
        setNomeTutor('');
        setCognomeTutor('');
        setDataNascitaTutor(null);
        setLuogoNascitaTutor('');
        setTelefonoTutor('');
        setMailTutor('');
        setTipologiaContrattoTutor('');
        setScadenzaContrattoTutor("");
        setLivelloInquadramentoTutor('');
        setCanBeModified(true);
        setAnniExpTutor('');
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function visualizzaItem(tutor) {
        let tutorTmp = tutor;
        setIdPersona(tutorTmp?.id ?? null)
        setCFTutor(tutorTmp?.CF ?? '');
        setNomeTutor(tutorTmp?.nome ?? '');
        setCognomeTutor(tutorTmp?.cognome ?? '');
        setDataNascitaTutor(tutorTmp?.dataNascita ?? null);
        setLuogoNascitaTutor(tutorTmp?.luogoNascita ?? '');
        setTelefonoTutor(tutorTmp?.telefono ?? '');
        setMailTutor(tutorTmp?.mail ?? '');
        setTipologiaContrattoTutor(tutorTmp?.tipologiaContratto ?? '');
        setScadenzaContrattoTutor(tutorTmp?.scadenzaContratto ?? "");
        setLivelloInquadramentoTutor(tutorTmp?.livelloInquadramento ?? '');
        setAnniExpTutor(tutorTmp?.anniExp ?? '');
        setCanBeModified(tutorTmp?.canBeModified ?? true);
        openDialogAggiungiTutor()
    }

    function openDialogConfermaDelete(idPersona) {
        setIdPersona(idPersona);
        setConfirmAlertDeleteIsOpen(true);
    }

    return (
        <Fragment>
            <Box sx={{ px: 3, mt: 2 }}>
                {isCanEdit && (<Box sx={{ p: 1 }}>
                    <Button startIcon={<Add />} fullWidth variant="contained" onClick={() => setDialogAggiungiTutorOpen(prev => !prev)} color="success">Aggiungi nuovo tutor</Button>
                </Box>)}
                <Box sx={{ p: 1, mt: 2, display: 'flex', flexDirection: 'column', maxHeight: '50vh' }}>
                    {hasLoaded ? (
                        listTutor.length > 0 ?
                            (
                                <TableSediPersone list={listTutor} soggetto={'tutor'} deleteItem={openDialogConfermaDelete} visualizzaItem={visualizzaItem} isCanEdit={isCanEdit} />
                            )
                            :
                            (
                                <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* <Alert sx={{ mb: 2 }} severity="info">Per proseguire è necessario associare almeno un tutor a questa azienda.</Alert> */}
                                    <Alert sx={{ mb: 2 }} severity="info">Non è presente un Tutor Aziendale per l'azienda richiesta.</Alert>
                                </Box>
                            )

                    ) : (
                        <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <CircularProgress sx={{ mb: 4 }} />
                            <Typography >Caricamento in corso...</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: 'unset' }, mt: 2, mr: { xs: 0, md: 4 } }}>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 0 } }} variant="outlined" fullWidth color={'error'} onClick={closeDialogAggiungiAzienda}>{isCanEdit ? (dataExist ? 'Chiudi procedura di modifica' : 'Chiudi procedura di inserimento') : 'Chiudi'}</Button>
                <Button startIcon={<ArrowBack />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }} variant="contained" fullWidth color={'secondary'} onClick={previousTab} >Torna indietro</Button>
                <Button endIcon={<ArrowForward />}
                    // disabled={listTutor.length === 0}
                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                    variant="contained"
                    fullWidth color={'success'}
                    onClick={nextTab} >
                    {'Prosegui'}
                </Button>
            </Box>
            <Dialog fullWidth maxWidth={'md'} open={dialogAggiungiTutorOpen} sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>{idPersona !== -1 ? (isCanEdit ? 'Modifica tutor' : 'Dettaglio tutor') : 'Aggiungi tutor'}</Typography>
                    <IconButton onClick={closeDialogAggiungiTutor}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid container spacing={2} mt={2}>
                        <Grid xs={12} md={6}>
                            <TextField
                                error={nomeTutor === '' && !nomeTutorValid}
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={nomeTutor ?? ''}
                                value={nomeTutor ?? ''}
                                required
                                label={"Nome"}
                                onChange={changeNomeTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                error={cognomeTutor === '' && !cognomeTutorValid}
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={cognomeTutor ?? ''}
                                value={cognomeTutor ?? ''}
                                required
                                label={'Cognome'}
                                onChange={changeCognomeTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                // error={!CFTutorValid}
                                defaultValue={CFTutor ?? ''}
                                value={CFTutor ?? ''}
                                // required
                                label={"C.F."}
                                onChange={changeCFTutor}
                                sx={{ width: '100%' }}
                                helperText={CFTutorMessageError}
                                inputProps={{ maxLength: 16 }}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <CustomDatePicker
                                isDisabled={!canBeModified || !isCanEdit}
                                label={"Data di nascita"}
                                year={true}
                                selectedValue={dataNascitaTutor ?? null}
                                onChangeValue={onChangeValueDataNascitaTutor}
                                maxDate={currentDate}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                error={!luogoNascitaTutorValid}
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={luogoNascitaTutor ?? ''}
                                value={luogoNascitaTutor ?? ''}
                                // required
                                label={"Luogo di nascita"}
                                onChange={changeLuogoNascitaTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={telefonoTutor ?? ''}
                                value={telefonoTutor ?? ''}
                                label={"Telefono"}
                                onChange={changeTelefonoTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={mailTutor ?? ''}
                                value={mailTutor ?? ''}
                                label={"E-mail"}
                                onChange={changeMailTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={tipologiaContrattoTutor ?? ''}
                                value={tipologiaContrattoTutor ?? ''}
                                label={"Tipologia contratto"}
                                onChange={changeTipologiaContrattoTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                label={"Data di scadenza del contratto"}
                                disabled={!canBeModified || !isCanEdit}
                                value={scadenzaContrattoTutor ?? null}
                                onChange={onChangeValueScadenzaContrattoTutor}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={livelloInquadramentoTutor ?? ''}
                                value={livelloInquadramentoTutor ?? ''}
                                label={"Livello di inquadramento"}
                                onChange={changeLivelloInquadramentoTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={anniExpTutor ?? ''}
                                value={anniExpTutor ?? ''}
                                label={"Anni di esperienza"}
                                onChange={changeAnniExpTutor}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                        {isCanEdit &&
                            (
                                <Button variant="contained"
                                    color={idPersona !== -1 ? 'warning' : 'success'}
                                    disabled={validateFormData()}
                                    onClick={() => idPersona !== -1 ? setConfirmAlertEditIsOpen(true) : setConfirmAlertInsertIsOpen(true)}>
                                    {idPersona !== -1 ? 'Modifica tutor' : ' Conferma e aggiungi tutor'}
                                </Button>
                            )}
                        <Button sx={{ mt: 2 }} variant="outlined" fullWidth color={'error'} onClick={closeDialogAggiungiTutor}>Annulla e chiudi</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <DialogConferma
                isDialogOpen={confirmAlertInsertIsOpen}
                title={'Conferma aggiunta tutor'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere il tutor? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={savePersoneAzienda}
                disabledButton={buttonConfermaDisabled}
            />
            <DialogConferma
                isDialogOpen={confirmAlertEditIsOpen}
                title={'Conferma modifica tutor'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare i dati il tutor? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={closeAlertConfirmEdit}
                conferma={savePersoneAzienda}
                disabledButton={buttonConfermaDisabled}
            />
            <DialogConferma
                isDialogOpen={confirmAlertDeleteIsOpen}
                title={'Conferma eliminazione tutor'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler procedere con l'eliminazione dei dati del tutor? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={closeAlertConfirmDelete}
                conferma={deletePersoneAzienda}
                disabledButton={buttonConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}