import { useState, useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, Switch } from '@mui/material';

export default function TableCondizioniTirocinante(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);
    const [isCanEdit, setIsCanEdit] = useState(true);

    useEffect(() => {
        if (props.list) {
            setList([...props.list]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100);
        }
    }, [props.list])

    useEffect(() => {
        if (props.isCanEdit !== undefined) {
            setIsCanEdit(props.isCanEdit);
        }
    }, [props.isCanEdit])

    function changeList(e, index) {

        let listTmp = [...list];
        listTmp[index].stato = !listTmp[index].stato;
        setList(listTmp);
        if (props.changeList) props.changeList(listTmp)
    }

    return (
        <Paper>
            <TableContainer sx={{ width: '100%' }} >
                <Table sx={{ maxHeight: '40vh' }}>
                    <TableHead>
                        <TableRow >

                            <TableCell align="center" colSpan={2}
                                className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    // px: 1,
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Attuale condizione</b></Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow style={{ height: '40px' }} key={item?.id}>

                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Switch
                                                disabled={!isCanEdit}
                                                checked={item?.stato} onChange={(e) => { changeList(e, index) }} label="" />
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ ml: 2 }}>
                                                {(item?.descrizione ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}