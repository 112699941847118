import { Alert, Snackbar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography, IconButton, DialogActions, Chip } from "@mui/material"
import { Fragment, useState } from "react"
import Breadcrumb from "../../registri/shared/breadcrumb"
import ElencoCorsiFormatore from "../elencoCorsiFormatore"
import { useNavigate } from "react-router-dom";
import { convertDataDiNascita, convertDataGetProssimeLezione, convertToItalianTimeFormatted } from "../../shared/metodiDateUtility";
import { TirociniAPI } from "../../../api/tirociniApi";
import { ArrowBack, Edit, EditCalendar } from "@mui/icons-material";
import RangeDate from "../../shared/rangeDate";
import { convertISODate } from "../../shared/metodiDateUtility";
import { Help, Close } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import LegendaPresenzeTutorTirocinio from "./legendaPresenzeTutorTirocinio";
import DettaglioAlunno from "../../dettaglio-utente/dettaglioAlunno";
import { DateTime } from "luxon";
import TablePresenzeTutorTirocinio from "./tablePresenzeTutorTirocinio";
import FirmaVisitaTutor from "./firmaVisitaTutor";

export default function PresenzeTutorTirocinio() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const [dialogSelezionaCorsoOpen, setDialogSelezionaCorsoOpen] = useState(true)
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1)
    const [nomeCorso, setNomeCorso] = useState("")
    const [sedeUtente, setSedeUtente] = useState("");

    const [dataDiInteresseInizio, setDataDiInteresseInizio] = useState(null);
    const [dataDiInteresseFine, setDataDiInteresseFine] = useState(null);
    const [formattedDataDiInteresseInizio, setFormattedDataDiInteresseInizio] = useState("");
    const [formattedDataDiInteresseFine, setFormattedDataDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false)

    const [listVisite, setListVisite] = useState([]);

    const [totaleOre, setTotaleOre] = useState({});
    const [canEditFirme, setCanEditFirme] = useState(false);

    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [dialogFirmaOraVisitaOpen, setDialogFirmaOraVisitaOpen] = useState(false);
    const [dataVisita, setDataVisita] = useState(null);
    const [idVisitaSelezionata, setIdVisitaSelezionata] = useState(null);


    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [isDettaglioAlunnoOpen, setIsDettaglioAlunnoOpen] = useState(false);
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [alunnoSelezionatoId, setAlunnoSelezionatoId] = useState(-1);
    const [indexTabDettaglioAlunno, setIndexTabDettaglioAlunno] = useState(0);

    const [oraInizio, setOraInizio] = useState(null);
    const [oraFine, setOraFine] = useState(null);

    const [isModificaFirma, setIsModificaFirma] = useState(false);

    function goToHome() {
        navigate("/")
    }

    function openChangeCorso() {
        setDialogSelezionaCorsoOpen(true);
        setHasLoaded(false);
    }

    function changeIdClasseSelezionata(idCorso, nomeCorso, codiceCorso, codiceSimon, nomeSede, dataDiInteresse) {

        setDialogSelezionaCorsoOpen(false);

        setIdCorsoSelezionato(idCorso);
        setNomeCorso(codiceCorso + " - " + nomeCorso + " - " + nomeSede);

        setSedeUtente(nomeSede)

        let dataDiInteresseInizioConverted = convertDataGetProssimeLezione(dataDiInteresse, true);
        setDataDiInteresseInizio(dataDiInteresseInizioConverted);
        let dataDiInteresseInizioFormatted = convertDataDiNascita(dataDiInteresse);
        setFormattedDataDiInteresseInizio(dataDiInteresseInizioFormatted);

        let dataDiInteresseFineTmp = dataDiInteresse.endOf('week');
        let dataDiInteresseFineConverted = convertDataGetProssimeLezione(dataDiInteresseFineTmp, true);
        setDataDiInteresseFine(dataDiInteresseFineConverted);
        let dataDiInteresseFineFormatted = convertDataDiNascita(dataDiInteresseFineTmp);
        setFormattedDataDiInteresseFine(dataDiInteresseFineFormatted);

        getElencoFirmeTutorTirocinio(idCorso, dataDiInteresseInizioConverted, dataDiInteresseFineConverted);
    }

    async function getElencoFirmeTutorTirocinio(idCorso, dataInizio, dataFine) {
        let dataObj = {
            idCorso: idCorso,
            dataDa: dataInizio,
            dataA: dataFine
        }
        TirociniAPI.elencoFirmeTutorTirocinio(dataObj)
            .then((result) => {

                setHasLoaded(true);
                if (isError) setIsError(false);
                setListVisite([...result?.visite]);
                setTotaleOre({ ...result?.ore });
                setCanEditFirme(result?.canEditFirme);
            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    setIsError(true);
                }
            })
    }

    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }

    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let today = DateTime.local();
        let dataInizioTmp = dataInizio ?? today.toISO();
        let dataFineTmp = dataFine ?? today.toISO();

        setDataDiInteresseInizio(dataInizioTmp);
        setDataDiInteresseFine(dataFineTmp);

        setFormattedDataDiInteresseInizio(convertISODate(dataInizio));
        setFormattedDataDiInteresseFine(convertISODate(dataFine));

        setChangePeriodoInteresseOpen(false)

        getElencoFirmeTutorTirocinio(idCorsoSelezionato, dataInizio, dataFine);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }

    function openDialogFirmaOraVisita(visita) {
        let visitaTmp = visita;
        if (visita !== null) {
            setIdVisitaSelezionata(visitaTmp.idVisita);
            setDataVisita(visitaTmp.data);
            setOraInizio(visitaTmp.orarioInizio);
            setOraFine(visitaTmp.orarioFine);
            setAlunnoSelezionatoId(visitaTmp.alunno.id);
            setIsModificaFirma(true)
        } else setIsModificaFirma(false)

        setDialogFirmaOraVisitaOpen(true)
    }

    function closeDialogFirmaOraVisita() {

        setIdVisitaSelezionata(null);
        setDataVisita(null);
        setOraInizio(null);
        setOraFine(null);
        setAlunnoSelezionatoId(-1);

        setDialogFirmaOraVisitaOpen(false)
        getElencoFirmeTutorTirocinio(idCorsoSelezionato, dataDiInteresseInizio, dataDiInteresseFine);
    }



    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function openDettaglioAlunno(alunno, showBesTmp) {
        let alunnoTmp = null;
        if (alunno !== undefined) {
            alunnoTmp = {
                id: alunno?.id,
                nome: alunno?.nome,
                cognome: alunno?.cognome
            }
        }
        if (showBesTmp !== undefined && showBesTmp === true) setIndexTabDettaglioAlunno(4);
        else setIndexTabDettaglioAlunno(0);
        setAlunnoSelezionato(alunnoTmp);
        setIsDettaglioAlunnoOpen(prev => !prev);
    }

    async function saveFirmaTutor(idVisita, dataVisita, oraInizio, oraFine, alunnoSelezionato, idCorso) {
        let obj = {
            "idVisita": idVisita,
            "data": dataVisita,
            "oraInizioVisita": oraInizio.length > 8 ? convertToItalianTimeFormatted(oraInizio) : oraInizio,
            "oraFineVisita": oraFine.length > 8 ? convertToItalianTimeFormatted(oraFine) : oraFine,
            "idAlunno": alunnoSelezionato,
            "idCorso": idCorso
        }

        TirociniAPI.saveFirmaTutorTirocinio(obj)
            .then((result) => {
                setHasLoaded(true);
                openAlertMsg(result.msg, 'success')
                closeDialogFirmaOraVisita()
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? 'Spiacenti, qualcosa è andato storto'
                    openAlertMsg(errorMsg, 'error')
                } else {
                    openAlertMsg('Spiacenti, qualcosa è andato storto', 'error')
                }
            })
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Breadcrumb sections={["Presenze tutor di tirocinio", nomeCorso]} isTirocinio={true} />
                    <Box sx={{ px: 5, minHeight: '80vh' }}>
                        <Fragment>
                            <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: 'start', md: 'space-between' }}>
                                <Box display={"flex"} flexDirection={{ xs: 'column', lg: 'row' }} width={{ xs: '100%', lg: 'unset' }} mb={2}>
                                    <Button onClick={openChangeCorso} sx={{ width: { xs: '100%', lg: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                        Torna alla selezione del corso
                                    </Button>
                                    <Button sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                        Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                    </Button>
                                    <Button startIcon={<Help />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                        Mostra legenda
                                    </Button>
                                    <Button startIcon={<Edit />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="contained" color="success" onClick={() => openDialogFirmaOraVisita(null)}>
                                        Firma ora in azienda
                                    </Button>
                                </Box>
                            </Box>
                            {listVisite.length > 0 ? (
                                <Fragment>
                                    <Box display={"flex"} flexDirection={'row'} width={{ xs: '100%', lg: 'unset' }} justifyContent={'space-between'} alignItems={{ xs: "start", md: 'center' }}>
                                        <Box mr={2}>
                                            <Typography variant={"body2"}>Corso:</Typography>
                                            <Typography color={'primary'}>{(nomeCorso ?? "").toUpperCase()}</Typography>
                                        </Box>
                                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={'center'}>
                                            <Chip color="primary" sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 0.5, md: 0 }, width: '100%' }} variant="outlined" label={"Ore firmate: " + totaleOre?.oreFirmate} />
                                            <Chip color="success" sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 0.5, md: 0 }, width: '100%' }} variant="outlined" label={"Ore firmate (validate): " + totaleOre?.oreValidate} />
                                            <Chip color="secondary" sx={{ width: '100%' }} variant="outlined" label={"Ore previste: " + totaleOre?.orePreviste} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ border: '1px solid #eaeaea', borderRadius: '15px', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center', overflow: 'scroll' }}>
                                        <TablePresenzeTutorTirocinio idCorso={idCorsoSelezionato} nomeCorso={nomeCorso} listVisite={listVisite} openDialogFirmaOraVisita={openDialogFirmaOraVisita} canEditFirme={canEditFirme} />
                                    </Box>
                                </Fragment>
                            ) : (
                                <Box height={"80vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                    <Alert severity="info">Nessuna visita di tirocinio nel periodo selezionato ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})</Alert>
                                    {/* <Button startIcon={<ArrowBack />} onClick={openChangeCorso} variant="contained" sx={{ mt: 2 }}>  Torna alla selezione del corso</Button> */}
                                    {/* <Button sx={{ mt: 1, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                        Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                    </Button> */}
                                </Box>
                            )}
                        </Fragment>

                    </Box>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                </Box>
            )}

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaCorsoOpen} sx={{ mt: 8 }}>
                <DialogTitle variant="h6"><b>Seleziona corso</b></DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoCorsiFormatore showDateRange={true}
                        sedeUtente={sedeUtente} goToHome={goToHome} changeCorsoSelezionato={changeIdClasseSelezionata} />
                </DialogContent>
            </Dialog>
            <Dialog open={changePeriodoDiInteresseOpen} >
                <DialogTitle variant="h6">
                    Seleziona il periodo di interesse
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RangeDate idClasse={idCorsoSelezionato} dataDiInteresseInizio={dataDiInteresseInizio} dataDiInteresseFine={dataDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaCorsoOpen} sx={{ mt: 8 }}>
                <DialogTitle variant="h6"><b>Seleziona corso</b></DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoCorsiFormatore showDateRange={true}
                        sedeUtente={sedeUtente} goToHome={goToHome} changeCorsoSelezionato={changeIdClasseSelezionata} />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogFirmaOraVisitaOpen} >
                <DialogTitle display={"flex"} alignItems={"center"} variant="h6" justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Firma ora in azienda
                    </Typography>
                    <IconButton onClick={closeDialogFirmaOraVisita} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 0 }}>
                    <FirmaVisitaTutor canEditTime={true} canEditDate={true} alunnoSelezionato={alunnoSelezionatoId} dataVisita={dataVisita} idCorso={idCorsoSelezionato} idVisita={idVisitaSelezionata}
                        closeDialogFirmaOraVisita={closeDialogFirmaOraVisita} saveFirmaTutor={saveFirmaTutor} oraFine={oraFine} oraInizio={oraInizio}
                        isModificaFirma={isModificaFirma}
                    />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="md" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaPresenzeTutorTirocinio />
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioAlunnoOpen} onClose={openDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome})</Typography>
                    <IconButton onClick={openDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno showBes={false} tabIndex={indexTabDettaglioAlunno} id={alunnoSelezionato?.id} nomeAlunno={alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome} idCorso={idCorsoSelezionato} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Presenze tutor di tirocinio</title>
            </Helmet>
        </Fragment>
    )
}