import { useState, useEffect } from "react";

export default function TimerBes({startTimer, stopViewBes}) {
   
   
    const [timeLeft, setTimeLeft] = useState(5 * 60);
    const minutes = Math.floor(timeLeft / 60);
    const seconds = String(timeLeft % 60).padStart(2, '0');


    useEffect(() => {
        if (startTimer && timeLeft > 0) {
            setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        }
        else if (timeLeft <= 0) {
            stopViewBes();
            setTimeLeft(5*60)
        }
    }, [startTimer, timeLeft, stopViewBes]);

    return (
        startTimer === true ? (
            <span> &nbsp; <b>{minutes}:{seconds}</b> minuti </span>
        ) : null
    );
}