import * as React from 'react';
import { Fragment, useState, useEffect, useRef, createRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, Paper, Button, FormControl, TextField, Box, Collapse, Container, Select, Chip, Typography, Dialog, DialogContent, DialogTitle, DialogContentText, IconButton, Breadcrumbs, useTheme, Divider } from '@mui/material';
import { Download, Warning, Search, Help, ArrowBack, CalendarToday, Description, Close, Edit } from "@mui/icons-material";
import { DateTime } from "luxon";
import useMediaQuery from '@mui/material/useMediaQuery';

import { ColloquiAPI } from '../../../api/colloquiApi';

export default function DettaglioStudenteAnnotazioni(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [studente, setStudente] = useState({});
    const [studenteSupporto, setStudenteSupporto] = useState({});
    const [docentiList, setDocentiList] = useState([]);
    const [tutorList, setTutorList] = useState([]);
    const [direttore, setDirettore] = useState({});
    const [coordinatore, setCoordinatore] = useState({});

    useEffect(() => {
        if (props.studente !== undefined)
            setStudente(props.studente);
        textFormatterAnnotazioniStudente(props.studente)
    }, [props.studente])

    useEffect(() => {
        if (props.docentiList !== undefined)
            setDocentiList(props.docentiList);
    }, [props.docentiList])

    useEffect(() => {
        if (props.tutorList !== undefined)
            setTutorList(props.tutorList);
    }, [props.tutorList])

    useEffect(() => {
        if (props.direttore !== undefined)
            setDirettore(props.direttore);
    }, [props.direttore])

    useEffect(() => {
        if (props.coordinatore !== undefined)
            setCoordinatore(props.coordinatore);
    }, [props.coordinatore])

    // function finderDocenti(array, id) {
    //     for (let i = 0; i < array.length; i++) {
    //         if (array[i].idDocente === id) {
    //             return array[i].cognomeDocente + ' ' + array[i].nomeDocente;
    //         }
    //     }
    //     return null;
    // }

    function textFormatterAnnotazioniStudente(studente) {
        let studenteFormatted = JSON.parse(JSON.stringify(studente));

        if (studenteFormatted?.annotazioneCoordinatore !== null && studenteFormatted?.annotazioneCoordinatore !== undefined) {
            studenteFormatted.annotazioneCoordinatore.annotazioneFormatted = ''
            let annotazioneCoordinatoreFormatted = studenteFormatted?.annotazioneCoordinatore?.annotazione.replace(/\n/g, "<br />")
            studenteFormatted.annotazioneCoordinatore.annotazioneFormatted = annotazioneCoordinatoreFormatted
        }

        if (studenteFormatted?.annotazioneDirettore !== null && studenteFormatted?.annotazioneDirettore !== undefined) {
            studenteFormatted.annotazioneDirettore.annotazioneFormatted = ''
            let annotazioneDirettoreFormatted = studenteFormatted?.annotazioneDirettore?.annotazione.replace(/\n/g, "<br />")
            studenteFormatted.annotazioneDirettore.annotazioneFormatted = annotazioneDirettoreFormatted
        }

        if (studenteFormatted?.annotazioneTutor !== undefined) {
            if (studenteFormatted?.annotazioneTutor?.length > 0) {
                for (let a of studenteFormatted.annotazioneTutor) {
                    a.annotazioneFormatted = ''
                    let annotazioneTutorFormatted = a.annotazione.replace(/\n/g, "<br />")
                    a.annotazioneFormatted = annotazioneTutorFormatted
                }
            }
        }

        if (studenteFormatted?.annotazioniDocenti !== undefined) {
            if (studenteFormatted?.annotazioniDocenti?.length > 0) {
                for (let a of studenteFormatted.annotazioniDocenti) {
                    a.annotazioneFormatted = ''
                    let annotazioneDocenteFormatted = a.annotazione.replace(/\n/g, "<br />")
                    a.annotazioneFormatted = annotazioneDocenteFormatted
                }
            }
        }

        setStudenteSupporto(studenteFormatted)
    }

    function finderDocenti(array, id) {
        let filteredArray = array.filter(item => item.idDocente === id);
        let materieTmp = '';
        for (let i = 0; i < filteredArray[0].materie.length; i++) {
            materieTmp += i === 0 ? filteredArray[0].materie[i].nomeBreveMateria : ', ' + filteredArray[0].materie[i].nomeBreveMateria
        }
        return filteredArray.length > 0 ? filteredArray[0].nomeDocente + ' ' + filteredArray[0].cognomeDocente + ' ( ' + materieTmp + ' )' : null;
    }

    function finderTutor(array, id) {
        let filteredArray = array.filter(item => item.idDocente === id);
        return filteredArray.length > 0 ? filteredArray[0].nome + ' ' + filteredArray[0].cognome : null;
    }

    return (
        <Fragment>
            <Typography variant="h5" mt={2}>ELENCO ANNOTAZIONI - <b>{(studente?.cognome ?? '').toUpperCase() + " " + (studente?.nome ?? '').toUpperCase()}</b></Typography>
            <Box sx={{ height: mobile ? '60vh' : '70vh', overflow: 'scroll', px: 2, pb: 2, border: '1px solid rgba(224, 224, 224, 1)', mt: 2 }}>
                {(studente?.annotazioniDocenti !== undefined && studenteSupporto?.annotazioniDocenti !== undefined) ? (
                    <Box>
                        {studenteSupporto?.annotazioniDocenti.length > 0 && (
                            <TableContainer sx={{ width: '100%', mt: 2 }} component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#1976d3" }}
                                            ><Box
                                                sx={{
                                                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                    color: 'white',
                                                    zIndex: 10,
                                                    px: 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "start",
                                                }}
                                            >
                                                    <Typography variant="h7">Docente (materia/e)</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#1976d3", }}
                                            ><Box
                                                sx={{
                                                    color: 'white',
                                                    zIndex: 10,
                                                    px: 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "start",
                                                }}
                                            >
                                                    <Typography variant="h7">Annotazione</Typography>
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studenteSupporto?.annotazioniDocenti.map((annotazione, index) => <TableRow>
                                            <TableCell
                                                align="center"
                                                sx={{ zIndex: 7, p: 0 }}
                                                key={annotazione?.idAnnotazione}
                                            >
                                                <Box
                                                    sx={{
                                                        borderTop: "1px solid white",
                                                        backgroundColor: "white",
                                                        zIndex: 10,
                                                        py: 1,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                    <Typography variant="h8">
                                                        {finderDocenti(docentiList, annotazione?.idDocente)}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{ zIndex: 7, p: 0 }}
                                            >
                                                <Box
                                                    sx={{
                                                        borderTop: "1px solid white",
                                                        borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                                        backgroundColor: "white",
                                                        zIndex: 10,
                                                        py: 1,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                        wordBreak: 'break-all'
                                                    }}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: (annotazione?.annotazioneFormatted ?? '') }} style={{ textAlign: 'start' }} />
                                                    {/* <TextField value={annotazione.annotazione} disabled multiline variant="standard" sx={{ borderColor: 'transparent', color: 'black' }}></TextField> */}
                                                    {/* <Typography variant="h8">{annotazione.annotazione}</Typography> */}
                                                </Box>
                                            </TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        )}
                    </Box>
                ) : (
                    null
                )}
                {(studente?.annotazioneTutor !== undefined && studenteSupporto?.annotazioneTutor !== undefined) ? (
                    <Box>
                        {studenteSupporto?.annotazioneTutor.length > 0 && (
                            <Fragment>
                                <Divider sx={{ my: 2 }}></Divider>
                                <TableContainer sx={{ width: '100%', mt: 2 }} component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#AA21AD", }}
                                                ><Box
                                                    sx={{
                                                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                        color: 'white',
                                                        zIndex: 10,

                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                        <Typography variant="h7">Tutor</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#AA21AD", }}
                                                ><Box
                                                    sx={{
                                                        color: 'white',
                                                        zIndex: 10,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                        <Typography variant="h7">Annotazione</Typography>
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {studenteSupporto?.annotazioneTutor.map((annotazione, index) => <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0 }}
                                                    key={annotazione?.idAnnotazione}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderTop: "1px solid white",
                                                            backgroundColor: "white",
                                                            zIndex: 10,
                                                            py: 1,
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "start",
                                                        }}
                                                    >
                                                        <Typography variant="h8">{finderTutor(tutorList, annotazione?.idDocente)}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderTop: "1px solid white",
                                                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                                            backgroundColor: "white",
                                                            zIndex: 10,
                                                            py: 1,
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "start",
                                                            wordBreak: 'break-all'
                                                        }}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: (annotazione?.annotazioneFormatted ?? '') }} style={{ textAlign: 'start' }} />
                                                        {/* <TextField value={annotazione.annotazione} disabled multiline variant="standard" sx={{ borderColor: 'transparent', color: 'black' }}></TextField> */}
                                                        {/* <Typography variant="h8">{annotazione.annotazione}</Typography> */}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        )}
                    </Box>
                ) : (
                    null
                )}


                {(studente?.annotazioneCoordinatore !== undefined && studenteSupporto?.annotazioneCoordinatore !== undefined) ? (
                    <Box>
                        {studenteSupporto?.annotazioneCoordinatore !== null && (
                            <Fragment>
                                <Divider sx={{ my: 2 }}></Divider>
                                <TableContainer sx={{ width: '100%', mt: 2 }} component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#AA21AD", }}
                                                ><Box
                                                    sx={{
                                                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                        color: 'white',
                                                        zIndex: 10,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                        <Typography variant="h7">Coordinatore</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#AA21AD" }}
                                                ><Box
                                                    sx={{
                                                        color: 'white',
                                                        zIndex: 10,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                        <Typography variant="h7">Annotazione</Typography>
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderTop: "1px solid white",
                                                            backgroundColor: "white",
                                                            zIndex: 10,
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "start",
                                                        }}
                                                    >
                                                        <Typography variant="h8">{coordinatore?.nome}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderTop: "1px solid white",
                                                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                                            backgroundColor: "white",
                                                            zIndex: 10,
                                                            py: 1,
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "start",
                                                            wordBreak: 'break-all'
                                                        }}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: (studenteSupporto?.annotazioneCoordinatore?.annotazioneFormatted ?? '') }} style={{ textAlign: 'start' }} />
                                                        {/* <TextField value={studente?.annotazioneCoordinatore?.annotazione} disabled multiline variant="standard" sx={{ borderColor: 'transparent', color: 'black' }}></TextField> */}
                                                        {/* <Typography variant="h8">{studente?.annotazioneCoordinatore?.annotazione}</Typography> */}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        )}
                    </Box>
                ) : (
                    null
                )}

                {(studente?.annotazioneDirettore !== undefined && studenteSupporto?.annotazioneDirettore !== undefined) ? (
                    <Box>
                        {studenteSupporto?.annotazioneDirettore !== null && (
                            <Fragment>
                                <Divider sx={{ my: 2 }}></Divider>
                                <TableContainer sx={{ width: '100%', mt: 2 }} component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#AA21AD", }}
                                                ><Box
                                                    sx={{
                                                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                        color: 'white',
                                                        zIndex: 10,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                        <Typography variant="h7">Direttore</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0, width: '50%', backgroundColor: "#AA21AD", }}
                                                ><Box
                                                    sx={{
                                                        color: 'white',
                                                        zIndex: 10,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                        <Typography variant="h7">Annotazione</Typography>
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderTop: "1px solid white",
                                                            backgroundColor: "white",
                                                            zIndex: 10,
                                                            px: 2,
                                                            py: 1,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "start",
                                                        }}
                                                    >
                                                        <Typography variant="h8">{direttore?.nome}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ zIndex: 7, p: 0 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderTop: "1px solid white",
                                                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                                            backgroundColor: "white",
                                                            zIndex: 10,
                                                            px: 2,
                                                            py: 1,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "start",
                                                            wordBreak: 'break-all'
                                                        }}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: (studenteSupporto?.annotazioneDirettore?.annotazioneFormatted ?? '') }} style={{ textAlign: 'start' }} />
                                                        {/* <TextField value={studente?.annotazioneDirettore?.annotazione} disabled multiline variant="standard" sx={{ borderColor: 'transparent' }}></TextField> */}
                                                        {/* <Typography variant="h8">{studente?.annotazioneDirettore?.annotazione}</Typography> */}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        )}
                    </Box>
                ) : (
                    null
                )}
                {studente?.annotazioneDirettore !== undefined && studente?.annotazioneCoordinatore !== undefined && studente?.annotazioneTutor !== undefined && studente?.annotazioniDocenti !== undefined ? (<Box>
                    {(studente?.annotazioneDirettore === null && studente?.annotazioneCoordinatore === null && studente?.annotazioneTutor.length === 0 && studente?.annotazioniDocenti.length === 0) && (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'65vh'}>
                            <Typography sx={{ fontWeight: '800' }}>Non sono presenti annotazioni.</Typography>
                        </Box>
                    )}
                </Box>) : (
                    null
                )}
            </Box>
        </Fragment >
    )
}