import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Typography, useTheme, useMediaQuery, TextField, Grid } from '@mui/material';


export default function ModificaVotiCondottaTutor(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [nomeMateria, setNomeMateria] = useState("");
    const [nomeCorso, setNomeCorso] = useState("");


    const [listAlunni, setListAlunni] = useState([]);
    const [listAlunniSupporto, setListAlunniSupporto] = useState([]);

    const [voti, setVoti] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false); //false
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [tutor, setTutor] = useState(false);

    const votiPropostiRef = useRef([])
    const votiMotEInteresseRef = useRef([])
    const votiInterazioneRef = useRef([])
    const votiRelazioneRef = useRef([])

    useEffect(() => {
        if (props.nomeMateria !== undefined) {
            setNomeMateria(props.nomeMateria)
        }
    }, [props.nomeMateria])

    useEffect(() => {
        if (props.nomeCorso !== undefined) {
            setNomeCorso(props.nomeCorso)
        }
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.tutor !== undefined) {
            setTutor(props.tutor)
        }
    }, [props.tutor])

    useEffect(() => {
        if (props.listAlunni !== undefined) {

            votiPropostiRef.current = votiPropostiRef.current.slice(0, props.listAlunni.lenght);
            votiMotEInteresseRef.current = votiMotEInteresseRef.current.slice(0, props.listAlunni.lenght);
            votiInterazioneRef.current = votiInterazioneRef.current.slice(0, props.listAlunni.lenght);
            votiRelazioneRef.current = votiRelazioneRef.current.slice(0, props.listAlunni.lenght);
            setListAlunniSupporto([...props.listAlunni]);
            setListAlunni([...props.listAlunni]);
            gestioneVoti(props.listAlunni);
            setHasLoaded(true);
        }
    }, [props.listAlunni])

    function gestioneVoti(listaAlunni) {

        let votiTmp = [];

        for (let a of listaAlunni) {
            {
                let motivazioneDocente = parseInt(a.condottaDocenti.motivazione);
                let relazioneDocente = parseInt(a.condottaDocenti.relazione);
                let interazioneDocente = parseInt(a.condottaDocenti.interazione);

                let voto = {
                    idAlunno: a.id,
                    motivazione: a.condottaTutor.motivazione != null ? parseInt(a.condottaTutor.motivazione) : motivazioneDocente,
                    interazione: a.condottaTutor.interazione != null ? parseInt(a.condottaTutor.interazione) : interazioneDocente,
                    relazione: a.condottaTutor.relazione != null ? parseInt(a.condottaTutor.relazione) : relazioneDocente,
                }
                votiTmp.push(voto)
            }
        }
        setVoti([...votiTmp]);
    }

    function changeConfermaModifica() {
        if (props.conferma !== undefined) {
            props.conferma(voti)
            setIsConfermaLoading(true);
        }
    }

    function changeOpenDialogModifica() {
        if (props.closeDialog !== undefined)
            props.closeDialog()
    }

    function changeRelazio(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiRelazioneRef.current[index].value = newStr;

    }
    function onBlurRelazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "0";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = "0"
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = null; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].condottaTutor.relazione = newStr;
        setListAlunniSupporto([...listAlunniTmp])

        let votiTmp = voti
        votiTmp[index].relazione = parseInt(newStr);
        setVoti([...votiTmp])
    }

    function changeInterazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiInterazioneRef.current[index].value = newStr;

    }
    function onBlurInterazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "0";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = "0"
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = null; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].condottaTutor.interazione = newStr;
        setListAlunniSupporto([...listAlunniTmp])

        let votiTmp = voti
        votiTmp[index].interazione = parseInt(newStr);
        setVoti([...votiTmp])
    }


    function changeMotivazione(id, e, index) {
        let newValue = e.target.value;
        let newStr = "";
        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiMotEInteresseRef.current[index].value = newStr;

    }
    function onBlurMotivazione(id, e, index) {
        let newValue = e.target.value;
        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "0";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = "0"
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = null; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].condottaTutor.motivazione = newStr;
        setListAlunniSupporto([...listAlunniTmp])

        let votiTmp = voti
        votiTmp[index].motivazione = parseInt(newStr);
        setVoti([...votiTmp])
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 100 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" rowSpan={2}
                                            className="colonna-nomi row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}
                                        >
                                            <Box sx={{
                                                backgroundColor: 'white',
                                                height: '100px',
                                                px: 2,
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                            }}>
                                                <Typography variant="caption">
                                                    Corso:
                                                </Typography>
                                                <Typography color={'secondary'} textAlign={"start"} sx={{ fontWeight: 'bold', mr: 1 }}
                                                    variant={mobile ? 'caption' : 'body1'}>
                                                    {nomeCorso}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >                                            <Box sx={{
                                            backgroundColor: 'white',
                                            height: '100px',
                                            px: 2,
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                        }}>
                                                <Typography textAlign={"center"}>Motivazione e interesse</Typography>
                                                {tutor && (<Grid container>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Proposta docenti' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Voto proposto' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>)}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >                                            <Box sx={{
                                            backgroundColor: 'white',
                                            height: '100px',
                                            px: 2,
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                        }}>
                                                <Typography textAlign={"center"}>Interazione con il contesto</Typography>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Proposta docenti' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Voto proposto' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ minWidth: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >                                            <Box sx={{
                                            backgroundColor: 'white',
                                            height: '100px',
                                            px: 2,
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                        }}>
                                                <Typography textAlign={"center"}>Relazione con gli altri</Typography>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >(Proposta docenti)</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >(Voto proposto)</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listAlunniSupporto.map((alunno, index) => (
                                        <TableRow style={{ minHeight: '20px' }} key={alunno?.id}>
                                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{ backgroundColor: 'white', px: 2, py: 1 }}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                                        <Typography variant={mobile ? 'caption' : 'body1'}
                                                            sx={{ mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                                            {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                    {alunno.msgIscrizione != null ? (
                                                        <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                    ) : null}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    <Box className="fakeInputBox">
                                                        <Typography>
                                                            {listAlunni[index]?.condottaDocenti?.motivazione != 0 ? (listAlunni[index]?.condottaDocenti?.motivazione ?? '-') : 'N.V.'}
                                                        </Typography>
                                                    </Box>
                                                    <input
                                                        className="inputEdit"
                                                        ref={el => votiMotEInteresseRef.current[index] = el}
                                                        defaultValue={alunno?.condottaTutor?.motivazione != null ?
                                                            (alunno?.condottaTutor?.motivazione != 0 ? alunno?.condottaTutor?.motivazione : "N.V.") : (
                                                                alunno?.condottaDocenti?.motivazione != null ? (
                                                                    alunno?.condottaDocenti?.motivazione != 0 ? alunno?.condottaDocenti?.motivazione : "N.V."
                                                                ) : ""
                                                            )}
                                                        style={{ width: '50px' }}
                                                        onChange={(e) => changeMotivazione(alunno?.id, e, index)}
                                                        onBlur={(e) => onBlurMotivazione(alunno?.id, e, index)}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    <Box className="fakeInputBox">
                                                        <Typography>
                                                            {listAlunni[index]?.condottaDocenti?.interazione != 0 ? (listAlunni[index]?.condottaDocenti?.interazione ?? '-') : 'N.V.'}
                                                        </Typography>
                                                    </Box>

                                                    <input
                                                        className="inputEdit"
                                                        ref={el => votiInterazioneRef.current[index] = el}
                                                        defaultValue={alunno?.condottaTutor?.interazione != null ?
                                                            (alunno?.condottaTutor?.interazione != 0 ? alunno?.condottaTutor?.interazione : "N.V.") : (
                                                                alunno?.condottaDocenti?.interazione != null ? (
                                                                    alunno?.condottaDocenti?.interazione != 0 ? alunno?.condottaDocenti?.interazione : "N.V."
                                                                ) : ""
                                                            )}
                                                        style={{ width: '50px' }}
                                                        onChange={(e) => changeInterazione(alunno?.id, e, index)}
                                                        onBlur={(e) => onBlurInterazione(alunno?.id, e, index)}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    <Box className="fakeInputBox">
                                                        <Typography>
                                                            {listAlunni[index]?.condottaDocenti?.relazione != 0 ? (listAlunni[index]?.condottaDocenti?.relazione ?? '-') : 'N.V.'}
                                                        </Typography>
                                                    </Box>

                                                    <input
                                                        className="inputEdit"
                                                        ref={el => votiRelazioneRef.current[index] = el}
                                                        defaultValue={alunno?.condottaTutor?.relazione != null ?
                                                            (alunno?.condottaTutor?.relazione != 0 ? alunno?.condottaTutor?.relazione : "N.V.") : (
                                                                alunno?.condottaDocenti?.relazione != null ? (
                                                                    alunno?.condottaDocenti?.relazione != 0 ? alunno?.condottaDocenti?.relazione : "N.V."
                                                                ) : ""
                                                            )}
                                                        style={{ width: '50px' }}
                                                        onChange={(e) => changeRelazio(alunno?.id, e, index)}
                                                        onBlur={(e) => onBlurRelazione(alunno?.id, e, index)}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'end'}>
                        <Button disabled={isConfermaLoading} variant='outlined' color="error" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeOpenDialogModifica}>
                            annulla modifica
                        </Button>
                        <Button disabled={isConfermaLoading} variant='contained' color="success" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeConfermaModifica}>
                            conferma e salva
                        </Button>
                    </Box>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    {/* <CircularProgress />  */}
                    <Typography sx={{ mt: 2 }}>Caricamento voti...</Typography>
                </Box>
            )}

        </Fragment>);
}