import { Fragment, useEffect, useState } from "react";
import { Box, Chip, Typography, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Assignment, AssignmentLate, AutoStories, CalendarToday, Close, FormatListNumbered, Info, MenuBook } from "@mui/icons-material";

export default function Presenza(props) {

    const [objTipoPresenza, setObjTipoPresenza] = useState(null);
    const [datiPresenza, setDatiPresenza] = useState(null);
    const [isRegistroDettaglio, setRegistroDettaglio] = useState(true)
    const [showDialog, setShowDialog] = useState(false);

    const tipoPresenzeList = [
        {
            id: 0,
            tipo: 'presente',
            colorChip: 'success',
            bgColor: 'success.light',
            descrizione: 'Dettaglio presenza',
            textIcon: 'P',
            variant: 'outlined',
        },
        {
            id: 1,
            tipo: 'assente',
            colorChip: 'error',
            bgColor: 'error.light',
            descrizione: 'Dettaglio assenza',
            textIcon: 'A',
            variant: 'outlined',
        },
        {
            id: 2,
            tipo: 'ritardo',
            colorChip: 'warning',
            bgColor: 'warning.light',
            descrizione: 'Dettaglio ritardo',
            textIcon: 'ER',
            variant: 'outlined',
        },
        {
            id: 3,
            tipo: 'uscita',
            colorChip: 'warning',
            bgColor: 'warning.light',
            descrizione: 'Dettaglio uscita anticipata',
            textIcon: 'UA',
            variant: 'outlined',
        },
        {
            id: 4,
            tipo: 'entrata-uscita',
            colorChip: 'warning',
            bgColor: 'warning.light',
            descrizione: 'Dettaglio entrata in ritardo e uscita anticipata',
            textIcon: 'ER/UA',
            variant: 'outlined',
        },
        {
            id: 5,
            tipo: 'non-definito',
            colorChip: 'default',
            bgColor: 'grey',
            descrizione: 'Non definito',
            textIcon: 'ND',
            variant: 'outlined',
        },
        {
            id: 6,
            tipo: 'presenza parziale',
            colorChip: 'warning',
            bgColor: 'warning.light',
            descrizione: 'Dettaglio presenza parziale',
            textIcon: 'PP',
            variant: 'outlined',
        },

    ];


    useEffect(() => {
        setRegistroDettaglio(props.isRegistroDettaglio)
    }, [props.isRegistroDettaglio])

    useEffect(() => {
        if (props.alunno !== undefined) {
            if (props.alunno.presenza !== undefined) {
                let objPresenzaTmp = props?.alunno?.presenza;
                let statoPresenzaText = objPresenzaTmp.statoPresenza ?? "P";
                setDatiPresenza(objPresenzaTmp);
                getIndexBasedOnStatoPresenza(statoPresenzaText);
            }
        }
    }, [props.alunno])

    useEffect(() => {
        if (props.presenza !== undefined) {            
            let objPresenzaTmp = props?.presenza;
            let statoPresenzaText = objPresenzaTmp.statoPresenza ?? "P";
            setDatiPresenza(objPresenzaTmp);
            getIndexBasedOnStatoPresenza(statoPresenzaText);
        }
    }, [props.presenza])

    function openDialog() {
        setShowDialog(prev => !prev);
    }

    function getIndexBasedOnStatoPresenza(textStatoPresenza) {
        switch (textStatoPresenza) {
            case 'P':
                setObjTipoPresenza(tipoPresenzeList[0]);
                break;
            case 'A':
                setObjTipoPresenza(tipoPresenzeList[1]);
                break;
            case 'ER':
                setObjTipoPresenza(tipoPresenzeList[2]);
                break;
            case 'UA':
                setObjTipoPresenza(tipoPresenzeList[3]);
                break;
            case 'ER/UA':
                setObjTipoPresenza(tipoPresenzeList[4]);
                break;
            case 'ND':
                setObjTipoPresenza(tipoPresenzeList[5]);
                break;
            case 'N.D.':
                setObjTipoPresenza(tipoPresenzeList[5]);
                break;
            case 'PP':
                setObjTipoPresenza(tipoPresenzeList[6]);
                break;
          
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>

            <Box width={"30px"} height={"30px"}
                border={objTipoPresenza?.variant === "outlined" ? "1px solid grey" : "none"}
                borderColor={objTipoPresenza?.bgColor}
                backgroundColor={objTipoPresenza?.variant === "contained" ? objTipoPresenza?.bgColor : "white"}
                display={"flex"} alignItems={"center"} justifyContent={"center"}
                sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                {objTipoPresenza?.icon !== undefined ? (
                    objTipoPresenza?.icon
                ) : (
                        <Typography fontWeight={"bold"} color={objTipoPresenza?.bgColor} sx={{ fontSize: objTipoPresenza?.id !== 4 ? '1rem' : '0.8rem'}}>{objTipoPresenza?.textIcon}</Typography>
                )}
            </Box>

            {(objTipoPresenza?.id === 2 && isRegistroDettaglio == true) && (
                <Typography textAlign={"center"} variant="body2" sx={{ mt: 1 }}>Entrata in ritardo: <b style={{ color: 'orange' }}>{datiPresenza?.orario_ingresso_reale}</b></Typography>
            )}
            {(objTipoPresenza?.id === 3 && isRegistroDettaglio == true) && (
                <Typography textAlign={"center"} variant="body2" sx={{ mt: 1 }}>Uscita anticipata: <b style={{ color: 'orange' }}>{datiPresenza?.orario_uscita_reale}</b></Typography>
            )}
            {(objTipoPresenza?.id === 4 && isRegistroDettaglio == true) && (
                <Box>
                    <Typography textAlign={"center"} variant="body2" sx={{ mt: 1 }}>Entrata in ritardo: <b style={{ color: 'orange' }}>{datiPresenza?.orario_ingresso_reale}</b></Typography>
                    <Typography textAlign={"center"} variant="body2" sx={{ mt: 1 }}>Uscita anticipata: <b style={{ color: 'orange' }}>{datiPresenza?.orario_uscita_reale}</b></Typography>
                </Box>
            )}

            {/* <Dialog open={showDialog} fullWidth={true} maxWidth={"sm"} onClick={openDialog}>
                <DialogTitle display={"flex"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }}>
                    {objTipoPresenza?.descrizione}
                    <IconButton onClick={openDialog} sx={{ ml: 5 }}>
                        <Close  />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {objTipoPresenza?.componenteAssociato}
                </DialogContent>
            </Dialog> */}
        </Box>
    );



}



