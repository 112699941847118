import { Alert, Autocomplete, Box, Button, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import CustomDatePicker from "../../../shared/customDatePicker";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, Check, ExpandLess, ExpandMore } from "@mui/icons-material";
import { convertDataGetProssimeLezione } from "../../../shared/metodiDateUtility";
import { DateTime } from "luxon";
import { RicercaAPI } from "../../../../api/ricercaApi";
import { getAnnoFormativo } from "../../../shared/utilityMethods";
import { UtilityAPI } from "../../../../api/utilityApi";

export default function AnagraficaConvenzione(props) {

    const [annoFormativo, setAnnoFormativo] = useState('');
    const [listAnniFormativi, setListAnniFormativi] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [idAzienda, setIdAzienda] = useState(-1);
    const [idConvenzione, setIdConvenzione] = useState(-1);
    // eslint-disable-next-line no-unused-vars
    const [convenzione, setConvenzione] = useState(null)
    const [azienda, setAzienda] = useState(null);

    const [aziendaFormCollapsed, setAziendaLegaleFormCollapsed] = useState(true);
    const [rapLegaleFormCollapsed, setRapLegaleFormCollapsed] = useState(true);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    //Azienda
    const [pIva, setPIva] = useState('');
    const [CF, setCF] = useState('');
    const [ragioneSociale, setRagioneSociale] = useState('');
    // const [CFRapLegale, setCFRapLegale] = useState('');
    const [nomeRapLegale, setNomeRapLegale] = useState('');
    const [cognomeRapLegale, setCognomeRapLegale] = useState('');
    const [dataNascitaRapLegale, setDataNascitaRapLegale] = useState(null);
    const [luogoNascitaRapLegale, setLuogoNascitaRapLegale] = useState('');
    const [indirizzoSedeLegale, setIndirizzoSedeLegale] = useState('');
    const [cittaSedeLegale, setCittaSedeLegale] = useState('');
    const [telefonoSedeLegale, setTelefonoSedeLegale] = useState('');
    const [faxSedeLegale, setFaxSedeLegale] = useState('');

    //info convenzione
    const [nomeDirettore, setNomeDirettore] = useState('');
    const [nomeDirettoreValid, setNomeDirettoreValid] = useState(true);
    const [cognomeDirettore, setCognomeDirettore] = useState('');
    const [cognomeDirettoreValid, setCognomeDirettoreValid] = useState(true);
    const [dataNascitaDirettore, setDataNascitaDirettore] = useState(null);
    const [luogoNascitaDirettore, setLuogoNascitaDirettore] = useState('');
    const [luogoNascitaDirettoreValid, setLuogoNascitaDirettoreValid] = useState(true);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [sediList, setSediList] = useState([]);
    const [numeroAllievi, setNumeroAllievi] = useState(0);
    const [numeroAllieviValid, setNumeroAllieviValid] = useState(true);
    const [percorsiIstruzioneList, setPercorsiIstruzioneList] = useState([]);
    const [percorsiIstruzioneSelected, setPercorsiIstruzioneSelected] = useState([]);
    const [idPercorsiIstruzioneSelected, setIdPercorsiIstruzioneSelected] = useState([]);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);

    const [createdByNome, setCreatedByNome] = useState('');
    const [createdByTitolo, setCreatedByTitolo] = useState('');

    useEffect(() => {
        let isVisualizzaTmp = false;
        if (props.isVisualizza !== undefined) {
            setIsVisualizza(props.isVisualizza)
            isVisualizzaTmp = props.isVisualizza;
        }
        if (isVisualizzaTmp === false) getDirettoreSede();
    }, [props.isVisualizza])


    useEffect(() => {
        if (props.idConvenzione !== undefined) {
            setIdConvenzione(props.idConvenzione);
            if (props.idConvenzione !== -1) getConvenzioneById(props.idConvenzione);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idConvenzione])


    useEffect(() => {
        getAnniFormativi();
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
        setIsConfermaLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.idAzienda !== undefined) {
            setIdAzienda(props.idAzienda);
            if (props.idAzienda !== -1) getAziendaById(props.idAzienda);
        }
    }, [props.idAzienda])

    function closeDialogAggiungiAzienda() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }


    function changeNomeDirettore(e) {
        let valueTmp = e.target.value;
        setNomeDirettore(valueTmp)
        if (valueTmp !== '') {
            setNomeDirettoreValid(true)
        } else {
            setNomeDirettoreValid(false)
        }
    }

    function changeCognomeDirettore(e) {
        let valueTmp = e.target.value;
        setCognomeDirettore(valueTmp)
        if (valueTmp !== '') {
            setCognomeDirettoreValid(true)
        } else {
            setCognomeDirettoreValid(false)
        }
    }

    function changeLuogoNascitaDirettore(e) {
        let valueTmp = e.target.value;
        setLuogoNascitaDirettore(valueTmp)
        if (valueTmp !== '') {
            setLuogoNascitaDirettoreValid(true)
        } else {
            setLuogoNascitaDirettoreValid(false)
        }
    }
    function onChangeValueDataNascitaDirettore(e) {
        let formattedDataTmp = convertDataGetProssimeLezione(e, true);
        setDataNascitaDirettore(formattedDataTmp)
    }

    function changeNumeroAllievi(e) {
        let valueTmp = e.target.value
        let valueNoExtraCharactersTmp = valueTmp.replace(/[^0-9]/g, '');
        valueNoExtraCharactersTmp = valueNoExtraCharactersTmp.replace(/^0+(?=\d)/, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
        setNumeroAllievi(valueNoExtraCharactersTmp);
        if (valueNoExtraCharactersTmp !== 0 && valueNoExtraCharactersTmp !== '' && valueNoExtraCharactersTmp !== '0') {
            setNumeroAllieviValid(true)
        } else {
            setNumeroAllieviValid(false)
        }
    }

    function validateFormData() {
        if (cognomeDirettore !== ''
            &&
            nomeDirettore !== ''
            &&
            dataNascitaDirettore !== null
            &&
            luogoNascitaDirettore !== ''
            &&
            (numeroAllievi !== '' && numeroAllievi !== '0' && numeroAllievi !== 0)
            &&
            sedeSelected !== -1
            &&
            percorsiIstruzioneSelected.length > 0
            &&
            annoFormativo !== -1
        ) return false
        else return true
    }

    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
            getPercorsiIstruzioneSede(e.target.value);
        }
        else {
            setSedeSelected(-1);
            setPercorsiIstruzioneSelected([])
        }
    };

    function changePercorsiIstruzioneSelected(e, value) {
        const percorsiIstruzioneSelectedTmp = value.filter((obj, index, self) =>
            index === self.findIndex((t) => t.id === obj.id)
        );

        setPercorsiIstruzioneSelected(...[percorsiIstruzioneSelectedTmp])
        let percorsiTmp = [];
        for (let percorso of value) {
            percorsiTmp.push(percorso.id)
        }
        setIdPercorsiIstruzioneSelected([...percorsiTmp])
    }

    function changeAnnoFormativo(e) {
        let annoFormativoTmp = e.target.value;
        setAnnoFormativo(annoFormativoTmp);
        setPercorsiIstruzioneSelected([]);
        setIdPercorsiIstruzioneSelected(-1);
        getSedi(annoFormativoTmp);
    }

    async function getSedi(annoFormativo) {
        setIsConfermaLoading(true)
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setIsConfermaLoading(false)
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])
                if (result.sedi.length === 1) {
                    setSedeSelected(result?.sedi[0]?.id ?? -1)
                    getPercorsiIstruzioneSede(result?.sedi[0]?.id ?? -1)
                }
            })
            .catch(function (error) {
                setIsConfermaLoading(false)
            })
    }

    async function getDirettoreSede() {
        UtilityAPI.getDirettoreSede()
            .then((result) => {
                let direttoreTmp = result;
                setNomeDirettore(direttoreTmp.nome ?? '');
                setCognomeDirettore(direttoreTmp.cognome ?? '');
                setDataNascitaDirettore(direttoreTmp.dataNascita);
                setLuogoNascitaDirettore(direttoreTmp.luogoNascita ?? '')
            })
            .catch(function (error) {
            })
    }

    async function getAnniFormativi() {

        UtilityAPI.getAnniFormativi()
            .then((result) => {
                setListAnniFormativi([...result?.anniFormativi])
            })
            .catch(function (error) {
            })
    }


    async function getAziendaById(idAzienda) {
        let obj = {
            idAzienda: idAzienda
        }
        TirociniAPI.getAziendaById(obj)
            .then((result) => {
                let objAzienda = result;
                setAzienda(objAzienda[0] ?? null);
                setRagioneSociale(objAzienda[0]?.azienda?.ragioneSociale ?? '');
                setPIva(objAzienda[0]?.azienda?.pIva ?? '');
                setCF(objAzienda[0]?.azienda?.CF ?? '');
                setCittaSedeLegale(objAzienda[0]?.sedeLegale?.citta ?? '');
                setIndirizzoSedeLegale(objAzienda[0]?.sedeLegale?.indirizzo ?? '');
                setTelefonoSedeLegale(objAzienda[0]?.sedeLegale?.telefono ?? '');
                setFaxSedeLegale(objAzienda[0]?.sedeLegale?.fax ?? '');
                setNomeRapLegale(objAzienda[0]?.responsabileLegale?.nome ?? '');
                setCognomeRapLegale(objAzienda[0]?.responsabileLegale?.cognome ?? '');
                setDataNascitaRapLegale(objAzienda[0]?.responsabileLegale?.dataNascita ?? null);
                setLuogoNascitaRapLegale(objAzienda[0]?.responsabileLegale?.luogoNascita ?? '');

            })
            .catch(function (error) {
            })

    }

    async function getConvenzioneById(idConvenzione) {
        let obj = {
            idConvenzione: idConvenzione
        }
        TirociniAPI.getConvenzioneById(obj)
            .then((result) => {
                let objConvenzione = result;

                setAzienda(objConvenzione[0]?.convenzione?.azienda);
                setConvenzione(objConvenzione[0]?.convenzione);
                setAnnoFormativo(objConvenzione[0]?.annoFormativo);

                setCreatedByNome(objConvenzione[0]?.createdBy?.nome);
                setCreatedByTitolo(objConvenzione[0]?.createdBy?.titolo);

                setRagioneSociale(objConvenzione[0]?.convenzione?.azienda?.azienda?.ragioneSociale ?? '');
                setPIva(objConvenzione[0]?.convenzione?.azienda?.azienda?.pIva ?? '');
                setCF(objConvenzione[0]?.convenzione?.azienda?.azienda?.CF ?? '');
                setCittaSedeLegale(objConvenzione[0]?.convenzione?.azienda?.sedeLegale?.citta ?? '');
                setIndirizzoSedeLegale(objConvenzione[0]?.convenzione?.azienda?.sedeLegale?.indirizzo ?? '');
                setTelefonoSedeLegale(objConvenzione[0]?.convenzione?.azienda?.sedeLegale?.telefono ?? '');
                setFaxSedeLegale(objConvenzione[0]?.convenzione?.azienda?.sedeLegale?.fax ?? '');
                setNomeRapLegale(objConvenzione[0]?.convenzione?.azienda?.responsabileLegale?.nome ?? '');
                setCognomeRapLegale(objConvenzione[0]?.convenzione?.azienda?.responsabileLegale?.cognome ?? '');
                setDataNascitaRapLegale(objConvenzione[0]?.convenzione?.azienda?.responsabileLegale?.dataNascita ?? null);
                setLuogoNascitaRapLegale(objConvenzione[0]?.convenzione?.azienda?.responsabileLegale?.luogoNascita ?? '');

                setSedeSelected(objConvenzione[0]?.convenzione?.sedeCiofs?.id);
                getPercorsiIstruzioneSede(objConvenzione[0]?.convenzione?.sedeCiofs?.id);
                setNumeroAllievi(objConvenzione[0]?.convenzione?.alunniPrevisti);
                setNomeDirettore(objConvenzione[0]?.convenzione?.direttoreCiofs?.nome);
                setCognomeDirettore(objConvenzione[0]?.convenzione?.direttoreCiofs?.cognome);
                setDataNascitaDirettore(objConvenzione[0]?.convenzione?.direttoreCiofs?.dataNascita);
                setLuogoNascitaDirettore(objConvenzione[0]?.convenzione?.direttoreCiofs?.luogoNascita);
                setPercorsiIstruzioneSelected([...objConvenzione[0]?.convenzione?.percorsiIstruzione]);
                let idPercorsiTmp = [];
                for (let i = 0; i < objConvenzione[0]?.convenzione?.percorsiIstruzione.length; i++) {
                    idPercorsiTmp.push(objConvenzione[0]?.convenzione?.percorsiIstruzione[i].id)
                }
                setIdPercorsiIstruzioneSelected([...idPercorsiTmp]);

                let PercorsiTmp = objConvenzione[0]?.convenzione?.percorsiIstruzione;
                let arrayIdPercorsiTmp = [];
                for (let percorso of PercorsiTmp) {
                    arrayIdPercorsiTmp.push(percorso.id)
                }
                setIdPercorsiIstruzioneSelected([...arrayIdPercorsiTmp])
            })
            .catch(function (error) {
            })

    }

    async function getPercorsiIstruzioneSede(idSede) {
        TirociniAPI.getPercorsiIstruzioneSede({ idSede: idSede })
            .then((result) => {
                setPercorsiIstruzioneList([...result])
            })
            .catch(function (error) {
            })
    }

    async function saveConvenzione() {
        setButtonConfermaDisabled(true)
        let obj = {
            azienda: azienda,
            sedeCiofs: sedeSelected,
            direttoreCiofs: {
                nome: nomeDirettore,
                cognome: cognomeDirettore,
                luogoNascita: luogoNascitaDirettore,
                dataNascita: dataNascitaDirettore,
            },
            alunniPrevisti: numeroAllievi,
            percorsiIstruzione: idPercorsiIstruzioneSelected,
            annoFormativo: annoFormativo.toString()
        }

        TirociniAPI.saveConvenzione(obj)
            .then((result) => {
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                closeAlertConfirmInserimento();
                if (props.closeDialogAggiungiConvenzione)
                    props.closeDialogAggiungiConvenzione(result?.idConvenzione[0]?.id)
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false)
            })
    }

    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                {isVisualizza && (<Paper elevation={1} sx={{ p: 2 }}>
                    <Box display={"flex"} flexDirection={'column'} >
                        {/* <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Convenzione</Typography>
                        </Box> */}
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} >Convenzione creata da <b>{createdByNome}</b> {' (' + createdByTitolo + ')'}</Typography>
                        </Box>
                    </Box>
                </Paper>)}
                <Paper elevation={1} sx={{ p: 2, mt: isVisualizza ? 2 : 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Azienda</Typography>
                        </Box>
                        <IconButton onClick={() => setAziendaLegaleFormCollapsed(prev => !prev)}>
                            {rapLegaleFormCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Box>
                    <Collapse in={aziendaFormCollapsed}>
                        <Grid container spacing={2} mt={2}>
                            <Grid xs={12} md={12}>
                                <TextField
                                    disabled
                                    defaultValue={ragioneSociale}
                                    value={ragioneSociale}
                                    label={'Ragione sociale'}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled
                                    inputProps={{ maxLength: 11 }}
                                    defaultValue={pIva ?? ''}
                                    value={pIva ?? ''}
                                    label={"P.Iva"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled
                                    defaultValue={CF ?? ''}
                                    value={CF ?? ''}
                                    label={"C.F."}
                                    sx={{ width: '100%' }}
                                    inputProps={{ maxLength: 16 }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    defaultValue={cittaSedeLegale ?? ''}
                                    value={cittaSedeLegale ?? ''}
                                    label={"Città sede legale"}
                                    sx={{ width: '100%' }}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    defaultValue={indirizzoSedeLegale ?? ''}
                                    value={indirizzoSedeLegale ?? ''}
                                    label={'Indirizzo sede legale'}
                                    sx={{ width: '100%' }}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled
                                    defaultValue={telefonoSedeLegale ?? ''}
                                    value={telefonoSedeLegale ?? ''}
                                    label={"Telefono"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    disabled
                                    defaultValue={faxSedeLegale ?? ''}
                                    value={faxSedeLegale ?? ''}
                                    label={"Fax"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={nomeRapLegale ?? ''}
                                    value={nomeRapLegale ?? ''}
                                    label={"Nome rappresentante legale"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={cognomeRapLegale ?? ''}
                                    value={cognomeRapLegale ?? ''}
                                    label={'Cognome rappresentante legale'}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <CustomDatePicker
                                    maxDate={DateTime.now()}
                                    label={"Data di nascita rapresentante legale"}
                                    isDisabled
                                    selectedValue={dataNascitaRapLegale ?? null}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    disabled
                                    defaultValue={luogoNascitaRapLegale ?? ''}
                                    value={luogoNascitaRapLegale ?? ''}
                                    label={"Luogo di nascita rapresentante legale"}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Info sede Ciofs</Typography>
                        </Box>
                        <IconButton onClick={() => setRapLegaleFormCollapsed(prev => !prev)}>
                            {rapLegaleFormCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Box>
                    <Collapse in={rapLegaleFormCollapsed}>
                        <Grid container spacing={2} mt={2}>
                            <Grid xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="sede-label">
                                        Sede Ciofs *
                                    </InputLabel>
                                    <Select
                                        data-testid='sedeRicercaSelect'
                                        label="Sede Ciofs *"
                                        labelId="sede-label"
                                        value={sedeSelected}
                                        onChange={changeSede}
                                        disabled={sediList.length <= 1}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                            ...
                                        </MenuItem>
                                        {sediList.map((sede) => (
                                            <MenuItem
                                                key={sede.id}
                                                value={sede.id}
                                            >
                                                {sede.nomeSede}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="anniFormativi-label">
                                        Anno formativo *
                                    </InputLabel>
                                    <Select
                                        data-testid='anniFormativiSelect'
                                        label="Anno formativo *"
                                        labelId="anniFormativi-label"
                                        value={annoFormativo}
                                        onChange={changeAnnoFormativo}
                                        disabled={isVisualizza}
                                        error={annoFormativo === -1}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                            ...
                                        </MenuItem>
                                        {listAnniFormativi.map((anno) => (
                                            <MenuItem
                                                key={anno.id}
                                                value={anno.id}
                                            >
                                                {anno.nomeVisualizzato}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    error={(numeroAllievi === "" || numeroAllievi === 0 || numeroAllievi === '0') && !numeroAllieviValid}
                                    helperText={(numeroAllievi === "" || numeroAllievi === 0 || numeroAllievi === '0') ? "il numero di allievi deve essere maggiore di 0" : ""}
                                    disabled={isVisualizza}
                                    defaultValue={numeroAllievi ?? ''}
                                    value={numeroAllievi ?? ''}
                                    required
                                    label={"Numero allievi"}
                                    onChange={changeNumeroAllievi}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    error={nomeDirettore === "" && !nomeDirettoreValid}
                                    disabled={isVisualizza}
                                    defaultValue={nomeDirettore ?? ''}
                                    value={nomeDirettore ?? ''}
                                    required
                                    label={"Nome direttore"}
                                    onChange={changeNomeDirettore}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    error={cognomeDirettore === "" && !cognomeDirettoreValid}
                                    disabled={isVisualizza}
                                    defaultValue={cognomeDirettore ?? ''}
                                    value={cognomeDirettore ?? ''}
                                    required
                                    label={'Cognome direttore'}
                                    onChange={changeCognomeDirettore}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <CustomDatePicker
                                    maxDate={DateTime.now()}
                                    label={"Data di nascita direttore*"}
                                    isDisabled={isVisualizza}
                                    selectedValue={dataNascitaDirettore ?? null}
                                    onChangeValue={onChangeValueDataNascitaDirettore}
                                    year={true}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    error={luogoNascitaDirettore === "" && !luogoNascitaDirettoreValid}
                                    disabled={isVisualizza}
                                    required
                                    defaultValue={luogoNascitaDirettore ?? ''}
                                    value={luogoNascitaDirettore ?? ''}
                                    label={"Luogo di nascita direttore"}
                                    onChange={changeLuogoNascitaDirettore}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid xs={12} md={12}>
                                <Autocomplete
                                    multiple
                                    onChange={changePercorsiIstruzioneSelected}
                                    options={[...percorsiIstruzioneList]}
                                    getOptionLabel={(option) => option.nome}
                                    defaultValue={[...percorsiIstruzioneSelected]}
                                    value={[...percorsiIstruzioneSelected]}
                                    disabled={isVisualizza}
                                    renderInput={(params) => (
                                        <TextField
                                            // error={percorsiIstruzioneSelected.length === 0}
                                            disabled={isVisualizza}
                                            {...params}
                                            label="Percorsi istruzione *"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
            </Box>
            <Divider />

            <Box sx={{ mt: 2, display: "flex", justifyContent: "end", alignItems: "center", px: 2 }}>
                {/* {validateFormData() ? (
                    <Alert severity="error">Alcune informazioni obbligatorie non sono state inserite.</Alert>
                ) : (
                    <span></span>
                )} */}
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: 'unset' } }}>
                    <Button startIcon={<ArrowBack />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }} onClick={closeDialogAggiungiAzienda} variant="outlined" fullWidth color={isVisualizza ? 'primary' : 'error'} >{isVisualizza ? 'Torna alla lista convenzioni' : ('Chiudi procedura di ' + (idConvenzione !== -1 ? 'copia' : 'inserimento'))}</Button>
                    {!isVisualizza && (<Button
                        disabled={validateFormData() || isConfermaLoading}
                        sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                        onClick={() => setConfirmAlertIsOpen(true)}
                        variant="contained" fullWidth color={'success'} >Concludi procedura di {idConvenzione !== -1 ? 'copia' : 'inserimento'}</Button>)}
                </Box>
            </Box>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta convenzione'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere la convenzione? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveConvenzione}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}