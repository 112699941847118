import { Box, useTheme, Step, StepLabel, Stepper, useMediaQuery, CircularProgress, Typography, DialogTitle, Divider } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import RicercaAzienda from "./dettaglio-convenzione/ricercaAzienda";
import AnagraficaConvenzione from "./dettaglio-convenzione/anagraficaConvenzione";


export default function DettaglioConvenzione(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [idAzienda, setIdAzienda] = useState(-1);
    const [idConvenzione, setIdConvenzione] = useState(-1);

    const [tabIndex, setTabIndex] = useState(0);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);

    const [addConvenzioneFromAzienda, setAddConvenzioneFromAzienda] = useState(false);
    const [pivaAziendaLocationState, setPivaAziendaLocationState] = useState(-1);
    useEffect(() => {
        if (props.idConvenzione !== undefined && props.isVisualizza !== undefined) {
            setIdConvenzione(props.idConvenzione)
            if (props.idConvenzione === -1) {
                setTabIndex(0);
                setIsVisualizza(false);
            }
            else {
                setTabIndex(1);
                if (props.isVisualizza === true) setIsVisualizza(true);
                else setIsVisualizza(false);
            }

            setTimeout(() => { setHasLoaded(true) }, 100)
        }
    }, [props.idConvenzione, props.isVisualizza])

    useEffect(() => {
        if (props.pivaAziendaLocationState !== undefined && props.addConvenzioneFromAzienda !== undefined) {
            setPivaAziendaLocationState(props.pivaAziendaLocationState);
            setAddConvenzioneFromAzienda(props.addConvenzioneFromAzienda);
        }
    }, [props.pivaAziendaLocationState, props.addConvenzioneFromAzienda])

    function changeIdAzienda(idAzienda) {
        setIdAzienda(idAzienda)
    }

    function changeIdConvenzione(idConvenzione) {
        setIdConvenzione(idConvenzione)
        if (props.changeIdConvenzione) props.changeIdConvenzione(idConvenzione)
    }

    function nextTab() {
        setTabIndex(prev => prev + 1);
    }

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
        setTabIndex(0);
        changeIdConvenzione(-1)
        changeIdAzienda(-1)
        setIsVisualizza(false)
    }

    function closeDialogAggiungiConvenzione(idAzienda) {
        if (props.closeDialogAggiungiConvenzione) props.closeDialogAggiungiConvenzione(idAzienda)
        setTabIndex(0);
        changeIdConvenzione(-1)
        changeIdAzienda(-1)
        setIsVisualizza(false)
    }

    return (
        <Fragment>
            {!isVisualizza && (<Box sx={{ borderBottom: 1, borderColor: 'divider', p: 2 }}>
                <Stepper activeStep={tabIndex} alternativeLabel={isMobile}>
                    <Step key={0}>
                        <StepLabel>Selezione azienda</StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Dati convenzione</StepLabel>
                    </Step>
                </Stepper>
            </Box>)}
            <Box sx={{ p: 0 }}>
                {hasLoaded ? (
                    <Fragment>
                        {tabIndex === 0 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Convenzione: selezione azienda</Typography>
                                </DialogTitle>
                                <Divider />
                                <RicercaAzienda
                                    addConvenzioneFromAzienda={addConvenzioneFromAzienda}
                                    pivaAziendaLocationState={pivaAziendaLocationState}
                                    closeDialog={closeDialog}
                                    changeIdAzienda={changeIdAzienda}
                                    nextTab={nextTab}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 1 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Convenzione: dati convenzione</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaConvenzione
                                    idAzienda={idAzienda}
                                    idConvenzione={idConvenzione}
                                    changeIdConvenzione={changeIdConvenzione}
                                    closeDialog={closeDialog}
                                    closeDialogAggiungiConvenzione={closeDialogAggiungiConvenzione}
                                    isVisualizza={isVisualizza}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress sx={{ mb: 4 }} />
                        <Typography >Caricamento in corso...</Typography>
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}