import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

//{ sendToken: true } sulle chiamate che vogliono l'autenticazione


export const UserAPI = {
    thereIsComunicazioneUrgente: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/auth/login`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDettaglioAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/alunni/dettaglio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(UserAPI)