import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const AuthAPI = {
    login: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/auth/login`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    logout: async function (token ,cancel = false) {
        const response = await api.request({
            url: `/utente/logout`,
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    } ,
    getRefreshToken: async function (token, cancel = false) {
        const response = await api.request({
            
            url: `/auth/refreshToken`,
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    richiestaRecuperoPin: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/auth/recuperoPin/nuovaRichiesta`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    verificaScadenzaTokenRecuperoPin: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/auth/recuperoPin/verificaScadenzaToken`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    cambioPin: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/auth/recuperoPin/cambioPin`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(AuthAPI)