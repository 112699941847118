import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useEffect, useState } from "react";
import { getDataInizioFinePeriodo } from "./utilityMethods";
import { DateTime } from "luxon";
import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function CustomDatePicker(props) {

    const [label, setLabel] = useState("Data")
    const [isFullWidth, setIsFullWidth] = useState(true);

    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [selectedValue, setSelectedValue] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    const [year, setYear] = useState(false);
    const [showResetButton, setShowResetButton] = useState(false);

    //Se passo solo getDatePeriodo = true senza passare i props di minDate e maxDate, prenderà in automatico il limite
    // dell'anno formativo.I casi in cui conviene passare i minDate e i maxDate sono quando, ad esempio nel registro, ci sono degli
    //eventi che prendono come minDate l'inizio del periodo (e non dell'anno formativo)

    useEffect(() => {
        if (props.isDisabled !== undefined)
            setIsDisabled(props.isDisabled);
    }, [props.isDisabled])

    useEffect(() => {
        if (props.year !== undefined)
            setYear(props.year);
    }, [props.year])

    useEffect(() => {
        if (props.showResetButton !== undefined)
            setShowResetButton(props.showResetButton);
    }, [props.showResetButton])

    useEffect(() => {
        if (props.getDatePeriodo !== undefined && props.getDatePeriodo === true) {
            let objDateInteresse = getDataInizioFinePeriodo();
            let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
            let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
            setMinDate(inizioPeriodoTmp ?? null);
            setMaxDate(finePeriodoTmp ?? null)
        }
    }, [props.getDatePeriodo])

    useEffect(() => {
        if (props.minDate !== undefined) {
            if (typeof (props.minDate) === "string") {
                const format = 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'';
                const luxonDateTime = DateTime.fromFormat(props.minDate, format);
                const minTimeTmp = luxonDateTime.plus({ days: 1 })
                setMinDate(minTimeTmp);
            } else setMinDate(props.minDate);
        }
    }, [props.minDate])

    useEffect(() => {
        if (props.maxDate !== undefined)
            if (typeof (props.maxDate) === "string") {
                const format = 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'';
                const luxonDateTime = DateTime.fromFormat(props.maxDate, format);
                const maxTimeTmp = luxonDateTime.plus({ days: 1 })
                setMaxDate(maxTimeTmp);
            } else setMaxDate(props.maxDate);

    }, [props.maxDate])

    useEffect(() => {
        if (props.selectedValue !== undefined) {
            if (typeof (props.selectedValue) !== "string") //Se non è una stringa è un oggetto
                setSelectedValue(props.selectedValue);
            else { //Se mi arriva una string lo converto in un obj luxon
                let dateTimeConverted = DateTime.fromISO(props.selectedValue);
                setSelectedValue(dateTimeConverted);
            }
        }
    }, [props.selectedValue])

    useEffect(() => {
        if (props.label !== undefined)
            setLabel(props.label);
    }, [props.label])

    useEffect(() => {
        if (props.isFullWidth !== undefined)
            setIsFullWidth(props.isFullWidth);
    }, [props.isFullWidth])

    function onChangeValue(value) {
        props.onChangeValue(value)
    }

    function resetValue() {
        if (props.resetValue) props.resetValue();
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", width: isFullWidth ? '100%' : 'unset' }}>
            <LocalizationProvider
                localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'
            >
                <MobileDatePicker views={year ? ['year', 'month', 'day'] : ['month', 'day']}
                    sx={{
                        width: isFullWidth ? '100%' : 'unset',
                        '& .MuiInputBase-root': {
                            borderRadius: (showResetButton && selectedValue !== null) ? '5px 0px 0px 5px' : '5px',
                        },
                    }}
                    disabled={isDisabled}
                    localeText={{
                        cancelButtonLabel: 'Annulla',
                        okButtonLabel: "Conferma"
                    }}

                    minDate={minDate}
                    maxDate={maxDate}
                    label={label}
                    value={selectedValue}
                    format='dd/MM/yyyy'
                    onChange={(value) => onChangeValue(value)}
                    dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                />
            </LocalizationProvider>
            {(showResetButton && selectedValue !== null) && (
                <Box sx={{ border: '1px solid #bdbdbd', borderLeft: '0px', borderRadius: '0px 5px 5px 0px', height: '54px', display: "flex", alignItems: "center" }}>
                    <IconButton disabled={selectedValue === null} onClick={resetValue}>
                        <Close />
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}