import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Snackbar, Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, useTheme, useMediaQuery, Divider, IconButton } from '@mui/material';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import { ArrowBack, Close, EditCalendar, ZoomIn } from "@mui/icons-material";
import RangeDate from "../shared/rangeDate";
import { DateTime, Interval } from "luxon";

import Presenza from "../registri/presenza"
import { convertDateObjectToString, convertISODate, getDayOfTheWeek, getDayAndMonth, convertDataGetProssimeLezione, convertDataDiNascita, convertToSqlFormat } from '../shared/metodiDateUtility';
import { RegistroAPI } from "../../api/registroApi";
import { PresenzeAPI } from "../../api/presenzeApi";
import DettaglioValidazione from "./dettaglioValidazione";
import Breadcrumb from "../registri/shared/breadcrumb";
export default function RegistroValidazione(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const [validazionePresenzeOpen, setValidazionePresenzeOpen] = useState(false)


    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");
    const [corso, setCorso] = useState({});

    const [dataRichiestaDettaglio, setDataRichiestaDettaglio] = useState(null);


    const [arrayGiorni, setArrayGiorni] = useState([])
    const [listAlunni, setListAlunni] = useState([]);

    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false) //(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [sedeUtente, setSedeUtente] = useState('');

    useEffect(() => {
        if (props.sedeUtente !== undefined)
            setSedeUtente(props.sedeUtente)
    }, [props.sedeUtente])

    useEffect(() => {
        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)
        }

        setChangePeriodoInteresseOpen(true)
    }, [])

    useEffect(() => {
        if (props.corso) {
            setNomeClasse(props.corso.nomeCorso);
            setCorso(props.corso)
            setIdClasseSelezionata(props.corso.id);
        }
    }, [props.corso])


    function goToSelezionaCorso() {
        if (props.closeDialog) props.closeDialog()
    }



    function openDettaglioRegistro(dataRichiesta) {
        setHasLoaded(false);
        setDataRichiestaDettaglio(dataRichiesta);
        setValidazionePresenzeOpen(true);
    }

    function changeIdClasseSelezionata(idClasse, dataInizio, dataFine) {
        if (idClasse !== undefined) {

            let idCorsoCrypted = CryptoJS.AES.encrypt(idClasse.toString(), process.env.REACT_APP_PRIVATE_KEY).toString();
            sessionStorage.setItem("classe", idCorsoCrypted);

            setPeriodoDiInteresseInizio(dataInizio);
            setPeriodoDiInteresseFine(dataFine);

            setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
            setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));

            getRegistroDiSintesi(idClasse, dataInizio, dataFine);
        }
    }

    //Periodo di interesse
    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let dataInizioTmp = dataInizio ?? new DateTime().toISO();
        let dataFineTmp = dataFine ?? new DateTime().toISO();

        setPeriodoDiInteresseInizio(dataInizioTmp);
        setPeriodoDiInteresseFine(dataFineTmp);

        setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
        setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));

        setChangePeriodoInteresseOpen(false)

        changeIdClasseSelezionata(idClasseSelezionata, dataInizioTmp, dataFineTmp)
    }
    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }

    function isAlunnoIscrittoInData(dataCella, alunno) {
        let dataSql = DateTime.fromSQL(dataCella);
        let dataConvertedIso = dataSql.toUTC().toISO()
        let iscrittoDalIso = alunno?.iscrittoDal;
        let iscrittoAlIso = alunno?.iscrittoAl;

        let startDate = DateTime.fromISO(iscrittoDalIso);
        let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
        let someDate = DateTime.fromISO(dataConvertedIso);
        let interval = Interval.fromDateTimes(startDate, endDate);
        if (interval.contains(someDate)) {
            return true
        } else return false;
    }

    function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
        let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
        let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

        let dataInizioObject = DateTime.fromISO(dataInizioTmp);
        let dataFineObject = DateTime.fromISO(dataFineTmp);

        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);


        if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }

        if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
        {
            return "Ritirato il " + convertDataDiNascita(iscrittoAl)
        }
        if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
        {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
            return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        }
        else return null
    }

    function closeModal() {
        setValidazionePresenzeOpen(false);
        setDataRichiestaDettaglio('');
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine)
    }

    function presAssValidate(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
    }

    function openAlertMsg(msg, severity) {
        setValidazionePresenzeOpen(false);
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function getRegistroDiSintesi(idClasseSelezionata, dataDiInteresseInizio, dataDiInteresseFine) {

        let dataDiInteresseInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true)
        let dataDiInteresseFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true)

        let dataObj = {
            dataRichiestaDa: dataDiInteresseInizioTmp,
            dataRichiestaA: dataDiInteresseFineTmp,
            idCorso: idClasseSelezionata
        }
        RegistroAPI.getRegistroDiSintesi(dataObj)
            .then((result) => {
                // let alunniTmp = result.alunni;
                let arrayGiorniTmp = result.giorni;
                let alunniTmp = result.alunni;

                for (let i = 0; i < arrayGiorniTmp.length; i++) {

                    let dataIso = arrayGiorniTmp[i].data;
                    let dataConverted = convertDateObjectToString(new Date(dataIso));
                    //Passare dataISO a oggetto Date 
                    arrayGiorniTmp[i].dataIso = dataIso;
                    arrayGiorniTmp[i].data = dataConverted
                }

                for (let i = 0; i < alunniTmp.length; i++) {
                    alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

                }

                setArrayGiorni([...arrayGiorniTmp])
                setListAlunni([...alunniTmp]);
                getPresenzeSintesi(alunniTmp, dataDiInteresseInizio, dataDiInteresseFine, arrayGiorniTmp);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }


            })

    }

    async function getPresenzeSintesi(alunniTmp, dataInizio, dataFine, arrayGiorniTmp) {


        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        // let dataInizioSql = dataInizioConverted.toISODate();
        let dataInizioConverted = DateTime.fromISO(dataInizio);
        let dataInizioCorretta = convertDataGetProssimeLezione(dataInizioConverted, true)

        let dataFineConverted = DateTime.fromISO(dataFine);
        let dataFineCorretta = convertDataGetProssimeLezione(dataFineConverted, true)

        let dataObj = {
            "dataDal": dataInizioCorretta,
            "dataAl": dataFineCorretta,
            "idAlunno": idAlunni
        }
        setHasLoaded(false)

        PresenzeAPI.getPresenzeSintesiValidazione(dataObj)
            .then((result) => {
                if (result.alunni.length > 0) {
                    let arrayAppello = result.alunni;
                    for (let i = 0; i < alunniTmp.length; i++) {
                        alunniTmp[i].presenze = [];
                        for (let j = 0; j < arrayAppello.length; j++) {
                            if (alunniTmp[i].id === arrayAppello[j].idAlunno) {
                                let statoPresenzaTmp = arrayAppello[j].stato_presenza;
                                let uscitaConverted = null;
                                let ingressoConverted = null;
                                if (arrayAppello[j].orario_uscita_reale != null) {
                                    uscitaConverted = arrayAppello[j].orario_uscita_reale.substring(0, arrayAppello[j].orario_uscita_reale.length - 3);
                                }
                                if (arrayAppello[j].orario_ingresso_reale != null) {
                                    ingressoConverted = arrayAppello[j].orario_ingresso_reale.substring(0, arrayAppello[j].orario_ingresso_reale.length - 3)
                                }

                                let objPresenza = {
                                    id: arrayAppello[j].id,
                                    data: arrayAppello[j].data,
                                    statoPresenza: statoPresenzaTmp,
                                    note: arrayAppello[j].note,
                                    orario_uscita_reale: uscitaConverted,
                                    orario_ingresso_reale: ingressoConverted,
                                    validata: arrayAppello[j].validata
                                }
                                alunniTmp[i].presenze.push(objPresenza)
                            }
                        }
                    }
                    //Se sto qua dentro vuol dire che l'appello è stato fatto, quindi se manca un alunno lo segno come ND (Non definito)

                    for (let i = 0; i < arrayGiorniTmp.length; i++) {
                        let numeroPresenzeValidate = 0;
                        let numeroAlunniIscritti = 0;

                        for (let alunno of alunniTmp) {
                            for (let j = 0; j < alunno.presenze.length; j++) {
                                let dataConvertedSql = convertToSqlFormat(alunno.presenze[j]?.data);
                                if (arrayGiorniTmp[i].data === dataConvertedSql && alunno.presenze[j]?.validata) {
                                    numeroPresenzeValidate++;
                                }

                            }

                            if (isAlunnoIscrittoInData(arrayGiorniTmp[i]?.data, alunno))
                                numeroAlunniIscritti++
                        }
                        arrayGiorniTmp[i].nValidate = numeroPresenzeValidate;
                        arrayGiorniTmp[i].nIscritti = numeroAlunniIscritti;

                    }
                }
                setListAlunni([...alunniTmp]);
                setHasLoaded(true);
                setArrayGiorni([...arrayGiorniTmp])

            })
            .catch((error) => {
                if (error.response.status === 403) props.logout();
                else {
                    console.error("Errore durante il get delle presenze di sintesi")

                }
            })
    }

    return (
        <Fragment>

            {(idClasseSelezionata !== -1) ? (
                <Fragment>
                    <Box sx={{ px: 1, mt: 10 }}>
                        <Breadcrumb disableGutters={true} sections={["Validazione presenze", nomeClasse + ' (' + sedeUtente + ')', 'Vista di sintesi (' + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + " )"]} />

                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                            <Button onClick={goToSelezionaCorso} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                Torna alla selezione della classe
                            </Button>
                            <Button sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                Cambia periodo di interesse ({formattedPeriodoDiInteresseInizio}
                                {formattedPeriodoDiInteresseFine != null && formattedPeriodoDiInteresseFine !== formattedPeriodoDiInteresseInizio ? (
                                    " - " + formattedPeriodoDiInteresseFine
                                ) : ""})
                            </Button>
                        </Box>
                        <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '70vh' }, display: "flex", justifyContent: 'center' }}>
                            {arrayGiorni.length > 0 ? (
                                <TableContainer sx={{ width: '100%' }} component={Paper}>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='colonna-nomi row-head' sx={{ p: 0, zIndex: 99 }}>
                                                    <Box sx={{ backgroundColor: 'white', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', minWidth: '120px', minHeight: '100px' }}></Box>
                                                </TableCell>
                                                {arrayGiorni.map((giorno) => (
                                                    <TableCell key={"giorno_" + giorno?.data} className='row-head' sx={{ textAlign: 'center', minWidth: '120px', p: 0 }}>
                                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{ backgroundColor: 'white', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', height: '100px', px: '2' }}>
                                                            <Typography variant="body1" sx={{ fontWeight: 'bold', }}>{getDayAndMonth(giorno?.data)}</Typography>
                                                            <Typography variant="subtitle2">{getDayOfTheWeek(giorno?.data)}</Typography>
                                                            {giorno?.nValidate === 0 && (
                                                                <Typography variant="subtitle2">Non validata</Typography>
                                                            )}
                                                            {giorno?.nValidate === giorno?.nIscritti && (
                                                                <Typography variant="subtitle2" color="primary"> Giornata validata</Typography>
                                                            )}
                                                            {giorno?.nValidate < giorno?.nIscritti && giorno?.nValidate > 0 && (
                                                                <Typography variant="subtitle2">{giorno?.nValidate + "/" + giorno?.nIscritti} presenze validate</Typography>
                                                            )}
                                                            {giorno?.chiusura ? (
                                                                <Typography color="primary" variant="subtitle2"><b>CHIUSURA</b></Typography>
                                                            ) : null}
                                                        </Box>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell className="colonna-nomi" sx={{ p: 0 }}>
                                                    <Box sx={{ backgroundColor: 'white', p: 2, display: 'flex', flexDirection: 'column', borderRight: '1px solid rgba(224, 224, 224, 1)', height: '100%' }}>
                                                        <Typography variant="caption">
                                                            Corso:
                                                        </Typography>
                                                        <Typography color={'primary'}
                                                            variant={mobile ? 'caption' : 'body1'}
                                                            sx={{ fontWeight: 'bold', mr: 1 }}>{corso?.codiceCorso + ' - ' + (corso?.nomeCorso ?? "").toUpperCase() + ' (' + corso?.codiceSimon + ')'}</Typography>
                                                        <Typography variant="caption" mt={2}>
                                                            Sede:
                                                        </Typography>
                                                        <Typography
                                                            variant={mobile ? 'caption' : 'subtitle2'}
                                                            sx={{ fontWeight: 'bold', mr: 1 }}>{sedeUtente !== '' ? sedeUtente : ''}</Typography>
                                                    </Box>

                                                </TableCell>
                                                {arrayGiorni.map((giorno) => (
                                                    <TableCell className={giorno?.chiusura ? 'disabledCell' : ""} key={"dettaglio_" + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <Fragment>
                                                            <Box justifyContent={"center"} alignItems={"center"} display="flex" flexDirection={"column"} >
                                                                {!giorno?.chiusura ? (
                                                                    <Box onClick={() => openDettaglioRegistro(giorno.data)} width={"30px"} height={"30px"}
                                                                        border={"1px solid #1976d2"}
                                                                        borderColor={"primary"}
                                                                        backgroundColor={"white"}
                                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                        <ZoomIn />
                                                                    </Box>) : null}
                                                                <Box sx={{ mt: 2 }} justifyContent={"center"} alignItems={"center"} display="flex" >

                                                                </Box>
                                                            </Box>
                                                        </Fragment>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            {listAlunni.map((alunno, index) => (
                                                <TableRow key={alunno.id} >
                                                    <TableCell
                                                        className="colonna-nomi"
                                                        sx={{ p: 0 }}>
                                                        <Box justifyContent={"center"} alignItems={"center"} display="flex" flexDirection={"column"} sx={{ backgroundColor: 'white', p: 4, borderRight: '1px solid rgba(224, 224, 224, 1)', minHeight: '70px' }}>
                                                            <Box display={"flex"} alignItems={"center"}>
                                                                <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                                                <Typography variant={mobile ? 'caption' : 'body1'} sx={{ mr: 2, fontWeight: alunno.isMaggiorenne ? 'bold' : 'unset' }}>
                                                                    {(alunno.cognome).toUpperCase() + " " + (alunno.nome).toUpperCase()}
                                                                </Typography>
                                                            </Box>
                                                            {alunno.msgIscrizione != null ? (
                                                                <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                            ) : null}
                                                        </Box>
                                                    </TableCell>
                                                    {arrayGiorni.map((giorno, indexGiorno) => (
                                                        <TableCell className={(isAlunnoIscrittoInData(giorno?.data, alunno) === false) || (giorno?.chiusura) ? 'disabledCell' : ""} key={"alunno_" + alunno?.id + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {isAlunnoIscrittoInData(giorno?.data, alunno) === false ? (
                                                                <span></span>
                                                            ) : (
                                                                <Box justifyContent={"center"} display="flex" key={indexGiorno}>
                                                                    {alunno.presenze !== undefined && (
                                                                        alunno?.presenze.map((presenza, index) => (
                                                                            presenza?.data === giorno?.dataIso ? (
                                                                                <Box key={index}>
                                                                                    <Presenza key={"pres_" + index} presenza={presenza} isRegistroDettaglio={false} />
                                                                                    <Typography variant="subtitle2" sx={{ mt: 1, textAlign: 'center', fontWeight: !presenza?.validata ? 'bold' : 'normal', color: !presenza?.validata ? 'black' : '#1976d2' }}>{presenza?.validata ? "VALIDATA" : "NON VALIDATA"}</Typography>
                                                                                </Box>
                                                                            ) : null))
                                                                    )}
                                                                </Box>
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            ) : (
                                <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography variant="h5">Per il periodo selezionato (<b>{(formattedPeriodoDiInteresseInizio)} - {(formattedPeriodoDiInteresseFine)}</b>) non è possibile visualizzare il registro di sintesi</Typography>
                                    <Button sx={{ mt: 2 }} variant="contained" onClick={openPeriodoDiInteresseDialog} startIcon={<EditCalendar />}>
                                        Cambia periodo di interesse
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Fragment>
            ) : null}

            <Dialog fullScreen open={!hasLoaded}>
                <DialogContent>
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento presenze...</Typography>
                    </Box>
                </DialogContent>
            </Dialog>


            <Dialog open={changePeriodoDiInteresseOpen} >
                {/* sx = {{zIndex: 3001 }} */}

                <DialogTitle  variant="h6">
                      Seleziona il periodo di interesse
                
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <RangeDate idClasse={idClasseSelezionata} dataDiInteresseInizio={periodoDiInteresseInizio} dataDiInteresseFine={periodoDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog fullScreen sx={{ marginTop: '7vh' }} open={validazionePresenzeOpen} >
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant="h6">
                        <b> Validazione presenze</b>
                    </Typography>
                    <IconButton onClick={closeModal} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton> 
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DettaglioValidazione presAssValidate={presAssValidate} idDocente={idDocente} closeModal={closeModal} corso={corso} listAlunni={listAlunni} dataRichiesta={dataRichiestaDettaglio} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Validazione presenze</title>
            </Helmet>
        </Fragment>
    )
}