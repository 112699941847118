// import './App.css';
import '../../App.css';
import { Fragment, useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { JSONTree } from 'react-json-tree';
import { axiosCalls } from '../../api/configs/axiosCalls';

export default function DebugApiInterceptor(props) {

    const [isDialogDataOpen, setIsDialogDataOpen] = useState(false)
    const [data, setData] = useState({})
    const theme = {
        scheme: 'monokai',
        author: 'wimer hazenberg (http://www.monokai.nl)',
        base00: '#272822',
        base01: '#383830',
        base02: '#49483e',
        base03: '#75715e',
        base04: '#a59f85',
        base05: '#f8f8f2',
        base06: '#f5f4f1',
        base07: '#f9f8f5',
        base08: '#f92672',
        base09: '#fd971f',
        base0A: '#f4bf75',
        base0B: '#a6e22e',
        base0C: '#a1efe4',
        base0D: '#66d9ef',
        base0E: '#ae81ff',
        base0F: '#cc6633',
    };
    function openDialogData(title, data1, data2) {
        setIsDialogDataOpen(true)
        let dataParsed = data1 !== undefined ? JSON.parse(data1) : {}
        let objTmp = {
            title: title,
            data1: dataParsed,
            data2: data2
        }
        setData(objTmp)
        // if (props.openDialogData) props.openDialogData(title, data1, data2)
    }

    function closeDialogData() {
        setIsDialogDataOpen(false)
    }

    return (
        <Fragment>
            <TableContainer sx={{ width: '100%', height: '70vh' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="row-head"
                                sx={{ zIndex: 7, width: '20px' }}
                            >
                                <Box sx={{ backgroundColor: 'white', py: 1 }}>
                                    <Typography variant="body2"
                                        sx={{ fontWeight: "bold" }}>Stato</Typography>
                                </Box>
                            </TableCell>
                            <TableCell
                                className=" row-head"
                                sx={{ zIndex: 7, width: '20px' }}
                            >
                                <Box sx={{ backgroundColor: 'white', py: 1 }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Metodo
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell
                                className=" row-head"
                                sx={{ zIndex: 7, }}
                            >
                                <Box sx={{ backgroundColor: 'white', py: 1 }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        URL
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell className=" row-head" sx={{ zIndex: 7, }} >
                                <Box sx={{ backgroundColor: 'white', py: 1 }}>
                                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                        Body/Headers
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell className=" row-head" sx={{ zIndex: 7, }}>
                                <Box sx={{ backgroundColor: 'white', py: 1 }}>
                                    <Typography variant="body2" sx={{ fontWeight: "bold" }} >
                                        Data
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {axiosCalls.map((call, index) => (<TableRow key={index + "call"}>
                            <TableCell sx={{ p: 0 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box sx={{ backgroundColor: (call?.response?.status == '200' || call?.response?.status == '204') ? 'green' : 'red', borderRadius: '5px', display: 'flex', justifyContent: 'center', width: '50%' }}>
                                        <Typography
                                            variant="caption"
                                            color={'white'}
                                        >
                                            {call?.response?.status}
                                        </Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ p: 0 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography
                                        variant="caption"
                                        sx={{ pl: 1 }}
                                    >
                                        {call?.request?.method}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ p: 0 }}>
                                <Typography
                                    variant="caption"
                                    sx={{ pl: 1 }}
                                >
                                    {call?.request?.url}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0 }}>
                                <Button size='small' variant='text' onClick={() => openDialogData(call?.request?.method === 'post' ? 'Body/Headers' : 'Headers', call?.request?.headers, call?.request?.data)}>{call?.request?.method === 'post' ? 'Body/Headers' : 'headers'}</Button>
                            </TableCell>
                            <TableCell sx={{ p: 0 }}>
                                <Button size='small' variant='text' onClick={() => openDialogData((call?.response?.status == '200' || call?.response?.status == '204') ? 'Data' : 'Error', JSON.stringify(call?.response?.data))}>{(call?.response?.status == '200' || call?.response?.status == '204') ? 'data' : 'error'}</Button>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog fullWidth maxWidth={"xl"} open={isDialogDataOpen} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <DialogTitle>{data?.title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', minHeight: '40vh' }}>
                        <Box>
                            {(data?.data2 !== null && data?.data2 !== undefined) && (<Typography variant="caption" sx={{ fontWeight: "bold" }}>Body:</Typography>)}
                            <Typography variant="caption" sx={{ wordWrap: 'break-word' }}>{data?.data2}</Typography>
                        </Box>
                        <Box>
                            {(data?.title === 'Headers' || data?.title === 'Body/Headers') ? (<Typography variant="caption" sx={{ fontWeight: "bold", mt: 1 }}>Headers:</Typography>)
                                : (<Typography variant="caption" sx={{ fontWeight: "bold", mt: 1 }}>{data?.title}:</Typography>)}
                            <JSONTree theme={theme} invertTheme={false} hideRoot={true} data={data?.data1} />
                            {/* <Typography variant="caption" sx={{ wordWrap: 'break-word' }}>{data?.data1}</Typography> */}
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"end"} sx={{ mt: 3 }}>
                        <Button onClick={closeDialogData} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">chiudi</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>

    )
}