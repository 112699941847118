import { useState, useEffect } from "react"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, Switch } from '@mui/material';
import { convertOra, convertToItalianTime } from "../../shared/metodiDateUtility";
import PresenzaTirocini from "../presenzaTirocini";

export default function RowPresenzaTutor(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [visita, setVisita] = useState(null);
    const [indexVisita, setIndexVisita] = useState(-1);
    const [isValidata, setIsValidata] = useState(false);
    const [isAnnullata, setIsAnnullata] = useState(false);

    useEffect(() => {
        if (props.visita !== undefined) {
            setVisita(props.visita);
            if (props.visita.canEdit === false) {
                setIsValidata(props?.visita?.validata ?? false)
                setIsAnnullata(props?.visita?.annullata ?? false)
            } else {
                setIsValidata(false);
                setIsAnnullata(false)
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 200)
        }
    }, [props.visita])


    useEffect(() => {
        if (props.indexAllValidate) { //Un index che si aggiorna ogni volta che clicco il pulsante "valida tutto"
            setVisita(props.visita);
        }
    }, [props.indexAllValidate])

    useEffect(() => {
        if (props.indexVisita !== undefined) setIndexVisita(props.indexVisita)
    }, [props.indexVisita])

    function openDettaglioAlunno(alunno) {
        if (props.openDettaglioAlunno) props.openDettaglioAlunno(alunno);
    }

    function viewDettaglioVisita(presenza) {
        if (presenza !== undefined && presenza != null) {
            if (props.viewDettaglioVisita) props.viewDettaglioVisita(presenza);

        }
    }

    function changeValidazione(e) {
        let visitaTmp = { ...visita };
        visitaTmp.validata = !visitaTmp.validata;
        visitaTmp.annullata = false;
        visitaTmp.isModified = true;  //TODO: delete v["isModified"] eliminare il isModified quando visitaTmp.validata === false
        setVisita(visitaTmp)
        if (props.modificaValidazioneAlunni)
            props.modificaValidazioneAlunni(visitaTmp, indexVisita);
    }

    function changeAnnullamento(e) {
        let visitaTmp = { ...visita };
        visitaTmp.annullata = !visitaTmp.annullata;
        visitaTmp.isModified = true;  //TODO: delete v["isModified"] eliminare il isModified quando visitaTmp.validata === false
        setVisita(visitaTmp)
        if (props.modificaValidazioneAlunni)
            props.modificaValidazioneAlunni(visitaTmp, indexVisita);
    }

    return (
        <TableRow style={{ height: '40px' }} key={visita?.idVisita + '_visita'}>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Box sx={{ backgroundColor: 'white', px: 2, py: 2 }}>
                        <Typography variant="body2" textAlign={'center'}>  {convertToItalianTime(visita?.data)}</Typography>
                    </Box>
                ) : (
                    <Skeleton sx={{ mx: 2 }} />
                )}
            </TableCell>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Box sx={{ backgroundColor: 'white', px: 2, py: 2 }}>
                        <Typography variant="body2" textAlign={'center'}>  {convertOra(visita?.orarioInizio)}</Typography>
                    </Box>
                ) : (
                    <Skeleton sx={{ mx: 2 }} />
                )}
            </TableCell>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Box sx={{ backgroundColor: 'white', px: 2, py: 2 }}>
                        <Typography variant="body2" textAlign={'center'}>  {convertOra(visita?.orarioFine)}</Typography>
                    </Box>
                ) : (
                    <Skeleton sx={{ mx: 2 }} />
                )}
            </TableCell>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Typography onClick={() => openDettaglioAlunno(visita?.alunno)} variant={mobile ? 'caption' : 'body2'} textAlign={'center'} sx={{ cursor: 'pointer', mr: 2, fontWeight: visita?.alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                        {(visita?.alunno?.cognome).toUpperCase() + " " + (visita?.alunno?.nome).toUpperCase()}
                    </Typography>
                ) : (
                    <Skeleton sx={{ mx: 2 }} />
                )}
            </TableCell>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Box sx={{ backgroundColor: 'white', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ cursor: (visita?.presenzaAlunno !== undefined && visita?.presenzaAlunno !== null) ? 'pointer' : 'null' }} onClick={() => viewDettaglioVisita(visita?.presenzaAlunno)}>
                            <PresenzaTirocini isSmallSize={true} presenza={visita?.presenzaAlunno} />
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent={"center"}>
                        <Skeleton variant="circular" width={40} height={40} sx={{ mx: 2 }} />
                    </Box>
                )}
            </TableCell>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: 'white', px: 2, py: 2 }}>
                        <Typography variant="body2" textAlign={'center'} color={visita?.validata ? 'primary' : 'unset'}>  {visita?.validata ? 'SI' : 'NO'}</Typography>
                        <Switch checked={visita?.validata} disabled={isValidata || visita?.annullata} onChange={changeValidazione} />
                    </Box>
                ) : (
                    <Skeleton sx={{ mx: 2 }} />
                )}
            </TableCell>
            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {!isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: 'white', px: 2, py: 2 }}>
                        <Typography variant="body2" textAlign={'center'} color={visita?.annullata ? 'primary' : 'unset'}>  {visita?.annullata ? 'SI' : 'NO'}</Typography>
                        <Switch checked={visita?.annullata} disabled={isAnnullata || visita?.validata} onChange={changeAnnullamento} />
                    </Box>
                ) : (
                    <Skeleton sx={{ mx: 2 }} />
                )}
            </TableCell>
        </TableRow>
    )
}