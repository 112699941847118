import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";

export default function DialogNoItemInSessionStorage (props){
 

    const navigate = useNavigate();
    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);


    useEffect(() => {
        setAlertNoIdIsOpen(props.alertNoIdIsOpen);
     }, [props.alertNoIdIsOpen])
 
    function goToRegistroDocente() {
        navigate("/registro-del-docente");
    }
    function goToRegistroClasse() {
        navigate("/registro-di-classe");
    }

    return(
        <Dialog open={alertNoIdIsOpen}>
            <DialogTitle>
                {"Errore durante la visualizzazione della pagina"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Al momento non puoi visualizzare questa pagina. Devi prima selezionare un corso dal registro (di classe o docente).
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3, justifyContent: 'space-between' }}>
                <Button variant="outlined" onClick={goToRegistroDocente} autoFocus>
                    Vai al registro docente
                </Button>
                <Button variant="outlined" onClick={goToRegistroClasse} autoFocus>
                    Vai al registro di classe
                </Button>
            </DialogActions>
        </Dialog>
    )
}