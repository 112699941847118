import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

//{ sendToken: true } sulle chiamate che vogliono l'autenticazione

export const PresenzeAPI = {
    getPresenzeSintesiValidazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/classeSintesiCompleto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    validaPresenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/valida`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPresenzeGiornaliere: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/classeGiornaliere`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPresenzeSintesi: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/classeSintesi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    modificaPresenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/modifica`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    inserisciPresenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/inserisci`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    aggiungiRitardoUscita: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/aggiorna`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    checkAggiorna: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/checkAssenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    checkAssenza: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/checkAssenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    inserisciGiustificazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/giustificazioni/inserisci`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    validaGiustificazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/giustificazioni/valida`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDettaglioAssenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/getDettaglioAssenze`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getAssenzeNonGiustificate: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/getNumeroAssenzeNonGiustificate`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getAssenzeGiustificate: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/getNumeroAssenzeGiustificate`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPresenzeParziali: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/elencoPresenzeParziali`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deletePresenzeParziali: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/eliminaPresenzeParziali`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deletePresenzeParziali: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/presenze/eliminaPresenzeParziali`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getSintesiMonitoraggioFirme: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioFirme/sintesi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDettaglioMonitoraggioFirme: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioFirme/dettaglio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    firmaOraMonitoraggioFirme: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioFirme/firmaOra`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getSintesiMonitoraggioAppello: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioAppello/sintesi`,
            method: "POST", 
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDettaglioMonitoraggioAppello: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioAppello/dettaglio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getOreDuplicate: async function (obj,cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioFirme/getOreDuplicate`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deleteFirma: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/monitoraggioFirme/deleteFirma`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
   
}
const cancelApiObject = defineCancelApiObject(PresenzeAPI)