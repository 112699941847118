import * as React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { CircularProgress, Link, Paper, Button, FormControl, Container, Select, Typography, Breadcrumbs, Snackbar, Alert } from '@mui/material';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

import 'react-virtualized/styles.css';
import { RicercaAPI } from '../api/ricercaApi';
import { DateTime } from 'luxon';
import CryptoJS from 'crypto-js';
import { UtilityAPI } from '../api/utilityApi';
import { Download } from '@mui/icons-material';
import axios from 'axios';
import Breadcrumb from './registri/shared/breadcrumb';
import { getAnnoFormativo } from './shared/utilityMethods';

export default function ExportExAnte(props) {

    const { t, i18n } = useTranslation();
    const regExCf = /^[A-Z]{6}\d{2}[A-EHLMPRST]\d{2}([A-Z]\d{3})[A-Z]$/;


    const inputNomeAlunnoRef = useRef(null);
    const inputCfRef = useRef(null);

    const [isBesPage, setIsBesPage] = useState(false);

    const [isDialog, setIsDialog] = useState(false);


    const [sediList, setSediList] = useState([]);
    const [classiList, setClassiList] = useState([]);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [classeSelected, setClasseSelected] = useState(-1);


    const [documentiList, setDocumentiList] = useState([]);
    const [documentoSelected, setDocumentoSelected] = useState(-1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [annoFormativo, setAnnoFormativo] = useState('');

    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [permessiUtente, setPermessiUtente] = useState([]);

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);
        }
    }, [])







    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
            setClasseSelected(-1);
            getClassiBySede(e.target.value, annoFormativo);
        }
        else {
            setSedeSelected(-1);
            setClasseSelected(-1);
        }
    };

    function changeClasse(e) {
        if (e.target.value !== '') {
            setClasseSelected(e.target.value);
            getDocumentiExportExAnte();
        }
        else {
            setClasseSelected(-1);
        }
    };

    function changeDocumento(e) {
        if (e.target.value !== '') {
            setDocumentoSelected(e.target.value)
        } else {
            setDocumentoSelected(-1)
        }
    }


    async function getSedi(annoFormativo) {

        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setSediList(result.sedi)
                if (result.sedi.length === 1) {
                    setSedeSelected(result.sedi[0].id);
                    getClassiBySede(result.sedi[0].id, annoFormativo);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }


            })

    }

    async function getClassiBySede(idSede, annoFormativo) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setClassiList(result.sedi)
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    async function getDocumentiExportExAnte() {


        UtilityAPI.getDocumentiExportExAnte()
            .then((result) => {
                setDocumentiList([...result])
            })
            .catch(function (error) { })
    }

    async function exportExAnte() {
        let dataObj = {
            idCorso: classeSelected,
            idDocumento: documentoSelected
        }

        axios.post(process.env.REACT_APP_FETCH_URL + "/exportPDF/CSV", dataObj, { responseType: 'blob' })
            .then((response) => {
                setAlertMsg("File scaricato correttamente")
                setAlertSeverity("success")
                setAlertOpen(true)

                let contentDisposition = response.headers['content-disposition'];
                let filename = "";
                if (contentDisposition) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                exportFile(response.data, filename, 'text/csv;charset=utf-8;');
            })
            .catch((error) => {
                setAlertMsg("Errore durante il download del file")
                setAlertSeverity("error")
                setAlertOpen(true)
            });
    }

    const exportFile = (data, fileName, type) => {
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };


    return (
        <Fragment>

            {!isInsideOtherComponent && !isDialog ? (
                <Breadcrumb sections={["Export ex ante"]} />
            ) : null}


            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Grid container sx={{ width: '100%', pt: 2 }}>
                    <Grid xs={12} md={3} sx={{ pr: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="sede-label">
                                Sede
                            </InputLabel>
                            <Select
                                data-testid='sedeRicercaSelect'
                                label="sede"
                                labelId="sede-label"
                                value={sedeSelected}
                                onChange={changeSede}
                            >
                                <MenuItem
                                    key={-1}
                                    value={-1}
                                >
                                </MenuItem>
                                {sediList.map((sede) => (
                                    <MenuItem
                                        key={sede.id}
                                        value={sede.id}
                                    >
                                        {sede.nomeSede}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6} sx={{ pr: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="corso-label">Corso</InputLabel>
                            <Select
                                disabled={sedeSelected !== -1 || classiList.length !== 0 ? false : true}
                                label="Corso"
                                labelId="corso-label"
                                value={classeSelected}
                                onChange={changeClasse}
                            >
                                <MenuItem
                                    key={-1}
                                    value={-1}
                                >
                                </MenuItem>
                                {classiList.map((classe) => (
                                    <MenuItem style={{ whiteSpace: 'normal' }} key={classe.id} value={classe.id} sx={{ wordBreak: 'break-all' }}>
                                        {classe.nomeCorso}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={3} sx={{ mt: { xs: 2, md: 0 } }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="classe-label">Tipo documento</InputLabel>
                            <Select
                                disabled={documentiList.length === 0 || classeSelected === -1}
                                label="Tipo documento"
                                value={documentoSelected}
                                onChange={changeDocumento}
                            >
                                <MenuItem
                                    key={-1}
                                    value={-1}
                                >
                                </MenuItem>
                                {documentiList.map((documento) => (
                                    <MenuItem style={{ whiteSpace: 'normal' }} key={documento.id} value={documento.id} sx={{ wordBreak: 'break-all' }}>
                                        {documento.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={12} display={"flex"} alignItems={"center"} justifyContent={"end"} sx={{ mt: 2 }}>
                        <Button disabled={documentoSelected === -1 || classeSelected === -1 || sedeSelected === -1} sx={{ width: '100%' }} startIcon={<Download />} color="success" variant="contained" onClick={exportExAnte}>
                            Esporta
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertOpen} autoHideDuration={1500} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    );
}
