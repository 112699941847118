import { Fragment, useState, useEffect } from "react"
import { Dialog, DialogTitle, DialogContent, Divider, Alert, Box, Button, CircularProgress, Table, TableBody, TableCell, TableRow, Typography, TableHead, useMediaQuery, useTheme, Snackbar } from "@mui/material";
import { getAnnoFormativo } from "../shared/utilityMethods";
import SelectPercorsiIstruzione from "./selectPercosiIstruzione";
import Breadcrumb from "../registri/shared/breadcrumb";
import RowMateriaCreditiFormativi from "./rowMateriaCreditiFormativi";
import DialogConferma from "../shared/dialogConferma";
import { CheckCircleOutline, Save } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ScrutiniAPI } from "../../api/scrutiniApi";

export default function GestioneCreditiFormativi(props) {

    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);

    const [isModalPercosiIstruzioneOpen, setIsModalPercosiIstruzioneOpen] = useState(true);
    const [annoFormativo, setAnnoFormativo] = useState(null);
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);
    const [idPercorsoFormativoSelezionato, setIdPercorsoAnnoFormativoSelezionato] = useState(-1);
    const [nomePercorsoSelezionato, setNomePercorsoSelezionato] = useState("");
    const [listMaterie, setListMaterie] = useState([]);

    const [confermaCompilazioneUfcOpen, setConfermaCompilazioneUfcOpen] = useState(false);
    const [confermaSalvataggioOpen, setConfermaSalvataggioOpen] = useState(false);

    const [indexSalvataggio, setIndexSalvataggio] = useState(0);
    const [errorMsg, setErrorMsg] = useState("Errore durante il recupero dei dati. Riprovare tra qualche minuto.")

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("Modifiche salvate correttamente");

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo(true);
        let annoFormativoParsed = JSON.parse(annoFormativoTmp)

        setAnnoFormativo((annoFormativoParsed.id).toString());
        setIsAnnoFormativoCorrente(annoFormativoParsed?.isAnnoFormativoCorrente ?? true);
    }, [])



    function openDialogPercorsoFormativo() {
        setIsModalPercosiIstruzioneOpen(true);
    }

    function materiaChanged(materiaTmp) {
        let listMaterieTmp = listMaterie;
        let indexMateriaChanged = listMaterieTmp.findIndex(m => m.idMateria === materiaTmp.idMateria);
        if (indexMateriaChanged !== -1) {
            listMaterieTmp[indexMateriaChanged] = materiaTmp;
            setListMaterie([...listMaterieTmp])
        }
    }

    function saveModifiche() {
        setIndexSalvataggio(prev => prev + 1);
        saveMateriePercorsoIstruzione();
    }

    function goHome(){
        navigate("/");
    }


    async function getMateriePercorsoFormazione(idPercorsoSelezionato, nomePercorso) {
        setIdPercorsoAnnoFormativoSelezionato(idPercorsoSelezionato);
        setNomePercorsoSelezionato(nomePercorso);
        setIsModalPercosiIstruzioneOpen(false);
        setIsLoading(true);

        let dataObj = {
            annoFormativo: annoFormativo,
            idPercorsoFormazione: idPercorsoSelezionato
        }
        ScrutiniAPI.getMateriePercorsoFormazione(dataObj)
            .then((result) => {
                if (showError === true) setShowError(false);
                setListMaterie([...result]);
                setIsLoading(false);

            })
            .catch(function (error) {
                let errorMsgTmp = error?.response?.data?.data[0]?.msg ?? "Errore durante il recupero dei dati. Riprovare tra qualche minuto."
                setErrorMsg(errorMsgTmp)

                setIsLoading(false);
                setShowError(true);
            })
    }
    async function saveMateriePercorsoIstruzione(listMaterieTmp) {

        let listMaterieToPass = listMaterieTmp !== undefined ? listMaterieTmp : listMaterie;
        let dataObj = {
            annoFormativo: annoFormativo,
            idPercorsoFormazione: idPercorsoFormativoSelezionato,
            materie: listMaterieToPass
        }
        ScrutiniAPI.saveMateriePercorsoIstruzione(dataObj)
            .then((result) => {
                setConfermaSalvataggioOpen(false);
                setAlertSeverity("success");
                setAlertMsg("Modifiche salvate correttamente")
                setIsAlertOpen(true);
            })
            .catch(function (error) {
                setConfermaSalvataggioOpen(false);
                setAlertSeverity("error");
                setAlertMsg("Errore durante il salvataggio delle modifiche")
                setIsAlertOpen(true);
            })
    }
    async function getOldUFC() {
        let dataObj = {
            annoFormativo: annoFormativo,
            idPercorsoFormazione: idPercorsoFormativoSelezionato
        }
        ScrutiniAPI.getOldUfc(dataObj)
            .then((result) => {
                let listMaterieTmp = listMaterie;
                for (let r of result) {
                    let index = listMaterieTmp.findIndex(m => m.idMateria === r.idMateria);
                    if (index !== -1) {
                        listMaterieTmp[index].UFC = r.UFC;
                    }
                }
                setListMaterie([...listMaterieTmp]);
                //setAlertSeverity("success");
                //setAlertMsg("Gli UFC delle materie sono stati precompilati con i dati dell'anno precedente.");
                //setIsAlertOpen(true);
                setConfermaCompilazioneUfcOpen(false);
                saveMateriePercorsoIstruzione(listMaterieTmp, true);
            })
            .catch(function (error) {
                setConfermaCompilazioneUfcOpen(false);
                setAlertSeverity("error");
                let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il recupero dei dati."
                setAlertMsg(errorMsg)
                setIsAlertOpen(true);
            })
    }
    return (
        <Fragment>
            <Breadcrumb sections={["Gestione crediti formativi", nomePercorsoSelezionato]} />
            <Box sx={{ px: 2 }}>
                {!showError && (
                    <Box>
                        <Button disabled={isLoading} variant="contained" color="primary" onClick={openDialogPercorsoFormativo}>
                            Cambia percorso formativo
                        </Button>
                        <Button disabled={isLoading} sx={{ ml: 1 }} variant="contained" color="success" onClick={() => setConfermaSalvataggioOpen(true)}>
                            Salva modifiche
                        </Button>
                        {isAnnoFormativoCorrente && (
                            <Button disabled={isLoading} sx={{ ml: 1 }} variant="contained" color="secondary" onClick={() => setConfermaCompilazioneUfcOpen(true)}>
                                Precompila UFC con i dati dell'anno passato
                            </Button>
                        )}
                    </Box>)}
                {isLoading ? (
                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                        <CircularProgress sx={{ mt: 5 }} />
                        <Typography sx={{ mt: 3, width: 'fit-content' }}>
                            Caricamento in corso...
                        </Typography>
                    </Box>
                ) : (
                    showError ? (
                        <Box sx={{ height: '70vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Alert sx={{ mt: 2 }} severity="error">{errorMsg}</Alert>
                            <Button variant="contained" sx={{ mt: 2 }} onClick={openDialogPercorsoFormativo}>Torna alla selezione del corso</Button>
                        </Box>
                    ) : (
                        <Box sx={{ mt: 3, mb: 3, width: '100%', overflowY: 'scroll', maxHeight: { xs: 'unset', md: '75vh' }, border: '1px solid #eaeaea', borderRadius: '15px' }}>
                            <Table sx={{ height: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='row-head' sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                            <Typography variant={isMobile ? 'caption' : 'body2'}><b>Materia</b></Typography>
                                        </TableCell>
                                        <TableCell align="center" className='row-head' sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                            <Typography variant={isMobile ? 'caption' : 'body2'}><b>UFC</b></Typography>
                                        </TableCell>
                                        <TableCell align="center" className='row-head' sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                            <Typography variant={isMobile ? 'caption' : 'body2'}><b>Livello credito minimo</b></Typography>
                                        </TableCell>
                                        <TableCell align="center" className='row-head' sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                            <Typography variant={isMobile ? 'caption' : 'body2'}><b>Livello credito medio</b></Typography>
                                        </TableCell>
                                        <TableCell align="center" className='row-head' sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                            <Typography variant={isMobile ? 'caption' : 'body2'}><b>Livello credito massimo</b></Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listMaterie.map((materia) => (
                                        <RowMateriaCreditiFormativi indexSalvataggio={indexSalvataggio} materia={materia} materiaChanged={materiaChanged} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )
                )}
            </Box>
            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isModalPercosiIstruzioneOpen}>
                <DialogTitle variant="h6">
                    <b>Seleziona percorso formativo</b>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <SelectPercorsiIstruzione annoFormativo={annoFormativo} confermaPercorso={getMateriePercorsoFormazione} goHome={goHome}/>
                </DialogContent>
            </Dialog>

            <DialogConferma
                isDialogOpen={confermaCompilazioneUfcOpen}
                title={'Conferma precompilazione dati UFC'}
                icon={<CheckCircleOutline sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Accettando di confermare, i campi relativi agli UFC (Unità di Formazione Continua) saranno precompilati utilizzando i dati dell'anno formativo passato. `}
                showAlunni={false}
                confermaButton={'Conferma e procedi'}
                severity={'success'}
                closeDialog={() => { setConfermaCompilazioneUfcOpen(false) }}
                conferma={getOldUFC}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confermaSalvataggioOpen}
                title={'Conferma salvataggio'}
                icon={<Save sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler proseguire con il salvataggio dei dati inseriti?`}
                showAlunni={false}
                confermaButton={'Conferma salvataggio'}
                severity={'success'}
                closeDialog={() => { setConfermaSalvataggioOpen(false) }}
                conferma={saveModifiche}
                disabledButton={false}
            />

            <Snackbar open={isAlertOpen} autoHideDuration={10000} onClose={() => (setIsAlertOpen(false))} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                    onClose={() => (setIsAlertOpen(false))}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}


