import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

export default function SelettoreConvenzione(props) {

    const [idConvenzioneSelected, setIdConvenzioneSelected] = useState(-1);
    const [listConvenzioni, setListConvenzioni] = useState([]);

    useEffect(() => {
        if (props.listConvenzioni !== undefined) setListConvenzioni(props.listConvenzioni)
    }, [props.listConvenzioni])

    useEffect(() => {
        if (props.idConvenzioneSelected !== undefined) setIdConvenzioneSelected(props.idConvenzioneSelected)
    }, [props.idConvenzioneSelected])

    function changeIdConvenzioneSelezionata(idConvenzione) {
        if (props.changeIdConvenzioneSelezionata) props.changeIdConvenzioneSelezionata(idConvenzione)
    }
    return (
        <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
            <Table >
                <TableBody>
                    {listConvenzioni.map((convenzione, index) => (
                        <Fragment key={convenzione?.id + '_azienda'}>
                            {!convenzione?.maxAlunniReached ? (<TableRow sx={{ cursor: 'pointer' }} onClick={() => changeIdConvenzioneSelezionata(convenzione?.id)}>
                                <TableCell sx={{ minWidth: '200px', background: (convenzione?.id === idConvenzioneSelected) ? '#1976D2' : 'white', color: (convenzione?.id === idConvenzioneSelected) ? 'white' : 'black' }}>
                                    <Typography>
                                        {'Convenzione: ' + convenzione?.protocolloConvenzione + ' - Azienda: ' + convenzione?.ragioneSociale + ' - Alunni assegnati: ' + convenzione?.alunniAssegnati + '/' + convenzione?.alunniPrevisti}{(convenzione?.id === idConvenzioneSelected) ? " - SELEZIONATA" : ""}
                                    </Typography>
                                </TableCell>
                            </TableRow>)
                                :
                                (<TableRow key={convenzione?.id + '_azienda'}>
                                    <TableCell sx={{ minWidth: '200px', background: 'grey', color: 'white' }}>
                                        <Typography>
                                            {'Convenzione: ' + convenzione?.protocolloConvenzione + ' - Azienda: ' + convenzione?.ragioneSociale + " - Limite alunni assegnati raggiunto"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>)}
                        </Fragment>
                    )
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}