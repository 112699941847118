import { Box, Dialog, DialogTitle, Link, DialogContent, DialogActions, DialogContentText, Typography, TextField, Button, Container, Snackbar, Alert, FormControl, Select, Breadcrumbs, IconButton } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"

import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";

import CryptoJS from 'crypto-js';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import { Check, Close, Edit } from "@mui/icons-material";
import DialogConferma from "../../shared/dialogConferma";

export default function AggiuntaArgomenti(props) {

    const inputProgrammaRef = useRef(null);

    const [data, setData] = useState(null);
    // const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);
    const [oraSelected, setOraSelected] = useState(-1);
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [nomeClasse, setNomeClasse] = useState("");

    const [titoloProgramma, setTitoloProgramma] = useState("");
    const [testoProgramma, setTestoProgramma] = useState("");
    const [isDialog, setIsDialog] = useState(false);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined && props.alunniSelezionati !== undefined) {

            let dataRichiesta = DateTime.fromSQL(props.dataRichiesta);
            setData(dataRichiesta)

            getOreLezione(dataRichiesta, props.idCorso, props.idDocente, props.idMateria, props.tipoRegistro);
            setIdCorso(props.idCorso)
            setTipoRegistro(props.tipoRegistro)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
        }
    }, [props.idCorso, props.idDocente, props.idMateria, props.dataRichiesta, props.tipoRegistro]);

    useEffect(() => {

        if (isDialog === false) {
            if (sessionStorage.getItem("classe") != null) {
                let encryptedIdCorso = sessionStorage.getItem("classe");
                let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                //TODO: Poi imposterò questo ID
                setAlertNoIdIsOpen(false);

            } else {
                //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
                setAlertNoIdIsOpen(true);
            }
        }
    }, [isDialog])

    useEffect(() => {
        if (props.nomeClasse !== undefined) {
            setNomeClasse(props.nomeClasse);
        }
    }, [props.nomeClasse])



    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, tipoRegistro) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)
        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
        }

        if (tipoRegistro === "docente") dataObj.idMateria = idMateria;

        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                setElencoOre([...prossimeOreTmp]);
                if (prossimeOreTmp.length > 0) {
                    setOraSelected(prossimeOreTmp[0].idOrario);
                    setIdMateria(prossimeOreTmp[0].materia.idMateria)
                }
                else {
                    setOraSelected(-1);
                    // setIdMateria(-1);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                }
                console.error(error)
            })
    }

    async function inserisciArgomentiSvolti() {

        const obj = {
            titolo: titoloProgramma,
            contenuto: testoProgramma,
            idOrario: oraSelected, // int - da definire 
            idClasse: idCorso // int - da definire
        }

        setPulsanteConfermaDisabled(true);

        EventiAPI.insertArgomentiSvolti(obj)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Argomenti svolti aggiunti", "success");
                } else {
                    setTestoProgramma("");

                    if (inputProgrammaRef != null)
                        inputProgrammaRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Argomenti svolti aggiunti");
                    setAlertIsOpen(true);
                }
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");
                setAlertMsg("Al momento non è possibile completare l'operazione");
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setAlertIsOpen(true);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
    }

    function logout() {
        if (props.logout) props.logout()
    }

    function changeTitoloProgramma(e) {
        setTitoloProgramma(e.target.value)
    }
    function changeTestoProgramma(e) {
        setTestoProgramma(e.target.value)
    }



    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria, tipoRegistro);
    }

    function changeOra(e, o) {
        setOraSelected(e.target.value);
        //setIdMateria(o.props.idmateria);
    }

    function resetData() {
        setData(null);
        setOraSelected(-1);
        setElencoOre([]);
    }

    function isButtonSaveDisabled() {
        if (titoloProgramma === "" || testoProgramma === "" || data === null || oraSelected === -1)
            return true
        else return false;
    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">Argomenti svolti</Typography>
                    </Breadcrumbs>) : null}
                {/* <Typography variant="h4" mt={1}>Aggiungi argomenti svolti</Typography> */}
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={4} sx={{ width: '100%', pt: 3 }}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label='Classe'
                                    value={nomeClasse}
                                />
                            </Grid>
                            <Grid xs={12} md={4} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                            minDate={dataInizioPeriodo}
                                            maxDate={DateTime.now() || dataFinePeriodo}
                                            label="Data lezione"
                                            value={data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                    <IconButton onClick={resetData} sx={{ border: '1px rgba(190, 190, 190, 1) solid', borderRadius: '0 4px 4px 0', borderLeft: 'none' }}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            </Grid>

                            <Grid xs={12} md={4} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="materia-label">
                                        {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                        Seleziona ora</InputLabel>
                                    <Select
                                        data-testid='selOraProgrammaSelect'
                                        label='Seleziona ora '
                                        labelId="materia-label"
                                        value={oraSelected}
                                        onChange={changeOra}
                                        disabled={elencoOre.length === 0}
                                    // disabled={!modifica}
                                    >
                                        <MenuItem key={-1} value={-1}>
                                        </MenuItem>
                                        {elencoOre.map((ora) => (
                                            <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta</Typography>) : null}
                            </Grid>
                            <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                <TextField fullWidth sx={{ mt: 1 }} label='Titolo' onChange={changeTitoloProgramma} />
                                <TextField fullWidth sx={{ mt: 1 }} inputRef={inputProgrammaRef} label='Testo'
                                    onChange={changeTestoProgramma} placeholder="Scrivi qui..." multiline rows={4} />
                            </Grid>
                        </Grid>

                        <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                            {isDialog ? (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                            ) : null}
                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isButtonSaveDisabled()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                color="success" variant="contained" >
                                Aggiungi argomenti svolti
                            </Button>
                        </Box>
                    </Fragment>
                ) : null}

            </Container>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta argomenti svolti'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere un argomento svolto.`}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={inserisciArgomentiSvolti}
                disabledButton={false}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}