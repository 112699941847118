import { Box, IconButton,  Typography, TextField, Button, Container, Snackbar, Alert, FormControl, Select } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"

import { DateTime } from "luxon";
import { convertISODate, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { Close, Delete, Edit } from "@mui/icons-material";
import DialogConferma from "../../shared/dialogConferma";
import CustomDatePicker from "../../shared/customDatePicker";

export default function ModificaArgomenti(props) {

    const inputProgrammaRef = useRef(null);

    const [permessiUtente, setPermessiUtente] = useState([]);


    const [data, setData] = useState(null);
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [materiaSelected, setMateriaSelected] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);
    const [listAlunni, setListAlunni] = useState([]);

    const [tipoRegistro, setTipoRegistro] = useState("classe");
    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);

    const [testoProgramma, setTestoProgramma] = useState("");
    const [titoloProgramma, setTitoloProgramma] = useState("");
    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [programmaSel, setProgrammaSel] = useState("");
    const [idProgramma, setIdProgramma] = useState(-1);

    const [modificaMode, setModificaMode] = useState(false);

    const [showPulsanteEdit, setShowPulsanteEdit] = useState(false);
    const [showPulsanteDelete, setShowPulsanteDelete] = useState(false);
    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] = useState(false);
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    const [riepilogo, setRiepilogo] = useState(false);

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
        }
    }, [props.permessiUtente])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined) {
            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
        }
    }, [props.idCorso, props.idDocente, props.idMateria]);

    useEffect(() => {
        if (props.programmaSel != undefined && props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
            setTipoRegistro(props.tipoRegistro)
            getDataProgramma(props.permessiUtente, props.tipoRegistro);
        }
    }, [props.programmaSel, props.permessiUtente, props.tipoRegistro]);


    function getDataProgramma(permessiUtenteTmp, tipoRegistro) {
        setProgrammaSel(props.programmaSel)
        const date = DateTime.fromISO(props.programmaSel?.contenuto.dataLezione);
        setData(date);

        setTestoProgramma(props.programmaSel?.contenuto?.contenuto);
        setTitoloProgramma(props.programmaSel?.contenuto?.titolo);
        setIdProgramma(props.programmaSel?.id);

        let idMateriaTmp = props.programmaSel?.contenuto?.materia?.idMateria;
        let idDocenteTmp = props.programmaSel?.contenuto?.docente?.idDocente;
        let idCorsoTmp = props.programmaSel?.albero_destinatari?.idCorso[0];
        let arrayIdAlunno = props.programmaSel?.albero_destinatari?.idAlunno;
        let idOrarioTmp = props.programmaSel?.contenuto?.idOrario;

        setIdMateria(idMateriaTmp);
        setIdDocente(idDocenteTmp);
        setIdCorso(idCorsoTmp);
        setOraSelected(idOrarioTmp)

        canAccessButtons(props.programmaSel, permessiUtenteTmp)

        //  if (props.programmaSel?.contenuto?.dataLezione != null)
        getOreLezione(date, idCorsoTmp, idDocenteTmp, idMateriaTmp, idOrarioTmp, tipoRegistro);
    }


    // useEffect(() => {

    //     if (isDialog === false) {

    //         if (sessionStorage.getItem("classe") != null) {
    //             let encryptedIdCorso = sessionStorage.getItem("classe");
    //             let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
    //             //TODO: Poi imposterò questo ID
    //             setAlertNoIdIsOpen(false);

    //         } else {
    //             //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
    //             setAlertNoIdIsOpen(true);
    //         }

    //     }
    // }, [isDialog])

    useState(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
            setMaterie([...materie, props.nota.materia])
            setMateriaSelected(props?.nota?.materia.idMateria)
        }
    }, [props.nota]);

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            if (props.listAlunni == null)
                getAlunniByClasseID();
            else {
                setListAlunni([...props.listAlunni]);
                setGetAlunniIsLoading(false);
            }
        }
    }, [props.listAlunni])


    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    function logout() {
        if (props.logout) props.logout()
    }

    function changeTestoProgramma(e) {
        setTestoProgramma(e.target.value)
    }

    function changeTitoloProgramma(e) {
        setTitoloProgramma(e.target.value)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria, oraSelected, tipoRegistro);
    }

    function changeOra(e, o) {
        setOraSelected(e.target.value);
        //setIdMateria(o.props.idmateria);
    }


    function annullaModifiche() {
        getDataProgramma(permessiUtente);
        setModificaMode(false);
    }

    function resetData() {
        setData(null);
        setOraSelected(-1);
        setElencoOre([]);
    }

    function isButtonSaveDisabled() {
        if (titoloProgramma === "" || testoProgramma === "" || data === null || oraSelected === -1)
            return true
        else return false;
    }

    async function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);

            })
    }

    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, idOrario, tipoRegistro) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)
        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
        }

        if (tipoRegistro === "docente") dataObj.idMateria = idMateria;

        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                setElencoOre([...prossimeOreTmp]);
                if (prossimeOreTmp.length > 0) {

                    let index = prossimeOreTmp.findIndex(ora => ora.idOrario === idOrario)
                    if (index !== -1)
                        setOraSelected(prossimeOreTmp[index].idOrario);
                    else setOraSelected(prossimeOreTmp[0].idOrario);
                    //setIdMateria(prossimeOreTmp[0].materia.idMateria)
                    //setOraSelected(idOrario);

                }
                else {
                    setOraSelected(-1);
                    // setIdMateria(-1);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                }
                console.error(error)
            })
    }

    async function modificaProgramma() {

        const obj = {
            titolo: titoloProgramma,
            contenuto: testoProgramma,
            idOrario: oraSelected, // int - da definire 
            idClasse: idCorso // int - da definire
        }

        EventiAPI.modificaArgomentiSvolti(obj, idProgramma)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Argomenti svolti modificati", "success");
                } else {
                    setTestoProgramma("");

                    if (inputProgrammaRef != null)
                        inputProgrammaRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Argomenti svolti eliminati");
                    setAlertIsOpen(true);
                }
                setConfirmAlertIsOpen(false);
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");
                setAlertMsg("Al momento non è possibile completare l'operazione");
                setAlertIsOpen(true);
            })
    }

    async function canAccessButtons(objEvento, permessiUtenteTmp) {
        let idEventoTmp = objEvento?.id;
        let isEditable = objEvento?.contenuto?.editable;

        let dataObj = {
            idEvento: idEventoTmp
        }
        if (idEventoTmp !== -1 && isEditable && permessiUtenteTmp.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) {
            RegistroAPI.showPulsantiEvento(dataObj)
                .then((result) => {

                    setShowPulsanteEdit(result?.showPulsanteEdit ?? false)
                    setShowPulsanteDelete(result?.showPulsanteDelete ?? false)
                })
                .catch(function (error) { })
        }
    }

    async function deleteEvento() {
        let dataObj = {
            idEvento: programmaSel?.id,
            tipo: programmaSel?.tipologia_comunicazioni
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.eventoAggiunto) {
                    props.eventoAggiunto("Argomenti eliminati", "success");

                }
                closeDialog()
            })
            .catch(function (error) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto(error.response.data.data[0].msg, "error");
            })
    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                <Fragment>
                    <Grid container spacing={2}>
                        {!modificaMode && (<Grid xs={12} md={12}>
                            <Typography><b>Data di inserimento:</b>  {convertISODate(programmaSel?.created_at)} {'(Inserito da: ' + programmaSel?.created_by_obj?.nome + ')'};</Typography>
                        </Grid>)}
                        {(!modificaMode && programmaSel?.modified_at !== null) && (<Grid xs={12} md={12}>
                            <Typography><b>Data di modifica:</b>  {convertISODate(programmaSel?.modified_at)}  {'(Modificato da: ' + programmaSel?.modified_by_obj?.nome + ')'};</Typography>
                        </Grid>)}
                        <Grid xs={12} md={12}>
                            <Box>
                                <Grid container rowSpacing={2} columnSpacing={2} >
                                    <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <CustomDatePicker
                                                minDate={dataInizioPeriodo}
                                                maxDate={DateTime.now() || dataFinePeriodo}
                                                onChangeValue={changeData}
                                                label="Data argomenti svolti"
                                            />
                                            {modificaMode && (<IconButton onClick={resetData} sx={{ border: '1px rgba(190, 190, 190, 1) solid', borderRadius: '0 4px 4px 0', borderLeft: 'none' }}>
                                                <Close />
                                            </IconButton>)}
                                        </Box>
                                    </Grid>

                                    <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                        {elencoOre.length > 0 ? (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="materia-label"> Seleziona ora</InputLabel>
                                                <Select
                                                    data-testid='selOraProgammaSelect'
                                                    label='Seleziona ora '
                                                    labelId="materia-label"
                                                    value={oraSelected}
                                                    onChange={changeOra}
                                                    disabled={!modificaMode || elencoOre.length === 0}
                                                >
                                                    <MenuItem key={-1} value={-1}>
                                                    </MenuItem>
                                                    {elencoOre.map((ora) => (
                                                        <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                            {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta.</Typography>) : null}
                                            </FormControl>) : (
                                            <Select disabled fullWidth >
                                                <MenuItem
                                                    key={-1}
                                                    value={undefined}
                                                >
                                                    Caricamento...
                                                </MenuItem>
                                            </Select>
                                        )
                                        }
                                    </Grid>
                                </Grid>

                            </Box>
                            <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                <TextField fullWidth sx={{ mt: 1 }} value={titoloProgramma} disabled={!modificaMode}
                                    onChange={changeTitoloProgramma} label={'Titolo'} />
                            </Grid>
                            <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                <TextField fullWidth sx={{ mt: 1 }} inputRef={inputProgrammaRef} value={testoProgramma} disabled={!modificaMode}
                                    onChange={changeTestoProgramma} multiline rows={4} label={'Testo'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"} sx={{ mt: 3, width: '100%' }}>
                        <Box>
                            {showPulsanteDelete && !modificaMode ? (
                                <Button onClick={() => { setConfirmDeleteDialogIsOpen(true) }} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Elimina argomenti svolti</Button>
                            ) : null}
                        </Box>
                        <Box>
                            {showPulsanteEdit ? (
                                <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                    {!modificaMode ? (<Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">chiudi dettaglio</Button>
                                    ) : (<Button onClick={() => annullaModifiche()} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla modifiche</Button>)}
                                    {!modificaMode ? (<Button onClick={() => setModificaMode(true)}
                                        sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                        variant="contained" >
                                        Modifica Argomenti Svolti
                                    </Button>) : (<Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isButtonSaveDisabled()}
                                        sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                        color="success" variant="contained" >
                                        Conferma Modifica
                                    </Button>)}
                                </Box>
                            ) : (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">chiudi dettaglio</Button>
                            )}
                        </Box>
                    </Box>
                </Fragment>

            </Container >

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma modifica argomenti svolti'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare l'argomento svolto.`}
                showAlunni={false}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={modificaProgramma}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confirmDeleteDialogIsOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare l'argomento svolto? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfirmDeleteDialogIsOpen(false) }}
                conferma={deleteEvento}
                disabledButton={false}
            />

            {/* <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} /> */}


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}