import { Fragment, useEffect, useState } from "react";
import { Card, CardActionArea, CardContent, Typography, Box, Dialog, DialogContent, Button, Divider, DialogActions, DialogTitle, IconButton } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Close } from '@mui/icons-material';

export default function CardHome(props) {

    const [text, setText] = useState([]);
    const [isModulo, setIsModulo] = useState(false);
    const [backgroundColorCard, setBackgroundColorCard] = useState('white');
    const [colorTextCard, setColorTextCard] = useState('unset');
    const [colorIconCard, setColorIconCard] = useState('default');
    const [icon, setIcon] = useState(null);
    const [dialogSubModulesOpen, setDialogSubModulesOpen] = useState(false);
    const [elencoPermessiUtente, setElencoPermessiUtente] = useState([]);
    const [elencoPermessiModulo, setElencoPermessiModulo] = useState([]);
    const [subModuli, setSubModuli] = useState([]);

    useEffect(() => {
        if (props.backgroundColorCard !== undefined) {
            setBackgroundColorCard(props.backgroundColorCard)
        }
        if (props.colorTextCard !== undefined) {
            setColorTextCard(props.colorTextCard)
        }
        if (props.colorIconCard !== undefined) {
            setColorIconCard(props.colorIconCard)
        }
        if (props.subModuli !== undefined) {
            setSubModuli([...props.subModuli])
        }
        if (props.icon !== undefined) {
            setIcon(props.icon)
        }
        if (props.isModulo !== undefined) {
            setIsModulo(props.isModulo)
        }
        if (props.text !== undefined) {
            setText(props.text)
        }

    }, [props.backgroundColorCard, props.colorTextCard, props.colorIconCard, props.subModuli, props.icon, props.text, props.isModulo])

    useEffect(() => {
        if (props.elencoPermessiUtente !== undefined) {
            setElencoPermessiUtente(props.elencoPermessiUtente)
        }
    }, [props.elencoPermessiUtente])

    useEffect(() => {
        if (props.permessiModulo !== undefined) {
            setElencoPermessiModulo(props.permessiModulo)
        }
    }, [props.permessiModulo])

    useEffect(() => {
        if (props.dialogSubModulesOpen !== undefined) {
            setDialogSubModulesOpen(props.dialogSubModulesOpen)
        }
    }, [props.dialogSubModulesOpen])


    function closeSubModules() {
        setDialogSubModulesOpen(false);
    }

    function openSubModules() {
        if (subModuli.length > 0) {
            setDialogSubModulesOpen(true);
            let n = 0;
            let permessoSingolo = '';
            for (let p of elencoPermessiModulo) {
                for (let i = 0; i < elencoPermessiUtente.length; i++) {
                    if (elencoPermessiUtente[i]['nome'] === p && elencoPermessiUtente[i]['value'] === true) {

                        n++;
                        permessoSingolo = elencoPermessiUtente[i]['nome'];
                    }
                }
            }

            if (n === 1) {
                let indexModulo = 0;
                indexModulo = subModuli.findIndex(m => m.permessoNome === permessoSingolo);
                props.navigateTo(subModuli[indexModulo].navigateTo);
            }
        } else {
            props.navigateTo()
        }
    }


    return (
        <Fragment>
            <Card elevation={2} sx={{ backgroundColor: backgroundColorCard, color: colorTextCard, height: '100%', borderRadius: '8px' }}>
                <CardActionArea sx={{ py: 1, height: '100%' }} onClick={openSubModules} >
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Grid container spacing={3} sx={{ width: '100%' }}>
                            <Grid xs={3} sx={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                {/* <CalendarMonthIcon sx={{ fontSize: '3.5rem' }} color={colorIconCard} /> */}
                                {icon}
                            </Grid>
                            <Grid xs={9} sx={{ display: "flex", alignItems: 'center' }}>
                                <Box sx={{ display: "flex", alignItems: 'center' }}>
                                    {/* {isModulo && (<Typography variant="subtitle2"> Modulo </Typography>)} */}
                                    <Typography sx={{ mt: 0, fontWeight: '500' }} variant='body1'> {text}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog fullWidth maxWidth="lg" open={dialogSubModulesOpen} sx={{ zIndex: 3000 }} onClose={closeSubModules}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>{isModulo ? 'Modulo ' + text : null}</Typography>
                    <IconButton onClick={closeSubModules}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid spacing={3} container>
                        {subModuli.map((submodulo, index) => (
                            <Fragment key={index + 'subModulo'}>
                                {elencoPermessiUtente.some(permesso => permesso.nome === submodulo?.permessoNome && permesso.value === true) && (
                                    <Grid xs={12} sm={6} >
                                        <CardHome text={submodulo?.text} isModulo={submodulo?.isModulo} navigateTo={submodulo?.navigateTo} icon={submodulo?.icon} />
                                    </Grid>)}
                            </Fragment>)
                        )}
                    </Grid>

                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button fullWidth variant="contained" onClick={closeSubModules}>Chiudi finestra</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}