import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {DialogActions, Divider, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, useTheme, Breadcrumbs, useMediaQuery, IconButton, Alert, Snackbar, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import { ArrowBack, EditCalendar, Close, ZoomIn } from "@mui/icons-material";
import RangeDate from "../shared/rangeDate";
import { DateTime, } from "luxon";

import EventoChip from "../registri/shared/eventoChip";

import { convertISODate } from '../shared/metodiDateUtility';
import { RegistroAPI } from "../../api/registroApi";
import Legende from "../registri/shared/legende";
import DettaglioAlunno from "../dettaglio-utente/dettaglioAlunno";
import SelezioneClassiTutor from "./selezioneClassiTutor";
import { RicercaAPI } from "../../api/ricercaApi";
import ElencoNote from "../eventi/elencoNote";
import ElencoAnnotazioni from "../eventi/elencoAnnotazioni";
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";

export default function RiepilogoAttivitaFormativaAlunno(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true)
    const [dialogSelezionaAlunnoOpen, setDialogSelezionaAlunnoOpen] = useState(false)
    const [permessiUtente, setPermessiUtente] = useState([]);

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);

    const [nomeClasse, setNomeClasse] = useState("");
    const [codiceClasse, setCodiceClasse] = useState("");
    const [codiceSimon, setCodiceSimon] = useState("");
    const [nomeMateria, setNomeMateria] = useState("");
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);

    const [risultatiRicerca, setRisultatiRicerca] = useState([]);

    const [listAlunni, setListAlunni] = useState([]);
    const [dialogDettaglioRegistroOpen, setDialogDettaglioRegistroOpen] = useState(false);

    const [trimestri, setTrimestri] = useState([]);
    const [trimestreNome, setTrimestreNome] = useState('');
    const [trimestreSelezionatoAppoggio, setTrimestreSelezionatoAppoggio] = useState(-1);
    const [trimestreSelezionato, setTrimestreSelezionato] = useState(-1);

    //Periodo di interesse
    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");

    const [changeTrimestreOpen, setChangeTrimestreOpen] = useState(false);
    const [changePeriodoOpen, setChangePeriodoOpen] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    //DETTAGLIO ALUNNO
    const [dettaglioAlunnoOpen, setDettaglioAlunnoOpen] = useState(false);
    const [dettaglioOpen, setDettaglioOpen] = useState(false);
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(-1);
    const [indexTabToOpen, setIndexTabToOpen] = useState(0);
    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [sedeUtente, setSedeUtente] = useState('');
    const [idSedeUtente, setIdSedeUtente] = useState(-1);

    const [materieRiepilogoAttivitaFormativa, setMaterieRiepilogoAttivitaFormativa] = useState([]);
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [comunicazioniAlunnoSelezionato, setComunicazioniAlunnoSelezionato] = useState({});

    const [comunicazioniDettaglio, setComunicazioniDettaglio] = useState([]);
    const [nomeElenco, setNomeElenco] = useState("");

    const matches = useMediaQuery('(min-width:500px)');

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 75,
    });

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)
        }

        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);

        }
        if (localStorage.getItem("sede")) {
            let sedeTmp = localStorage.getItem("sede");
            let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let sedeParsed = JSON.parse(decryptedSede);
            setSedeUtente(sedeParsed.nome)
            setIdSedeUtente(sedeParsed.id)
        }

    }, [])



    function goToSelezionaOra() {
        setDialogSelezionaClasseOpen(true);
    }


    function changeIdClasseSelezionata(idClasse, dataInizio, dataFine, idMateria, nomeClasse, trimestri, trimestreSelezionato, nomeMateria, idSede, isChangePeriodo, codiceClasse, codiceSimon, trimestreNome) {
        if (idClasse !== undefined) {
            let idCorsoCrypted = CryptoJS.AES.encrypt(idClasse.toString(), process.env.REACT_APP_PRIVATE_KEY).toString();
            sessionStorage.setItem("classe", idCorsoCrypted);
            setIdClasseSelezionata(idClasse);
            setIdSedeUtente(idSede);
            setIdMateriaSelezionata(idMateria);
            setNomeClasse(nomeClasse);
            if (codiceClasse !== undefined) setCodiceClasse(codiceClasse);
            if (codiceSimon !== undefined) setCodiceSimon(codiceSimon);
            setDialogSelezionaClasseOpen(false);

            setNomeMateria(nomeMateria)
            setTrimestri([...trimestri])
            setTrimestreNome(trimestreNome)
            setTrimestreSelezionato(trimestreSelezionato);
            setTrimestreSelezionatoAppoggio(trimestreSelezionato);
            setPeriodoDiInteresseInizio(dataInizio)
            setPeriodoDiInteresseFine(dataFine)
            setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
            setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));
            // getRiepilogoClasse(idClasse, dataInizio, dataFine, idMateria);
            if (isChangePeriodo !== true) getAlunni(idSede, idClasse);
            else getRegistroAlunno(dataInizio, dataFine)
        }
    }


    function openCambiaTrimestreDialog() {
        setTrimestreSelezionatoAppoggio(trimestreSelezionato)
        setChangeTrimestreOpen(prev => !prev)
    }

    function openCambiaPeriodo() {
        // setTrimestreSelezionatoAppoggio(trimestreSelezionato)
        setChangePeriodoOpen(prev => !prev)
    }

    // function changeTrimestre(e) {
    //     setTrimestreSelezionatoAppoggio(e.target.value);
    // }




    function closeDettaglioAlunno() {
        setDettaglioAlunnoOpen(false);
        // getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
        getRegistroAlunno(periodoDiInteresseInizio, periodoDiInteresseFine)
    }

    function closeDettaglio() {
        setDettaglioOpen(false);
    }

    function eventoModificato(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
        // getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
        getRegistroAlunno(periodoDiInteresseInizio, periodoDiInteresseFine)
    }

    // function openDialogLegenda() {
    //     setDialogLegendaOpen(true);
    // }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }
    // function vediDettaglioAlunno(id, indexTab, nome, cognome, ritardo) {
    //     setOpenDialogRitardo(ritardo)
    //     setIdAlunnoSelezionato(id);
    //     setNomeAlunnoSelezionato(nome + " " + cognome);
    //     setDettaglioAlunnoOpen(true);
    //     setIndexTabToOpen(indexTab);
    // }



    // function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
    //     let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
    //     let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

    //     let dataInizioObject = DateTime.fromISO(dataInizioTmp);
    //     let dataFineObject = DateTime.fromISO(dataFineTmp);

    //     let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
    //     let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
    //     if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
    //     {
    //         return "Ritirato il " + convertDataDiNascita(iscrittoAl)
    //     }
    //     if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
    //     {
    //         return "Ritirato il " + convertDataDiNascita(iscrittoAl)
    //     }
    //     else if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
    //     {
    //         return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
    //     }
    //     else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
    //         return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
    //     }
    //     else return null
    // }


    // async function getRiepilogoClasse(idClasseSelezionata, dataDiInteresseInizio, dataDiInteresseFine, idMateria) {
    //     let dataObj = {
    //         dataRichiestaDa: dataDiInteresseInizio,
    //         dataRichiestaA: dataDiInteresseFine,
    //         idCorso: idClasseSelezionata,
    //         idMateria: idMateria
    //     }
    //     setPeriodoDiInteresseInizio(dataDiInteresseInizio);
    //     setPeriodoDiInteresseFine(dataDiInteresseFine);
    //     setFormattedPeriodoDiInteresseInizio(convertISODate(dataDiInteresseInizio));
    //     setFormattedPeriodoDiInteresseFine(convertISODate(dataDiInteresseFine));
    //     RegistroAPI.getRiepilogoFormativo(dataObj)
    //         .then((result) => {
    //             if (result.alunni.length > 0) {
    //                 let alunniTmp = result.alunni;
    //                 for (let i = 0; i < alunniTmp.length; i++) {
    //                     alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

    //                 }
    //                 setListAlunni([...alunniTmp])
    //                 setHasLoaded(true)
    //             }
    //         })
    //         .catch(function (error) { })
    // }


    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function changeSede(nome, id) {
        setSedeUtente(nome);
        setIdSedeUtente(id);
    }

    function changeNomeMateria(nome) {
        setNomeMateria(nome)
    }

    function getRegistro() {
        // getRiepilogoClasse(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
        getRegistroAlunno(periodoDiInteresseInizio, periodoDiInteresseFine)
    }

    function confirmChangeTrimestre() {
        setTrimestreSelezionato(trimestreSelezionatoAppoggio)
        let dataInizio = trimestri[trimestreSelezionatoAppoggio].dataInizio;
        let dataFine = trimestri[trimestreSelezionatoAppoggio].dataFine
        // getRiepilogoClasse(idClasseSelezionata, dataInizio, dataFine, idMateriaSelezionata);
        getRegistroAlunno(dataInizio, dataFine)
        setChangeTrimestreOpen(false);

    }

    function selezionaAlunno(idAlunno, nomeAlunno) {
        setIdAlunnoSelezionato(idAlunno);
        setNomeAlunnoSelezionato(nomeAlunno)
    }

    async function getAlunni(idSede, idClasse) {

        let dataObj = {
            cognome: null,
            codiceFiscale: null,
            idSede: idSede !== -1 ? idSede : null,
            idCorso: idClasse !== -1 ? idClasse : null,
        }

        RicercaAPI.getAlunni(dataObj)
            .then((result) => {
                let risultati = [];
                for (let i = 0; i < result.sedi.length; i++) {
                    let corsiTmp = [];

                    for (let y = 0; y < result.sedi[i].corsi.length; y++) {
                        let corsoTmp = {};
                        corsoTmp.id = result.sedi[i].corsi[y].idCorso;
                        corsoTmp.name = result.sedi[i].corsi[y].corso;

                        let alunniTmp = [];
                        for (let x = 0; x < result.sedi[i].corsi[y].alunni.length; x++) {
                            let alunnoTmp = {};
                            alunnoTmp.id = result.sedi[i].corsi[y].alunni[x].idAlunno;
                            alunnoTmp.cf = result.sedi[i].corsi[y].alunni[x].codiceFiscale;
                            alunnoTmp.name = result.sedi[i].corsi[y].alunni[x].cognomeAlunno + " " + result.sedi[i].corsi[y].alunni[x].nomeAlunno;
                            alunnoTmp.type = "studente";
                            alunniTmp.push(alunnoTmp)
                        }
                        corsoTmp.type = "classe";
                        corsoTmp.children = alunniTmp;
                        corsiTmp.push(corsoTmp)
                    }


                    risultati.push({
                        id: idSede,
                        name: result.sedi[i].sede,
                        type: "sede",
                        children: corsiTmp
                    })

                }
                setRisultatiRicerca([...risultati])


                if (risultati.length > 0) {
                    setDialogSelezionaClasseOpen(false);
                    setDialogSelezionaAlunnoOpen(true);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    function backToSelCorso() {
        setDialogSelezionaAlunnoOpen(false);
        setDialogSelezionaClasseOpen(true);
    }

    async function getRegistroAlunno(dataInizio, dataFine) {

        let dataObj = {
            idAlunno: idAlunnoSelezionato,
            idCorso: idClasseSelezionata,
            periodoDal: dataInizio,
            periodoAl: dataFine
        }

        RegistroAPI.getRiepilogoFormativoAlunno(dataObj)
            .then((result) => {
                setMaterieRiepilogoAttivitaFormativa([...result?.materie]);
                let alunnoSel = result?.alunno;
                let arrayAlunni = [];
                arrayAlunni.push(alunnoSel)
                setAlunnoSelezionato(alunnoSel);
                setListAlunni([...arrayAlunni]);
                setDialogSelezionaAlunnoOpen(false);
                setHasLoaded(true);
                setComunicazioniAlunnoSelezionato(result?.alunno?.comunicazioni)
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    // function eventoAggiunto(alertMsg, alertSeverity) {
    //     openAlertMsg(alertMsg, alertSeverity);
    //     getRegistro();
    // }

    function changePeriodoDiInteresse(dataInizio, dataFine, trimestreNome) {
        let dataInizioTmp = dataInizio ?? new DateTime().toISO();
        let dataFineTmp = dataFine ?? new DateTime().toISO();

        setPeriodoDiInteresseInizio(dataInizioTmp);
        setPeriodoDiInteresseFine(dataFineTmp);

        setChangePeriodoOpen(false)

        changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateriaSelezionata, nomeClasse, trimestri, trimestreSelezionato, nomeMateria, idSedeUtente, true, codiceClasse, codiceSimon, trimestreNome);
    }

    function openDettaglio(elencoChips, nomeElenco) {
        setComunicazioniDettaglio(elencoChips);
        setNomeElenco(nomeElenco);
        setDettaglioOpen(true);
    }

    function rowRenderer({ index, key, parent, style }) {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (

                    <div onLoad={measure} style={style}>
                        <Box key={index} display={"flex"} alignItems={"center"} my={1.5} >
                            <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazioniDettaglio[index]} alunno={alunnoSelezionato} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} isListaEventi={true} />
                        </Box>
                    </div>
                )}
            </CellMeasurer>
        )

    }

    return (
        <Fragment>
            {(idClasseSelezionata !== -1 && dialogSelezionaClasseOpen === false) || (dialogDettaglioRegistroOpen === true) || (dialogSelezionaAlunnoOpen === false) ? (
                hasLoaded ? (
                    <Box sx={{ px: 5 }}>
                        <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
                            <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                            <Typography color="text.disabled">Riepilogo attività formativa</Typography>
                            <Typography color="text.disabled">{nomeClasse}</Typography>
                        </Breadcrumbs>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                            <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                Torna alla selezione
                            </Button>
                            {/* <Button variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} startIcon={<EditCalendar />} onClick={openCambiaTrimestreDialog}>
                                Cambia trimestre
                            </Button> */}
                            <Button variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} startIcon={<EditCalendar />} onClick={openCambiaPeriodo}>
                                Cambia periodo
                            </Button>
                            {/* <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                Mostra legenda
                            </Button> */}
                        </Box>
                        <Box px={{ xs: 1, md: 5 }} display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: "start", md: 'start' }} mt={2}>
                            <Box sx={{ width: { xs: '100%', md: 'unset' }, mr: { xs: 0, md: 2 } }}>
                                <Typography variant="caption">
                                    Sede:
                                </Typography>
                                <Typography
                                    variant={mobile ? 'caption' : 'body1'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>
                                    {(sedeUtente ?? "")}
                                </Typography>
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: 'unset' }, mr: { xs: 0, md: 2 } }}>
                                <Typography variant="caption">
                                    Corso:
                                </Typography>
                                <Typography color={'primary'}
                                    variant={mobile ? 'caption' : 'body1'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>
                                    {(codiceClasse + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()}
                                    {/* {(nomeClasse ?? "").toUpperCase()} */}
                                </Typography>
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
                                <Typography variant="caption">
                                    Periodo:
                                </Typography>
                                <Typography variant={mobile ? 'caption' : 'body1'} sx={{ fontWeight: { xs: 'bold', md: '' } }}>
                                    {trimestreNome ?? trimestreNome} {" (" + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + ")"}
                                </Typography>
                            </Box>
                        </Box>
                        <Grid container sx={{ px: 4 }} spacing={2}>
                            <Grid xs={12}>
                                <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '70vh' }, display: "flex", justifyContent: 'center' }}>
                                    <TableContainer component={Paper}>
                                        <Table >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='colonna-nomi row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2, zIndex: 4 }}>
                                                        <Typography variant="caption">
                                                            alunno:
                                                        </Typography>
                                                        <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                            variant={mobile ? 'caption' : 'body1'}>
                                                            {nomeAlunnoSelezionato}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign="center">Presenze</Typography></TableCell>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Voti scritti</Typography></TableCell>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Voti pratici</Typography></TableCell>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Voti orali</Typography></TableCell>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Valutazioni</Typography></TableCell>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}><Typography textAlign={"center"}>Accertamento prerequisiti</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {materieRiepilogoAttivitaFormativa.map((materia, index) => (
                                                    <TableRow style={{ height: '80px' }} key={materia.id}>
                                                        <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{ backgroundColor: 'white', px: 2, py: 5.5 }}>
                                                                <Box display={"flex"} alignItems={"center"}>
                                                                    {/* <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography> */}
                                                                    <Typography variant={mobile ? 'caption' : 'body1'}
                                                                        // onClick={() => vediDettaglioAlunno(alunno.id, 0, alunno.nome, alunno.cognome, false)}
                                                                        sx={{ cursor: 'pointer', mr: 2 }}>
                                                                        {(materia.nomeMateria).toUpperCase()}
                                                                    </Typography>
                                                                </Box>
                                                                {/* {alunno.msgIscrizione != null ? (
                                                            <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                        ) : null} */}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{ ml: 2 }}>
                                                                {materia?.presenze?.orePresenzaAlunno !== undefined ? (
                                                                    <Typography width={"fit-content"}>Ore di presenza: <b>{materia?.presenze?.orePresenzaAlunno}</b></Typography>
                                                                ) : null}
                                                                {materia?.presenze?.oreTotaliMateria !== undefined ? (
                                                                    <Typography width={"fit-content"}>Totale ore materia: <b>{materia?.presenze?.oreTotaliMateria}</b></Typography>
                                                                ) : null}
                                                                {materia?.presenze?.oreMateriaPeriodo !== undefined ? (
                                                                    <Typography width={"fit-content"}>Ore materia periodo: <b>{materia?.presenze?.oreMateriaPeriodo}</b></Typography>
                                                                ) : null}
                                                            </Box>

                                                        </TableCell>
                                                        {/* <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}></TableCell> */}
                                                        <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {/* {materia?.comunicazioni?.votiScritti.length > 0 ? (
                                                                <Box display="flex" justifyContent={'space-around'}>
                                                                    {materia?.comunicazioni?.votiScritti.map((comunicazione) => (
                                                                        < Fragment key={alunnoSelezionato.id + comunicazione.id} >
                                                                            <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunnoSelezionato} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        </Fragment>
                                                                    ))}
                                                                </Box>
                                                            ) : null} */}
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                                {materia?.comunicazioni?.votiScritti.length > 0 ? (
                                                                    <Box onClick={() => openDettaglio(materia?.comunicazioni?.votiScritti, " voti scritti")} width={"30px"} height={"30px"}
                                                                        border={"1px solid #1976d2"}
                                                                        borderColor={"primary"}
                                                                        backgroundColor={"white"}
                                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                        <ZoomIn />
                                                                    </Box>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {/* {materia.comunicazioni?.votiPratici.length > 0 ? (
                                                                <Box display="flex" justifyContent={'space-around'}>
                                                                    {materia?.comunicazioni?.votiPratici.map((comunicazione) => (
                                                                        < Fragment key={alunnoSelezionato.id + comunicazione.id} >
                                                                            <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunnoSelezionato} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        </Fragment>
                                                                    ))}
                                                                </Box>
                                                            ) : null} */}
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                                {materia?.comunicazioni?.votiPratici.length > 0 ? (
                                                                    <Box onClick={() => openDettaglio(materia?.comunicazioni?.votiPratici, " voti pratici")} width={"30px"} height={"30px"}
                                                                        border={"1px solid #1976d2"}
                                                                        borderColor={"primary"}
                                                                        backgroundColor={"white"}
                                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                        <ZoomIn />
                                                                    </Box>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {/* {materia?.comunicazioni?.votiOrali.length > 0 ? (
                                                                <Box display="flex" justifyContent={'space-around'}>
                                                                    {materia?.comunicazioni?.votiOrali.map((comunicazione) => (
                                                                        < Fragment key={alunnoSelezionato.id + comunicazione.id} >
                                                                            <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunnoSelezionato} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        </Fragment>
                                                                    ))}
                                                                </Box>
                                                            ) : null} */}
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                                {materia?.comunicazioni?.votiOrali.length > 0 ? (
                                                                    <Box onClick={() => openDettaglio(materia?.comunicazioni?.votiOrali, " voti orali")} width={"30px"} height={"30px"}
                                                                        border={"1px solid #1976d2"}
                                                                        borderColor={"primary"}
                                                                        backgroundColor={"white"}
                                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                        <ZoomIn />
                                                                    </Box>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {/* {materia?.comunicazioni?.valutazioni.length > 0 ? (
                                                                <Box display="flex" justifyContent={'space-around'}>
                                                                    {materia?.comunicazioni?.valutazioni.map((comunicazione) => (
                                                                        < Fragment key={alunnoSelezionato.id + comunicazione.id} >
                                                                            <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunnoSelezionato} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        </Fragment>
                                                                    ))}
                                                                </Box>
                                                            ) : null} */}
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                                {materia?.comunicazioni?.valutazioni.length > 0 ? (
                                                                    <Box onClick={() => openDettaglio(materia?.comunicazioni?.valutazioni, " valutazioni")} width={"30px"} height={"30px"}
                                                                        border={"1px solid #1976d2"}
                                                                        borderColor={"primary"}
                                                                        backgroundColor={"white"}
                                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                        <ZoomIn />
                                                                    </Box>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            {/* {materia?.comunicazioni?.accertamentoPrerequisiti.length > 0 ? (
                                                                <Box display="flex" justifyContent={'space-around'}>
                                                                    {materia?.comunicazioni?.accertamentoPrerequisiti.map((comunicazione) => (
                                                                        < Fragment key={alunnoSelezionato.id + comunicazione.id} >
                                                                            <EventoChip idDocente={idDocente} cantModify={true} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunnoSelezionato} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        </Fragment>
                                                                    ))}
                                                                </Box>
                                                            ) : null} */}
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                                {materia?.comunicazioni?.accertamentoPrerequisiti.length > 0 ? (
                                                                    <Box onClick={() => openDettaglio(materia?.comunicazioni?.accertamentoPrerequisiti, " accertamento prerequisiti")}
                                                                        width={"30px"} height={"30px"}
                                                                        border={"1px solid #1976d2"}
                                                                        borderColor={"primary"}
                                                                        backgroundColor={"white"}
                                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                        <ZoomIn />
                                                                    </Box>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                            {/* <Grid container spacing={2} sx={{ height: '30vh', px: 4 }}> */}
                            <Grid xs={12} md={6}>
                                <Paper sx={{ mb: 1, borderRight: '1px solid rgba(224, 224, 224, 1)', justifyContent: 'center' }}>
                                    <Typography textAlign={"center"} py={1} sx={{ fontWeight: 'bold', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>Annotazioni dei docenti</Typography>
                                    {/* {comunicazioniAlunnoSelezionato.annotazioni?.length > 0 ? ( */}
                                    <Box display="flex" flexDirection={'column'} justifyContent={'start'} alignContent={'start'} >
                                        <ElencoAnnotazioni alunno={alunnoSelezionato.id} annotazione={comunicazioniAlunnoSelezionato.annotazioni} />
                                    </Box>
                                    {/* ) : null} */}
                                </Paper>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Paper sx={{ mb: 2, borderRight: '1px solid rgba(224, 224, 224, 1)', justifyContent: 'center' }}>
                                    <Typography textAlign={"center"} py={1} sx={{ fontWeight: 'bold', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>Note disciplinari</Typography>
                                    {/* {comunicazioniAlunnoSelezionato.note?.length > 0 ? ( */}
                                    <Box display="flex" flexDirection={'column'} justifyContent={'start'} alignContent={'start'} >
                                        <ElencoNote alunno={alunnoSelezionato.id} note={comunicazioniAlunnoSelezionato.note} />
                                    </Box>
                                    {/* ) : null} */}
                                </Paper>
                            </Grid>
                            {/* </Grid> */}
                        </Grid>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento registro...</Typography>
                    </Box>
                )
            ) : null
            }

            <Dialog fullWidth maxWidth={"md"} open={changeTrimestreOpen}>
                <DialogTitle>Cambia trimestre di interesse</DialogTitle>
                <DialogContent>
                    {/* <FormControl sx={{ width: '100%', mt: 2 }} >
                        <InputLabel id="materia-label"> Seleziona trimestre</InputLabel>
                        <Select
                            label='Seleziona trimestre '
                            value={trimestreSelezionatoAppoggio}
                            onChange={changeTrimestre}
                        >
                            <MenuItem
                                key={-1}
                                value={-1}
                            >
                            </MenuItem>
                            {trimestri.map((trimestre, index) => (
                                <MenuItem
                                    key={trimestre.ordinale}
                                    value={index}
                                >
                                    {trimestre.nome} {"(" + convertISODate(trimestre.dataInizio) + " - " + convertISODate(trimestre.dataFine) + ")"}
                                </MenuItem>
                            ))}
                        </Select>
                        {trimestri.length === 0 ? (<Typography color="error" variant="caption">Nessun trimestre impostato per la classe selezionato</Typography>) : null}
                    </FormControl> */}
                    <Box display="flex" justifyContent="end" sx={{ mt: 2 }}>
                        <Button sx={{ mr: 2 }} variant="outlined" color="error" onClick={openCambiaTrimestreDialog}>Chiudi</Button>
                        <Button variant="contained" onClick={confirmChangeTrimestre}>Conferma</Button>
                    </Box>

                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={changePeriodoOpen}>
                <DialogTitle>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Cambia periodo di interesse</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* TODO: impostare showOnlyPeriodi={true} per il rangeDate solo periodi*/}
                    <RangeDate idClasse={idClasseSelezionata} dataDiInteresseInizio={periodoDiInteresseInizio} dataDiInteresseFine={periodoDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} showOnlyPeriodi={false}/>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: 8 }}>
                <DialogTitle>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Seleziona classe e periodo di riferimento</Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    {/* TODO: impostare showOnlyPeriodi={true} per il rangeDate solo periodi*/}
                    <SelezioneClassiTutor sedeUtente={idSedeUtente} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} changeNomeMateria={changeNomeMateria} riepilogoAlunno={true} showOnlyPeriodi={false} registro={''}/>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaAlunnoOpen} sx={{ mt: 8 }}>
                <DialogTitle>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Seleziona alunno</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant="caption" >Corso:</Typography>
                    <Typography color={'primary'}
                        variant={mobile ? 'caption' : 'body1'}
                        sx={{ fontWeight: 'bold', mr: 1, mb: 2 }}>
                        {(codiceClasse + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()} - {sedeUtente}
                        {/* {codiceClasse + " - "}{nomeClasse}{" (" + codiceSimon + ") "} - {sedeUtente} */}
                    </Typography>
                    {/* {risultatiRicerca[0]?.children[0]?.children?.map(alunno =>
                        <Box key={alunno.id} onClick={() => selezionaAlunno(alunno.id)} sx={{ mx: 1, my: 3, borderBottom: 'solid 1px rgba(224, 224, 224, 1)' }}>
                            <Typography>
                                {alunno.name}
                            </Typography>
                        </Box>

                    )} */}
                    <Box maxHeight={"40vh"} style={{ overflow: "scroll" }}>
                        <Table>
                            <TableBody>
                                {risultatiRicerca[0]?.children[0]?.children?.map((alunno, index) => (
                                    <TableRow key={alunno.id} sx={{ cursor: 'pointer' }} onClick={() => selezionaAlunno(alunno?.id, alunno?.name)}>
                                        <TableCell sx={{ minWidth: '200px', background: (alunno?.id === idAlunnoSelezionato) ? '#1976D2' : 'white', color: (alunno?.id === idAlunnoSelezionato) ? 'white' : 'black' }}>
                                            {index + 1 + ') '}{alunno?.name}  {(alunno?.id === idAlunnoSelezionato) ? "- SELEZIONATO" : ""}
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                    <Button sx={{ mt: 2 }}
                        onClick={backToSelCorso}
                        variant="outlined" fullWidth color="info" >Torna a seleziona classe</Button>
                    <Button sx={{ mt: 2 }}
                        onClick={getRegistro}
                        variant="contained" fullWidth color="success" disabled={idAlunnoSelezionato === -1}>Conferma e prosegui</Button>
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dettaglioAlunnoOpen} onClose={closeDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({nomeAlunnoSelezionato})</Typography>
                    <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno showBes={false} tabIndex={indexTabToOpen} idDocente={idDocente} id={idAlunnoSelezionato}
                        nomeAlunno={nomeAlunnoSelezionato} ritardo={openDialogRitardo} closeDettaglioAlunno={closeDettaglioAlunno} openAlertMsg={openAlertMsg} />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth open={dettaglioOpen} onClose={closeDettaglio} height={'70vh'}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    Elenco {nomeElenco} {'(' + comunicazioniDettaglio[0]?.contenuto?.materia?.nomeMateria + ')'}
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection={"column"} height={'40vh'} my={2} >
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    // deferredMeasurementCache={cache}
                                    height={height}
                                    rowCount={comunicazioniDettaglio.length}
                                    rowHeight={!matches ? 90 : 75}
                                    rowRenderer={rowRenderer}
                                    width={width}
                                />
                            )}
                        </AutoSizer>
                    </Box>
                    <Button variant="outlined" fullWidth onClick={closeDettaglio}>chiudi</Button>
                </DialogContent>
            </Dialog>


            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <Legende />
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


            <Helmet>
                <title>{t("name")} - Riepilogo attività formativa</title>
            </Helmet>
        </Fragment >
    )
}

// const VirtualizedTable = ({ arrayGiorni, nomeMateriaSelezionata, nomeClasse, mobile }) => {
//     const rowRenderer = ({ index, key, style }) => (
//         <TableRow key={key} style={style}>
//             <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
//                 <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
//                     <Typography color={'primary'}
//                         variant={mobile ? 'caption' : 'body1'}
//                         sx={{ fontWeight: 'bold', mr: 1 }}>{(nomeClasse ?? "").toUpperCase()}</Typography>
//                 </Box>
//             </TableCell>
//             {arrayGiorni.map((giorno) => (
//                 <TableCell className={giorno?.chiusura ? 'disabledCell' : ""} key={"dettaglio_" + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
//                     <Fragment>
//                         <Box justifyContent={"center"} alignItems={"center"} display="flex" flexDirection={"column"} >
//                             {giorno?.chiusura === false ? (
//                                 <Box onClick={() => openDettaglioRegistro(giorno.data)} width={"30px"} height={"30px"}
//                                     border={"1px solid #1976d2"}
//                                     borderColor={"primary"}
//                                     backgroundColor={"white"}
//                                     display={"flex"} alignItems={"center"} justifyContent={"center"}
//                                     sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
//                                 </Box>
//                             ) : null}
//                         </Box>
//                     </Fragment>
//                 </TableCell>
//             ))}
//         </TableRow>
//     );

//     return (
//         <AutoSizer>
//             {({ height, width }) => (
//                 <>
//                     <TableHead>
//                         <TableRow>
//                             <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
//                                 <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
//                                     variant={mobile ? 'caption' : 'body1'}>{nomeMateriaSelezionata.toUpperCase()}</Typography>
//                             </TableCell>
//                             {arrayGiorni.map((giorno) => (
//                                 <TableCell className='row-head' key={"giorno_" + giorno?.data} style={{ backgroundColor: 'white', p: 2, textAlign: 'center', minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
//                                     <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{getDayAndMonth(giorno?.data)}</Typography>
//                                     <Typography variant="subtitle2">{getDayOfTheWeek(giorno?.data)}</Typography>
//                                     {giorno?.chiusura ? (
//                                         <Typography color="primary" variant="subtitle2"><b>CHIUSURA</b></Typography>

//                                     ) : null}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     </TableHead>

//                     <List
//                         height={height}
//                         rowCount={arrayGiorni.length}
//                         rowHeight={48}
//                         rowRenderer={rowRenderer}
//                         width={width}
//                     />
//                 </>
//             )}
//         </AutoSizer>
//     );
// };