import { Fragment, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Container, FormControl, TextField, Paper, Link, Typography } from '@mui/material';
import { InputLabel, InputAdornment, IconButton, OutlinedInput } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthAPI } from '../../api/authApi';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Logo from "../../img/logo.png";

export default function Login(props) {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  const [isLoggedIn, setIsLoggedIn] = useState(false)


  const [username, setUsername] = useState("")
  const [emailValid, setEmailValid] = useState(true)
  const [emailMessage, setEmailMessage] = useState('')
  const [pw, setPw] = useState("")
  const [pwValid, setPwValid] = useState(true)
  const [pwMessage, setPwMessage] = useState('')

  const [credenzialiErrate, setCredenzialiErrate] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (props.isLoggedIn) {
      setIsLoggedIn(props.isLoggedIn);
      if (props.isLoggedIn === true)
        navigate("/")
    }
  }, [props.isLoggedIn])

  async function login() {

    let obj = {
      username: username,
      password: pw,
    }

    AuthAPI.login(obj)
      .then((result) => {

        if (result.idDocente != null) {
          let idDocenteString = result.idDocente.toString(); //Crypto non vuole numeri, ma solo stringhe
          let idDocente = CryptoJS.AES.encrypt(idDocenteString, process.env.REACT_APP_PRIVATE_KEY).toString();
          localStorage.setItem("doc", idDocente);
        }
        let idString = result.id.toString(); //Crypto non vuole numeri, ma solo stringhe
        let id = CryptoJS.AES.encrypt(idString, process.env.REACT_APP_PRIVATE_KEY).toString();
        let nomeUtente = CryptoJS.AES.encrypt(result.nome, process.env.REACT_APP_PRIVATE_KEY).toString();

        let token = CryptoJS.AES.encrypt(result.token, process.env.REACT_APP_PRIVATE_KEY).toString();
        let refreshToken = CryptoJS.AES.encrypt(result.refreshToken, process.env.REACT_APP_PRIVATE_KEY).toString();

        let permessiString = JSON.stringify(result.permessi);
        let permessi = CryptoJS.AES.encrypt(permessiString, process.env.REACT_APP_PRIVATE_KEY).toString();

        let ruolo = CryptoJS.AES.encrypt(result.titoloVisualizzato, process.env.REACT_APP_PRIVATE_KEY).toString();

        let sedeString = JSON.stringify(result.sede);
        let sede = CryptoJS.AES.encrypt(sedeString, process.env.REACT_APP_PRIVATE_KEY).toString();

        let ruoliObjString = JSON.stringify(result.objRuoli);
        let objRuoli = CryptoJS.AES.encrypt(ruoliObjString, process.env.REACT_APP_PRIVATE_KEY).toString();

        if (props.isLoggin)
          props.isLoggin(result);

        //TODO: Da decidere se local o session storage

        let isLoggedBool = CryptoJS.AES.encrypt("true", process.env.REACT_APP_PRIVATE_KEY).toString();

        localStorage.setItem("log", isLoggedBool);

        localStorage.setItem("id", id);
        localStorage.setItem("usr", nomeUtente);
        localStorage.setItem("tkn", token);
        localStorage.setItem("refresh", refreshToken);
        localStorage.setItem("permessi", permessi);
        localStorage.setItem("ruolo", ruolo);
        localStorage.setItem("sede", sede);
        localStorage.setItem("ruoliObj", objRuoli)

        sessionStorage.setItem('checkAnnoFormativo', true);

        navigate("/") //Vai alla home
        checkComunicazioni(process.env.REACT_APP_ANNO_SCOLASTICO);


      })
      .catch((error) => {
        setCredenzialiErrate(true)
      })



    //navigateToHome();
  }

  function validate(e) {
    e.preventDefault()

    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValidEmail = regex.test(username);



    if (username !== "" && pw !== "" && !isValidEmail) {
      login();
      setPwMessage('');
    };
    if (username === "") {
      setEmailValid(false);
      setEmailMessage(t("pages.login.blank"));
    } else {

      if(isValidEmail){
        setEmailValid(false);
        setEmailMessage("Per poter accedere è necessario inserire il NOME UTENTE. L'indirizzo email non è valido per l'accesso.");
      } else{
      setEmailValid(true);
        setEmailMessage("");

      }
    }
    if (pw === "") {
      setPwValid(false);
      setPwMessage(t("pages.login.blank"));
    } else {
      setPwValid(true);
    }
  }

  function usernameSet(e) {
    setUsername(e.target.value)
  }

  function pwSet(e) {
    setPw(e.target.value)
  }

  function checkComunicazioni() {
    if (props.checkComunicazioni) props.checkComunicazioni()
  }

  function showPasswordInput() {
    setShowPassword(prev => !prev);
}

  return (
    <Box sx={{ backgroundColor:'#03539c'}}>
      <Container fixed sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Paper elevation={4} component="form" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4 }}>
          <img src={Logo} alt="CIOFS logo" height="70" />
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              data-testid='emailLoginInput' error={(emailValid) ? false : true} required label="Nome utente" onChange={usernameSet}
              sx={{ mt: 4 }} helperText={emailMessage}
            />

            <Box display={"flex"} flexDirection={'column'} justifyContent={"center"} sx={{ my: 2 }}>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" color={!pwValid ? 'error' : 'primary'}>Password *</InputLabel>
                <OutlinedInput type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={showPasswordInput}  >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={pwSet}
                  label="Password *"
                  error={(pwValid) ? false : true}
                  required
                  value={pw}
                />
              </FormControl>
              <Typography variant='caption' sx={{ color: 'red' }}>{pwMessage}</Typography>
            </Box>

            {credenzialiErrate ? (<Typography sx={{ mt: 2 }} variant="body2" color="error">Le credenziali inserite non sono corrette</Typography>) : null}
            <Button disabled={username === "" || pw === ""} data-testid='loginBtn' type='submit' onClick={validate} variant="contained" sx={{ mt: 2 }}>{t("pages.login.log")}</Button>
            <Link sx={{mt:2, textAlign:'center'}} href="https://gtm.ciofslazio.it/pwdimenticata.php" underline="none"><Typography  variant="subtitle2">{t("pages.login.forgotpassword")}</Typography></Link>
          </Box>
        </Paper>
      </Container>
      <Helmet>
        <title>{t("name")} - {t("pages.login.title")}</title>
      </Helmet>
    </Box>
  );
}