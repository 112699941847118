import { Fragment, useEffect, useState } from "react"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, Switch } from '@mui/material';
import { convertOra } from "../../../shared/metodiDateUtility";
import { ArrowDownward } from "@mui/icons-material";
import PresenzaTirocini from "../../presenzaTirocini";

export default function RowAlunnoDettaglio(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [canEditPresenze, setCanEditPresenze] = useState(false);
    const [alunno, setAlunno] = useState(-1);
    const [indexAlunno, setIndexAlunno] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [indexChanges, setIndexChanges] = useState(0);

    const [dataDettaglio, setDataDettaglio] = useState(null);

    const [isValidataSetted, setIsValidataSetted] = useState(false); //Il set validata viene chiamato solo la prima volta al change del props alunno
    const [isValidata, setIsValidata] = useState(false); //Viene definita al GET 

    useEffect(() => {
        if (props.dataDettaglio) setDataDettaglio(props.dataDettaglio);
    }, [props.dataDettaglio])

    useEffect(() => {
        if (props.canEditPresenze) setCanEditPresenze(props.canEditPresenze);
    }, [props.canEditPresenze])

    useEffect(() => {
        if (props.alunno) {
            setAlunno(props.alunno);
            if (isValidataSetted === false) {
                setIsValidataSetted(true);
                setIsValidata(props?.alunno?.presenzaGiornata?.validata ?? false);
            }
            setIsLoading(false);
        }
        if (props.indexAlunno) setIndexAlunno(props.indexAlunno);
    }, [props.alunno, props.indexAlunno])

    function changeValidazione(e) {
        let alunnoTmp = { ...alunno };
        alunnoTmp.presenzaGiornata.validata = e.target.checked;
        if (e.target.checked)
            alunnoTmp.presenzaGiornata.isModified = true;
        else alunnoTmp.presenzaGiornata.isModified = false;

        setAlunno(alunnoTmp)
        if (props.modificaValidazioneAlunni)
            props.modificaValidazioneAlunni(alunnoTmp, indexAlunno)
        setTimeout(() => {
            setIndexChanges(prev => prev + 1);
        }, 100)
    }

    return (
        <Fragment>
            <TableRow style={{ height: '40px' }}>
                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid black' }}>
                    {!isLoading ? (
                        <Box sx={{ backgroundColor: 'white', px: 2, py: 2 }}>
                            <Box display={"flex"} sx={{ alignItems: "center", justifyContent: 'space-between' }}>
                                <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                    <Typography sx={{ mr: 2 }}>  {(indexAlunno + 1) + ")"}</Typography>
                                    <Typography o variant={isMobile ? 'caption' : 'body2'} sx={{ mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                        {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                    </Typography>

                                </Box>

                            </Box>
                            {alunno.msgIscrizione != null ? (
                                <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                            ) : null}
                        </Box>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>

                <TableCell sx={{ borderRight: '1px solid black' }}>
                    {!isLoading ? (
                        <Box sx={{ backgroundColor: 'white', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <PresenzaTirocini isSmallSize={true} presenza={alunno?.presenzaGiornata} indexChanges={indexChanges} />
                            {alunno?.presenzaGiornata?.idStato !== null && (
                                <Switch disabled={isValidata} onChange={changeValidazione} checked={alunno?.presenzaGiornata?.validata} />
                            )}
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent={"center"}>
                            <Skeleton variant="circular" width={40} height={40} sx={{ mx: 2 }} />
                        </Box>
                    )}
                </TableCell>

                <TableCell sx={{ py: 1, px: 1.5, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    {!isLoading ? (
                        <Typography sx={{ textAlign: "center" }} color={isValidata ? "primary" : "black"}>{isValidata ? "Validata" : "Non validata"}</Typography>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>

                <TableCell sx={{ py: 1.5, px: 1.5, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    {!isLoading ? (
                        <Grid container spacing={2} sx={{ display: "flex", alignItems: 'center' }}>
                            <Grid xs={3} sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                <PresenzaTirocini isSmallSize={true} presenza={alunno?.primaFascia?.presenzaFascia} indexChanges={indexChanges} />
                            </Grid>
                            <Grid xs={8}>
                                {alunno?.primaFascia?.presenzaFascia?.idStato === 0 ? (
                                    <Typography variant="body2" sx={{ ml: 2 }}><b>Fascia orario non definita.</b></Typography>
                                ) : (
                                    <Box >
                                        <Typography variant="body2">Orario previsto:</Typography>
                                        <Grid container>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.primaFascia?.orarioPrevisto?.oraInizio)}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}>
                                            </Grid>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.primaFascia?.orarioPrevisto?.oraFine)}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Typography sx={{ mt: 1 }} variant="body2">Orario effettuato:</Typography>
                                        {alunno?.primaFascia?.presenzaFascia?.idStato !== 1 ? (

                                            <Grid container>
                                                <Grid xs={12} md={5}>
                                                    <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                        <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.primaFascia?.orarioEffettuato?.oraInizio)}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}>
                                                </Grid>
                                                <Grid xs={12} md={5}>
                                                    <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                        <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.primaFascia?.orarioEffettuato?.oraFine)}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Typography variant="body2" color="error"><b>Assente</b></Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid>

                        </Grid>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>

                <TableCell sx={{ py: 1, px: 1.5, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    {!isLoading ? (
                        <Grid container spacing={2} sx={{ display: "flex", alignItems: 'center' }}>
                            <Grid xs={3} sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                <PresenzaTirocini isSmallSize={true} presenza={alunno?.secondaFascia?.presenzaFascia} indexChanges={indexChanges} />
                            </Grid>
                            <Grid xs={8}>
                                {alunno?.secondaFascia?.presenzaFascia?.idStato === 0 ? (
                                    <Typography variant="body2" sx={{ ml: 2 }}><b>Fascia orario non definita.</b></Typography>
                                ) : (
                                    <Box >
                                        <Typography variant="body2">Orario previsto:</Typography>

                                        <Grid container>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.secondaFascia?.orarioPrevisto?.oraInizio)}</Typography>
                                                </Box>                                                </Grid>
                                            <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}></Grid>
                                            <Grid xs={12} md={5}>
                                                <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                    <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.secondaFascia?.orarioPrevisto?.oraFine)}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>


                                        <Typography variant="body2" sx={{ mt: 1 }}>Orario effettuato:</Typography>
                                        {alunno?.secondaFascia?.presenzaFascia?.idStato !== 1 ? (
                                            <Grid container>
                                                <Grid xs={12} md={5}>
                                                    <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                        <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.secondaFascia?.orarioEffettuato?.oraInizio)}</Typography>
                                                    </Box>                                                </Grid>
                                                <Grid xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}></Grid>
                                                <Grid xs={12} md={5}>
                                                    <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                        <Typography variant="body2" textAlign={'center'}>{convertOra(alunno?.secondaFascia?.orarioEffettuato?.oraFine)}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                        ) : (
                                            <Typography variant="body2" color="error"><b>Assente</b></Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid>

                        </Grid>
                    ) : (
                        <Skeleton sx={{ mx: 2 }} />
                    )}
                </TableCell>
            </TableRow >
        </Fragment >
    )
}





