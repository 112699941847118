import { useState, useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';

export default function TableSediPersone(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);

    const [soggettoTable, setSoggettoTable] = useState("tutor");

    const [isCanEdit, setIsCanEdit] = useState(true);

    useEffect(() => {
        if (props.isCanEdit !== undefined)
            setIsCanEdit(props.isCanEdit)
    }, [props.isCanEdit])

    useEffect(() => {
        if (props.list) {
            setList([...props.list]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100);
        }
    }, [props.list])

    useEffect(() => {
        if (props.soggetto) setSoggettoTable(props.soggetto);
    }, [props.soggetto])

    function deleteItem(idItem) {
        if (props.deleteItem) props.deleteItem(idItem)
    }

    function visualizzaItem(Item) {
        if (props.visualizzaItem) props.visualizzaItem(Item)
    }

    return (
        <Paper sx={{ maxHeight: '50vh', overflowX:'hidden', overflowY:'scroll' }}>
            <TableContainer sx={{ width: '100%' }} >
                <Table sx={{ maxHeight: '50vh' }}>
                    <TableHead>
                        <TableRow >

                            <TableCell align="center"
                                className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body1" textAlign={"center"}><b>{soggettoTable === 'tutor' ? 'Nome tutor' : 'Sedi'}</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body1" textAlign={"center"}><b>{soggettoTable === 'tutor' ? 'C.F.' : 'Indirizzi'}</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body1" textAlign={"center"}><b>Azioni</b></Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow style={{ height: '40px' }} key={item?.id}>

                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ fontWeight: 'bold', ml: 2 }}>
                                                {soggettoTable === 'tutor' ? ((item?.cognome ?? '').toUpperCase() + " " + (item?.nome ?? '').toUpperCase()) : ((item?.citta ?? '').toUpperCase())}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%', ml: 2 }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} textAlign={'start'}>
                                                {soggettoTable === 'tutor' ? item?.CF : (item?.indirizzo + ' (cap:' + item?.cap + ')')}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>
                                        <IconButton color="primary" onClick={() => visualizzaItem(item)} sx={{ mr: (item?.canBeDeleted && isCanEdit) ? 2 : 0, width: '40px', height: '40px', border: 'solid 1px', borderRadius: '50%' }}>
                                            <FontAwesomeIcon color="primary" icon={faSearch} style={{ fontSize: '1.25rem' }} />
                                        </IconButton>
                                        {(item?.canBeDeleted && isCanEdit) && (<IconButton color="error" onClick={() => deleteItem(item?.id)} sx={{ width: '40px', height: '40px', border: 'solid 1px', borderRadius: '50%' }}>
                                            <FontAwesomeIcon color="error" icon={faTrash} style={{ fontSize: '1.25rem' }} />
                                        </IconButton>)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}