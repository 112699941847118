import { useState, useEffect, Fragment, useRef } from "react"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, useTheme, useMediaQuery, Alert, Skeleton } from '@mui/material';
import { regexGetVoto, regexInserimentoVoto } from "../../shared/utilityMethods";

export default function RowInserimentoVotiTirocinio(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [indexAlunno, setIndexAlunno] = useState(0);

    const [alunno, setAlunno] = useState(null);
    const [listAlunni, setListAlunni] = useState([]);
    const [listAlunniSetted, setListAlunniSetted] = useState(false);


    const votiValutazioneGeneraleStageRef = useRef(null)
    const votiFrequenzaRef = useRef(null)
    const votiTenutaDocumentazioneRef = useRef(null)
    const votiDisponibilitaPropostaFormativaRef = useRef(null)

    useEffect(() => {
        if (props.alunno) setAlunno(props.alunno);
        if (props.indexAlunno) setIndexAlunno(props.indexAlunno);
    }, [props.alunno, props.indexAlunno])

    useEffect(() => {
        if (props.listAlunni && !listAlunniSetted) {
            setListAlunniSetted(true); //Imposto la lista alunni solo la prima volta, senno mi crea problemi dopo
            setListAlunni([...props.listAlunni]);
        }
    }, [props.listAlunni])


    function isAllInputCompiled() {
        //Primo if è per controllare se il componente ha caricato
        if (votiValutazioneGeneraleStageRef?.current !== null && votiFrequenzaRef?.current !== null &&
            votiTenutaDocumentazioneRef?.current !== null && votiDisponibilitaPropostaFormativaRef?.current !== null) {
            if (votiValutazioneGeneraleStageRef.current.value != "" && votiFrequenzaRef.current.value != "" &&
                votiTenutaDocumentazioneRef.current.value != "" && votiDisponibilitaPropostaFormativaRef.current.value != "")
                return true
            else { //Se non viene rispettata la prima condizione controllo se tutti gli input suono vuoti, in quel caso mi va comunque bene
                if (votiValutazioneGeneraleStageRef.current.value === "" && votiFrequenzaRef.current.value === "" &&
                    votiTenutaDocumentazioneRef.current.value === "" && votiDisponibilitaPropostaFormativaRef.current.value === "")
                    return true
                else return false
            }
        } else return true;
    }

    function changeValutazioneGeneraleStage(e) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, false, true, true);
        votiValutazioneGeneraleStageRef.current.value = newStr;
    }
    function onBlurvalutazioneGeneraleStage(e, index) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, true, true, true, 30, true);

        let listAlunniTmp = listAlunni;
        listAlunniTmp[index].valutazioni.canSave = isAllInputCompiled();
        listAlunniTmp[index].valutazioni.valutazioneGenerale = newStr;
        setListAlunni([...listAlunniTmp]);

        if (props.votiChanged) props.votiChanged(listAlunniTmp);
    }

    function changeDisponibilitaFormativa(e) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, false, true, true);
        votiDisponibilitaPropostaFormativaRef.current.value = newStr;
    }
    function onBlurDisponibilitaPropostaFormativa(e, index) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, true, true, true, 30, true);
        let listAlunniTmp = listAlunni;
        listAlunniTmp[index].valutazioni.canSave = isAllInputCompiled();
        listAlunniTmp[index].valutazioni.disponibilitaProposta = newStr;
        setListAlunni([...listAlunniTmp])

        if (props.votiChanged) props.votiChanged(listAlunniTmp);
    }


    function changeTenutaDocumentazione(e) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, false, true, true);
        votiTenutaDocumentazioneRef.current.value = newStr;
    }
    function onBlurTenutaDocumentazione(e, index) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, true, true, true, 30, true);

        let listAlunniTmp = listAlunni;
        listAlunniTmp[index].valutazioni.canSave = isAllInputCompiled();
        listAlunniTmp[index].valutazioni.tenutaDocumentazione = newStr;
        setListAlunni([...listAlunniTmp]);

        if (props.votiChanged) props.votiChanged(listAlunniTmp);

        //  if (!isModificheNonSalvate) setIsModificheNonSalvate(true);
    }


    function changeFrequenza(e) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, false, true, true);
        votiFrequenzaRef.current.value = newStr;
    }
    function onBlurFrequenza(e, index) {
        let newValue = e.target.value;
        let newStr = regexInserimentoVoto(newValue, true, true, true, 30, true);

        let listAlunniTmp = listAlunni;
        listAlunniTmp[index].valutazioni.canSave = isAllInputCompiled();
        listAlunniTmp[index].valutazioni.frequenza = newStr;

        if (props.votiChanged) props.votiChanged(listAlunniTmp);

        //if (!isModificheNonSalvate) setIsModificheNonSalvate(true);
    }

  

    return (
        <Fragment>
            {alunno != null ? (
                <TableRow style={{ minHeight: '20px' }} key={alunno?.alunno?.id}>
                    <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box sx={{ backgroundColor: 'white', px: 2, py: 1 }}>
                            <Box display={"flex"} alignItems={"center"}>
                                <Typography variant={mobile ? 'caption' : 'body2'} sx={{ mr: 2 }}>  {(indexAlunno + 1) + ")"}</Typography>
                                <Typography variant={mobile ? 'caption' : 'body2'}
                                    sx={{ mr: 2, fontWeight: alunno?.alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                    {(alunno?.alunno?.cognome).toUpperCase() + " " + (alunno?.alunno?.nome).toUpperCase()}
                                </Typography>
                            </Box>
                            {!isAllInputCompiled() && (
                                <Typography variant="caption" color="error">Per poter proseguire con il salvataggio, tutti i valori devono essere compilati</Typography>
                            )}
                            {alunno.msgIscrizione != null ? (
                                <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                            ) : null}
                        </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}>
                            <input
                                className="inputEdit"
                                ref={el => votiValutazioneGeneraleStageRef.current = el}
                                defaultValue={regexGetVoto(alunno?.valutazioni?.valutazioneGenerale, true, true)}
                                style={{ width: '50px' }}
                                onChange={(e) => changeValutazioneGeneraleStage(e)}
                                onBlur={(e) => onBlurvalutazioneGeneraleStage(e, indexAlunno)}
                            />
                        </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}>
                            <input
                                className="inputEdit"
                                ref={el => votiFrequenzaRef.current = el}
                                defaultValue={regexGetVoto(alunno?.valutazioni?.frequenza, true, true)}
                                style={{ width: '50px' }}
                                onChange={(e) => changeFrequenza(e)}
                                onBlur={(e) => onBlurFrequenza(e, indexAlunno)}
                            />
                        </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}>
                            <input
                                className="inputEdit"
                                ref={el => votiTenutaDocumentazioneRef.current = el}
                                defaultValue={regexGetVoto(alunno?.valutazioni?.tenutaDocumentazione, true, true)}
                                style={{ width: '50px' }}
                                onChange={(e) => changeTenutaDocumentazione(e)}
                                onBlur={(e) => onBlurTenutaDocumentazione(e, indexAlunno)}
                            />
                        </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}>
                            <input
                                className="inputEdit"
                                ref={el => votiDisponibilitaPropostaFormativaRef.current = el}
                                defaultValue={regexGetVoto(alunno?.valutazioni?.disponibilitaProposta, true, true)}
                                style={{ width: '50px' }}
                                onChange={(e) => changeDisponibilitaFormativa(e)}
                                onBlur={(e) => onBlurDisponibilitaPropostaFormativa(e, indexAlunno)}
                            />
                        </Box>
                    </TableCell>
                </TableRow>) :
                (
                    <TableRow style={{ minHeight: '20px' }} key={alunno?.alunno?.id}>
                        <TableCell className="colonna-nomi" sx={{ px: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Skeleton sx={{ mx: 2 }} />
                        </TableCell>
                        <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Skeleton />
                        </TableCell>
                        <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Skeleton />
                        </TableCell>
                        <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Skeleton />
                        </TableCell>
                        <TableCell sx={{ py: 1.5, px: 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Skeleton />
                        </TableCell>
                    </TableRow>
                )}
        </Fragment>
    )
}