import { Fragment, useEffect, useState } from "react";
import { Typography, Divider, Button } from "@mui/material";
import { convertISODate } from "../../shared/metodiDateUtility";

export default function EventoCompitoRegistro(props) {
    const [comunicazione, setComunicazione] = useState(null);

    /*
    "tipoComunicazione": INT, 
    "idOrario": INT, //PK lezione (idOrario) in cui è stato assegnato il compito
    "dataLezione": DATE,  //Data lezione in cui è stato assegnato il compito
    "dataConsegna": DATE,  //Data lezione in cui cosegnare il compito
    "idOrarioConsegna": INT, //PK lezione (idOrario) in cui cosegnare il compito
    "materia": { //Informazioni sulla materia
     "idMateria": INT,
     "nomeMateria": STRING,
     "nomeMateriaBreve": STRING
    },	
    "contenuto": STR //Descrizione compito -Testo libero non formattato-
    */

    useEffect(() => {
        if (props.comunicazione !== undefined) {
            setComunicazione(props.comunicazione);
        }
    }, [props.comunicazione]);

    function closeDialog(){
        if(props.closeDialog)
        props.closeDialog();
    }


    return (
        <Fragment>
            <Typography><b>Data di inserimento:</b>  {convertISODate(comunicazione?.data_comunicazione)}</Typography>
            <Typography><b>Inserita da:</b>  {comunicazione?.created_by_obj?.nome}</Typography>
            <Typography><b>Materia:</b> {comunicazione?.contenuto?.materia?.nomeMateria}</Typography>
            <Typography color="primary"><b>Data di consegna: {comunicazione?.dataLezione ?? "NON ASSEGNATA"}</b></Typography>
            <Divider sx={{ my: 1 }} />
            <Typography >
                {comunicazione?.contenuto?.note}
            </Typography>

            <Button sx={{ mt: 3 }} onClick={closeDialog} variant="contained" fullWidth>Chiudi schermata</Button>
        </Fragment>
    )
}