import { Box, Divider, Typography } from "@mui/material";
import { Assignment, AssignmentLate, CalendarToday, Info, Description, DoneAll, AccessibilityNew } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


export default function LegendaGestioneTirocinio() {
    return (
        <Grid container >
            <Grid xs={12} md={12} sx={{
                pb: { xs: 4, md: 0 }, pl: { xs: 0, md: 4 }
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #4169e1', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#4169e1" }} />
                    </Box>
                    <Typography>BES (PDP/B E.S.)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #1ebf24', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#1ebf24" }} />
                    </Box>
                    <Typography>BES (PDP/D S.A.)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #ffbfaa', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#ffbfaa" }} />
                    </Box>
                    <Typography>BES (P.E.I. ORDINARIO)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #ff7b5a', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#ff7b5a" }} />
                    </Box>
                    <Typography>BES (P.E.I. PERSONALIZZATO)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #d11507', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#d11507" }} />
                    </Box>
                    <Typography>BES (P.E.I. DIFFERENZIATO)</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}