import { useState, useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, Button} from '@mui/material';
import { Add, CopyAll, Delete, Download, FormatListBulleted, Info, Search } from "@mui/icons-material";
import CustomIconButton from "../../shared/customIconButton";

export default function TableConvenzioni(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (props.list) {
            setList([...props.list]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100);
        }
    }, [props.list])

    function deleteItem(idItem) {
        if (props.deleteItem) props.deleteItem(idItem)
    }

    function visualizzaItem(idItem) {
        if (props.visualizzaItem) props.visualizzaItem(idItem)
    }

    function visualizzaListProgettiItem(protocolloConvenzione) {
        if (props.visualizzaListProgettiItem) props.visualizzaListProgettiItem(protocolloConvenzione)
    }

    function downloadItem(idItem,protocolloConvenzione) {
        if (props.downloadItem) props.downloadItem(idItem, protocolloConvenzione)
    }

    function addProgettoItem(protocolloConvenzione) {
        if (props.addProgettoItem) props.addProgettoItem(protocolloConvenzione)
    }

    function copyItem(idItem) {
        if (props.copyItem) props.copyItem(idItem)
    }

    function openLegendaDialog() {
        if (props.openLegendaDialog) props.openLegendaDialog();
    }

    return (
        <Paper>
            <TableContainer sx={{ width: '100%' }} >
                <Table sx={{ maxHeight: '40vh' }}>
                    <TableHead>
                        <TableRow >

                            <TableCell align="center"
                                className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body2"} textAlign={"center"}><b>Protocollo convenzione</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body2"} textAlign={"center"}><b>Ragione sociale</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body2"} textAlign={"center"}><b>P.Iva</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body2"} textAlign={"center"}><b>Stato</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body2"} textAlign={"center"}><b>Alunni assegnati</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center" colSpan={2}
                                className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body2"} textAlign={"center"}><b>Azioni</b></Typography>
                                    <Button onClick={openLegendaDialog} variant="outlined" size="small" sx={{ ml: 1 }} startIcon={<Info />}>Mostra legenda</Button>
                                </Box>
                            </TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {list.map((item, index) => (

                            <TableRow style={{ height: '40px' }} key={item?.id}>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ fontWeight: 'bold' }}>
                                                {(item?.protocolloConvenzione ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'}>
                                                {(item?.ragioneSociale ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'}>
                                                {(item?.pIva ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ height: '100%' }} >
                                            <Typography color={item?.stato === "Attiva" ? 'primary' : 'unset'} variant={mobile ? 'caption' : 'body2'}>
                                                {(item?.stato ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'}>
                                                {item?.alunniAssegnati}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>
                                        <CustomIconButton buttonClicked={() => visualizzaItem(item?.id)} mr={1} color="primary" variant="outlined" icon={<Search color="primary" />} />
                                        <CustomIconButton buttonClicked={() => copyItem(item?.id)} mr={1} color="secondary" variant="contained" icon={<CopyAll sx={{ color: "white" }} color="primary" />} />
                                        <CustomIconButton buttonClicked={() => downloadItem(item?.id, item?.protocolloConvenzione)} mr={1} color="success" variant="contained" icon={<Download sx={{ color: "white" }} />} />
                                        {item?.canDelete && (<CustomIconButton buttonClicked={() => deleteItem(item?.id)} mr={1} color="error" variant="contained" icon={<Delete sx={{ color: "white" }} />} />)}

                                    </Box>
                                </TableCell>

                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>
                                        {item.alunniAssegnati > 0 && (<CustomIconButton buttonClicked={() => visualizzaListProgettiItem(item?.protocolloConvenzione)} mr={1} color="primary" variant="contained" icon={<FormatListBulleted sx={{ color: "white" }} />} />)}
                                        {(item?.stato === 'Attiva' && item?.maxAlunniReached === false) && (<CustomIconButton buttonClicked={() => addProgettoItem(item?.protocolloConvenzione)} mr={1} color="primary" variant="outlined" icon={<Add color="primary" />} />)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}