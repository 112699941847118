import { Box, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, InputLabel, MenuItem, Select, FormControl, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"
import ElencoAlunniSelezionabili from "../elencoAlunniSelezionabili";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";
import CryptoJS from 'crypto-js';
import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../../shared/metodiDateUtility";
import { Check, Close } from "@mui/icons-material";
import { PresenzeAPI } from "../../../api/presenzeApi";
import DialogConferma from "../../shared/dialogConferma";

export default function AggiuntaCompiti(props) {

    const inputDescrizioneCompitiRef = useRef(null);

    const [data, setData] = useState(null);


    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);

    const [elencoOre, setElencoOre] = useState([]);
    const [tipoRegistro, setTipoRegistro] = useState('classe');

    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    const [descrizioneCompiti, setDescrizioneCompiti] = useState("");
    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)




    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])

    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined && props.alunniSelezionati !== undefined && props.dataRichiesta !== undefined) {

            if (props.listAlunni == null)
                getAlunniByClasseID();
            else {
                setListAlunni([...props.listAlunni]);
                setGetAlunniIsLoading(false);
            }

            getAlunniPresentiAtStart(props.listAlunni, props.alunniSelezionati)

            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
            setTipoRegistro(props.tipoRegistro)
            //getOreLezione(dataRichiesta, props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.tipoRegistro);
        }
    }, [props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.dataRichiesta, props.tipoRegistro]);

    useEffect(() => {
        if (isDialog === false) {
            if (sessionStorage.getItem("classe") != null) {
                let encryptedIdCorso = sessionStorage.getItem("classe");
                let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                //TODO: Poi imposterò questo ID
                setAlertNoIdIsOpen(false);

            } else {
                //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
                setAlertNoIdIsOpen(true);
            }
        }
    }, [isDialog])


    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])



    function selectAlunni(listAlunni) {
        if (listAlunni !== undefined)
            setAlunniSelezionati([...listAlunni]);
    }
    function changeDescrizioneCompiti(e) {
        setDescrizioneCompiti(e.target.value)
    }


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria, listAlunni, alunniSelezionati, tipoRegistro);
        //isAlunnoIscrittoInData(value, listAlunni);
    }

    function changeOra(e) {
        setOraSelected(e.target.value);
        let formattedData = convertDataGetProssimeLezione(data, true)
        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            idMateria: idMateria
        }


        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let ora = result.filter(ora => ora.idOrario === e.target.value);
                if (ora.length > 0) {
                    setIdMateria(ora[0].materia.idMateria)
                    getAlunniPresenti(listAlunni, data, alunniSelezionati, ora[0])

                }
            });
    }


    async function inserisciCompiti() {

        let arrayAlunniSelezionatiID = [];
        for (let alunno of alunniSelezionati) {
            arrayAlunniSelezionatiID.push(alunno.id)
        }

        const obj = {
            note: descrizioneCompiti,
            idAlunno: arrayAlunniSelezionatiID,
            idDocente: idDocente,
            idMateria: idMateria,
            idOrario: oraSelected !== -1 ? oraSelected : null,
            is_interaClasse: alunniSelezionati.length === alunniSelezionabiliNumber ? true : false,
            idClasse: idCorso,
            idCorso: idCorso

        }

        setPulsanteConfermaDisabled(true);

        EventiAPI.insertCompiti(obj)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Compiti assegnati", "success");
                } else {
                    setAlunniSelezionati([]);
                    setDescrizioneCompiti("");

                    if (inputDescrizioneCompitiRef != null)
                        inputDescrizioneCompitiRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Compiti assegnati");
                    setAlertIsOpen(true);
                }
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setConfirmAlertIsOpen(false);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) props.logout();
                }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");
                setAlertMsg("Al momento non è possibile completare l'operazione");
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setAlertIsOpen(true);
                setPulsanteConfermaDisabled(false);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
    }
    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, listAlunniTmp, alunniSelezionatiTmp, tipoRegistro) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)

        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            //idMateria: idMateria
        }
        if (tipoRegistro === "docente") dataObj.idMateria = idMateria;
        if (formattedData != null) {

            RegistroAPI.getProssimeLezioni(dataObj)
                .then((result) => {
                    let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                    setElencoOre([...prossimeOreTmp]);
                    if (prossimeOreTmp.length > 0) {
                        setOraSelected(prossimeOreTmp[0].idOrario);
                        setIdMateria(prossimeOreTmp[0].materia.idMateria)
                    }
                    else {
                        setOraSelected(-1);
                        // setIdMateria(-1);
                    }
                    getAlunniPresenti(listAlunniTmp, dataTmp, alunniSelezionatiTmp, prossimeOreTmp[0]);

                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 403) props.logout();
                    }
                    console.error(error)
                })
        }
    }
    async function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);
                // isAlunnoIscrittoInData(DateTime.now(), [...result.alunni])
            })
    }

    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp;// setAlunniNonSelezionabili([...listTmp]);
        }
        else {
            let listTmp = [];
            for (let alunno of listAlunni) {
                listTmp.push(alunno);
            }
            setAlunniNonSelezionabili([...listTmp]);
        }
    }

    function resetData() {
        setData(null);
        setOraSelected(-1);
        setElencoOre([]);
    }

    function isButtonSaveDisabled() {
        if (descrizioneCompiti === "" || alunniSelezionati.length === 0 || (data != null && oraSelected === -1))
            return true
        else return false;
    }

    async function getAlunniPresenti(alunniTmp, dataRichiesta, alunniSelezionati, oraSelezionata) {

        let dataSql = convertToSqlFormat(dataRichiesta);

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataObj = {
            "data": dataSql,
            "idAlunno": idAlunni
        }

        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                let alunniNonSelezionabiliTmp = [];
                for (let alunno of result.alunni) {
                    if (alunno?.stato_presenza === "A") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);
                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        alunniNonSelezionabiliTmp.push(objAlunno)
                    } else if (alunno?.stato_presenza === "UA" || alunno?.stato_presenza === "ER/UA" || alunno?.stato_presenza === "ER") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);

                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        if (alunno?.stato_presenza === "ER") {
                            let orarioFineOraConverted = oraSelezionata.oraFine.substring(0, oraSelezionata.oraFine.length - 3)
                            let orarioIngressoConverted = alunno.orario_ingresso_reale.substring(0, alunno.orario_ingresso_reale.length - 3)
                            let dataFineLezione = new Date(`1970-01-01T${orarioFineOraConverted}:00Z`);
                            let dataIngresso = new Date(`1970-01-01T${orarioIngressoConverted}:00Z`);
                            if (dataIngresso > dataFineLezione) {
                                alunniNonSelezionabiliTmp.push(objAlunno)
                            }
                        } else if (alunno?.stato_presenza === "UA") {
                            let orarioInizioOraConverted = oraSelezionata.oraInizio.substring(0, oraSelezionata.oraInizio.length - 3)
                            let orarioUscitaConverted = alunno.orario_uscita_reale.substring(0, alunno.orario_uscita_reale.length - 3)
                            let dataInizioLezione = new Date(`1970-01-01T${orarioInizioOraConverted}:00Z`);
                            let dataUscita = new Date(`1970-01-01T${orarioUscitaConverted}:00Z`);
                            if (dataUscita < dataInizioLezione) {
                                alunniNonSelezionabiliTmp.push(objAlunno)
                            }
                        } else if (alunno?.stato_presenza === "ER/UA") {
                            let orarioFineOraConverted = oraSelezionata.oraFine.substring(0, oraSelezionata.oraFine.length - 3)
                            let orarioIngressoConverted = alunno.orario_ingresso_reale.substring(0, alunno.orario_ingresso_reale.length - 3)
                            let dataFineLezione = new Date(`1970-01-01T${orarioFineOraConverted}:00Z`);
                            let dataIngresso = new Date(`1970-01-01T${orarioIngressoConverted}:00Z`);
                            let orarioInizioOraConverted = oraSelezionata.oraInizio.substring(0, oraSelezionata.oraInizio.length - 3)
                            let orarioUscitaConverted = alunno.orario_uscita_reale.substring(0, alunno.orario_uscita_reale.length - 3)
                            let dataInizioLezione = new Date(`1970-01-01T${orarioInizioOraConverted}:00Z`);
                            let dataUscita = new Date(`1970-01-01T${orarioUscitaConverted}:00Z`);
                            if (dataIngresso > dataFineLezione && dataUscita < dataInizioLezione) {
                                alunniNonSelezionabiliTmp.push(objAlunno)
                            }
                        }
                    }

                }

                let newAlunni = isAlunnoIscrittoInData(dataRichiesta, alunniTmp);
                for (let alunno of newAlunni) {
                    alunniNonSelezionabiliTmp.push(alunno);
                }

                setAlunniNonSelezionabili([...alunniNonSelezionabiliTmp]);
                let arrayTmp = alunniNonSelezionabiliTmp;
                let arraySelTmp = []


                let alunniSelezionabiliNumber = alunniTmp.length - alunniNonSelezionabiliTmp.length
                if (alunniSelezionabiliNumber < 0)
                    alunniSelezionabiliNumber = 0;

                setAlunniSelezionabiliNumber(alunniSelezionabiliNumber)
                for (let alunno of alunniSelezionati) {
                    if (!arrayTmp.includes(alunno)) arraySelTmp.push(alunno);
                }
                setAlunniSelezionati([...arraySelTmp]);
                selectAlunni(arraySelTmp);
            })
            .catch((error) => {
            })

    }

    function getAlunniPresentiAtStart(alunniTmp, alunniSelezionati) {

        let alunniNonSelezionabiliTmp = [];

        let todayDate = DateTime.local();
        let newAlunni = isAlunnoIscrittoInData(todayDate, alunniTmp);
        for (let alunno of newAlunni) {
            alunniNonSelezionabiliTmp.push(alunno);
        }

        setAlunniNonSelezionabili([...alunniNonSelezionabiliTmp]);
        let arrayTmp = alunniNonSelezionabiliTmp;
        let arraySelTmp = []


        let alunniSelezionabiliNumber = alunniTmp.length - alunniNonSelezionabiliTmp.length
        if (alunniSelezionabiliNumber < 0)
            alunniSelezionabiliNumber = 0;

        setAlunniSelezionabiliNumber(alunniSelezionabiliNumber)
        for (let alunno of alunniSelezionati) {
            if (!arrayTmp.includes(alunno)) arraySelTmp.push(alunno);
        }
        setAlunniSelezionati([...arraySelTmp]);
        selectAlunni(arraySelTmp);
    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili checkAssenze={true} selectAlunni={selectAlunni} alunniSelezionabiliNumber={alunniSelezionabiliNumber}
                                        listAlunniSelezionati={alunniSelezionati} alunniNonSelezionabili={alunniNonSelezionabili} listAlunni={listAlunni} />
                                ) : (
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Box>
                                    <Grid container rowSpacing={2} columnSpacing={2} >
                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                                    <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                                        minDate={dataInizioPeriodo}
                                                        maxDate={dataFinePeriodo}
                                                        label="Data di consegna"
                                                        value={data}
                                                        format="dd/MM/yyyy"
                                                        onChange={(value) => changeData(value)}
                                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                                </LocalizationProvider>
                                                <IconButton onClick={resetData} sx={{ border: '1px rgba(190, 190, 190, 1) solid', borderRadius: '0 4px 4px 0', borderLeft: 'none' }}>
                                                    <Close />
                                                </IconButton>
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="materia-label">
                                                    {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                                    Seleziona ora</InputLabel>
                                                <Select
                                                    data-testid='selOraValutazioneSelect'
                                                    label='Seleziona ora '
                                                    labelId="materia-label"
                                                    value={oraSelected}
                                                    onChange={changeOra}
                                                    disabled={elencoOre.length === 0}
                                                // disabled={!modifica}
                                                >
                                                    <MenuItem key={-1} value={-1}>
                                                    </MenuItem>
                                                    {elencoOre.map((ora) => (
                                                        <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                            {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta.</Typography>) : null}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                    <TextField fullWidth sx={{ mt: 1 }} inputRef={inputDescrizioneCompitiRef}
                                        onChange={changeDescrizioneCompiti} placeholder="Scrivi qui..." multiline rows={4} />
                                </Grid>
                            </Grid>
                        </Grid>





                        <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                            {isDialog ? (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                            ) : null}
                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isButtonSaveDisabled()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                color="success" variant="contained" >
                                Aggiungi compiti
                            </Button>
                        </Box>
                    </Fragment>
                ) : null}

            </Container>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={"Conferma aggiunta compito"}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={'Confermi di voler aggiungere una compito a'}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={inserisciCompiti}
                disabledButton={pulsanteConfermaDisabled}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}