import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { MenuItem, Box, Button, CircularProgress, FormControl, InputLabel, Select, Typography, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { RicercaAPI } from "../../api/ricercaApi";
import { getAnnoFormativo } from "../shared/utilityMethods";

export default function SelezionaClasse(props) {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();


    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [nomeSedeSelezionata, setNomeSedeSelezionata] = useState("");


    const [classiInGiornata, setClassiInGiornata] = useState([])

    const [nomeClasseSelezionata, setNomeClasseSelezionata] = useState("");
    const [hasLoaded, setHasLoaded] = useState(false)

    const [sedeUtente, setSedeUtente] = useState(-1);
    const [elencoSedi, setElencoSedi] = useState([])
    const [sedeSelezionata, setSedeSelezionata] = useState(-1);

    const [annoFormativo, setAnnoFormativo] = useState('');

    useEffect(() => {
        if (props.sedeUtente !== undefined) {
            setSedeUtente(props.sedeUtente);
            setSedeSelezionata(props.sedeUtente)
            let annoFormativoTmp = getAnnoFormativo();
            getSedi(props.sedeUtente, annoFormativoTmp)
        }
    }, [props.sedeUtente])

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        if (localStorage.getItem("sede")) {
            let sedeTmp = localStorage.getItem("sede");
            let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let idSede = JSON.parse(decryptedSede).id;
            let nomeSede = JSON.parse(decryptedSede).nome;

            getClassiBySede(idSede, annoFormativoTmp);
            setNomeSedeSelezionata(nomeSede)
        }
    }, [])

    useEffect(() => {
        if (props.idClasseSelezionata)
            setIdClasseSelezionata(props.idClasseSelezionata)
    }, [props.idClasseSelezionata])

    function changeIdClasseSelezionata(idClasse, nomeClasse) {
        setIdClasseSelezionata(idClasse);
        setNomeClasseSelezionata(nomeClasse);
    }

    function changeSede(e) {
        setSedeSelezionata(e.target.value);
        getClassiBySede(e.target.value, annoFormativo)
        //props.changeSede(sediList[e.target.value - 1].nomeSede)
    }


    function confirmClasse() {
        if (idClasseSelezionata !== -1)
            props.confirmClasse(idClasseSelezionata, nomeClasseSelezionata);
    }






    async function getClassiBySede(idSedeTmp, annoFormativo) {

        let dataObj = {
            idSede: idSedeTmp,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setClassiInGiornata([...result.sedi])
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    function getSedi(idSede, annoFormativo) {
        if (idSede === 1) { //Sede legale
            RicercaAPI.getSedi({ annoFormativo: annoFormativo })
                .then((result) => {
                    setElencoSedi([...result.sedi])
                })
        }
    }


    function goToHome() {
        navigate("/")
    }




    return (
        <Box sx={{ maxHeight: "70vh" }}>
            <Fragment>
                {/* <Typography variant="h6">Seleziona classe di riferimento - Sede ({nomeSedeSelezionata})</Typography> */}
                {sedeUtente === 1 && (
                    <FormControl sx={{ width: '100%', mt: 1 }}>
                        <InputLabel id="sede-label">
                            Sede
                        </InputLabel>
                        <Select
                            disabled={elencoSedi.length === 0}
                            data-testid='sedeRicercaSelect'
                            label="Sede"
                            labelId="sede-label"
                            value={sedeSelezionata}
                            onChange={changeSede}
                        >
                            <MenuItem
                                key={-1}
                                value={-1}
                            >
                            </MenuItem>
                            {elencoSedi.map((sede) => (
                                <MenuItem
                                    key={sede.id}
                                    value={sede.id}
                                >
                                    {sede.nomeSede}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {hasLoaded ? (
                    classiInGiornata.length > 0 ? (
                        <Fragment>
                            <Box maxHeight={"50vh"} style={{ overflow: "scroll" }}>
                                <Table >
                                    <TableBody>
                                        {classiInGiornata.map((classe, index) => (
                                            <TableRow key={classe.id} sx={{ cursor: 'pointer' }} onClick={() => changeIdClasseSelezionata(classe?.id, classe?.nomeCorso)}>
                                                <TableCell sx={{ minWidth: '200px', background: (classe.id === idClasseSelezionata) ? '#1976D2' : 'white', color: (classe.id === idClasseSelezionata) ? 'white' : 'black' }}>
                                                    {classe?.codiceCorso + " - " + classe?.nomeCorso + " (" + classe?.codiceSimon + ")"}  {(classe.id === idClasseSelezionata) ? "- SELEZIONATA" : ""}
                                                </TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                            <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                            <Button sx={{ my: 2 }} onClick={confirmClasse} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Conferma e prosegui</Button>
                        </Fragment>
                    ) : (
                        <Fragment>

                            <Typography sx={{ mt: 1 }} textAlign={"center"} color="red">Nessuna classe associata alla sede selezionata</Typography>
                            <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={goToHome}>Torna alla home</Button>
                        </Fragment>
                    )
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                        <CircularProgress />
                    </Box>
                )}
            </Fragment>
        </Box>
    )
}
