import * as React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, DialogContent, DialogTitle, IconButton, Breadcrumbs, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider } from '@mui/material';

import DettaglioAlunno from '../dettaglio-utente/dettaglioAlunno';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Add, Clear, Close, Download } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import 'react-virtualized/styles.css';
import TreeViewRicerca from './treeViewRicerca';

import { RicercaAPI } from '../../api/ricercaApi';
import RicercaStudente from './ricercaStudente';
import { DateTime } from 'luxon';
import axios from 'axios';
import Breadcrumb from '../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../shared/utilityMethods';

export default function TracciatiBes(props) {

    const { t, i18n } = useTranslation();
    const regExCf = /^[A-Z]{6}\d{2}[A-EHLMPRST]\d{2}([A-Z]\d{3})[A-Z]$/;


    const inputNomeAlunnoRef = useRef(null);
    const inputCfRef = useRef(null);


    const [aggiungiAlunnoDialogOpen, setAggiungiAlunnoDialogOpen] = useState(false);

    const [sediList, setSediList] = useState([]);
    const [sediListExport, setSediListExport] = useState([]);

    const [classiList, setClassiList] = useState([]);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [classeSelected, setClasseSelected] = useState(-1);




    const [risultatiRicerca, setRisultatiRicerca] = useState([]);


    const [nomeAlunno, setNomeAlunno] = useState('');
    const [nomeValido, setNomeValido] = useState(true);
    const [nomeMessage, setNomeMessage] = useState('')
    const [cfAlunno, setCfAlunno] = useState('');
    const [cfValido, setCfValido] = useState(true);
    const [cfMessage, setCfMessage] = useState('')

    const [selection, setSelection] = useState([]);


    const [searchStarted, setSearchStarted] = useState(false);
    const [searchLoaded, setSearchLoaded] = useState(false);

    //Modale alunno
    const [dialogDettaglioAlunnoOpen, setDialogDettaglioAlunnoOpen] = useState(false);
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(-1);
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");

    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);

    const [permessiUtente, setPermessiUtente] = useState([]);

    const [canViewExportCsv, setCanViewExportCsv] = useState(true)
    const [exportCsvOpen, setExportCsvOpen] = useState(false);
    const [sedeSelectedExport, setSedeSelectedExport] = useState(-1);
    const [csvExportHasError, setCsvExportHasError] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState("");

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);

            setPermessiUtente(permessiParsed);

            if (permessiParsed.some(permesso => permesso?.id === 17 && permesso?.value === true)) {
                setCanViewExportCsv(true)
            } else setCanViewExportCsv(false)
        }
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])

    useEffect(() => {
        if (props.showCheckbox !== undefined)
            setShowCheckbox(props.showCheckbox);
    }, [props.showCheckbox])


    function validate(e) {
        if (nomeAlunno.length < 3) {
            setNomeAlunno("");
            setNomeValido(true)
            setNomeMessage('');

            if (inputNomeAlunnoRef.current !== undefined)
                inputNomeAlunnoRef.current.value = "";
        }
        e.preventDefault()
        startSearch()
        getAlunni();
    }


    function nomeSet(e) {
        if (e.target.value.length > 2 || e.target.value === "") {
            setNomeAlunno(e.target.value);
            setNomeMessage('');
            setNomeValido(true);
        }
        else {
            setNomeValido(false);
            setNomeMessage('Min. 3 caratteri.');
        }
    };


    function changeSedeExport(e) {
        if (e.target.value !== '') {
            setSedeSelectedExport(e.target.value);
        }
        else {
            setSedeSelectedExport(-1);
        }
    };
    function changeSede(e) {
        if (e.target.value !== '') {

            setSedeSelected(e.target.value);
            getClassiBySede(e.target.value, annoFormativo);
        }
        else {
            setSedeSelected(-1);
            setClasseSelected(-1);
        }
    };

    function changeClasse(e) {
        if (e.target.value !== '') {
            setClasseSelected(e.target.value);
        }
        else {
            setClasseSelected(-1);
        }
    };



    function checkValidateInput() {
        return false
        // if (sedeSelected !== -1 && (nomeAlunno === "" || (nomeValido && nomeAlunno.length > 2)) || (cfValido === "" ||
        //     (cfValido && cfAlunno.length === 16))) {
        //     return false
        // }
        // else {

        //     return true
        // }
    }

    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {
        setNomeAlunno("");
        setCfAlunno("");
        setSedeSelected(-1);
        setClasseSelected(-1);
        setClassiList([])
        setNomeMessage("");
        setNomeValido(true);
        setCfValido(true);
        setCfMessage("");
        if (inputNomeAlunnoRef.current !== undefined)
            inputNomeAlunnoRef.current.value = "";
        //inputCfRef.current.value = "";
        setSelection([])

        setSearchStarted(false)
        setSearchLoaded(false);
    }



    function checkedRow(idArray) {
        if (props.passIdDestinatariSelezionati)
            props.passIdDestinatariSelezionati(idArray);
    }



    function openDettaglioAlunno(idAlunno, nomeAlunno) {
        setIdAlunnoSelezionato(idAlunno);
        setNomeAlunnoSelezionato(nomeAlunno);
        setDialogDettaglioAlunnoOpen(true)
    }

    function closeDettaglioAlunno() {
        setDialogDettaglioAlunnoOpen(false)
    }

    function openAggiungiBes() {
        setAggiungiAlunnoDialogOpen(prev => !prev)
    }

    function openExportCsv() {
        setExportCsvOpen(prev => !prev)
    }

    async function getAlunni() {
        let dataObj = {
            cognome: nomeAlunno !== '' && nomeAlunno.length >= 3 ? nomeAlunno : null,
            idCorso: classeSelected !== -1 ? classeSelected : null,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getAlunniBes(dataObj)
            .then((result) => {
                let risultati = [];
                for (let i = 0; i < result.sedi.length; i++) {
                    let corsiTmp = [];

                    for (let y = 0; y < result.sedi[i].corsi.length; y++) {
                        let corsoTmp = {};
                        corsoTmp.id = result.sedi[i].corsi[y].idCorso;
                        corsoTmp.name = result.sedi[i].corsi[y].corso;

                        let alunniTmp = [];
                        for (let x = 0; x < result.sedi[i].corsi[y].alunni.length; x++) {
                            let alunnoTmp = {};
                            alunnoTmp.id = result.sedi[i].corsi[y].alunni[x].idAlunno;
                            alunnoTmp.cf = result.sedi[i].corsi[y].alunni[x].codiceFiscale;
                            alunnoTmp.name = result.sedi[i].corsi[y].alunni[x].cognomeAlunno + " " + result.sedi[i].corsi[y].alunni[x].nomeAlunno;
                            alunnoTmp.type = "studente";
                            alunniTmp.push(alunnoTmp)
                        }
                        corsoTmp.type = "classe";
                        corsoTmp.children = alunniTmp;
                        corsiTmp.push(corsoTmp)
                    }


                    risultati.push({
                        id: sedeSelected,
                        name: result.sedi[i].sede,
                        type: "sede",
                        children: corsiTmp
                    })

                }
                setRisultatiRicerca([...risultati])
            })
            .catch(function (error) {
                setRisultatiRicerca([])
            })
    }

    async function getClassiBySede(idSede, annoFormativo) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setClassiList([...result.sedi])
            })
            .catch(function (error) {
                if (error.response) { }
            })
    }

    async function getSedi(annoFormativo) {
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])
                setSediListExport([...sediTmp])

            })
            .catch(function (error) {
            })


    }

    async function exportCsv() {
        let dataObj = {
            idSede: sedeSelectedExport,
            annoFormativo: annoFormativo
        }
        if (sedeSelectedExport !== -1) {

            const encryptedToken = localStorage.getItem('tkn');
            let token = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8)
            let headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            axios.post(process.env.REACT_APP_FETCH_URL + "/alunni/BES/exportcsv", dataObj, { headers, responseType: 'blob' })
                .then((response) => {
                    setCsvExportHasError(false)
                    let contentDisposition = response.headers['content-disposition'];
                    let filename = "";
                    if (contentDisposition) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    exportFile(response.data, filename, 'text/csv;charset=utf-8;');
                    setExportCsvOpen(false)
                    setAlertMsg("File esportato correttamente")
                    setAlertSeverity("success");
                    setAlertOpen(true);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setCanViewExportCsv(false);
                        setExportCsvOpen(false)
                        setAlertMsg("Non disponi dei permessi necessari per eseguire questa operazione")
                        setAlertSeverity("error");
                        setAlertOpen(true);
                    } else {
                        setCsvExportHasError(true)
                    }
                });
        }
    }

    const exportFile = (data, fileName, type) => {
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <Fragment>

            {!isInsideOtherComponent ? (
                <Breadcrumb sections={["Tracciati Bes"]} />
            ) : null}


            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 5 }}>
                    <form onSubmit={validate} style={{ width: '100%' }}>
                        <Grid container spacing={2} sx={{ width: '100%', pt: 2, px: 3 }} alignItems={"start"}>
                            <Grid xs={12} md={3}>
                                <TextField sx={{ width: '100%' }} data-testid='nomeRicercaInput' error={(nomeValido) ? false : true}
                                    label="Cognome" onChange={nomeSet} helperText={nomeMessage} inputRef={inputNomeAlunnoRef} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="sede-label">
                                        Seleziona sede
                                    </InputLabel>
                                    <Select
                                        data-testid='sedeRicercaSelect'
                                        label="Seleziona sede"
                                        labelId="sede-label"
                                        value={sedeSelected}
                                        onChange={changeSede}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                        </MenuItem>
                                        {sediList.map((sede) => (
                                            <MenuItem
                                                key={sede.id}
                                                value={sede.id}
                                            >
                                                {sede.nomeSede}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="classe-label">Corso</InputLabel>
                                    <Select
                                        // error={(ricercaValida) ? false : true}
                                        disabled={sedeSelected !== -1 || classiList.length !== 0 ? false : true}
                                        data-testid='classeRicercaSelect'
                                        label="Corso"
                                        labelId="classe-label"
                                        value={classeSelected}
                                        onChange={changeClasse}
                                    >
                                        <MenuItem
                                            key={-1}
                                            value={-1}
                                        >
                                        </MenuItem>
                                        {classiList.map((classe) => (
                                            <MenuItem style={{ whiteSpace: 'normal' }} key={classe.id} value={classe.id} sx={{ wordBreak: 'break-all' }}>
                                                {classe.nomeCorso}
                                            </MenuItem> // .nomeCorso
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>



                        </Grid>

                        <Box sx={{ py: 2, px: 5, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: "end" }}>
                            {searchStarted && (
                                <Button sx={{ height: '100%', px: { xs: 0, md: 5 }, width: { xs: '100%', md: 'fit-content' } }} onClick={removeFilters} variant="outlined" endIcon={<Clear />}>
                                    Rimuovi filtri
                                </Button>
                            )}
                            <Button sx={{ ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, height: '100%', width: { xs: '100%', md: 'fit-content' } }} onClick={validate} endIcon={<SearchIcon />} disabled={classeSelected === -1 && nomeAlunno.length === 0} variant="contained">
                                Avvia ricerca
                            </Button>
                            <Button sx={{ ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, height: '100%', width: { xs: '100%', md: 'fit-content' } }} onClick={openAggiungiBes} endIcon={<Add />} color="success" variant="contained">
                                Aggiungi bes
                            </Button>
                            {canViewExportCsv && (
                                <Button sx={{ ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, height: '100%', width: { xs: '100%', md: 'fit-content' } }} onClick={openExportCsv} endIcon={<Download />} color="secondary" variant="contained">
                                    Export csv
                                </Button>
                            )}
                        </Box>
                    </form>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ p: 2, overflowY: 'auto' }}>

                        {risultatiRicerca.length > 0 ?
                            (<TreeViewRicerca openDettaglioAlunno={openDettaglioAlunno} risultatiRicerca={risultatiRicerca} showCheckbox={showCheckbox} checkedRow={checkedRow} />)
                            : (<Typography align='center'> La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Typography>)}

                    </Paper>
                </Collapse>
            </Container>

            <Dialog
                sx={{ mt: '8vh' }}
                fullWidth={true}
                maxWidth={'lg'}
                open={dialogDettaglioAlunnoOpen}
                onClose={closeDettaglioAlunno}
            >
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} variant="h6">
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {t("pages.ricercaAlunni.dettaglio")} ({nomeAlunnoSelezionato})
                    </Typography>
                    <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />

                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno idCorso={classeSelected} dataAttuale={DateTime.local()} showBes={true} tabIndex={4} nomeAlunno={nomeAlunnoSelezionato} id={idAlunnoSelezionato} comesFromTracciato={true} permessiUtente={permessiUtente} tipo={true} />
                </DialogContent>

            </Dialog>

            <Dialog open={aggiungiAlunnoDialogOpen} fullWidth={true} maxWidth={'xd'} sx={{ mt: '8vh' }}>


                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} variant="h6">
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Aggiungi bes ad alunno
                    </Typography>
                    <IconButton onClick={openAggiungiBes} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RicercaStudente isDialog={true} isBesPage={true} besAggiunto={openAggiungiBes} />
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={exportCsvOpen}>
                <DialogContent>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="sede-label">
                            Seleziona sede
                        </InputLabel>
                        <Select
                            data-testid='sedeRicercaSelect'
                            label="Seleziona sede"
                            labelId="sede-label"
                            value={sedeSelectedExport}
                            onChange={changeSedeExport}
                        >
                            <MenuItem
                                key={-1}
                                value={-1}
                            >
                            </MenuItem>
                            {sediListExport.map((sede) => (
                                <MenuItem
                                    key={sede.id}
                                    value={sede.id}
                                >
                                    {sede.nomeSede}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {csvExportHasError && (
                        <Typography sx={{ mt: 1 }} color="error">Errore durante l'export del CSV.</Typography>
                    )}
                    <Button fullWidth variant='outlined' sx={{ mt: 2 }} onClick={openExportCsv}>Chiudi</Button>
                    <Button fullWidth disabled={sedeSelectedExport === -1} sx={{ mt: 1 }} onClick={exportCsv} endIcon={<Download />} color="success" variant="contained">
                        Export csv
                    </Button>
                </DialogContent>
            </Dialog>

            {!isInsideOtherComponent ? (
                <Helmet>
                    <title>{t("name")} - {t("pages.ricercaAlunni.title")}</title>
                </Helmet>
            ) : null}

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    );
}