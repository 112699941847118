import * as React from 'react';
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from "react-router-dom";
import ErrorImg from "../img/404.png";
import Logo from "../img/logo.png";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export default function ErrorPage() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  function goToHome() {
    navigate("/home")
  }

  return (
    <Fragment>
      <Container fixed sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
        <Box mb={{ xs: 2, md: 0 }}><img src={Logo}  alt="CIOFS logo" height="60" /></Box>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={12} md={4}>
            <Typography textAlign={{ xs: 'center', md: 'start' }} variant="h4">Ops! <br/>{t("pages.errorPage.pageMessage")}</Typography>
            <Button fullWidth sx={{ mt: 5 }} onClick={goToHome} startIcon={<ArrowBackIcon />} data-testid='backHomeBtn' variant="contained">
              {t("pages.errorPage.backToHome")}
            </Button>
          </Grid>
          <Grid xs={12} md={8} display={{xs:'none',md:'block'}}>
            {/*  */}
            <img src={ErrorImg} draggable="false" alt="CIOFS logo" style={{maxWidth:'100%'}} />
          </Grid>
        </Grid>


      </Container>
      <Helmet>
        <title>{t("name")} - {t("pages.errorPage.title")}</title>
      </Helmet>
    </Fragment>
  )
}