import { Fragment, useEffect, useState } from "react";
import { Box, Chip, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button, Divider } from "@mui/material";
import { Assignment, AssignmentLate, CalendarToday, Close, Description, Info } from "@mui/icons-material";
import TestEvento from "../testEvento";

import EventoVotoRegistro from "../eventi/eventoVotoRegistro";
import EventoCompitoRegistro from "../eventi/eventoCompitoRegistro";
import ModificaCompiti from "../../eventi/compiti/modificaCompiti";
import ModificaVerifica from "../../eventi/verifiche/modificaVerifica";
import ModificaAnnotazioni from "../../eventi/annotazioni/modificaAnnotazione";
import ModificaProgrammi from "../../eventi/argomenti/modificaArgomenti";
import ModificaNoteDisciplinari from "../../eventi/noteDisciplinari/modificaNoteDisciplinari";
import EventoPrerequisitoRegistro from "../eventi/eventoPrerequisitoRegistro";
import GestioneNoteDisciplinari from "../../eventi/noteDisciplinari/gestioneNoteDisciplinari";
import GestioneCompiti from "../../eventi/compiti/gestioneCompiti";
import GestioneVerifiche from "../../eventi/verifiche/gestioneVerifiche";
import GestioneArgomenti from "../../eventi/argomenti/gestioneArgomenti";
import GestioneAnnotazioni from "../../eventi/annotazioni/gestioneAnnotazioni";

import { convertDataDiNascita } from "../../shared/metodiDateUtility";

import useMediaQuery from '@mui/material/useMediaQuery';


export default function EventoChip(props) {


    const [permessiUtente, setPermessiUtente] = useState([]);
    const [openElenco, setOpenElenco] = useState(false);

    const [idComunicazione, setIdComunicazione] = useState(null);
    const [comunicazioneObj, setComunicazioneObj] = useState(null);
    const [oreRegistro, setOreRegistro] = useState([]);
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [idDocente, setIdDocente] = useState(-1);
    const [alunno, setAlunno] = useState(null);
    const [idMateria, setIdMateria] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [dataRichiesta, setDataRichiesta] = useState(null)
    const [isDocente] = useState(false);

    const [listAlunni, setListAlunni] = useState([]);

    const [cantModify, setCantModify] = useState(false)
    const [showTitle] = useState(false);
    const [tipoComunicazioneObj, seTtipoComunicazioneObj] = useState(null);
    const [statoAppello, setStatoAppello] = useState(0);
    const [showDialog, setShowDialog] = useState(false);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")

    const [isListaEventi, setIsListaEventi] = useState(false);
    const [canChangeColorVoto, setCanChangeColorVoto] = useState(false);

    const matches = useMediaQuery('(min-width:500px)');

    let comunicazioniList = [
        {
            id: 0,
            tipo: 'comunicazione',
            colorChip: 'error',
            bgColor: 'error.light',
            descrizione: 'Comunicazione interna',
            icon: <AssignmentLate />,
            variant: 'contained',
            componenteAssociato: <TestEvento idComunicazione={idComunicazione} />
        },
        {
            id: 1,
            tipo: 'nota',
            colorChip: 'error',
            bgColor: 'error.light',
            descrizione: 'Nota disciplinare',
            icon: <AssignmentLate />,
            variant: 'contained',
            componenteAssociato: <TestEvento idComunicazione={idComunicazione} />
        },
        {
            id: 2,
            tipo: 'compito',
            colorChip: 'primary',
            bgColor: 'primary.light',
            descrizione: 'Compiti assegnati',
            icon: <Assignment />,
            variant: 'contained',
            componenteAssociato: <EventoCompitoRegistro closeDialog={openDialog} comunicazione={comunicazioneObj} />
        },
        {
            id: 3,
            tipo: 'verifica',
            colorChip: 'warning',
            bgColor: 'warning.light',
            descrizione: 'Verifica programmata',
            icon: <CalendarToday />,
            variant: 'contained',
            componenteAssociato: <TestEvento idComunicazione={idComunicazione} />
        },
        {
            id: 4,
            tipo: 'voto',
            colorChip: 'secondary',
            bgColor: 'secondary.light',
            descrizione: 'Voto',
            variant: 'outlined',
            componenteAssociato: <EventoVotoRegistro comunicazione={comunicazioneObj} />
        },
        {
            id: 5,
            tipo: 'valutazione',
            colorChip: 'secondary',
            bgColor: 'secondary.light',
            descrizione: 'Valutazione',
            variant: 'outlined',
            componenteAssociato: <EventoVotoRegistro comunicazione={comunicazioneObj} />
        },
        {
            id: 6,
            tipo: 'argomenti',
            colorChip: 'grey',
            bgColor: 'grey',
            descrizione: 'Argomenti svolti',
            icon: <Info />,
            variant: 'contained',
            componenteAssociato: <TestEvento idComunicazione={idComunicazione} />
        },
        {
            id: 7,
            tipo: 'annotazione',
            colorChip: 'orange',
            bgColor: 'orange',
            descrizione: 'Annotazione',
            icon: <Description sx={{ color: 'orange', fontSize: '2rem' }} />,
            variant: 'outlined',
            componenteAssociato: <TestEvento idComunicazione={idComunicazione} />
        },
        {
            id: 8,
            tipo: 'prerequisiti',
            colorChip: 'green',
            bgColor: 'green',
            descrizione: 'Gestione ex ante/accertamento prerequisiti ',
            variant: 'outlined',
            componenteAssociato: <EventoPrerequisitoRegistro comunicazione={comunicazioneObj} />
        },
    ];
    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])

    useEffect(() => {
        if (props.canChangeColorVoto !== undefined)
            setCanChangeColorVoto(props.canChangeColorVoto)
    }, [props.canChangeColorVoto])

    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])
    useEffect(() => {
        if (props.isListaEventi !== undefined)
            setIsListaEventi(props.isListaEventi)
    }, [props.isListaEventi])
    useEffect(() => {
        if (props.openElenco !== undefined)
            setOpenElenco(props.openElenco)
        if (props.idMateria !== undefined)
            setIdMateria(props.idMateria)
    }, [props.openElenco, props.idMateria])

    useEffect(() => {
        if (props.idDocente !== undefined)
            setIdDocente(props.idDocente)
    }, [props.idDocente])

    useEffect(() => {
        if (props.statoAppello !== undefined)
            setStatoAppello(props.statoAppello)
    }, [props.statoAppello])

    useEffect(() => {
        if (props.idCorso !== undefined)
            setIdCorso(props.idCorso)
    }, [props.idCorso])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro)
    }, [props.tipoRegistro])

    useEffect(() => {
        if (props.cantModify !== undefined)
            setCantModify(props.cantModify)
    }, [props.cantModify])

    useEffect(() => {
        if (props.dataRichiesta !== undefined)
            setDataRichiesta(props.dataRichiesta)
    }, [props.dataRichiesta])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
        }
    }, [props.permessiUtente])

    useEffect(() => {
        if (props.oreRegistro !== undefined)
            setOreRegistro([...props.oreRegistro]);
    }, [props.oreRegistro])

    useEffect(() => {
        if (props.listAlunni !== undefined)
            setListAlunni([...props.listAlunni]);
    }, [props.listAlunni])

    useEffect(() => {
        if (props.objComunicazione !== undefined) {
            setComunicazioneObj(props.objComunicazione);
            setIdComunicazione(props.objComunicazione.tipologia_comunicazioni);
            const listComunicazioni = comunicazioniList;
            let tmpIndex = listComunicazioni.findIndex((comunicazioneTmp) => comunicazioneTmp.id === props.objComunicazione.tipologia_comunicazioni);
            if (tmpIndex !== -1) {
                seTtipoComunicazioneObj(listComunicazioni[tmpIndex]);
            }
            else seTtipoComunicazioneObj(null)
        }
        // eslint-disable-next-line 
    }, [props.objComunicazione])

    useEffect(() => {
        if (props.alunno !== undefined) {
            setAlunno(props.alunno)
        }
    }, [props.alunno])


    function openDialog() {
        if (statoAppello !== 1 && statoAppello !== 3)
            setShowDialog(true);
    }

    function closeDialog() {
        setShowDialog(false);

        if (props.getRegistro)
            props.getRegistro();
    }

    function aggiornaRegistro(alertMsg, alertSeverity) {
        if (props.aggiornaRegistro) props.aggiornaRegistro(alertMsg, alertSeverity)
    }

    function eventoAggiunto(alertMsg, alertSeverity) {
        if (props.eventoAggiunto)
            props.eventoAggiunto(alertMsg, alertSeverity)
    }

    function changeColorVoto(votoTmp){
        if (votoTmp != undefined && votoTmp != null && votoTmp != "" && votoTmp != "N.V." && votoTmp != "/")
        {
            if(votoTmp < 18) return "red"
            else return "green"
        } else return "black"
    }

    return (
        <Fragment>
            <Box onClick={!matches ? openDialog : null} sx={{ display: isListaEventi ? 'flex' : 'unset', justifyContent: isListaEventi ? 'space-between' : 'unset', alignItems: 'center', width: isListaEventi ? '100%' : 'unset', border: !matches ? 'solid rgba(224, 224, 224, 1) 1px' : '0px', borderRadius: !matches ? 2 : null, py: !matches ? 1 : null, cursor: !matches ? 'pointer' : 'unset' }}>
                {tipoComunicazioneObj !== null && (
                    showTitle ? (
                        <Chip sx={{ p: 1, mx: 1 }} color={tipoComunicazioneObj.colorChip} variant={tipoComunicazioneObj.variant}
                            label={tipoComunicazioneObj.descrizione} icon={tipoComunicazioneObj.icon} onClick={openDialog} />
                    ) : (
                        <Box width={"30px"} height={"30px"} minWidth={'30px'} onClick={openDialog}
                            border={tipoComunicazioneObj?.variant === "outlined" ? "1px solid grey" : "none"}
                            borderColor={tipoComunicazioneObj.bgColor}
                            backgroundColor={tipoComunicazioneObj?.variant === "contained" ? tipoComunicazioneObj?.bgColor : "white"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white', cursor: (statoAppello !== 1 && statoAppello !== 3) ? 'pointer' : 'default' }}>
                            {tipoComunicazioneObj?.icon !== undefined ? (
                                tipoComunicazioneObj?.icon
                            ) : (
                                <Fragment>
                                    {tipoComunicazioneObj?.id === 4 && (
                                                <Typography fontWeight={"bold"} color={canChangeColorVoto == true ? changeColorVoto(comunicazioneObj?.contenuto?.voto):  tipoComunicazioneObj?.bgColor}>{comunicazioneObj?.contenuto?.voto !== "0" ? comunicazioneObj?.contenuto?.voto : "N.V."}</Typography>
                                    )}
                                    {tipoComunicazioneObj?.id === 5 && (
                                        <Typography fontWeight={"bold"} color={tipoComunicazioneObj?.bgColor}>{comunicazioneObj?.contenuto?.tipologia}</Typography>

                                    )}
                                    {tipoComunicazioneObj?.id === 8 && (
                                                <Typography fontWeight={"bold"} color={canChangeColorVoto == true ? changeColorVoto(comunicazioneObj?.contenuto?.voto) : tipoComunicazioneObj?.bgColor}>{comunicazioneObj?.contenuto?.voto !== "0" ? comunicazioneObj?.contenuto?.voto : "N.V."}</Typography>
                                    )}
                                </Fragment>
                            )}
                        </Box>

                    )
                )
                }
                {isListaEventi && (<Typography mx={2}>Data di inserimento: <b>{convertDataDiNascita(comunicazioneObj?.created_at)}</b></Typography>)}
                {(isListaEventi && matches) && (<Button onClick={openDialog} size="small" variant="contained" sx={{ px: 2, mr: 1 }}> dettaglio</Button>)}
            </Box>

            <Dialog open={showDialog} fullWidth={true} maxWidth={(tipoComunicazioneObj?.id === 4 || tipoComunicazioneObj?.id === 5) ? 'md' : "xl"} onClose={closeDialog} sx={{ mt: '8vh' }}>

                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {tipoComunicazioneObj?.descrizione}
                    </Typography>
                    <IconButton onClick={closeDialog} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {tipoComunicazioneObj?.id === 1 && (
                        openElenco ? (
                            <GestioneNoteDisciplinari dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} permessiUtente={permessiUtente} tipoRegistro={tipoRegistro} dataRichiesta={dataRichiesta} idCorso={idCorso} showOnlyElenco={true} idMateria={idMateria} isDialog={true} listAlunni={listAlunni} />
                        ) : (
                            <ModificaNoteDisciplinari dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} permessiUtente={permessiUtente} notaSel={comunicazioneObj} listAlunni={listAlunni} closeDialog={closeDialog} />
                        )
                        // <EventoNotaDiscplinareEvento closeDialog={openDialog} comunicazione={comunicazioneObj} />
                    )}
                    {tipoComunicazioneObj?.id === 2 && (
                        openElenco ? (
                            <GestioneCompiti dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} permessiUtente={permessiUtente} tipoRegistro={tipoRegistro} dataRichiesta={dataRichiesta} idCorso={idCorso} showOnlyElenco={true} idMateria={idMateria} isDialog={true} listAlunni={listAlunni} />
                        ) : (
                            <ModificaCompiti dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} permessiUtente={permessiUtente} compitoSel={comunicazioneObj} listAlunni={listAlunni} closeDialog={closeDialog} />
                        )
                    )}
                    {tipoComunicazioneObj?.id === 3 && (
                        openElenco ? (
                            <GestioneVerifiche dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} permessiUtente={permessiUtente} tipoRegistro={tipoRegistro} dataRichiesta={dataRichiesta} idCorso={idCorso} showOnlyElenco={true} idMateria={idMateria} isDialog={true} listAlunni={listAlunni} />
                        ) : (
                            <ModificaVerifica dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} permessiUtente={permessiUtente} verificaSel={comunicazioneObj} listAlunni={listAlunni} closeDialog={closeDialog} />
                        )
                    )}
                    {(tipoComunicazioneObj?.id === 4 || tipoComunicazioneObj?.id === 5) && (
                        <EventoVotoRegistro  dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} cantModify={cantModify} isDocente={isDocente} permessiUtente={permessiUtente} closeDialog={closeDialog} oreRegistro={oreRegistro} comunicazione={comunicazioneObj} alunno={alunno} aggiornaRegistro={aggiornaRegistro} />
                    )}
                    {tipoComunicazioneObj?.id === 6 && (
                        openElenco ? (
                            <GestioneArgomenti dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} permessiUtente={permessiUtente} tipoRegistro={tipoRegistro} dataRichiesta={dataRichiesta} idCorso={idCorso} showOnlyElenco={true} idMateria={idMateria} isDialog={true} listAlunni={listAlunni} />
                        ) : (
                            <ModificaProgrammi dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} permessiUtente={permessiUtente} closeDialog={closeDialog} programmaSel={comunicazioneObj} />
                        )
                    )}
                    {tipoComunicazioneObj?.id === 7 && (
                        openElenco ? (
                            <GestioneAnnotazioni dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} permessiUtente={permessiUtente} tipoRegistro={tipoRegistro} dataRichiesta={dataRichiesta} idCorso={idCorso} showOnlyElenco={true} idMateria={idMateria} isDialog={true} listAlunni={listAlunni} />
                        ) : (
                            <ModificaAnnotazioni dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} permessiUtente={permessiUtente} annotazioneSel={comunicazioneObj} listAlunni={listAlunni} closeDialog={closeDialog} />
                        )
                    )}
                    {tipoComunicazioneObj?.id === 8 && (
                        <EventoPrerequisitoRegistro dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} cantModify={cantModify} isDocente={isDocente} permessiUtente={permessiUtente} closeDialog={closeDialog} oreRegistro={oreRegistro} comunicazione={comunicazioneObj} alunno={alunno} aggiornaRegistro={aggiornaRegistro} />
                    )}

                </DialogContent>
            </Dialog>
        </Fragment >
    );



}



