import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function SelettoreAzienda(props) {

    const [idAziendaSelected, setIdAziendaSelected] = useState(-1);
    const [listAziende, setListAziende] = useState([]);

    useEffect(() => {
        if (props.listAziende !== undefined) setListAziende(props.listAziende)
    }, [props.listAziende])

    useEffect(() => {
        if (props.idAziendaSelected !== undefined) setIdAziendaSelected(props.idAziendaSelected)
    }, [props.idAziendaSelected])

    function changeIdAziendaSelezionata(idAzienda) {
        if (props.changeIdAziendaSelezionata) props.changeIdAziendaSelezionata(idAzienda)
    }
    return (
        <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
            <Table >
                <TableBody>
                    {listAziende.map((azienda, index) => (
                        <TableRow key={azienda?.id + '_azienda'} sx={{ cursor: 'pointer' }} onClick={() => changeIdAziendaSelezionata(azienda?.id)}>
                            <TableCell sx={{ minWidth: '200px', background: (azienda?.id === idAziendaSelected) ? '#1976D2' : 'white', color: (azienda?.id === idAziendaSelected) ? 'white' : 'black' }}>
                                <Typography>
                                    {'Azienda: ' + azienda?.ragioneSociale + ' - P.iva: ' + azienda?.pIva}{(azienda?.id === idAziendaSelected) ? " - SELEZIONATA" : ""}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}