import { Table, Box, Dialog, DialogTitle, DialogContent, Paper, Typography, Button, Container, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment } from "react"
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { Close } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";

import { EventiAPI } from "../../../api/eventiApi";
import { convertISODate, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import ModificaAnnotazioni from "./modificaAnnotazione";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";
export default function ElencoAnnotazioni(props) {

    const [dataStart, setDataStart] = useState(null);
    const [dataEnd, setDataEnd] = useState(null);
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [dataLezioneStart, setDataLezioneStart] = useState(null);
    const [dataLezioneEnd, setDataLezioneEnd] = useState(null);

    const [elencoAnnotazioni, setElencoAnnotazioni] = useState([]);
    const [annotazioneSel, setAnnotazioneSel] = useState({});
    const [idAlunno, setIdAlunno] = useState({});
    const [idCorso, setIdCorso] = useState(-1);
    const [idMateria, setIdMateria] = useState(-1);
    const [permessiUtente, setPermessiUtente] = useState([])

    const [listAlunni, setListAlunni] = useState([]);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [showOnlyElenco, setShowOnlyElenco] = useState(false);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null);
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null);


    useEffect(() => {
        if (props.listAlunni !== undefined) {
            setListAlunni([...props.listAlunni]);
        }
    }, [props.listAlunni])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro)
    }, [props.tipoRegistro])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente]);
        }
    }, [props.permessiUtente])


    useEffect(() => {
        if (props.idCorso !== undefined && props.idMateria !== undefined) {

            let objDateInteresse = getDataInizioFinePeriodo();
            let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
            setDataInizioPeriodo(inizioPeriodoTmp ?? null);
            let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
            setDataFinePeriodo(finePeriodoTmp ?? null);

            setIdAlunno(props.alunno?.id);
            setIdCorso(props.idCorso);
            setIdMateria(props.idMateria);
            let showOnlyElenco = props.showOnlyElenco ?? false;
            setShowOnlyElenco(showOnlyElenco)

            if (objDateInteresse?.isAnnoFormativoCorrente)
                getElencoAnnotazioni(props.alunno?.id, props.idCorso, props.idMateria, false, showOnlyElenco)
            else {
                getElencoAnnotazioni(props.alunno?.id, props.idCorso, props.idMateria, true, showOnlyElenco)
            }

        }
    }, [props.alunno, props.idCorso, props.idMateria, props.showOnlyElenco, props.dataRichiesta])

    function changeDataStart(value) {
        setDataStart(value);
    }

    function changeDataEnd(value) {
        setDataEnd(value);
    }

    function changeDataLezioneStart(value) {
        setDataLezioneStart(value);
    }

    function changeDataLezioneEnd(value) {
        setDataLezioneEnd(value);
    }

    function filtro() {
        getElencoAnnotazioni(idAlunno, idCorso, idMateria, false);
    }

    function rimuoviFiltro() {
        setDataEnd(null);
        setDataStart(null);
        setDataLezioneEnd(null);
        setDataLezioneStart(null);
        getElencoAnnotazioni(idAlunno, idCorso, idMateria, true);
    }

    function openDialog(annotazione, index) {
        setAnnotazioneSel(annotazione);
        setDialogIsOpen(true);
    }

    function closeDialog() {
        setDialogIsOpen(false);
        getElencoAnnotazioni(idAlunno, idCorso, idMateria, false);
    }


    function isSearchButtonDisabled() {
        if (((dataStart == null && dataEnd == null) || (dataStart != null && dataEnd != null)) && ((dataLezioneStart == null && dataLezioneEnd == null) || (dataLezioneStart != null && dataLezioneEnd != null)))
            return false
        else return true
    }


    async function getElencoAnnotazioni(idAlunno, idCorso, idMateria, skipDate, showOnlyElenco) {

        let dataStartTmp = null;
        let dataEndTmp = null;

        let dataLezioneStartTmp = null;
        let dataLezioneEndTmp = null;

        let soloInteraClasse = false;

        if (skipDate === undefined || skipDate == null || skipDate == false) {
            if (dataStart != null && dataEnd !== null) {
                let dataConverted = convertDataGetProssimeLezione(dataStart)
                dataStartTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataEnd)
                dataEndTmp = dataConvertedEnd;
            } else {
                setDataStart(null);
                setDataEnd(null)
            }
            if (dataLezioneStart != null && dataLezioneEnd !== null) {
                let dataConverted = convertDataGetProssimeLezione(dataLezioneStart)
                dataLezioneStartTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataLezioneEnd)
                dataLezioneEndTmp = dataConvertedEnd;
            } else {

                if (!showOnlyElenco) {
                    setDataLezioneStart(null);
                    setDataLezioneEnd(null)
                }
                else {
                    soloInteraClasse = true;
                    if (props.dataRichiesta != null) {
                        let dateObject = DateTime.fromSQL(props.dataRichiesta);
                        let dataConverted = convertDataGetProssimeLezione(dateObject, true)
                        dataLezioneStartTmp = dataConverted;
                        dataLezioneEndTmp = dataConverted;
                        setDataLezioneStart(dateObject)
                        setDataLezioneEnd(dateObject)
                    }
                }
            }



        }
        let obj = {
            idCorso: idCorso,
            idMateria: idMateria,
            idAlunno: idAlunno,
            // dataRichiestaDa: dataStartTmp,
            // dataRichiestaA: dataEndTmp,
            dataInserimentoDa: dataStartTmp,
            dataInserimentoA: dataEndTmp,
            soloInteraClasse: soloInteraClasse,

            dataLezioneDa: dataLezioneStartTmp,
            dataLezioneA: dataLezioneEndTmp
        }
        if (idCorso !== -1) {
            EventiAPI.elencotAnnotazioni(obj)
                .then((result) => {
                    let annotazioniTmp = [];
                    for (let annotazione of result) {
                        let annotazioneTmp = annotazione;
                        if (annotazione.contenuto.note.length >= 20) {
                            let descBreve = annotazione.contenuto.note.slice(0, 20) + '...';
                            annotazioneTmp.contenuto.descrBreve = descBreve;
                        } else annotazioneTmp.contenuto.descrBreve = annotazione.contenuto.note;


                        annotazioniTmp.push(annotazioneTmp);
                    }
                    setElencoAnnotazioni([...annotazioniTmp])
                })
                .catch((error) => {
                    if (error.response.status === 401) console.log("Errore di autenticazione")
                    if (error.response.status === 403) props.logout();
                })
        }
    }


    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} fullWidth maxWidth="xl">
                <Grid container spacing={2} mt={{ xs: 2, md: 0 }}>
                    <Grid xs={12} md={3}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataEnd != null ? dataEnd : dataFinePeriodo}
                                label="Periodo (inserimento) dal"
                                value={dataStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataStart != null ? dataStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}
                                label="Periodo (inserimento) al"
                                value={dataEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataLezioneEnd != null ? dataLezioneEnd : dataFinePeriodo}
                                label="Periodo (Lezione) dal"
                                value={dataLezioneStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataLezioneStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataLezioneStart != null ? dataLezioneStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}
                                label="Periodo (Lezione) al"
                                value={dataLezioneEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataLezioneEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box mb={2}>
                            {((dataStart != null && dataEnd == null) || (dataStart == null && dataEnd != null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date di inserimento</b> per applicare il filtro.
                                </Typography>
                            )}
                            {((dataLezioneStart != null && dataLezioneEnd == null) || (dataLezioneStart == null && dataLezioneEnd != null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date della Lezione</b> per applicare il filtro.
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: { xs: '100%', md: 'fit-content' } }}>
                            <Button onClick={() => rimuoviFiltro()} sx={{ ml: { xs: 0, md: 2 }, mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="outlined" startIcon={<Close />}>
                                Rimuovi filtri
                            </Button>
                            <Button disabled={isSearchButtonDisabled()} onClick={() => filtro()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="contained" startIcon={<FilterList />}>
                                Filtra
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Paper sx={{ minHeight: '20vh', maxHeight: '60vh', px: 2, overflowY: 'scroll', mt: 4, mb: 1 }}>
                    {elencoAnnotazioni.length > 0 ? (

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data di inserimento</TableCell>

                                    <TableCell>Data lezione</TableCell>

                                    <TableCell>Descrizione breve</TableCell>
                                    <TableCell align="right"></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {elencoAnnotazioni.map((annotazione, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{convertISODate(annotazione?.created_at)}</TableCell>

                                        <TableCell component="th" scope="row">{convertISODate(annotazione?.contenuto?.dataLezione)}</TableCell>

                                        <TableCell component="th" scope="row">
                                            {annotazione?.contenuto?.descrBreve}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            <Button onClick={() => openDialog(annotazione, index)} size="small" variant="contained" data-testid='leggiComBtn'>Apri dettaglio</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    ) : (
                        <Box sx={{ width: '100%', height: '20vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography >Nessun'annotazione assegnata nel periodo selezionato</Typography>
                        </Box>
                    )}

                </Paper>
                <Dialog fullWidth maxWidth={"xl"} open={dialogIsOpen} onClose={closeDialog} sx={{ mt: '8vh' }}>
                    <DialogTitle>
                        Dettaglio annotazione

                    </DialogTitle>
                    <DialogContent>
                        <ModificaAnnotazioni tipoRegistro={tipoRegistro} permessiUtente={permessiUtente} annotazioneSel={annotazioneSel} listAlunni={listAlunni} closeDialog={closeDialog} />
                    </DialogContent>
                </Dialog>
            </Container>
        </Fragment>
    )



}