import * as React from 'react';

import { Fragment, useState, useEffect, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Link, Paper, Button, TextField, Box, Container, Typography, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, IconButton, Breadcrumbs, useTheme, Snackbar, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Search, Help, ArrowBack, Description, Close, Edit, ErrorOutline, Person, Check, Delete } from "@mui/icons-material";
import { DateTime } from "luxon";
import useMediaQuery from '@mui/material/useMediaQuery';

import { ColloquiAPI } from '../../../api/colloquiApi';
import DettaglioStudenteAnnotazioni from './dettaglioStudenteAnnotazioni';
import LoadingComponent from '../../shared/loadingComponent';
import { useNavigate, useLocation } from 'react-router-dom';

// import { convertDataDiNascita, convertDataGetProssimeLezione } from '../../shared/metodiDateUtility';
import { convertDataDiNascita } from '../../shared/metodiDateUtility';
import LegendeColloquio from './legendeColloquio';
import DialogConferma from '../../shared/dialogConferma';
import Breadcrumb from '../../registri/shared/breadcrumb';

export default function DettaglioColloquio(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const location = useLocation();

    const [insertAvailable, setInsertAvailable] = useState(false);

    const [nomeSede, setNomeSede] = useState('');
    const [annoFormativo, setAnnoFormativo] = useState('2023/2024'); // TODO: da reimpostare a ''
    const [colloquio, setColloquio] = useState({});
    const [dataColloquio, setDataColloquio] = useState('');
    const [idColloquio, setIdColloquio] = useState(-1);
    const [ordineColloquio, setOrdineColloquio] = useState(0);
    const [docentiList, setDocentiList] = useState([]);
    const [tutorList, setTutorList] = useState([]);
    const [alunniList, setAlunniList] = useState([]);
    const [direttore, setDirettore] = useState(null);
    const [coordinatore, setCoordinatore] = useState(null);

    const [annotazioneSel, setAnnotazioneSel] = useState(null);
    const [isDialogAnnotazioniOpen, setIsDialogAnnotazioniOpen] = useState(false);
    const [studenteSel, setStudenteSel] = useState({});
    const [isDialogStudenteOpen, setIsDialogStudenteOpen] = useState(false);
    const [isDialogSoggettoPresenteOpen, setIsDialogSoggettoPresenteOpen] = useState(false);
    const [isDialogLegendaOpen, setIsDialogLegendaOpen] = useState(false);
    const [isAnnotazioneUtente, setIsAnnotazioneUtente] = useState(false);
    const [isDettaglioUtente, setIsDettaglioUtente] = useState(false);
    const [isDialogConfermaOpen, setIsDialogConfermaOpen] = useState(false);
    const [testoConferma, setTestoConferma] = useState('');

    const refTestoAnnotazione = useRef(null)

    const [isLoading, setIsLoading] = useState(true);
    const [thereIsAnError, setThereIsAnError] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [addEditButtonDisabled, setAddEditButtonDisabled] = useState(false);

    const [tipologieSoggettoPresente, setTipologieSoggettoPresente] = useState([]);
    const [soggettoPresenteSel, setSoggettoPresenteSel] = useState('');
    const [confermaPresenzaColloquiDialogOpen, setConfermaPresenzaColloquiDialogOpen] = useState(false);
    const [confermaDeletePresenzaColloquiDialogOpen, setConfermaDeletePresenzaColloquiDialogOpen] = useState(false);
    const [presenzaSel, setPresenzaSel] = useState(null);
    const [isDialogPresenzaOpen, setIsDialogPresenzaOpen] = useState(null);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(null);

    useEffect(() => {
        if (location?.state?.colloquio !== undefined && location?.state?.ordineColloquio !== undefined) {
            setColloquio(location.state.colloquio);
            let dataColloquioTmp = location.state.colloquio.dataColloquio;
            setDataColloquio(dataColloquioTmp);
            setOrdineColloquio(location.state.ordineColloquio);
            let idColloquio = idColloquioSet(location.state.colloquio, location.state.ordineColloquio);
            setIdColloquio(idColloquio);
            riepilogoColloquiCorso(idColloquio);
        } else {
            setColloquio({});
            setOrdineColloquio(0);
        }
        // let sedeTmp = localStorage.getItem("sede");
        // let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        // let sedeParsed = JSON.parse(decryptedSede);
        // setNomeSede(sedeParsed.nome);
        if (location?.state?.sede !== undefined) {
            let sedeTmp = location.state.sede;
            setNomeSede(sedeTmp.nomeSede);
        }
    }, [])

    function idColloquioSet(colloquio, ordineColloquio) {
        let idColloquio;
        switch (ordineColloquio) {
            case 0: idColloquio = colloquio.idColloquio;
                break;
            case 1: idColloquio = colloquio.primoColloquio.idColloquio;
                break;
            case 2: idColloquio = colloquio.secondoColloquio.idColloquio;
                break;
            default: idColloquio = -1
        }

        return idColloquio
    }

    useEffect(() => {
        if (props.annoFormativo !== undefined)
            setAnnoFormativo(props.annoFormativo)
    }, [props.annoFormativo])

    function aggiungiAnnotazione() {
        openDialogConferma('inserimento')
        // addAnnotazione()
    }

    function modificaAnnotazione() {
        openDialogConferma('modifica')
        // editAnnotazione()
    }

    function openDialogLegenda() {
        setIsDialogLegendaOpen(prev => !prev)
    }
    function openDialogConferma(testoConferma) {
        setIsDialogConfermaOpen(prev => !prev)
        setTestoConferma(testoConferma)
    }

    function openDialogStudente(studente) {
        setStudenteSel(studente);
        setIsDialogStudenteOpen(true);
    }

    function openDialogSoggettoPresente(studente) {
        setStudenteSel(studente);
        if (studente.presenzaUtente !== null) setSoggettoPresenteSel(studente.presenzaUtente.soggettoPresente);
        else setSoggettoPresenteSel('');
        getTipologiePresenze();
        setIsDialogSoggettoPresenteOpen(true);
    }

    // function openDialogDeleteSoggettoPresente(studente) {
    //     setStudenteSel(studente);
    //     setSoggettoPresenteSel(studente.presenzaUtente.soggettoPresente);
    //     setConfermaDeletePresenzaColloquiDialogOpen(true);
    // }

    function closeDialogSoggettoPresente() {
        setStudenteSel({});
        setSoggettoPresenteSel('');
        setIsDialogSoggettoPresenteOpen(false);
    }

    function openDialogAnnotazioni(annotazione, studente, isAnnotazioneUtente, isDettaglioUtente) {
        setStudenteSel(studente);
        setAnnotazioneSel(annotazione);
        setIsDialogAnnotazioniOpen(true);
        setIsAnnotazioneUtente(isAnnotazioneUtente);
        setIsDettaglioUtente(isDettaglioUtente);
        setAddEditButtonDisabled(true)
        setTimeout(() => {
            refTestoAnnotazione.current.value = annotazione?.annotazione !== undefined ? annotazione?.annotazione : "";
        }, 300)
    }

    function openDialogPresenza(presenza, studente) {
        if (presenza !== undefined) {
            setSoggettoPresenteSel(presenza.soggettoPresente)
            setStudenteSel(studente);
            setPresenzaSel(presenza);
            setIsDialogPresenzaOpen(true);
            setDeleteButtonDisabled(presenza.isDeletable);
        }
    }

    function closeDialogPresenza() {
        setIsDialogPresenzaOpen(false);
        setSoggettoPresenteSel('')
        setStudenteSel({})
        setPresenzaSel(null)
        setDeleteButtonDisabled(false)
    }

    function closeDialogAnnotazioni() {
        refTestoAnnotazione.current.value = ''
        setIsDialogAnnotazioniOpen(false);
        setTimeout(() => {
            setAnnotazioneSel(null);
        }, 100)
    }

    function closeDialogStudente() {
        setStudenteSel({});
        // refTestoAnnotazione.current.value = ''
        setAnnotazioneSel(null);
        setIsDialogStudenteOpen(false);
    }

    function changeTestoAnnotazione(e, valueInput) {
        refTestoAnnotazione.current.value = e.target.value;
        refTestoAnnotazione.current.value === valueInput ? setAddEditButtonDisabled(true) : setAddEditButtonDisabled(false);
    }

    function backToListColloqui() {
        const nuovoStato = { ...location.state };
        delete nuovoStato['colloquio'];
        delete nuovoStato['ordineColloquio'];
        navigate("/colloqui/elenco", { state: nuovoStato })
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function isAlunnoIscrittoInRange(alunno) {

        let dataColloquioCompare = DateTime.fromISO(dataColloquio);
        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);


        if (alunno.isRitirato == 1 && alunno.showDataRitiro == 1) {
            if (iscrittoAl.startOf('day') < dataColloquioCompare.startOf('day')) // Ritirato
            {
                return "Ritirato il " + convertDataDiNascita(iscrittoAl)
            } else return ""
        } else {
            if (alunno.showDataIscritto == 1)
                return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
            else return "";
        }
    }

    async function riepilogoColloquiCorso(idColloquio, idAlunno) {
        const obj = {
            idColloquio: idColloquio
        }

        ColloquiAPI.riepilogoColloquiCorso(obj)
            .then((result) => {
                let dataTmp = result;
                let docentiListTmp = [];
                for (let docente of dataTmp.docenti) {
                    let docenteTmp = docente;
                    docenteTmp.materiaString = "";
                    for (let i = 0; i < docente?.materie.length; i++) {
                        docenteTmp.materiaString += docente.materie[i]?.nomeBreveMateria;
                        if ((i + 1) < docente?.materie.length) docenteTmp.materiaString += ", ";
                    }
                    docentiListTmp.push(docenteTmp);
                }
                setInsertAvailable(dataTmp.insertAvailable);
                setDocentiList([...docentiListTmp]);
                setAlunniList([...dataTmp.alunni]);

                // se mi trovo nella sezione dettaglio studente passo il dato idAlunno, 
                //imposto nuovamente lo stato studenteSel e faccio triggherare lo usEffect nel componente figlio
                let alunnoSelezionato = idAlunno !== undefined ? dataTmp.alunni.find(alunno => alunno.idAlunno === idAlunno) : {};
                setStudenteSel(alunnoSelezionato);
                if (dataTmp.tutor != null)
                    setTutorList(dataTmp.tutor);
                else setTutorList([]);
                if (dataTmp.coordinatore != undefined && dataTmp.coordinatore != null)
                    setCoordinatore(dataTmp.coordinatore);
                else setCoordinatore(null)

                if (dataTmp.direttore != undefined && dataTmp.direttore != null)
                    setDirettore(dataTmp.direttore);
                else setDirettore(null);

                setThereIsAnError(false);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                setThereIsAnError(true);
                if (error.response) {
                }
            })
    }
    async function addAnnotazione() {
        const obj = {
            idAlunno: studenteSel.idAlunno,
            idColloquio: colloquio.idColloquio,
            annotazione: refTestoAnnotazione.current.value
        }
        setAddEditButtonDisabled(true)
        ColloquiAPI.addAnnotazione(obj)
            .then((result) => {
                setAddEditButtonDisabled(false)
                closeDialogAnnotazioni();
                !isDettaglioUtente ?
                    (
                        addAnnotazioneIsNotDettaglioUtente()
                    ) : (
                        addAnnotazioneIsDettaglioUtente()
                    )
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'inserimento dell'annotazione. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error')
                } else {
                    openAlertMsg("Errore durante l'inserimento dell'annotazione. Riprovare tra qualche istante.", "error")
                }
                setAddEditButtonDisabled(false)
            })
    }

    async function getTipologiePresenze() {

        ColloquiAPI.getTipologiePresenze()
            .then((result) => {
                let tipologieTmp = result?.tipologie
                setTipologieSoggettoPresente([...tipologieTmp])
            })
            .catch(function (error) {

            })
    }



    function addAnnotazioneIsDettaglioUtente() {
        openDialogConferma('')
        openAlertMsg("Annotazione inserita correttamente.", "success")
        riepilogoColloquiCorso(idColloquio, studenteSel.idAlunno);
    }

    function addAnnotazioneIsNotDettaglioUtente() {
        closeDialogStudente();
        openDialogConferma('')
        openAlertMsg("Annotazione inserita correttamente.", "success")
        riepilogoColloquiCorso(idColloquio);
    }

    async function editAnnotazione() {

        const obj = {
            idAlunno: studenteSel.idAlunno,
            idColloquio: colloquio.idColloquio,
            annotazione: refTestoAnnotazione.current.value
        }
        setAddEditButtonDisabled(true)
        ColloquiAPI.editAnnotazione(obj)
            .then((result) => {
                setAddEditButtonDisabled(false)
                closeDialogAnnotazioni();
                !isDettaglioUtente ?
                    (
                        editAnnotazioneIsNotDettaglioUtente()
                    ) : (
                        editAnnotazioneIsDettaglioUtente()
                    )
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante la modifica dell'annotazione. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error')
                } else {
                    openAlertMsg("Errore durante la modifica dell'annotazione. Riprovare tra qualche istante.", "error")
                }
                setAddEditButtonDisabled(false)
            })
    }

    function editAnnotazioneIsDettaglioUtente() {
        openDialogConferma('')
        openAlertMsg("Annotazione modificata.", "success")
        riepilogoColloquiCorso(idColloquio, studenteSel.idAlunno);
    }

    function editAnnotazioneIsNotDettaglioUtente() {
        closeDialogStudente();
        openDialogConferma('')
        openAlertMsg("Annotazione modificata.", "success")
        riepilogoColloquiCorso(idColloquio);
    }

    function changeTipologiaSoggettoPresente(e) {
        setSoggettoPresenteSel(e.target.value)
    };

    async function addPresenza() {

        const obj = {
            idAlunno: studenteSel.idAlunno,
            idColloquio: idColloquio,
            soggettoPresente: soggettoPresenteSel
        }

        ColloquiAPI.addPresenza(obj)
            .then((result) => {
                closeDialogSoggettoPresente()
                setConfermaPresenzaColloquiDialogOpen(false)
                riepilogoColloquiCorso(idColloquio);
                openAlertMsg('Presenza confermata.', 'success')
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante la conferma della presenza. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error')
                } else {
                    openAlertMsg("Errore durante la conferma della presenza. Riprovare tra qualche istante.", "error")
                }
                setAddEditButtonDisabled(false)
            })
    }

    async function deletePresenza() {

        const obj = {
            idPresenza: studenteSel?.presenzaUtente?.idPresenza
        }

        ColloquiAPI.deletePresenza(obj)
            .then((result) => {
                setConfermaDeletePresenzaColloquiDialogOpen(false)
                closeDialogPresenza()
                riepilogoColloquiCorso(idColloquio);
                openAlertMsg('Presenza eliminata.', 'success')
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'eliminazione della presenza. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error')
                } else {
                    openAlertMsg("Errore durante l'eliminazione della presenza. Riprovare tra qualche istante.", "error")
                }
                setAddEditButtonDisabled(false)
            })
    }


    function returnDataColloquio() {
        if (ordineColloquio === 0) return "Colloquio del " + convertDataDiNascita(colloquio?.dataColloquio);
        if (ordineColloquio === 1) return "Colloquio del " + convertDataDiNascita(colloquio?.primoColloquio?.data);
        if (ordineColloquio === 2) return "Colloquio del " + convertDataDiNascita(colloquio?.secondoColloquio?.data);
    }

    return (
        <Fragment>
            {!isLoading ? (
                <Fragment>
                    <Breadcrumb sections={["Modulo colloqui", "Annotazioni colloquio", colloquio?.codiceCorso + ' - ' + colloquio?.nomeCorso + ' (' + colloquio?.CodSimon + ')', returnDataColloquio()]} />
                    <Container maxWidth="false">
                        <Box display={"flex"} alignItems={"center"} flexDirection={{ xs: 'column', md: 'row' }} width={{ xs: '100%', md: 'unset' }} mt={2}>
                            <Button sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />} onClick={backToListColloqui}>
                                Torna all'elenco dei colloqui
                            </Button>
                            <Button onClick={openDialogLegenda} startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" >
                                Mostra legenda
                            </Button>
                        </Box>
                        <Box sx={{ backgroundColor: 'white', p: 2, display: 'flex', flexDirection: 'row' }}>
                            <Box>
                                <Typography variant="caption" sx={{ mt: 1 }}>Sede:</Typography>
                                <Typography
                                    variant={mobile ? 'caption' : 'subtitle2'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>{nomeSede !== '' ? nomeSede : ''}</Typography>
                            </Box>
                            <Box ml={2}>
                                <Typography variant="caption">Corso:</Typography>
                                <Typography color={'primary'} variant={mobile ? 'caption' : 'body1'} sx={{ fontWeight: 'bold', mr: 1 }}>
                                    {(colloquio?.codiceCorso + ' - ' + colloquio?.nomeCorso + ' (' + colloquio?.CodSimon + ')' ?? "").toUpperCase()}
                                </Typography>
                            </Box>
                        </Box>
                        {!thereIsAnError ? (
                            <TableContainer sx={{ width: '100%', height: '65vh' }} component={Paper}>
                                <Table>


                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                rowSpan={2}
                                                className="colonna-nomi row-head"
                                                sx={{ zIndex: 10, p: 0, borderBottom: "0px solid ", }}
                                            >
                                                <Box
                                                    sx={{
                                                        borderTop: "1px solid white",
                                                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                                        backgroundColor: "white",
                                                        height: '150px',
                                                        zIndex: 10,
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                    }}
                                                >
                                                    <Typography variant="h6"></Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                colSpan={docentiList.length}
                                                className=" row-head"
                                                sx={{ p: 0, borderRight: "3px solid black", zIndex: 7, }}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#1976d3",
                                                        height: "50px",
                                                        px: 2,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        color: "white",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ fontWeight: "bold" }}
                                                    >
                                                        Docenti
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            {tutorList.length > 0 && (
                                                <TableCell
                                                    colSpan={tutorList.length}
                                                    className=" row-head"
                                                    sx={{ p: 0, borderRight: "1px solid rgba(224, 224, 224, 1)", zIndex: 7, }}
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundColor: "#AA21AD",
                                                            height: "50px",
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "white",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Tutor
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {coordinatore != null && (
                                                <TableCell
                                                    className=" row-head"
                                                    sx={{ p: 0, borderRight: "1px solid rgba(224, 224, 224, 1)", zIndex: 7, }}
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundColor: "#AA21AD",
                                                            height: "50px",
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "white",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Coordinatore
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {direttore != null && (
                                                <TableCell
                                                    className=" row-head"
                                                    sx={{ p: 0, borderRight: "1px solid rgba(224, 224, 224, 1)", zIndex: 7, }}
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundColor: "#AA21AD",
                                                            height: "50px",
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "white",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            Direttore
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                        <TableRow>
                                            {docentiList.map((docente, index) => (
                                                <TableCell
                                                    key={index + 'docente'}
                                                    align="center"
                                                    className=" secondRow-head"
                                                    sx={{
                                                        minWidth: "200px", p: 0, zIndex: 7, borderBottom: "0px solid ",
                                                        borderRight: index + 1 == docentiList.length ? "3px solid black" : "0px",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: "100px",
                                                            backgroundColor: "white",
                                                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                                            borderRight: "1px solid rgba(224, 224, 224, 1)",

                                                            px: 0,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >
                                                            {docente?.nomeDocente}  {docente?.cognomeDocente}
                                                        </Typography>
                                                        <Typography variant="subtitle2">{docente?.materiaString}</Typography>
                                                    </Box>
                                                </TableCell>
                                            ))}
                                            {tutorList.length > 0 && (
                                                tutorList.map((tutor, index) => (
                                                    <TableCell
                                                        key={index + 'tutor'}
                                                        align="center"
                                                        className=" secondRow-head"
                                                        sx={{ minWidth: "200px", p: 0, zIndex: 7, borderBottom: "0px solid " }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                height: "100px",
                                                                backgroundColor: "white",
                                                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                                                px: 2,
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >
                                                                {tutor?.nome + ' ' + tutor?.cognome}
                                                            </Typography>

                                                        </Box>
                                                    </TableCell>
                                                ))
                                            )}


                                            {coordinatore != null && (
                                                <TableCell
                                                    key={'1coordinatore'}
                                                    align="center"
                                                    className=" secondRow-head"
                                                    sx={{ minWidth: "200px", p: 0, zIndex: 7, borderBottom: "0px solid " }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: "100px",
                                                            backgroundColor: "white",
                                                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >
                                                            {coordinatore?.nome}
                                                        </Typography>


                                                    </Box>
                                                </TableCell>
                                            )}
                                            {direttore != null && (
                                                <TableCell
                                                    key={'1direttore'}
                                                    align="center"
                                                    className=" secondRow-head"
                                                    sx={{ minWidth: "200px", p: 0, zIndex: 7, borderBottom: "0px solid " }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: "100px",
                                                            backgroundColor: "white",
                                                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                                            px: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >
                                                            {direttore?.nome}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {alunniList.map((studente, indexStudente) => (
                                            <TableRow key={indexStudente + "studente"}>
                                                <TableCell className="colonna-nomi"
                                                    sx={{ minWidth: '300px', p: 0, borderBottom: '0px solid', zIndex: '5' }}>
                                                    <Box display={'flex'} flexDirection={'column'} sx={{ backgroundColor: studente?.isRitirato === 1 || studente?.isIscritto === 0 ? '#eeeeee' : 'white', px: 2, py: 1, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', minHeight: '40px' }}>

                                                        <Box className="spacebetween-direction" sx={{ minHeight: '40px' }}>
                                                            <Box display={"flex"} alignItems={"center"}>
                                                                <Typography sx={{ mr: 2 }}>  {(indexStudente + 1) + ")"}</Typography>
                                                                <Typography variant="h8" sx={{ mr: 2, fontWeight: studente?.isMaggiorenne ? 'bold' : 'unset' }}>
                                                                    {(studente?.cognome).toUpperCase() + " " + (studente?.nome).toUpperCase()}
                                                                </Typography>

                                                            </Box>
                                                            {(studente?.isRitirato !== 1 && studente?.isIscritto !== 0) && (<Box display={"flex"} flexDirection={'row'} alignItems={"center"}>
                                                                {insertAvailable && (
                                                                    <Box>
                                                                        {studente?.presenzaUtente === null ? (
                                                                            <IconButton color={'primary'} sx={{ border: '1px solid ', mr: 1 }} onClick={() => openDialogSoggettoPresente(studente)}>
                                                                                <Person sx={{ fontSize: '1rem' }} />
                                                                            </IconButton>) : (
                                                                            < Box width={"35px"} height={"35px"} backgroundColor={'#1976d3'}
                                                                                onClick={() => openDialogPresenza(studente.presenzaUtente, studente)}

                                                                                display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                                sx={{ borderRadius: '100%', color: 'white', cursor: 'pointer', mr: 1 }}>
                                                                                <Person sx={{ fontSize: '1rem', color: 'white' }} />
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                                <IconButton color='primary' sx={{ border: '1px solid ', mr: insertAvailable ? 1 : 0 }} onClick={() => openDialogStudente(studente)}><Search sx={{ fontSize: '1rem' }} /></IconButton>
                                                                {insertAvailable && (
                                                                    <Box>
                                                                        {studente?.annotazioneUtente === null ? (
                                                                            <IconButton color={'primary'} sx={{ border: '1px solid ' }} onClick={() => openDialogAnnotazioni({}, studente, true, false)}><Edit sx={{ fontSize: '1rem' }} /></IconButton>
                                                                        ) : (
                                                                            < Box width={"35px"} height={"35px"} backgroundColor={'#1976d3'}
                                                                                onClick={() => openDialogAnnotazioni(studente?.annotazioneUtente, studente, true, false)}

                                                                                display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                                sx={{ borderRadius: '100%', color: 'white', cursor: 'pointer' }}>
                                                                                <Edit sx={{ fontSize: '1rem', color: 'white' }} />
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                            </Box>)}
                                                        </Box>
                                                        {isAlunnoIscrittoInRange(studente) != null && (
                                                            <Typography color="primary" variant="subtitle2">{isAlunnoIscrittoInRange(studente)}</Typography>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                                {
                                                    docentiList.map((docente, indexDocente) => <TableCell
                                                        key={"cell" + indexDocente + "-" + indexStudente}
                                                        sx={{
                                                            // display: 'flex',
                                                            p: 0, py: 1, borderRight: indexDocente + 1 === docentiList.length ? "3px solid black" : "1px solid rgba(224, 224, 224, 1)", borderBottom: '1px solid rgba(224, 224, 224, 1)', backgroundColor: studente?.isRitirato === 1 || studente?.isIscritto === 0 ? '#eeeeee' : 'white'
                                                        }}>

                                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                            {studente?.presenzeDocenti !== undefined && (
                                                                studente?.presenzeDocenti.map((presenza, indexPresenza) =>
                                                                    docente?.idDocente === presenza?.idDocente && (
                                                                        <Box key={indexStudente + '_' + indexDocente + '_annotazioneDocente-' + indexPresenza} display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ mr: 1 }}>
                                                                            <IconButton sx={{ border: '1px solid #4caf50', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => openDialogPresenza(presenza, studente)}><Typography sx={{ color: '#4caf50', fontSize: '1rem', fontWeight: 'bold' }} >P</Typography></IconButton>
                                                                        </Box>
                                                                    )
                                                                )
                                                            )}
                                                            {studente?.annotazioniDocenti !== undefined && (
                                                                studente?.annotazioniDocenti.map((annotazione, indexAnnotazione) =>
                                                                    docente?.idDocente === annotazione?.idDocente && (
                                                                        <Box key={indexStudente + '_' + indexDocente + '_annotazioneDocente-' + indexAnnotazione} display={"flex"} alignItems={"center"} justifyContent={'center'}>
                                                                            <IconButton sx={{ border: '1px solid orange' }} onClick={() => openDialogAnnotazioni(annotazione, studente, (annotazione?.idDocente === studente?.annotazioneUtente?.idUtente ? true : false), false)}><Description sx={{ color: 'orange', fontSize: '1rem' }} /></IconButton>
                                                                        </Box>
                                                                    )
                                                                )
                                                            )}
                                                        </Box>
                                                    </TableCell>)
                                                }
                                                {
                                                    tutorList.map((tutor, indexTutor) =>
                                                        <TableCell key={"cell" + indexTutor + "-" + indexStudente + "tutor"}
                                                            sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', backgroundColor: studente?.isRitirato === 1 || studente?.isIscritto === 0 ? '#eeeeee' : 'white' }}>
                                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                {studente?.presenzaTutor !== undefined && (
                                                                    studente?.presenzaTutor.map((presenza, indexPresenza) =>
                                                                        tutor?.idDocente === presenza?.idDocente && (
                                                                            <Box key={indexStudente + '_' + indexTutor + '_annotazioneDocente-' + indexPresenza} display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ mr: 1 }}>
                                                                                <IconButton sx={{ border: '1px solid #4caf50', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => openDialogPresenza(presenza, studente)}><Typography sx={{ color: '#4caf50', fontSize: '1rem', fontWeight: 'bold' }} >P</Typography></IconButton>
                                                                            </Box>
                                                                        )
                                                                    )
                                                                )}
                                                                {studente?.annotazioneTutor !== undefined && (
                                                                    studente?.annotazioneTutor.map((annotazione, indexAnnotazione) =>
                                                                        tutor?.idDocente === annotazione?.idDocente && (
                                                                            <Box key={indexStudente + '_' + indexTutor + 'annotazioneTutor-' + indexAnnotazione} display={"flex"} alignItems={"center"} justifyContent={'center'}>
                                                                                <IconButton sx={{ border: '1px solid orange' }} onClick={() => openDialogAnnotazioni(annotazione, studente, (annotazione?.idDocente === studente?.annotazioneUtente?.idUtente ? true : false), false)}><Description sx={{ color: 'orange', fontSize: '1rem' }} /></IconButton>
                                                                            </Box>
                                                                        )
                                                                    )
                                                                )}
                                                            </Box>
                                                        </TableCell>)
                                                }


                                                < TableCell key={"cell-" + indexStudente + "Coordinatore"}
                                                    sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', backgroundColor: studente?.isRitirato === 1 || studente?.isIscritto === 0 ? '#eeeeee' : 'white' }}>
                                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                        {studente?.presenzaCoordinatore !== null ?
                                                            (<Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ mr: 1 }}>
                                                                <IconButton sx={{ border: '1px solid #4caf50', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => openDialogPresenza(studente?.presenzaCoordinatore, studente)}><Typography sx={{ color: '#4caf50', fontSize: '1rem', fontWeight: 'bold' }} >P</Typography></IconButton>
                                                            </Box>) : (null)
                                                        }
                                                        {studente?.annotazioneCoordinatore !== null ?
                                                            (<Box display={"flex"} alignItems={"center"} justifyContent={'center'}>
                                                                <IconButton sx={{ border: '1px solid orange' }} onClick={() => openDialogAnnotazioni(studente?.annotazioneCoordinatore, studente, (studente?.annotazioneCoordinatore?.idUtente === studente?.annotazioneUtente?.idUtente ? true : false), false)}><Description sx={{ color: 'orange', fontSize: '1rem' }} /></IconButton>
                                                            </Box>) : (null)}
                                                    </Box>
                                                </TableCell>
                                                <TableCell key={"cell-" + indexStudente + "Direttore"}
                                                    sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', backgroundColor: studente?.isRitirato === 1 || studente?.isIscritto === 0 ? '#eeeeee' : 'white' }}>
                                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                        {studente?.presenzaDirettore !== null ?
                                                            (<Box display={"flex"} alignItems={"center"} justifyContent={'center'} sx={{ mr: 1 }}>
                                                                <IconButton sx={{ border: '1px solid #4caf50', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => openDialogPresenza(studente?.presenzaDirettore, studente)}><Typography sx={{ color: '#4caf50', fontSize: '1rem', fontWeight: 'bold' }} >P</Typography></IconButton>
                                                            </Box>) : (null)
                                                        }
                                                        {studente?.annotazioneDirettore !== null ? (
                                                            <Box display={"flex"} alignItems={"center"} justifyContent={'center'}>
                                                                <IconButton sx={{ border: '1px solid orange' }} onClick={() => openDialogAnnotazioni(studente?.annotazioneDirettore, studente, (studente?.annotazioneDirettore?.idUtente === studente?.annotazioneUtente?.idUtente ? true : false), false)}><Description sx={{ color: 'orange', fontSize: '1rem' }} /></IconButton>
                                                            </Box>) : (null)}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Box sx={{ width: '100%', height: '65vh', display: "flex", alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="h5">
                                    <ErrorOutline sx={{ fontSize: '3rem' }} color="error" />
                                </Typography>
                                <Typography sx={{ width: '100%', mt: 1 }} textAlign={"center"} variant="h5">Errore durante il caricamento del colloquio.</Typography>
                                <Typography sx={{ width: '100%' }} textAlign={"center"} variant="subtitle1">Riprova tra qualche istante.</Typography>
                            </Box>
                        )
                        }

                    </Container >
                </Fragment>
            ) : (
                <LoadingComponent isLoading={isLoading} timeoutLoading={1000} testoCaricamento={"Caricamento in corso..."} />

            )}

            <Dialog open={isDialogStudenteOpen} fullScreen maxWidth={"sm"}>
                <DialogContent >
                    <Box display={"flex"} alignItems={"center"} flexDirection={{ xs: 'column', md: 'row' }} width={{ xs: '100%', md: 'unset' }} mt={8}>
                        <Button sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />} onClick={closeDialogStudente}>
                            Torna all'elenco degli alunni
                        </Button>
                        {insertAvailable && (<Button
                            sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }}
                            variant="contained" color="success"
                            onClick={() => openDialogAnnotazioni(studenteSel?.annotazioneUtente !== null ? studenteSel?.annotazioneUtente : {}, studenteSel, true, true)}
                            startIcon={<Edit />}>
                            {studenteSel?.annotazioneUtente !== null ? 'Modifica annotazione' : 'Inserisci annotazione'}
                        </Button>)}
                    </Box>
                    <DettaglioStudenteAnnotazioni studente={studenteSel} docentiList={docentiList} tutorList={tutorList} direttore={direttore} coordinatore={coordinatore} />
                </DialogContent>
            </Dialog>


            <Dialog fullWidth open={isDialogPresenzaOpen} maxWidth={"sm"}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Presenza colloqui ({studenteSel?.cognome + ' ' + studenteSel?.nome})</Typography>
                    <IconButton onClick={closeDialogPresenza}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent >
                    <Box mb={2}>
                        {presenzaSel?.created_at !== undefined && (
                            <Typography><b>Data di inserimento:</b>  {convertDataDiNascita(presenzaSel?.created_at) + ' ( ' + presenzaSel?.nome + ' ) '}</Typography>
                        )}
                        {presenzaSel?.soggettoPresente !== undefined && (
                            <Typography sx={{ mb: 3, textAlign: 'start' }}><b>Soggetto presente al colloquio:</b> {presenzaSel?.soggettoPresente}</Typography>
                        )}
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ m: 2, width: '100%' }}>
                        {deleteButtonDisabled && (<Button sx={{ mb: 1 }} onClick={() => setConfermaDeletePresenzaColloquiDialogOpen(true)} variant="contained" fullWidth color="error">Elimina presenza</Button>)}
                        <Button onClick={closeDialogPresenza} variant="outlined" fullWidth color="error">Chiudi finestra</Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth open={isDialogSoggettoPresenteOpen} maxWidth={"sm"}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Presenza colloqui ({studenteSel?.cognome + ' ' + studenteSel?.nome})</Typography>
                    <IconButton onClick={closeDialogSoggettoPresente}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent >
                    <Typography sx={{ mb: 3, textAlign: 'start' }}>Al fine di confermare la presenza al colloquio, selezionare la tipologia di soggetto presente e premere il pulsante di conferma.</Typography>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="tipologia-label">
                            Tipologia soggetto presente
                        </InputLabel>
                        <Select
                            data-testid='tipologiaSoggettoPresenteSelect'
                            label="tipologia soggetto presente"
                            labelId="tipologia-label"
                            value={soggettoPresenteSel}
                            defaultValue={soggettoPresenteSel}
                            onChange={changeTipologiaSoggettoPresente}
                        >
                            <MenuItem
                                key={''}
                                value={''}
                            >
                            </MenuItem>
                            {tipologieSoggettoPresente.map((tipologia, index) => (
                                <MenuItem
                                    key={tipologia}
                                    value={tipologia}
                                >
                                    {tipologia}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ m: 2, width: '100%' }}>
                        <Button disabled={soggettoPresenteSel === ''} sx={{ mb: 1 }} onClick={() => setConfermaPresenzaColloquiDialogOpen(true)} variant="contained" fullWidth color="primary">Conferma presenza</Button>
                        <Button onClick={closeDialogSoggettoPresente} variant="outlined" fullWidth color="error">Chiudi finestra</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <DialogConferma
                isDialogOpen={confermaPresenzaColloquiDialogOpen}
                title={'Conferma presenza'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi la presenza ${soggettoPresenteSel === 'Alunno maggiorenne' ? "dell'" : 'del'} ${soggettoPresenteSel} al colloquio dello studente ${studenteSel?.cognome + ' ' + studenteSel?.nome}?`}
                showAlunni={false}
                confermaButton={'Conferma presenza'}
                severity={'success'}
                closeDialog={() => { setConfermaPresenzaColloquiDialogOpen(false) }}
                conferma={addPresenza}
                disabledButton={false}
            />
            <DialogConferma
                isDialogOpen={confermaDeletePresenzaColloquiDialogOpen}
                title={'Elimina presenza'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare la presenza ${soggettoPresenteSel === 'Alunno maggiorenne' ? 'dell' : 'del'} ${soggettoPresenteSel} al colloquio dello studente ${studenteSel?.cognome + ' ' + studenteSel?.nome}? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfermaDeletePresenzaColloquiDialogOpen(false) }}
                conferma={deletePresenza}
                disabledButton={false}
            />



            <Dialog open={isDialogAnnotazioniOpen} fullWidth maxWidth={"sm"}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} variant='h6'>

                    <Box display={"flex"} alignItems={"center"}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{!annotazioneSel?.isEditable && annotazioneSel?.isEditable !== undefined ? 'Dettaglio annotazione ' : ''}</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{annotazioneSel?.isEditable === undefined ? 'Inserisci annotazione ' : ''}</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{isAnnotazioneUtente && annotazioneSel?.isEditable ? 'Modifica annotazione ' : ''}</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{' (' + studenteSel?.cognome + ' ' + studenteSel?.nome + ')'}</Typography>
                    </Box>
                    <IconButton onClick={closeDialogAnnotazioni} sx={{ position: 'absolute', top: 10, right: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box mb={2}>
                        {annotazioneSel?.created_at !== undefined && (

                            <Typography><b>Data di inserimento:</b>  {convertDataDiNascita(annotazioneSel?.created_at) + ' ( ' + annotazioneSel?.nome + ' ) '}</Typography>
                        )}
                        {(annotazioneSel?.modified_at !== null && annotazioneSel?.modified_at !== undefined) && (
                            <Typography><b>Data di modifica:</b>  {convertDataDiNascita(annotazioneSel?.modified_at) + ' ( ' + annotazioneSel?.nome + ' ) '}</Typography>
                        )}
                    </Box>
                    <TextField fullWidth sx={{ mt: 1 }} disabled={!annotazioneSel?.isEditable && annotazioneSel?.isEditable !== undefined} inputRef={refTestoAnnotazione} defaultValue={annotazioneSel?.annotazione}
                        onChange={(e) => changeTestoAnnotazione(e, annotazioneSel?.annotazione)} placeholder="Scrivi qui..." multiline rows={4} label='Testo annotazione' />

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"end"}
                        m={2}
                        width={'100%'}
                    >
                        {(annotazioneSel?.isEditable) ? (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={modificaAnnotazione}
                                width={'100%'}
                                disabled={addEditButtonDisabled}
                            >
                                Modifica
                            </Button>
                        ) : (
                            <Box
                                width={'100%'}>
                                {(isAnnotazioneUtente && insertAvailable) && (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={aggiungiAnnotazione}
                                        sx={{ width: '100%' }}
                                        disabled={addEditButtonDisabled}
                                    >
                                        Conferma
                                    </Button>
                                )}

                            </Box>)}
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={closeDialogAnnotazioni}
                            width={'100%'}
                            sx={{ mt: 1 }}
                        >
                            chiudi dettaglio
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={isDialogLegendaOpen} fullWidth maxWidth={"sm"}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} variant='h6'>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={openDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendeColloquio />
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={openDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <DialogConferma
                isDialogOpen={isDialogConfermaOpen}
                title={testoConferma === 'modifica' ? 'Conferma modifica annotazione' : 'Conferma inserimento annotazione'}
                icon={testoConferma === 'modifica' ? <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" /> : <Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler ${testoConferma === 'modifica' ? 'modificare' : 'inserire'} l'annotazione? ${testoConferma === 'modifica' ? "Una volta confermato, non potrai annullare l'operazione." : ''}`}
                showAlunni={false}
                confermaButton={testoConferma === 'modifica' ? 'Conferma modifica' : 'Conferma inserimento'}
                severity={testoConferma === 'modifica' ? 'warning' : 'success'}
                closeDialog={() => openDialogConferma('')}
                conferma={testoConferma === 'modifica' ? () => editAnnotazione() : () => addAnnotazione()}
                disabledButton={false}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}