import { Box, Typography } from "@mui/material";
import { Description, Search, Edit, Person } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";


export default function LegendeColloquio() {
    return (
        <Box sx={{maxHeight:'40vh'}}>
            <Grid container px={1}>
                <Grid xs={12} md={6}
                    sx={{
                        pb: { xs: 4, md: 0 }
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} >
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid orange'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Description sx={{ color: 'orange', fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Annotazione</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Search color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Dettaglio alunno annotazioni</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Edit color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Inserisci annotazione</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Edit sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>Modifica annotazione</Typography>
                    </Box>


                </Grid>
                <Grid xs={12} md={6}
                    sx={{
                        pb: { xs: 4, md: 0 }
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 2, md: 0 } }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #4caf50'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Typography sx={{ color: '#4caf50', fontSize: '1.8rem', fontWeight: 'bold' }} >P</Typography>
                        </Box>
                        <Typography>Presenza</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Person color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Confermata presenza</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                            <Person sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>Presenza confermata</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}