import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Add, ArrowBack, ArrowForward, Check, Clear, Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';


import 'react-virtualized/styles.css';

import { RicercaAPI } from '../../../../api/ricercaApi';

import { getAnnoFormativo } from '../../../shared/utilityMethods';
import { TirociniAPI } from '../../../../api/tirociniApi';
import TreeViewRicerca from './treeViewRicerca';
import DialogConferma from '../../../shared/dialogConferma';

export default function RicercaAlunno(props) {


    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState('');

    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [idConvenzione, setIdConvenzione] = useState(-1);

    const [CF, setCF] = useState('');
    const [CFValid, setCFValid] = useState(true);
    const [CFMessageError, setCFMessageError] = useState('');

    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [nomeAlunno, setNomeAlunno] = useState('');
    const [nomeAlunnoValid, setNomeAlunnoValid] = useState(true);
    const [nomeAlunnoMessageError, setNomeAlunnoMessageError] = useState('');

    const [cognomeAlunno, setCognomeAlunno] = useState('');
    const [cognomeAlunnoValid, setCognomeAlunnoValid] = useState(true);
    const [cognomeAlunnoMessageError, setCognomeAlunnoMessageError] = useState('');

    const [idAlunno, setIdAlunno] = useState(-1);
    const [listAlunni, setListAlunni] = useState([]);

    const [datiRicerca, setDatiRicerca] = useState([]);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);

    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])

    useEffect(() => {
        if (props.idConvenzione !== undefined)
            setIdConvenzione(props.idConvenzione);
    }, [props.idConvenzione])

    function validate(e) {
        e.preventDefault()
        startSearch()
    }

    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {
        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse
        setIdAlunno(-1)
        setNomeAlunno('');
        setNomeAlunnoValid(true);
        setNomeAlunnoMessageError('');

        setCognomeAlunno('');
        setCognomeAlunnoValid(true);
        setCognomeAlunnoMessageError('');

        setCF('');
        setCFValid(true);
        setCFMessageError('');

        setSearchStarted(false)

        setTimeout(() => {
            setListAlunni([]);
            setHasLoaded(false)
        }, 200)
    }

    function changeCF(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCF(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setCFValid(true);
            setCFMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
                setCFValid(true);
                setCFMessageError('')
            } else {
                // setPIvaValid(false);
                setCFValid(false);
                setCFMessageError('Il C.F si compone di 11 o 16 caratteri')
            }
        }
    }

    function changeNomeAlunno(e) {
        let nomeAlunnoTmp = e.target.value;
        setNomeAlunno(nomeAlunnoTmp)
        if (nomeAlunnoTmp.length > 2 || nomeAlunnoTmp === "") {
            setNomeAlunnoMessageError('');
            setNomeAlunnoValid(true);
        }
        else {
            setNomeAlunnoValid(false);
            setNomeAlunnoMessageError('Min. 3 caratteri.');
        }
    }

    function changeCognomeAlunno(e) {
        let nomeAlunnoTmp = e.target.value;
        setCognomeAlunno(nomeAlunnoTmp)
        if (nomeAlunnoTmp.length > 2 || nomeAlunnoTmp === "") {
            setCognomeAlunnoMessageError('');
            setCognomeAlunnoValid(true);
        }
        else {
            setCognomeAlunnoValid(false);
            setCognomeAlunnoMessageError('Min. 3 caratteri.');
        }
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }


    function validateFormRicerca() {
        if (cognomeAlunnoValid &&
            nomeAlunnoValid &&
            CFValid
        ) return false
        else return true
    }

    function changeIdAlunno(idAlunno) {
        setIdAlunno(idAlunno)
        if (props.changeIdAlunno) props.changeIdAlunno(idAlunno)
    }


    function closeDialog() {
        setIdAlunno(-1);
        setIdConvenzione(-1);
        if (props.closeDialog) props.closeDialog()
    }

    function changeIdConvenzione(idConvenzione) {
        if (props.changeIdConvenzione) props.changeIdConvenzione(idConvenzione)
    }

    function confermaProsegui() {
        if (props.nextTab) props.nextTab()
    }

    function backToSelezioneConvenzione() {
        if (props.previousTab) {
            changeIdAlunno(-1);
            changeIdConvenzione(-1);
            props.previousTab();
        }
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeIdProgettoFormativo(idProgetto) {
        if (props.changeIdProgettoFormativo) props.changeIdProgettoFormativo(idProgetto)
    }

    function gestioneFiltroRicerca() {
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (cognomeAlunnoValid &&
                nomeAlunnoValid &&
                CFValid
            ) return false
            else return true
        }
        else {
            if (cognomeAlunnoValid &&
                nomeAlunnoValid &&
                CFValid &&
                (cognomeAlunno !== '' ||
                    nomeAlunno !== '' ||
                    CF !== ''
                )
            ) return false
            else return true
        }
    }

    async function ricercaAlunniConvenzione() {
        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri

        let obj = {
            idConvenzione: idConvenzione,
            nome: nomeAlunno !== '' ? nomeAlunno : null,
            cognome: cognomeAlunno !== '' ? cognomeAlunno : null,
            CF: CF !== '' ? CF : null,
        }
        TirociniAPI.ricercaAlunniConvenzione(obj)
            .then((result) => {
                let listCorsiTmp = result.corsi;
                setListAlunni([...listCorsiTmp]);
                let risultati = [];
                let corsiTmp = [];

                for (let corso of listCorsiTmp) {
                    let corsoTmp = {};
                    corsoTmp.id = corso.idCorso;
                    corsoTmp.name = corso.corso;

                    let alunniTmp = [];
                    for (let alunno of corso.alunni) {
                        let alunnoTmp = {};
                        alunnoTmp.id = alunno.idAlunno;
                        alunnoTmp.cf = alunno.codiceFiscale;
                        alunnoTmp.name = alunno.cognomeAlunno + " " + alunno.nomeAlunno;
                        alunnoTmp.type = "studente";
                        alunniTmp.push(alunnoTmp)
                    }
                    corsoTmp.type = "classe";
                    corsoTmp.codiceCorso = corso.codiceCorso;
                    corsoTmp.children = alunniTmp;
                    corsiTmp.push(corsoTmp)
                }

                risultati.push({
                    id: -1,
                    name: '',
                    type: "sede",
                    children: corsiTmp
                })
                setDatiRicerca([...risultati])

                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })

    }


    async function createProgetto() {
        setButtonConfermaDisabled(true);
        let obj = {
            idAlunno: idAlunno,
            annoFormativo: annoFormativo,
            idConvenzione: idConvenzione
        }
        TirociniAPI.createProgetto(obj)
            .then((result) => {
                changeIdProgettoFormativo(result.idProgetto);
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                if (props.nextTab) props.nextTab();
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>

            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca alunno</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />

                    </Box>
                    <Collapse in={filtriRicercaNotCollapsed}>
                        <Divider />
                        <Box sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                            <form onSubmit={validate} style={{ width: '100%' }}>
                                <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            error={!CFValid ? true : false}
                                            defaultValue={CF ?? ''}
                                            value={CF ?? ''}
                                            label={"C.F. alunno"}
                                            onChange={changeCF}
                                            sx={{ width: '100%' }}
                                            helperText={CFMessageError}
                                            inputProps={{ maxLength: 16 }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            defaultValue={nomeAlunno ?? ''}
                                            value={nomeAlunno ?? ''}
                                            label={"Nome alunno"}
                                            onChange={changeNomeAlunno}
                                            sx={{ width: '100%' }}
                                            error={!nomeAlunnoValid}
                                            helperText={nomeAlunnoMessageError}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            defaultValue={cognomeAlunno ?? ''}
                                            value={cognomeAlunno ?? ''}
                                            label={"Cognome alunno"}
                                            onChange={changeCognomeAlunno}
                                            sx={{ width: '100%' }}
                                            error={!cognomeAlunnoValid}
                                            helperText={cognomeAlunnoMessageError}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato.</Alert>
                    </Box>)}
                    <Divider />
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', md: "space-between" } }}>
                        {searchStarted ? (
                            <Button sx={{ height: 'fit-content', px: { xs: 0, md: 5 }, width: { xs: '100%', md: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                Rimuovi filtri
                            </Button>
                        ) : (
                            <span></span>
                        )}
                        <Box sx={{ width: { xs: '100%', md: 'fit-content' } }}>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} onClick={ricercaAlunniConvenzione} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca()} variant="contained">
                                Avvia ricerca
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ p: 2, overflowY: 'auto', height: '40vh' }}>
                        {hasLoaded ? (
                            listAlunni.length > 0 ?
                                (<TreeViewRicerca
                                    isMultipleSedi={false}
                                    setCheckedNodes={false}
                                    changeIdAlunno={changeIdAlunno}
                                    idAlunnoSelected={idAlunno}
                                    risultatiRicerca={datiRicerca}
                                    showCheckbox={false}
                                    checkedRow={[]}
                                />)
                                : (
                                    <Box sx={{ height: '35vh',display:"flex", alignItems:"center", justifyContent:"center"}}>
                                        <Alert severity='info'>La ricerca non ha prodotto nessun risultato con i filtri selezionati.</Alert>
                                    </Box>
                            )
                        ) : (
                            <Box display={"flex"} height={"100%"} width={"100%"} flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                                <CircularProgress />
                                <Typography sx={{ mt: 1 }}>Caricamento in corso...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Collapse>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: '100%' } }}>
                    <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                        onClick={closeDialog}
                        variant="outlined" fullWidth color={'error'} >Chiudi procedura di inserimento</Button>
                    <Button startIcon={<ArrowBack />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                        onClick={backToSelezioneConvenzione}
                        variant="contained" fullWidth color={'secondary'} >Torna indietro</Button>
                    <Button endIcon={<ArrowForward />}
                        sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                        onClick={() => setConfirmAlertIsOpen(true)}
                        disabled={idAlunno === -1}
                        variant="contained" fullWidth color={'success'} > Conferma e prosegui</Button>
                </Box>
            </Container>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta progetto formativo'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere il progetto formativo? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={createProgetto}
                disabledButton={buttonConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    );
}