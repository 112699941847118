import { Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, Breadcrumbs, Link } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment } from "react"
import ElencoAlunniSelezionabili from "./elencoAlunniSelezionabili";

import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../api/eventiApi";
import { RegistroAPI } from "../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../shared/metodiDateUtility";
import { PresenzeAPI } from "../../api/presenzeApi";
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";
import DialogConferma from "../shared/dialogConferma";
import { Check } from "@mui/icons-material";

export default function AggiuntaPrerequisiti(props) {

    const [data, setData] = useState(null);
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);

    const [materiaSelected, setMateriaSelected] = useState(-1);


    const [elencoOre, setElencoOre] = useState([]);
    const [oraSelected, setOraSelected] = useState(-1);

    const [TypeTipologia, setTipologia] = useState(-1);
    const [hideSelectTypeTipologia, setHideSelectTypeTipologia] = useState(false);

    const [valType, setValType] = useState(0);

    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [exAnte, setExAnte] = useState(false);
    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)

    const [objPermessiExAnte, setObjPermessiExAnte] = useState({
        insertExAnte: true,
        prerequisitiPassaggio: false,
        prerequisitiRecupero: false
    })

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 75,
    });

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])

    useEffect(() => {
        if (props.objPermessiExAnte !== undefined) {
            setObjPermessiExAnte(props.objPermessiExAnte)
        }
    }, [props.objPermessiExAnte])

    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.typeLezione !== undefined) {
            setTipologia(props.typeLezione);
            if (props.typeLezione !== -1)
                setHideSelectTypeTipologia(true)
            else setHideSelectTypeTipologia(false);
        }
    }, [props.typeLezione])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined && props.alunniSelezionati !== undefined && props.dataRichiesta !== undefined) {

            let listAlunniTmp = [];

            if (props.listAlunni == null)
                getAlunniByClasseID();
            else {
                for (let i = 0; i < props.listAlunni.length; i++) {
                    let obj = props.listAlunni[i];
                    obj.n = i;
                    listAlunniTmp.push(obj)
                }
                setListAlunni([...listAlunniTmp]);
                setGetAlunniIsLoading(false);
                // isAlunnoIscrittoInData(DateTime.now(), [...props.listAlunni])
            }

            let dataRichiesta = DateTime.fromSQL(props.dataRichiesta);
            let todayDate = DateTime.now();

            if (dataRichiesta > todayDate)
                setData(todayDate)
            else setData(dataRichiesta)

            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
            setMateriaSelected(props.idMateria)
            getOreLezione(dataRichiesta, props.idCorso, props.idDocente, props.idMateria, listAlunniTmp, props.alunniSelezionati);

        }
    }, [props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.dataRichiesta]);


    useEffect(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
            setMaterie([...materie, props.nota.materia])
        }
    }, [props.nota]);




    // useEffect(() => {
    //     if (props.alunniSelezionati !== undefined) {
    //         setAlunniSelezionati([...props.alunniSelezionati])
    //         selectAlunni(props.alunniSelezionati)
    //     }
    // }, [props.alunniSelezionati])

    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])




    function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);
                // isAlunnoIscrittoInData(DateTime.now(), [...props.listAlunni])
                getOreLezione(DateTime.now(), idCorso, idDocente, idMateria, [...props.listAlunni]);
            })
    }

    async function inserisciVoto() {

        let alunniSelezionatiTmp = alunniSelezionati;
        let votiFalliti = [];
        for (let alunno of alunniSelezionati) {

            const obj = {
                voto: alunno?.voto === 'N.V.' ? "0" : alunno?.voto,
                note: '',
                idAlunno: [alunno?.id],
                idOrario: oraSelected, // oraSelected, // TODO: int - da definire 
                is_ex_ante: exAnte,
                tipologia: valoreTipologia()
            }

            setPulsanteConfermaDisabled(true);

            const result = await inserimentoPrerequisito(obj);
            if (result != null) {

                votiFalliti.push(result)
            }

        }

        if (votiFalliti.length === 0) {
            if (isDialog) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto("Voto aggiunto", "success");
            } else {
                setAlunniSelezionati([]);
                setAlertSeverity("success");
                setAlertMsg("Voto aggiunto");
                setAlertIsOpen(true);
            }
        } else {
            for (let i = 0; i < alunniSelezionatiTmp.length; i++) {
                let indexVoti = votiFalliti.findIndex(a => a.id === alunniSelezionatiTmp[i].id)
                if (indexVoti !== -1)
                    alunniSelezionatiTmp[i].msgError = votiFalliti[indexVoti].errorMsg;
            }

            let newAlunniSelezionati = alunniSelezionatiTmp.filter(a => a.msgError !== undefined)
            setAlunniSelezionati([...newAlunniSelezionati])
        }
    }


    function selectAlunni(listAlunni) {


        let alunniSelezionatiTmp = alunniSelezionati;

        if (listAlunni !== undefined) {
            let arrayTmp = listAlunni;
            for (let i = 0; i < arrayTmp.length; i++) {
                let votoTmp = "";
                let valutazioneTmp = "";
                if (alunniSelezionatiTmp.some(alunno => alunno.id === arrayTmp[i].id)) {
                    let index = alunniSelezionatiTmp.findIndex(alunno => alunno.id === arrayTmp[i].id);
                    if (index !== -1) {
                        votoTmp = alunniSelezionatiTmp[index].voto;
                        valutazioneTmp = alunniSelezionatiTmp[index].valutazione;

                    }
                }
                arrayTmp[i].voto = votoTmp;
                arrayTmp[i].valutazione = valutazioneTmp;
            }
            // setAlunniSelezionati([...arrayTmp]);

            let test = arrayTmp.sort((a, b) => {
                if (a.n < b.n) {
                    return -1;
                }
                if (a.n > b.n) {
                    return 1;
                }
                return 0;
            });

            setAlunniSelezionati([...test]);
        }
    }


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        //setAlunniSelezionati([])
        setData(value);
        getOreLezione(value, idCorso, idDocente, idMateria, listAlunni, alunniSelezionati);
        //isAlunnoIscrittoInData(value, listAlunni);
    }

    function changeTypeVal(e) {
        setValType(e.target.value)
        let arrayTmp = alunniSelezionati;
        for (let a of arrayTmp) {
            a.voto = '';
        }
    }

    function changeTipologia(e) {
        setTipologia(e.target.value)
    }

    function valoreTipologia() {
        switch (TypeTipologia) {
            case -1:
                return '';
            case 0:
                return 'Ex ante';
            case 1:
                return 'Accertamento prerequisiti per recupero debito';
            case 2:
                return 'Accertamento prerequisiti per passaggio';
        }
    }

    function changeVoti(e, idAlunno) {

        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr === 0) newStr = 'N.V.';
        } else {
            newStr = '' //resetta a ''
        }

        let alunniSelezionatiTmp = alunniSelezionati;
        let index2 = alunniSelezionatiTmp.findIndex((element) => element.id === idAlunno);
        alunniSelezionatiTmp[index2].voto = newStr
        setAlunniSelezionati([...alunniSelezionatiTmp])
    }


    function changeOra(e) {
        setOraSelected(e.target.value);
        let formattedData = convertDataGetProssimeLezione(data, true)

        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            idMateria: idMateria
        }


        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let ora = result.filter(ora => ora.idOrario === e.target.value);
                if (ora.length > 0) {
                    setIdMateria(ora[0].materia.idMateria)
                    getAlunniPresenti(listAlunni, data, alunniSelezionati, ora[0])

                }
            });
    }
    function changeExAnte() {
        setExAnte(prev => !prev)
    }

    function votoIsNotValid() {
        return alunniSelezionati.findIndex((element) => element.voto === '') !== -1 ? true : false
    }



    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp; // setAlunniNonSelezionabili([...listTmp]);
        }
    }

    function isAddButtonDisabled() {
        if (alunniSelezionati.length > 0 && data != null && oraSelected != -1) {
            if (!votoIsNotValid() && TypeTipologia !== -1)
                return false
            else return true
        } else return true
    }

    async function getOreLezione(dataTmp, idCorso, idDocente, idMateria, listAlunniTmp, alunniSelezionatiTmp) {

        let formattedData = convertDataGetProssimeLezione(dataTmp, true)

        let dataObj = {
            dataRichiesta: formattedData,
            idDocente: idDocente,
            idCorso: idCorso,
            //idMateria: idMateria
        }

        RegistroAPI.getProssimeLezioni(dataObj)
            .then((result) => {
                let prossimeOreTmp = result.filter(ora => ora.data === formattedData);
                setElencoOre([...prossimeOreTmp]);
                if (prossimeOreTmp.length > 0) {
                    setOraSelected(prossimeOreTmp[0].idOrario);
                    setIdMateria(prossimeOreTmp[0].materia.idMateria)
                }
                else {
                    setOraSelected(-1);
                    // setIdMateria(-1);
                }
                getAlunniPresenti(listAlunniTmp, dataTmp, alunniSelezionatiTmp, prossimeOreTmp[0]);

            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) props.logout();
                }
                console.error(error)
            })
    }
    async function inserimentoPrerequisito(obj) {
        try {
            const result = await EventiAPI.insertPrerequisito(obj);
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            return null;

        } catch (error) {
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            let errorMsg = "";

            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            if (error.response.status === 409) {
                errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                // setAlertMsg(error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione");
            } else {
                errorMsg = "Al momento non è possibile completare l'operazione";
            }
            return {
                id: obj.idAlunno[0],
                errorMsg: errorMsg
            };

        }
    }



    async function getAlunniPresenti(alunniTmp, dataRichiesta, alunniSelezionati, oraSelezionata) {

        let dataSql = convertToSqlFormat(dataRichiesta);

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataObj = {
            "data": dataSql,
            "idAlunno": idAlunni
        }

        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                let alunniNonSelezionabiliTmp = [];
                for (let alunno of result.alunni) {
                    if (alunno?.stato_presenza === "A") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);
                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        alunniNonSelezionabiliTmp.push(objAlunno)
                    } else if (alunno?.stato_presenza === "UA" || alunno?.stato_presenza === "ER/UA" || alunno?.stato_presenza === "ER") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);

                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        if (alunno?.stato_presenza === "ER") {
                            let orarioFineOraConverted = oraSelezionata.oraFine.substring(0, oraSelezionata.oraFine.length - 3)
                            let orarioIngressoConverted = alunno.orario_ingresso_reale.substring(0, alunno.orario_ingresso_reale.length - 3)
                            let dataFineLezione = new Date(`1970-01-01T${orarioFineOraConverted}:00Z`);
                            let dataIngresso = new Date(`1970-01-01T${orarioIngressoConverted}:00Z`);
                            if (dataIngresso > dataFineLezione) {
                                alunniNonSelezionabiliTmp.push(objAlunno)
                            }
                        } else if (alunno?.stato_presenza === "UA") {
                            let orarioInizioOraConverted = oraSelezionata.oraInizio.substring(0, oraSelezionata.oraInizio.length - 3)
                            let orarioUscitaConverted = alunno.orario_uscita_reale.substring(0, alunno.orario_uscita_reale.length - 3)
                            let dataInizioLezione = new Date(`1970-01-01T${orarioInizioOraConverted}:00Z`);
                            let dataUscita = new Date(`1970-01-01T${orarioUscitaConverted}:00Z`);

                            if (dataUscita < dataInizioLezione) {
                                alunniNonSelezionabiliTmp.push(objAlunno)
                            }
                        } else if (alunno?.stato_presenza === "ER/UA") {
                            let orarioFineOraConverted = oraSelezionata.oraFine.substring(0, oraSelezionata.oraFine.length - 3)
                            let orarioIngressoConverted = alunno.orario_ingresso_reale.substring(0, alunno.orario_ingresso_reale.length - 3)
                            let dataFineLezione = new Date(`1970-01-01T${orarioFineOraConverted}:00Z`);
                            let dataIngresso = new Date(`1970-01-01T${orarioIngressoConverted}:00Z`);
                            let orarioInizioOraConverted = oraSelezionata.oraInizio.substring(0, oraSelezionata.oraInizio.length - 3)
                            let orarioUscitaConverted = alunno.orario_uscita_reale.substring(0, alunno.orario_uscita_reale.length - 3)
                            let dataInizioLezione = new Date(`1970-01-01T${orarioInizioOraConverted}:00Z`);
                            let dataUscita = new Date(`1970-01-01T${orarioUscitaConverted}:00Z`);
                            if (dataIngresso > dataFineLezione && dataUscita < dataInizioLezione) {
                                alunniNonSelezionabiliTmp.push(objAlunno)
                            }
                        }
                    }
                }

                let newAlunni = isAlunnoIscrittoInData(dataRichiesta, alunniTmp);
                for (let alunno of newAlunni) {
                    alunniNonSelezionabiliTmp.push(alunno);
                }

                setAlunniNonSelezionabili([...alunniNonSelezionabiliTmp]);
                let arrayTmp = alunniNonSelezionabiliTmp;
                let arraySelTmp = []


                let alunniSelezionabiliNumber = alunniTmp.length - alunniNonSelezionabiliTmp.length
                if (alunniSelezionabiliNumber < 0)
                    alunniSelezionabiliNumber = 0;

                setAlunniSelezionabiliNumber(alunniSelezionabiliNumber)
                for (let alunno of alunniSelezionati) {
                    if (!arrayTmp.includes(alunno)) arraySelTmp.push(alunno);
                }
                setAlunniSelezionati([...arraySelTmp]);
                selectAlunni(arraySelTmp);
            })
            .catch((error) => {
            })

    }

    function rowRenderer({ index, key, parent, style }) {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (

                    <div onLoad={measure} style={style}>
                        <Grid container spacing={2} mb={1} sx={{ p: 1, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderBottom: 'solid 1px rgb(210,210,210)' }}>
                            <Grid xs={12} md={8} sx={{ width: '100%' }}>
                                <Typography>{alunniSelezionati[index]?.cognome} {alunniSelezionati[index]?.nome}  </Typography>
                                {alunniSelezionati[index]?.msgError ? (
                                    <Typography variant="subtitle2" color="error">{alunniSelezionati[index]?.msgError}</Typography>
                                ) : null}
                            </Grid>
                            <Grid xs={12} md={4} sx={{ width: '100%' }}>
                                <TextField sx={{ width: '100%' }} data-testid='votoInput'
                                    label="Voto"
                                    onChange={(e) => changeVoti(e, alunniSelezionati[index]?.id)}
                                    value={alunniSelezionati[index]?.voto ? alunniSelezionati[index]?.voto : ''}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </CellMeasurer>
        )

    }

    async function checkOraFirmata(idOrarioFirma, idDocente) {
        let dataObj = {
            "idDocente": idDocente,
            "idOrario": idOrarioFirma
        }
        RegistroAPI.checkOraFirmata(dataObj)
            .then((result) => {
                if (result.check === true) {
                    setHideSelectTypeTipologia(true)
                    if (result.tipoOra === "pratica")
                        setTipologia(2)
                    else setTipologia(1)
                } else {
                    setTipologia(-1)
                    setHideSelectTypeTipologia(false)
                }
            })
            .catch((error) => {
                // if (error.response.status === 403) logout();
                // else
                //   console.error(error)
            })
    }


    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">Voto</Typography>
                    </Breadcrumbs>) : null}
                <Typography variant="h4" mt={1}>Gestione ex ante/accertamento prerequisiti </Typography>

                <Grid container spacing={2}>
                    <Grid xs={12} md={6} alignItems="stretch">
                        {!getAlunniIsLoading ? (
                            <ElencoAlunniSelezionabili alunniSelezionabiliNumber={alunniSelezionabiliNumber} checkAssenze={true} canSelezioneMultipla={true} selectAlunni={selectAlunni} alunniNonSelezionabili={alunniNonSelezionabili} listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                        ) : (
                            <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                <CircularProgress />
                                <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                            </Paper>
                        )}
                    </Grid>
                    <Grid xs={12} md={6} alignItems="stretch" direction="column" justifyContent="flex-end">
                        <Box sx={{ display: "flex", flexDirection: "column", height: '100%' }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                            // disabled
                                            minDate={dataInizioPeriodo}
                                            maxDate={DateTime.now() || dataFinePeriodo}
                                            label="Data"
                                            value={data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="materia-label">
                                            {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                            Seleziona ora</InputLabel>
                                        <Select
                                            data-testid='selOraVotoSelect'
                                            label='Seleziona ora '
                                            labelId="materia-label"
                                            value={oraSelected}
                                            onChange={changeOra}
                                            disabled={elencoOre.length === 0}
                                        >
                                            <MenuItem key={-1} value={-1}>
                                            </MenuItem>
                                            {elencoOre.map((ora) => (
                                                <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                    {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {data != null && elencoOre.length === 0 ? (<Typography variant="caption">Nessuna ora di lezione nella data scelta</Typography>) : null}
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: { xs: 2, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="TypeTipologia-label">
                                            {/* {classeName === '' ? t("pages.ricercaAlunni.classe") : t("pages.ricercaAlunni.classeS")} */}
                                            Tipologia
                                        </InputLabel>
                                        <Select
                                            data-testid='selTypeTipologiaSelect'
                                            labelId="TypeTipologia-label"
                                            label='Tipologia'
                                            value={TypeTipologia}
                                            onChange={changeTipologia}
                                        >
                                            <MenuItem key={-1} value={-1} >

                                            </MenuItem>
                                            {objPermessiExAnte?.insertExAnte !== undefined && objPermessiExAnte?.insertExAnte === true && (
                                                <MenuItem key={0} value={0} >
                                                    Ex ante
                                                </MenuItem>
                                            )}
                                            {objPermessiExAnte?.prerequisitiPassaggio !== undefined && objPermessiExAnte?.prerequisitiPassaggio === true && (
                                                <MenuItem key={2} value={2} >
                                                    Accertamento prerequisiti per passaggio
                                                </MenuItem>
                                            )}
                                            {objPermessiExAnte?.prerequisitiRecupero !== undefined && objPermessiExAnte?.prerequisitiRecupero === true && (
                                                <MenuItem key={1} value={1} >
                                                    Accertamento prerequisiti per recupero debito
                                                </MenuItem>
                                            )}


                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid xs={12} md={12}>
                                <Typography mb={1}>Lista alunni selezionati</Typography>
                            </Grid>
                            <Grid container sx={{ height: { xs: '50vh', md: "100%" } }}>
                                <Grid xs={12} md={12} sx={{ height: '100%' }}>
                                    <Container mt={1} sx={{ p: 1, border: '1px solid rgba(200, 200, 200, 1)', height: '100%', overflow: 'hidden', borderRadius: '4px' }}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    deferredMeasurementCache={cache}
                                                    height={height}
                                                    rowCount={alunniSelezionati.length}
                                                    rowHeight={cache.rowHeight}
                                                    rowRenderer={rowRenderer}
                                                    width={width}
                                                />
                                            )}
                                        </AutoSizer>

                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                    {isDialog ? (
                        <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                    ) : null}
                    <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isAddButtonDisabled()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                        color="success" variant="contained" >
                        Aggiungi voto
                    </Button>
                </Box>
            </Container>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta voto'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere un voto a `}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={inserisciVoto}
                disabledButton={pulsanteConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}