
import { Box, Button, Card, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import GiornataOrarioFormativoCell from "./giornataOrarioFormativoCell";
import { ContentCopy } from "@mui/icons-material";
import { copyArrayWithoutReference } from "../../shared/utilityMethods";

export default function OrarioFormativo(props) {

    const [week, setWeek] = useState([]);
    const [isCanEdit, setIsCanEdit] = useState(true);
    const [isVariazione, setIsVariazione] = useState(true);
    const [indexRender, setIndexRender] = useState(0);
    const [isCopyLoading, setIsCopyLoading] = useState(false);

    useEffect(() => {
        if (props.week !== undefined) {
            setIndexRender(prev => prev + 1);
            const clonedWeek = copyArrayWithoutReference(props.week);
            setWeek([...clonedWeek]);
        }
    }, [props.week]);

    useEffect(() => {
        if (props.isCanEdit !== undefined) {
            setIsCanEdit(props.isCanEdit)
        }
    }, [props.isCanEdit])

    useEffect(() => {
        if (props.isVariazione !== undefined) {
            setIsVariazione(props.isVariazione)
        }
    }, [props.isVariazione])


    function timeChanged(index, e) {
        let updatedWeek = copyArrayWithoutReference(week);
        updatedWeek[index] = e;
        setWeek(updatedWeek);

       if (props.changeOrario) props.changeOrario(updatedWeek);
    }

    function copyMondayTime() {
        setIsCopyLoading(true);

        // Crea una copia indipendente dell'oggetto del lunedì
        const mondayValues = { ...week[0] };

        let updatedWeek = copyArrayWithoutReference(week) //.map(day => ({ ...day }));
        for (let i = 0; i < updatedWeek.length; i++) {
            updatedWeek[i].primaFascia = mondayValues.primaFascia;
            updatedWeek[i].secondaFascia = mondayValues.secondaFascia;
        }
        console.log(updatedWeek);
        setWeek([...updatedWeek]); // Aggiorna lo stato con il nuovo array
        if (props.changeOrario) props.changeOrario(updatedWeek);

        setTimeout(() => {
            setIsCopyLoading(false);
            setIndexRender(prev => prev + 1);
        }, 300);
    }

    function isPossibleCopy() {
        if (isCanEdit && week[0] !== undefined && week[0] !== null
            && week[0]?.primaFascia?.oraInizio !== null && week[0]?.primaFascia?.oraFine !== null) {
            return true
        } else return false
    }

    return (
        <Box>
            <Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} rowSpan={2}>
                                {/* {isCanEdit && (<Button variant="outlined" size="small" fullWidth color={'secondary'} onClick={copyMondayTime}>Copia nel resto della settimana</Button>)} */}
                            </TableCell>
                            {/* <TableCell></TableCell> */}
                            <TableCell rowSpan={(isPossibleCopy() === true) ? 1 : 2} sx={{ borderLeft: '1px solid rgb(220,220,220)' }} align="center">Lunedì</TableCell>
                            <TableCell rowSpan={2} sx={{ border: '1px solid rgb(220,220,220)' }} align="center">Martedì</TableCell>
                            <TableCell rowSpan={2} sx={{ border: '1px solid rgb(220,220,220)' }} align="center">Mercoledì</TableCell>
                            <TableCell rowSpan={2} sx={{ border: '1px solid rgb(220,220,220)' }} align="center">Giovedì</TableCell>
                            <TableCell rowSpan={2} sx={{ border: '1px solid rgb(220,220,220)' }} align="center">Venerdì</TableCell>
                            <TableCell rowSpan={2} sx={{ border: '1px solid rgb(220,220,220)' }} align="center">Sabato</TableCell>
                            <TableCell rowSpan={2} sx={{ border: '1px solid rgb(220,220,220)' }} align="center">Domenica</TableCell>
                        </TableRow>

                        {(isPossibleCopy() === true) && <TableRow>
                            <TableCell sx={{ borderLeft: '1px solid rgb(220,220,220)' }}>
                                <Button endIcon={<ContentCopy />} disabled={isCopyLoading} variant="contained" size="small" fullWidth color={'primary'} onClick={copyMondayTime}>
                                    <Typography variant="caption" sx={{ fontSize: '10px' }}>
                                        Copia
                                    </Typography>
                                </Button>
                            </TableCell>
                        </TableRow>}
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ background: '#1976d2' }}>
                                <Typography textAlign={"center"} sx={{ color: 'white' }}>Prima fascia</Typography>
                            </TableCell>
                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                <Typography>Dalle ore</Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography>Alle ore</Typography>
                            </TableCell>
                            {week.map(
                                (day, index) => <GiornataOrarioFormativoCell key={index + '_day'} indexRender={indexRender} isPrimaFascia={true} objPeriodo={day} isCanEdit={isCanEdit} isVariazione={isVariazione} timeChanged={(e) => timeChanged(index, e)} />
                            )}
                        </TableRow>

                        <TableRow >
                            <TableCell sx={{ background: '#9c27b0' }}>
                                <Typography textAlign={"center"} sx={{ color: 'white' }}>Seconda fascia</Typography>
                            </TableCell>
                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                <Typography>Dalle ore</Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography>Alle ore</Typography>
                            </TableCell>
                            {week.length > 0 && (
                                week.map((day, index) =>
                                    day !== undefined && (
                                        day.primaFascia.oraInizio !== null && day.primaFascia.oraFine !== null ? (
                                            <GiornataOrarioFormativoCell key={index + '_day'} indexRender={indexRender} isPrimaFascia={false} objPeriodo={day} isCanEdit={isCanEdit} isVariazione={isVariazione} timeChanged={(e) => timeChanged(index, e)} />
                                        ) : (
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                {(isCanEdit || isVariazione) ? (
                                                    <Typography variant="body2" textAlign={"center"}>Seconda fascia disponibile dopo aver inserito la prima fascia</Typography>
                                                ) : (
                                                    <Typography variant="body1" textAlign={"center"}>-</Typography>

                                                )}
                                            </TableCell>
                                        ))
                                )
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </Box>
    )
}