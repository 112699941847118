import { Box, Typography } from "@mui/material";
import {  Edit } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";



export default function LegendaValidazionePresenzeTutorTirocinio() {
    return (
        <Grid container >
            <Grid xs={12} md={6} sx={{
                pb: { xs: 4, md: 0 }, borderBottom: { xs: 'rgb(210,210,210) 1px solid', md: 'none' },
                borderRight: { md: 'rgb(210,210,210) 1px solid', xs: 'none' }
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 0, md: 2 } }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #4caf50 solid', color: '#4caf50' }}>
                        <Typography fontWeight={'700'} >P</Typography>
                    </Box>
                    <Typography>Presente</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ef5350 solid', color: '#ef5350' }}>
                        <Typography fontWeight={'700'}>A</Typography>
                    </Box>
                    <Typography>Assente</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ff9800 solid', color: '#ff9800' }}>
                        <Typography fontWeight={'700'}>PP</Typography>
                    </Box>
                    <Typography>Presenza parziale</Typography>
                </Box>
            </Grid>
            <Grid xs={12} md={6} sx={{
                pb: { xs: 4, md: 0 },
                pl:{ xs: 0, md: 4}
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #D2D2D2 solid', color: '#D2D2D2' }}>
                        <Typography fontWeight={'700'}>/</Typography>
                    </Box>
                    <Typography>Presenza non indicata</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #808080 solid', color: '#808080' }}>
                        <Typography fontWeight={'700'}>ND</Typography>
                    </Box>
                    <Typography>Presenza non definita</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}