import { useState, Fragment, useRef } from "react"
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, IconButton, Alert, Snackbar, FormControl, TextField, Divider, DialogActions } from '@mui/material';

import { ArrowBack, Close, Edit, Help } from "@mui/icons-material";

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import InserimentoVotiTirocinio from "./inserimentoVotiTirocinio";
import ElencoCorsiFormatore from "../elencoCorsiFormatore";
import { TirociniAPI } from "../../../api/tirociniApi";
import Breadcrumb from "../../registri/shared/breadcrumb";
import DettaglioAlunno from "../../dettaglio-utente/dettaglioAlunno";
import TableGestioneTirocinio from "./tableGestioneTirocinio";
import BesButton from "../../shared/besButton";
import { useNavigate } from "react-router-dom";
import LegendaGestioneTirocinio from "./legendaGestioneTirocinio";
import { isAlunnoIscrittoInRange } from "../../shared/utilityMethods";
import { convertDataDiNascita } from "../../shared/metodiDateUtility";

export default function GestioneTirocinio(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true)

    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [nomeCorso, setNomeCorso] = useState("");
    const [isAnnotazioneSaving, setIsAnnotazioneSaving] = useState(false);

    const [canEditValutazioni, setCanEditValutazioni] = useState(false);
    const [canViewBes, setCanViewBes] = useState(false);
    const [isAnnotazioneInEditMode, setIsAnnotazioneInEditMode] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [listAlunni, setListAlunni] = useState([]);

    const [isDettaglioAlunnoOpen, setIsDettaglioAlunnoOpen] = useState(false);


    const [sedeUtente, setSedeUtente] = useState('');
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [showBes, setShowBes] = useState(false);
    const [indexTabDettaglioAlunno, setIndexTabDettaglioAlunno] = useState(0);

    const [isModificaVotiDialogOpen, setIsModificaVotiDialogOpen] = useState(false);
    const [isAnnotazioneDialogOpen, setIsAnnotazioneDialogOpen] = useState(false);
    const annotazioniRef = useRef('');

    const [isError, setIsError] = useState(true);
    const [alunnoSel, setAlunnoSel] = useState({});
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);


    function goToHome() {
        navigate("/")
    }

    function changeIdClasseSelezionata(idCorso, nomeCorso, codiceCorso, codiceSimon, nomeSede) {

        setIdCorsoSelezionato(idCorso);
        setNomeCorso(codiceCorso + " - " + nomeCorso);
        setDialogSelezionaClasseOpen(false);
        setSedeUtente(nomeSede)

        getElencoAlunniTirocini(idCorso);
    }

    function openDettaglioAlunno(alunno, showBesTmp) {
        let alunnoTmp = null;
        if (alunno !== undefined) {
            alunnoTmp = {
                id: alunno?.id,
                nome: alunno?.nome,
                cognome: alunno?.cognome
            }
        }
        if (showBesTmp !== undefined && showBesTmp === true) setIndexTabDettaglioAlunno(4);
        else setIndexTabDettaglioAlunno(0);
        setAlunnoSelezionato(alunnoTmp);
        setIsDettaglioAlunnoOpen(prev => !prev);
    }



    function changeSede(nome) {
        setSedeUtente(nome)
    }

    function goToSelezionaOra() {
        setDialogSelezionaClasseOpen(true);
    }


    function openDialogAnnotazioni(alunno, index) {
        let obj = {
            index: index,
            alunno: alunno
        }
        setAlunnoSel(obj);
        setIsAnnotazioneDialogOpen(true);
    }

    function openDialogModificaVoti() {
        setIsModificaVotiDialogOpen(true);
    }

    function closeAnnotazioneDialog() {
        setIsAnnotazioneDialogOpen(false);
        setIsAnnotazioneInEditMode(false);
        annotazioniRef.current = '';
    }

    function closeModificaVotiDialog() {
        setIsModificaVotiDialogOpen(false);
    }

    function changeAnnotazione(e) {
        annotazioniRef.current = e.target.value;
    }

    function changeConfermaModifica(voti) {
        let votiTmp = voti;
        setIsModificaVotiDialogOpen(false);
    }

    function besConfirmed(listAlunniTmp) {
        setListAlunni([...listAlunniTmp]);
        openAlertMsg("Bes abilitati", "info")
        setShowBes(true);
    }

    function stopBes() {
        let listAlunniTmp = listAlunni;
        for (let i = 0; i < listAlunni.length; i++)
            if (listAlunniTmp[i].besObj !== undefined) {
                listAlunniTmp[i].besObj = {
                    hasBes: false
                }
            }
        setListAlunni([...listAlunniTmp])
        setShowBes(false);
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function votiSaved() {
        openAlertMsg("Voti salvati correttamente", "success");
        setIsModificaVotiDialogOpen(false);
        getElencoAlunniTirocini(idCorsoSelezionato);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }


    async function getElencoAlunniTirocini(idCorso) {
        let dataObj = {
            idCorso: idCorso
        }
        TirociniAPI.getElencoAlunniTirocini(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setIsError(false);

                setCanEditValutazioni(result?.canEditValutazioni ?? false);
                setCanViewBes(result?.canShowBES ?? false);

                let alunniTmp = result?.alunni ?? [];


                for (let i = 0; i < alunniTmp.length; i++) {

                    alunniTmp[i].msgIscrizione = alunniTmp[i]?.isRitirato === 1 ? "Ritirato in data " + convertDataDiNascita(alunniTmp[i]?.iscrittoAl) : "";

                    if (alunniTmp[i].valutazioni.annotazione) {
                        let annotazioneTmp = alunniTmp[i]?.valutazioni?.annotazione ?? "";
                        if (alunniTmp[i]?.valutazioni?.annotazione.length > 30)
                            alunniTmp[i].valutazioni.annotazioneBreve = annotazioneTmp.slice(0, 30) + "...";
                        else alunniTmp[i].valutazioni.annotazioneBreve = annotazioneTmp
                    }
                }
                setListAlunni([...alunniTmp])
            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    setIsError(true);
                }
            })
    }
    async function saveChangeAnnotazione() {
        let dataObj = {
            idAlunno: alunnoSel?.alunno?.id,
            idCorso: idCorsoSelezionato,
            annotazione: annotazioniRef.current
        }
        setIsAnnotazioneSaving(true);
        TirociniAPI.saveAnnotazione(dataObj)
            .then((result) => {
                let listAlunniTmp = listAlunni;
                listAlunniTmp[alunnoSel?.index].valutazioni.annotazione = annotazioniRef.current;

                if (annotazioniRef.current.length > 30)
                    listAlunniTmp[alunnoSel?.index].valutazioni.annotazioneBreve = annotazioniRef.current.slice(0, 30) + "...";
                else listAlunniTmp[alunnoSel?.index].valutazioni.annotazioneBreve = annotazioniRef.current


                setListAlunni([...listAlunniTmp]);
                setIsAnnotazioneInEditMode(false);

                setAlertSeverity("success");
                setAlertMsg("Modifiche salvate correttamente");
                setAlertOpen(true);

                setIsAnnotazioneSaving(false);

            })
            .catch(function (error) {
                if (error.response) {
                    setAlertSeverity("error");
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio delle modifiche. Riprovare tra qualche istante."
                    setAlertMsg(errorMsg);
                    setAlertOpen(true);

                    setIsAnnotazioneSaving(false);
                }
            })
    }

    return (
        <Fragment>
            {(idCorsoSelezionato !== -1 && dialogSelezionaClasseOpen === false) ? (
                hasLoaded ? (
                    isError ? (
                        <Box sx={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography variant="h6">Al momento non è possibile visualizzare il corso selezionato.</Typography>
                            <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' }, mt: 1 }} variant="contained" startIcon={<ArrowBack />}>
                                Torna alla selezione del corso
                            </Button>
                        </Box>
                    ) : (
                        <Fragment>
                            <Breadcrumb sections={["Valutazione tirocinio formativo", nomeCorso]} isTirocinio={true} />
                            <Box sx={{ px: 5 }}>
                                <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: 'start', md: 'space-between' }}>
                                    <Box display={"flex"} flexDirection={{ xs: 'column', lg: 'row' }} width={{ xs: '100%', lg: 'unset' }}>
                                        <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                            Torna alla selezione del corso
                                        </Button>

                                        {canEditValutazioni && (
                                            <Button startIcon={<Edit />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="contained" color="success" onClick={() => openDialogModificaVoti()}>
                                                Modifica Voti
                                            </Button>
                                        )}
                                        <Button startIcon={<Help />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, mb: { xs: showBes ? 1 : 0, md: showBes ? 0 : 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                            Mostra legenda
                                        </Button>
                                    </Box>
                                    {canViewBes && (
                                        <BesButton stopBes={stopBes} listAlunni={listAlunni} idCorso={idCorsoSelezionato}
                                            openAlertMsg={openAlertMsg} besConfirmed={besConfirmed} />
                                    )}
                                </Box>

                                <Box sx={{ border: '1px solid #eaeaea', borderRadius: '15px', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center', overflow: 'scroll' }}>
                                    <TableGestioneTirocinio nomeCorso={nomeCorso} listAlunni={listAlunni}
                                        openDialogAnnotazioni={openDialogAnnotazioni} openDettaglioAlunno={openDettaglioAlunno} />
                                </Box>
                            </Box>


                        </Fragment>
                    )
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                    </Box>
                )
            ) : null
            }

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: 8 }}>
                <DialogTitle variant="h6"><b>Seleziona corso</b></DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoCorsiFormatore sedeUtente={sedeUtente} goToHome={goToHome} changeCorsoSelezionato={changeIdClasseSelezionata} changeSede={changeSede} />
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={isAnnotazioneDialogOpen}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ textAlign: 'start', fontWeight: 'bold' }}>
                        Modifica annotazione ({(alunnoSel?.alunno?.cognome ?? '').toUpperCase() + " " + (alunnoSel?.alunno?.nome ?? '').toUpperCase()})
                    </Typography>
                    <IconButton disabled={isAnnotazioneSaving} onClick={closeAnnotazioneDialog} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl sx={{ width: '100%', mt: 2 }} >
                        <TextField
                            label="Annotazione"
                            defaultValue={alunnoSel?.alunno?.valutazioni?.annotazione}
                            onChange={(e) => changeAnnotazione(e)}
                            multiline
                            disabled={!isAnnotazioneInEditMode}
                            rows={4}
                        />
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                        {isAnnotazioneInEditMode ? (
                            <Button variant="outlined" disabled={isAnnotazioneSaving} color="error" onClick={() => setIsAnnotazioneInEditMode(false)}>Annulla modifiche</Button>
                        ) : (
                            <Button variant="outlined" disabled={isAnnotazioneSaving} color="error" onClick={closeAnnotazioneDialog}>Chiudi finestra</Button>

                        )}
                        {canEditValutazioni && (
                            isAnnotazioneInEditMode ? (
                                <Button sx={{ mt: 1 }} disabled={isAnnotazioneSaving} variant="contained" color="success" onClick={saveChangeAnnotazione} >Conferma Modifiche</Button>
                            ) : (
                                <Button sx={{ mt: 1 }} disabled={isAnnotazioneSaving} variant="contained" color="primary" onClick={() => setIsAnnotazioneInEditMode(true)} >Modifica annotazione</Button>
                            )
                        )}
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioAlunnoOpen} onClose={openDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome})</Typography>
                    <IconButton onClick={openDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno showBes={showBes} tabIndex={indexTabDettaglioAlunno} id={alunnoSelezionato?.id} nomeAlunno={alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome} idCorso={idCorsoSelezionato} />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="md" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaGestioneTirocinio />
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen sx={{ marginTop: "7vh" }} open={isModificaVotiDialogOpen}>
                <DialogContent>
                    <InserimentoVotiTirocinio votiSaved={votiSaved} idCorso={idCorsoSelezionato} listAlunni={listAlunni} closeDialog={closeModificaVotiDialog} conferma={changeConfermaModifica} nomeMateria={"ELIMINA NOME MATERIA"} nomeCorso={nomeCorso} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


            <Helmet>
                <title>{t("name")} - Gestione tirocinio formativo</title>
            </Helmet>
        </Fragment>
    )
}