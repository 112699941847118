import { useState, useEffect, Fragment } from "react"
import { Box, Typography, useTheme, useMediaQuery, Divider, CircularProgress, Alert } from '@mui/material';
import { convertDataDiNascita, convertOra } from "../../shared/metodiDateUtility";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TirociniAPI } from "../../../api/tirociniApi";

export default function DettaglioVisita(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);

    const [visitaObj, setVisitaObj] = useState(null);
    const [primaFasciaObj, setPrimaFasciObj] = useState(null);
    const [secondaFasciaObj, setSecondaFasciaObj] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [dataVisita, setDataVisita] = useState("");

    useEffect(() => {
        if (props.idPresenza) {
            getDettaglioVisita(props.idPresenza);
        }
    }, [props.idPresenza])

    async function getDettaglioVisita(idPresenza) {

        setIsLoading(true);

        let dataObj = {
            idPresenza: idPresenza
        }

        TirociniAPI.getDettaglioVisita(dataObj)
            .then((result) => {
                setShowError(false);
                let dataTmp = convertDataDiNascita(result?.data)
                setDataVisita(dataTmp)

                setVisitaObj(result);
                setPrimaFasciObj(result?.presenza?.primaFascia)
                setSecondaFasciaObj(result?.presenza?.secondaFascia)

                setTimeout(() => {
                    setIsLoading(false);
                }, 200)

            })
            .catch(function (error) {
                setIsLoading(false);
                setShowError(true);
            })
    }

    return (
        <Fragment>
            {isLoading ? (
                <Box sx={{ mt: 4 }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress sx={{ mb: 3 }} />
                    <Typography >Caricamento in corso...</Typography>
                </Box>
            ) : (
                !showError ? (
                    <Box sx={{ mt: 1 }}>
                        <Typography color="primary"><b>Visita del</b>: {dataVisita}</Typography>
                        <Typography><b>Alunno</b>: {visitaObj?.nomeAlunno + " " + visitaObj?.cognomeAlunno}</Typography>
                        <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: 'space-between' }} container spacing={2}>
                            <Box sx={{ width: '100%' }}>
                                <Typography><b>1^ Fascia</b></Typography>
                                <Box>
                                    <Typography variant="body2">Orario previsto:</Typography>
                                        <Grid container spacing={isMobile ? 0 : 2}>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(primaFasciaObj?.orarioPrevisto?.oraInizio)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(primaFasciaObj?.orarioPrevisto?.oraFine)}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                    <Box sx={{ mt: { xs: 2, md: 0 } }}>
                                    <Typography variant="body2">Orario effettuato:</Typography>
                                        <Grid container spacing={isMobile ? 0 : 2}>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(primaFasciaObj?.orarioEffettuato?.oraInizio)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(primaFasciaObj?.orarioEffettuato?.oraFine)}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                                <Divider orientation="vertical" variant="middle" flexItem sx={{mx:5}}/>
                            <Box sx={{ width: '100%' }}>
                                <Typography><b>2^ Fascia</b></Typography>
                                <Box>
                                    <Typography variant="body2">Orario previsto:</Typography>
                                        <Grid container spacing={isMobile ? 0 : 2}>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(secondaFasciaObj?.orarioPrevisto?.oraInizio)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(secondaFasciaObj?.orarioPrevisto?.oraFine)}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box>
                                    <Typography variant="body2" sx={{mt:{xs:2,md:0}}}>Orario effettuato:</Typography>
                                        <Grid container spacing={isMobile ? 0 : 2}>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(secondaFasciaObj?.orarioEffettuato?.oraInizio)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box sx={{ border: '1px solid #c4c4c4', p: 0, borderRadius: '3px', background: '#ddd', mt: { xs: 1, md: 0 }, width: '100%' }}>
                                                <Typography variant="body2" textAlign={'center'}>{convertOra(secondaFasciaObj?.orarioEffettuato?.oraFine)}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                ) : (
                    <Box  sx={{ mt: 2,p:2 }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Alert sx={{width:'100%'}} severity="error">Errore durante il recupero del dato</Alert>
                    </Box>
                )
            )}
        </Fragment>
    )
}