import { useEffect, useState } from "react"
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Box, ListItemIcon, ListItemButton, styled, Dialog, Divider, DialogContent, DialogTitle, Snackbar, Alert, ListItemText, FormControl, useTheme, Badge } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
export default function DialogOreNonFirmate(props) {

    const [oreNonFirmate, setOreNonFirmate] = useState([]);

    useEffect(() => {
        if (props.oreNonFirmate !== undefined)
            setOreNonFirmate([...props.oreNonFirmate])
    }, [props.oreNonFirmate])

    return (
        oreNonFirmate.length > 0 ? (
            <Box>
                <Alert severity="warning" >Ci sono <b>{oreNonFirmate.length}</b> presenze non firmate.</Alert>

                <Table sx={{my:2}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><b>Ora</b></TableCell>
                            <TableCell sx={{ borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}>
                                <b>Corso</b>
                                </TableCell>
                            <TableCell ><b>Materia</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {oreNonFirmate.map((row) => (
                            <TableRow
                                key={row.id}
                            >
                                <TableCell align="center" component="th" scope="row" >
                                    {row.ora}
                                </TableCell>
                                <TableCell sx={{ borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}>
                                    {row.codiceCorso + " - " + row.nomeCorso}
                                </TableCell>
                                <TableCell >{row.nomeMateria}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        ) : (
            <Box>
                <Alert severity="info">Tutte le presenze sono state firmate.</Alert>
            </Box>
        )

    )
}