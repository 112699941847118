import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Box, Alert, Divider, Chip, CircularProgress } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { ArrowForward, Clear, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';


import 'react-virtualized/styles.css';

import { getAnnoFormativo } from '../../../shared/utilityMethods';
import { TirociniAPI } from '../../../../api/tirociniApi';
import SelettoreConvenzione from './selettoreConvenzione';

export default function RicercaConvenzione(props) {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState('');

    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [alertOpen, setAlertOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [alertSeverity, setAlertSeverity] = useState("success");
    // eslint-disable-next-line no-unused-vars
    const [alertMsg, setAlertMsg] = useState(false);

    const [idConvenzione, setIdConvenzione] = useState(-1);
    const [listConvenzioni, setListConvenzioni] = useState([]);

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [ragioneSocialeValid, setRagioneSocialeValid] = useState(true);
    const [ragioneSocialeMessageError, setRagioneSocialeMessageError] = useState('');

    const [protocolloConvenzione, setProtocolloConvenzione] = useState('');
    const [protocolloConvenzioneValid, setProtocolloConvenzioneValid] = useState(true);
    const [protocolloConvenzioneMessageError, setProtocolloConvenzioneMessageError] = useState('');

    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])

    useEffect(() => {
        if (props.protocolloConvenzione !== undefined) {
            setProtocolloConvenzione(props.protocolloConvenzione);

            setTimeout(() => {
                ricercaConvenzione(props.protocolloConvenzione);
            }, 500)
        }
    }, [props.protocolloConvenzione])


    function validate(e) {
        e.preventDefault()
        startSearch()
    }

    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {
        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse

        setIdConvenzione(-1)

        setRagioneSociale('');
        setRagioneSocialeValid(true);
        setRagioneSocialeMessageError('');

        setProtocolloConvenzione('');
        setProtocolloConvenzioneValid(true);
        setProtocolloConvenzioneMessageError('');

        setSearchStarted(false)

        setTimeout(() => {
            setListConvenzioni([]);
            setHasLoaded(false)
        }, 200)
    }

    function changeProtocolloConvenzione(e) {
        let protocolloConvenzioneTmp = e.target.value;
        setProtocolloConvenzione(protocolloConvenzioneTmp)
        if (protocolloConvenzioneTmp.length === 20 || protocolloConvenzioneTmp === "") {
            setProtocolloConvenzioneMessageError('');
            setProtocolloConvenzioneValid(true);
        }
        else {
            setProtocolloConvenzioneValid(false);
            setProtocolloConvenzioneMessageError('Il numero protocollo si compone di 20 caratteri.');
        }
    }

    function changeRagioneSociale(e) {
        let ragioneSocialeTmp = e.target.value;
        setRagioneSociale(ragioneSocialeTmp);
        if (ragioneSocialeTmp.length > 2 || ragioneSocialeTmp === "") {
            setRagioneSocialeMessageError('');
            setRagioneSocialeValid(true);
        }
        else {
            setRagioneSocialeValid(false);
            setRagioneSocialeMessageError('Min. 3 caratteri.');
        }
    }
    // function openAlertMsg(msg, severity) {
    //     setAlertMsg(msg)
    //     setAlertSeverity(severity)
    //     setAlertOpen(true)
    // }

    function validateFormRicerca() {
        if (ragioneSocialeValid &&
            protocolloConvenzioneValid
        ) return false
        else return true
    }

    function changeIdConvenzione(idConvenzione) {
        setIdConvenzione(idConvenzione)
        if (props.changeIdConvenzione) props.changeIdConvenzione(idConvenzione)
    }

    function closeDialog() {
        setIdConvenzione(-1);
        if (props.closeDialog) props.closeDialog();
    }

    function confermaProsegui() {
        if (props.nextTab) props.nextTab()
    }

    function gestioneFiltroRicerca() {
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (ragioneSocialeValid &&
                protocolloConvenzioneValid
            ) return false
            else return true
        }
        else {
            if (ragioneSocialeValid &&
                protocolloConvenzioneValid &&
                (ragioneSociale !== '' ||
                    protocolloConvenzione !== ''
                )
            ) return false
            else return true
        }
    }

    async function ricercaConvenzione(protocolloConvenzioneState) {
        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri
        let obj = {
            annoFormativo: annoFormativo,
            protocolloConvenzione: protocolloConvenzioneState !== undefined ? protocolloConvenzioneState : (protocolloConvenzione !== '' ? protocolloConvenzione : null),
            denominazioneAzienda: ragioneSociale !== '' ? ragioneSociale : null,
            stato: 1
        }
        TirociniAPI.ricercaConvenzione(obj)
            .then((result) => {
                setListConvenzioni([...result]);
                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })

    }

    return (
        <Fragment>

            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca convenzione</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />

                    </Box>
                    <Collapse in={filtriRicercaNotCollapsed}>
                        <Divider />
                        <Box sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                            <form onSubmit={validate} style={{ width: '100%' }}>
                                <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            defaultValue={ragioneSociale}
                                            value={ragioneSociale}
                                            label={'Ragione sociale azienda'}
                                            onChange={changeRagioneSociale}
                                            sx={{ width: '100%' }}
                                            error={!ragioneSocialeValid}
                                            helperText={ragioneSocialeMessageError}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            defaultValue={protocolloConvenzione ?? ''}
                                            value={protocolloConvenzione ?? ''}
                                            label={"Protocollo convenzione"}
                                            onChange={changeProtocolloConvenzione}
                                            sx={{ width: '100%' }}
                                            error={!protocolloConvenzioneValid}
                                            helperText={protocolloConvenzioneMessageError}
                                            inputProps={{ maxLength: 20 }}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato.</Alert>
                    </Box>)}
                    <Divider />
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', md: "space-between" } }}>
                        {searchStarted ? (
                            <Button sx={{ height: 'fit-content', px: { xs: 0, md: 5 }, width: { xs: '100%', md: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                Rimuovi filtri
                            </Button>
                        ) : (
                            <span></span>
                        )}
                        <Box sx={{ width: { xs: '100%', md: 'fit-content' } }}>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} onClick={()=>ricercaConvenzione(undefined)} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca()} variant="contained">
                                Avvia ricerca
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                        {hasLoaded ? (
                            listConvenzioni.length > 0 ?
                                (
                                    <Fragment>
                                        <SelettoreConvenzione
                                            listConvenzioni={listConvenzioni}
                                            changeIdConvenzioneSelezionata={changeIdConvenzione}
                                            idConvenzioneSelected={idConvenzione}
                                        />
                                    </Fragment>
                                )
                                :
                                (
                                    <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Alert sx={{ mb: 2 }} severity="info">La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Alert>
                                    </Box>
                                )

                        ) : (
                            <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress sx={{ mb: 4 }} />
                                <Typography >Caricamento in corso...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Collapse>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: '100%' } }}>
                    <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                        onClick={closeDialog}
                        variant="outlined" fullWidth color={'error'} >Chiudi procedura di inserimento</Button>
                    <Button endIcon={<ArrowForward />}
                        sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                        onClick={confermaProsegui}
                        disabled={idConvenzione === -1}
                        variant="contained" fullWidth color={'success'} > Conferma e prosegui</Button>
                </Box>
            </Container>
        </Fragment >
    );
}