import { useState, useEffect, Fragment } from "react"
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { RegistroAPI } from "../../api/registroApi";
import { RicercaAPI } from "../../api/ricercaApi";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../shared/utilityMethods";

export default function SelezioneCorsoMateriaGiorno(props) {

    const navigate = useNavigate();

    const [elencoCorsi, setElencoCorsi] = useState([])
    const [tipoRegistro, setTipoRegistro] = useState("classe")

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [idOrario, setIdOrario] = useState(-1);
    const [oraMateria, setOraMateria] = useState('');

    const [nomeClasseSelezionata, setNomeClasseSelezionata] = useState("");
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState("");
    const [codiceCorso, setCodiceCorso] = useState("");
    const [codiceSimon, setCodiceSimon] = useState("");
    const [sedeSelezionata, setSedeSelezionata] = useState("");

    const [modalitaSelezionata, setModalitaSelezionata] = useState("corsi")
    const [dialogElencoMaterieOpen, setDialogElencoMaterieOpen] = useState(false);

    const [riepilogo, setRiepilogo] = useState(false);

    const [dataDiInteresse, setDataDiInteresse] = useState(null);

    useEffect(() => {

        let annoFormativoTmp = getAnnoFormativo();

        let objDateInteresse = getDataInizioFinePeriodo();;
        setDataDiInteresse(objDateInteresse?.dataDiInteresse ?? null)


        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)

            getClassiDocente(annoFormativoTmp);
        } else {
            getClassiBySede(4, annoFormativoTmp); //Teoricamente non dovrebbe mai finire qua
        }
    }, [])

    useEffect(() => {
        if (props.riepilogo !== undefined) {
            setRiepilogo(props.riepilogo);
        }
    }, [props.riepilogo])

    useEffect(() => {
        if (props.tipoRegistro !== undefined) {
            setTipoRegistro(props.tipoRegistro);
        }
    }, [props.tipoRegistro])

    function changeCorsoSelezionato(idClasse, nomeClasse, codiceCorso, codiceSimon, nomeSede) { //Viene chiamato dal selettore dei corsi (elenco corsi)
        setIdClasseSelezionata(idClasse);
        setNomeClasseSelezionata(nomeClasse);
        setCodiceSimon(codiceSimon)
        setCodiceCorso(codiceCorso)
        setSedeSelezionata(nomeSede);
    }

    function goToElencoMaterie() {
        setDialogElencoMaterieOpen(true);
    }
    function confirmMateria(idMateria) {
        setIdMateriaSelezionata(idMateria);
        confirmClasse(idMateria);
        setDialogElencoMaterieOpen(false);
    }
    function confirmClasse(idMateria) {
        let dataInizio = dataDiInteresse.startOf('week') //dataDiInteresse.toISO();
        let dataFine = dataDiInteresse.endOf('week') //dataDiInteresse.plus({ days: 7 });
        dataFine = dataFine.toISO();
        dataInizio = dataInizio.toISO();
        props.changeSede(sedeSelezionata);
        if (riepilogo) {
            dataFine = dataDiInteresse.toISO();
            props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateria, nomeClasseSelezionata, [], null, true, nomeMateriaSelezionata, "", codiceCorso, codiceSimon);
        }
        else if (modalitaSelezionata === "classe") {
            props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateria, nomeClasseSelezionata, [], null, true, "", "", codiceCorso, codiceSimon);
        } else {
            let oreClasseSelezionata = generateArrayMaterieInClasseSelezionata();
            props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateria, nomeClasseSelezionata, oreClasseSelezionata, idOrario, true, nomeMateriaSelezionata, oraMateria, codiceCorso, codiceSimon);
        }

    }
    function goToHome() {
        navigate("/")
    }
    function closeMaterieDialog() {
        setDialogElencoMaterieOpen(false);
    }
    function generateArrayMaterieInClasseSelezionata() {
        let arrayOreTmp = [];
        for (let lezione of elencoCorsi) {
            if (lezione != null) {
                if (lezione?.idCorso === idClasseSelezionata) {
                    arrayOreTmp.push(lezione)
                }
            }
        }
        return arrayOreTmp;
    }
    async function getClassiDocente(annoFormativoTmp) {
        let dataObj = {
            annoFormativo: annoFormativoTmp
        }
        RegistroAPI.getElencoClassiDocente(dataObj)
            .then((result) => {
                // Rimuovo eventuali duplicati
                if (result[0] !== undefined) {
                    let elencoClassiTmp = result.filter((obj, index, self) =>
                        index === self.map(item => item.corso.idCorso).indexOf(obj.corso.idCorso)
                    );
                    elencoClassiTmp.sort((a, b) => a.corso.idCorso - b.corso.idCorso);
                    setElencoCorsi([...elencoClassiTmp])
                } else { //Se non trovo classi allora provo il metodo get classi operatore
                    setElencoCorsi([]);
                    if (props.checkSelezioneClasseOperatore)
                        props.checkSelezioneClasseOperatore();
                }
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    async function getClassiBySede(idSede, annoFormativoTmp) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativoTmp
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                let elencoClassiTmp = result.sedi.filter((obj, index, self) =>
                    index === self.map(item => item.id).indexOf(obj.id)
                );
                elencoClassiTmp.sort((a, b) => a.id - b.id);
                let elencoClassiDefinitive = [];
                for (let i = 0; i < elencoClassiTmp.length; i++) {
                    let objTmp = {
                        corso: {
                            idCorso: elencoClassiTmp[i].id,
                            nomeCorso: elencoClassiTmp[i].nomeCorso,
                            codiceCorso: elencoClassiTmp[i].codiceCorso,
                            codiceSimon: elencoClassiTmp[i].codiceSimon
                        },
                        sede: {
                            id: 4,
                            nomeSede: 'Ginori'
                        }
                    }
                    elencoClassiDefinitive.push(objTmp)

                }
                setElencoCorsi([...elencoClassiDefinitive]);
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }


    return (
        <Fragment>
            <ElencoCorsiDocente elencoCorsi={elencoCorsi} changeCorsoSelezionato={changeCorsoSelezionato} />
            <Divider />
            <Box>
                <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                {(modalitaSelezionata === "corsi" && tipoRegistro === "docente") || riepilogo ? (
                    <Button sx={{ mt: 2 }} onClick={goToElencoMaterie} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Prosegui alla selezione della materia</Button>
                ) : (
                    <Button sx={{ my: 2 }} onClick={() => confirmClasse(idMateriaSelezionata)} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Conferma e prosegui</Button>
                )}
            </Box>

            <Dialog fullWidth maxWidth={"md"} open={dialogElencoMaterieOpen}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Seleziona materia</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoMaterieCorso codiceCorso={codiceCorso} nomeCorsoSelezionato={nomeClasseSelezionata} sedeCorso={sedeSelezionata} confirmMateria={confirmMateria} closeMaterieDialog={closeMaterieDialog} idCorso={idClasseSelezionata} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}


function ElencoCorsiDocente(props) {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [elencoCorsi, setElencoCorsi] = useState([])
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);

    useEffect(() => {
        if (props.elencoCorsi !== undefined) {
            setElencoCorsi([...props.elencoCorsi]);
            setHasLoaded(true)
        }
    }, [props.elencoCorsi])

    function changeIdClasseSelezionata(idClasse, nomeClasse, codiceCorso, codiceSimon, nomeSede) {
        setIdClasseSelezionata(idClasse);
        if (props.changeCorsoSelezionato)
            props.changeCorsoSelezionato(idClasse, nomeClasse, codiceCorso, codiceSimon, nomeSede)
    }

    return (
        <Fragment>
            {hasLoaded ? (
                elencoCorsi.length > 0 ? (
                    <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
                        <Table >
                            <TableBody>
                                {elencoCorsi.map((classe, index) => (
                                    <TableRow key={classe?.corso?.idCorso} sx={{ cursor: 'pointer' }} onClick={() => changeIdClasseSelezionata(classe?.corso?.idCorso, classe?.corso?.nomeCorso, classe?.corso?.codiceCorso, classe?.corso?.codiceSimon, classe?.sede?.nomeSede, classe?.sede?.idSede,)}>
                                        <TableCell sx={{ minWidth: '200px', background: (classe?.corso?.idCorso === idClasseSelezionata) ? '#1976D2' : 'white', color: (classe?.corso?.idCorso === idClasseSelezionata) ? 'white' : 'black' }}>
                                            <Typography>
                                                {classe?.corso?.codiceCorso ? classe?.corso?.codiceCorso + " - " : ""}{classe?.corso?.nomeCorso ?? ""} {classe?.corso?.codiceSimon ? "(" + classe?.corso?.codiceSimon + ") " : ""} - {classe?.sede?.nomeSede} {(classe?.corso?.idCorso === idClasseSelezionata) ? "- SELEZIONATA" : ""}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                ) : (
                    <Typography textAlign={"center"} color="red">Al momento non è possibile visualizzare l'elenco dei corsi.</Typography>
                )
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                    <CircularProgress />
                </Box>
            )}
        </Fragment>
    )
}
function ElencoMaterieCorso(props) {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [elencoMaterie, setElencoMaterie] = useState([]);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeCorsoSelezionato, setNomeCorsoSelezionato] = useState("");
    const [sedeCorso, setSedeCorso] = useState("");

    useEffect(() => {
        if (props.idCorso !== undefined)
            getMaterieByClasse(props.idCorso);
    }, [props.idCorso])

    useEffect(() => {
        if (props.nomeCorsoSelezionato !== undefined && props.codiceCorso !== undefined)
            setNomeCorsoSelezionato(props.codiceCorso + " - " + props.nomeCorsoSelezionato);
    }, [props.nomeCorsoSelezionato, props.codiceCorso])

    useEffect(() => {
        if (props.sedeCorso !== undefined)
            setSedeCorso(props.sedeCorso);
    }, [props.sedeCorso])

    async function getMaterieByClasse(idClasse) {


        let dataObj = {
            idCorso: parseInt(idClasse)
        }

        RicercaAPI.getMaterieByClasse(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setElencoMaterie([...result?.materie])
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    function changeIdMateriaSelezionata(idMateria, nomeMateria) {
        setIdMateriaSelezionata(idMateria);
        //setNomeMateriaSelezionata(nomeMateria);
        //if (props.changeNomeMateria) props.changeNomeMateria(nomeMateria);
    }

    function closeMaterieDialog() {
        if (props.closeMaterieDialog) {
            setIdMateriaSelezionata(-1);
            props.closeMaterieDialog();
        }
    }

    function confirmMateria() {
        if (props.confirmMateria)
            props.confirmMateria(idMateriaSelezionata);
    }


    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Typography>
                        <b>Corso selezionato:</b> {nomeCorsoSelezionato} ({sedeCorso})
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                    {elencoMaterie.length > 0 ? (
                        <Box maxHeight={"30vh"} style={{ overflow: "scroll" }}>
                            <Table>
                                <TableBody>
                                    {elencoMaterie.map((materia, index) => (
                                        <TableRow key={materia.idMateria} sx={{ cursor: 'pointer' }} onClick={() => changeIdMateriaSelezionata(materia?.idMateria, materia?.nomeMateria)}>
                                            <TableCell sx={{ minWidth: '200px', background: (materia.idMateria === idMateriaSelezionata) ? '#1976D2' : 'white', color: (materia.idMateria === idMateriaSelezionata) ? 'white' : 'black' }}>
                                                {materia?.nomeMateria}  {(materia.idMateria === idMateriaSelezionata) ? "- SELEZIONATA" : ""}
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    ) : (
                        <Typography textAlign={"center"} color="red">Nessuna materia associata alla classe selezionata</Typography>
                    )}
                    <Divider sx={{ my: 1 }} />
                    <Button sx={{ mt: 2 }} onClick={closeMaterieDialog} variant="outlined" fullWidth color="info" >Torna alla selezione del corso</Button>
                    <Button sx={{ mt: 2 }} onClick={confirmMateria} variant="contained" fullWidth color="success" disabled={idMateriaSelezionata === -1}>Conferma e prosegui</Button>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                    <CircularProgress />
                </Box>
            )}
        </Fragment>
    )
}