import { Box, Dialog, DialogTitle, DialogContent, Typography, Button, Container, Divider } from "@mui/material";
import { useState, useEffect, Fragment } from "react"
import { Close } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import { ZoomIn } from "@mui/icons-material";
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";

import { convertISODate } from "../shared/metodiDateUtility";
export default function ElencoAnnotazioni(props) {

    const [elencoAnnotazioni, setElencoAnnotazione] = useState([]);
    const [notaSel, setNotaSel] = useState({});
    const [idAlunno, setIdAlunno] = useState({});

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 40,
    });

    useEffect(() => {
        if (props.alunno !== undefined) {
            setIdAlunno(props.alunno);
        }
    }, [props])

    useEffect(() => {
        if (props.annotazione !== undefined) {
            setElencoAnnotazione(props.annotazione);
        }
    }, [props.annotazione])

    function openDialog(nota, index) {
        setNotaSel(nota);
        // setAnnotazioneSelIndex(index);
        setDialogIsOpen(true);
    }

    function closeDialog() {
        setDialogIsOpen(false);
    }

    function rowRenderer({ index, key, parent, style }) {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (

                    <div onLoad={measure} style={style}>
                        <Box key={index} display={"flex"} justifyContent={"space-between"} alignItems={"center"} my={0.5} sx={{ borderBottom: 'solid 1px rgb(210,210,210)' }}>
                            <Typography>Annotazione del   <b>{convertISODate(elencoAnnotazioni[index]?.data_comunicazione)}</b> {elencoAnnotazioni[index]?.contenuto?.isInteraClasse ? " (tutta la classe)" : null} </Typography>
                            <IconButton color="primary" sx={{ ml: 2 }} onClick={() => openDialog(elencoAnnotazioni[index], index)}>
                                <ZoomIn />
                            </IconButton>
                        </Box>
                    </div>
                )}
            </CellMeasurer>
        )

    }


    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                <Box sx={{ height: '30vh', mt: 1, mb: 1 }}>
                    {elencoAnnotazioni.length > 0 ? (
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    deferredMeasurementCache={cache}
                                    height={height}
                                    rowCount={elencoAnnotazioni.length}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={rowRenderer}
                                    width={width}
                                />
                            )}
                        </AutoSizer>
                    ) : (
                        <Box sx={{ width: '100%', height: '30vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="body2 " px={2}>Al momento non sono presenti annotazioni da visualizzare</Typography>
                        </Box>
                    )}

                </Box>
                <Dialog fullWidth maxWidth={"sm"} open={dialogIsOpen} onClose={closeDialog} >
                    <DialogTitle>
                        Dettaglio annotazione

                        <IconButton onClick={closeDialog} sx={{ ml: 5, position: 'absolute', top: 5, right: 5 }}  >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {notaSel?.contenuto?.isInteraClasse ? <Typography color="primary"><b>ANNOTAZIONE DI CLASSE</b></Typography> : null}
                        <Typography><b>Data di inserimento:</b>  {convertISODate(notaSel?.data_comunicazione)}</Typography>
                        <Typography><b>Inserita da:</b>  {notaSel?.created_by_obj?.nome}</Typography>
                        {/* <Typography><b>Categoria:</b> {notaSel?.contenuto?.categoria?.descrizione}</Typography> */}

                        <Divider sx={{ my: 1 }} />
                        <Typography>{notaSel?.contenuto?.note}</Typography>
                        <Button sx={{ mt: 2 }} fullWidth onClick={closeDialog} variant="contained">Chiudi dettaglio</Button>
                    </DialogContent>
                </Dialog>
            </Container>
        </Fragment>
    )



}