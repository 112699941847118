import { Fragment, useEffect, useState, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Paper, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Select, MenuItem, Typography, IconButton, FormControl, InputLabel } from '@mui/material';
import { PresenzeAPI } from "../../api/presenzeApi";
import { RicercaAPI } from "../../api/ricercaApi";
import { convertDataGetProssimeLezione, convertISODate, getDayAndMonth, getDayOfTheWeek } from "../shared/metodiDateUtility";
import { DateTime } from "luxon";
import {Close, ArrowBack, CheckCircleOutline, EditCalendar, Help, HighlightOffOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import RangeDate from "../shared/rangeDate";
import Breadcrumb from "../registri/shared/breadcrumb";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../shared/utilityMethods";

export default function SintesiMonitoraggioAppello(props) {

    const { t, i18n } = useTranslation();
    const tableRef = useRef();


    const [idSede, setIdSede] = useState(-1);
    const [nomeSedeSelezionata, setNomeSedeSelezionata] = useState("");
    const [hasListSediLoaded, setHasListSediLoaded] = useState(false);
    const [listSedi, setListSedi] = useState([]);
    const [isDialogSediOpen, setIsDialogSediOpen] = useState(false);
    const [changePeriodoDiInteresseOpen, setChangePeriodoDiInteresseOpen] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState("20232024")
    const [dataRichiestaDa, setDataRichiestaDa] = useState(null)
    const [dataRichiestaA, setDataRichiestaA] = useState(null)
    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");


    const [hasLoaded, setHasLoaded] = useState(false);
    const [listCorsi, setListCorsi] = useState([]);
    const [arrayGiorni, setArrayGiorni] = useState([])

    const [isDialogLegendaOpen, setIsDialogLegendaOpen] = useState(false);

    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo(DateTime.local());

        let today = objDateInteresse?.dataDiInteresse;
        let todayConverted = convertDataGetProssimeLezione(today, true)
        let todayFormatted = convertISODate(todayConverted)

        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp)

        setFormattedPeriodoDiInteresseInizio(todayFormatted);
        setFormattedPeriodoDiInteresseFine(null);

        setDataRichiestaDa(todayConverted);
        setDataRichiestaA(null);

        getSedi(todayConverted, null, annoFormativoTmp);
    }, [])

    function changeSede(e) {
        setIdSede(e.target.value);
    }

    function confirmSede() {
        setIsDialogSediOpen(false);
        getSintesiAppello(idSede);
    }

    function openDialogSelezioneSede() { }
    function openPeriodoDiInteresseDialog() {
        setChangePeriodoDiInteresseOpen(true)
    }
    function openDialogLegenda() {
        setIsDialogLegendaOpen(prev => !prev);
    }

    function changePeriodoDiInteresse(dataInizioFormatted, dataFineFormatted) {


        setChangePeriodoDiInteresseOpen(false);
        let formattedInizioTmp = convertISODate(dataInizioFormatted);
        let formattedFineTmp = convertISODate(dataFineFormatted);

        let dataRichiestaDaTmp = convertDataGetProssimeLezione(dataInizioFormatted, true);
        let dataRichiestaATmp = convertDataGetProssimeLezione(dataFineFormatted, true);

        setFormattedPeriodoDiInteresseInizio(formattedInizioTmp);
        setDataRichiestaDa(dataRichiestaDaTmp);
        if (dataFineFormatted != null) {
            setDataRichiestaA(dataRichiestaATmp);
            setFormattedPeriodoDiInteresseFine(formattedFineTmp);
        }

        if (dataFineFormatted != null)
            getSintesiAppello(idSede, dataRichiestaDaTmp, dataRichiestaATmp, annoFormativo);
        else getSingoloGiornoAppello(idSede, dataRichiestaDaTmp, annoFormativo)
    }

    async function getSedi(dataRichiestaDaTmp, dataRichiestaATmp, annoFormativoTmp) {
        RicercaAPI.getSedi()
            .then((result) => {
                setListSedi(result.sedi);
                setHasListSediLoaded(true);
                if (result.sedi.length === 1) {
                    setIdSede(result.sedi[0].id);
                    setNomeSedeSelezionata(result.sedi[0].nomeSede);
                    setIsDialogSediOpen(false);
                    if (dataRichiestaATmp != null)
                        getSintesiAppello(result.sedi[0].id, dataRichiestaDaTmp, dataRichiestaATmp, annoFormativoTmp)
                    getSingoloGiornoAppello(result.sedi[0].id, dataRichiestaDaTmp, annoFormativoTmp)
                } else {
                    setIsDialogSediOpen(true)
                }
            })
            .catch(function (error) {
            })
    }
    async function getSintesiAppello(idSedeTmp, dataRichiestaDaTmp, dataRichiestaATmp, annoFormativoTmp) {
        let dataObj = {
            "dataRichiestaDa": dataRichiestaDaTmp,
            "dataRichiestaA": dataRichiestaATmp,
            "idSede": idSedeTmp,
            "annoFormativo": annoFormativoTmp
        }
        setHasLoaded(false);
        PresenzeAPI.getSintesiMonitoraggioAppello(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setListCorsi([...result.corsi]);

                let arrayDateTmp = [];
                //Qui aggiungo tutte le date che trovo, poi in seguito elimino i doppioni e faccio il sort
                for (let corso of result.corsi) {
                    for (let i = 0; i < corso.dataLezione.length; i++) {
                        arrayDateTmp.push(corso.dataLezione[i].data)
                    }
                }
                arrayDateTmp.sort();
                let arrayDateCleaned = [...new Set(arrayDateTmp)]; //Pulisco dai doppioni
                setArrayGiorni([...arrayDateCleaned]);

            })
            .catch(function (error) {
                setHasLoaded(true);
                setListCorsi([]);
            })
    }
    async function getSingoloGiornoAppello(idSedeTmp, data, annoFormativoTmp) {
        let dataObj = {
            "data": data,
            "idSede": idSedeTmp,
            "annoFormativo": annoFormativoTmp
        }
        setHasLoaded(false);
        PresenzeAPI.getDettaglioMonitoraggioAppello(dataObj)
            .then((result) => {
                setHasLoaded(true);
                let listCorsi = result;
                for (let i = 0; i < listCorsi.length; i++) {
                    listCorsi[i].dataLezione = [{
                        data: data,
                        appelloEseguito: listCorsi[i].appelloEseguito,
                        chiusura: listCorsi[i].chiusura
                    }];
                }
                setListCorsi([...listCorsi]);
                setArrayGiorni([data]);
            })
            .catch(function (error) {
                setHasLoaded(true);
                setListCorsi([]);
            })
    }

    function ReturnCellGiornoCorso({ corsoObj, giorno, indexCorso, indexGiorno }) {
        let dateCorso = corsoObj?.dataLezione ?? [];
        let indexData = dateCorso.findIndex(d => d.data === giorno);

        return (
            indexData !== -1 ? (
                <TableCell className={dateCorso[indexData]?.chiusura ? 'disabledCell' : ""} key={"dettaglio_" + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        {!corsoObj?.dataLezione[indexData]?.chiusura && (
                            dateCorso[indexData]?.appelloEseguito ? (
                                <CheckCircleOutline color="success" fontSize="large" />
                            ) : (
                                <HighlightOffOutlined color="error" fontSize="large" />
                            )
                        )}
                    </Box>
                </TableCell >
            ) : (
                <TableCell className={'disabledCell'} key={"dettaglio_" + indexCorso + indexGiorno + "disabled"} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                </TableCell>
            )
        )
    }

    return (
        <Fragment>
            <Breadcrumb sections={["Monitoraggio appello", nomeSedeSelezionata]} />

            <Box sx={{ px: 5 }}>
                <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                    {listSedi.length > 1 && (
                        <Button onClick={openDialogSelezioneSede} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                            Cambia sede ({nomeSedeSelezionata})
                        </Button>
                    )}
                    <Button sx={{ ml: { xs: 0, md: listSedi.length > 1 ? 2 : 0 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                        Cambia periodo di interesse ({formattedPeriodoDiInteresseInizio}
                        {formattedPeriodoDiInteresseFine != null && formattedPeriodoDiInteresseFine !== formattedPeriodoDiInteresseInizio ? (
                            " - " + formattedPeriodoDiInteresseFine
                        ) : ""})
                    </Button>
                    <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                        Mostra legenda
                    </Button>
                </Box>

                {!isDialogSediOpen && (
                    hasListSediLoaded ? (
                        <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                            {(listCorsi.length > 0 && arrayGiorni.length > 0) ? (
                                <TableContainer ref={tableRef} sx={{ width: '100%' }} component={Paper}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                                    <Typography color={'primary'} fontWeight={'bold'}>{nomeSedeSelezionata.toUpperCase()}</Typography>
                                                </TableCell>
                                                {arrayGiorni.map((giorno) => (
                                                    <TableCell className='row-head' key={"giorno_" + giorno} style={{ backgroundColor: 'white', p: 2, textAlign: 'center', minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{getDayAndMonth(giorno)}</Typography>
                                                        <Typography variant="subtitle2">{getDayOfTheWeek(giorno)}</Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {listCorsi.map((corso, indexCorso) => (
                                                <TableRow style={{ height: '80px' }} key={corso?.idCorso} >
                                                    <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <Box sx={{ backgroundColor: 'white', p: 2 }}>
                                                            <Typography ><b>{corso?.codiceCorso}</b> - {corso?.nomeCorso} ({corso?.CodSimon})</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {arrayGiorni.map((giorno, indexGiorno) => (
                                                        <ReturnCellGiornoCorso corsoObj={corso} giorno={giorno} indexCorso={indexCorso} indexGiorno={indexGiorno} />))}
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            ) : (
                                <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography variant="h5">Per il periodo selezionato (<b>{formattedPeriodoDiInteresseInizio}  {formattedPeriodoDiInteresseFine != null ? ("-" + formattedPeriodoDiInteresseFine) : ""}</b>) non ci sono lezioni in programma.</Typography>
                                    <Button sx={{ mt: 2 }} variant="contained" onClick={openPeriodoDiInteresseDialog} startIcon={<EditCalendar />}>
                                        Cambia periodo di interesse
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ height: '90vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                            <Typography sx={{ mt: 3, width: 'fit-content' }}>Caricamento in corso...</Typography>
                        </Box>
                    )
                )
                }
            </Box>

            <Dialog open={isDialogSediOpen}>
                <DialogTitle>Seleziona sede</DialogTitle>
                <DialogContent>
                    <FormControl sx={{ width: '100%', mt: 1 }}>
                        <InputLabel id="sede-label">
                            Sede
                        </InputLabel>
                        <Select disabled={!hasListSediLoaded} label="Sede" value={idSede} onChange={changeSede} >
                            <MenuItem key={-1} value={-1} >
                            </MenuItem>
                            {listSedi.map((sede) => (
                                <MenuItem key={sede.id} value={sede.id} >
                                    {sede.nomeSede}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button sx={{ mt: 1 }} onClick={confirmSede} disabled={idSede === -1} fullWidth variant="contained" color="success">
                        Conferma e prosegui
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={changePeriodoDiInteresseOpen} sx={{ mt: '8vh' }}>
                <DialogTitle>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Seleziona il periodo di interesse
                    </Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <RangeDate canSelectSingleDay={true} dataDiInteresseInizio={dataRichiestaDa} dataDiInteresseFine={dataRichiestaA} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog open={isDialogLegendaOpen} onClose={openDialogLegenda}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={openDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 1 }}>
                        <Box width={"30px"} height={"30px"}
                            // backgroundColor={"error.light"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid green', color: 'green' }}>
                            <CheckCircleOutline sx={{ fontSize: "2rem" }} />

                        </Box>
                        <Typography>Appello effettuato</Typography>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"}
                            // backgroundColor={"error.light"}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid red', color: 'red' }}>
                            <HighlightOffOutlined sx={{ fontSize: "2rem" }} />

                        </Box>
                        <Typography>Appello non effettuato</Typography>
                    </Box>

                    <Button fullWidth sx={{ mt: 2 }} variant="contained" color="primary" onClick={openDialogLegenda}>Chiudi finestra</Button>
                </DialogContent>
            </Dialog>

            <Helmet>
                <title>{t("name")} - Monitoraggio appello</title>
            </Helmet>
        </Fragment >
    )
}