import {IconButton, Divider, Table, Box, Dialog, DialogTitle, DialogContent, Paper, Typography, Button, Container, TableHead, TableRow, TableCell, TableBody, Select, InputLabel, MenuItem, FormControl, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment } from "react";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { Close } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";

import { EventiAPI } from "../../../api/eventiApi";
import { convertISODate, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import ModificaNoteDisciplinari from "./modificaNoteDisciplinari";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";
export default function ElencoNoteDisciplinari(props) {

    const [dataStart, setDataStart] = useState(null);
    const [dataEnd, setDataEnd] = useState(null);
    const [includiNoteClasse, setIncludiNoteClasse] = useState(true);
    const [isTuttaLaClasse, setIsTuttaLaClasse] = useState(false);
    const [tipoRegistro, setTipoRegistro] = useState("classe")
    const [idCategoria, setIdCategoria] = useState(0);
    const [categorieList, setCategorieList] = useState([]);

    const [elencoNote, setelencoNote] = useState([]);
    const [notaSel, setnotaSel] = useState({});
    const [idAlunno, setIdAlunno] = useState(null);
    const [idCorso, setIdCorso] = useState(-1);

    const [permessiUtente, setPermessiUtente] = useState([]);

    const [listAlunni, setListAlunni] = useState([]);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)
    useEffect(() => {
        if (props.listAlunni !== undefined) {
            setListAlunni([...props.listAlunni]);
        }
    }, [props.listAlunni])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro)
    }, [props.tipoRegistro])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente]);
        }
    }, [props.permessiUtente])


    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        setDataInizioPeriodo(inizioPeriodoTmp ?? null);
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
        setDataFinePeriodo(finePeriodoTmp ?? null);
        getCategorieNota()
    }, [])

    useEffect(() => {
        if (props.idCorso !== undefined && props.idMateria !== undefined && props.alunniSelezionati !== undefined) {
            setIdCorso(props.idCorso);

            let idAlunnoTmp = null;
            if (props.alunniSelezionati.length === 1) {
                idAlunnoTmp = props.alunniSelezionati[0].id
                setIdAlunno(props.alunniSelezionati[0].id)
            }
            else {
                idAlunnoTmp = null;
                setIdAlunno(null)
            }

            let showOnlyElenco = props.showOnlyElenco ?? false;
            getElencoNote(idAlunnoTmp, props.idCorso, idCategoria, false, showOnlyElenco)
        }
    }, [props.idCorso, props.idMateria, props.alunniSelezionati, props.showOnlyElenco, props.dataRichiesta])

    useEffect(() => {
        if (props.isTuttaLaClasse !== undefined)
            setIsTuttaLaClasse(props.isTuttaLaClasse)
    }, [props.isTuttaLaClasse])

    function changeDataStart(value) {
        setDataStart(value);
    }

    function changeDataEnd(value) {
        setDataEnd(value);
    }

    function filtro() {
        getElencoNote(idAlunno, idCorso, idCategoria, false);
    }

    function isSearchButtonDisabled() {
        if ((dataStart == null && dataEnd == null) || (dataStart != null && dataEnd != null))
            return false
        else return true
    }

    function rimuoviFiltro() {
        setDataEnd(null);
        setDataStart(null);
        setIdCategoria(0)

        getElencoNote(idAlunno, idCorso, 0, true);
    }

    function openDialog(nota, index) {
        setnotaSel(nota);
        setDialogIsOpen(true);
    }

    function closeDialog() {
        setDialogIsOpen(false);
        getElencoNote(idAlunno, idCorso, idCategoria, false);
    }
    function changeCategoria(e) {
        setIdCategoria(e.target.value)
    }

    function changeShowNoteClasse(e) {
        setIncludiNoteClasse(e.target.checked);
    }


    async function getElencoNote(idAlunno, idCorso, idCategoria, skipDate, showOnlyElenco) {
        let dataStartTmp = null;
        let dataEndTmp = null;
        let soloInteraClasse = false;

        if (skipDate === undefined || skipDate == null || skipDate == false) {
            if (dataStart != null && dataEnd !== null) {
                let dataConverted = convertDataGetProssimeLezione(dataStart)
                dataStartTmp = dataConverted;
                let dataConvertedEnd = convertDataGetProssimeLezione(dataEnd)
                dataEndTmp = dataConvertedEnd;
            } else {
                if (!showOnlyElenco) {
                    setDataStart(null)
                    setDataEnd(null)
                } else {
                    soloInteraClasse = true;

                    if (props.dataRichiesta != null) {
                        let dateObject = DateTime.fromSQL(props.dataRichiesta);
                        let dataConverted = convertDataGetProssimeLezione(dateObject, true)
                        dataStartTmp = dataConverted;
                        dataEndTmp = dataConverted;
                        setDataStart(dateObject)
                        setDataEnd(dateObject)
                    }
                }
            }
        }

        let obj = {
            idCorso: idCorso,
            idCategoria: idCategoria,
            mostraNoteDiClasse: includiNoteClasse,
            dataRichiestaDa: dataStartTmp,
            dataRichiestaA: dataEndTmp,
            //soloInteraClasse: soloInteraClasse,

        }

        if (idAlunno != null)
            obj.idAlunno = idAlunno

        if (idCorso !== -1) {
            EventiAPI.elencoNote(obj)
                .then((result) => {
                    let annotazioniTmp = [];
                    for (let nota of result) {
                        let notaTmp = nota;
                        if (nota.contenuto.note.length >= 30) {
                            let descBreve = nota.contenuto.note.slice(0, 30) + '...';
                            notaTmp.contenuto.descrBreve = descBreve;
                        } else notaTmp.contenuto.descrBreve = nota.contenuto.note;

                        // if ((idAlunno == null && nota.contenuto.isInteraClasse) || idAlunno != null)
                        annotazioniTmp.push(notaTmp);
                    }
                    setelencoNote([...annotazioniTmp])
                })
                .catch((error) => {

                })
        }
    }

    async function getCategorieNota() {

        EventiAPI.getCategorieNota()
            .then((result) => {
                setCategorieList(result)
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }


    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                <Grid container spacing={2} mt={{ xs: 2, md: 0 }}>
                    <Grid xs={12} md={3}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataInizioPeriodo}
                                maxDate={dataEnd != null ? dataEnd : dataFinePeriodo}
                                label="Periodo (inserimento) dal"
                                value={dataStart}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataStart(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                            <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                minDate={dataStart != null ? dataStart : dataInizioPeriodo}
                                maxDate={dataFinePeriodo}
                                label="Periodo (inserimento) al"
                                value={dataEnd}
                                format="dd/MM/yyyy"
                                onChange={(value) => changeDataEnd(value)}
                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={6} sx={{ width: '100%' }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="categoria-label">
                                {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                Categoria</InputLabel>
                            <Select
                                data-testid='selCategoriaNotaSelect'
                                label='Categoria '
                                labelId="materia-label"
                                value={idCategoria}
                                onChange={changeCategoria}
                            >
                                <MenuItem
                                    key={0}
                                    value={0}
                                >
                                    Tutte
                                </MenuItem>
                                {categorieList.map((categoria) => (
                                    <MenuItem
                                        key={categoria.id}
                                        value={categoria.id}
                                    >
                                        {categoria.descrizione}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={4}>
                        {!isTuttaLaClasse ? (
                            <FormControlLabel
                                control={
                                    <Checkbox checked={includiNoteClasse} onChange={changeShowNoteClasse} />
                                }
                                label="includi note disciplinari collettive"
                            />
                        ) : null}

                    </Grid>
                    <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box mb={2}>
                            {((dataStart != null && dataEnd == null) || (dataStart == null && dataEnd != null)) && (
                                <Typography color="error">
                                    È necessario selezionare <b>entrambe le date di inserimento</b> per applicare il filtro.
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'end', alignItems: 'center', width: { xs: '100%', md: 'fit-content' } }} >
                            <Button onClick={() => rimuoviFiltro()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="outlined" startIcon={<Close />}>
                                Rimuovi filtri
                            </Button>
                            <Button disabled={isSearchButtonDisabled()} onClick={() => filtro()} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }}
                                variant="contained" startIcon={<FilterList />}>
                                Filtra
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Paper sx={{ minHeight: '20vh', maxHeight: '60vh', px: 2, overflowY: 'scroll', mt: 4, mb: 1 }}>
                    {elencoNote.length > 0 ? (

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data di inserimento</TableCell>
                                    <TableCell>Descrizione breve</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {elencoNote.map((nota, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {convertISODate(nota?.data_comunicazione)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {nota?.contenuto?.isInteraClasse ? <b>(NOTA DI CLASSE) - </b> : ""} {nota?.contenuto?.descrBreve}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            <Button onClick={() => openDialog(nota, index)} size="small" variant="contained" data-testid='leggiComBtn'>Apri dettaglio</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    ) : (
                        <Box sx={{ width: '100%', height: '20vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography >Nessuna nota disciplinare da mostrare nel periodo selezionato</Typography>
                        </Box>
                    )}

                </Paper>
                <Dialog fullWidth maxWidth={"md"} open={dialogIsOpen} onClose={closeDialog} sx={{ mt: '8vh' }}>
                    <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"} variant="h6">
                        Dettaglio nota disciplinare
                        <IconButton onClick={closeDialog} sx={{ ml: 5 }}  >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <ModificaNoteDisciplinari tipoRegistro={tipoRegistro} permessiUtente={permessiUtente} notaSel={notaSel} listAlunni={listAlunni} closeDialog={closeDialog} />
                    </DialogContent>
                </Dialog>
            </Container>
        </Fragment>
    )



}