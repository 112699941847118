import { Box } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect, Fragment } from "react"

import AnagraficaAlunno from './sub-compontents/anagraficaAlunno';
import AssenzeAlunno from './sub-compontents/assenzeAlunno';
import BesAlunno from './sub-compontents/besAlunno';

import CryptoJS from "crypto-js";

import { useTranslation } from 'react-i18next';
import PresenzeParziali from "./sub-compontents/presenzeParziali";
import ExAnte from "./sub-compontents/exAnte/exAnte";
import { getAnnoFormativo } from '../shared/utilityMethods';

export default function DettaglioAlunno(props) {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [idStudente, setIdStudente] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [alunno, setAlunno] = useState('');
    const [alunnoObj, setAlunnoObj] = useState(undefined);

    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);

    const [presenzaObj, setPresenzaObj] = useState(null)

    const [dontCallBesBE, setDontCallBesBE] = useState(false);

    const [showBes, setShowBes] = useState(false);
    const [besData, setBesData] = useState(null);

    const [dataAttuale, setDataAttuale] = useState(null);

    const [comesFromTracciato, setComesFromTracciato] = useState(false);
    const [comesFromConsultaRegistro, setComesFromConsultaRegistro] = useState(false);
    const [canTouchAssenze, setCanTouchAssenze] = useState(false);

    const [ruoliUtente, setRuoliUtente] = useState({});
    const [permessiUtente, setPermessiUtente] = useState([]);
    const [tipo, setTipo] = useState(false)
    const [tipoRegistro, setTipoRegistro] = useState('nessun registro')

    const [annoFormativo, setAnnoFormativo] = useState("20232024")
    useEffect(() => {

        if (props.showBes !== undefined)
            setShowBes(props.showBes)

        if (props.idDocente !== undefined) {
            setIdDocente(props.idDocente);
        } else {
            if (localStorage.getItem("doc")) {
                let idDocenteTmp = localStorage.getItem("doc");
                let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                let idDocenteInt = parseInt(idDocenteDecrypted)
                setIdDocente(idDocenteInt)
            }
        }

        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp)
    }, [])

    useEffect(() => {
        if (props.ruoliUtente) {
            setRuoliUtente(props.ruoliUtente);
        }
    }, [props.ruoliUtente]);

    useEffect(() => {
        if (props.dontCallBesBE)
            setDontCallBesBE(props.dontCallBesBE)
    }, [props.dontCallBesBE])

    useEffect(() => {
        if (props.canTouchAssenze)
            setCanTouchAssenze(props.canTouchAssenze)
    }, [props.canTouchAssenze])

    useEffect(() => {
        if (props.comesFromConsultaRegistro) {
            setComesFromConsultaRegistro(props.comesFromConsultaRegistro)
        }
    }, [props.comesFromConsultaRegistro])

    useEffect(() => {
        if (props.besData !== undefined)
            setBesData(props.besData)
    }, [props.besData])

    useEffect(() => {
        if (props.presenzaObj !== undefined)
            setPresenzaObj(props.presenzaObj)
    }, [props.presenzaObj])



    useEffect(() => {
        if (props.dataAttuale !== undefined)
            setDataAttuale(props.dataAttuale)
    }, [props.dataAttuale])

    useEffect(() => {
        if (props.ritardo !== undefined)
            setOpenDialogRitardo(props.ritardo);
    }, [props.ritardo])

    useEffect(() => {
        if (props.tabIndex !== undefined)
            setTabIndex(props.tabIndex);
    }, [props.tabIndex])

    useEffect(() => {
        if (props.idDocente !== undefined) {
            setIdDocente(props.idDocente);
        }
    }, [props.idDocente])

    useEffect(() => {
        if (props.id !== undefined) {
            setIdStudente(props.id);
        }
    }, [props.id])

    useEffect(() => {
        if (props.idCorso !== undefined) {
            setIdCorso(props.idCorso);
        }
    }, [props.idCorso])

    useEffect(() => {
        if (props.nomeAlunno !== undefined)
            setAlunno(props.nomeAlunno);
    }, [props.nomeAlunno])


    useEffect(() => {
        if (props.comesFromTracciato !== undefined) {
            setComesFromTracciato(props.comesFromTracciato);
        }
    }, [props.comesFromTracciato])

    useEffect(() => {
        if (props.permessiUtente !== undefined)
            setPermessiUtente(props.permessiUtente);
    }, [props.permessiUtente])

    useEffect(() => {
        if (props.tipo !== undefined)
            setTipo(props.tipo);
    }, [props.tipo])

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro);
    }, [props.tipoRegistro])

    function changeTabIndex(event, newValue) {
        setTabIndex(newValue);
        setPresenzaObj(null);
    }

    return (
        <Box sx={{ width: '100%', height: '100%' }} >
            <Box
                sx={{
                    position:'sticky', top:0,
                    borderBottom: 1, borderColor: 'divider', width: '100%', backgroundColor: 'white', zIndex:'200'
                }}>
                <Tabs fixed value={tabIndex} onChange={changeTabIndex} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    <Tab label={t("pages.anagrafica.title")} />
                    <Tab label={t("pages.assenze.title")} />
                    <Tab label={t("pages.ritardo_uscita.title")} />
                    <Tab label="Ex ante"/>
                    {(showBes ) ? (
                        <Tab label={t("pages.bes.title")} />
                    ) : null}
                </Tabs>
            </Box>
            <Box sx={{ p: 5}}>
                {tabIndex === 0 && (
                    <AnagraficaAlunno annoFormativo={annoFormativo} idDocente={idDocente} id={idStudente} nomeAlunno={alunno} ritardo={openDialogRitardo} closeDettaglioAlunno={props.closeDettaglioAlunno} openAlertMsg={props.openAlertMsg} />
                )}
                {tabIndex === 1 && (
                    <AssenzeAlunno ruoliUtente={ruoliUtente} canTouchAssenze={canTouchAssenze} comesFromConsultaRegistro={comesFromConsultaRegistro} dataAttuale={dataAttuale} idDocente={idDocente} idAlunno={idStudente} nomeAlunno={alunno} permessiUtente={permessiUtente} tipo={tipo} tipoRegistro={tipoRegistro} />
                )}
                {tabIndex === 2 && (
                    <PresenzeParziali presenzaObj={presenzaObj} canTouchAssenze={canTouchAssenze} dataAttuale={dataAttuale} idDocente={idDocente} idAlunno={idStudente} nomeAlunno={alunno} permessiUtente={permessiUtente} tipo={tipo} tipoRegistro={tipoRegistro} idCorso={idCorso} comesFromConsultaRegistro={comesFromConsultaRegistro} />
                )}
                {tabIndex === 3 && (
                    <ExAnte nomeAlunno={alunno} idAlunno={idStudente} idCorso={idCorso} idDocente={idDocente}/>
                )}
                {tabIndex === 4 && (
                    <BesAlunno dontCallBesBE={dontCallBesBE} besData={besData} idDocente={idDocente} id={idStudente} idCorso={idCorso} nomeAlunno={alunno} comesFromTracciato={comesFromTracciato} permessiUtente={permessiUtente} />
                )}
            </Box>
        </Box>
    )
}