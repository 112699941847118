import { Fragment, useState, useEffect, useRef } from "react"
import { Dialog, DialogTitle, DialogContent, Divider, Box, Button, TableCell, TableRow, Typography,  Skeleton, useMediaQuery, useTheme, IconButton, TextField } from "@mui/material";
import { regexInserimentoVoto } from "../shared/utilityMethods";
import { Close, Search, Warning } from "@mui/icons-material";

export default function RowMateriaCreditiFormativi(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const ufcRef = useRef(null)
    const creditoMinimoRef = useRef(null)
    const creditoMedRef = useRef(null)
    const creditoMaxRef = useRef(null)

    const [materia, setMateria] = useState(null)
    const [isEditNonSalvato, setIsEditNonSalvato] = useState(false)
    const [isUfcDialogOpen, setIsUfcDialogOpen] = useState(false)
    const [isUfcInEditMode, setIsUfcInEditMode] = useState(false)
    const [isLoadingUfcEditMode, setIsLoadingUfcEditMode] = useState(false);

    const [indexSalvataggio, setIndexSalvataggio] = useState(0);

    useEffect(() => {
        if (props.indexSalvataggio !== undefined) {
            if (props.indexSalvataggio !== indexSalvataggio) {
                setIndexSalvataggio(props.indexSalvataggio);
                setIsEditNonSalvato(false);
            }
        }
    }, [props.indexSalvataggio])

    useEffect(() => {
        if (props.materia !== undefined)
            setMateria(props.materia)
    }, [props.materia])


    function openUfcDialog() {
        setIsUfcDialogOpen(prev => !prev)
    }

    function confirmModificheUfc() {
        let materiaTmp = materia;
        materiaTmp.UFC = ufcRef.current.value;

        if (props.materiaChanged)
            props.materiaChanged(materiaTmp)

        openUfcDialog();
        setIsEditNonSalvato(true);
        setIsUfcInEditMode(false);
    }

    function changeUfcRef(e) {
        ufcRef.current.value = e.target.value;
    }
    function changeCreditoMinRef(e) {
        let refParsed = regexInserimentoVoto(e?.target?.value ?? "", false, false, false, 100);
        creditoMinimoRef.current.value = refParsed;
    }
    function changeCreditoMedRef(e) {
        let refParsed = regexInserimentoVoto(e?.target?.value ?? "", false, false, false, 100);
        creditoMedRef.current.value = refParsed;
    }
    function changeCreditoMaxRef(e) {
        let refParsed = regexInserimentoVoto(e?.target?.value ?? "", false, false, false, 100);
        creditoMaxRef.current.value = refParsed;
    }

    function checkModificheNonSalvate() {
        if (isEditNonSalvato === false) {
            setIsEditNonSalvato(true);
        }

        let materiaTmp = materia;
        //materiaTmp.UFC = ufcRef.current.value; Questo non serve più, al salvataggio nella dialog già mando il dato all'obj materia
        materiaTmp.livelloCreditoMinimo = parseInt(creditoMinimoRef.current.value);
        materiaTmp.livelloCreditoMedio = parseInt(creditoMedRef.current.value);
        materiaTmp.livelloCreditoMassimo = parseInt(creditoMaxRef.current.value);

        if (props.materiaChanged)
            props.materiaChanged(materiaTmp)
    }

    function enableEditModeUfc() {
        let isUfcInEditModeTmp = !isUfcInEditMode;

        if (!isUfcInEditModeTmp)  //Sto uscend dalla modalità edit, quindi ho annullato le modifiche
            ufcRef.current.value = materia.UFC;

        setIsUfcInEditMode(prev => !prev);
        setIsLoadingUfcEditMode(true);

        setTimeout(() => {
            setIsLoadingUfcEditMode(prev => !prev);
        }, 300)
    }
    return (
        <Fragment>
            {materia != null ? (
                <TableRow key={materia.idMateria} >
                    <TableCell component="th" scope="row" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }} >
                        <Typography variant={isMobile ? 'caption' : 'body2'}>{materia?.nomeMateria ?? "-"}</Typography>
                        {isEditNonSalvato && (
                            <Box sx={{ color: "#ed6c02", display: "flex", alignItems: 'center' }}>
                                <Warning sx={{ mr: 1, fontSize: '1.2rem' }} />
                                <Typography variant="caption">
                                    Modifiche non salvate
                                </Typography>
                            </Box>
                        )}
                    </TableCell>
                    <TableCell align="center" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <IconButton onClick={openUfcDialog} aria-label="delete" color="primary">
                            <Search />
                        </IconButton>
                    </TableCell>
                    <TableCell align="center" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <input className='inputEdit'
                            ref={creditoMinimoRef}
                            onChange={changeCreditoMinRef}
                            onBlur={checkModificheNonSalvate}
                            defaultValue={regexInserimentoVoto(materia?.livelloCreditoMinimo ?? "", false, false, false, 100)}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <input className='inputEdit'
                            ref={creditoMedRef}
                            onChange={changeCreditoMedRef}
                            onBlur={checkModificheNonSalvate}
                            defaultValue={regexInserimentoVoto(materia?.livelloCreditoMedio ?? "", false, false, false, 100)}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <input className='inputEdit'
                            ref={creditoMaxRef}
                            onChange={changeCreditoMaxRef}
                            onBlur={checkModificheNonSalvate}
                            defaultValue={regexInserimentoVoto(materia?.livelloCreditoMassimo ?? "", false, false, false, 100)}
                        />
                    </TableCell>
                </TableRow>
            ) : (
                <TableRow>
                    <TableCell >
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell align="center">
                        <Skeleton animation="wave" />
                    </TableCell>
                </TableRow>
            )}

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isUfcDialogOpen} onClose={openUfcDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" >
                        <b>{materia?.nomeMateria} - UFC</b>
                    </Typography>
                    <IconButton onClick={openUfcDialog} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>

                    <TextField sx={{ mt: 2 }}
                        fullWidth
                        inputRef={ufcRef}
                        onChange={changeUfcRef}
                        defaultValue={materia?.UFC ?? ""}
                        multiline
                        rows={4}
                        disabled={!isUfcInEditMode}
                    />
                    {isUfcInEditMode ? (
                        <Fragment>
                            <Button sx={{ mt: 2 }} disabled={isLoadingUfcEditMode} fullWidth variant="contained" color="error" onClick={enableEditModeUfc}>
                                Annulla modifiche
                            </Button>
                            <Button sx={{ mt: 2 }} disabled={isLoadingUfcEditMode} fullWidth variant="contained" color="success" onClick={confirmModificheUfc}>
                                Conferma modifiche e chiudi finestra
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Button sx={{ mt: 2 }} disabled={isLoadingUfcEditMode} fullWidth variant="contained" color="success" onClick={enableEditModeUfc}>
                                Modifica ufc
                            </Button>
                            <Button sx={{ mt: 2 }} disabled={isLoadingUfcEditMode} fullWidth variant="contained" color="primary" onClick={openUfcDialog}>
                                Chiudi finestra
                            </Button>
                        </Fragment>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}